export default {
	user: {
		users: "Những người dùng",
		newUser: "Người dùng mới",
		permissionGroup: "Nhóm Cấp Phép",
		associatedEntity: "Thực Thể",
		deleteUser: "Xoá Người Dùng",
		deleteFullNote:
			"Anh/Chị có thể xoá vĩnh viễn người dùng khỏi cơ sỡ dữ liệu. Hành động này không thể khôi phục được và Anh/Chị không thể phục hồi người dùng này được nữa.",
		confirmToDelete: "Xác nhận xoá",
		cfDeleteNote: "Anh/Chị đang xoá ",
		cfDeleteNote2: "Hành động này không thể khôi phục được. Bản ghi này sẽ bị xoá vĩnh viễn.",
		userLockoutStatus: "Trạng Thái Người Dùng",
		userLockStatusNote: "Anh/Chị có thể vô hiệu hoá người dùng. Người dùng không thể đăng nhập khi bị vô hiệu hoá.",
		userUnlockStatusNote: "Anh/Chị có thể kích hoạt người dùng. Người dùng có thể đăng nhập trở lại sau khi được kích hoạt.",
		confirmToActivate: "Xác nhận kích hoạt",
		confirmToActivateNote1: "Anh/Chị đang kích hoạt",
		confirmToActivateNote2: "Người dùng này có thể đăng nhập được sau khi được kích hoạt.",
		confirmToDeactivate: "Xoá nhận vô hiệu hoá",
		confirmToDeactivateNote1: "Anh/Chị đang vô hiệu hoá",
		confirmToDeactivateNote2: "Người dùng này không thể đăng nhập được sau khi bị vô hiệu hoá.",
		optional: "Tuỳ chọn",
		deactivateSuccessfully: "Vô hiệu hoá tài khoản thành công",
		reactivateSuccessfully: "Kích hoạt tài khoản thành công",
		updatedSuccessfully: "Cập nhật tài khoản thành công",
		pleaseInputAgentCode: "Nhập Mã đại lý bảo hiểm",
		agentCodeNoteTip: "Mã đại lý bảo hiểm là bắt buộc với các chức danh iTCBLife = DRBO/RBO/SRBO/PRM/TIS/TIS1",
		infoUnchangedWarning: "Thông tin tài khoản không thay đổi, vui lòng kiểm tra lại!",
		createAccountSuccessfully: "Tạo tài khoản thành công",
		form: {
			updateStaffInfo: "Cập nhật tài khoản người dùng",
			staffId: "Mã nhân viên",
			username: "Tài khoản TCB/Username",
			email: "Email Techcombank",
			fullName: "Họ và tên",
			iTcbLifeRole: "Chức danh iTCBLife",
			area: "Miền",
			region: "Vùng",
			branch: "Chi nhánh",
			agentCode: "Mã đại lý bảo hiểm",
			noteForPurpose: "Ghi chú/Mục đích",
			placeHolderNotes: "Nhập lý do, Ghi chú...",
			placeHolderStaffId: "Nhập mã nhân viên",
			placeHolderUserName: "Nhập username",
			placeHolderMail: "Nhập email Techcombank",
			placeHolderFullName: "Nhập Họ Tên",
			createNewAccount: "Thông tin tài khoản người dùng mới",
			englishFirstName: "Tên",
			englishLastName: "Họ",
			placeHolderFirstName: "Nhập tên",
			placeHolderLastName: "Nhập họ",
		},
		label: {
			emailOrUsername: "Email hoặc Tên đăng nhập",
			entityName: "Tên thực thể",
			code: "Mã",
			emailActivationStatus: "Trạng thái email",
			lockoutStatus: "Trạng thái người dùng",
			active: "Hoạt động",
			inactive: "Ngừng hoạt động",
			done: "Hoàn tất",
			pending: "Chờ",
			createdAt: "Thời gian tạo",
			area: "Khu vực",
			region: "Vùng",
			branch: "Chi nhánh",
			username: "Tên đăng nhập",
			name: "Tên",
			englishFirstName: "Tên",
			englishLastName: "Họ",
			chineseFirstName: "Tên",
			chineseLastName: "Họ",
			email: "Email",
			role: "Chức vụ",
			type: "Loại",
			position: "Vị trí",
			pleaseSelect: "Hãy chọn",
			all: "Tất cả",
		},

		button: {
			view: "Xem",
			reInvite: "Mời Lại",
			add: "Thêm",
			create: "Tạo",
			confirm: "Xác Nhận",
			cancel: "Huỷ Bỏ",
			delete: "Xoá",
			deleteUser: "Xoá Người Dùng",
			activateUser: "Kích Hoạt",
			deactivateUser: "Vô Hiệu Hoá",
			search: "Tìm kiếm",
			clear: "Xoá",
			createNewUser: "Tạo tài khoản",
			updateRoleInfo: "Cập nhật role",
			ok: "OK",
			save: "Lưu",
		},

		message: {
			reinviteSuccess: "Một email mời đã được gửi tới người dùng!",
			createSuccess: "Đã tạo người dùng thành công!",
			deleteSuccess: "Đã xoá người dùng thành công!",
			activateSuccess: "Đã kích hoạt người dùng thành công!",
			deactivateSuccess: "Đã vô hiệu hoá người dùng thành công!",
		},

		area: {
			all: "Tất cả",
			north: "Miền Bắc",
			south: "Miền Nam",
			mb: "Bắc",
			mn: "Nam",
			Hub_Mortgage: "Hub_Mortgage",
			Hub_Private: "Hub_Private",
			Hub_Insurance: "Hub_Insurance",
		},

		region: {
			all: "Tất cả",
			region_1: "Vùng 01",
			region_2: "Vùng 02",
			region_3: "Vùng 03",
			region_4: "Vùng 04",
			region_5a: "Vùng 5A",
			region_5: "Vùng 05",
			region_6: "Vùng 06",
			region_7: "Vùng 07",
			region_8: "Vùng 08",
			region_9: "Vùng 09",
			region_10: "Vùng 10",
			region_11: "Vùng 11",
			region_12: "Vùng 12",
			region_12a: "Vùng 12A",
			region_13: "Vùng 13",
			region_14: "Vùng 14",
			region_15: "Vùng 15",
			region_16: "Vùng 16",
			Hub_Insurance: "Vùng Insurance",
			Hub_Mortgage: "Vùng Mortgage",
			Hub_Private: "Vùng Private",
		},

		branch: {
			// needs locale
			branchManager: "Branch Manager",
			regionalManager: "Regional Manager",
			areaManager: "Area Manager",
			all: "Tất cả",
			HDG: "Hải Dương",
			TNT: "Thống Nhất",
			HPG: "Hải Phòng",
			KAN: "Kiến An",
			LHP: "Lê Hồng Phong",
			NQN: "Ngô Quyền",
			TNN: "Thủy Nguyên",
			TOH: "Tô Hiệu",
			VCO: "Văn Cao",
			CDN: "Chợ Đôn",
			NSU: "Lê Thánh Tông ",
			HBG: "Hồng Bàng",
			BTY: "Ecopark",
			HYN: "Hưng Yên",
			PHN: "Phố Hiến",
			KDT: "Văn Lâm",
			YMY: "Yên Mỹ",
			MCI: "Móng Cái",
			QNH: "Quảng Ninh",
			CPA: "Cẩm Phả",
			UBI: "Uông Bí",
			BDA: "Hạ Long ",
			BGN: "Bắc Giang",
			BAN: "Bắc Ninh",
			DBG: "Từ Sơn",
			TDU: "Tiên Du",
			TNS: "Quế Võ",
			NCO: "Yên Phong",
			LSN: "Lạng Sơn",
			DDG: "Đồng Đăng",
			LCI: "Lào Cai",
			THN: "Thái Nguyên",
			MBH: "Phổ Yên",
			PTO: "Việt Trì",
			VPC: "Vĩnh Phúc",
			PYN: "Phúc Yên",
			YBI: "Yên Bái",
			BLD: "Gia Lâm",
			TRP: "Ruby",
			CHD: "Chương Dương",
			NTU: "Ngô Gia Tự",
			YVN: "Ninh Hiệp ",
			NLM: "Ngọc Lâm",
			SDG: "Sài Đồng",
			VHG: "Việt Hưng",
			PSM: "Long Biên ",
			BIZ: "Hà Nội",
			LTH: "Văn Phú",
			BTD: "Bát Đàn",
			HBT: "Xuân Phương",
			CBC: "Cửa Bắc",
			HDU: "Hàng Đậu",
			LND: "Cổ Linh",
			LTT: "Smart City",
			XDU: "Xuân Diệu",
			NBI: "Nội Bài",
			DAH: "Đông Anh",
			MLH: "Mê Linh",
			PLO: "Phủ Lỗ",
			SSN: "Sóc Sơn",
			CLH: "Cát Linh",
			KTN: "Khâm Thiên",
			TLG: "Thăng Long",
			DCG: "Định Công",
			DOA: "Đống Đa",
			HCU: "Hoàng Cầu",
			KMI: "Khương Mai",
			KLN: "Kim Liên",
			LHA: "Láng Hạ",
			NHN: "Hapulico",
			NSO: "Ngã Tư Sở",
			PMI: "Tân Xuân",
			TTH: "Thái Thịnh",
			DVN: "Gamuda",
			HGA: "Hoàng Gia",
			TXN: "Thanh Xuân",
			SGO: "Hà Thành",
			HMI: "Hoàng Mai",
			LDM: "Linh Đàm",
			LDC: "Lò Đúc",
			LTK: "Kim Giang",
			TGA: "Tràng An",
			TRN: "Trần Điền",
			TKC: "Hai Bà Trưng",
			BTR: "Bà Triệu",
			BKA: "Bách Khoa",
			CMO: "Kiến Hưng",
			LTN: "Lạc Trung",
			LNM: "Lĩnh Nam",
			NAH: "Nam Thăng Long",
			TRD: "Trương Định",
			NHT: "Thường Tín",
			HKM: "Nguyễn Tuân",
			CNM: "Park City",
			THO: "Tây Mỗ",
			HSO: "Trung tâm giao dịch Hội Sở",
			BDN: "Ba Đình",
			CGY: "Cầu Giấy",
			CLG: "Chùa Láng",
			DTN: "Đào Tấn",
			DCN: "Đội Cấn",
			HTK: "An Hưng",
			KMA: "Hồ Tùng Mậu",
			NKH: "Ngọc Khánh",
			KTG: "Dương Nội",
			HQV: "Hoàng Quốc Việt",
			HHT: "Tây Hồ Tây",
			LLQ: "Lạc Long Quân",
			NKT: "Nguyễn Khánh Toàn",
			CBI: "Tây Hồ",
			TKE: "Thụy Khuê",
			TNI: "Trần Đăng Ninh",
			NPS: "Trần Thái Tông",
			XLA: "Phạm Văn Đồng",
			CPT: "Ciputra",
			TAH: "Trần Bình",
			DDA: "Đông Đô",
			BGC: "Big C",
			KNM: "Keangnam",
			MDH: "Hà Đông",
			CTH: "Nguyễn Cơ Thạch",
			PXA: "An Khánh",
			TDH: "Trần Duy Hưng",
			QNM: "Từ Liêm",
			NTD: "Hoàng Đạo Thúy",
			MNR: "The Manor",
			TYN: "Trung Yên",
			DXN: "Green Bay",
			HTY: "Hà Tây",
			DPG: "Đan Phượng",
			NGG: "Nhuệ Giang",
			STY: "Sơn Tây",
			VQN: "Văn Quán",
			XAL: "Xa La",
			XMI: "Xuân Mai",
			HNM: "Hà Nam",
			HTH: "Hà Tĩnh",
			BHI: "Kỳ Anh",
			NDH: "Nam Định",
			NAN: "Nghệ An",
			NSS: "Nguyễn Sỹ Sách",
			NBH: "Ninh Bình",
			TBH: "Thái Bình",
			THA: "Thanh Hóa",
			TRU: "Chợ Tây Thành",
			DTU: "Quảng Bình",
			DNG: "Đà Nẵng",
			CHN: "Sơn Trà",
			HCH: "Ngũ Hành Sơn",
			HKH: "Hòa Khánh",
			NHE: "Nguyễn Hữu Thọ",
			PCT: "Phan Chu Trinh",
			DLK: "Đắk Lắk",
			HUE: "Huế",
			LDG: "Lâm Đồng",
			NTG: "Nha Trang",
			MVG: "Mã Vòng",
			VHI: "Starcity",
			PLU: "Pleiku",
			TAK: "Quảng Nam",
			HON: "Hội An",
			QNI: "Quảng Ngãi",
			QNN: "Quy Nhơn",
			THK: "Đà Thành",
			HCB: "29-3",
			CMI: "Chợ Mới",
			TBI: "SunCity",
			BHD: "Bình Dương",
			STN: "Dĩ An",
			MPC: "Mỹ Phước",
			LTU: "Lái Thiêu",
			BPC: "Bình Phước",
			BTU: "Bình Thuận",
			DAI: "Đồng Nai",
			BHA: "Biên Hòa",
			NAI: "Hố Nai",
			LBN: "Long Khánh",
			LOT: "Long Thành",
			THP: "Tam Hiệp",
			TNH: "Tây Ninh",
			VTU: "Vũng Tàu",
			BMT: "30/4",
			BRI: "Bà Rịa",
			HDV: "Chí Linh",
			SMI: "Sao Mai",
			SGN: "Phú Nhuận",
			HCM: "Sài Gòn",
			KDG: "Kỳ Đồng",
			LVS: "Lê Văn Sỹ",
			NSG: "Nam Sài Gòn",
			NTT: "Nguyễn Tất Thành",
			NVT: "Nguyễn Văn Trỗi",
			PTR: "Pasteur",
			HVG: "Central Park ",
			SGM: "Sala",
			TQD: "Lumiere",
			BTH: "An Phú",
			ADV: "CityLand",
			TDN: "Thảo Điền",
			LVB: "Tân Cảng",
			PMH: "Phú Mỹ Hưng",
			ANG: "Bình Đăng",
			HTP: "Huỳnh Tấn Phát",
			PPU: "Phong Phú",
			PXN: "Phú Xuân",
			NTP: "Tân Qui",
			TTN: "Tân Thuận",
			PTG: "Sunrise City",
			BCH: "Phước Kiển.",
			CMT: "Hà Đô",
			TAL: "Thắng Lợi",
			BAH: "Bắc Hải",
			BTX: "Bùi Thị Xuân",
			DSG: "Diamond Island",
			D32: "Đường 3 tháng 2",
			HHU: "Hòa Hưng",
			KHA: "Kỳ Hòa",
			THI: "Hồng Lạc ",
			LTG: "Cao Thắng",
			Q10: "Lacasa",
			CLN: "Chợ Lớn",
			ADG: "An Đông",
			ANC: "An Lạc",
			BPU: "Bình Phú",
			BTI: "Bình Thới",
			BTN: "Bình Tiên",
			LBT: "Lãnh Bình Thăng",
			NSN: "Nguyễn Sơn",
			PCD: "Manhattan",
			PHO: "Phú Thọ",
			KDV: "Quận 6",
			TPU: "Tân Phú",
			TKU: "Thuận Kiều",
			BTG: "Bình Trị Đông",
			TTO: "Global City",
			PLM: "The Rainbow",
			LTR: "Bình Tân",
			HHG: "Hưng Đạo",
			QU5: "Quận 5",
			VAH: "Văn Thánh",
			PDL: "Gia Định",
			BDG: "Cantavil An Phú",
			BIH: "Bình Hòa",
			BTA: "Bình Thạnh",
			DBL: "Đinh Bộ Lĩnh",
			DXH: "Đỗ Xuân Hợp",
			HPU: "Hiệp Phú",
			LQD: "Lê Quang Định",
			GDN: "Minh Khai ",
			PXL: "Phan Xích Long",
			TDI: "Tân Định",
			TDA: "Thanh Đa",
			TNO: "Trần Não",
			VVN: "Võ Văn Ngân",
			BCU: "Bà Chiểu",
			CLI: "Nguyễn Duy Trinh ",
			LXN: "Hiệp Bình Phước ",
			MTN: "Vạn Phúc City",
			THD: "Thủ Đức",
			QTN: "Quang Trung",
			CCI: "Củ Chi",
			HMN: "Hóc môn",
			LDT: "Lê Đức Thọ",
			NGT: "Nguyễn Ảnh Thủ",
			HVH: "Nguyễn Kiệm",
			NOH: "Nguyễn Oanh",
			NTS: "Nguyễn Thái Sơn",
			THT: "Tân Hưng Thuận",
			TKY: "Tô Ký",
			TBN: "Tân Bình",
			APB: "Ấp Bắc",
			AUC: "Âu Cơ",
			BCT: "Bàu Cát",
			BHN: "Phan Văn Hớn",
			TSN: "Tân Sơn Nhất",
			TSG: "Tây Sài Gòn",
			TCH: "Trường Chinh",
			TVK: "Trương Vĩnh Ký",
			THG: "Tân Hương",
			TSO: "Trung Sơn",
			PMY: "Lê Văn Thọ",
			AGG: "An Giang",
			CMU: "Cà Mau",
			CTO: "Cần Thơ",
			AHA: "An Hòa",
			TAN: "Ninh Kiều",
			DTP: "Đồng Tháp",
			KGG: "Kiên Giang",
			LAN: "Long An",
			CRH: "Phú Quốc ",
			STG: "Sóc Trăng",
			TGG: "Tiền Giang",
			VLG: "Vĩnh Long",
			NTL: "Phạm Thái Bường",
			TTC: "Hub Mortgage Miền Bắc",
			TCU: "Hub Mortgage Miền Nam",
			VIN: "Hub Private Miền Bắc",
			PDP: "Hub Private Miền Nam",
			IMB: "Hub Insurance Miền Bắc",
			IMN: "Hub Insurance Miền Nam",
			BLN: "Hub Telesale Miền Nam",
			TTT: "Hub Telesale Miền Bắc",
		},

		staffType: {
			SRBO: "SRBO",
			RBO: "RBO",
			TIS: "TIS",
			PRM: "PRM",
		},
		ho: {
			HO: "Toàn hàng",
		},
	},
}
