<template>
	<div>
		<router-view />
		<!-- Layers where we teleport modals, sidebar, ... to -->
		<div id="layer1"></div>
		<div id="layer2"></div>
		<div id="layer3"></div>
		<div id="layer4"></div>
		<div id="layer5"></div>

		<CMessage />
	</div>
</template>

<script>
// import { inspect } from "@xstate/inspect"
// inspect({ iframe: false })

import getEnv from "@/utils/getEnv"
export default {
	name: "App",
	setup() {
		return {
			env1: getEnv("VUE_APP_VARIABLE_1"),
			env2: getEnv("VUE_APP_VARIABLE_2"),
		}
	},
}
</script>
