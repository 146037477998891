<template>
	<JBox>
		<JBox class="relative">
			<CIcon icon="Bell" width="21" height="21" />
		</JBox>
		<JFlex
			class="items-center justify-center absolute z-10 bg-c1-700 text-cWhite rounded-full"
			style="width: 17px; height: 17px; left: 18px; top: 4px; font-size: 11px"
			>{{ countTheUnreadNoti }}</JFlex
		>
	</JBox>
</template>
<script>
import { ref, onBeforeMount, watch, computed } from "vue"
import CIcon from "@/modules/core/components/CIcon"
import { useI18n } from "vue-i18n"
import { useContractInfoDetail } from "@/modules/dashboard/use/useContractInfoDetail"
import useApis from "@/modules/dashboard/use/useApis"
import { formatDateAndSubtractDate } from "../helpers"
import { useStore } from "vuex"
export default {
	name: "NotificationBell",
	components: { CIcon },
	props: {},
	emits: ["viewAllNotification"],
	setup(props, { emit }) {
		const { t } = useI18n()
		const store = useStore()
		const { agentCodeOfUserLogin, userBranch } = useContractInfoDetail()
		const { fetchAgentRemindersQuery } = useApis({})
		const notificationCountFromStore = computed(() => store.state.notification?.countNotification)
		const countTheUnreadNoti = ref(0)

		const limit = ref(5) // 5 newest items
		const currentPage = ref(1)
		const fetchNotificationList = async () => {
			try {
				currentPage.value = 1
				const variables = {
					where: {
						skip: (currentPage.value - 1) * limit.value,
						first: limit.value,
						where: {
							and: [
								{
									where: {
										agentCode: agentCodeOfUserLogin.value,
									},
								},
								{
									where: {
										branchCode: userBranch || null,
									},
								},
							],
						},
					},
				}
				const dataResponse = await fetchAgentRemindersQuery(variables)
				countTheUnreadNoti.value = dataResponse?.list?.filter((i) => !i?.isChecked)?.length
			} catch (e) {
				console.error(e)
			}
		}
		onBeforeMount(async () => {
			await fetchNotificationList()
		})
		watch(
			() => notificationCountFromStore.value,
			(val) => {
				countTheUnreadNoti.value = val
			}
		)

		return {
			t,
			formatDateAndSubtractDate,
			countTheUnreadNoti,
		}
	},
}
</script>
