<template>
	<CLayout v-loading="loaders.pageLoader">
		<!-- Filter Options -->
		<ContractsFilter
			:pageLoader="loaders.pageLoader.loading"
			:clearFilterLoader="loaders.clearFilter"
			:agentCodeOptions="agentCodeOptions"
			:filterOptions="filterOptions"
			:disabledFeaturesFields="disabledFeaturesFields"
			v-model:contractManagesByAgent="contractManagesByAgent"
			v-model:customerIdentify="customerIdentify"
			v-model:contractNo="contractNo"
			v-model:contractStatus="contractStatus"
			v-model:createdAt="createdAt"
			v-model:areas="areas"
			v-model:regions="regions"
			v-model:branches="branches"
			v-model:staffTypes="staffTypes"
			:searchBy="searchBy"
			@update:contractStatus="handleUpdateContractStatus"
			@update:contractNo="handleUpdateContractNo"
			@update:customerIdentify="handleUpdateCustomerIdentify"
			@update:areas="onUpdateAreas"
			@update:regions="onUpdateRegions"
			@update:branches="onUpdateBranches"
			@update:agentCode="onUpdateAgentCode"
			@update:staffTypes="onUpdateStaffType"
			@filter:apply="handleApplyFilter"
			@filter:clear="handleClearFilter"
			@export:contractList="handleToExportList"
			@searchPolicyBy="onUpdateSearchType"
			:loadingStates="loadingStates"
			:validator="v"
			:defaultPlaceHolder="defaultPlaceHolder"
			@show:roleOptions="showDropdownRoles"
			@show:branchList="showDropDownBranchList"
			@show:areaList="showDropDownAreaList"
			@hide:areaList="hideDropdownAreaList"
			@hide:branchList="hideDropdownBranchList"
			@hide:roleOptions="hideDropdownRoles"
			:warningNoAgentCodeMatch="warningNoAgentCodeMatch"
			:areasIsRequiredByRole="areasIsRequiredByRole"
		/>

		<JBox class="px-4">
			<!-- Table -->
			<CTable
				:list="contractList"
				:isLoading="loadingStates.fetchPolicies"
				:customErrorMessage="mTextContractListAreEmpty"
				customClassErrorMessage="text-c1-400"
				class="mt-6 lg:mt-10 rounded-md"
				:isDisabledScrollable="true"
				:style="{
					boxShadow: contractList?.length > 0 && 'rgb(40 126 184 / 5%) -3px 0px 20px 1px',
					border: contractList?.length > 0 && '2px solid rgb(212 163 163 / 5%);',
				}"
			>
				<template #header>
					<CTableHeaderRow>
						<CTableHeaderCell v-for="header in contractsTableHeader" :key="header.name">
							{{ header.label }}
						</CTableHeaderCell>
					</CTableHeaderRow>
				</template>
				<template v-slot="{ item, first, last }">
					<CTableRow>
						<!--1/ ROLE manager -> Staff name -->
						<CTableCell v-if="isLocationManagers" class="">{{ formatData(item.servicingAgentName) }}</CTableCell>

						<!--2/ ROLE manager -> Agent code -->
						<CTableCell v-if="isLocationManagers" class="">{{ formatData(item.servicingAgentCode) }}</CTableCell>

						<!--3/ Contract No -->
						<CTableCell>
							<JText class="underline cursor-pointer" @click="onViewContractorInfo(item)">{{ formatData(item.policyNo) }}</JText>
						</CTableCell>

						<!--4/ Contractor -->
						<CTableCell class=""> {{ item.poName }}</CTableCell>

						<!--5/ LeadGCM -->
						<CTableCell>
							<JText class="">{{ formatData(item.leadId) }}</JText>
						</CTableCell>

						<!--6/ Apply Date: submissionDate-->
						<CTableCell>
							{{ formatData(item.submissionDate, dayMonthYearFormat) }}
						</CTableCell>

						<!--7/ Issue Date -->
						<CTableCell>
							{{ formatData(item.firstIssuanceDate, dayMonthYearFormat) }}
						</CTableCell>

						<!--8/ Product APE fee -->
						<CTableCell>
							<JText class="">{{ formatData(formatMoney(item.ape)) }}</JText>
						</CTableCell>

						<!--9/ Contract status -->
						<CTableCell>
							<JText class="">{{ formatData(getValPolicyStatusByCodeMVL({ status: item.policyStatus })) }}</JText>
						</CTableCell>

						<!--10/ Resume case Icon -->
						<CTableCell :class="{ 'rounded-tr-lg': first, 'rounded-br-lg': last }">
							<JFlex @click="onViewContractorInfo(item)" class="justify-center items-center h-full cursor-pointer">
								<JText class="text-sm font-bold">{{ t("dashboard.viewMore") }}</JText>
								<CIcon icon="EllipsisVertical" width="20" height="20" />
							</JFlex>
						</CTableCell>
					</CTableRow>
				</template>
				<template #footer>
					<!-- Pagination -->
					<JFlex
						v-if="totalItems && !loadingStates.fetchPolicies"
						class="mt-8 flex-col px-4 md:pr-20 sm:mt-12 sm:flex-row pb-4"
						variant="basic"
					>
						<CPaginationStatus class="mb-4 sm:mb-0" :currentPage="currentPage" :totalCount="totalItems" :limit="limit" />
						<CPagination
							v-model:modelValue="currentPage"
							:totalItems="totalItems"
							:itemPerPage="limit"
							isInputSearch
							@update:modelValue="onHandleSearchPage($event)"
							@searchPage="onHandleSearchPage($event)"
						/>
					</JFlex>
				</template>
			</CTable>
		</JBox>
		<!-- Modal contractor Info -->
		<teleport to="#layer2">
			<CModalSimple
				zIndex="50"
				:isVisible="isOpenModalContractDetail"
				:isCustomClassRoot="true"
				variant="center-max-w"
				:vScrollLock="false"
				@overlay-click="closeModalContractDetail"
			>
				<ViewManagementPolicyDetail :policyNo="policyNo" @closeModalContractDetail="closeModalContractDetail" />
			</CModalSimple>
		</teleport>
	</CLayout>
</template>
<script setup>
import { formatData } from "@/modules/core/composables"
import { ref, computed } from "vue"
import { useContracts } from "@/modules/dashboard/use/useContracts"
import ContractsFilter from "@/modules/dashboard/components/contracts/ContractsFilter.vue"
import ViewManagementPolicyDetail from "@/modules/dashboard/views/contractsManagement/ViewManagementPolicyDetail.vue"
import { useContractInfoDetail } from "@/modules/dashboard/use/useContractInfoDetail"
import debounce from "lodash/debounce"
import { useI18n } from "vue-i18n"
import { formatMoney } from "../../../fna/helpers"
import CIcon from "@/modules/core/components/CIcon"
import { useRouter } from "vue-router"
import { USER_SOURCE_TO_VIEW_POLICY_MANAGEMENT } from "@/modules/dashboard/utils/constant"
import validateToAccessPage from "@/modules/pages/use/usePermissionToAccessPage"

const { locale, t } = useI18n()
const router = useRouter()
const isOpenModalContractDetail = ref(false)
const { userDoesNotHavePermissionAndRole, invalidUserRoleToAccessPage } = validateToAccessPage()

const {
	currentPage,
	totalItems,
	limit,
	contractsTableHeader,
	contractManagesByAgent,
	customerIdentify,
	contractNo,
	contractStatus,
	createdAt,
	contractList,
	readAllContractData,
	exportToFile,
	mTextContractListAreEmpty,
	v,
	agentCodeOptions,
	loadingStates,
	staffTypes,
	areas,
	regions,
	branches,
	filterOptions,
	onUpdateAreas,
	onUpdateRegions,
	onUpdateBranches,
	onUpdateAgentCode,
	disabledFeaturesFields,
	showDropdownRoles,
	hideDropdownRoles,
	loaders,
	onUpdateStaffType,
	warningNoAgentCodeMatch,
	showDropDownBranchList,
	hideDropdownBranchList,
	searchBy,
	onUpdateSearchType,
	handleClearFilter,
	areasIsRequiredByRole,
	hideDropdownAreaList,
	showDropDownAreaList,
} = useContracts()
const { isLocationManagers, getValPolicyStatusByCodeMVL, dayMonthYearFormat, userPosition } = useContractInfoDetail()
const policyNo = ref("")

// redirect to ViewHome if user is invalid positions
if (
	userDoesNotHavePermissionAndRole.value ||
	invalidUserRoleToAccessPage.value ||
	!USER_SOURCE_TO_VIEW_POLICY_MANAGEMENT?.includes(userPosition) // to avoid user access to the page by route
) {
	router.push({ name: "ViewHome" })
}
// computed
const defaultPlaceHolder = computed(() => (locale.value === "en-US" ? "Please select" : "Hãy lựa chọn"))

// METHODS
const handleUpdateContractStatus = (val) => {
	contractStatus.value = val === defaultPlaceHolder.value ? "" : val
}
const handleUpdateContractNo = () => {
	v.value.contractNo.$touch()
}
const handleUpdateCustomerIdentify = () => {
	v.value.customerIdentify.$touch()
}

const onViewContractorInfo = (item) => {
	isOpenModalContractDetail.value = true
	policyNo.value = item?.policyNo
}
const closeModalContractDetail = () => {
	isOpenModalContractDetail.value = false
}
const onHandleSearchPage = debounce(async (pageToGo) => {
	currentPage.value = pageToGo
	await readAllContractData({ agentCodeOptions: agentCodeOptions.value })
}, 400)
const handleApplyFilter = debounce(async () => {
	currentPage.value = 1

	await readAllContractData({ agentCodeOptions: agentCodeOptions.value })

	v.value.$reset()
}, 400)

const handleToExportList = () => {
	exportToFile({ agentCodeOptions: agentCodeOptions.value })
}
</script>
