<template>
	<JBox>
		<JFlex class="mx-5 mt-6 mb-6 text-c1-800 font-bold text-lg">
			<JText class="font-bold text-lg">{{ $t("core.facts") }}</JText>
			<JButton v-if="isEditable" variant="tertiary-rounded" class="ml-auto" style="padding: 0.25rem" @click="openModal()">
				<JIcon class="text-c1-500" icon="Plus" />
			</JButton>
		</JFlex>
		<CEditableField
			v-for="(item, index) in computedList"
			:key="index"
			:label="item.type"
			:value="parseFact(item.value)"
			:canEdit="isEditable"
			:canDelete="isEditable"
			@edit="handleUpdate(item)"
			@delete="handleRemove(item.id)"
		/>
		<JBox v-if="computedList.length === 0" class="mx-5">
			<JText class="text-c0-500"> {{ $t("core.noDataHere") }}</JText>
		</JBox>

		<!-- Modal to handle add and update -->
		<teleport to="#layer2">
			<JModalSimple :isVisible="showModal" @overlay-click="closeModalAndClearForm()">
				<CFormInput
					v-model="factType"
					:options="factTypeOptions"
					componentName="JSelect"
					:label="$t('core.type')"
					class="mb-6"
					:validator="v"
					field="form.type"
				/>
				<CFormInput
					v-model="form.type"
					componentName="JInputText"
					:label="$t('core.label')"
					class="mb-6"
					:validator="v"
					field="form.type"
				/>
				<CFormInput
					v-model="form.value"
					:componentName="
						factType === 'stringValue'
							? 'JInputText'
							: factType === 'numberValue'
							? 'JInputNumber'
							: factType === 'dateValue'
							? 'JDatePicker'
							: factType === 'booleanValue'
							? 'JToggle'
							: null
					"
					:label="$t('core.value')"
					class="mb-6"
					:validator="v"
					field="form.value"
				/>
				<JFlex>
					<JButton @click="selectedId ? updateFact() : addFact()" class="mr-2">
						{{ selectedId ? $t("core.update") : $t("core.add") }}
					</JButton>
					<JButton variant="tertiary-outline" @click="closeModalAndClearForm()">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import dayjs from "dayjs"
import { apiAddFact, apiUpdateFact, apiRemoveFact } from "@covergo/cover-composables"
import { fetcher } from "../../api/fetcher"
import { handleErrorForUser } from "../../api/handleErrorForUser"
import { ref, computed, watch } from "vue"
import { required } from "@vuelidate/validators"
import { useStore } from "vuex"
import useVuelidate from "@vuelidate/core"
import { useI18n } from "vue-i18n"

export default {
	props: {
		fields: {
			type: Array,
		},
		entityId: {
			type: String,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const { t } = useI18n()
		const store = useStore()
		const list = ref([])
		const computedList = computed(() => list.value || [])
		const showModal = ref(false)
		const factType = ref("stringValue") // stringValue, numberValue etc.
		const factTypeOptions = ref([
			{ name: t("core.text"), value: "stringValue" },
			{ name: t("core.number"), value: "numberValue" },
			{ name: t("core.boolean"), value: "booleanValue" },
			{ name: t("core.date"), value: "dateValue" },
		])
		const form = ref({
			type: null,
			value: null,
		})
		const selectedId = ref(null)

		/** Vuelidate */
		const rules = {
			form: {
				type: {
					required,
				},
				value: {
					required,
				},
			},
		}
		const v = useVuelidate(rules, { form })
		const checkFormValid = () => {
			v.value.form.$touch()
			return !v.value.form.$invalid
		}

		// helper functions
		const parseFact = (fact) => {
			return fact?.stringValue
				? fact?.stringValue
				: fact?.numberValue
				? fact?.numberValue
				: fact?.dateValue
				? dayjs(fact?.dateValue)?.format(store?.state?.dateFormat)
				: fact?.booleanValue
				? "true"
				: !fact?.booleanValue
				? "false"
				: null
		}
		const getScalarType = (value) => {
			return value?.stringValue
				? "stringValue"
				: value?.numberValue
				? "numberValue"
				: value?.dateValue
				? "dateValue"
				: value?.booleanValue !== null
				? "booleanValue"
				: null
		}
		const parseFactForInputs = (fact) => {
			return fact?.stringValue
				? fact?.stringValue
				: fact?.numberValue
				? fact?.numberValue
				: fact?.dateValue
				? new Date(fact?.dateValue)
				: fact?.booleanValue !== null
				? fact?.booleanValue
				: null
		}

		const openModal = () => {
			showModal.value = true
		}
		const closeModalAndClearForm = () => {
			v.value.$reset()
			showModal.value = false
			form.value = {
				type: null,
				value: null,
			}
			selectedId.value = null
		}

		// managing local state
		const addToList = (item) => {
			const array = list?.value
			array.push(item)
		}
		const removeFromList = (factId) => {
			list.value = list.value?.filter((item) => item.id !== factId)
		}
		const updateList = (id, updatedObj) => {
			const found = list.value?.findIndex((item) => item?.id === id)
			list.value[found] = { id, ...updatedObj }
		}

		// adding
		const addFact = async () => {
			const isFormValid = checkFormValid()
			if (!isFormValid) return
			const variables = {
				entityId: props?.entityId,
				input: {
					type: form.value?.type,
					value: { [factType.value]: form.value?.value },
				},
			}
			const { error, data } = await apiAddFact({
				variables,
				fetcher,
			})
			if (error) {
				handleErrorForUser({ error, $t: t })
				return
			}
			const addObject = {
				id: data?.createdStatus?.id,
				type: form.value?.type,
				value: { [factType.value]: form.value?.value },
			}
			addToList(addObject)
			closeModalAndClearForm()
		}

		// update
		const handleUpdate = (fact) => {
			factType.value = getScalarType(fact?.value)
			selectedId.value = fact?.id
			form.value = {
				type: fact?.type,
				value: parseFactForInputs(fact?.value),
			}
			openModal()
		}
		const updateFact = async () => {
			const isFormValid = checkFormValid()
			if (!isFormValid) return
			const variables = {
				entityId: props?.entityId,
				input: {
					id: selectedId.value,
					type: form.value?.type,
					value: { [factType.value]: form.value?.value },
				},
			}
			const { error } = await apiUpdateFact({
				variables,
				fetcher,
			})
			if (error) {
				handleErrorForUser({ error, $t: t })
				return
			}
			const updatedObj = {
				type: form.value?.type,
				value: { [factType.value]: form.value?.value },
			}
			updateList(selectedId.value, updatedObj)
			closeModalAndClearForm()
		}

		// remove
		const removeFact = async (id) => {
			const variables = {
				entityId: props?.entityId,
				id,
			}
			const { error } = await apiRemoveFact({
				variables,
				fetcher,
			})
			return error
		}
		const handleRemove = async (id) => {
			const hasError = await removeFact(id)
			if (hasError) {
				handleErrorForUser({ error: hasError, $t: t })
				return
			}
			removeFromList(id)
		}

		// watcher
		watch(
			() => props.fields,
			(updatedVal) => {
				list.value = updatedVal || []
			},
			{
				deep: true,
				immediate: true,
			}
		)

		return {
			v,
			computedList,
			showModal,
			factType,
			factTypeOptions,
			form,
			selectedId,
			parseFact,
			getScalarType,
			openModal,
			closeModalAndClearForm,
			addFact,
			handleUpdate,
			updateFact,
			handleRemove,
		}
	},
}
</script>
