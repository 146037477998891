import core from "@/modules/core/locales/vn.js"
import fna from "@/modules/fna/locales/vn.js"
import auth from "@/modules/auth/locales/vn.js"
import dashboard from "@/modules/dashboard/locales/vn.js"
import user from "@/modules/user/locales/vn.js"
import caseManagement from "@/modules/case/locales/vn.js"
import pages from "@/modules/pages/locales/vn.js"
import setting from "@/modules/setting/locales/vn.js"
import cms from "@/modules/cms/locales/vn.js"
import notification from "@/modules/notification/locales/vn.js"
import rbo from "@/modules/rbo/locales/vn.js"
import customerSupport from "@/modules/customerSupport/locales/vn.js"
export default {
	...fna,
	...core,
	...auth,
	...dashboard,
	...user,
	...caseManagement,
	...pages,
	...setting,
	...cms,
	...notification,
	...rbo,
	...customerSupport,
	errors: {
		network: "Network Error: Please try again.",
		token: "Invalid Token: Please login again.",
		permission: "You are not authorized to do this action.",
		query: "There was a problem.",
		general: "Oops, some errors occurred.",
		dynamicTemplatesQuery: "Unexpected error: template is undefined.",
	},
}
