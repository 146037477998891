<template>
	<JBox :class="{ [customStyle]: true, show: tooltipShow }" class="flex tooltip">
		<CIcon :icon="icon" @click="showTooltip" class="cursor-pointer relative" width="16" height="16" />
		<div v-if="tooltipShow" class="overlay fixed inset-0" @click="hideTooltip"></div>
		<JBox
			:style="inputStyleToolTipBox"
			:class="!lastProduct && 'left-position'"
			class="tooltipText ml-8 absolute z-50 bottom-125 left-100 px-5 py-4 rounded bg-c3-50 min-w-full w-96"
		>
			<JBox v-if="tooltipKey && tooltipKey === 'medicalSupport'">
				<MCPlusSupportToolTip />
			</JBox>
			<JBox v-else-if="tooltipKey && (tooltipKey === 'criticalIllness' || tooltipKey === 'criticalIllnessPlus')">
				<CIPlusSupportToolTip :benefit="tooltipKey" />
			</JBox>
			<ul
				v-else
				style="color: #003956"
				class="flex flex-col text-sm pl-2"
				:class="{ 'list-disc': tooltipData && tooltipData.length > 1 }"
			>
				<li
					v-for="(tooltipItem, index) in tooltipData"
					:key="index"
					class="cursor-pointer select-none relative font-normal"
					style="min-width: 5rem"
					:class="{ 'list-none': firstChildWithoutBullet && index === 0 }"
				>
					<div v-html="tooltipItem"></div>
				</li>
			</ul>
		</JBox>
	</JBox>
</template>

<script>
import CIcon from "@/modules/core/components/CIcon"
import MCPlusSupportToolTip from "./products/MCPlusSupportToolTip"
import CIPlusSupportToolTip from "./products/CIPlusSupportToolTip"

export default {
	name: "FNATooltip",
	components: { CIcon, MCPlusSupportToolTip, CIPlusSupportToolTip },
	props: {
		tooltipData: {
			type: Array,
		},
		icon: {
			type: String,
			default: "Info",
		},
		tooltipKey: {
			type: String,
		},
		customStyle: {
			type: String,
		},
		inputStyleToolTipBox: {
			type: String,
		},
		lastProduct: {
			type: Boolean,
			default: false,
		},
		firstChildWithoutBullet: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			tooltipShow: false,
		}
	},
	methods: {
		showTooltip() {
			this.tooltipShow = true
		},
		hideTooltip() {
			this.tooltipShow = false
		},
	},
}
</script>

<style>
.tooltip {
	position: relative;
	display: inline-block;
}

.tooltip .tooltipText {
	visibility: hidden;
	position: absolute;
	z-index: 1;
	top: -5px;
}

.tooltip .tooltipText.left-position {
	left: -20px;
}

.tooltip .tooltipText::after {
	display: none;
	content: "";
	position: absolute;
	top: 14%;
	right: 100%;
	margin-top: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: transparent #dceaf7 transparent transparent;
}

.tooltip:hover .tooltipText {
	visibility: visible;
}
.tooltip.show .tooltipText {
	visibility: visible;
}
.tooltip .overlay {
	right: -100%;
	z-index: 1;
}
@media screen and (max-width: 475px) {
	.tooltip .tooltipText.left-position {
		left: -300px;
	}
}
.w-96 {
	width: 24rem;
}
</style>
