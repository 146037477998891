<template>
	<JBox>
		<JFlex variant="basic">
			<JLabel class="font-bold">{{ label }}</JLabel>
			<JText class="text-cError-500 cursor-pointer select-none focus:outline-none" @click="clearDate">
				{{ clearLabel }}
			</JText>
		</JFlex>
		<!-- Date Picker Group -->
		<JFlex class="-mx-2 flex-wrap">
			<JBox class="w-full sm:w-4/12 px-2" :style="customStyleDateType">
				<CFormInput
					:variant="variant"
					:modelValue="modelValue.type"
					:validator="validator"
					field="type"
					componentName="JSelect"
					:allowSelectNothing="customAllowSelectNoValue"
					:options="dateTypeOptions"
					:placeholder="customPlaceholderDateType"
					@input="handleChangeType($event)"
				/>
			</JBox>
			<JBox class="w-full sm:w-8/12 px-2" :style="customStyleDatePicker">
				<CFormInput
					:variant="variant"
					:modelValue="modelValue.value"
					:validator="validator"
					field="value"
					componentName="JDatePicker"
					:mode="modelValue.type === 'between' ? 'range' : 'single'"
					:placeholder="customPlaceHolderDate"
					iconSuffix="Calendar"
					:minDate="minDate"
					:maxDate="maxDate"
					@update:modelValue="handleInput($event)"
				/>
			</JBox>
		</JFlex>
		<!-- Quick options -->
		<JFlex class="flex-wrap mt-2">
			<JBox v-for="item in quickOptions" :key="item.value" :class="getQuickOptionClass(item)" @click="quickSet(item.value)">
				<JText>{{ item.label }}</JText>
			</JBox>
		</JFlex>
	</JBox>
</template>

<script>
import dayjs from "dayjs"

export default {
	name: "CDatePickerAdvanced",
	emits: ["update:modelValue"],
	props: {
		/**
		 * Date picker value
		 * - type // between - before - after
		 * - value
		 * - quickOptionValue
		 */
		modelValue: {
			type: Object,
			default: () => {},
		},
		label: {
			type: String,
			default: "",
		},
		clearLabel: {
			type: String,
			default: "Clear",
		},
		dateTypeOptions: {
			type: Array,
			default: () => [],
		},
		quickOptions: {
			type: Array,
			default: () => [],
		},
		variant: {
			type: String,
			default: "primary",
		},
		customPlaceHolderDate: {
			type: String,
			default: "",
		},
		customPlaceholderDateType: {
			type: String,
			default: "",
		},
		customAllowSelectNoValue: {
			type: Boolean,
			default: false,
		},
		customStyleDateType: {
			type: String,
			default: "",
		},
		customStyleDatePicker: {
			type: String,
			default: "",
		},
		validator: {
			type: Object,
			default: () => {},
		},
	},
	computed: {
		minDate() {
			return dayjs().subtract(65, "year")
		},
		maxDate() {
			return dayjs()
		},
	},
	methods: {
		clearDate() {
			this.$emit("update:modelValue", {
				type: "between",
				value: [],
				quickOptionValue: "",
			})
		},
		handleChangeType(event) {
			const type = event.target.value
			this.$emit("update:modelValue", {
				type,
				value: type === "between" ? [] : null,
				quickOptionValue: "",
			})
		},
		handleInput(value) {
			this.$emit("update:modelValue", {
				type: this.modelValue.type,
				value,
				quickOptionValue: "",
			})
		},
		getQuickOptionClass(item) {
			return [
				"text-sm cursor-pointer px-3 py-2 rounded-full select-none",
				item.value === this.modelValue.quickOptionValue ? "bg-c1-500 text-cWhite" : "bg-cWhite text-cBlack",
			]
		},
		quickSet(quickOptionValue) {
			const computedValue = {
				type: "between",
				value: null,
				quickOptionValue,
			}
			const today = dayjs().set("hour", 0).set("minute", 0).set("second", 0)
			if (quickOptionValue === "today") {
				computedValue.value = [today, today]
			} else if (quickOptionValue === "lastWeek") {
				computedValue.value = [dayjs().set("hour", 0).set("minute", 0).set("second", 0).add(-6, "day"), today]
			} else if (quickOptionValue === "lastMonth") {
				computedValue.value = [dayjs().set("hour", 0).set("minute", 0).set("second", 0).add(-1, "month"), today]
			} else if (quickOptionValue === "last3Months") {
				computedValue.value = [dayjs().set("hour", 0).set("minute", 0).set("second", 0).add(-3, "month"), today]
			}
			this.$emit("update:modelValue", computedValue)
		},
	},
}
</script>
