import { computed, getCurrentInstance } from "vue"
import { get, isFunction, isString } from "lodash"

import { def, isPlainObject } from "@/utils"
import { propsFactory } from "@/helpers"

let defaultVariants = {}

export function registerVariants(variants) {
	defaultVariants = variants
}

export const genVariantProps = propsFactory({
	variant: {
		type: String,
		default: "primary",
	},
	size: {
		type: String,
		default: "md",
	},
	modifier: String,
})

export function propertize(app, source, properties, ...args) {
	for (const key in properties) {
		const prop = properties[key]
		const newSource = isPlainObject(prop) ? {} : Array.isArray(prop) ? [] : null

		if (newSource) {
			source[key] = propertize(app, newSource, prop, ...args)
		} else if (isFunction(prop)) {
			def(source, key, {
				get: () => {
					// use `app.exposed` for components that are using script setup because it's closed by default, otherwise use `app.setupState`
					const exposed = app.exposed || app.setupState

					return prop.call(exposed, app.props, app.setupContext, app, ...args)
				},
			})
		} else {
			source[key] = prop
		}
	}

	return source
}

export function useVariant(name, props, variants) {
	const app = getCurrentInstance()
	const componentVariants = computed(() => {
		const component = variants ? variants?.[name] : defaultVariants?.[name]
		let variant = props.variant

		// If "default" points to another variant, look for that variant
		if (variant === "default") variant = component?.default

		return component?.[variant]
	})

	const classes = computed(() => propertize(app, {}, componentVariants.value.el || {}))
	const assets = computed(() => propertize(app, {}, componentVariants.value.assets || {}))

	function getClass(path, ...args) {
		const value = get(classes.value, path)

		if (Array.isArray(value)) {
			return value.map((val) => (isFunction(val) ? val(...args) : val))
		}

		return value
	}

	return {
		getClass,
		classes,
		assets,
	}
}

function combine(obj, ...targets) {
	targets.forEach((target, i) => {
		if (target) {
			Object.keys(target).forEach((key) => {
				const value = obj[key]
				const targetValue = target[key]

				if (isPlainObject(targetValue)) {
					const next = targets[i + 1]
					const nextValue = next[key]

					/**
					 * {
					 *   [target1]: {
					 *     [keyA]: {...}
					 *   },
					 *   [target2]?: {
					 *     [keyA]?: {...}
					 *   },
					 * }
					 */
					if (next && nextValue && isPlainObject(nextValue)) {
						obj[key] = combine({}, targetValue, nextValue)
					} else {
						obj[key] = targetValue
					}
				} else {
					// [string, function]
					const arr = [...(Array.isArray(value) ? value : [value]), targetValue].filter((item) => item)
					const hasStringOnly = !arr.some((item) => !isString(item))

					obj[key] = hasStringOnly ? arr.join(" ") : arr
				}
			})
		}
	})

	return obj
}

export function classesFactory(base, element, assets) {
	return {
		element([color, size, ...mods], override = {}) {
			const sizes = override.sizes || element.sizes || {}
			const colors = override.colors || element.colors || {}
			const modifiers = override.modifiers || element.modifiers || {}
			const combined = combine(
				{
					root: base,
				},
				colors[color],
				sizes[size],
				...mods.map((mod) => modifiers[mod])
			)

			return combined
		},
		assets([color, size, ...mods], override = {}) {
			const sizes = override.sizes || assets.sizes || {}
			const colors = override.colors || assets.colors || {}
			const modifiers = override.modifiers || assets.modifiers || {}
			const combined = combine(
				{
					root: base,
				},
				colors[color],
				sizes[size],
				...mods.map((mod) => modifiers[mod])
			)

			return combined
		},
	}
}

export function classify(...args) {
	const arr = args.reduce((acc, item) => {
		if (isString(item)) {
			acc.push(item)
		} else if (isPlainObject(item)) {
			Object.keys(item).forEach((classes) => {
				const value = item[classes]

				if (value === true) {
					acc.push(classes)
				}
			})
		}

		return acc
	}, [])

	return arr.join(" ")
}

export function genModifierClasses(name, props) {
	return ["variant", "size", "modifier"].map((type) => name && props[type] && `c-${name}--${props[type]}`)
}
