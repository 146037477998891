import { parseJSON } from "@/modules/core/composables"
import { uniqBy, isEmpty } from "lodash"
import { LEAD_DEPARTMENT, SUPERVISOR_DEPT } from "@/modules/dashboard/utils/constant"
import { getUserPositionBySource } from "@/modules/core/use/useUserSource"
import { gql } from "@covergo/cover-composables"
import { fetcher } from "@/modules/core/api/fetcher"

export default function useAgentCodes() {
	function defaultVariablesOfFetchingAgentCodes(userLocatedByBranchOrArea) {
		const variables = {
			where: {
				and: [{ source_contains: userLocatedByBranchOrArea }],
			},
		}
		return variables
	}

	function getVariablesOfInputSearch(value) {
		return {
			where: {
				fields: {
					path: "fields.agentCodeName",
					value: { stringValue: value },
					condition: "STRING_CONTAINS",
				},
			},
		}
	}
	async function fetchAgentCode(variables = {}) {
		const query = gql`
			query individualAgentCode2($where: individualWhereInput!) {
				individualAgentCode(where: $where) {
					list {
						englishFirstName
						englishLastName
						fields
						source
					}
				}
			}
		`
		const { data, error } = await fetcher({ variables, query })
		return {
			data,
			error,
		}
	}

	function getAgentCodeOptionsByUserFrontLine({ agentList }) {
		let dataList = agentList?.reduce((resultList, currentItem) => {
			const fields = parseJSON(currentItem.fields, "{}")
			if (isEmpty(fields)) return resultList
			const userPosition = getUserPositionBySource(currentItem?.source)

			const transformItem = {
				...currentItem,
				value: fields?.agentCodeName,
				code: fields?.agentCodeName,
				name: fields?.agentCode,
			}
			if (![...SUPERVISOR_DEPT, ...LEAD_DEPARTMENT].includes(userPosition)) {
				resultList.push(transformItem)
			}
			return resultList
		}, [])

		dataList = uniqBy(dataList, "code")
		return dataList
	}
	return {
		fetchAgentCode,
		getAgentCodeOptionsByUserFrontLine,
		getVariablesOfInputSearch,
		defaultVariablesOfFetchingAgentCodes,
	}
}
