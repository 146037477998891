<template>
	<CLayout :title="$t('dashboard.dashboard')" v-loading="loaders.pageLoader">
		<JFlex class="flex-wrap m-4">
			<JBox class="w-3/12 px-3 mb-4">
				<CFormInput
					componentName="JSelect"
					:allowSelectNothing="false"
					:placeholder="$t('dashboard.typeToSearch')"
					:options="indicatorData"
					v-model="indicator"
					:label="$t('dashboard.indicator')"
				/>
			</JBox>
			<JBox class="w-3/12 px-3 mb-4" v-if="enableSumBy">
				<CFormInput
					componentName="JSelect"
					:placeholder="$t('dashboard.typeToSearch')"
					:options="sumByData"
					v-model="sumBy"
					:label="$t('dashboard.sumBy')"
				/>
			</JBox>
			<JBox class="w-3/12 px-3 mb-4" v-if="enableFilters.staffType">
				<CFormInput
					componentName="JSelect"
					:allowSelectNothing="false"
					:placeholder="$t('dashboard.typeToSearch')"
					:options="staffTypes"
					v-model="filters.staffType"
					:label="$t('dashboard.staffType')"
			/></JBox>
			<JBox class="w-3/12 px-3 mb-4" v-if="enableFilters.area">
				<CFormInput
					componentName="JSelect"
					:placeholder="$t('dashboard.typeToSearch')"
					:options="areas"
					v-model="filters.area"
					:label="$t('dashboard.area')"
				/>
			</JBox>
			<JBox class="w-3/12 px-3 mb-4" v-if="enableFilters.region && filters.area !== 'all'">
				<CFormInput
					componentName="JSelect"
					:placeholder="$t('dashboard.typeToSearch')"
					:options="regions"
					v-model="filters.region"
					:label="$t('dashboard.region')"
				/>
			</JBox>
			<JBox class="w-3/12 px-3 mb-4" v-if="enableFilters.branch && filters.area !== 'all' && filters.region !== 'all'">
				<CFormInput
					componentName="JSelect"
					:disabled="disableBranchSelect"
					:placeholder="$t('dashboard.typeToSearch')"
					:options="branches"
					v-model="filters.branch"
					:label="$t('dashboard.branch')"
				/>
			</JBox>

			<JBox class="w-6/12 px-3">
				<CDatePickerAdvanced
					v-model="filters.createdAt"
					:label="$t('case.createdAt')"
					:clearLabel="$t('core.clear')"
					:dateTypeOptions="dateTypeOptions"
					:quickOptions="quickOptions"
					variant="primary"
				/>
			</JBox>

			<JBox class="w-3/12 px-3 mb-4 relative" v-if="enableGroupBy">
				<CFormInput
					componentName="JSelect"
					:placeholder="$t('dashboard.typeToSearch')"
					:options="groupByData"
					v-model="groupedBy"
					:label="$t('dashboard.groupBy')"
				/>

				<JBox class="absolute" style="right: -10px; top: 40px">
					<FNATooltip
						v-if="tooltipData.length > 0"
						:tooltipData="tooltipData"
						inputStyleToolTipBox="top: 40px; right: 0;left: initial"
					/>
				</JBox>
			</JBox>
			<JFlex class="w-full px-3 justify-center mt-4" :class="disableViewChartButton && 'cursor-not-allowed'">
				<JButton @click="handleSubmit" :class="disableViewChartButton && 'opacity-50 pointer-events-none'">
					<template v-if="isLoading">
						<JSpinner type="dots" variant="primary" />
					</template>
					<template v-else>
						{{ $t("dashboard.viewChart") }}
					</template>
				</JButton>
			</JFlex>
		</JFlex>

		<JBox class="px-4">
			<JBox v-if="isShowChart">
				<apexchart v-if="type === 'line'" type="line" width="100%" height="500" :options="options" :series="series"></apexchart>
				<JFlex v-if="type === 'pie'" class="w-full justify-center mt-10">
					<apexchart type="pie" width="700" :options="options" :series="series"></apexchart>
				</JFlex>
			</JBox>
			<!-- /** GRAPH TABLE INFO */ -->
			<JBox class="mb-4">
				<!-- <table class="w-full">
					<tr>
						<td style="width: 11rem"></td>
						<td v-for="i in 8" :key="i" class="text-center border border-c0-600 px-3 border-opacity-50">
							<span>R{{ i }}</span>
						</td>
					</tr>
					<tr>
						<td class="border border-c0-600 px-3 border-opacity-50">
							<span>Started</span>
						</td>
						<td v-for="i in 8" :key="i" class="text-center border border-c0-600 px-3 border-opacity-50">
							<span>{{ 140 + i }}</span>
						</td>
					</tr>
					<tr>
						<td class="border border-c0-600 px-3 border-opacity-50">
							<span>Completed</span>
						</td>
						<td v-for="i in 8" :key="i" class="text-center border border-c0-600 px-3 border-opacity-50">
							<span>{{ 140 + i }}</span>
						</td>
					</tr>
					<tr>
						<td class="border border-c0-600 px-3 border-opacity-50">
							<span>Dropped</span>
						</td>
						<td v-for="i in 8" :key="i" class="text-center border border-c0-600 px-3 border-opacity-50">
							<span>{{ 140 + i }}</span>
						</td>
					</tr>
					<tr>
						<td class="border border-c0-600 px-3 border-opacity-50">
							<span>% Complete</span>
						</td>
						<td v-for="i in 8" :key="i" class="text-center border border-c0-600 px-3 border-opacity-50">
							<span>{{ 140 + i }}</span>
						</td>
					</tr>
				</table> -->
			</JBox>
		</JBox>
	</CLayout>
</template>

<script setup>
import { useDashboard } from "./../use/useDashboard"
import FNATooltip from "@/modules/fna/components/FNATooltip"

const {
	indicator,
	sumBy,
	areas,
	regions,
	branches,
	disableBranchSelect,
	staffTypes,
	filters,
	dateTypeOptions,
	quickOptions,
	enableFilters,
	disableViewChartButton,
	indicatorData,
	sumByData,
	chartOptions: options,
	chartType: type,
	chartSeries: series,
	isShowChart,
	groupByData,
	groupedBy,
	isLoading,
	enableSumBy,
	enableGroupBy,
	tooltipData,
	// methods
	handleSubmit,
	loaders,
} = useDashboard()
</script>
