<template>
	<JBox>
		<CAccordion :title="title || $t('core.credentials')">
			<!-- Credentials -->
			<EntityCredentials
				:entityId="entityId"
				:typename="typename"
				:fields="credentials"
				:isEditable="isEditable"
				@updated="handleUpdateCredentials()"
			/>
			<!-- Contacts -->
			<EntityContacts :entityId="entityId" :fields="contacts" :isEditable="isEditable" />
			<!-- Addresses -->
			<EntityAddresses :entityId="entityId" :fields="addresses" :isEditable="isEditable" />
			<!-- Identities -->
			<EntityIdentities :entityId="entityId" :fields="identities" :isEditable="isEditable" />
			<!-- Facts -->
			<EntityFacts :entityId="entityId" :fields="facts" :isEditable="isEditable" />
			<!-- Relationships -->
			<EntityRelationships :entityId="entityId" :fields="relationships" :isEditable="isEditable" />
			<slot name="footer" />
		</CAccordion>
	</JBox>
</template>

<script>
import {
	getIndividualCredentials,
	getCompanyCredentials,
	getOrganizationCredentials,
	getInternalCredentials,
	getObjectCredentials,
} from "./credentialsFields"
import EntityCredentials from "./EntityCredentials"
import EntityContacts from "./EntityContacts"
import EntityIdentities from "./EntityIdentities"
import EntityAddresses from "./EntityAddresses"
import EntityRelationships from "./EntityRelationships"
import EntityFacts from "./EntityFacts"

import { computed } from "vue"
import { useI18n } from "vue-i18n"

export default {
	name: "CEntityCard",
	components: {
		EntityContacts,
		EntityIdentities,
		EntityCredentials,
		EntityAddresses,
		EntityRelationships,
		EntityFacts,
	},
	props: {
		entity: {
			type: Object,
			required: true,
		},
		typename: {
			// individual, company, internal, organization, object etc
			type: String,
			required: true,
		},
		title: {
			type: String,
			defaut: null,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()

		const handleUpdateCredentials = () => {
			emit("update:credentials")
		}

		// computed
		const entityId = computed(() => props?.entity?.id)
		const credentials = computed(() => {
			const locale = t
			const entity = props?.entity
			const type = props?.typename
			return type === "individual"
				? getIndividualCredentials(entity, locale)
				: type === "company"
				? getCompanyCredentials(entity, locale)
				: type === "organization"
				? getOrganizationCredentials(entity, locale)
				: type === "internal"
				? getInternalCredentials(entity, locale)
				: type === "object"
				? getObjectCredentials(entity, locale)
				: []
		})
		const contacts = computed(() => props?.entity?.contacts)
		const identities = computed(() => props?.entity?.identities)
		const addresses = computed(() => props?.entity?.addresses)
		const relationships = computed(() => props?.entity?.relationships)
		const facts = computed(() => props?.entity?.facts)

		return {
			handleUpdateCredentials,
			entityId,
			credentials,
			contacts,
			identities,
			addresses,
			relationships,
			facts,
		}
	},
}
</script>
