<template>
	<div v-click-outside="hideCalendar" class="relative text-c0-500" :class="variantCls.root" style="min-height: 2.5rem">
		<!-- Date Label -->
		<div :class="variantCls.input">
			<input
				type="text"
				v-model="inputDate"
				@blur="handleBlur"
				@focus="handleFocus"
				v-mask="'99/99/9999'"
				:placeholder="placeholder"
				class="border border-c0-300 rounded-lg px-5 h-10 w-full placeholder-c0-500"
				v-on:keydown="keydown"
				:disabled="disabled"
			/>
		</div>
		<div
			:enterFromClass="variantCls.enterFrom"
			:enterActiveClass="variantCls.enterActive"
			:leaveActiveClass="variantCls.leaveActive"
			:leaveToClass="variantCls.leaveTo"
		>
			<div class="relative">
				<JCalendar
					v-if="calendarVisible"
					:modelValue="computedValue"
					:variant="componentInstanceVariants.calendar"
					:mode="mode"
					v-bind="filteredAttrs"
					@update:modelValue="onUpdateModelValue"
				/>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @description This component can be used for picking one day
 * We are using https://vcalendar.io/
 * @param {ISOString | Date} value - it accepts String date or JavaScript Date object
 * @returns {ISOString} - it returns simplified ISOString like so "2019-01-24"
 */
import dayjs from "dayjs"
import { makeGetComponentVariants } from "@covergo/cover-components"
const customParseFormat = require("dayjs/plugin/customParseFormat")
dayjs.extend(customParseFormat)
export default {
	name: "CDatePicker",
	emits: ["update:modelValue"],
	props: {
		variant: {
			type: String,
			default: "default",
		},
		format: {
			type: String,
			default: "DD/MM/YYYY",
		},
		modelValue: {
			// Accepts {String in ISO date format YYYY-MM-DD}, {Null} or {Date}
			validator(val) {
				return val === null || typeof val === "string" || val instanceof Date || typeof val === "object"
			},
			required: true,
			default: null,
		},
		placeholder: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		mode: {
			type: String,
			default: "single",
		},
	},
	data() {
		return {
			calendarVisible: false,
			inputDate: "",
		}
	},
	computed: {
		filteredAttrs() {
			const newAttrs = { ...this.$props, ...this.$attrs }
			delete newAttrs.class
			return newAttrs
		},
		variantCls() {
			return (
				makeGetComponentVariants({
					componentName: "CDatePicker",
					variant: this.variant,
				})?.el ?? {}
			)
		},
		componentInstanceVariants() {
			return (
				makeGetComponentVariants({
					componentName: "CDatePicker",
					variant: this.variant,
				})?.componentInstanceVariants ?? {}
			)
		},
		computedValue() {
			if (!this.modelValue) return null
			// If value is string, convert it to date
			if (typeof this.modelValue === "string") {
				const dateValue = this.modelValue
					?.split("-")
					.map((s) => s.padStart(2, "0"))
					.join("-")
				const res = dayjs(dateValue, this.format, true)
				if (res.isValid()) {
					this.$emit("update:modelValue", res.format("YYYY-MM-DD"))
					return res.format(this.format)
				} else {
					return dayjs(this.modelValue).format(this.format)
				}
			}
			return this.modelValue
		},
	},
	watch: {
		modelValue(val) {
			if (val) {
				const dateValue = val
					?.split("-")
					.map((s) => s.padStart(2, "0"))
					.join("-")
				// ["YYYY", "YYYY-MM-DD"], "es" : in case not sure about the format date
				const res = dayjs(dateValue, ["YYYY", "YYYY-MM-DD"], "es", true)
				if (res.isValid()) {
					this.$emit("update:modelValue", res.format("YYYY-MM-DD"))
					this.inputDate = res.format(this.format)
				} else {
					if (this.validate(val, this.format)) {
						this.inputDate = dayjs(val).format(this.format)
					} else {
						this.inputDate = val
					}
				}
			}
		},
	},
	methods: {
		validate(date, format) {
			return dayjs(date, format).format(format) === date
		},
		onUpdateModelValue(value) {
			if (this.disabled) return
			if (this.mode === "single") {
				this.toggleCalendar()
			}
			this.$emit("update:modelValue", value)
		},
		keydown() {
			this.calendarVisible = false
		},
		toggleCalendar() {
			if (this.disabled) return
			this.calendarVisible = !this.calendarVisible
		},
		hideCalendar() {
			this.calendarVisible = false
		},
		handleFocus() {
			if (!this.modelValue) this.inputDate = ""
			this.calendarVisible = true
		},
		handleBlur() {
			const date = dayjs(this.inputDate, this.format, true)
			if (date.isValid()) {
				this.$emit("update:modelValue", date.format("YYYY-MM-DD"))
			} else {
				// in case inputDate is invalid, we also submit the value to validate
				this.$emit("update:modelValue", this.inputDate)
			}
		},
	},
	mounted() {
		if (this.modelValue) {
			const dateValue = this.modelValue
				?.split("-")
				.map((s) => s.padStart(2, "0"))
				.join("-")
			const res = dayjs(dateValue, this.format, true)
			if (res.isValid()) {
				this.$emit("update:modelValue", res.format("YYYY-MM-DD"))
				this.inputDate = res.format(this.format)
			} else {
				this.inputDate = dayjs(this.modelValue).format(this.format)
			}
		}
	},
}
</script>
<style scoped>
input:focus {
	outline: 0;
}
</style>
