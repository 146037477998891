<template>
	<JBox :class="cls">
		<JFlex class="px-5 items-center justify-between select-none cursor-pointer" @click="toggleOpen()">
			<JHeadline :as="headVariant" :variant="headVariant" class="flex text-c1-800">
				<JText class="text-c1-800">{{ title }}</JText>
				<JText v-if="showCount" class="ml-2 text-c0-300"> ({{ $t("core.count") }}: {{ numberOfField }}) </JText>
			</JHeadline>
			<JIcon class="text-c1-500" width="30" height="20" :icon="statusArrow" />
		</JFlex>
		<JBox :style="dropDownStyle">
			<slot></slot>
		</JBox>
	</JBox>
</template>

<script>
export default {
	name: "CAccordion",
	props: {
		title: {
			type: String,
			default: "",
		},
		showCount: {
			type: Boolean,
			default: false,
		},
		numberOfField: {
			type: Number,
			default: 0,
		},
		cls: {
			type: String,
			default: "rounded-lg shadow px-2 sm:px-4 py-8 bg-cWhite",
		},
		headVariant: {
			type: String,
			default: "h2",
		},
		defaultClose: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			isOpen: true,
		}
	},
	computed: {
		dropDownStyle() {
			return {
				transition: "margin 0.3s ease",
				marginTop: this.isOpen ? "2rem" : "0",
				maxHeight: this.isOpen ? "none" : "0",
				overflow: this.isOpen ? "" : "hidden",
			}
		},
		statusArrow() {
			return this.isOpen ? "ChevronUp" : "ChevronDown"
		},
	},
	mounted() {
		this.isOpen = !this.defaultClose
	},
	methods: {
		toggleOpen() {
			this.isOpen = !this.isOpen
		},
	},
}
</script>
