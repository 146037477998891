/* eslint-disable quotes */
export default {
	pages: {
		financialNeedsAnalysis: "Màn hình chính",
		library: "Thư viện",
		// Campaign Page
		promotion: "Chương trình ưu đãi",
		ourPromotions: "Chương trình khuyến mãi",
		promotionName1: "KHAI XUÂN BÌNH AN, NHẬN QUÀ NHƯ Ý (HẾT HẠN)",
		promotionheadline1: "Thời gian áp dụng: 01/01/2022 đến hết ngày 31/03/2022",
		promotionContent1: "Tặng tiền vào tài khoản của khách hàng hoặc quà tặng có giá trị tương đương",
		promotionName2: "ƯU ĐÃI DÀNH CHO CÁN BỘ, NHÂN VIÊN THAM GIA SẢN PHẨM BẢO HIỂM NHÂN THỌ (HẾT HẠN)",
		promotionheadline2: "Thời gian áp dụng: 01/01/2022 đến hết ngày 31/12/2022",
		promotionContent2:
			"Có bảo hiểm, có ngay ưu đãi hoàn tiền lên đến 35%/Phí bảo hiểm. Cùng Techcombank lựa chọn phong cách sống vững vàng cho hành trình trải nghiệm và tận hưởng cuộc sống thêm trọn vẹn!",
		// Product Page
		product: "Sản phẩm",
		ourProducts: "Sản phẩm của chúng tôi",
		productLibrary: "Thư viện sản phẩm",
		downloadBrochure: "Tải về",
		product1Name: "An Gia Như Ý",
		product1Headline: "Tâm an hiện tại, điểm tựa tương lai",
		product1Content:
			"Bạn sẽ an tâm khi cuộc sống được bảo vệ và tài sản được tích luỹ xuyên suốt 15 năm với thời gian đóng phí chỉ 6 năm hoặc 10 năm. An Gia Như Ý - giải pháp ưu việt kết hợp giữa bảo hiểm và tiết kiệm trong cùng một sản phẩm, đem đến sự an tâm tài chính trong mọi giai đoạn cuộc sống của bạn.",
		product2Name: "Bách Lộc Toàn Gia Vượt Trội",
		product2Headline: "Tài chính vẹn toàn, thảnh thơi vui sống",
		product2Content:
			"Cùng Bách Lộc Toàn Gia Vượt Trội vững vàng trong từng bước tiến về tương lai cũng như tận hưởng từng phút giây an lành của cuộc sống.",
		product3Name: "Đắc Lộc Tâm An (Sản phẩm đã ngừng phân phối)",
		product3Headline: "Sản phẩm bảo hiểm kết hợp với đầu tư",
		product3Content:
			"Với Đắc Lộc Tâm An, bạn có thể tham gia đầu tư gia tăng tài sản một cách dễ dàng, thuận tiện nhờ vào sự hỗ trợ từ các chuyên gia; đồng thời các kế hoạch tài chính vẫn được duy trì trước các rủi ro của cuộc sống.",
		product4Name: "An Nhi Bảo Phúc Ưu Việt (Sản phẩm đã ngừng phân phối)",
		product4Headline: "Bên con trên từng chặng đường học vấn",
		product4Content:
			"Lập gia đình và sinh con là những sự kiện quan trọng, mở ra một trang mới tuyệt vời trong cuộc sống của bạn. Với mong ước được cùng bạn chắp cánh cho những ước mơ con trẻ bay cao, Manulife và Techcombank hân hạnh giới thiệu giải pháp An Nhi Bảo Phúc Ưu Việt với nhiều quyền lợi đặc biệt.",
		mainProduct: "Sản phẩm chính",
		mainProductContent: "Tài liệu giới thiệu sản phẩm và điều khoản điều kiện",
		riders: "Sản phẩm bổ trợ",
		ridersContent: "Điều khoản điều kiện",
		search: "Tìm kiếm",
		productBrochure: "Tài liệu giới thiệu sản phẩm",
		rulesTermsSavings: "Quy tắc, điều khoản sản phẩm - Tiết kiệm",
		rulesTermsProtection: "Quy tắc, điều khoản sản phẩm - Bảo vệ",
		annualReport: "Báo cáo thường niên quỹ LKC 2020",
		// Forms Page
		referenceMaterial: "Tài liệu tham khảo",
		requestForm: "Biểu mẫu",
		requestForm0112: "Biểu mẫu thường dùng của Manulife",
		requestForm0210: "Biểu mẫu theo quy định của Techcombank",
		appraisalDocuments: "Tài liệu thẩm định",
		handbookOfContractAppraisalAndIssuance: "Sổ tay thẩm định và phát hành Hợp đồng",
		instructionsToOvercomeCommonEvaluationErrors: "Hướng dẫn khắc phục lỗi thẩm định thường gặp khi nộp HSYCBH",
		contactPointToResolveIssues: "Đầu mối giải quyết phát sinh",
		customerContractsPage: "Trang Hợp đồng của Khách hàng",
		customerContractsPageLink: "https://hopdongcuatoi.manulife.com.vn/login",
		customerHandbook: "Sổ tay Khách hàng",
		customerHandbookLink: "https://www.manulife.com.vn/vi/dich-vu/so-tay-khach-hang.html",
		unitPriceOfManulifeFund: "Giá đơn vị Quỹ Manulife",
		unitPriceOfManulifeFundLink: "https://www.manulife.com.vn/vi/san-pham-dau-tu/bao-hiem-dau-tu/gia-don-vi-quy.html",
		interestIndicators: "Các chỉ số lãi suất sản phẩm Manulife",
		interestIndicatorsLink: "https://www.manulife.com.vn/vi/bao-hiem-tiet-kiem/cac-chi-so-lai-suat.html",
		QLBHTheGift: "Bảo lãnh viện phí, giải quyết QLBH Món quà sức khỏe/Sống khỏe mỗi ngày",
		QLBHTheGiftLink: "https://insmart.com.vn/lien-he/",
		listOfClinicsNationwide: "Danh sách phòng khám trên toàn quốc",
		listOfClinicsNationwideContent: "Danh sách phòng khám toàn quốc (Cập nhật tháng 10.2020)",
		fundReport: "Báo cáo thường niên Quỹ",
		// Forms FAQ
		faq: "Câu hỏi thường gặp",
		faqFrequentlyAccountAccess: "Câu hỏi thường gặp về truy cập và tài khoản",
		accountAccessDevicesUsed: "Công cụ này dùng được trên những thiết bị nào?",
		accountAccessBrowsersUsed: "Công cụ này có thể sử dụng trên những trình duyệt nào?",
		accountAccessBrowsersUsedContent1:
			"Có thể sử dụng iTCBLife trên trình duyệt Chrome/ Firefox/ Safari/Microsoft Edge, KHÔNG sử dụng được trên trình duyệt Internet Explorer.",
		accountAccessBrowsersUsedContent2:
			"Trong trường hợp không truy cập được bằng trình duyệt đang sử dụng, vui lòng chuyển sang sử dụng bằng các trình duyệt khác.",
		userAndpassword: "User và mật khẩu đăng nhập của tôi là gì?",
		userAndpasswordContent1: "User: email Techcombank",
		userAndpasswordContent2: "Mật khẩu: mật khẩu đăng nhập máy tính",
		forgotMyPassword: "Trường hợp nếu tôi quên mật khẩu cần làm thế nào?",
		forgotMyPasswordContent1: "Nếu người dùng quên mật khẩu thì gửi yêu cầu reset lên IT Support",
		iPadDeviceForConsultation: "Khi sử dụng chung thiết bị iPad để tư vấn, tôi cần phải lưu ý những gì?",
		iPadDeviceForConsultationContent1: "Sau khi sử dụng, TVV lưu ý phải đăng xuất khỏi iTCBLife & tài khoản Microsoft",
		accountAccessUser: "Những ai được cung cấp user để thực hiện tư vấn trên iTCBlife?",
		accountAccessUserContent1: "Lực lượng tư vấn (PRM, RBO/SRBO, TIS) đã được cấp mã đại lý bảo hiểm của Manulife",
		accountAccessRegister: "Khi nào tôi sẽ được đăng ký user?",
		accountAccessRegisterContent1:
			"iTCBLife đăng ký định kỳ hàng tháng theo danh sách cập nhật từ Nhân sự (HR) với các bạn đã được cấp mã đại lý bảo hiểm của Manulife. Khi đăng ký thành công, TVV sẽ nhận được email thông báo từ hòm thư {email}",
		accountAccessDevicesUsedContent: "Ứng dụng có thể truy cập trên các thiết bị sau:",
		accountAccessDevicesUsedContent1: "- iPad, điện thoại thông minh",
		accountAccessDevicesUsedContent2:
			"- Laptop và máy tính bàn (Trường hợp sử dụng mạng ngoài TCB (public wifi) thì cần kết nối VPN)",

		faqInsuranceConsultationITCBLife: "Câu hỏi thường gặp về luồng tư vấn bảo hiểm trên iTCBlife",
		errorStep1: "Tại Bước 1, khi tìm kiếm thông tin Khách hàng, hệ thống báo lỗi thì tôi phải làm gì?",
		errorStep1Content1:
			"Trong trường hợp này, TVV thoát khỏi Bước 1 (Đóng hội thoại) và quay trở lại Trang chủ. Thực hiện tìm kiếm lại tại Bước 1.",
		howToCreateLead: "Làm cách nào để tạo/ gắn lead CRM với cuộc hội thoại iTCBlife?",
		howToCreateLeadContent: "TVV có thể thực hiện tạo/gắn lead CRM theo 1 trong các cách như sau:",
		howToCreateLeadContent1:
			"1. Bước 1 (Màn hình Thông tin khách hàng): Tìm kiếm khách hàng, chọn lead phù hợp trong danh sách lead được hiển thị",
		howToCreateLeadContent2:
			"2. Trường hợp chưa gắn lead tại bước 1, thực hiện tạo lead tại bước 7 (Màn hình Kết thúc) hoặc tại màn hình Dừng cuộc hội thoại giữa chừng",
		howToCreateLeadContent3:
			"3. Trường hợp chưa tạo/gắn lead theo 2 cách nêu trên, TVV có thể truy cập Màn hình Truy vấn và thực hiện gắn lead",
		howToCreateLeadContent4: "(Chi tiết tao tác thực hiện vui lòng xem hướng dẫn sử dụng iTCBLife)",
		saveInStep2: "Khi lựa chọn luồng tắt (shortcut) bằng cách ấn 'Lựa chọn giải pháp' tại bước 2 cần lưu ý những điểm gì?",
		saveInStep2Content1: "- Hệ thống sẽ đưa ra giải pháp tại Bước 6 với giá trị bảo vệ mặc định là 1 tỷ đồng",
		saveInStep2Content2:
			"- Sau khi khách hàng đồng ý với giải pháp, TVV cần kiểm tra/ điền lại các thông tin của khách hàng từ bước 3 'Am Hiểu Mục Tiêu'",
		customerWantsToAdjustBenefits: "Nếu Khách hàng muốn điều chỉnh quyền lợi thì TVV có thể làm gì?",
		customerWantsToAdjustBenefitsContent:
			"TVV có thể hỗ trợ Khách hàng điều chỉnh các giá trị sau tại chức năng Tùy chỉnh ở Bước 6:",
		customerWantsToAdjustBenefitsContent1: "- Thay đổi giá trị bảo vệ",
		customerWantsToAdjustBenefitsContent2: "- Thêm/bớt, điều chỉnh giá trị quyền lợi bổ trợ",
		customerWantsToAdjustBenefitsContent3: "- Thời gian thanh toán (Áp dụng riêng đối với An gia Như ý)",
		iTCBifeTool: "Khi sử dụng công cụ iTCBife , tư vấn viên có cần upload file Phân tích nhu cầu tài chính (FNA) lên ePOS không?",
		iTCBifeToolContent1:
			"Không, hệ thống sẽ tự động chuyển FNA đã thực hiện trên iTCBLife sang phần Tài liệu đính kèm trên Epos.",
		iTCBifeToolContent2: "KH không cần cung cấp chữ ký tươi đối với file FNA này.",
		TVVEmail: "Những trường hợp nào thì TVV nhận được email về cuộc hội thoại với Khách hàng?",
		TVVEmailContent: "Có 2 trường hợp:",
		TVVEmailContent1: "1. TVV chọn 'Kết thúc' thành công buổi tư vấn ở bước 7.",
		TVVEmailContent2: "2. TVV dừng giữa chừng buổi tư vấn, chọn 'Thoát và Lưu' thông tin cuộc hội thoại thành công",
		customerEmail: "Trường hợp nào KH nhận được Email/SMS từ hệ thống iTCBLife?",
		customerEmailContent:
			"Sau khi kết thúc cuộc tư vấn trên iTCBlife & đẩy thông tin sang hệ thống Manulife, KH sẽ nhận được email/SMS nếu KH thuộc 1 trong các trường hợp sau:",
		customerEmailContent1: "- KH có tài khoản mở tại TCB trước 5/10/2013 và không có FMB/TCB Mobile",
		customerEmailContent2: "- Có FMB/TCB Mobile mở trước ngày 5/10/2013 và không có tài khoản tại TCB",
		customerEmailContent3: "- Có tài khoản tại TCB và FMB/TCB Mobile mở trước ngày 5/10/2013",
		customerEmailContent4: "- Khách hàng không có tài khoản và FMB/TCB Mobile",
		customerEmailContent5: "- Khách hàng mới",
		iquiryScreen: "Trên màn hình 'Truy vấn', có thể thực hiện những thao tác gì?",
		iquiryScreenContent: "Có thể thực hiện các thao tác sau:",
		iquiryScreenContent1:
			"- Tìm kiếm cuộc hội thoại theo Ngày thực hiện, Mã hội thoại, Trạng thái, Kết quả tư vấn, Mã Lead CRM, Thông tin khách hàng",
		iquiryScreenContent2: "- Xem chi tiết cuộc hội thoại và tiếp tục các cuộc hội thoại còn dang dở",
		iquiryScreenContent3: "- Tạo/Gắn mã lead CRM với các cuộc hội thoại chưa có mã lead",
		iTCBLifeSupport: "iTCBLife có hỗ trợ tạo Bảng Minh Họa Quyền Lợi Sản Phẩm không?",
		iTCBLifeSupportContent:
			"iTCBLife chỉ  hỗ trợ tạo Bảng Minh Họa Quyền Lợi rút gọn với Sản phẩm An Gia Như ý. Hiện chưa có Bảng minh họa cho các sản phẩm còn lại.",
		createLeadInvite: "Có thể tạo lead gắn với chiến dịch trên iTCBLife ko?",
		createLeadInviteContent:
			"Hiện tại iTCBLife chưa hỗ trợ tạo lead gắn với chiến dịch kinh doanh. Trường hợp muốn gắn lead với chiến dịch, TVV vui lòng tạo lead trên CRM, gắn với đúng chiến dịch, để trạng thái 'KH hẹn gặp' hoặc 'KH đang cân nhắc'.  Sau đó, TVV truy cập iTCBlife và chọn lead đó để tiếp tục tư vấn",

		faqRuleAndChange: "Câu hỏi thường gặp về quy tắc sản phẩm và cách tính phí",
		solutionScreen: "Tại sao tại màn hình 'Giải pháp' bước 6 hệ thống báo không có sản phẩm phù hợp với khách hàng?",
		solutionScreenContent1:
			"- Vì mỗi sản phẩm có quy định về độ tuổi, giá trị bảo vệ, người tham gia… khác nhau nên trường hợp nếu thông tin Khách hàng điền không phù hợp với quy định các sản phẩm khác, Hệ thống  sẽ báo chưa có sản phẩm phù hợp với Khách hàng.",
		solutionScreenContent2: "- TVV cần Xem lại thông tin Khách hàng đã điền và điều khoản điều kiện sản phẩm để chỉnh sửa",
		customerAgeLessThan18: "Tại sao khi nhập tuổi của khách hàng nhỏ hơn 18 tuổi, hệ thống không đưa ra đề xuất sản phẩm nào?",
		customerAgeLessThan18Content1:
			"Theo quy định của Luật, chủ hợp đồng phải đủ 18 tuổi trở lên. Vì vậy, nếu khách hàng nhỏ hơn 18 tuổi, hệ thống sẽ không đưa ra đề xuất sản phẩm nào phù hợp. Riêng đối với sản phẩm An Gia Như Ý, độ tuổi của chủ hợp đồng phải từ 18-50 tuổi",
		policyholderAgeRegulation:
			"Quy định về tuổi của chủ hợp đồng đối với mục tiêu giáo dục là gì? Tại sao tôi chọn tuổi của chủ hợp đồng là 18 tuổi mà hệ thống vẫn không đề xuất ra sản phẩm nào?",
		policyholderAgeRegulationContent1:
			"Theo quy định của luật, tuổi kết hôn là 18 đối với nữ và 20 tuổi đối với nam. Do vậy, cần đảm bảo người bố/mẹ (Chủ hợp đồng) có con sau 2 mức tuổi nêu trên.",
		policyholderAgeRegulationContent2:
			"Ví dụ: Phụ nữ kết hôn năm 18 tuổi thì 19 tuổi là tuổi bắt đầu có con. Nếu số tuổi hiện tại của con là 3, thì người mẹ ít nhất phải là 21 thì mới đúng quy định của luật và hệ thống mới hiển thị sản phẩm phù hợp.",
		policyholderAgeRegulationContent3:
			"Tương tự với nam giới, nếu kết hôn năm 20 tuổi, thì 21 tuổi bắt đầu có thể có con. Nếu số tuổi hiện tại của con là 3, thì người cha ít nhất phải là 24 thì mới đúng quy định của luật và hệ thống mới hiển thị sản phẩm phù hợp",
		theSystemRecommendsProducts:
			"Tại sao hệ thống có đề xuất sản phẩm An Gia Như Ý nhưng khi click vào biểu tượng tạo bảng minh họa thì ko thể tạo được bảng minh họa?",
		theSystemRecommendsProductsContent:
			"Hiện tại, hệ thống chỉ hỗ trợ tạo Bảng Minh Họa trong trường hợp Số tiền bảo vệ nằm trong khoảng 100 triệu - 5 tỷ đồng. Do vậy, các trường hợp khác sẽ ko hiển thị lên BMH. Việc bổ sung sẽ được thực hiện trong các giai đoạn tiếp theo",
		aggregateStep:
			"Tại bước 'Tổng hợp' làm thế nào để hệ thống tính ra số tiền 'Mục Tiêu', 'Tiết kiệm', 'Thiếu hụt' của khách hàng?",
		aggregateStepContent1:
			"- Để tính ra số tiền 'Mục Tiêu' và 'Tiết kiệm' ,  hệ thống căn cứ theo thông tin khách hàng nhập ở bước 3 và 4 và theo công thức tính giá trị tương lai với số năm khách hàng cần sử dụng tiền hoặc tiết kiệm và theo mức lãi suất/ lạm phát dự kiến",
		aggregateStepContent2: "- Số tiền 'Thiếu hụt' = 'Mục tiêu' - 'Tiết kiệm' - 'Bảo hiểm'",
		aggregateStepContent3:
			"- Khách hàng có thể điều chỉnh số tiền bằng thay đổi các thông tin nhu cầu, số tiền tiết kiệm, bảo hiểm, lãi suất, lạm phát trên màn hình nếu cần",
		notedAboutPolicyholder: "Cần lưu ý gì về thông tin của Chủ hợp đồng và Người được bảo hiểm trên iTCBlife?",
		notedAboutPolicyholderContent1:
			"1. Đối với An gia Như ý, Bách lộc toàn gia vượt trội, Đắc lộc tâm an và SuperLink: Khách hàng vừa là chủ hợp đồng, vừa là Người được bảo hiểm.",
		notedAboutPolicyholderContent2: "2. Đối với An nhi Bảo phúc Ưu việt (EDU):",
		notedAboutPolicyholderContent2Sub1: "+ Khách hàng là chủ hợp đồng, con Khách hàng là Người được bảo hiểm thứ nhất",
		notedAboutPolicyholderContent2Sub2:
			"+ EDU chưa được áp dụng đối với trường hợp Khách hàng muốn bảo hiểm cho hơn một người con",
		notedAboutPolicyholderContent3:
			"=>  Do đó, thông tin của Người được bảo hiểm và Chủ hợp đồng sẽ được dựa trên những giả định này. Nguyên tắc sẽ được áp dụng theo điều kiện tương ứng của từng sản phẩm.",
		hospitalRoomClassOf750:
			"Tại sao khi tôi chọn hạng phòng nằm viện là 750 nghìn/ ngày, phần tổng quyền lợi cho hạng mục này tại Bước 6 lại là 125 triệu?",
		hospitalRoomClassOf750Content1:
			"Ngoài chi phí giường phòng với giới hạn 750 nghìn/ ngày, quyền lợi bổ trợ này còn có các hạng mục khác như xét nghiệm, phẫu thuật. Tổng quyền lợi lên đến 125 triệu.",
		TVVStep6: "Tại Bước 6, TVV cần lưu ý gì về Chi phí Hỗ trợ y tế ?",
		TVVStep6Content: "Tùy thuộc vào mức phí bảo hiểm KH tham gia để đưa ra quyền lợi trợ cấp y tế/nằm viện:",
		TVVStep6Content1: "• Không xét APE: ≤ 1 triệu",
		TVVStep6Content2: "• ≥ 20 triệu: < 2 triệu",
		TVVStep6Content3: "• ≥ 40 triệu: < 3 triệu",
		TVVStep6Content4: "• ≥ 80 triệu: 3 triệu",
		theSystemSuggestInsuranceValue: "Cách hệ thống đề xuất ra giá trị bảo hiểm ở phần giải pháp?",
		theSystemSuggestInsuranceValueContent:
			"Căn cứ vào số thiếu hụt ở Bước Tổng hợp, hệ thống sẽ đề xuất ra số tiền bảo hiểm tương ứng. Cụ thể:",
		theSystemSuggestInsuranceValueContent1:
			"- Trường hợp 1: Số tiền thiếu hụt > Tổng (Số tiền tích lũy + Số tiền bảo hiểm đã có), hệ thống sẽ đề xuất giải pháp có số tiền bảo hiểm bằng với số tiền thiếu hụt",
		theSystemSuggestInsuranceValueContent2:
			"- Trường hợp 2: Số tiều thiếu hụt < Tổng (Số tiền tích lũy + Số tiền bảo hiểm đã có), hệ thống sẽ đề xuất giải pháp có số tiền bảo hiểm bằng với số tiền tích lũy.",
		sumMoneyEdu: "Cách hệ thống tính ra số tiền mục tiêu 'Giáo dục'",
		sumMoneyEduContent:
			"Số tiền mục tiêu Giáo dục được tính dựa trên tổng mức học phí đại học/ sau đại học mà khách hàng cần cho con bao gồm lạm phát giáo dục. Lạm phát giáo dục tính từ thời điểm tư vấn KH đến thời điểm KH cần sử dụng Quỹ.",
		sumMoneyIncreaseAssets: "Cách hệ thống tính ra số tiền mục tiêu 'Gia Tăng Tài sản'",
		sumMoneyIncreaseAssetsContent:
			"Số tiền mục tiêu Gia tăng Tài sản được tính bằng trên số tiền tích lũy KH mong mong đạt được trong tương lai.",
		sumMoneyIncomeProtection: "Cách hệ thống tính ra số tiền mục tiêu 'Bảo vệ thu nhập'",
		sumMoneyIncomeProtectionContent:
			"Số tiền mục tiêu 'Bảo vệ thu nhập' bằng tổng giá trị trong tương lai của số chi phí hàng tháng cần đảm bảo * số năm cần hỗ trợ sinh hoạt phí và dư nợ hiện tại",
		sumMoneyHealthCare: "Cách hệ thống tính ra số tiền mục tiêu 'Chăm sóc sức khỏe'",
		sumMoneyHealthCareContent:
			"Số tiền mục tiêu 'Chăm sóc sức khỏe' được tính bằng tổng các nhu cầu của KH về Trợ cấp y tế, Chi phí y tế, Thương tật do tai nạn,  Điều trị bệnh hiểm nghèo. Trong đó:",
		sumMoneyHealthCareContent1: "- Trợ cấp y tế: Mức trợ cấp mất giảm thu nhập/ngày *1000 ngày",
		sumMoneyHealthCareContent2:
			"- Chi phí y tế: Số tiền bảo hiểm (nội trú) căn cứ theo hạng phòng BV khách hàng lựa chọn (Bạc, titan, vàng, bạch kim, kim cương)",
		sumMoneyHealthCareContent3: "- Thương tật và tai nạn: Căn cứ theo nhu cầu của KH",
		sumMoneyHealthCareContent4: "- Điều trị bệnh hiểm nghèo: Căn cứ theo nhu cầu của KH",
		sumMoneyPeacefulRetirement: "Cách hệ thống tính ra số tiền mục tiêu 'Hưu trí an nhàn'",
		sumMoneyPeacefulRetirementContent:
			"Số tiền mục tiêu 'Hưu trí an nhàn' bằng Giá trị trong tương lai của chi phí hàng tháng * số năm cần hỗ trợ chi phí sau sau khi nghỉ hưu",
		// Guidance Page
		guidance: "Hướng dẫn sử dụng",
		ourGuidance: "Hướng dẫn sử dụng iTCBlife",
		guidanceiTCBLife: "Hướng dẫn sử dụng iTCB Life",
		guideToStopConversation: "Hướng dẫn dừng cuộc hội thoại giữa chừng",
		guideTheBriefFlow: "Hướng dẫn luồng tắt",
		guideTheStraightFlow: "Hướng dẫn luồng tư vấn đầy đủ",
		guideTheEditFlow: "Hướng dẫn luồng truy vấn",

		// Contact Page
		contact: "Liên hệ",
		contactUs: "Liên hệ với chúng tôi",

		contactTechcombank: "Đầu mối hỗ trợ tại techcombank",
		contactTechcombankOption1: "Dự án số hóa Banca - iTCBlife",
		contactTechcombankOption1Content: "Hỗ trợ giải đáp thông tin, hướng dẫn sử dụng liên quan hệ thống iTCBlife",
		contactTechcombankOption2: "BỘ PHẬN HỖ TRỢ KHIẾU NẠI DỊCH VỤ SAU BÁN",
		contactTechcombankOption2Content: "Tiếp nhận, giải quyết các trường hợp khách hàng khiếu nại, khiếu kiện…",
		contactTechcombankOption3: "BỘ PHẬN HỖ TRỢ VỀ SẢN PHẨM/CHƯƠNG TRÌNH ƯU ĐÃI",
		contactTechcombankOption3Content: "Giải đáp thông tin về các sản phẩm bảo hiểm và chương trình ưu đãi cho KH",
		contactTechcombankOption4: "BỘ PHẬN HỖ TRỢ VỀ ĐÀO TẠO",
		contactTechcombankOption4Content1: "- Giải đáp về kế hoạch dào tạo MIT/Cấp code",
		contactTechcombankOption4Content2: "- Giải đáp về AWS (Chạy Bảng minh họa/Code chung)",
		contactTechcombankOption4Content3: "- Các khóa học đào tạo online trên Manulearn",
		contactTechcombankOption5: "ĐẦU MỐI HỖ TRỢ NHẬP LIỆU TRÊN AWS",
		contactTechcombankOption5Content: "",
		contactTechcombankOption6: "BỘ PHẬN HỖ TRỢ GIẢI ĐÁP VỀ CHÍNH SÁCH KPI",
		contactTechcombankOption6Content: "Thông báo, điều chỉnh, giải đáp về chính sách KPI",
		contactTechcombankOption7: "BỘ PHẬN HỖ TRỢ VỀ TECHCOMBANK APP",
		contactTechcombankOption7Content1: "Truy vấn HĐBH trên { name } Mobile/Techcombank mobile",
		contactTechcombankOption7Content2: "Thông tin thanh toán phí, trích phí tự động trên { name } Mobile/Techcombank mobile",

		contactMVL: "Đầu mối hỗ trợ Manulife",
		contactMVLOption1: "ĐẦU MỐI HỖ TRỢ CHUNG",
		contactMVLOption1Content: "Các vấn đề liên quan đến nhập dữ liệu lên AWS, hợp đồng in lại, in thẻ y tế…)",
		contactMVLOption1ContentHotline: "Hotline cho Tư vấn viên Techcombank : { phone } (#9 > #5)",
		contactMVLOption2: "BỘ PHẬN HỖ TRỢ KÊNH PHÂN PHỐI BH",
		contactMVLOption2Content1: "- Thực hiện hỗ trợ công việc hành chính",
		contactMVLOption2Content2: "- Giải đáp thắc mắc cho NVNH liên quan đến thông tin/tình trạng hợp đồng..",
		contactMVLOption2Content3: "- Nhận hồ sơ, đăng ký học MIT cho NVNH",
		contactMVLOption2ContentHotline: "Hotline: { phone } (#9 > #5)",
		contactMVLOption3: "BỘ PHẬN THẨM ĐỊNH HĐ MỚI",
		contactMVLOption3Content: "Thực hiện thẩm định HSYCBH bao gồm đơn YCBH và các giấy tờ liên quan khác",
		contactMVLOption4: "BỘ PHẬN GIẢI QUYẾT KHIẾU NẠI",
		contactMVLOption4Content: "Tiếp nhận, giải quyết các trường hợp khách hàng khiếu nại, khiếu kiện…",
		contactMVLOption5: "BỘ PHẬN GIẢI QUYẾT QLBH CHO KH",
		contactMVLOption5Content: "Thực hiện giải quyết QLBH cho KH khi xảy ra sự kiện bảo hiểm",
		contactMVLOption5ContentHotline: "Hotline cho Tư vấn viên Techcombank : { phone } (#9 > #2)",
		contactMVLOption6: "BỘ PHẬN ĐIỀU CHỈNH HỢP ĐỒNG",
		contactMVLOption6Content1: "Áp dụng cho cả Khách hàng và TVV",
		contactMVLOption6Content2: "Thực hiện ghi nhận phí thanh toán cho HĐ",
		contactMVLOption6Content3: "Thực hiện điều chỉnh, HĐBH, thanh toán các quyền lợi cho NĐBH...",
		contactMVLOption7: "ĐẦU MỐI LIÊN HỆ KHI CẦN BẢO LÃNH VIỆN PHÍ",
		contactMVLOption7Content: "Liên hệ Công ty TNHH Insmart - Bộ phận Chăm sóc Khách hàng",
		contactMVLOption7ContentHotline: "Hotline: { phone }",
		contactMVLOption8: "ĐẦU MỐI LIÊN HỆ CHO KHÁCH HÀNG THAM GIA BH QUA TECHCOMBANK",
		contactMVLOption8Content: "",
		contactMVLOption8ContentHotline: "Hotline cho Tư vấn viên Techcombank : { phone } (bấm phím 1, sau đó bấm phím 1)",
		contactMVLOption9: "BỘ PHẬN DỊCH VỤ KHÁCH HÀNG",
		contactMVLOption9Content:
			"Tiếp nhận hồ sơ yêu cầu bảo hiểm, các giấy tờ bổ sung - Tư vấn, giải đáp mọi thắc mắc của KH liên quan đến HĐBH",
		contactMVLOption10: "HỖ TRỢ EPOS/AWS/MANULEARN",
		contactMVLOption10Content: "Hỗ trợ giải đáp các lỗi, thông tin liên quan hệ thống ePOS/AWS/Manulearn",
		contactMVLOption10ContentHotline: "Hotline: { phone }",
		contactMVLOption11: "HỖ TRỢ POS",
		contactMVLOption11Content1: "- Máy hỏng phần mềm, lỗi kỹ thuật, hết mực, hết giấy",
		contactMVLOption11Content2: "- Máy hỏng, rơi vỡ, thất lạc",

		contactInfoSub: "Dự án số hóa Banca: ",
		// About Us Page
		aboutUs: "Về chúng tôi",
		aboutTcb: "Về Techcombank",
		tcbMission: "Thông điệp từ Tổng Giám Đốc Techcombank",
		tcbMsgFromVicePresident: "Thông điệp từ Phó Tổng Giám Đốc Thường Trực Techcombank",
		aboutManulife: "Về Manulife",
		manulifeContent: `Manulife Việt Nam là doanh nghiệp bảo hiểm nhân thọ nước ngoài đầu tiên có mặt tại Việt Nam từ năm 1999 và hiện là Công ty Bảo hiểm Nhân thọ lớn nhất Việt Nam.
		Tháng 09/2017, Manulife và Techcombank chính thức công bố việc ký kết thoả thuận hợp tác độc quyền phân phối sản phẩm bảo hiểm Manulife qua mạng lưới TCB kéo dài 15 năm.`,
		// Inquiry
		inquiry: "Tra cứu hội thoại",
		product5Name: "Super Link",
		product5Headline: "Bảo hiểm liên kết đầu tư",
		product5Content:
			"Mong muốn được đồng hành cùng bạn trên hành trình bước đến một tương lai phú quý bền vững, Techcombank và Manulife hợp tác cùng cho ra mắt sản phẩm Bảo hiểm liên kết Đầu tư SUPERLINK, một giải pháp bảo hiểm kết hợp đầu tư ưu việt, cam kết đem đến những quyền lợi vượt trội. ",
		// Following text of tcb-1.5 (terminal)
		product1TC: "Quy tắc, điều kiện điều khoản sản phẩm",
		product1AnnualReport: "Báo cáo thường niên quỹ LKC 2020",
		product2TCSaving: "Quy tắc, điều kiện điều khoản sản phẩm, Tiết kiệm",
		product2TCProtection: "Quy tắc, điều kiện điều khoản sản phẩm, Bảo vệ",
		product2AnnualReport: "Báo cáo thường niên quỹ LKC 2020",
		unitLinkedTC: "Quy tắc, điều kiện điều khoản sản phẩm",
		unitLinkedAnnualReport: "Báo cáo thường niên quỹ LKĐV 2020",
		unitLinkedUnitPrice: "Cập nhật giá đơn vị quỹ LKĐV",
		eduTC: "Quy tắc, điều kiện điều khoản sản phẩm",
		superLinkTC: "Quy tắc, điều kiện điều khoản sản phẩm",
		superLinkAnnualReport: "Báo cáo thường niên quỹ LKĐV 2020",
		superLinkUnitPrice: "Cập nhật giá đơn vị quỹ LKĐV",
		criticalIllness: "Bảo Hiểm Bệnh Lý Nghiêm Trọng",
		criticalIllnessUDR: "Bảo hiểm bệnh lý nghiêm trọng nâng cao - Khấu trừ phí rủi ro",
		criticalIllnessPlusUDR: "Bảo hiểm bệnh lý nghiêm trọng mở rộng - Khấu trừ phí rủi ro",
		criticalIllnessShortTerm: "Bảo hiểm bệnh lý nghiêm trọng đóng phí ngắn hạn",
		cancerUDR: "Bảo hiểm bệnh ung thư giai đoạn cuối - Khấu trừ phí rủi ro",
		medicalCash: "Bảo Hiểm Trợ Cấp Y Tế",
		medicalCashUDR: "Bảo hiểm trợ cấp y tế nâng cao",
		medicalCashPlus: "Bảo hiểm trợ cấp y tế mở rộng",
		medicalCash2014: "Bảo hiểm trợ cấp y tế 2014",
		medicalCashShortTerm: "Bảo hiểm trợ cấp y tế đóng phí ngắn hạn",
		accidentDeathAndDisablement: "Bảo Hiểm Tử Vong Và Thương Tật Do Tai Nạn",
		accidentDeathAndDisablementUDR: "Bảo hiểm tử vong và thương tật do tai nạn UDR",
		accidentDeathAndDisablementRPD: "Bảo hiểm tử vong và thương tật do tai nạn nâng cao - khấu trừ phí rủi ro",
		accidentDeathAndDisablementShortTerm: "Bảo hiểm tử vong và thương tật do tai nạn đóng phí ngắn hạn",

		premiumWaive: "Bảo Hiểm Hỗ Trợ Đóng Phí",
		premiumWaiveDeductBMBH: "Bảo hiểm hỗ trợ đóng phí cho BMBH hoặc người hôn phối - Khấu trừ phí rủi ro",
		premiumWaiveSpouse: "Bảo hiểm hỗ trợ đóng phí cho NĐBH - Khấu trừ phí rủi ro",
		premiumWaiveWaiveDeduction: "Bảo hiểm miễn nộp phí",
		premiumWaiveCIAnnualRenew: "Bảo hiểm miễn nộp phí đặc biệt",
		premiumWaiveTDAnnualRenew: "Bảo hiểm miễn nộp phí cho bên mua bảo hiểm",
		premiumWaiveTDCIAnnualRenew: "Bảo hiểm miễn nộp phí đóng phí ngắn hạn",
		premiumWaiveForPO: "Bảo hiểm hỗ trợ đóng phí do BLNT của NĐBH",

		healthcare: "Sống Khỏe Mỗi Ngày",
		healthcareTC: "Quy tắc, điều khoản sản phẩm",
		healthcareBrochure: "eBook",
		promotionName3: "ƯU ĐÃI DÀNH CHO CÁN BỘ NHÂN VIÊN CỦA TẬP ĐOÀN VINGROUP, MASAN, ONE MOUNT (HẾT HẠN)",
		promotionheadline3: "Thời gian áp dụng: 14/01/2022 đến hết ngày 14/04/2022",
		promotionContent3: "Ưu đãi dành riêng cho cán bộ nhân viên của đối tác.",

		TotalPermanentDisability: "Bảo hiểm thương tật toàn bộ vĩnh viễn",
		TotalPermanentDisabilityShortTermFeePayment: "Bảo hiểm thương tật toàn bộ vĩnh viễn - Đóng phí ngắn hạn",
		TotalPermanentDisabilityDeductTheRiskFee: "Bảo hiểm thương tật toàn bộ vĩnh viễn - Khấu trừ phí rủi ro",

		DeathBenefitCoverage: "Bảo hiểm tử vong",
		DeathBenefitCoverageShortTermFeePayment: "Bảo hiểm tử vong - Đóng phí ngắn hạn",
		DeathBenefitCoverageLimitTime: "Bảo hiểm tử vong có thời hạn",

		// promotion page
		promotionName4: "ƯU ĐÃI NGẬP TRÀN, YÊU THƯƠNG VẸN TOÀN (HẾT HẠN)",
		promotionheadline4: "Thời gian áp dụng: 01/04/2022 đến hết ngày 30/06/2022",
		promotionContent4: `Hãy cùng Techcombank gói gọn yêu thương trong món quà sức khỏe là bảo hiểm nhân thọ gửi đến gia đình và người thân. Đặc biệt hơn, kể từ 01/04/2022, khi tham gia bảo hiểm nhân thọ, Quý khách sẽ được nhận ưu đãi hoàn tiền đến 20% phí bảo hiểm.`,

		prenatalTitle: "Bảo hiểm trợ cấp thai sản",

		// Legacy (updated on July 2022)
		legacyProductName: "Legacy",
		legacySubtitle: "Điểm tựa toàn diện – Cho vạn bước tiến",
		legacyContent:
			"Giải pháp đầu tư tài chính linh hoạt, khoản dự phòng cho sức khỏe và gia sản; giúp bạn luôn tiến lên phía trước đồng thời vững vàng điểm tựa phía sau.",
		legacyAnnualReport: "Báo cáo thường niên quỹ LKĐV 2021",

		// Material Reference (Library page)
		mainMaterialReference: "Tài liệu về các tình huống bán hàng thành công",
		introductionMaterialReference: "Giới thiệu về bộ tài liệu",
		introductionVideo: "Video giới thiệu",
		typicalCases: "Các tình huống điểm hình",
		bancaLending: "01. BANCA LENDING - Nguyễn Hải Đăng - AFF - Chủ doanh nghiệp - 2,5 tỷ đồng",
		bancaLendingOption1: "Chân dung khách hàng & Hướng dẫn tư vấn",
		bancaLendingOption2: "Bảng minh hoạ",
		bancaLendingOption3: "Video tóm tắt",
		bancaMH: "02. BANCA MH - Lưu Quang Phương - AFF - Chủ doanh nghiệp - 922trđ (3HĐ)",
		bancaMHOption1: "Chân dung khách hàng & Hướng dẫn tư vấn",
		bancaMHOption2: "Bảng minh hoạ",
		bancaMHOption3: "T&C Masterise Homes lần 3 (1.12.2021)",
		bancaMH02: "03. BANCA MH - Trần Kiều Loan - AFF - Chủ doanh nghiệp - 450trđ (2HĐ)",
		bancaMH02Option1: "Chân dung khách hàng & Hướng dẫn tư vấn",
		bancaMH02Option2: "Bảng minh hoạ",
		bancaMH02Option3: "T&C Masterise Homes lần 3 (1.12.2021)",
		bancaMH02Option4: "Video tóm tắt",
		bancaMH03: "4. BANCA MH - Lê Nguyên Trung - AFF - Chủ doanh nghiệp - 464trđ (2HĐ)",
		bancaMH03Option2: "Bảng minh họa bố + con lớn",
		bancaMH03Option3: "Bảng minh họa mẹ + con nhỏ",
		bancaTD: "05. BANCA TD- Nguyễn Huy Toàn - AFF - Chủ doanh nghiệp - 100trđ",
		bancaTDOption2: "Bảng minh họa APE 44",
		bancaTDOption3: "Bảng minh họa APE 56",
		bancaTDOption4: "T&C Banca TD",
		bancaDreamCity: "06. BANCA DREAM CITY - Hoàng Vân Trinh - AFF - Chủ doanh nghiệp - 100trđ",
		bancaDreamCityOption2: "Bảng minh họa APE 64",
		bancaDreamCityOption3: "Bảng minh họa APE 100",
		bancaDreamCityOption4: "T&C Vin Dream City",
		bancaDreamCity2: "07. BANCA DREAM CITY - Trần Huyền My - AFF (VIP2) - Kế toán trưởng - 100trđ",
		bancaLending2: "08. BANCA LENDING - Hoàng Tú Linh - AFF (VIP3) - Chủ doanh nghiệp - 30trđ",
		bancaLending2Option3: "T&C Banca Lending",
		bancaLending3: "09. BANCA LENDING - Trần Minh Quang - MAFF - Làm công ăn lương - 30trđ",
		bancaTD2: "10. BANCA TD - Lê Thanh Trà- MASS - Nhân viên văn phòng - 25trđ",
		bancaTD3: "11. BANCA TD - Nguyễn Diệu Minh - AFF (VIP2) - Chủ doanh nghiệp - 50trđ",
		bancaTD4: "12. BANCA TD - Nhâm Mạnh Dũng - MAFF - Lãnh đạo công ty - 100trđ+ 400trđ top-up",
		bancaTD4Option4: "T&C Cashback Q2.2022",

		// Campaign page
		promotionName5: "ƯU ĐÃI NGẬP TRÀN, YÊU THƯƠNG VẸN TOÀN (HẾT HẠN)",
		promotionHeadline5: "Thời gian áp dụng: 07/07/2022 đến hết ngày 30/09/2022",
		promotionContent5: "Tặng tiền vào tài khoản của khách hàng hoặc quà tặng có giá trị tương đương",

		// Material Reference Info
		introductionMaterialWithFullPackage: "Bộ tài liệu đầy đủ",

		// Guidance page
		guideTheHomePage: "Hướng dẫn chức năng ở màn hình trang chủ",

		// Titanium 1.1 (new updated 2023)
		titanium11ProductName: "An Tâm Vững Bước",
		titanium11Subtitle: "An tâm sống trọn – Vững bước tương lai",
		titanium11Content:
			"An Tâm Vững Bước sẽ là người bạn đồng hành giúp bạn tự tin vững bước vươn xa và tận hưởng từng phút giây của cuộc sống.",
		titanium11Rider: "Bảo hiểm bệnh Ung thư giai đoạn cuối",

		// Campaign page
		promotionName6: "ƯU ĐÃI HOÀN TIỀN (HẾT HẠN)",
		promotionHeadline6: "Thời gian áp dụng: {startDate} đến hết {endDate}",
		promotionContent6: "Tặng tiền vào tài khoản của khách hàng hoặc quà tặng có giá trị tương đương",

		// Campaign page
		promotionName7: "ƯU ĐÃI HOÀN TIỀN",
		promotionHeadline7: "Thời gian áp dụng: {startDate} đến hết {endDate}",
		promotionContent7: "Tặng tiền vào tài khoản của khách hàng hoặc quà tặng có giá trị tương đương",
	},
}
