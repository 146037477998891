import {
	LEAD_DEPARTMENT,
	FRONTLINE_BY_SOURCE,
	BRANCH_MANAGER,
	INSURANCE_EXPERT_USER,
	USERS_DOES_NOT_HAVE_REGION,
	USERS_EMPTY_SOURCE,
	HO_MANAGER,
	USER_ROLES,
	REGIONAL_MANAGER,
	AREA_MANAGER,
	FRONTLINE_DRBO,
} from "@/modules/dashboard/utils/constant"

import { useStore } from "vuex"

export default function useSourceOfLoginUser() {
	const store = useStore()
	const me = store?.getters?.me
	const userLoginId = me?.id
	const userLoginIsAdmin = Boolean(me?.associatedUser === null)
	const associatedUser = me?.associatedUser ? me.associatedUser : {}
	const userSource = splitUserSource(associatedUser?.source)
	const userPosition = getUserPositionBySource(associatedUser?.source)

	const userArea = getAreaByUserPosition({ userPosition, source: userSource })
	const userRegion = getRegionByUserPosition({ userPosition, source: userSource })
	const userBranch = getUserBranchBySource({ userPosition, source: userSource })
	const userLocatedByBranchOrArea = getUserLocatedByBranchOrArea({ userBranch, userPosition, source: userSource })

	const userLoginIsDRBO = userPosition === FRONTLINE_DRBO

	return {
		userLoginId,
		userPosition,
		userArea,
		userBranch,
		userRegion,
		userLocatedByBranchOrArea,
		userLoginIsAdmin,
		userLoginIsDRBO,
	}
}
export function splitUserSource(source) {
	if (!source) return ""
	return source.split("-")
}
export function getUserPositionBySource(source) {
	if (!source) return ""
	const splitSource = splitUserSource(source)
	return splitSource[splitSource.length - 1]
}
export function getUserBranchBySource({ userPosition, source }) {
	if (!source) return ""
	if (userPosition === BRANCH_MANAGER || LEAD_DEPARTMENT.includes(userPosition) || FRONTLINE_BY_SOURCE.includes(userPosition)) {
		return source[2]
	}

	return undefined
}

export function getAreaByUserPosition({ userPosition, source }) {
	if (USERS_EMPTY_SOURCE.includes(userPosition)) return undefined
	else return source[0]
}

export function getRegionByUserPosition({ userPosition, source }) {
	if (USERS_EMPTY_SOURCE.includes(userPosition) || USERS_DOES_NOT_HAVE_REGION.includes(userPosition)) {
		return undefined
	} else return source[1]
}

export function getUserLocatedByBranchOrArea({ userBranch, userPosition, source }) {
	if (!userBranch && userPosition === INSURANCE_EXPERT_USER.NewTIS) {
		const userArea = source[0]
		return userArea
	} else return userBranch
}

export function combineSource({ area, region, branch, position }) {
	if (!position) return ""

	/**
	 * position === "hoManager", position === "executive", position === "security" -> source formatted = [position]
	 * position === "areaManager" -> source formatted = [area, position]
	 * position === "regionalManager" -> source formatted = [area, region, position]
	 * position === "branchManager" -> source formatted = [area, region, branch, position]
	 * position === "NewTIS" -> source formatted = [area, position]
	 * other positions is formatted default = [area, region, branch, position]
	 */
	let source = null

	switch (position) {
		case HO_MANAGER:
		case USER_ROLES.EXECUTIVE:
		case USER_ROLES.SECURITY:
			source = position
			break
		case AREA_MANAGER:
		case INSURANCE_EXPERT_USER.NewTIS:
			source = [area, position].filter((item) => !!item).join("-")
			break
		case REGIONAL_MANAGER:
			source = [area, region, position].filter((item) => !!item).join("-")
			break

		default:
			source = [area, region, branch, position].filter((item) => !!item).join("-")
			break
	}
	return source
}
