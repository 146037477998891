<template>
	<div :class="$attrs.class" :style="$attrs.style">
		<!-- Form control inner -->
		<div :class="classes.wrapper">
			<slot name="label" :field="field" :classes="classes">
				<label v-if="label" :for="field" :class="classes.label">
					{{ label }}
					<span v-if="required" class="text-c1-600">*</span>
				</label>
			</slot>
			<!-- Input Wrapper -->
			<div :class="[classes.inner]">
				<!-- Icon Prefix -->
				<slot name="prefix">
					<JBox
						v-if="iconPrefix"
						:class="isFocus ? 'text-c1-500' : 'text-c0-300'"
						class="absolute flex items-center pointer-events-none py-2 px-5 left-0"
						style="top: 50%; transform: translateY(-50%)"
						:style="customIconStyle"
					>
						<JIcon width="24" height="24" :icon="iconPrefix" />
					</JBox>
				</slot>
				<!-- Component for input -->
				<component
					:is="componentName"
					:id="id || field"
					ref="control"
					:class="[classes.input, componentInputClass]"
					:placeholder="placeholder"
					:modelValue="modelValue"
					:options="options"
					:disabled="disabled"
					autocomplete="off"
					v-bind="filteredAttrs"
					@focus="handleFocus"
					@blur="handleBlur"
					:tabindex="tabindex"
				/>
				<span v-if="isShowUnit" class="absolute cursor-pointer unit">{{ customUnit }}</span>
				<!-- Icon Suffix -->
				<slot name="suffix">
					<JBox
						v-if="iconSuffix"
						:class="isFocus ? 'text-c0-500' : 'text-c0-300'"
						class="absolute flex items-center pointer-events-none py-2 px-3 right-0"
						style="top: 50%; transform: translateY(-50%)"
					>
						<JIcon width="24" height="24" :icon="iconSuffix" />
					</JBox>
				</slot>

				<!-- Custom input tips/message -->
				<slot></slot>
			</div>
		</div>

		<!-- Error message -->
		<JBox v-if="isDirty && hasError" class="mt-2" :class="classes.messages">
			<JBox>
				<!-- Goes through messages and if message exists in validation definition, it will display message based on if it's valid or not -->
				<JText v-for="key in Object.keys(messages)" :key="key" :class="[classes.message]">
					{{ getMessage(getField, key) }}
				</JText>
			</JBox>
		</JBox>
	</div>
</template>

<script>
import get from "lodash/get"
import CInputNumber from "./CInputNumber"
import CInputSearchSelect from "./CInputSearchSelect"
import { useVariant } from "@/composables/variant"

export default {
	inheritAttrs: false,
	name: "CFormInput",
	components: { CInputNumber, CInputSearchSelect },
	props: {
		/**
		 * @description Name of the component to render
		 * @example JInputText
		 * @supportComponents
		 *     JInputText
		 *     JInputNumber
		 *     JInputLongText
		 *     JSelect
		 *     JMultiSelect
		 *     JRadios
		 *     JToggle
		 *     JDatePicker
		 */
		componentName: {
			type: String,
			required: true,
			default: "CInputNumber",
		},
		/**
		 * @description Controller disable state
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * @description Dot notation of the field we are supposed to process. This field is also used for id for label
		 * @example "clients[1].name"
		 */
		field: {
			type: null,
			default: null,
		},

		id: {
			type: null,
			default: null,
		},
		/**
		 * @description Instance of vue-lidate from parent
		 * Simply in parent, we refer to it as $v
		 */
		validator: {
			type: Object,
			default: () => {},
		},
		/**
		 * @description Controller label
		 */
		label: {
			type: String,
			default: "",
		},
		required: {
			type: Boolean,
			default: false,
		},
		description: {
			type: String,
			default: "",
		},
		/**
		 * @description Controller options
		 */
		options: {
			type: Array,
			default: () => [],
		},
		/**
		 * @description Controller value
		 */
		modelValue: {
			required: false,
		},
		allowSelectNothing: {
			type: Boolean,
			required: false,
			default: true,
		},
		placeholder: {
			type: String,
			default: "",
		},
		iconPrefix: {
			type: String,
			default: "",
		},
		iconSuffix: {
			type: String,
			default: "",
		},
		/**
		 * @description Component theme (mostly for label color)
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		customIconStyle: {
			type: String,
		},
		tabindex: {
			type: [String, Number],
		},
		isShowUnit: {
			type: Boolean,
			default: false,
		},
		customUnit: {
			type: String,
			default: "",
		},
		fieldVariant: {
			type: String,
			default: "primary",
		},
	},
	setup(props) {
		const { classes } = useVariant("CFormInput", {
			variant: props.fieldVariant,
		})

		return {
			classes,
		}
	},
	data() {
		return {
			isFocus: false,
			messageKeys: [
				"required",
				"requiredIf",
				"email",
				"age",
				"hkid",
				"alpha",
				"alphaNum",
				"maxLength",
				"minLength",
				"sameAs",
				"noNumber",
				"phone",
				"validAge",
				"validChildAge",
				"validPercent",
				"minNumber",
				"maxNumber",
				"validNumber",
				"healthRoomBoardAmount",
				"restricLeadGCM",
				"restricPassword",
				"maxLengthTitle",
				"maxLengthSubTitle",
				"errorMessageFullName",
				"alphaWithSpace",
				"errorMessagePhoneNumber",
				"minAge",
				"maxAge",
				"errorDob",
				"invalidIssuedDate",
				"requiredToInputAgentCode",
				"invalidAmount",
				"invalidDirectReferralCode",
				"selectAtLeast1Agent",
				"fullNameMaxLength",
				"errorMessageUserFullName",
				"directReferralAgentCodeMaxLength",
				"investmentPreferenceWarningMessage",
				"productIsNotSuitableToInvestmentPreference",
				"expectedPremiumPaymentWarning",
				"exceedMaxLength",
				"duplicateStaffId",
				"duplicateUsername",
				"thisFieldIsRequired",
				"incorrectFormatMail",
			],
		}
	},
	computed: {
		filteredAttrs() {
			const newAttrs = { ...this.$props, ...this.$attrs }
			delete newAttrs.class
			delete newAttrs.style
			delete newAttrs.label
			return newAttrs
		},
		messages() {
			const result = {}
			this.messageKeys.forEach((key) => {
				result[key] = this.$t(`core.${key}`)
			})
			return result
		},
		/**
		 * Based on field address it will return field from validator object
		 */
		getField() {
			return get(this.validator, this.field)
		},
		/**
		 * Check if the field was already touched by user
		 */
		isDirty() {
			if (Array.isArray(this.field)) {
				return get(this.validator, [...this.field, "$dirty"])
			}
			return get(this.validator, `${this.field}.$dirty`)
		},
		/**
		 * Check if the field has errors
		 */
		hasError() {
			if (Array.isArray(this.field)) {
				return get(this.validator, [...this.field, "$error"])
			}
			return get(this.validator, `${this.field}.$error`)
		},
		componentInputClass() {
			const classes = []
			if (this.iconPrefix) classes.push("pl-16")
			else if (this.iconSuffix) classes.push("pr-10")

			if (this.disabled) classes.push("cursor-not-allowed")

			return classes.join(" ")
		},
	},
	methods: {
		getMessage(fieldObject = {}, key = "") {
			const found = Object.keys(fieldObject).find((item) => item === key)
			if (found) {
				return fieldObject[key].$invalid ? this.interpolate(this.messages[key], fieldObject, key) : null
			} else return ""
		},

		/**
		 * String interpolation when we replace {0} with a specific parameter
		 */
		interpolate(str, fieldObject, key) {
			// Go to $params[key] and get possible values that will be added to the str
			const values = fieldObject[key].$message

			// Some validators don't have values, in that case return str
			if (!values) return str

			// Find all {} to replace
			const reg = /({.*?})/gi
			const toReplace = str.match(reg)
			if (!toReplace) return str

			// Replace all {} with values
			let output = str

			// function to allow retrieving nested values
			function findValueFromKey(key, values) {
				return key
					.replace(/{|}/g, "")
					.split(".")
					.reduce((acc, cur) => acc[cur], values)
			}
			toReplace.forEach((item) => {
				// const valueKey = item.replace("{", "").replace("}", ""); // not needed with fn
				output = output.replace(item, findValueFromKey(item, values)) // replaces {count} with passed parameter e.g. 2
			})

			return output
		},
		handleFocus() {
			this.isFocus = true
		},
		handleBlur() {
			this.isFocus = false
		},
	},
}
</script>
<style scoped>
.unit {
	background-color: #e9e9e9;
	color: #7b7b7b;
	padding: 7px;
	text-align: center;
	width: 40px;
	height: 38px;
	right: 1px;
	top: 1px;
	border: 0 solid transparent;
	border-radius: 0px 7px 7px 0px;
	text-decoration: underline;
}
</style>
