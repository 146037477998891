<template>
	<JBox class="mb-8">
		<CAccordion :title="$t('core.premium')">
			<JBox>
				<!-- Premium Info -->
				<CEditableField v-for="(p, idx) in premiumInfo" :key="idx" :label="p.label" :value="p.value" isReadOnly />
				<!-- Discount Codes -->
				<JBox class="px-5 mt-8">
					<JFlex class="justify-between items-center">
						<JHeadline as="h3" variant="h3" class="text-c1-800">
							{{ $t("core.discountCodes") }}
						</JHeadline>
						<JButton v-if="isEditable" variant="rounded-small" @click="handleAddDiscount">
							<JIcon icon="Plus" class="text-c1-500" />
						</JButton>
					</JFlex>
					<JBox class="mt-6">
						<JBox v-for="(discount, idx) in discounts" :key="idx" class="text-c0-500 font-medium mb-2">
							<CEditableField :value="null" :canDelete="true" :canEdit="false" @delete="handleRemoveDiscount(discount)">
								<template #label>
									<JBox class="relative">
										<JText> {{ discount?.name || "-" }} </JText>
										<JText class="absolute top-0 left-0 font-light" style="transform: translateX(-1.1rem)">
											{{ discount?.order ?? "" }}.
										</JText>
									</JBox>
								</template>
								<template #value>
									<JBox class="relative">
										<JText> {{ discount?.formattedFlat || "-" }} </JText>
										<JBox class="absolute bottom-0 left-0" style="transform: translateY(1.2rem)">
											<JText class="text-xs text-c0-300 whitespace-no-wrap">
												({{ decimalToPercentage(discount?.ratio) }} of {{ discount?.formattedOriginalPrice }})
											</JText>
										</JBox>
									</JBox>
								</template>
							</CEditableField>
						</JBox>
						<JText v-if="!discounts.length" class="text-c0-500">
							{{ $t("core.noDataHere") }}
						</JText>
					</JBox>
				</JBox>
				<!-- Premium Breakdown -->
				<JBox class="px-5 mt-8">
					<JFlex class="justify-between items-center">
						<JHeadline as="h3" variant="h3" class="text-c1-800">
							{{ $t("core.premiumBreakdown") }}
						</JHeadline>
					</JFlex>
					<JBox class="mt-6 mr-8">
						<JBox
							v-for="(item, idx) in premiumBreakdown"
							:key="idx"
							class="py-4 text-c0-500 border-b-2 border-c1-200 last:border-b-0"
						>
							<JText class="text-lg font-medium mb-2">
								{{ item.name || item.code }}
							</JText>
							<JText class="text-right">{{ item.formattedFlat }}</JText>
							<JText v-if="item.ratio" class="text-right text-c0-300 mt-1">
								{{
									`(${(Math.floor(item.ratio * 100000) / 1000).toFixed(3)}% of
									${item.formattedOriginalPrice})`
								}}
							</JText>
						</JBox>
						<JBox v-if="!premiumBreakdown.length">
							<JText class="text-c0-500">
								{{ $t("core.noDataHere") }}
							</JText>
						</JBox>
					</JBox>
				</JBox>
				<JBox v-if="premiumOf === 'case' && isEditable" class="mt-8 px-5">
					<JButton variant="primary" iconPrefix="Refresh" @click="handleClickReset">
						{{ $t("core.reset") }}
					</JButton>
				</JBox>
			</JBox>
		</CAccordion>
	</JBox>

	<!-- Modals -->
	<teleport to="#layer2">
		<!-- Modal Update -->
		<JModalSimple :isVisible="isAddModalOpen" variant="center-3xl" @overlay-click="handleCancelAddDiscount">
			<!-- Modal Header -->
			<JBox class="mb-6">
				<JText class="text-c0-300 font-medium">
					{{ $t("core.addNew") }}
				</JText>
				<JHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
					{{ $t("core.discount") }}
				</JHeadline>
			</JBox>

			<!-- Modal form -->
			<CTabs :options="discountInputOption" :activeTab="activeTab" @change="handleChangeTab" />

			<JBox v-if="activeTab === 'manual'" class="mt-6">
				<JBox class="mb-4 capitalize">
					<CFormInput v-model="name" componentName="JInputText" :label="$t('core.name')" />
				</JBox>
				<JFlex class="mb-4 capitalize">
					<JBox
						class="mr-4 transition-all duration-300 ease-in-out cursor-pointer rounded-lg"
						:class="[mode === 'percentage' ? 'flex-grow' : 'flex-grow-0 w-4/12']"
						@click="mode = 'percentage'"
					>
						<CFormInput
							v-model="percentage"
							componentName="JInputNumber"
							:variant="mode === 'percentage' ? 'primary' : 'disabled'"
						>
							<template #label>
								<JText
									class="font-medium cursor-text block pointer-events-none text-md mb-2 transition-all duration-300 ease-in-out"
									:class="mode === 'percentage' ? '' : 'text-c0-200'"
								>
									{{ $t("core.percentage") }}
								</JText>
							</template>
							<template #suffix>
								<JFlex
									class="absolute flex items-center pointer-events-none right-0"
									style="top: 50%; transform: translateY(-50%)"
								>
									<JBox class="border-l-2 border-c0-200 py-3" />
									<JBox class="py-2 px-3" :class="mode === 'percentage' ? '' : 'text-c0-200'">
										<JText> % </JText>
									</JBox>
								</JFlex>
							</template>
						</CFormInput>
					</JBox>
					<JBox
						class="transition-all duration-300 ease-in-out cursor-pointer rounded-lg"
						:class="[mode === 'flat' ? 'flex-grow' : 'flex-grow-0 w-4/12']"
						@click="mode = 'flat'"
					>
						<CFormInput v-model="flat" componentName="JInputNumber" :variant="mode === 'flat' ? 'primary' : 'disabled'">
							<template #label>
								<JText
									class="font-medium cursor-text block pointer-events-none text-md mb-2 transition-all duration-300 ease-in-out"
									:class="mode === 'flat' ? '' : 'text-c0-200'"
								>
									{{ $t("core.flat") }}
								</JText>
							</template>
							<template #suffix>
								<JFlex
									v-if="currencyCode"
									class="absolute flex items-center pointer-events-none right-0"
									style="top: 50%; transform: translateY(-50%)"
								>
									<JBox class="border-l-2 border-c0-200 py-3" />
									<JBox class="py-2 px-3" :class="mode === 'flat' ? '' : 'text-c0-200'">
										<JText> {{ currencyCode }} </JText>
									</JBox>
								</JFlex>
							</template>
						</CFormInput>
					</JBox>
				</JFlex>
				<JBox class="mb-4 capitalize">
					<CFormInput v-model="order" componentName="JInputNumber" :label="$t('core.order')" />
				</JBox>
			</JBox>

			<JBox v-else-if="activeTab === 'code'" class="mt-6">
				<JBox class="mb-4 capitalize">
					<CFormInput v-model="code" componentName="JInputText" :label="$t('core.discountCode')" />
				</JBox>
			</JBox>

			<!-- Modal actions -->
			<JFlex v-if="!isUpdateLoading" class="mt-10">
				<JButton class="mr-2 flex items-center" variant="primary" @click="handleConfirmAddDiscount">
					{{ $t("case.confirm") }}
				</JButton>
				<JButton class="ml-2" variant="tertiary-outline" @click="handleCancelAddDiscount">
					{{ $t("case.cancel") }}
				</JButton>
			</JFlex>
			<JFlex v-else class="items-center mt-6 h-10">
				<JSpinner type="dots" />
			</JFlex>
		</JModalSimple>
	</teleport>
</template>

<script>
import { useCaseOfferPremium } from "../../use/useCaseOfferPremium"
import { usePolicyOfferPremium } from "../../use/usePolicyOfferPremium"

export default {
	name: "CPremiumCard",
	props: {
		premiumOf: {
			type: String,
			default: () => "policy",
			validators(value) {
				return ["policy", "case"].includes(value)
			},
		},

		isEditable: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const useService = props?.premiumOf === "policy" ? usePolicyOfferPremium : useCaseOfferPremium
		const {
			state,
			send,
			name,
			order,
			mode,
			flat,
			percentage,
			code,
			premiumInfo,
			premiumBreakdown,
			discounts,
			currencyCode,
			isAddModalOpen,
			discountInputOption,
			activeTab,
			inputForm,
			selectedDiscountId,
		} = useService()

		return {
			state,
			send,
			name,
			order,
			mode,
			flat,
			percentage,
			code,
			premiumInfo,
			premiumBreakdown,
			discounts,
			currencyCode,
			isAddModalOpen,
			discountInputOption,
			activeTab,
			inputForm,
			selectedDiscountId,
		}
	},
	methods: {
		decimalToPercentage(value = null) {
			if (!value) return ""
			const percentage = Math.floor(value * 100).toFixed(2)
			return `${percentage}%`
		},

		handleAddDiscount() {
			this.send("ADD_DISCOUNT")
		},

		handleRemoveDiscount(discount) {
			this.selectedDiscountId = discount?.id ?? null
			this.send("REMOVE_DISCOUNT")
		},

		handleConfirmAddDiscount() {
			this.send("SAVE")
		},

		handleCancelAddDiscount() {
			this.send("CANCEL")
		},

		handleChangeTab(event) {
			const tabName = event?.name ?? null
			if (tabName === "manual") this.send("SET_MANUAL")
			if (tabName === "code") this.send("SET_CODE")
		},

		handleClickReset() {
			this.send("RESET")
		},
	},
}
</script>
