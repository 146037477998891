export default {
	setting: {
		setting: "Cấu hình",
		accessTokenLifetime: "Access Token Lifetime (in second)",
		slidingRefreshTokenLifetime: "Sliding Refresh Token Lifetime (in second)",
		absoluteRefreshTokenLifetime: "Absolute Refresh Token Lifetime (in second)",
		updateSettingSuccess: "Thông tin ứng dụng đã được lưu thành công!",
		configApplication: "Cấu hình ứng dụng",
		interest: "Tỉ lệ lãi suất",
		inflation: "Tỉ lệ lạm phát",
		confirmToUpdateInterestRate: "Bạn có chắc chắn muốn cập nhật tỉ lệ lãi suất không?",
		confirmToUpdateInflationRate: "Bạn có chắc chắn muốn cập nhật tỉ lệ lạm phát không?",
	},
	button: {
		update: "Cập nhật",
		cancel: "Huỷ bỏ",
	},
}
