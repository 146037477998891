import { ref } from "vue"

export const EMITS = ["blur", "focus"]

export const SECTIONS = [
	"outer",
	"wrapper",
	"label",
	"inner",
	"prefixIcon",
	"prefix",
	"input",
	"suffixIcon",
	"suffix",
	"help",
	"messages",
	"message",
]

export default function useInput(props, { attrs, emit }, configs = {}) {
	const inputRef = ref()
	const isFocused = ref(false)

	function onBlur(...args) {
		isFocused.value = false

		emit("blur", ...args)
	}

	function onFocus(...args) {
		isFocused.value = true

		emit("focus", ...args)
	}

	return {
		SECTIONS,
		inputRef,
		isFocused,
		eventHandlers: {
			onBlur,
			onFocus,
		},
	}
}
