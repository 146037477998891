<template>
	<div :class="variantCls.root">
		<select
			:class="variantCls.select"
			:disabled="disabled"
			@change="onHandleChange($event)"
			@focus="emit('focus')"
			@blur="emit('blur')"
		>
			<option v-if="allowSelectNothing" :value="null">{{ placeholder }}</option>
			<option v-else-if="!iOS" :value="null" disabled selected hidden>
				{{ placeholder }}
			</option>
			<option
				v-for="(option, i) in options"
				:key="i"
				:selected="option[valueKey] === modelValue"
				:value="option[valueKey]"
				:disabled="option.disabled"
			>
				{{ option[nameKey] }}
			</option>
		</select>
		<div :class="variantCls.iconRoot">
			<slot name="icon" :variantCls="variantCls">
				<JIcon :class="variantCls.icon" :icon="variantAssets.iconName" />
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	name: "CSelectNumberValue",
	emits: ["update:modelValue", "blur", "focus"],
	props: {
		variant: {
			type: String,
			default: "primary",
		},
		modelValue: {
			required: true,
			default: null,
			validator(val) {
				return val === null || typeof val === "number"
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		options: {
			required: true,
			default: () => [],
			type: Array,
		},
		nameKey: {
			type: String,
			default: "name",
		},
		valueKey: {
			type: String,
			default: "value",
		},
		placeholder: {
			type: String,
			default: "----",
		},
		allowSelectNothing: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	computed: {
		variants() {
			return {
				primary: {
					el: {
						root: "block relative",
						select:
							"bg-cWhite text-c0-600 rounded-lg leading-tight border border-c0-300 pl-5 pr-8 appearance-none w-full h-10 placeholder-c0-500 focus:outline-none focus:border-c1-500",
						iconRoot: "pointer-events-none absolute inset-y-0 right-0 flex items-center text-cBlack px-2",
						icon: "",
					},
					assets: {
						iconName: "ChevronDown",
					},
				},
				secondary: {
					el: {
						root: "block relative",
						select:
							"bg-cWhite text-c0-600 leading-tight border border-c0-800 pl-5 pr-8 appearance-none w-full h-10 placeholder-c0-500 focus:outline-none focus:border-c1-500",
						iconRoot: "pointer-events-none absolute inset-y-0 right-0 flex items-center text-cBlack px-2",
						icon: "",
					},
					assets: {
						iconName: "ChevronDown",
					},
				},
			}
		},
		variantCls() {
			return this.getComponentVariants()?.el
		},
		variantAssets() {
			return this.getComponentVariants()?.assets || {}
		},
		iOS() {
			return (
				["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
				// iPad on iOS 13 detection
				navigator.userAgent.includes("Mac")
			)
		},
	},
	methods: {
		getComponentVariants() {
			return this.variants[this.variant]
		},
		emit(...args) {
			if (!this.disabled) {
				this.$emit(...args)
			}
		},
		onHandleChange(event) {
			// check this in case the value is string collected from the placeHolder
			const targetValue = event.target.value && Number(event.target.value)
			const isNaN = targetValue !== Number(event.target.value)
			const value = isNaN ? null : Number(event.target.value)
			this.$emit("update:modelValue", value)
		},
	},
}
</script>
