<template>
	<JBox class="mb-5">
		<!-- Main title -->
		<JBox class="md:w-11/12 w-full">
			<JBox class="flex items-center">
				<JBox class="flex items-center cursor-pointer" @click="tickCollapse">
					<JBox class="flex items-center w-5 h-5 bg-c1-300 text-c1-50 mr-2 rounded-2xl">
						<JIcon class="origin-center" :class="{ 'rotate-90': show }" width="20" height="15" icon="ChevronRight" />
					</JBox>
					<JText v-if="!hyperlink" class="text-cBlack font-bold text-start"
						><span class="match">{{ isTranslateTitle ? $t(title) : title }}</span></JText
					>
				</JBox>
				<JBox class="cursor-pointer">
					<a v-if="hyperlink" :href="hyperlink" target="_blank" class="text-cBlack font-bold text-start"
						><span class="match">{{ $t(title) }}</span></a
					>
				</JBox>
			</JBox>
		</JBox>
		<!-- Sub content -->
		<JBox class="ml-7 mt-1 text-sm tracking-tight" :class="{ hidden: !show }">
			<JBox v-if="options && options.length > 0" :class="customClassOptions">
				<JText v-for="(item, index) in options" :key="index" class="text-left">
					<a v-if="item.href" :href="item.href" target="_blank" class="leading-10 font-bold text-c2-600"
						>{{ item.customOrderList }} <span class="match" style="text-decoration: underline">{{ item.value }}</span></a
					>
					<a v-else class="leading-10 font-bold"
						>{{ item.customOrderList }}: <span class="match font-normal">{{ item.value }}</span></a
					>
				</JText>
			</JBox>
			<JBox>
				<slot />
			</JBox>
		</JBox>
	</JBox>
</template>
<script>
import { computed } from "vue"
import { useI18n } from "vue-i18n"
export default {
	name: "GroupCollapseContractInfo",
	props: {
		title: {
			type: String,
			default: "",
		},
		hyperlink: {
			type: String,
			default: "",
		},
		options: {
			type: Array,
			default: () => [],
		},
		tick: {
			type: Boolean,
			default: false,
		},
		customClassOptions: {
			type: String,
			default: "",
		},
		isTranslateTitle: {
			type: Boolean,
			default: true,
		},
	},
	emits: ["update:tick"],
	setup(props, { emit }) {
		const { t } = useI18n()

		const show = computed(() => props.tick)
		const tickCollapse = () => {
			emit("update:tick", !props.tick)
		}
		return {
			t,
			show,
			tickCollapse,
		}
	},
}
</script>
<style scoped>
.rotate-90 {
	transform: rotate(90deg);
}
</style>
