<template>
	<!-- Title & Content -->
	<JBox class="text-base text-c0-500 mb-4">
		<JFlex class="justify-between">
			<JText class="font-medium mb-3"> {{ note.title }} </JText>
			<NoteOption ref="optionRef" @edit="handleEdit" @delete="handleDelete" />
		</JFlex>
		<JText> {{ note.content }} </JText>
	</JBox>

	<!-- Date & Creator -->
	<JBox class="text-sm text-c0-300">
		<JText> {{ formatDate(note.createdAt) }} </JText>
		<JText> {{ note.createdBy.email }} </JText>
	</JBox>

	<!-- Separator -->
	<JBox class="border-b-2 border-c1-100 my-6" />

	<NoteModal
		:isVisible="showEditModal"
		:note="note"
		:label="$t('core.editFor')"
		:submitBtn="$t('core.confirm')"
		@submit="onSubmit"
		@cancel="onCancel"
	/>
</template>
<script>
import { ref } from "vue"
import { useStore } from "vuex"
import { useI18n } from "vue-i18n"
import dayjs from "dayjs"
import { apiUpdateNote, apiDeleteNote } from "./NoteComposables"
import { handleErrorForUser } from "../../api/handleErrorForUser"

import NoteOption from "./NoteOption"
import NoteModal from "./NoteModal"
export default {
	components: { NoteOption, NoteModal },

	emits: ["update"],

	props: {
		entityId: {
			type: String,
			required: true,
		},

		typename: {
			type: String,
			required: true,
		},

		note: {
			type: Object,
			required: true,
		},
	},

	setup(props, { emit }) {
		const store = useStore()
		const { t } = useI18n()
		const optionRef = ref(null)

		function formatDate(date) {
			const dateFormat = store?.state?.dateFormat ?? "DD/MM/YYYY"
			return dayjs(date).format(dateFormat)
		}

		async function updateNote(title, content) {
			const { error } = await apiUpdateNote(props.entityId, props.typename, props.note.id, title, content)

			if (error) handleErrorForUser({ error, $t: t })
			else {
				toggleEditModal(false)
				emit("update")
			}
		}

		async function deleteNote() {
			const { error } = await apiDeleteNote(props.entityId, props.typename, props.note.id)

			if (error) handleErrorForUser({ error, $t: t })
			else emit("update")
		}

		function handleEdit() {
			toggleEditModal(true)
		}

		function handleDelete() {
			deleteNote()
			optionRef.value.hideShowMore()
		}

		// Modal
		const showEditModal = ref(false)

		function toggleEditModal(option) {
			if (option) showEditModal.value = option
			else showEditModal.value = !showEditModal.value
		}

		function onCancel() {
			toggleEditModal(false)
		}

		function onSubmit(event) {
			const { title, content } = event
			updateNote(title, content)
		}

		return { optionRef, formatDate, handleEdit, handleDelete, showEditModal, toggleEditModal, onCancel, onSubmit }
	},
}
</script>
