<template>
	<JBox class="mb-8">
		<JFlex class="justify-between items-center flex-wrap mb-3">
			<JHeadline as="h2" variant="h2" class="text-c1-800 pr-4">
				{{ $t("policy.beneficiaries") }}
			</JHeadline>
			<JButton variant="primary" iconPrefix="plus-circle" @click="handleClickAddBeneficiary()">
				{{ $t("policy.addBeneficiary") }}
			</JButton>
		</JFlex>
		<JBox v-for="(beneficiary, index) in beneficiaryList" :key="beneficiary.id" class="mb-8 text-c1-800">
			<CEntityCard
				:entity="beneficiary"
				:typename="beneficiary.__typename"
				:title="`Beneficiary ${index + 1}`"
				@update:credentials="$emit('update')"
			>
				<template #footer>
					<JButton class="mt-8 mx-5" variant="warning-sm" iconPrefix="trash" @click="removeBeneficiary(beneficiary)">
						{{ $t("policy.removeBeneficiary") }}
					</JButton>
				</template>
			</CEntityCard>
		</JBox>
		<JBox v-if="beneficiaryList.length === 0" variant="card-1" class="mt-4 text-c0-500">
			<JText class="pl-5">{{ $t("policy.noDataHere") }}</JText>
		</JBox>
	</JBox>

	<!-- Modal Add Entity -->
	<teleport to="#layer2">
		<JModalSimple :isVisible="isModalOpen" @overlay-click="handleModalCancel">
			<!-- Modal Header -->
			<JBox>
				<JText class="text-c0-300 font-medium">
					{{ $t("policy.addNew") }}
				</JText>
				<JHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
					{{ $t("policy.beneficiary") }}
				</JHeadline>
			</JBox>

			<!-- Picker -->
			<CEntityPicker :allowedEntityTypes="['individual', 'company', 'internal']" @selected-entity="cacheEntity" />

			<!-- Fields -->
			<JBox class="mb-10">
				<CFormInput v-model="form.ratio" componentName="JInputNumber" :label="$t('policy.ratio')" />
			</JBox>

			<!-- Modal actions -->
			<JFlex>
				<JButton class="mr-2 flex items-center" variant="primary" @click="handleModalConfirm">
					{{ $t("policy.confirm") }}
				</JButton>
				<JButton class="ml-2" variant="tertiary-outline" @click="handleModalCancel">
					{{ $t("policy.cancel") }}
				</JButton>
			</JFlex>
		</JModalSimple>
	</teleport>
</template>
<script>
import {
	apiAddBeneficiaryEligibility,
	apiRemoveBeneficiaryEligibility,
	apiAddBeneficiaryEligibilityToCase,
	apiRemoveBeneficiaryEligibilityFromCase,
} from "@covergo/cover-composables"
import { fetcher } from "../api/fetcher"
import { handleErrorForUser } from "../api/handleErrorForUser"
export default {
	name: "CBeneficiaryCard",

	props: {
		beneficiaryOf: {
			type: String,
			required: true,
			validation(val) {
				return ["policy", "case"].includes(val)
			},
		},

		parentId: {
			type: String,
			required: true,
		},

		beneficiaryList: {
			type: Array,
			default: () => [],
		},
	},

	emits: ["update"],

	data() {
		return {
			isModalOpen: false,
			entityCache: null,
			form: {
				ratio: null,
			},
		}
	},

	computed: {
		apiKeychain() {
			return {
				policy: {
					create: {
						api: apiAddBeneficiaryEligibility,
						idType: "policyId",
					},
					delete: {
						api: apiRemoveBeneficiaryEligibility,
						idType: "policyId",
					},
				},
				case: {
					create: {
						api: apiAddBeneficiaryEligibilityToCase,
						idType: "caseId",
					},
					delete: {
						api: apiRemoveBeneficiaryEligibilityFromCase,
						idType: "caseId",
					},
				},
			}
		},
	},

	methods: {
		handleClickAddBeneficiary() {
			this.isModalOpen = true
		},

		/** Entity Picker Actions */
		cacheEntity(event = null) {
			this.entityCache = event
		},

		async addBenefitiary() {
			const { api, idType } = this.apiKeychain[this.beneficiaryOf].create
			const variables = {
				[idType]: this.parentId,
				input: {
					entityId: this.entityCache?.id || null,
					ratio: this.form.ratio / 100,
				},
			}
			const { error } = await api({ variables, fetcher })
			if (error) {
				handleErrorForUser({ error, $t: this.$t })
				return
			}

			this.$emit("update")
			this.addToast("success", this.$t("policy.addBeneficiarySuccess"))
		},

		async removeBeneficiary(beneficiary) {
			const { api, idType } = this.apiKeychain[this.beneficiaryOf].delete
			const variables = {
				[idType]: this.parentId,
				beneficiaryEligibilityId: beneficiary?.beneficiaryId ?? null,
			}
			const { error } = await api({ variables, fetcher })
			if (error) {
				handleErrorForUser({ error, $t: this.$t })
				return
			}

			this.$emit("update")
			this.addToast("success", this.$t("policy.removeBeneficiarySuccess"))
		},

		/** Modal Actions */
		async handleModalConfirm() {
			await this.addBenefitiary()
			this.handleModalCancel()
		},
		handleModalCancel() {
			this.isModalOpen = false
			this.entityCache = null
			this.resetForm()
		},

		resetForm() {
			this.form.ratio = null
		},

		/** Toast Msg */
		addToast(type, message) {
			this.$store.dispatch("addToastMessage", {
				type,
				content: {
					type: "message",
					text: message,
				},
			})
		},
	},
}
</script>
