<template>
	<JFlex v-if="isReadingData" class="justify-center pt-10">
		<JSpinner variant="secondary-lg" type="dots" />
	</JFlex>
	<JFlex class="flex-col justify-center space-y-3">
		<!-- Content -->
		<JFlex class="flex-col space-y-4">
			<!-- Contractor info -->
			<JFlex class="flex-row pointer-events-none space-x-2 items-center">
				<JFlex class="justify-center items-center w-5 h-5 bg-c1-300 text-c1-50 rounded-2xl">
					<JIcon class="origin-center" width="16" height="16" icon="ChevronRight" />
				</JFlex>

				<JHeadline as="h3" variant="h4" class="text-c0-800 pointer-events-none uppercase font-bold">
					{{ $t("dashboard.policyOwner") }}
				</JHeadline>
			</JFlex>
			<!-- Contractor detail -->
			<JFlex class="flex-col space-y-4 pointer-events-none pl-8">
				<JText v-for="(info, idx) in contractorInfo" :key="idx"
					><span class="font-bold pr-2">{{ info.label }}:</span>{{ info.value }}</JText
				>
			</JFlex>
		</JFlex>
	</JFlex>
</template>

<script setup>
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import { formatData } from "@/modules/core/composables"
import { useContractInfoDetail } from "../../use/useContractInfoDetail"

defineEmits(["cancel"])

const props = defineProps({
	contract: {
		type: Object,
		default: () => ({}),
	},
	isReadingData: {
		type: Boolean,
		default: false,
	},
})
const { t } = useI18n()
const { dayMonthYearFormat, genderMappingValue } = useContractInfoDetail()
const contractorInfo = computed(() => {
	const mappingGender = genderMappingValue.value?.find((i) => i.value === props?.contract?.poGender)?.name
	return [
		{ label: t("dashboard.fullName"), value: formatData(props.contract?.poName) },
		{ label: t("dashboard.citizenId"), value: formatData(props.contract?.poId) },
		{ label: t("dashboard.gender"), value: formatData(mappingGender) },
		{ label: t("dashboard.dob"), value: formatData(props.contract?.poDob, dayMonthYearFormat.value) },
		{ label: t("dashboard.phoneNumber"), value: formatData(props.contract?.poPhoneNum) },
		{ label: t("dashboard.email"), value: formatData(props.contract?.poEmail) },
		{ label: t("dashboard.occupation"), value: formatData(props.contract?.poOccupation) },
		{ label: t("dashboard.address"), value: formatData(props.contract?.poAddress) },
	]
})
</script>
