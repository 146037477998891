import { gql } from "@covergo/cover-composables"
export const casesFragmentOnLoadAFlow = gql`
	fragment result on cases {
		totalCount
		list {
			id
			fields
			caseNumber
			source
			createdAt
			status
			createdBy {
				username
			}
			facts {
				id
				type
				value {
					stringValue
					booleanValue
					numberValue
					dateValue
					arrayValue {
						stringValue
					}
					objectValue {
						key
						value {
							stringValue
							booleanValue
							numberValue
							dateValue
							objectValue {
								key
								value {
									stringValue
									booleanValue
									numberValue
									dateValue
									arrayValue {
										stringValue
										booleanValue
										numberValue
										dateValue
										objectValue {
											key
											value {
												stringValue
												booleanValue
												numberValue
												dateValue
												objectValue {
													key
													value {
														stringValue
														booleanValue
														numberValue
														dateValue
													}
												}
											}
										}
									}
									objectValue {
										key
										value {
											stringValue
											booleanValue
											numberValue
											dateValue
											objectValue {
												key
												value {
													stringValue
													booleanValue
													numberValue
													dateValue
													objectValue {
														key
														value {
															stringValue
															booleanValue
															numberValue
															dateValue
														}
													}
												}
											}
										}
									}
								}
							}
							arrayValue {
								stringValue
								booleanValue
								numberValue
								dateValue
								objectValue {
									key
									value {
										stringValue
										booleanValue
										numberValue
										dateValue
									}
								}
							}
						}
					}
				}
			}
			holder {
				... on individual {
					id
					gender
					facts {
						id
						type
						value {
							stringValue
							booleanValue
							numberValue
							dateValue
							arrayValue {
								stringValue
								booleanValue
								numberValue
								dateValue
								objectValue {
									key
									value {
										stringValue
										booleanValue
										numberValue
										dateValue
									}
								}
							}
							objectValue {
								key
								value {
									stringValue
									booleanValue
									numberValue
									dateValue
								}
							}
						}
					}
				}
			}
		}
	}
`
