import { computed, reactive, ref, toRefs, watch, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import XLSX from "xlsx"
import { saveAs } from "file-saver"
import { useStore } from "vuex"
import isBetween from "dayjs/plugin/isBetween"
import { useContractInfoDetail } from "./useContractInfoDetail"
import { useVuelidate } from "@vuelidate/core"
import { chunk, concat, cloneDeep, uniqBy, orderBy, merge, isEmpty } from "lodash"
import { formatData } from "@/modules/core/composables"
import { maxLength, requiredIf } from "@vuelidate/validators"
import { useRoute, useRouter } from "vue-router"
import useApis from "@/modules/dashboard/use/useApis"
import dayjs from "dayjs"
import { LEAD_DEPARTMENT, INSURANCE_EXPERT_USER } from "@/modules/dashboard/utils/constant"
import useLoading from "@/composables/loading"
import { fetchOriginalBranches } from "@/modules/setting/use/appConfigDynamicTemplates"
import getLocationByBranches from "@/modules/core/use/useLocationByCompanyBranches"
import { filterListByKey } from "@/modules/dashboard/helpers"
import paramsToFetchPolicies from "@/modules/dashboard/use/useParamsToGetPolicies"
import { handleErrorForUser } from "@/modules/setting/api/handleErrorForUser"

dayjs.extend(isBetween)

export function useContracts() {
	const { t, locale } = useI18n()
	const store = useStore()
	const route = useRoute()
	const router = useRouter()

	const NUMBER_LIMIT = 200

	const fullContractListToExport = ref([])

	const {
		isLocationManagers,
		userBranch: userLoginBranch,
		getValPolicyStatusByCodeMVL,
		agentCodeOfUserLogin,
		mapPaymentModes,
		mapNbPendingReason,
		userRegion,
		userArea,
		isAreaManager,
		isHoManager,
		isLeadDepartment,
		isRegionManager,
		defaultStaffRoles,
		mapValueList,
		mapAgentCodeList,
		contractStatusesOfTCB,
		isBranchManager,
		contractsTableHeader,
		isFrontlineAndExpertDept,
		SEARCH_TYPES,
	} = useContractInfoDetail()

	const { inquiryPolicies, fetchIndividuals, queryAgentList } = useApis()

	const { loaders, addLoader } = useLoading()
	addLoader("pageLoader")
	addLoader("clearFilter")

	const dataFromRoute = route?.query || {}

	const contractListAreEmpty = ref(null)
	const contractList = ref([])

	const loadingStates = reactive({
		fetchAgents: false,
		isExportingFile: false,
		fetchPolicies: false,
	})
	const filterData = reactive({
		searchBy: isLeadDepartment.value ? SEARCH_TYPES.AGENT : SEARCH_TYPES.BRANCH,
		contractManagesByAgent: [],
		customerIdentify: dataFromRoute?.poId || "",
		contractNo: "",
		contractStatus: "all",
		createdAt: {
			dateType: "",
			type: "between", // use crunchDate from composables file to search createAt field
			value: [],
			quickOptionValue: "",
		},

		areas: [],
		regions: [],
		branches: [],
		staffTypes: [],
	})

	const disabledFeaturesFields = reactive({
		show: {
			area: false,
			region: false,
			branch: false,
			staffRole: true,
			agent: false,
		},
		toggleOption: {
			area: false,
			region: false,
			branch: false,
			staffRole: false,
			agent: false,
		},
		search: {
			area: true,
			region: false,
			branch: false,
			staffRole: true,
			agent: false,
		},
		optionAll: {
			staffRole: false,
			areas: false,
			branches: false,
		},
	})

	const originalBankLocationList = ref([])
	const originalBranchValues = ref([])
	const originalRoleValues = ref([])
	const originalAreaValues = ref([])

	const mTextContractListAreEmpty = computed(() => (contractListAreEmpty.value ? t(`${contractListAreEmpty.value}`) : ""))

	const allowToFetchAgentCodes = computed(() => !isFrontlineAndExpertDept.value && filterData.staffTypes?.length)

	const selectedRoleIsNewTis = computed(() => {
		const findRole = filterData.staffTypes?.find((staff) => staff.value === INSURANCE_EXPERT_USER.NewTIS)
		return !isEmpty(findRole)
	})

	const areasIsRequiredByRole = computed(() => {
		const userLoginRole = isHoManager.value || isAreaManager.value
		return userLoginRole && selectedRoleIsNewTis.value
	})

	const mandatoryFieldsBySearchTypeAndDept = computed(
		() => filterData.searchBy === SEARCH_TYPES.AGENT && isLocationManagers.value
	)

	const newTISroleSelectedByHoManager = computed(() => isHoManager.value && selectedRoleIsNewTis.value)

	const customBranchFieldByUserRole = {
		optionDefaultValueIsNull: isHoManager.value || isAreaManager.value || isRegionManager.value,
		allowToSelectItems: isHoManager.value || isRegionManager.value || isAreaManager.value,
	}
	// pagination
	const totalItems = ref(0)
	const limit = ref(20) // records = 20 / page
	const currentPage = ref(1)
	const breakPointNum = ref(1)

	// validation
	const filterDataFormToRefs = toRefs(filterData)
	const rules = {
		customerIdentify: {
			maxLength: maxLength(50),
		},
		contractNo: {
			maxLength: maxLength(10),
		},
		branches: {
			thisFieldIsRequired: requiredIf(() => isLocationManagers.value && !newTISroleSelectedByHoManager.value),
		},
		staffTypes: {
			thisFieldIsRequired: requiredIf(() => mandatoryFieldsBySearchTypeAndDept.value),
		},
		contractManagesByAgent: {
			selectAtLeast1Agent: requiredIf(() => mandatoryFieldsBySearchTypeAndDept.value),
		},
		searchBy: {
			required: requiredIf(() => isLocationManagers.value),
		},
		areas: {
			thisFieldIsRequired: requiredIf(() => areasIsRequiredByRole.value),
		},
	}
	const v = useVuelidate(rules, filterDataFormToRefs)

	// LOGIC: fetch agent code
	const agentCodeOptions = ref([])
	const defaultAgentCodeOptions = ref([])
	const warningNoAgentCodeMatch = ref(false)

	function mapDataAgentList({ data, transform = (item) => ({ ...item, selected: false }) }) {
		if (Array.isArray(data)) {
			let dataList = data?.reduce((resultList, currentItem) => {
				const fields = JSON.parse(currentItem?.fields || "{}")
				if (!fields?.agentCodeName) return resultList
				const transformItem = {
					...currentItem,
					value: fields?.agentCodeName,
					code: fields?.agentCodeName,
					name: fields?.agentCode,
				}
				if (transformItem?.name === agentCodeOfUserLogin.value) return resultList
				if (isBranchManager.value) {
					const userSource = transformItem?.source?.split("-")
					const userPosition = userSource?.[userSource?.length - 1]
					if (!LEAD_DEPARTMENT.includes(userPosition)) {
						resultList.push(transformItem)
					}
				} else {
					resultList.push(transformItem)
				}
				return resultList
			}, [])
			if (dataList.length) {
				warningNoAgentCodeMatch.value = false

				dataList = uniqBy(dataList, "code")

				agentCodeOptions.value = dataList?.map(transform)
			} else {
				warningNoAgentCodeMatch.value = true
				agentCodeOptions.value = []
			}
		}
	}
	async function refetchAgentListWhenHideOptions({ value, originalValueType }) {
		// Only fetch when user clicks close the box > To avoid changing roles continuously

		const mapValues = orderBy(value?.map((item) => item.value))?.join("")
		const isDifference = originalValueType !== mapValues

		if (!filterData.staffTypes?.length) {
			resetAgentCodeData()
		} else if (isDifference) {
			await refetchAgentList()
		}
	}

	function showDropdownRoles(value) {
		const values = value?.map((item) => item.value)
		originalRoleValues.value = orderBy(values)?.join("")
	}

	async function hideDropdownRoles(value) {
		refetchAgentListWhenHideOptions({ value, originalValueType: originalRoleValues.value })
	}

	function showDropDownBranchList(value) {
		const values = value?.map((item) => item.value)
		originalBranchValues.value = orderBy(values)?.join("")
	}
	async function hideDropdownBranchList(value) {
		refetchAgentListWhenHideOptions({ value, originalValueType: originalBranchValues.value })
	}

	function showDropDownAreaList(value) {
		const values = value?.map((item) => item.value)
		originalAreaValues.value = orderBy(values)?.join("")
	}
	async function hideDropdownAreaList(value) {
		refetchAgentListWhenHideOptions({ value, originalValueType: originalAreaValues.value })
	}

	function filterAgentOptionsByUserRole(list, selectedSourceOptions) {
		if (!list.length) return []
		/**
		 * Filter by FE
		 */
		const mapList = list?.filter((item) => {
			const sourcePaths = item.source?.split("-")
			for (const source of selectedSourceOptions) {
				if (sourcePaths.includes(source.value)) return true
			}
			return false
		})
		return mapList
	}

	async function readAgentBatchList(options = {}) {
		try {
			const pageNumber = 1
			const agentList = []

			const mapBranchValues = options?.branches?.map(({ value }) => ({ source_contains: value }))
			const mapRoleValues = options?.staffTypes?.map(({ value }) => ({ source_contains: value }))
			const mapAreaValues = options?.areas?.map(({ value }) => ({ source_contains: value }))
			const optionsSelectedByRole = selectedRoleIsNewTis.value ? mapAreaValues : mapBranchValues

			const defaultVariables = {
				limit: NUMBER_LIMIT,
				roles: mapRoleValues,
				optionsSelectedByRole,
			}
			const data = await queryAgentList({
				...defaultVariables,
				skip: (pageNumber - 1) * NUMBER_LIMIT,
			})

			const totalCount = data?.individualAgentCode?.totalCount || 0
			const pageCounted = Math.ceil(totalCount / NUMBER_LIMIT)

			if (data?.individualAgentCode?.list?.length) agentList.push(...data.individualAgentCode.list)

			if (agentList.length < totalCount) {
				for (let i = 1; i < pageCounted; i++) {
					const res = await queryAgentList({
						...defaultVariables,
						skip: i * NUMBER_LIMIT,
					})
					if (res?.individualAgentCode?.list?.length) {
						agentList.push(...res.individualAgentCode.list)
					}
				}
			}

			return filterAgentOptionsByUserRole(agentList, filterData.staffTypes)
		} catch (e) {
			console.error(e)
		}
	}

	async function refetchAgentList() {
		try {
			loadingStates.fetchAgents = true

			const listedOptions = getLocationsByRoleSelected()

			const options = {
				areas: listedOptions?.areas,
				branches: listedOptions?.branches,
				staffTypes: filterData.staffTypes,
			}

			const data = await readAgentBatchList(options)

			mapDataAgentList({ data })

			filterData.contractManagesByAgent = []

			loadingStates.fetchAgents = false
		} catch (err) {
			console.warn(err)
		}
	}

	async function fetchAllAgentCode(options = {}) {
		try {
			loadingStates.fetchAgents = true
			const data = await readAgentBatchList(options)

			mapDataAgentList({ data })

			defaultAgentCodeOptions.value = agentCodeOptions.value
		} catch (err) {
			console.warn(err)
		} finally {
			loadingStates.fetchAgents = false
		}
	}

	// Methods
	function collectAgentListByLeadAndFrontLine() {
		let agentList = []

		if (isLeadDepartment.value) {
			agentList = filterData?.contractManagesByAgent?.length
				? mapValueList({ list: filterData?.contractManagesByAgent, key: "name" })
				: mapAgentCodeList({ list: agentCodeOptions.value })
		}
		if (isFrontlineAndExpertDept.value) {
			agentList = [agentCodeOfUserLogin.value]
		}

		return agentList
	}

	function collectAgentCodeByManager() {
		let list = []
		const isSelectedAllRole = filterData.staffTypes?.length === staffRoleOptions.value?.length
		const isSelectedAllAgents = filterData?.contractManagesByAgent?.length === agentCodeOptions.value?.length
		if (isSelectedAllRole) {
			/**
			 * User don't choose any agent or all agents => will send "[]" to get all agents
			 */
			if (isSelectedAllAgents || !filterData?.contractManagesByAgent?.length) {
				list = []
			} else {
				list = mapValueList({ list: filterData?.contractManagesByAgent, key: "name" })
			}
		} else {
			list = filterData?.contractManagesByAgent?.length
				? mapValueList({ list: filterData?.contractManagesByAgent, key: "name" })
				: mapAgentCodeList({ list: agentCodeOptions.value })
		}
		return list
	}

	function collectAgentCodeDataByRole() {
		/**
		 * APPLYING FOR HO / Area/ Region/ branch manager
		 * 1/ Role (staffRole) === "all" && filterData?.isSelectedAllAgents  -> No need to send agentCode to TCB
		 * 2/ role (staffRole) !== "all" && filterData?.isSelectedAllAgents -> send agentCode to TCB
		 *
		 * APPLYING FOR Lead Department / FrontLine : agentCode can be a list
		 * 1/ Frontline: send by default value (user logged in)
		 * 2/ Department: all value or any item that user chooses
		 */
		if (isLeadDepartment.value || isFrontlineAndExpertDept.value) return collectAgentListByLeadAndFrontLine()
		else return collectAgentCodeByManager()
	}

	function getPolicyStatusList() {
		if (filterData.contractStatus === "all") {
			const mapList = mapValueList({ list: contractStatusesOfTCB.value, key: "value" })
			return mapList?.filter((item) => item !== "all")
		} else {
			return [filterData.contractStatus]
		}
	}

	async function readAllCases({ overridePageSize, overrideCurrentPage, overridePoIdFromQuery }) {
		const mAgentCodes = collectAgentCodeDataByRole()
		const policyStatusList = getPolicyStatusList()
		const branchesSelected = mapValueList({ list: filterData.branches, key: "value" })
		const params = paramsToFetchPolicies({
			userLoginIsFrontlineAndExpertDept: isFrontlineAndExpertDept.value,
			searchTypeSelected: filterData.searchBy,
			filterData,
			limitPageSize: limit.value,
			currentPage: currentPage.value,
			userLoginBranch,
			mAgentCodes,
			branchesSelected,
			policyStatusList,
			overridePageSize,
			overrideCurrentPage,
			overridePoIdFromQuery,
		})

		return await inquiryPolicies({ params })
	}

	const readAllContractData = async ({ agentCodeOptions }) => {
		try {
			loadingStates.fetchPolicies = true
			const data = await readAllCases({ agentCodeOptions })
			if (!data.value?.data) contractListAreEmpty.value = "dashboard.resultNotMatch"
			totalItems.value = data.value?.totalElements
			contractList.value = data.value?.data || []
		} catch (e) {
			console.error(e)
		} finally {
			loadingStates.fetchPolicies = false
		}
	}

	// AREA FILTER OPTIONS

	function defaultAreaByRole({ list }) {
		disabledFeaturesFields.toggleOption.area = true
		return list?.filter((item) => item.value === userArea)
	}

	function mapLocationData({ areas, regions, branches }) {
		return areas?.map((area) => {
			const mapRegions = filterListByKey({ list: regions, field: area.value, fieldKey: "area" })
			const mapRegionAndBranchList = mapRegions?.map((region) => {
				const branchList = filterListByKey({ list: branches, field: region.value, fieldKey: "region" })
				const customBranchList = branchList?.map((branch) => ({
					...branch,
					name: computed(() => `${branch.value} - ${locale.value === "en-US" ? branch?.branchNameEn : branch.branchNameVn}`),
				}))

				return {
					...region,
					branches: customBranchList,
				}
			})
			return {
				...area,
				regions: mapRegionAndBranchList,
			}
		})
	}
	async function fetchAllLocationList() {
		const response = await fetchOriginalBranches()
		const getLocation = getLocationByBranches({ originalBranches: response, locale })
		return {
			branches: response,
			areas: getLocation?.areas,
			regions: getLocation?.regions,
		}
	}
	function generateAreaList({ bankLocations }) {
		const mapLocationOptions = mapLocationData({
			areas: bankLocations?.areas,
			regions: bankLocations?.regions,
			branches: bankLocations?.branches,
		})
		/**
		 * HO: can search and choose the area options: ALL and other options
		 * Area/ Region/ Branch/ Department branch: area option is settled down by branch of logging user - NOT ALLOW TO CHANGE AN OPTION
		 */
		let list = []
		const allowToSelectAreas = isHoManager.value || !userArea

		if (allowToSelectAreas) {
			list = mapLocationOptions || []
		} else {
			list = defaultAreaByRole({ list: mapLocationOptions })
		}

		let isSelected = true
		if (newTISroleSelectedByHoManager.value) isSelected = false

		return list.map((area) => {
			return {
				...area,
				name: computed(() => (locale.value === "en-US" ? area?.areaNameEn : area?.areaNameVN)), // to rerender locale
				selected: isSelected,
			}
		})
	}

	function updateAreas(value, { transformRegions } = {}) {
		filterData.areas = value

		refetchRegions({ transformRegions })
	}

	function onUpdateAreas(val) {
		updateAreas(val)
		v.value.areas?.$touch()
	}

	function updateRegions(value, { transformBranches } = {}) {
		filterData.regions = value
		refetchBranches({ transformBranches })
	}

	function onUpdateRegions(val) {
		updateRegions(val)
	}
	function onUpdateBranches(val) {
		filterData.branches = val
		v.value.branches?.$touch()
	}
	function onUpdateAgentCode(val) {
		filterData.contractManagesByAgent = val
	}
	async function openLocationsAndSetDefaultValues() {
		openLocationsSelection()

		disabledFeaturesFields.show.agent = true

		// Case: user login is branchManager and branch field is selected automatically
		if (filterData.branches?.length) {
			await initAgentOptionsByRole()
		}
	}

	function toggleAreasToSingleOrMultiSelection() {
		if (newTISroleSelectedByHoManager.value) disabledFeaturesFields.optionAll.areas = true
		else disabledFeaturesFields.optionAll.areas = false
	}

	async function onUpdateStaffType() {
		v.value.staffTypes?.$touch()

		resetAgentCodeData()
		v.value.branches?.$reset()

		if (!filterData.staffTypes?.length) {
			closeLocationsSelection()
		} else {
			toggleAreasToSingleOrMultiSelection()

			setDefaultLocations({ bankLocations: originalBankLocationList.value })

			await openLocationsAndSetDefaultValues()
		}
	}

	function defaultRegionByRole({ list }) {
		disabledFeaturesFields.search.region = true
		disabledFeaturesFields.toggleOption.region = true
		return list?.filter(({ value }) => value === userRegion)
	}

	const userIsAllowedToChooseRegions = isHoManager.value || isAreaManager.value

	function generateRegionList({ areasSelected = [], transform = (item) => merge(item, { selected: false }) }) {
		/**
		 * Only HO has a ALL option and can change options
		 */
		const mRegionList = areasSelected?.flatMap((area) => {
			const regionList = area?.regions
			return regionList?.map((region) => {
				return merge(region, {
					name: computed(() => (locale.value === "en-US" ? region?.regionNameEn : region?.regionNameVn)),
				})
			})
		})
		let filteredRegions = []
		if (userIsAllowedToChooseRegions) {
			filteredRegions = mRegionList
		} else {
			filteredRegions = defaultRegionByRole({ list: mRegionList })
		}

		const mList = filteredRegions?.map(transform)?.sort((a, b) => a.order - b.order)
		return mList
	}

	function defaultBranchByRole({ list }) {
		disabledFeaturesFields.toggleOption.branch = true
		disabledFeaturesFields.search.branch = true
		return list?.filter(({ value }) => value === userLoginBranch)
	}

	function generateBranchList({ regionsSelected = [], transform = (item) => merge(item, { selected: false }) }) {
		let filteredBranches

		const mapBranchList = regionsSelected?.flatMap((region) => region?.branches)

		if (customBranchFieldByUserRole.allowToSelectItems) {
			filteredBranches = mapBranchList
		} else {
			filteredBranches = defaultBranchByRole({ list: mapBranchList })
		}

		return filteredBranches?.map(transform)
	}

	function generateStaffRoleOptions({ transform = (item) => ({ ...item, selected: false }) }) {
		return defaultStaffRoles.value?.map(transform)
	}
	const staffRoleOptions = computed(() => {
		return generateStaffRoleOptions({ transform: (item) => ({ ...item, selected: false }) })
	})

	const filterOptions = reactive({
		areas: [],
		regions: [],
		branches: [],
		staffRoles: staffRoleOptions.value,
	})

	watch(
		() => agentCodeOptions.value,
		async () => {
			/**
			 * poId: for case from ViewReminderUserDOBList view -> click on ViewMore and redirect to this view
			 */
			const poIdFromQuery = dataFromRoute?.poId
			if (poIdFromQuery) {
				// to replace the query on URL
				router.replace({
					query: null,
				})
				await readAllContractData({ agentCodeOptions: agentCodeOptions.value })
			}
		}
	)

	function refetchRegions({ transformRegions }) {
		filterOptions.regions = generateRegionList({ areasSelected: filterData.areas, transform: transformRegions })
		filterData.regions = []

		refetchBranches({ refetchBranches: {} })
	}

	function refetchBranches({ transformBranches }) {
		filterOptions.branches = generateBranchList({ regionsSelected: filterData.regions, transform: transformBranches })
		filterData.branches = []
	}

	function resetAgentCodeData() {
		v.value.contractManagesByAgent?.$reset()
		agentCodeOptions.value = []
		filterData.contractManagesByAgent = []
		warningNoAgentCodeMatch.value = false
	}
	// if change branch , reset roles
	function resetStaffRoles() {
		filterData.staffTypes = []
		filterOptions.staffRoles = generateStaffRoleOptions({ transform: (item) => ({ ...item, selected: false }) })

		resetAgentCodeData()
	}

	function resetDefaultForm() {
		filterData.customerIdentify = ""
		filterData.contractNo = ""
		filterData.contractStatus = ""
		filterData.createdAt = {
			dateType: "",
			type: "between", // use crunchDate from composables file to search createAt field
			value: [],
			quickOptionValue: "",
		}
	}

	function resetLocationsAndDepartmentsForm() {
		filterData.contractManagesByAgent = []
		resetStaffRoles()

		if (filterData.searchBy === SEARCH_TYPES.AGENT) {
			toggleLocationSelectionByAgent()
		} else {
			toggleLocationSelectionByBranch()
		}

		warningNoAgentCodeMatch.value = false
		contractListAreEmpty.value = null
	}

	function resetDataForm() {
		resetDefaultForm()
		resetLocationsAndDepartmentsForm()
	}

	const handleClearFilter = () => {
		loaders.clearFilter.start()

		resetDataForm()

		initDataBySearchType({ searchBy: filterData.searchBy, bankLocations: originalBankLocationList.value })

		setDefaultContractStatusAfterClearFilter()

		v.value.$reset()

		loaders.clearFilter.end()
	}

	function openLocationsSelection() {
		disabledFeaturesFields.show.area = true
		if (filterData.staffTypes?.length && filterData.staffTypes?.find((staff) => staff.value === INSURANCE_EXPERT_USER.NewTIS)) {
			disabledFeaturesFields.show.region = false
			disabledFeaturesFields.show.branch = false
		} else {
			disabledFeaturesFields.show.region = true
			disabledFeaturesFields.show.branch = true
		}
	}

	function closeLocationsSelection() {
		disabledFeaturesFields.show.area = false
		disabledFeaturesFields.show.region = false
		disabledFeaturesFields.show.branch = false
	}

	function toggleLocationSelectionByAgent() {
		disabledFeaturesFields.show.staffRole = true
		disabledFeaturesFields.show.agent = false

		disabledFeaturesFields.optionAll.branches = true

		closeLocationsSelection()
	}
	function toggleLocationSelectionByBranch() {
		disabledFeaturesFields.show.staffRole = false
		disabledFeaturesFields.show.agent = false

		disabledFeaturesFields.optionAll.branches = false

		openLocationsSelection()
	}

	function onToggleSelectionBoxes({ searchBy }) {
		if (searchBy === SEARCH_TYPES.AGENT) {
			toggleLocationSelectionByAgent()
		} else {
			toggleLocationSelectionByBranch()
		}
	}

	function onUpdateSearchType(val) {
		filterData.searchBy = val

		v.value.$reset()
		resetLocationsAndDepartmentsForm()

		onToggleSelectionBoxes({ searchBy: val })

		initDataBySearchType({ searchBy: val, bankLocations: originalBankLocationList.value })
	}

	function initAreas({ bankLocations }) {
		filterOptions.areas = generateAreaList({ bankLocations })
		if (newTISroleSelectedByHoManager.value) {
			filterData.areas = []
		} else {
			filterData.areas = filterOptions.areas
		}
	}

	function initRegions() {
		filterOptions.regions = generateRegionList({
			areasSelected: filterData.areas,
			transform: (item) => merge(item, { selected: true }),
		})
		onUpdateRegions(filterOptions.regions)
	}

	function initBranches() {
		const defaultValueIsNull = customBranchFieldByUserRole.optionDefaultValueIsNull

		filterOptions.branches = generateBranchList({
			regionsSelected: filterData.regions,
			transform: (item) => merge(item, { selected: !defaultValueIsNull }),
		})

		if (defaultValueIsNull) {
			filterData.branches = []
		} else filterData.branches = filterOptions.branches
	}

	function setDefaultLocations({ bankLocations }) {
		initAreas({ bankLocations })

		initRegions()

		initBranches()
	}

	function setDefaultContractStatusAfterClearFilter() {
		filterData.contractStatus = "all"
	}

	function getLocationsByRoleSelected() {
		if (!filterData.staffTypes?.length) return null

		const listed = {
			areas: [],
			branches: [],
		}
		if (!selectedRoleIsNewTis.value) {
			listed.branches = filterData.branches
		} else {
			listed.areas = filterData.areas
		}
		return listed
	}

	async function initAgentOptionsByRole() {
		if (allowToFetchAgentCodes.value) {
			const listedOptions = getLocationsByRoleSelected()

			await fetchAllAgentCode({
				areas: listedOptions?.areas,
				branches: listedOptions?.branches,
				staffTypes: filterData.staffTypes,
			})
		}
	}

	function initDataBySearchType({ searchBy, bankLocations }) {
		// To: always set default whenever the searchBy selected is
		setDefaultLocations({ bankLocations })

		if (searchBy === SEARCH_TYPES.AGENT) {
			resetStaffRoles()
		}
	}

	async function initData() {
		try {
			loaders.pageLoader.start()

			onToggleSelectionBoxes({ searchBy: filterData.searchBy })

			originalBankLocationList.value = await fetchAllLocationList()

			initDataBySearchType({ searchBy: filterData.searchBy, bankLocations: originalBankLocationList.value })
		} catch (e) {
			handleErrorForUser({ error: e, $t: t })
		} finally {
			loaders.pageLoader.end()
		}
	}
	onMounted(async () => {
		await initData()
	})

	// Export function

	const fetchAllPoliciesToExport = async ({ agentCodeOptions }) => {
		loadingStates.isExportingFile = true
		try {
			let pageNumber = 0
			let fullCasesData = []
			let contractsData = await readAllCases({
				overridePageSize: NUMBER_LIMIT,
				overrideCurrentPage: pageNumber,
				agentCodeOptions,
			})
			const totalCount = contractsData?.value?.totalElements
			const numberOfPages = Math.ceil(totalCount / NUMBER_LIMIT)
			if (numberOfPages > 1) {
				fullCasesData = contractsData?.value?.data
				while (pageNumber < numberOfPages) {
					pageNumber++
					contractsData = await readAllCases({ overridePageSize: NUMBER_LIMIT, overrideCurrentPage: pageNumber })
					fullCasesData = [...fullCasesData, ...(contractsData?.value?.data || [])]
				}
				return fullCasesData
			} else {
				return contractsData?.value?.data
			}
		} catch (e) {
			console.error(e)
		} finally {
			loadingStates.isExportingFile = false
		}
	}

	const fetchAllIndividualsToExport = async ({ agentCodeOptions }) => {
		fullContractListToExport.value = await fetchAllPoliciesToExport({ agentCodeOptions })
		const contractsData = cloneDeep(fullContractListToExport.value)
		const mapIndividualList = (contractsData || [])?.map((i) => i?.bankStaffId)?.filter(Boolean)
		const contractsChunk = chunk(mapIndividualList, 20)
		const chunkIndividuals = await Promise.all(contractsChunk.map((list) => fetchIndividuals(list)))
		return concat(...chunkIndividuals?.map((item) => item))
	}
	const setPolicyListToExport = async ({ agentCodeOptions }) => {
		const fetchIndividualList = await fetchAllIndividualsToExport({ agentCodeOptions })
		const dataToExport = fullContractListToExport?.value?.map((p) => {
			const individualName = fetchIndividualList?.find((i) => JSON.parse(i?.fields || "{}")?.staffId === p?.bankStaffId)?.name
			return {
				...p,
				bankStaffName: individualName || "-",
			}
		})
		const chunkList = chunk(dataToExport, 20)
		const templateToExport = (chunkList || [])?.reduce((acc, list) => {
			const batch = list?.map((_case, idx) => {
				const no = idx + breakPointNum.value
				const mPaymentMode = mapPaymentModes.value?.find((i) => i.value === _case?.paymentMode)
				const mNBPendingReason = mapNbPendingReason.value?.find((i) => i.value === _case?.nbPendingReason)
				return {
					STT: no,
					"Số HĐ": _case?.policyNo,
					"Lead GCM": _case?.leadId,
					"Mã số đại lý bán": _case?.tiotisSellingCode,
					"Tên đại lý bán": _case?.tiotisSellingName,
					"Mã số đại lý phục vụ": _case?.servicingAgentCode,
					"Tên đại lý phục vụ": _case?.servicingAgentName,
					"ID NS người refer": _case?.bankStaffId,
					"Tên người refer": _case?.bankStaffName, // get data from itcbLife DB -> use bankstaffId as a key to map data
					"Tình trạng HĐ": `${_case?.policyStatus} - ${getValPolicyStatusByCodeMVL({ status: _case?.policyStatus })}`,
					"Tên khách hàng": _case?.poName,
					"CMTND/CCCD/HC": _case?.poId,
					"Mệnh giá": _case?.sumAssured,
					"Phí bảo hiểm": _case?.ape,
					APE: _case?.ape,
					"Ngày nộp HĐ": _case?.submissionDate,
					"Ngày phát hành HĐ": _case?.issuanceDate,
					"Định kỳ đóng phí": mPaymentMode?.name,
					"Pending description":
						_case?.nbPendingReason && mNBPendingReason
							? `${_case?.nbPendingReason}: ${mNBPendingReason?.name}`
							: _case?.nbPendingReason && `${_case?.nbPendingReason}: ${t("dashboard.otherReason")}`,
				}
			})
			breakPointNum.value += list?.length
			return [...acc, ...batch]
		}, [])
		return templateToExport
	}
	const exportToFile = async ({ agentCodeOptions }) => {
		const list = await setPolicyListToExport({ agentCodeOptions })
		const mBranchName = originalBankLocationList.value?.find((b) => b?.value === userLoginBranch)?.name
		const layoutTitle = [
			["BÁO CÁO THEO DÕI HỢP ĐỒNG BẢO HIỂM"],
			["Từ ngày:", formatData(filterData?.createdAt?.value?.[0]), "đến ngày", formatData(filterData?.createdAt?.value?.[1])],
			["Mã CN:", formatData(userLoginBranch), "Tên chi nhánh", formatData(mBranchName)],
		]
		const me = store.getters.me
		const staffId = JSON.parse(me?.associatedUser?.fields || "{}")?.staffId || ""
		const fileName = {
			exportDate: dayjs(new Date()).format("YYYYMMDD"),
			userName:
				me?.associatedUser?.name && me?.associatedUser?.name?.includes(".")
					? me?.associatedUser?.name?.split(".")?.join("_")
					: me?.associatedUser?.name,
			staffId: staffId && staffId?.includes(".") ? staffId?.split(".")?.join("_") : staffId,
		}

		// /* generate worksheet */
		const wb = XLSX.utils.book_new()
		const ws = XLSX.utils.aoa_to_sheet(layoutTitle, { origin: "A1" })
		XLSX.utils.sheet_add_json(ws, list, { origin: "A8" })

		XLSX.utils.book_append_sheet(wb, ws)

		const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" })
		const file = new Blob([excelBuffer], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
		})
		saveAs(file, `${fileName?.userName?.trim()}_${fileName?.staffId}_${fileName?.exportDate}`)
	}

	return {
		v,
		contractsTableHeader,
		contractList,
		contractListAreEmpty,
		exportToFile,
		totalItems,
		currentPage,
		limit,
		readAllContractData,
		readAllCases,
		agentCodeOptions,
		loadingStates,
		showDropdownRoles,
		hideDropdownRoles,
		// search agent code
		...filterDataFormToRefs,
		// Area filter
		staffRoleOptions,
		filterOptions,
		onUpdateAreas,
		onUpdateRegions,
		onUpdateBranches,
		onUpdateAgentCode,
		disabledFeaturesFields,
		loaders,
		onUpdateStaffType,
		warningNoAgentCodeMatch,
		mTextContractListAreEmpty,
		// conditions to choose filter
		userIsAllowedToChooseRegions,
		isHoManager,
		showDropDownBranchList,
		hideDropdownBranchList,
		resetStaffRoles,
		onUpdateSearchType,
		handleClearFilter,
		areasIsRequiredByRole,
		showDropDownAreaList,
		hideDropdownAreaList,
	}
}
