import { findBenefitName, mRidersOfProduct } from "../helpers"
import { PRODUCT_NAME } from "@/modules/customerSupport/constants"
import { PERIODIC_PAYMENT_TERM } from "@/modules/rbo/utils/constants"

function buildSubFacts({ dataList = [] }) {
	if (!dataList.length) return []
	return dataList?.map((item) => {
		const mBenefitData = {
			key: item.benefitName,
			value: {
				objectValue: [
					{
						key: "isCheckedMainBenefit",
						value: {
							booleanValue: item.isCheckedMainBenefit,
						},
					},
					{
						key: "amountProtection",
						value: {
							numberValue: item.amountProtection || null,
						},
					},
					{
						key: "year",
						value: {
							numberValue: item.year || null,
						},
					},
					{
						key: "APEYear",
						value: {
							numberValue: item.APEYear || null,
						},
					},
					{
						key: "APEIncome",
						value: {
							numberValue: item.APEIncome || null,
						},
					},
				],
			},
		}
		if (item.otherValue) {
			mBenefitData.value.objectValue.push({
				key: "otherValue",
				value: {
					stringValue: item.otherValue || "",
				},
			})
		}
		if (item?.benefitOptions?.length) {
			const mBenefitOptions = item?.benefitOptions?.map((bo) => {
				return {
					key: bo?.riderName,
					value: {
						objectValue: [
							{
								key: "isCheckedRider",
								value: {
									booleanValue: bo.isCheckedRider,
								},
							},
							{
								key: "amountProtection",
								value: {
									numberValue: bo.amountProtection || null,
								},
							},
							{
								key: "APEYear",
								value: {
									numberValue: bo.APEYear || null,
								},
							},
							{
								key: "APEIncome",
								value: {
									numberValue: bo.APEIncome || null,
								},
							},
						],
					},
				}
			})
			mBenefitData.value.objectValue.push({
				key: "riders",
				value: { objectValue: mBenefitOptions },
			})
		}
		return mBenefitData
	})
}
function buildDataLifeProtection(overwriteProductOptions) {
	const lifeProtectionBenefit = findBenefitName({
		listData: overwriteProductOptions?.benefitsData,
		benefitName: "lifeProtection",
	})
	const dataValue = buildSubFacts({ dataList: lifeProtectionBenefit?.dataOptions })
	return {
		objectValue: dataValue,
	}
}

function buildDataAccidentBenefit(overwriteProductOptions) {
	const accidentProtectionBenefit = findBenefitName({
		listData: overwriteProductOptions?.benefitsData,
		benefitName: "accidentProtection",
	})
	const dataValue = buildSubFacts({ dataList: accidentProtectionBenefit?.dataOptions })
	return {
		objectValue: dataValue,
	}
}
function buildIllnessBenefit(overwriteProductOptions) {
	const illnessProtectionBenefit = findBenefitName({
		listData: overwriteProductOptions?.benefitsData,
		benefitName: "illnessProtection",
	})
	const dataValue = buildSubFacts({ dataList: illnessProtectionBenefit?.dataOptions })
	return {
		objectValue: dataValue,
	}
}
function buildHealthCareBenefit(overwriteProductOptions) {
	const healthcareProtectionBenefit = findBenefitName({
		listData: overwriteProductOptions?.benefitsData,
		benefitName: "healthcareProtection",
	})
	const dataValue = buildSubFacts({ dataList: healthcareProtectionBenefit?.dataOptions })
	return {
		objectValue: dataValue,
	}
}
function buildMedicalBenefit(overwriteProductOptions) {
	const medicalCareBenefit = findBenefitName({
		listData: overwriteProductOptions?.benefitsData,
		benefitName: "medicalCare",
	})
	const dataValue = buildSubFacts({ dataList: medicalCareBenefit?.dataOptions })
	return {
		objectValue: dataValue,
	}
}
function buildDataCancerBenefit(overwriteProductOptions) {
	const cancerProtectionBenefit = findBenefitName({
		listData: overwriteProductOptions?.benefitsData,
		benefitName: "benefitCancerProtection",
	})
	const dataValue = buildSubFacts({ dataList: cancerProtectionBenefit?.dataOptions })
	return {
		objectValue: dataValue,
	}
}
export function factsStructure(getters) {
	const mOverwriteProductOptions = getters?.overwriteProductOptions?.[getters.selectedProduct]
	const lifeProtectionBenefit = buildDataLifeProtection(mOverwriteProductOptions)
	const accidentProtectionBenefit = buildDataAccidentBenefit(mOverwriteProductOptions)
	const illnessProtectionBenefit = buildIllnessBenefit(mOverwriteProductOptions)
	const healthcareProtectionBenefit = buildHealthCareBenefit(mOverwriteProductOptions)
	const medicalCareBenefit = buildMedicalBenefit(mOverwriteProductOptions)
	const cancerProtectionBenefit = buildDataCancerBenefit(mOverwriteProductOptions)

	const { amountProtection: totalSA, APEYear: totalAPEYear, APEIncome: totalAPEIncome, financialAffordability } =
		mOverwriteProductOptions.totalBenefit || {}
	const mRidersProducts = mRidersOfProduct({ mainBenefitList: mOverwriteProductOptions?.benefitsData })
	const toStringFactValue = (value) => ({ stringValue: value })
	const mapRiderKeys = mRidersProducts?.map(toStringFactValue)

	const valueList = {
		type: "selectedProduct",
		value: {
			objectValue: [
				{
					key: "name",
					value: { stringValue: getters?.selectedProduct },
				},
				{
					key: "sa",
					value: { numberValue: totalSA },
				},
				{
					key: "totalAPEIncome",
					value: { numberValue: totalAPEIncome },
				},
				{
					key: "totalAPEYear",
					value: { numberValue: totalAPEYear },
				},
				{
					key: "paymentTerm",
					value: { numberValue: getters?.selectedProductDetail?.paymentTerm || PERIODIC_PAYMENT_TERM },
				},
				{
					key: "productPeriodicPayment",
					value: { numberValue: getters?.selectedProductDetail?.paymentTerm || PERIODIC_PAYMENT_TERM },
				},
				{
					key: "investmentProfile",
					value: { stringValue: mOverwriteProductOptions?.investmentPreference },
				},
				{
					key: "financialAffordability",
					value: { numberValue: financialAffordability },
				},
				{
					key: "financialAffordabilityAmount",
					value: { numberValue: mOverwriteProductOptions?.financialAffordabilityAmount },
				},
				{
					key: "estimatedPremiumYear",
					value: { numberValue: mOverwriteProductOptions?.estimatedPremiumYear },
				},
				{
					key: "lifeProtection",
					value: lifeProtectionBenefit,
				}, // base product
			],
		},
	}
	if (getters?.selectedProduct !== PRODUCT_NAME.titan_6years && getters?.selectedProduct !== PRODUCT_NAME.titan_10years) {
		valueList.value.objectValue.push(
			{
				key: "riders",
				value: {
					arrayValue: mapRiderKeys,
				},
			},
			{
				key: "accidentProtection",
				value: accidentProtectionBenefit,
			}, // rider data
			{
				key: "illnessProtection",
				value: illnessProtectionBenefit,
			}, // rider data
			{
				key: "healthcareProtection",
				value: healthcareProtectionBenefit,
			}, // rider data
			{
				key: "medicalCare",
				value: medicalCareBenefit,
			}, // rider data
			{
				key: "lateStageCancer",
				value: cancerProtectionBenefit,
			}
		)
	} else {
		const medicalBenefit = {
			objectValue: medicalCareBenefit.objectValue?.filter((item) => item?.key === "medicalCashAllowance"),
		}
		valueList.value.objectValue.push(
			{
				key: "medicalCare",
				value: medicalBenefit,
			} // benefit
		)
	}

	return valueList
}
