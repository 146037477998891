import router from "@/router"
import getEnv from "@/utils/getEnv"

export default {
	addToastMessage(state, payload) {
		state.messages = { ...state.messages, ...payload }
	},
	removeToastMessage(state, payload) {
		if (state.messages[payload]) {
			state.messages[payload].cb && state.messages[payload].cb()
			delete state.messages[payload]
			state.messages = { ...state.messages }
		}
	},
	setTenantSettings(state, payload) {
		state.tenantSettings = payload
	},
	setTenantId(state, payload) {
		state.tenantId = payload
	},
	setAccessToken(state, payload) {
		state.accessToken = payload
	},
	setTokenExpired(state, payload) {
		state.tokenExpired = payload
	},
	setClientId(state, payload) {
		state.clientId = payload
	},
	logout(state) {
		const tenantId = state?.tenantId
		const clientId = state?.clientId
		if (clientId !== "covergo_crm") router.push(`/login?tenantId=${tenantId}&?clientId=${clientId}`)
		else router.push(`/login?tenantId=${tenantId}`)
		// Clear token
		localStorage.setItem(getEnv("VUE_APP_TOKEN_KEY"), null)
		localStorage.setItem(getEnv("VUE_APP_TOKEN_EXPIRES"), null)
	},
	setMe(state, payload) {
		state.me = payload
	},
	setAppConfig(state, payload) {
		state.appConfig = payload
	},
	setLeadGCM(state, payload) {
		state.leadGCM = payload
	},
}
