<template>
	<JModalSimple isVisible class="z-50" variant="center-3xl-border-gray">
		<JBox class="text-center">
			<JButton @click="$emit('cancel')" class="absolute top-1 right-0" iconPrefix="X" variant="tertiary"></JButton>
			<JBox>
				<JBox class="h-12">
					<JHeadline as="h2" variant="headline" class="text-c0-800 pointer-events-none">
						{{ $t("fna.areYouSureToLogOut") }}
					</JHeadline>
				</JBox>
				<JFlex class="justify-center mt-10">
					<JButton class="bg-c0-100" variant="tertiary-outline" @click="$emit('cancel')">
						{{ $t("fna.cancelBtn") }}
					</JButton>
					<JButton class="text-cWhite bg-c0-800 ml-4" variant="primary-outline" @click="onHandleLogout">
						{{ $t("fna.logoutText") }}
					</JButton>
				</JFlex>
			</JBox>
		</JBox>
	</JModalSimple>
</template>
<script>
import { PublicClientApplication } from "@azure/msal-browser"
import getEnv from "@/utils/getEnv"
export default {
	name: "CLogout",
	// eslint-disable-next-line vue/prop-name-casing
	props: ["FNAState"],
	created() {
		this.$msalInstance = new PublicClientApplication(this.$store.state.msalConfig)
	},
	async mounted() {
		await this.$msalInstance.handleRedirectPromise()
	},
	methods: {
		async onHandleLogout() {
			// you can select which account application should sign out
			const logoutRequest = {
				account: this.$msalInstance.getAccountByHomeId(this.$store.state.accessToken),
				mainWindowRedirectUri: getEnv("VUE_APP_COVERGO_LOGOUT_URI"),
				postLogoutRedirectUri: getEnv("VUE_APP_COVERGO_LOGOUT_URI"),
			}
			localStorage.setItem(getEnv("VUE_APP_TOKEN_KEY"), null)
			localStorage.removeItem(getEnv("VUE_APP_TOKEN_EXPIRES"))

			await this.$msalInstance.logoutRedirect(logoutRequest)
		},
	},
}
</script>
