import { createApp } from "vue"

import App from "./App.vue"
import router from "./router"
import store from "./store"
// import { i18n } from "./setups/i18n.js"
import directivesRegistration from "./setups/directivesRegistration.js"
import globalComponentsRegistration from "./setups/globalComponentsRegistration.js"
import { makeGetComponentVariants } from "@covergo/cover-components"
import VueGtag from "vue-gtag-next"
import "@/assets/css/tailwind.css"
import { initializeTenant } from "./initialize"
import VueApexCharts from "vue3-apexcharts"
import Emitter from "tiny-emitter"
import getEnv from "@/utils/getEnv"
import createForm from "@/setups/form.js"
import { registerVariants } from "./composables/variant"

// import { inspect } from "@xstate/inspect"
// if (process.env.NODE_ENV === "development") inspect({ iframe: false })
const defaultTenant = getEnv("VUE_APP_TENANT_ID")
const defaultClient = "covergo_crm"
/* Set tenantId and clientId *
 *  First priority is always route params, we check if is passed. If yes, we use this.
 *  Second priority is localStorage, if it exists there, we fetch and use that.
 * lastly if neither route param or localStorage is undefined or null we use defaults
 */
const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const tenantId = urlParams.get("tenantId") || defaultTenant
const clientId = urlParams.get("clientId") || defaultClient

const app = createApp(App)

app.config.globalProperties.$msalInstance = {}
app.config.globalProperties.$emitter = new Emitter()
window.test = null
// we initialize tenant before mounting app
initializeTenant({ tenantId, clientId }).then(async ({ variants, i18n }) => {
	await store.dispatch("setAccessToken", localStorage[getEnv("VUE_APP_TOKEN_KEY")] || null)

	app.use(router).use(i18n).use(store).use(VueApexCharts)
	app.use(VueGtag, {
		property: {
			id: "UA-189045296-1",
		},
	})

	createForm(app)
	globalComponentsRegistration(app)
	directivesRegistration(app)

	registerVariants(variants)

	app.provide("getComponentVariants", makeGetComponentVariants({ variants }))
	app.mount("#app")
})

export { app }
