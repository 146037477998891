<template>
	<JBox v-show="totalCount > 0">
		<JText>
			{{ $t("core.showing") }} {{ (currentPage - 1) * limit + 1 }} - {{ Math.min(currentPage * limit, totalCount) }}
			{{ $t("core.of") }}
			{{ totalCount }}
		</JText>
	</JBox>
</template>

<script>
export default {
	name: "CPaginationStatus",
	props: {
		currentPage: {
			type: Number,
			default: 1,
		},
		limit: {
			type: Number,
			default: 1,
		},
		totalCount: {
			type: Number,
			default: 0,
		},
	},
}
</script>
