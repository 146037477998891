<template>
	<JBox>
		<CAccordion class="mb-2 border border-c0-200 border-opacity-25" headVariant="h2" :title="$t('core.commissionSplit')">
			<!-- Display Rules List -->
			<JBox class="px-5">
				<CEditableField
					v-for="(item, index) in rulesList"
					:key="index"
					:itemId="item.id"
					:label="decideLabel(index, item)"
					:value="decideValue(item)"
					@delete="confirmRemoveRule($event)"
					@edit="handleUpdateRule($event)"
				/>
				<span v-if="rulesList.length === 0">
					<JText>{{ $t("core.noDataHere") }}</JText>
				</span>
				<JButton v-if="!actionType" class="mt-6" @click="handleAddRule()">
					<JFlex class="items-center">
						<JIcon icon="PlusCircle" class="mr-2" />
						{{ $t("core.addRule") }}
					</JFlex>
				</JButton>
			</JBox>
			<!-- Add New Rule Section -->
			<AddUpdateRule
				class="px-5"
				v-if="actionType"
				:rules="rulesList"
				:action="actionType"
				:updatePayload="computedUpdatePayload"
				@add="confirmAddRule($event)"
				@update="confirmUpdateRule($event)"
				@add:cancel="cancelAdd"
				@update:cancel="cancelUpdate"
			/>
		</CAccordion>
	</JBox>
</template>

<script>
import { usePolicyCommissionSplit } from "../../use/usePolicyCommissionSplit"
import { useCaseCommissionSplit } from "../../use/useCaseCommissionSplit"

import AddUpdateRule from "./AddUpdateRule"
import { useI18n } from "vue-i18n"
import { ref, computed } from "vue"

export default {
	components: {
		AddUpdateRule,
	},
	props: {
		for: {
			type: String,
			default: () => "policy", // policy or case
		},
	},
	setup(props) {
		const { t } = useI18n()
		const useService = props?.for === "policy" ? usePolicyCommissionSplit : useCaseCommissionSplit
		const { send, queryVariables, commissionData, idOfRuleToDelete } = useService()

		/** Handle Displaying Rules List */
		const rulesList = computed(() => commissionData.value || [])
		const getBaseIdFromJsonLogic = (jsonRule) => {
			const parsed = JSON?.parse(jsonRule)
			return parsed && parsed["*"] && parsed["*"][0] ? parsed["*"][0].var : null
		}
		const decideLabel = (index, rule) => {
			return `${index + 1}. ${rule?.entity?.name}` || "---"
		}
		const decideValue = (rule) => {
			const ruleType = rule?.type
			if (ruleType === "ratio") {
				return `${rule?.ratio * 100}% ${t("core.ofPremium")}`
			}
			if (ruleType === "flat") {
				return `${rule?.currencyCode} ${rule?.amount}`
			}
			if (ruleType === "ratioBasedOnOtherRule") {
				const idOfOtherRule = getBaseIdFromJsonLogic(rule?.jsonRule)
				const indexOfOtherRule = rulesList.value?.findIndex((item) => item.id === idOfOtherRule) + 1
				return `${rule?.baseCommissionRatio?.ratio * 100}% ${t("core.ofRule")} ${indexOfOtherRule}`
			}
		}

		/** Handle toggling Add/Update Rule Section */
		const actionType = ref(null)
		const handleAddRule = () => {
			actionType.value = "add"
			send("ADD_RULE")
		}
		const hideAddUpdateSection = () => {
			actionType.value = null
		}
		const confirmAddRule = (payload) => {
			queryVariables.value = payload
			send("CONFIRM_ADD")
			hideAddUpdateSection()
		}
		const confirmRemoveRule = (id) => {
			idOfRuleToDelete.value = id
			send("CONFIRM_REMOVE")
		}

		/** Update Rule */
		const updatePayload = ref(null)
		const computedUpdatePayload = computed(() => updatePayload.value)
		const handleUpdateRule = ({ id }) => {
			updatePayload.value = rulesList?.value?.find((item) => item.id === id)
			actionType.value = "update"
			send("UPDATE_RULE")
		}
		const cancelAdd = () => {
			send("CANCEL_ADD")
			hideAddUpdateSection()
		}
		const cancelUpdate = () => {
			send("CANCEL_UPDATE")
			hideAddUpdateSection()
		}
		const confirmUpdateRule = (payload) => {
			queryVariables.value = payload
			send("CONFIRM_UPDATE")
			hideAddUpdateSection()
		}

		return {
			rulesList,
			queryVariables,
			idOfRuleToDelete,
			decideLabel,
			decideValue,
			actionType,
			handleAddRule,
			confirmAddRule,
			confirmRemoveRule,
			updatePayload,
			computedUpdatePayload,
			handleUpdateRule,
			cancelAdd,
			cancelUpdate,
			confirmUpdateRule,
		}
	},
}
</script>
