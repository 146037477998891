import { ref } from "vue"
import { Machine } from "xstate"
import { useMachine } from "@/modules/core/composables/useMachine"
import { commissionSplitMachine } from "../machine/commissionSplitMachine"
import {
	gql,
	apiPromise,
	apiCases,
	apiAddCommissionToOffer,
	apiRemoveCommissionFromOffer,
	apiUpdateCommissionOfOffer,
} from "@covergo/cover-composables"

import { fetcher } from "../api/fetcher"
import { handleErrorForUser } from "../api/handleErrorForUser"

import { useI18n } from "vue-i18n"

import { useRoute } from "vue-router"

export function useCaseCommissionSplit() {
	const { t } = useI18n()
	const route = useRoute()

	const commissionData = ref([])

	const queryVariables = ref({})
	const idOfRuleToDelete = ref(null)

	const caseId = route?.params?.caseId
	const proposalId = route?.params?.proposalId
	const offerId = route?.params?.offerId

	const options = {
		services: {
			async readCommissionSplitData() {
				const variables = {
					where: {
						id: caseId,
					},
					proposalWhere: {
						id: proposalId,
					},
					offerWhere: {
						id: offerId,
					},
				}
				const fragment = gql`
					fragment result on cases {
						list {
							proposals(where: $proposalWhere) {
								basket(where: $offerWhere) {
									id
									commissionSplitRules {
										id
										entity {
											__typename
											id
											name
										}
										amount
										jsonRule
										type
										currencyCode
										ratio
										remark
										baseCommissionRatio {
											ratio
										}
									}
								}
							}
						}
					}
				`
				return await apiPromise(apiCases, {
					variables,
					fetcher,
					fragment,
				})
			},
			async addCommissionSplitRule() {
				const variables = {
					caseId,
					proposalId,
					offerId,
					...queryVariables.value,
				}
				return await apiPromise(apiAddCommissionToOffer, {
					variables,
					fetcher,
				})
			},
			async removeCommissionSplitRule() {
				const variables = {
					caseId,
					proposalId,
					offerId,
					id: idOfRuleToDelete.value,
				}
				return await apiPromise(apiRemoveCommissionFromOffer, {
					variables,
					fetcher,
				})
			},
			async updateCommissionSplitRule() {
				const variables = {
					caseId,
					proposalId,
					offerId,
					...queryVariables.value,
				}
				return await apiPromise(apiUpdateCommissionOfOffer, {
					variables,
					fetcher,
				})
			},
		},
		actions: {
			setResults(ctx, event) {
				commissionData.value = event?.data?.list?.[0]?.proposals?.[0]?.basket?.[0]?.commissionSplitRules || []
			},
			setFailureMessage(ctx, event) {
				handleErrorForUser({ error: event?.data?.errors, $t: t })
			},
		},
	}

	const { state, send } = useMachine(Machine(commissionSplitMachine, options), {
		devTools: process.env.NODE_ENV === "development",
	})

	return {
		state,
		send,
		queryVariables,
		commissionData,
		idOfRuleToDelete,
	}
}
