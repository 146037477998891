/* eslint-disable camelcase */
import { PRODUCT_NAME } from "@/modules/customerSupport/constants"

const titan_6years = (...riders) => ({ name: PRODUCT_NAME.titan_6years, riders }) // Titanium 1 An Gia Nhu Y - 6 years
const titan_10years = (...riders) => ({ name: PRODUCT_NAME.titan_10years, riders }) // Titanium 1 An Gia Nhu Y - 10 years
const ul048 = (...riders) => ({ name: PRODUCT_NAME.ul048, riders }) // Titanium 1.1 - An Tam Vung Buoc
const ul038 = (...riders) => ({ name: PRODUCT_NAME.ul038, riders }) // UL2019 - Saving
const ul040 = (...riders) => ({ name: PRODUCT_NAME.ul040, riders }) // UL2019 - Protect
const titanium_2 = (...riders) => ({ name: PRODUCT_NAME.titanium_2, riders }) // ILP

/** Copy from productRecommendation file
 * const goalNamesMapping = {
		childrenEducation: "EDU",
		retirement: "RET",
		growWealthAndAssets: "GRO",
		financeUncertainty: "PRO",
	}
 */
/**
 * New rule of ADD : if user selected / inputted the accidentAndDisability (ADD) at step 3 => will map this value to be a rider name ADD-plus at step 6 and show it up
 */

export const productsMapping = {
	EDU: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	RET: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	GRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	PRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	ADD: [titanium_2("ADD-plus"), ul038("ADD-plus"), ul040("ADD-plus", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	ADD_MC: [
		titanium_2("ADD-plus", "MC"),
		ul038("ADD-plus", "MC"),
		ul040("ADD-plus", "MC", "CAN06"),
		titan_6years("MC"),
		titan_10years("MC"),
	],
	ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
	EDU_RET: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	EDU_GRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	EDU_PRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	EDU_ADD: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	EDU_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	EDU_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	EDU_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	EDU_ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	EDU_ADD_MC: [
		titanium_2("ADD-plus", "MC"),
		ul038("ADD-plus", "MC"),
		ul040("ADD-plus", "MC", "CAN06"),
		titan_6years("MC"),
		titan_10years("MC"),
	],
	EDU_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	EDU_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	EDU_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	EDU_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	EDU_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	EDU_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	EDU_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	EDU_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06"), titanium_2("CI-plus", "MC", "HC")],
	EDU_ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
	RET_GRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_PRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_ADD: [titanium_2("ADD-plus"), ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	RET_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	RET_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	RET_ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	RET_ADD_MC: [titanium_2("ADD-plus", "MC"), ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	RET_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	RET_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	RET_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	RET_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	RET_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	RET_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	RET_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	RET_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	RET_ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
	GRO_PRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	GRO_ADD: [titanium_2("ADD-plus"), ul038("ADD-plus"), ul040("ADD-plus", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	GRO_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	GRO_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	GRO_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	GRO_ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	GRO_ADD_MC: [
		titanium_2("ADD-plus", "MC"),
		ul038("ADD-plus", "MC"),
		ul040("ADD-plus", "MC", "CAN06"),
		titan_6years("MC"),
		titan_10years("MC"),
	],
	GRO_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	GRO_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	GRO_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	GRO_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	GRO_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	GRO_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	GRO_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	GRO_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	GRO_ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
	PRO_ADD: [titanium_2("ADD-plus"), ul038("ADD-plus"), ul040("ADD-plus", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	PRO_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	PRO_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	PRO_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	PRO_ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	PRO_ADD_MC: [
		titanium_2("ADD-plus", "MC"),
		ul038("ADD-plus", "MC"),
		ul040("ADD-plus", "MC", "CAN06"),
		titan_6years("MC"),
		titan_10years("MC"),
	],
	PRO_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	PRO_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	PRO_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	PRO_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	PRO_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	PRO_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	PRO_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	PRO_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	PRO_ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
	EDU_RET_GRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	EDU_RET_PRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	EDU_RET_ADD: [ul048("CAN06"), titanium_2("ADD-plus"), ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	EDU_RET_CI: [ul048("CAN06"), titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	EDU_RET_MC: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	EDU_RET_HC: [ul048("CAN06"), titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	EDU_RET_ADD_MC: [titanium_2("ADD-plus", "MC"), ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	EDU_RET_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	EDU_RET_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	EDU_RET_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	EDU_RET_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	EDU_RET_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	EDU_RET_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	EDU_RET_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	EDU_RET_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	EDU_RET_ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
	EDU_GRO_PRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	EDU_GRO_ADD: [
		titanium_2("ADD-plus", "MC"),
		ul038("ADD-plus", "MC"),
		ul040("ADD-plus", "MC", "CAN06"),
		titan_6years("MC"),
		titan_10years("MC"),
	],
	EDU_GRO_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	EDU_GRO_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	EDU_GRO_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	EDU_GRO_ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	EDU_GRO_ADD_MC: [
		titanium_2("ADD-plus", "MC"),
		ul038("ADD-plus", "MC"),
		ul040("ADD-plus", "MC", "CAN06"),
		titan_6years("MC"),
		titan_10years("MC"),
	],
	EDU_GRO_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	EDU_GRO_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	EDU_GRO_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	EDU_GRO_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	EDU_GRO_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	EDU_GRO_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	EDU_GRO_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	EDU_GRO_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	EDU_GRO_ADD_CI_MC_HC: [
		titanium_2("CI-plus", "MC", "HC", "ADD-plus"),
		ul038("CI-plus", "MC", "HC", "ADD-plus"),
		ul040("CI-plus", "MC", "HC", "ADD-plus", "CAN06"),
	],
	EDU_PRO_ADD: [
		titanium_2("MC", "ADD-plus"),
		ul038("MC", "ADD-plus"),
		ul040("MC", "ADD-plus", "CAN06"),
		titan_6years("MC"),
		titan_10years("MC"),
	],
	EDU_PRO_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	EDU_PRO_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06"), titan_6years("MC"), titan_10years("MC")],
	EDU_PRO_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	EDU_PRO_ADD_CI: [titanium_2("CI-plus", "ADD-plus"), ul038("CI-plus", "ADD-plus"), ul040("CI-plus", "ADD-plus", "CAN06")],
	EDU_PRO_ADD_MC: [
		titanium_2("MC", "ADD-plus"),
		ul038("MC", "ADD-plus"),
		ul040("MC", "ADD-plus", "CAN06"),
		titan_6years("MC"),
		titan_10years("MC"),
	],
	EDU_PRO_ADD_HC: [titanium_2("HC", "ADD-plus"), ul038("HC", "ADD-plus"), ul040("HC", "ADD-plus", "CAN06")],
	EDU_PRO_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	EDU_PRO_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	EDU_PRO_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	EDU_PRO_ADD_CI_MC: [
		titanium_2("CI-plus", "MC", "ADD-plus"),
		ul038("CI-plus", "MC", "ADD-plus"),
		ul040("CI-plus", "MC", "ADD-plus", "CAN06"),
	],
	EDU_PRO_ADD_CI_HC: [
		titanium_2("CI-plus", "HC", "ADD-plus"),
		ul038("CI-plus", "HC", "ADD-plus"),
		ul040("CI-plus", "HC", "ADD-plus", "CAN06"),
	],
	EDU_PRO_ADD_MC_HC: [titanium_2("MC", "HC", "ADD-plus"), ul038("MC", "HC", "ADD-plus"), ul040("MC", "HC", "ADD-plus", "CAN06")],
	EDU_PRO_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	EDU_PRO_ADD_CI_MC_HC: [
		titanium_2("CI-plus", "MC", "HC", "ADD-plus"),
		ul038("CI-plus", "MC", "HC", "ADD-plus"),
		ul040("CI-plus", "MC", "HC", "ADD-plus", "CAN06"),
	],
	RET_GRO_PRO: [ul048("CAN06"), titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_GRO_ADD: [titanium_2("ADD-plus"), ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	RET_GRO_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	RET_GRO_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_GRO_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	RET_GRO_ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	RET_GRO_ADD_MC: [titanium_2("ADD-plus", "MC"), ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	RET_GRO_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	RET_GRO_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	RET_GRO_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	RET_GRO_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06"), titanium_2("MC", "HC")],
	RET_GRO_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	RET_GRO_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	RET_GRO_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	RET_GRO_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	RET_GRO_ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
	RET_PRO_ADD: [titanium_2("ADD-plus"), ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	RET_PRO_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	RET_PRO_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_PRO_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	RET_PRO_ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	RET_PRO_ADD_MC: [titanium_2("ADD-plus", "MC"), ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	RET_PRO_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	RET_PRO_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	RET_PRO_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	RET_PRO_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	RET_PRO_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	RET_PRO_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	RET_PRO_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	RET_PRO_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	RET_PRO_ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
	GRO_PRO_ADD: [titanium_2("MC", "ADD-plus"), ul038("MC", "ADD-plus"), ul040("MC", "ADD-plus", "CAN06")],
	GRO_PRO_CI: [titanium_2("CI-plus"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	GRO_PRO_MC: [titanium_2("MC"), ul038("MC"), ul040("MC", "CAN06")],
	GRO_PRO_HC: [titanium_2("HC"), ul038("HC"), ul040("HC", "CAN06")],
	GRO_PRO_ADD_CI: [titanium_2("ADD-plus", "CI-plus"), ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	GRO_PRO_ADD_MC: [
		titanium_2("ADD-plus", "MC"),
		titan_6years("MC"),
		titan_10years("MC"),
		ul038("ADD-plus", "MC"),
		ul040("ADD-plus", "MC", "CAN06"),
	],
	GRO_PRO_ADD_HC: [titanium_2("ADD-plus", "HC"), ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	GRO_PRO_CI_MC: [titanium_2("CI-plus", "MC"), ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	GRO_PRO_CI_HC: [titanium_2("CI-plus", "HC"), ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	GRO_PRO_MC_HC: [titanium_2("MC", "HC"), ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	GRO_PRO_ADD_CI_MC: [
		titanium_2("ADD-plus", "CI-plus", "MC"),
		ul038("ADD-plus", "CI-plus", "MC"),
		ul040("ADD-plus", "CI-plus", "MC", "CAN06"),
	],
	GRO_PRO_ADD_CI_HC: [
		titanium_2("ADD-plus", "CI-plus", "HC"),
		ul038("ADD-plus", "CI-plus", "HC"),
		ul040("ADD-plus", "CI-plus", "HC", "CAN06"),
	],
	GRO_PRO_ADD_MC_HC: [titanium_2("ADD-plus", "MC", "HC"), ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	GRO_PRO_CI_MC_HC: [titanium_2("CI-plus", "MC", "HC"), ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	GRO_PRO_ADD_CI_MC_HC: [
		titanium_2("ADD-plus", "CI-plus", "MC", "HC"),
		ul038("ADD-plus", "CI-plus", "MC", "HC"),
		ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06"),
	],
}

export const productsDRBOMapping = {
	EDU: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038(), ul040("CAN06")],
	RET: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("MC"), ul040("MC", "CAN06")],
	GRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038(), ul040("CAN06")],
	PRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038(), ul040("CAN06")],
	ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	MC: [ul038("MC"), ul040("MC", "CAN06")],
	HC: [ul038("HC"), ul040("HC", "CAN06")],
	ADD_CI: [ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	ADD_MC: [ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	ADD_HC: [ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	ADD_CI_MC: [ul038("ADD-plus", "CI-plus", "MC"), ul040("ADD-plus", "CI-plus", "MC", "CAN06")],
	ADD_CI_HC: [ul038("ADD-plus", "CI-plus", "HC"), ul040("ADD-plus", "CI-plus", "HC", "CAN06")],
	ADD_MC_HC: [ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	ADD_CI_MC_HC: [ul038("ADD-plus", "CI-plus", "MC", "HC"), ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06")],
	EDU_RET: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("MC"), ul040("MC", "CAN06")],
	EDU_GRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038(), ul040("CAN06")],
	EDU_PRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038(), ul040("CAN06")],
	EDU_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	EDU_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	EDU_MC: [ul038("MC"), ul040("MC", "CAN06")],
	EDU_HC: [ul038("HC"), ul040("HC", "CAN06")],
	EDU_ADD_CI: [ul038("CI-plus", "ADD-plus"), ul040("CI-plus", "ADD-plus", "CAN06")],
	EDU_ADD_MC: [ul038("MC", "ADD-plus"), ul040("MC", "ADD-plus", "CAN06")],
	EDU_ADD_HC: [ul038("HC", "ADD-plus"), ul040("HC", "ADD-plus", "CAN06")],
	EDU_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	EDU_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	EDU_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	EDU_ADD_CI_MC: [ul038("CI-plus", "MC", "ADD-plus"), ul040("CI-plus", "MC", "ADD-plus", "CAN06")],
	EDU_ADD_CI_HC: [ul038("CI-plus", "HC", "ADD-plus"), ul040("CI-plus", "HC", "ADD-plus", "CAN06")],
	EDU_ADD_MC_HC: [ul038("MC", "HC", "ADD-plus"), ul040("MC", "HC", "ADD-plus", "CAN06")],
	EDU_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	EDU_ADD_CI_MC_HC: [ul038("CI-plus", "MC", "HC", "ADD-plus"), ul040("CI-plus", "MC", "HC", "ADD-plus", "CAN06")],
	RET_GRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_PRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	RET_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	RET_MC: [ul038("MC"), ul040("MC", "CAN06")],
	RET_HC: [ul038("HC"), ul040("HC", "CAN06")],
	RET_ADD_CI: [ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	RET_ADD_MC: [ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	RET_ADD_HC: [ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	RET_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	RET_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	RET_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	RET_ADD_CI_MC: [ul038("ADD-plus", "CI-plus", "MC"), ul040("ADD-plus", "CI-plus", "MC", "CAN06")],
	RET_ADD_CI_HC: [ul038("ADD-plus", "CI-plus", "HC"), ul040("ADD-plus", "CI-plus", "HC", "CAN06")],
	RET_ADD_MC_HC: [ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	RET_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	RET_ADD_CI_MC_HC: [ul038("ADD-plus", "CI-plus", "MC", "HC"), ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06")],
	GRO_PRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038(), ul040("CAN06")],
	GRO_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	GRO_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	GRO_MC: [ul038("MC"), ul040("MC", "CAN06")],
	GRO_HC: [ul038("HC"), ul040("HC", "CAN06")],
	GRO_ADD_CI: [ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	GRO_ADD_MC: [ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	GRO_ADD_HC: [ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	GRO_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	GRO_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	GRO_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	GRO_ADD_CI_MC: [ul038("ADD-plus", "CI-plus", "MC"), ul040("ADD-plus", "CI-plus", "MC", "CAN06")],
	GRO_ADD_CI_HC: [ul038("ADD-plus", "CI-plus", "HC"), ul040("ADD-plus", "CI-plus", "HC", "CAN06")],
	GRO_ADD_MC_HC: [ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	GRO_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	GRO_ADD_CI_MC_HC: [ul038("ADD-plus", "CI-plus", "MC", "HC"), ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06")],
	PRO_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	PRO_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	PRO_MC: [ul038("MC"), ul040("MC", "CAN06")],
	PRO_HC: [ul038("HC"), ul040("HC", "CAN06")],
	PRO_ADD_CI: [ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	PRO_ADD_MC: [ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	PRO_ADD_HC: [ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	PRO_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	PRO_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	PRO_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	PRO_ADD_CI_MC: [ul038("ADD-plus", "CI-plus", "MC"), ul040("ADD-plus", "CI-plus", "MC", "CAN06")],
	PRO_ADD_CI_HC: [ul038("ADD-plus", "CI-plus", "HC"), ul040("ADD-plus", "CI-plus", "HC", "CAN06")],
	PRO_ADD_MC_HC: [ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	PRO_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	PRO_ADD_CI_MC_HC: [ul038("ADD-plus", "CI-plus", "MC", "HC"), ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06")],
	EDU_RET_GRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("MC"), ul040("MC", "CAN06")],
	EDU_RET_PRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("MC"), ul040("MC", "CAN06")],
	EDU_RET_ADD: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	EDU_RET_CI: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	EDU_RET_MC: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("MC"), ul040("MC", "CAN06")],
	EDU_RET_HC: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("HC"), ul040("HC", "CAN06")],
	EDU_RET_ADD_CI: [ul038("CI-plus", "ADD-plus"), ul040("CI-plus", "ADD-plus", "CAN06")],
	EDU_RET_ADD_MC: [ul038("MC", "ADD-plus"), ul040("MC", "ADD-plus", "CAN06")],
	EDU_RET_ADD_HC: [ul038("HC", "ADD-plus"), ul040("HC", "ADD-plus", "CAN06")],
	EDU_RET_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	EDU_RET_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	EDU_RET_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	EDU_RET_ADD_CI_MC: [ul038("CI-plus", "MC", "ADD-plus"), ul040("CI-plus", "MC", "ADD-plus", "CAN06")],
	EDU_RET_ADD_CI_HC: [ul038("CI-plus", "HC", "ADD-plus"), ul040("CI-plus", "HC", "ADD-plus", "CAN06")],
	EDU_RET_ADD_MC_HC: [ul038("MC", "HC", "ADD-plus"), ul040("MC", "HC", "ADD-plus", "CAN06")],
	EDU_RET_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	EDU_RET_ADD_CI_MC_HC: [ul038("CI-plus", "MC", "HC", "ADD-plus"), ul040("CI-plus", "MC", "HC", "ADD-plus", "CAN06")],
	EDU_GRO_PRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038(), ul040("CAN06")],
	EDU_GRO_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	EDU_GRO_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	EDU_GRO_MC: [ul038("MC"), ul040("MC", "CAN06")],
	EDU_GRO_HC: [ul038("HC"), ul040("HC", "CAN06")],
	EDU_GRO_ADD_CI: [ul038("CI-plus", "ADD-plus"), ul040("CI-plus", "ADD-plus", "CAN06")],
	EDU_GRO_ADD_MC: [ul038("MC", "ADD-plus"), ul040("MC", "ADD-plus", "CAN06")],
	EDU_GRO_ADD_HC: [ul038("HC", "ADD-plus"), ul040("HC", "ADD-plus", "CAN06")],
	EDU_GRO_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	EDU_GRO_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	EDU_GRO_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	EDU_GRO_ADD_CI_MC: [ul038("CI-plus", "MC", "ADD-plus"), ul040("CI-plus", "MC", "ADD-plus", "CAN06")],
	EDU_GRO_ADD_CI_HC: [ul038("CI-plus", "HC", "ADD-plus"), ul040("CI-plus", "HC", "ADD-plus", "CAN06")],
	EDU_GRO_ADD_MC_HC: [ul038("MC", "HC", "ADD-plus"), ul040("MC", "HC", "ADD-plus", "CAN06")],
	EDU_GRO_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	EDU_GRO_ADD_CI_MC_HC: [ul038("CI-plus", "MC", "HC", "ADD-plus"), ul040("CI-plus", "MC", "HC", "ADD-plus", "CAN06")],
	EDU_PRO_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	EDU_PRO_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	EDU_PRO_MC: [ul038("MC"), ul040("MC", "CAN06")],
	EDU_PRO_HC: [ul038("HC"), ul040("HC", "CAN06")],
	EDU_PRO_ADD_CI: [ul038("CI-plus", "ADD-plus"), ul040("CI-plus", "ADD-plus", "CAN06")],
	EDU_PRO_ADD_MC: [ul038("MC", "ADD-plus"), ul040("MC", "ADD-plus", "CAN06")],
	EDU_PRO_ADD_HC: [ul038("HC", "ADD-plus"), ul040("HC", "ADD-plus", "CAN06")],
	EDU_PRO_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	EDU_PRO_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	EDU_PRO_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	EDU_PRO_ADD_CI_MC: [ul038("CI-plus", "MC", "ADD-plus"), ul040("CI-plus", "MC", "ADD-plus", "CAN06")],
	EDU_PRO_ADD_CI_HC: [ul038("CI-plus", "HC", "ADD-plus"), ul040("CI-plus", "HC", "ADD-plus", "CAN06")],
	EDU_PRO_ADD_MC_HC: [ul038("MC", "HC", "ADD-plus"), ul040("MC", "HC", "ADD-plus", "CAN06")],
	EDU_PRO_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	EDU_PRO_ADD_CI_MC_HC: [ul038("CI-plus", "MC", "HC", "ADD-plus"), ul040("CI-plus", "MC", "HC", "ADD-plus", "CAN06")],
	RET_GRO_PRO: [ul048("CAN06"), titan_6years("MC"), titan_10years("MC"), ul038("MC"), ul040("MC", "CAN06")],
	RET_GRO_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	RET_GRO_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	RET_GRO_MC: [ul038("MC"), ul040("MC", "CAN06")],
	RET_GRO_HC: [ul038("HC"), ul040("HC", "CAN06")],
	RET_GRO_ADD_CI: [ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	RET_GRO_ADD_MC: [ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	RET_GRO_ADD_HC: [ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	RET_GRO_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	RET_GRO_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	RET_GRO_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	RET_GRO_ADD_CI_MC: [ul038("ADD-plus", "CI-plus", "MC"), ul040("ADD-plus", "CI-plus", "MC", "CAN06")],
	RET_GRO_ADD_CI_HC: [ul038("ADD-plus", "CI-plus", "HC"), ul040("ADD-plus", "CI-plus", "HC", "CAN06")],
	RET_GRO_ADD_MC_HC: [ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	RET_GRO_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	RET_GRO_ADD_CI_MC_HC: [ul038("ADD-plus", "CI-plus", "MC", "HC"), ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06")],
	RET_PRO_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	RET_PRO_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	RET_PRO_MC: [ul038("MC"), ul040("MC", "CAN06")],
	RET_PRO_HC: [ul038("HC"), ul040("HC", "CAN06")],
	RET_PRO_ADD_CI: [ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	RET_PRO_ADD_MC: [ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	RET_PRO_ADD_HC: [ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	RET_PRO_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	RET_PRO_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	RET_PRO_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	RET_PRO_ADD_CI_MC: [ul038("ADD-plus", "CI-plus", "MC"), ul040("ADD-plus", "CI-plus", "MC", "CAN06")],
	RET_PRO_ADD_CI_HC: [ul038("ADD-plus", "CI-plus", "HC"), ul040("ADD-plus", "CI-plus", "HC", "CAN06")],
	RET_PRO_ADD_MC_HC: [ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	RET_PRO_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	RET_PRO_ADD_CI_MC_HC: [ul038("ADD-plus", "CI-plus", "MC", "HC"), ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06")],
	GRO_PRO_ADD: [ul038("ADD-plus"), ul040("ADD-plus", "CAN06")],
	GRO_PRO_CI: [ul038("CI-plus"), ul040("CI-plus", "CAN06")],
	GRO_PRO_MC: [ul038("MC"), ul040("MC", "CAN06")],
	GRO_PRO_HC: [ul038("HC"), ul040("HC", "CAN06")],
	GRO_PRO_ADD_CI: [ul038("ADD-plus", "CI-plus"), ul040("ADD-plus", "CI-plus", "CAN06")],
	GRO_PRO_ADD_MC: [ul038("ADD-plus", "MC"), ul040("ADD-plus", "MC", "CAN06")],
	GRO_PRO_ADD_HC: [ul038("ADD-plus", "HC"), ul040("ADD-plus", "HC", "CAN06")],
	GRO_PRO_CI_MC: [ul038("CI-plus", "MC"), ul040("CI-plus", "MC", "CAN06")],
	GRO_PRO_CI_HC: [ul038("CI-plus", "HC"), ul040("CI-plus", "HC", "CAN06")],
	GRO_PRO_MC_HC: [ul038("MC", "HC"), ul040("MC", "HC", "CAN06")],
	GRO_PRO_ADD_CI_MC: [ul038("ADD-plus", "CI-plus", "MC"), ul040("ADD-plus", "CI-plus", "MC", "CAN06")],
	GRO_PRO_ADD_CI_HC: [ul038("ADD-plus", "CI-plus", "HC"), ul040("ADD-plus", "CI-plus", "HC", "CAN06")],
	GRO_PRO_ADD_MC_HC: [ul038("ADD-plus", "MC", "HC"), ul040("ADD-plus", "MC", "HC", "CAN06")],
	GRO_PRO_CI_MC_HC: [ul038("CI-plus", "MC", "HC"), ul040("CI-plus", "MC", "HC", "CAN06")],
	GRO_PRO_ADD_CI_MC_HC: [ul038("ADD-plus", "CI-plus", "MC", "HC"), ul040("ADD-plus", "CI-plus", "MC", "HC", "CAN06")],
}
