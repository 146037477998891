<template>
	<JBox>
		<CEditableField
			v-for="(item, index) in computedList"
			:key="index"
			:label="item.label"
			:value="item.id === 'dateOfBirth' ? formatData(item.value, 'DD/MM/YYYY') : item.value"
			:canEdit="isEditable && item.id !== 'name'"
			:canDelete="false"
			@edit="decideUpdateModal(item)"
		/>
		<!-- Update Modal  -->
		<teleport to="#layer2">
			<JModalSimple zIndex="50" variant="center-3xl" :isVisible="showModal" @overlay-click="closeModalAndClearForms()">
				<CFormInput
					v-model="updateObj"
					:componentName="componentToDisplay"
					:options="componentOptions"
					:label="updateFieldLabel"
					class="mb-6"
				/>
				<JFlex>
					<JButton @click="handleUpdate" class="mr-2">
						{{ $t("core.update") }}
					</JButton>
					<JButton variant="tertiary-outline" @click="closeModalAndClearForms()">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import { formatData } from "@/modules/core/composables"
import {
	apiUpdateIndividual,
	apiUpdateCompany,
	apiUpdateOrganization,
	apiUpdateInternal,
	apiUpdateObject,
} from "@covergo/cover-composables"
import { fetcher } from "../../api/fetcher"
import { handleErrorForUser } from "../../api/handleErrorForUser"
import { ref, computed, watch } from "vue"

/**
 * @description based on id , decide what kind of component will appear when you update field
 * to see id of field , check credentialFields.js
 * @exampple When updating dateOfBirth, modal with date input will be shown
 **/
const dateFields = ["dateOfBirth"]
const booleanFields = ["acceptsMarketing", "isActive"]
const selectFields = ["gender", "salutation", "maritalStatus"]

export default {
	props: {
		entityId: {
			type: String,
		},
		fields: {
			type: Array,
		},
		typename: {
			// individual, company, internal, organization, object etc
			type: String,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	setup(props, { emit }) {
		const list = ref([])
		const computedList = computed(() => list.value || [])
		const showModal = ref(false)
		const componentToDisplay = ref(null)
		const componentOptions = ref([]) // type of options boolean or select component has, defined in credentials.js
		const selectedId = ref(null)
		const updateObj = ref(null)

		// const formatData = () => formatData
		const updateFieldLabel = computed(() => list?.value?.find((item) => item?.id === selectedId.value)?.label)

		const openModal = () => {
			showModal.value = true
		}
		const closeModal = () => {
			showModal.value = false
		}

		const closeModalAndClearForms = () => {
			closeModal()
			updateObj.value = null
		}

		// shows update modal, see dateFields, selectFields, booleanFields definitions above to define what kind of component will be displayed
		const decideUpdateModal = (item) => {
			const { id } = item
			selectedId.value = id
			updateObj.value = item.value
			if (dateFields?.includes(id)) {
				componentToDisplay.value = "JDatePicker"
			} else if (selectFields?.includes(id)) {
				componentToDisplay.value = "JSelect"
				componentOptions.value = item?.options
			} else if (booleanFields?.includes(id)) {
				componentToDisplay.value = "JToggle"
				componentOptions.value = item?.options
			} else {
				componentToDisplay.value = "JInputText"
			}
			openModal()
		}

		const handleUpdate = async () => {
			const type = props?.typename
			// different composable based on entity type
			const apiCall =
				type === "individual"
					? apiUpdateIndividual
					: type === "company"
					? apiUpdateCompany
					: type === "organization"
					? apiUpdateOrganization
					: type === "internal"
					? apiUpdateInternal
					: type === "object"
					? apiUpdateObject
					: null
			const variables = {
				id: props?.entityId,
				input: {
					[selectedId.value]: updateObj.value,
				},
			}
			const { error } = await apiCall({
				variables,
				fetcher,
			})
			if (error) {
				handleErrorForUser({ error, $t: this.$t })
				return
			}
			emit("updated")
			closeModalAndClearForms()
		}

		// watcher
		watch(
			() => props.fields,
			(updatedVal) => {
				list.value = updatedVal || []
			},
			{
				deep: true,
				immediate: true,
			}
		)

		return {
			computedList,
			componentOptions,
			showModal,
			componentToDisplay,
			selectedId,
			updateObj,
			formatData,
			updateFieldLabel,
			openModal,
			closeModal,
			closeModalAndClearForms,
			decideUpdateModal,
			handleUpdate,
		}
	},
}
</script>
