export default {
	core: {
		numberGreaterThan: "Số phải lớn hơn {gt}",
		numberLessThan: "Số phải bé hơn {lt}",
		dashboard: "Báo cáo",
		policies: "Chính sách",
		clients: "Khách hàng",
		product: "Sản phẩm",
		users: "Quản lý user",
		participants: "Người tham gia",
		organizations: "Tổ chức",
		report: "Báo cáo",
		chatRooms: "Phòng trò chuyện",
		logout: "Đăng xuất",
		order: "order",
		edit: "Chỉnh sửa",
		delete: "Xoá",
		required: "Thông tin bắt buộc",
		requiredIf: "Thông tin bắt buộc",
		email: "Địa chỉ mail chưa chính xác",
		age: "您的年齡必須在18至65歲之間才能被接受",
		hkid: "請輸入有效之香港身份證號碼",
		alpha: "Trường này chỉ có thể chứa các ký tự chữ cái",
		alphaNum: "Trường này chỉ có thể chứa các ký tự chữ cái và số",
		maxLength: "Vui lòng không nhập vượt quá giới hạn ký tự",
		minLength: "Vui lòng không nhập ít hơn 8 kí tự chữ cái",
		sameAs: "Thông tin mật khẩu chưa chính xác",
		noNumber: "此欄不能包含數字。",
		phone: "Hãy nhập vào số điện thoại hợp lệ",
		validAge: "Tuổi phải từ 18 đến 99",
		validChildAge: "Tuổi phải từ 0 đến 99",
		validDependent: "Số con phải nhỏ hơn hoặc bằng 3",
		minNumber: "Số phải lớn hơn 0",
		maxNumber: "Số phải bé hơn 99",
		validPercent: "Số phải từ 0 đến 100",
		validNumber: "Số không hợp lệ",
		noDataHere: "Không có dữ liệu",
		notifications: "Thông báo",
		home: "Trang chủ",
		healthRoomBoardAmount: "Vui lòng chọn giá trị trong khoảng từ 200.000 đến 3.000.000",
		between: "Giữa",
		before: "Trước",
		after: "Sau",
		today: "Hôm nay",
		lastWeek: "Tuần trước",
		lastMonth: "Tháng trước",
		lastThreeMonths: "Ba tháng trước",
		clear: "Xoá",
		inProgress: "Đang xử lý",
		showing: "Hiển thị",
		of: "của",
		vietnamese: "TIẾNG VIỆT",
		eng: "TIẾNG ANH",
		restricLeadGCM: "Nhập sai định dạng CRM lead ID",
		restricPassword:
			"Vui lòng nhập không ít hơn 8 kí tự, mật khẩu phải bao gồm số và kí tự đặc biệt, chữ viết thường và viết hoa",
		warningMessageOfNavigation: "Vui lòng đóng hội thoại hiện tại trước khi chuyển sang trang khác!",
		selectedDate: "Ngày thực hiện",
		goToPage: "Đi đến trang",
		maxDependants: "Số con không nên quá 10 người",
		maxLengthTitle: "Vui lòng không nhập quá 100 kí tự",
		maxLengthSubTitle: "Vui lòng không nhập quá 100 kí tự",
		noCodeFound: "Không tìm thấy kết quả phù hợp.",
		errorMessageFullName: "Vui lòng nhập đầy đủ họ và tên khách hàng",
		alphaWithSpace: "Trường này chỉ chứa các ký tự chữ cái",
		errorMessagePhoneNumber: "Vui lòng nhập đúng định dạng số điện thoại, ví dụ: 0123456789",
		errorDob: "Ngày sinh không hợp lệ",
		minAge: "Tuổi phải từ 18 đến 99",
		maxAge: "Tuổi phải từ 18 đến 99",
		invalidIssuedDate: "Ngày cấp không hợp lệ",
		accidentDeathMinAmount: "Số phải lớn hơn 100.000.000 VND",
		criticalIllnessMinAmount: "Số phải lớn hơn 100.000.000 VND",
		requiredToInputAgentCode: "Vui lòng nhập thông tin tư vấn viên",
		requiredValueGreaterThan0: "Thông tin bắt buộc",
		invalidAmount: "Giá trị không hợp lệ",
		insuranceAmountIsRequired: "Vui lòng nhập Giá trị hợp đồng bảo hiểm",
		invalidDirectReferralCode: "Vui lòng nhập đúng Mã nhân viên của người refer",
		agentsSelected: "Tư vấn viên được chọn",
		inputAtLeast1ExpenseField: "Bắt buộc nhập ít nhất một thông tin",
		inputAtLeast1IncomeField: "Bắt buộc nhập ít nhất một thông tin",
		selectAtLeast1Agent: "Vui lòng chọn ít nhất một tư vấn viên!",
		yes: "Có",
		no: "Không",
		fullNameMaxLength: "Họ tên không được quá 255 ký tự",
		errorMessageUserFullName: "Vui lòng nhập đầy đủ Họ và tên",
		emailContainsMissingText: "Để đảm bảo thông tin email đầy đủ và chính xác, vui lòng kiểm tra lại và xác nhận.",
		directReferralAgentCodeMaxLength: " Vui lòng nhập không quá 20 ký tự",
		livingExpenseAmountWarningMessage: "Chi phí sinh hoạt thiết yếu phải lớn hơn hoặc bằng 3.600.000đ/tháng (~43.000.000đ/năm)",
		investmentPreferenceWarningMessage: "Vui lòng lựa chọn trước khi tư vấn sản phẩm",
		productIsNotSuitableToInvestmentPreference: "Sản phẩm chưa phù hợp",
		totalIncomeMinValue: "Tổng thu nhập phải lớn hơn hoặc bằng 8.750.000đ/tháng (~105.000.000đ/năm)",
		totalExpenseInvalidAmount: "Tổng chi phí phải lớn hơn hoặc bằng 5.000.000đ/tháng (~60.000.000đ/năm)",
		expectedPremiumPaymentWarning: "Thời gian đóng phí dự kiến từ 3-100 năm.",
		financialYearsWarning:
			"Tối thiểu bằng Phí bảo hiểm quy năm. Tối đa 30% tổng thu nhập theo năm, và nhỏ hơn hoặc bằng Thu nhập thuần theo năm",
		exceedMaxLength: " Vượt quá số ký tự cho phép!",
		duplicateStaffId: "Mã nhân viên bị trùng!",
		duplicateUsername: "Username bị trùng!",
		duplicateEmail: "Email bị trùng!",
		thisFieldIsRequired: "Trường thông tin bắt buộc!",
		incorrectFormatMail: "Email chưa đúng định dạng!",
	},
}
