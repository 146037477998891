export default {
	setInitialActiveStep(state, payload) {
		state.initialActiveStep = payload || "customerDetails"
	},
	setInitialCompletedSteps(state, payload) {
		state.initialCompletedSteps = payload || []
	},
	setGoals(state, payload) {
		state.selectedGoals = payload || []
	},
	setCustomerDetails(state, payload) {
		state.customerDetails = { ...payload }
	},
	setCustomerInfo(state, payload) {
		state.customerInfo = { ...payload }
	},
	setCaseData(state, payload) {
		state.caseData = payload
	},
	setRates(state, payload) {
		state.rates = payload
	},
	setAnswers(state, payload) {
		state.answers = payload
	},
	setAnswersFactId(state, payload) {
		state.answersFactId = payload
	},
	setGoalsFactId(state, payload) {
		state.goalsFactId = payload
	},
	stopFNA(state) {
		state.initialActiveStep = "customerDetails"
		state.initialCompletedSteps = []
		state.goalsFactId = null
		state.selectedGoals = ["childrenEducation"]
		state.caseData = null
		state.prices = {}
		state.defaultSA = null
		state.overwriteProductOptions = null
		state.selectedProduct = null
		state.customizingProduct = null
		state.customerDetails = {
			gender: null,
			age: null,
			numberOfDependents: 0,
		}
		state.customerInfo = {
			leadId: "",
			fullName: "",
			citizenIdType: null, // 1: citizen id || 4: passport
			citizenId: "",
			phoneNumber: "",
			email: "",
			gender: "",
			dateOfBirth: "",
			maritalStatus: "",
			spouseName: "",
			spouseAge: null,
			customerId: "",
			dependentList: [],
			meetingMethod: "",
			staffId: "",
			bankStaffName: "",
		}
		state.ratesFactId = null
		state.answersFactId = null
		state.dropOffReason = null
		state.otherDropOffReason = null
		state.pauseReason = null
		state.conversationResult = null
		state.tisAgentCode = null
		state.leadGCM = null
		state.planCode = null
		state.mvpVersion = null
		state.rates = {
			interest: 5,
			inflation: 5,
			educationInflation: 4,
		}
		state.answers = {}
	},
	setDropOffReason(state, payload) {
		state.dropOffReason = payload
	},
	setOtherDropOffReason(state, payload) {
		state.otherDropOffReason = payload
	},
	setInterestRate(state, payload) {
		state.rates = { ...state.rates, interest: payload }
	},
	setInflationRate(state, payload) {
		state.rates = { ...state.rates, inflation: payload }
	},
	setEducationInflationRate(state, payload) {
		state.rates = { ...state.rates, educationInflation: payload }
	},
	setSelectedProduct(state, payload) {
		state.selectedProduct = payload
	},
	setPrices(state, payload) {
		state.prices = payload
	},
	setPromotions(state, payload) {
		state.promotions = payload
	},
	setOverwriteProductOptions(state, payload) {
		state.overwriteProductOptions = payload
	},
	setCustomizingProduct(state, payload) {
		state.customizingProduct = payload
	},
	setConversationResult(state, payload) {
		state.conversationResult = payload
	},
	setTisAgentCode(state, payload) {
		state.tisAgentCode = payload
	},
	setLeadGCM(state, payload) {
		state.leadGCM = payload
	},
	setGCMLeadIdFromRoute(state, payload) {
		state.gcmLeadIdFromRoute = payload
	},
	setReferLeadFna(state, payload) {
		state.referLeadFna = payload
	},
	setRecentLeadApiStatus(state, payload) {
		state.recentLeadApiStatus = payload
	},
	setPlanCode(state, payload) {
		state.planCode = payload
	},
	setMVPVersion(state, payload) {
		state.mvpVersion = payload
	},
	setDefaultSA(state, payload) {
		state.defaultSA = payload
	},
	setIsResumeFNA(state, payload) {
		state.isResumeFNA = payload
	},
	setCompletedDate(state, payload) {
		state.completedDate = payload
	},
	setResumeFlag(state, payload) {
		state.resumeFlag = payload
	},
	setTitanPdf(state, payload) {
		state.titanPdf = payload
	},
	setFNAPdf(state, payload) {
		state.fnaPdf = payload
	},
	setIsAnswerDefault(state, payload) {
		state.isAnswerDefault = payload
	},
	setFnaBackDestination(state, payload) {
		state.fnaBackDestination = payload
	},
	setProductCode(state, payload) {
		state.productCode = payload
	},
	setEPosLinkCustomerDB(state, payload) {
		state.ePosLinkCustomerDB = payload
	},
	setBaseProductId(state, payload) {
		state.baseProductId = payload
	},
	setBaseProductList(state, payload) {
		state.baseProductList = payload
	},
	setExistenceUserInfo(state, payload) {
		state.existenceUserInfo = payload
	},
	setDirectReferralCode(state, payload) {
		state.directReferralCode = payload
	},
	setRidersValid(state, payload) {
		state.ridersValid = payload
	},
	setCustomerAccountsBySearching(state, payload) {
		state.customerAccountsBySearching = payload
	},
	setRefererBranchCode(state, payload) {
		state.refererBranchCode = payload
	},
	setAnnualPremium(state, payload) {
		state.annualPremium = payload
	},
}
