const getIndividualCredentials = (entity, $t) => {
	return [
		{ id: "name", label: $t("core.name"), value: entity?.name },
		{
			id: "gender",
			label: $t("core.gender"),
			value: entity?.gender,
			options: [
				{ name: "male", value: "male" },
				{ name: "female", value: "female" },
				{ name: "none", value: "none" },
			],
		},
		{
			id: "salutation",
			label: $t("core.salutation"),
			value: entity?.salutation,
			options: [
				{ name: "Mr", value: "mr" },
				{ name: "Mrs", value: "mrs" },
				{ name: "Ms", value: "ms" },
				{ name: "Miss", value: "miss" },
				{ name: "Dr", value: "dr" },
				{ name: "None", value: "none" },
			],
		},
		{ id: "englishFirstName", label: $t("core.englishFirstName"), value: entity?.englishFirstName },
		{ id: "englishLastName", label: $t("core.englishLastName"), value: entity?.englishLastName },
		{ id: "chineseFirstName", label: $t("core.chineseFirstName"), value: entity?.chineseFirstName },
		{ id: "chineseLastName", label: $t("core.chineseLastName"), value: entity?.chineseLastName },
		{ id: "dateOfBirth", label: $t("core.dateOfBirth"), value: entity?.dateOfBirth },
		{
			id: "maritalStatus",
			label: $t("core.maritalStatus"),
			value: entity?.maritalStatus,
			options: [
				{ name: "Single", value: "single" },
				{ name: "Married", value: "married" },
				{ name: "none", value: "none" },
			],
		},
		{ id: "countryOfResidency", label: $t("core.countryOfResidency"), value: entity?.countryOfResidency },
		{
			id: "acceptsMarketing",
			label: $t("core.acceptsMarketing"),
			value: entity?.acceptsMarketing,
			options: [
				{ name: "yes", value: "true" },
				{ name: "no", value: "false" },
			],
		},
		{
			id: "preferredCommunicationChannel",
			label: $t("core.preferredCommunicationChannel"),
			value: entity?.preferredCommunicationChannel,
		},
		{ id: "preferredLanguage", label: $t("core.preferredLanguage"), value: entity?.preferredLanguage },
	]
}

const getCompanyCredentials = (entity, $t) => {
	return [
		{ id: "name", label: $t("core.name"), value: entity?.name },
		{ id: "natureOfBusiness", label: $t("core.natureOfBusiness"), value: entity?.natureOfBusiness },
		{ id: "registrationNumber", label: $t("core.registrationNumber"), value: entity?.registrationNumber },
	]
}

const getOrganizationCredentials = (entity, $t) => {
	return [
		{ id: "name", label: $t("core.name"), value: entity?.name },
		{ id: "internalCode", label: $t("core.code"), value: entity?.internalCode },
		{ id: "source", label: $t("core.source"), value: entity?.source },
		{ id: "isActive", label: $t("core.activeReferral"), value: entity?.isActive },
	]
}

const getInternalCredentials = (entity, $t) => {
	return [
		{ id: "name", label: $t("core.name"), value: entity?.name },
		{ id: "englishFirstName", label: $t("core.englishFirstName"), value: entity?.englishFirstName },
		{ id: "englishLastName", label: $t("core.englishLastName"), value: entity?.englishLastName },
		{ id: "chineseFirstName", label: $t("core.chineseFirstName"), value: entity?.chineseFirstName },
		{ id: "chineseLastName", label: $t("core.chineseLastName"), value: entity?.chineseLastName },
		{ id: "internalCode", label: $t("core.code"), value: entity?.internalCode },
		{ id: "isActive", label: $t("core.activeReferral"), value: entity?.isActive },
	]
}

const getObjectCredentials = (entity, $t) => {
	return [{ label: $t("core.name"), value: entity?.name }]
}

export {
	getIndividualCredentials,
	getCompanyCredentials,
	getOrganizationCredentials,
	getInternalCredentials,
	getObjectCredentials,
}
