<template>
	<!-- Agent code -->
	<CFormInput
		componentName="CInputSearchSelect"
		v-model="agentCodeSelected"
		:hasRemoveItem="true"
		@update:modelValue="onUpdateAgentCode"
		:isDisabledSearch="isDisabledSearch"
		field="tisAgentCode"
		valueKey="value"
		nameKey="value"
		selectedNameKey="code"
		:lookupFields="['code', 'name']"
		:placeholder="$t('dashboard.searchAgent')"
		:validator="v"
		:options="agentCodeOptions"
		:searchFunction="onInputSearch"
		maxHeightDropdown="165px"
		:isSearchLoading="isSearchLoading"
		:class="disabled && 'opacity-50 pointer-events-none'"
	/>
</template>
<!-- eslint-disable no-tabs -->
<script>
import { computed, ref, watch } from "vue"
import { useStore } from "vuex"
import { parseJSON } from "@/modules/core/composables"
import { uniqBy } from "lodash"
import { useContractInfoDetail } from "../../use/useContractInfoDetail"
import useApis from "../../use/useApis"
import { useI18n } from "vue-i18n"
export default {
	name: "SearchAgentCodeOptionsOnField",
	components: {},
	emits: ["newValAgentCode", "update:agentCodeSelectedTableItem", "update:policyAgentCode"],
	props: {
		policyList: {
			type: Array,
			default: () => [],
		},
		policyInfo: {
			type: Object,
			default: () => {},
		},
		disabled: {
			type: Boolean,
		},
		v: {
			type: Object,
		},
		agentCodeOptionList: {
			type: Array,
			default: () => [],
		},
		isDisabledSearch: {
			type: Boolean,
			default: false,
		},
		newAgentCodeOnModal: {
			type: String,
			default: "",
		},
		notAllowAgentCodeOnOptions: {
			type: String,
			default: "",
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()
		const store = useStore()
		const { userLocatedByBranchOrArea, mValueUserPosition, isBranchManager } = useContractInfoDetail()
		const { readIndividualAgentCode } = useApis({ t })
		const agentCodeOptions = ref(props.agentCodeOptionList)
		const userAgentCodeOptions = ref(props.agentCodeOptionList)
		const isSearchLoading = ref(false)
		const userAgentCode = computed(() => JSON.parse(store.getters.me?.associatedUser?.fields || "{}")?.agentCode || "")
		const loadedTisAgentCode = computed(() => store.state.fna.tisAgentCode)
		// const agentCodeSelected = ref("")
		const agentCodeSelected = computed({
			get: () => props?.newAgentCodeOnModal,
			set: (val) => {
				emit("newValAgentCode", val)
			},
		})
		/**
		 * // Why watch: options are basing on the main view UpdateAgentService
		 * -> after fetching a contract list -> we should know the agentCode on the item
		 * -> the agentCodeOptions will be filtered/ remove agentCode has presented on the contract list
		 */
		watch(
			() => props?.agentCodeOptionList,
			(val) => {
				agentCodeOptions.value = val
				userAgentCodeOptions.value = val
			}
		)

		const onUpdateAgentCode = (newVal) => {
			// val default is: code -> example: B2065
			emit("update:agentCodeSelectedTableItem", newVal)
		}

		async function onSearchAgentCode({ filterValue }) {
			isSearchLoading.value = true
			try {
				const data = await readIndividualAgentCode({
					where: {
						and: [
							{ source_contains: userLocatedByBranchOrArea },
							{ or: [...mValueUserPosition.value] },
							{
								fields: {
									path: "fields.agentCodeName",
									value: { stringValue: filterValue },
									condition: "STRING_CONTAINS",
								},
							},
						],
					},
				})
				if (Array.isArray(data?.individualAgentCode?.list)) {
					let dataList = data.individualAgentCode.list?.reduce((resultList, currentItem) => {
						const fields = parseJSON(currentItem.fields, "{}")
						if (!fields?.agentCodeName) return resultList
						const transformItem = {
							...currentItem,
							value: fields?.agentCodeName,
							code: fields?.agentCodeName,
							name: fields?.agentCode,
						}
						if (transformItem?.name === userAgentCode.value) return resultList
						if (transformItem?.name === props?.notAllowAgentCodeOnOptions) return resultList
						if (isBranchManager.value) {
							const userSource = transformItem?.source?.split("-")
							const userPosition = userSource?.[userSource?.length - 1]
							if (!["RBO.SRBO", "TIS.PRM"]?.includes(userPosition)) {
								resultList.push(transformItem)
							}
						} else {
							resultList.push(transformItem)
						}
						return resultList
					}, [])

					dataList = uniqBy(dataList, "code")
					agentCodeOptions.value = dataList

					agentCodeSelected.value = agentCodeOptions.value?.find((x) => x?.code.includes(loadedTisAgentCode.value))?.value || ""
				}
			} catch (e) {
				console.error(e)
			} finally {
				isSearchLoading.value = false
			}
		}

		function onInputSearch(value) {
			if (value.length) {
				onSearchAgentCode({ filterValue: value })
			} else {
				agentCodeOptions.value = userAgentCodeOptions.value
			}
		}

		return {
			agentCodeOptions,
			isSearchLoading,
			agentCodeSelected,
			onUpdateAgentCode,
			onInputSearch,
		}
	},
}
</script>

<style scoped></style>
