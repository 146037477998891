import { computed } from "vue"
import { merge } from "lodash"

import { useVariant } from "@/composables/variant"
import useInput, { EMITS as INPUT_EMITS } from "@/composables/input.js"

export const EMITS = [...INPUT_EMITS]

export default function useFormKitInput(formKitContext, ctx, { defaultProps = {}, ...configs } = {}) {
	const variants = useVariant(configs.componentName, formKitContext.inputProps)
	const computedProps = computed(() => {
		const { id, attrs, inputProps } = formKitContext

		return merge(
			{
				classes: variants.classes.value,
				id,
			},
			attrs,
			inputProps
		)
	})
	const { eventHandlers, ...inputProps } = useInput(computedProps.value, ctx)
	const { onBlur } = eventHandlers
	const isDisabled = computed(() => !!computedProps.value.disabled)

	function getNodeValue() {
		return formKitContext._value
	}

	function setNodeValue(value) {
		formKitContext.node.input(value)
	}

	return {
		setNodeValue,
		getNodeValue,
		computedProps,
		isDisabled,
		...inputProps,
		...variants,
		eventHandlers: {
			...eventHandlers,
			onBlur(...args) {
				formKitContext.handlers.blur()

				onBlur(...args)
			},
		},
	}
}
