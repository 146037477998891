export default function customerFacts() {
	const initIndividualFacts = [
		{
			type: "uid",
			value: {
				stringValue: null,
			},
		},
		{
			type: "relatedAccountId",
			value: {
				stringValue: null,
			},
		},
		{
			type: "fullName",
			value: {
				stringValue: null,
			},
		},
		{
			type: "citizenIdType",
			value: {
				numberValue: null,
			},
		},
		{
			type: "citizenId",
			value: {
				stringValue: null,
			},
		},
		{
			type: "phoneNumber",
			value: {
				stringValue: null,
			},
		},
		{
			type: "email",
			value: {
				stringValue: null,
			},
		},
		{
			type: "gender",
			value: {
				stringValue: null,
			},
		},
		{
			type: "dateOfBirth",
			value: {
				stringValue: null,
			},
		},
		{
			type: "maritalStatus",
			value: {
				stringValue: null,
			},
		},
		{
			type: "spouseName",
			value: {
				stringValue: null,
			},
		},
		{
			type: "spouseAge",
			value: {
				numberValue: null,
			},
		},
		{
			type: "dependentList",
			value: {
				arrayValue: null,
			},
		},
		{
			type: "staffId",
			value: {
				stringValue: null,
			},
		},
		{
			type: "branchCode",
			value: {
				stringValue: null,
			},
		},
		{
			type: "indirectReferrer",
			value: {
				stringValue: null,
			},
		},
		{
			type: "confirmTimeline",
			value: {
				stringValue: null,
			},
		},
		{
			type: "bankStaffName",
			value: {
				stringValue: null,
			},
		},
		{
			type: "age",
			value: {
				numberValue: null,
			},
		},
		{
			type: "numberOfDependents",
			value: {
				numberValue: 0,
			},
		},
		{
			type: "address",
			value: {
				objectValue: null,
			},
		},
		{
			type: "idIssuedDate",
			value: {
				stringValue: null,
			},
		},
		{
			type: "idIssuedPlace",
			value: {
				stringValue: null,
			},
		},
		{
			type: "occupation",
			value: {
				stringValue: null,
			},
		},
		{
			type: "smokingHabit",
			value: {
				stringValue: null,
			},
		},
		{
			type: "leadId",
			value: {
				stringValue: null,
			},
		},
		{
			type: "dealCloser",
			value: {
				stringValue: null,
			},
		},
		{
			type: "isAgentPO",
			value: {
				booleanValue: null,
			},
		},
		{
			type: "directReferralAgentCode",
			value: {
				stringValue: null,
			},
		},
		{
			type: "customerConsentType",
			value: {
				stringValue: null,
			},
		},
	]
	function initCaseFacts({ me }) {
		const annualPremiumFactVariables = {
			type: "annualPremium",
			value: {
				numberValue: null,
			},
		}
		const dropOffReasonFactVariables = {
			type: "dropOffReason",
			value: {
				arrayValue: null,
			},
		}
		const otherDropOffReasonFactVariables = {
			type: "otherDropOffReason",
			value: {
				stringValue: null,
			},
		}
		const productFactVariables = {
			type: "selectedProduct",
			value: {
				objectValue: null,
			},
		}
		const ratesFactVariables = {
			type: "rates",
			value: {
				objectValue: null,
			},
		}
		const productCodeFactVariables = {
			type: "productCode",
			value: {
				stringValue: null,
			},
		}
		const staffLeadFactVariables = {
			type: "staffIdLead",
			value: {
				stringValue: null,
			},
		}
		const staffLeadCreatorFactVariables = {
			type: "staffIdLeadCreator",
			value: {
				stringValue: null,
			},
		}
		const createdDateLeadFactVariables = {
			type: "createdDateLead",
			value: {
				dateValue: null,
			},
		}
		const bankStaffNameFactVariables = {
			type: "bankStaffName",
			value: {
				stringValue: null,
			},
		}
		const updatedDateLeadFactVariables = {
			type: "updatedDateLead",
			value: {
				dateValue: null,
			},
		}
		const referDateLeadFactVariables = {
			type: "referDateLead",
			value: {
				dateValue: null,
			},
		}
		const staffIdCreatorFactVariables = {
			type: "staffIdCreator",
			value: {
				stringValue: me?.staffId || null,
			},
		}
		const agentCodeCreatorFactVariables = {
			type: "agentCodeCreator",
			value: {
				stringValue: me?.agentCode || null,
			},
		}
		const meetingMethodVariables = {
			type: "meetingMethod",
			value: {
				stringValue: null,
			},
		}
		const customerIdVariables = {
			type: "customerId",
			value: {
				stringValue: null,
			},
		}
		const branchCodeVariables = {
			type: "branchCode",
			value: {
				stringValue: null,
			},
		}

		const conversationResultVariables = { type: "conversationResult", value: { stringValue: null } }
		const tisAgentCodeVariables = { type: "tisAgentCode", value: { stringValue: null } }
		const leadGCMVariables = { type: "leadGCM", value: { stringValue: null } }
		const referLeadFnaVariables = { type: "referLeadFna", value: { stringValue: "" } }
		const planCodeVariables = { type: "planCode", value: { stringValue: null } }
		const mvpVersionVariables = { type: "mvpVersion", value: { stringValue: "2.0" } }
		const completedDateFactVariables = {
			type: "completedDate",
			value: {
				dateValue: null,
			},
		}
		const baseProductIdVariables = {
			type: "baseProductId",
			value: {
				numberValue: null,
			},
		}
		const ePosLinkCustomerDBVariables = {
			type: "ePosLinkCustomerDB",
			value: {
				stringValue: "",
			},
		}
		return [
			productFactVariables,
			ratesFactVariables,
			conversationResultVariables,
			tisAgentCodeVariables,
			leadGCMVariables,
			referLeadFnaVariables,
			dropOffReasonFactVariables,
			otherDropOffReasonFactVariables,
			annualPremiumFactVariables,
			productCodeFactVariables,
			completedDateFactVariables,
			planCodeVariables,
			mvpVersionVariables,
			staffLeadFactVariables,
			createdDateLeadFactVariables,
			bankStaffNameFactVariables,
			updatedDateLeadFactVariables,
			referDateLeadFactVariables,
			staffLeadCreatorFactVariables,
			staffIdCreatorFactVariables,
			agentCodeCreatorFactVariables,
			meetingMethodVariables,
			customerIdVariables,
			branchCodeVariables,
			baseProductIdVariables,
			ePosLinkCustomerDBVariables,
		]
	}
	return {
		initIndividualFacts,
		initCaseFacts,
	}
}
