export default {
	setting: {
		setting: "Settings",
		accessTokenLifetime: "Access Token Lifetime (in second)",
		slidingRefreshTokenLifetime: "Sliding Refresh Token Lifetime (in second)",
		absoluteRefreshTokenLifetime: "Absolute Refresh Token Lifetime (in second)",
		updateSettingSuccess: "App information saved successfully!",
		configApplication: "Application Configuration",
		interest: "Interest rate",
		inflation: "Inflation rate",
		confirmToUpdateInterestRate: "Are you sure you want to update Interest Rate?",
		confirmToUpdateInflationRate: "Are you sure you want to update Inflation Rate?",
	},
	button: {
		update: "Update",
		cancel: "Cancel",
	},
}
