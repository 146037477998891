<template>
	<FormKit
		wrapperClass="c-form-options-field"
		type="list"
		v-model="model"
		v-bind="{
			...computedProps,
			...attrs,
		}"
		ref="field"
	>
		<template #default="{ classes, node }">
			<div :class="classes.options" class="c-form-options-field__options">
				<FormKit
					v-for="(option, i) in options"
					:key="i"
					:outerClass="classes.option"
					:inputProps="{
						label: option.label,
						variant: 'option-box',
						checkedValue: option.value,
					}"
					v-model="model[i]"
					type="CCheckbox"
					@change="(e) => $emit('change', option, e.target.checked)"
				/>
			</div>

			<div v-for="(message, i) in node?.context.messages" :key="i" :class="classes.message">
				{{ $t(message.value) }}
			</div>
		</template>
	</FormKit>
</template>

<script setup>
import { ref, useAttrs } from "vue"

import { useProxiedModel } from "@/composables/proxiedModel.js"
import useFormKit from "@/composables/formKit.js"
import { genVariantProps } from "@/composables/variant"

const props = defineProps({
	...genVariantProps(),
	modelValue: {
		type: Array,
		default: () => [],
	},
	options: {
		type: Array,
		default: () => [],
	},
})

defineEmits(["update:modelValue", "change"])

const attrs = useAttrs()
const field = ref()
const model = useProxiedModel(props, "modelValue")
const { computedProps } = useFormKit(
	props,
	{ attrs },
	{
		componentName: "FormOptionsField",
	}
)
</script>
