import enUS from "./en-US.js"
import vn from "./vn.js"

export default {
	"en-US": {
		...enUS,
	},
	vn: {
		...vn,
	},
}
