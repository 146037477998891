import dayjs from "dayjs"

export const formatDateAndSubtractDate = (value, dateFormat, dateSubtract = 1) => {
	if (!value) return "-"
	// If dayjs || Date object
	if (dateFormat) {
		const dayObj = dayjs(value)
		if (dayObj.$d.toString() !== "Invalid Date") {
			return dayObj.subtract(dateSubtract, "day").format(dateFormat)
		}
	}
	return value
}
