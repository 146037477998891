<template>
	<td :class="headerCellClass" :style="headerCellStyle">
		<slot />
	</td>
</template>

<script>
export default {
	name: "CTableHeaderCell",
	props: {
		variant: {
			type: String,
			default: "default",
		},
		customClassHeader: {
			type: String,
			default: "",
		},
	},
	computed: {
		headerCellClass() {
			return [
				{
					"text-c1-800 h-16 px-6 py-4 text-left whitespace-no-wrap font-medium": true,
					"absolute top-0 right-0 px-8": this.variant === "gradient",
				},
				this.customClassHeader,
			]
		},
		headerCellStyle() {
			return this.variant === "gradient" ? "background: linear-gradient(270deg, #F6F7FB 85%, rgba(255, 255, 255, 0) 100%)" : ""
		},
	},
}
</script>
