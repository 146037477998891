<template>
	<JBox class="w-full" :class="$attrs.class">
		<!-- Form control inner -->
		<JBox class="rounded-lg text-left">
			<label v-if="label" :for="field" :class="labelClass" class="font-bold block pointer-events-none text-md mb-2">
				<slot name="label">
					{{ label }}
				</slot>
			</label>
			<!-- Input Wrapper -->
			<JBox class="relative">
				<!-- Icon Prefix -->
				<slot name="prefix">
					<JBox
						v-if="iconPrefix"
						:class="isFocus ? 'text-c1-500' : 'text-c0-300'"
						class="absolute flex items-center pointer-events-none py-2 px-5 left-0"
						style="top: 50%; transform: translateY(-50%)"
					>
						<JIcon width="24" height="24" :icon="iconPrefix" />
					</JBox>
				</slot>
				<!-- Component for input -->
				<component
					:is="componentName"
					:id="field"
					ref="control"
					:class="componentInputClass"
					:placeholder="placeholder"
					v-model="internalValue"
					:options="options"
					:disabled="disabled"
					v-bind="filteredAttrs"
					autocomplete="off"
					@focus="handleFocus"
					thousandSeparator="."
					@blur="handleBlur"
					@input="onInput"
				/>
				<span v-if="isShowUnit" class="absolute cursor-pointer unit">{{ customUnit || $t("fna.currency") }}</span>
				<!-- Icon Suffix -->
				<slot name="suffix">
					<JBox
						v-if="iconSuffix"
						:class="isFocus ? 'text-c0-500' : 'text-c0-300'"
						class="absolute flex items-center pointer-events-none py-2 px-3 right-0"
						style="top: 50%; transform: translateY(-50%)"
					>
						<JIcon width="24" height="24" :icon="iconSuffix" />
					</JBox>
				</slot>
			</JBox>
		</JBox>

		<!-- Error message -->
		<JBox v-if="isDirty && hasError" class="mt-2">
			<JBox>
				<!-- Goes through messages and if message exists in validation definition, it will display message based on if it's valid or not -->
				<JText
					v-for="key in Object.keys(messages)"
					:key="key"
					:class="customErrorColor || 'text-cError-500'"
					class="text-sm mt-1"
				>
					{{ getMessage(getField, key) }}
				</JText>
			</JBox>
		</JBox>

		<!-- Suggestion dropdown -->

		<JFlex class="mt-1" v-if="isShowSugestions && isFocus"
			><template v-for="suggestion in suggestionList">
				<JButton @click="handleSuggest(suggestion?.val)" variant="tertiary-xs" v-if="suggestion" :key="suggestion">
					{{ suggestion?.label }}
				</JButton>
			</template>
		</JFlex>
	</JBox>
</template>

<script>
import get from "lodash/get"
import CInputNumber from "./CInputNumber"
export default {
	inheritAttrs: false,
	name: "CAmountInput",
	components: { CInputNumber },
	props: {
		/**
		 * @description Name of the component to render
		 * @example JInputText
		 * @supportComponents
		 *     JInputText
		 *     JInputNumber
		 *     JInputLongText
		 *     JSelect
		 *     JMultiSelect
		 *     JRadios
		 *     JToggle
		 *     JDatePicker
		 */
		componentName: {
			type: String,
			required: true,
			default: "CInputNumber",
		},
		/**
		 * @description Controller disable state
		 */
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * @description Dot notation of the field we are supposed to process. This field is also used for id for label
		 * @example "clients[1].name"
		 */
		field: {
			type: null,
			default: null,
		},
		/**
		 * @description Instance of vue-lidate from parent
		 * Simply in parent, we refer to it as $v
		 */
		validator: {
			type: Object,
			default: () => {},
		},
		/**
		 * @description Controller label
		 */
		label: {
			type: String,
			default: "",
		},
		description: {
			type: String,
			default: "",
		},
		/**
		 * @description Controller options
		 */
		options: {
			type: Array,
			default: () => [],
		},
		/**
		 * @description Controller value
		 */
		modelValue: {
			required: false,
		},
		allowSelectNothing: {
			type: Boolean,
			required: false,
			default: true,
		},
		placeholder: {
			type: String,
			default: "",
		},
		iconPrefix: {
			type: String,
			default: "",
		},
		iconSuffix: {
			type: String,
			default: "",
		},
		/**
		 * @description Component theme (mostly for label color)
		 */
		dark: {
			type: Boolean,
			default: false,
		},
		customErrorColor: {
			type: String,
		},
		inputClass: {
			type: String,
		},
		isShowUnit: {
			type: Boolean,
			default: true,
		},
		customUnit: {
			type: String,
			default: "",
		},
		isShowSugestions: {
			type: Boolean,
			default: true,
		},
		preparedSuggestionList: {
			type: Array,
			default: null,
			required: false,
		},
		defaultValue: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			isFocus: false,
			messageKeys: [
				"required",
				"requiredIf",
				"email",
				"age",
				"hkid",
				"alpha",
				"alphaNum",
				"maxLength",
				"minLength",
				"validPercent",
				"sameAs",
				"noNumber",
				"phone",
				"validAge",
				"minNumber",
				"maxNumber",
				"validNumber",
				"healthRoomBoardAmount",
				"errorMessagePhoneNumber",
				"minAge",
				"maxAge",
				"accidentDeathMinAmount",
				"criticalIllnessMinAmount",
				"requiredValueGreaterThan0",
				"invalidAmount",
				"insuranceAmountIsRequired",
				"livingExpenseAmountWarningMessage",
				"totalIncomeMinValue",
				"totalExpenseInvalidAmount",
				"financialYearsWarning",
			],
			internalValue: this.modelValue,
		}
	},
	computed: {
		filteredAttrs() {
			const newAttrs = { ...this.$props, ...this.$attrs }

			delete newAttrs.class
			return newAttrs
		},
		messages() {
			const result = {}
			this.messageKeys.forEach((key) => {
				result[key] = this.$t(`core.${key}`)
			})
			return result
		},
		labelClass() {
			return [this.dark ? "text-cWhite" : "text-cBlack"]
		},
		/**
		 * Based on field address it will return field from validator object
		 */
		getField() {
			return get(this.validator, this.field)
		},
		/**
		 * Check if the field was already touched by user
		 */
		isDirty() {
			if (Array.isArray(this.field)) {
				return get(this.validator, [...this.field, "$dirty"])
			}
			return get(this.validator, `${this.field}.$dirty`)
		},
		/**
		 * Check if the field has errors
		 */
		hasError() {
			if (Array.isArray(this.field)) {
				return get(this.validator, [...this.field, "$error"])
			}
			return get(this.validator, `${this.field}.$error`)
		},
		componentInputClass() {
			const inputPadding = this.iconPrefix ? "pl-16" : this.iconSuffix ? "pr-10" : ""
			return `${inputPadding} ${this.inputClass || ""}`
		},

		suggestionList() {
			if (this.preparedSuggestionList) return this.preparedSuggestionList

			const coefficientSuggestion = [
				{ coefficient: 3, max: 6 },
				{ coefficient: 6, max: 9 },
				{ coefficient: 9, max: 11 },
			]

			if (this.internalValue >= 10000000000) {
				return [
					{ val: 1000, label: "" },
					{ val: 1000000, label: "" },
					{ val: 1000000000, label: "" },
				].map((item) => ({ ...item, label: item.val.toLocaleString() }))
			}

			return coefficientSuggestion.map((item) => this.calculateSuggestionValue(item.coefficient, item.max))
		},
	},
	watch: {
		modelValue(val) {
			this.internalValue = val
		},
	},
	methods: {
		getMessage(fieldObject = {}, key = "") {
			const found = Object.keys(fieldObject).find((item) => item === key)
			if (found) {
				return fieldObject[key].$invalid ? this.interpolate(this.messages[key], fieldObject, key) : null
			} else return ""
		},

		/**
		 * String interpolation when we replace {0} with a specific parameter
		 */
		interpolate(str, fieldObject, key) {
			// Go to $params[key] and get possible values that will be added to the str
			const values = fieldObject[key].$message

			// Some validators don't have values, in that case return str
			if (!values) return str

			// Find all {} to replace
			const reg = /({.*?})/gi
			const toReplace = str.match(reg)
			if (!toReplace) return str

			// Replace all {} with values
			let output = str

			// function to allow retrieving nested values
			function findValueFromKey(key, values) {
				return key
					.replace(/{|}/g, "")
					.split(".")
					.reduce((acc, cur) => acc[cur], values)
			}
			toReplace.forEach((item) => {
				// const valueKey = item.replace("{", "").replace("}", ""); // not needed with fn
				output = output.replace(item, findValueFromKey(item, values)) // replaces {count} with passed parameter e.g. 2
			})

			return output
		},
		handleFocus() {
			this.isFocus = true
		},
		handleBlur() {
			setTimeout(() => {
				this.isFocus = false
			}, 150)
		},
		handleSuggest(val) {
			this.$emit("update:modelValue", val)
			this.$emit("input", val)
		},
		calculateSuggestionValue(coefficient, maxValue) {
			if (coefficient < 0 || !this.internalValue) return null
			const val = this.internalValue * 10 ** coefficient
			const valToShow = val?.toLocaleString()?.split(",")?.join(".")
			if (val.toString().length <= maxValue) return { val, label: valToShow }
			return this.calculateSuggestionValue(coefficient - 1, maxValue)
		},
		onInput(e) {
			this.$emit("input", this.internalValue)
		},
	},
}
</script>

<style scoped>
.unit {
	background-color: #e9e9e9;
	color: #7b7b7b;
	padding: 7px;
	text-align: center;
	width: 40px;
	height: 38px;
	right: 1px;
	top: 1px;
	border: 0 solid transparent;
	border-radius: 0px 7px 7px 0px;
	text-decoration: underline;
}
</style>
