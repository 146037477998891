import { createI18n } from "vue-i18n"
import { messages } from "@/setups/i18n.js"

let i18nInstance = null

export function getI18nInstance() {
	return i18nInstance
}

export function pluralTranslate(key, ...args) {
	return key && i18nInstance.global.te(key) ? i18nInstance.global.tc(key, ...args) : key
}

export function translate(key, data) {
	return key && i18nInstance.global.te(key) ? i18nInstance.global.t(key, data) : key
}

export default function () {
	i18nInstance = createI18n({
		legacy: false,
		locale: "vn", // set locale
		fallbackLocale: "en-US",
		messages, // set locale messages
	})

	return i18nInstance
}
