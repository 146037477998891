import {
	get,
	byKey,
	byType,
	getHavingFact,
	getHavingFromFact,
	calculateTotalBenefitByAmountOfBaseProduct,
	mRidersOfProduct,
} from "@/modules/rbo/helpers"
import { factsStructure } from "../use/useBuildFactProduct"
import { PRODUCT_NAME } from "@/modules/customerSupport/constants"
import { PERIODIC_PAYMENT_TERM, TITAN_PAYMENT_TERM } from "../utils/constants"
import getSelectedProductFromFacts from "../use/useGetSelectedProductFromFacts"
import { mapOccupationClass } from "@/modules/customerSupport/utils"
import occupations from "@/mock/occupationsList.json"

export default {
	caseId: (state, getters) => {
		return state?.caseData?.id
	},
	caseStatus: (state) => {
		return state?.caseData?.status
	},
	caseData: (state, getters) => {
		return state?.caseData
	},
	individualId: (state, getters) => {
		return state?.caseData?.holder?.id
	},
	ratesFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("rates"))?.id
	},
	conversationResultFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("conversationResult"))?.id
	},
	conversationResultFact: (state, getters) => {
		return {
			type: "conversationResult",
			value: { stringValue: getters.conversationResult },
		}
	},
	conversationResultFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("conversationResult"))?.value?.stringValue
	},
	staffIdLeadFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("staffIdLead"))?.value?.stringValue
	},

	createdDateLeadFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("createdDateLead"))?.value?.stringValue
	},
	bankStaffNameFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("bankStaffName"))?.value?.stringValue
	},

	updatedDateLeadFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("updatedDateLead"))?.value?.stringValue
	},

	referDateLeadFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("referDateLead"))?.value?.stringValue
	},

	tisAgentCodeFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("tisAgentCode"))?.id
	},
	tisAgentCodeFact: (state, getters) => {
		return {
			type: "tisAgentCode",
			value: { stringValue: getters.tisAgentCode },
		}
	},
	tisAgentCodeFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("tisAgentCode"))?.value?.stringValue
	},
	dropOffReasonFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("dropOffReason"))?.id
	},
	dropOffReasonFact: (state, getters) => {
		return {
			type: "dropOffReason",
			value: { stringValue: getters.dropOffReason },
		}
	},
	productCodeFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("productCode"))?.id
	},
	productCodeFact: (state, getters) => {
		return {
			type: "productCode",
			value: { stringValue: getters.productCode },
		}
	},
	dropOffReasonFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("dropOffReason"))?.value?.stringValue
	},
	otherDropOffReasonFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("otherDropOffReason"))?.id
	},
	otherDropOffReasonFact: (state, getters) => {
		return {
			type: "otherDropOffReason",
			value: { stringValue: getters.otherDropOffReason },
		}
	},
	otherDropOffReasonFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("otherDropOffReason"))?.value?.stringValue
	},
	leadGCMFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("leadGCM"))?.id
	},
	leadGCMFact: (state, getters) => {
		return {
			type: "leadGCM",
			value: { stringValue: getters.leadGCM },
		}
	},
	leadGCMFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("leadGCM"))?.value?.stringValue
	},
	referLeadFnaFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("referLeadFna"))?.id
	},
	referLeadFnaFact: (state, getters) => {
		return {
			type: "referLeadFna",
			value: { stringValue: getters.referLeadFna },
		}
	},
	referLeadFnaFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("referLeadFna"))?.value?.stringValue
	},
	planCodeFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("planCode"))?.id
	},
	planCodeFact: (state, getters) => {
		return {
			type: "planCode",
			value: { stringValue: getters.planCode },
		}
	},
	planCodeFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("planCode"))?.value?.stringValue
	},

	flowIdToResumeFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("flowIdToResume"))?.id
	},
	flowIdToResumeFact: (state, getters) => {
		return {
			type: "flowIdToResume",
			value: { stringValue: getters.flowIdToResume },
		}
	},
	flowIdToResumeFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("flowIdToResume"))?.value?.stringValue
	},

	mvpVersionFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("mvpVersion"))?.id
	},
	mvpVersionFact: (state, getters) => {
		return {
			type: "mvpVersion",
			value: { stringValue: getters.mvpVersion },
		}
	},
	mvpVersionFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("mvpVersion"))?.value?.stringValue
	},
	completedDateFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("completedDate"))?.id
	},
	completedDateFact: (state, getters) => {
		return {
			type: "completedDate",
			value: { dateValue: new Date() },
		}
	},
	completedDateFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("completedDate"))?.value?.dateValue
	},
	meetingMethodFactId: (state, getters) => {
		const { facts } = state.caseData ?? {}
		const meetingMethodFact = facts?.find((fact) => fact.type === "meetingMethod")
		return meetingMethodFact?.id
	},
	meetingMethodFact: (state, getters) => {
		return {
			type: "meetingMethod",
			value: {
				stringValue: getters.customerInfo.meetingMethod,
			},
		}
	},
	ratesFromFact: (state, getters, rootState) => {
		const rates = rootState?.appConfig
		const interest = rates?.interest || 5
		const inflation = rates?.inflation || 5
		const caseRatesFact = state?.caseData?.facts?.find(byType("rates"))?.value?.objectValue
		return caseRatesFact?.reduce(
			(acc, fact) => {
				acc[fact.key] = fact.value.numberValue
				return acc
			},
			{ interest, inflation, educationInflation: 4 }
		)
	},
	ratesFact: (state, getters) => {
		const { interest, inflation, educationInflation } = state.rates
		return {
			type: "rates",
			value: {
				objectValue: [
					{
						key: "inflation",
						value: { numberValue: inflation },
					},
					{
						key: "interest",
						value: { numberValue: interest },
					},
					{
						key: "educationInflation",
						value: { numberValue: educationInflation },
					},
				],
			},
		}
	},

	allQuestionsAnswered: (state, getters) => {
		/**
		 * RBO flow: only apply for HAVING questions
		 */
		const answers = getters.answersFromFact
		const { maritalStatus } = getters?.customerInfo
		let unAnsweredQuestion = null
		const notCompletedAnswer = (obj) => !obj || Object.keys(obj).find((key) => obj[key] === null || obj[key] === undefined)
		if (answers?.having) {
			const havingAnswers =
				maritalStatus === "2" ? answers?.having?.householdIncomeExpenseDetails : answers?.having?.incomeExpenseDetails
			const { livingExpense, incomeMonth, livingExpenseRatio } = havingAnswers
			if (!livingExpense && (!incomeMonth || !livingExpenseRatio)) unAnsweredQuestion = true
			if (notCompletedAnswer(havingAnswers?.summaryDetail)) {
				unAnsweredQuestion = true
			}
		}
		return !unAnsweredQuestion
	},
	completedHavingQuestions: (state, getters) => {
		const atLeast1Answer = (obj) =>
			!obj ||
			Object.keys(obj).find((key) => obj[key] && key !== "householdIncomeExpenseDetails" && key !== "incomeExpenseDetails")
		return atLeast1Answer(getters?.answersFromFact?.having)
	},
	initialCompletedSteps: (state, getters) => {
		const completedSteps = []
		// Step 1: Customer details
		if (getters?.customerDetails?.gender) {
			completedSteps.push("customerDetails")
		}
		// Step 2: Having questions
		if (getters?.allQuestionsAnswered) {
			completedSteps.push("incomes")
		}
		// Step 3: Recommendation products
		if (getters?.allQuestionsAnswered && getters.selectedProduct) {
			completedSteps.push("selectProduct")
		}
		// Step 4: Verification user info
		if (getters?.allQuestionsAnswered && getters.selectedProduct && getters?.customerInfo?.citizenId) {
			completedSteps.push("verifyInfo")
		}
		// Step 5: FNA confirmation (PDF generation): accordingly to user information
		if (getters?.allQuestionsAnswered && getters?.fnaPdf) {
			completedSteps.push("infoConfirmation")
		}
		return completedSteps
	},
	initialStep: (state, getters) => {
		let result = "customerDetails"
		const { gender } = getters?.customerDetails
		const customerVerification = getters?.customerInfo?.citizenId
		const annualPremiumOfProductSelected = state?.caseData?.facts?.find(byType("annualPremium"))?.value?.stringValue
		if (gender) {
			result = "incomes"
		}
		if (getters.allQuestionsAnswered) {
			result = "selectProduct"
		}
		if (getters?.allQuestionsAnswered && getters.selectedProduct) {
			result = "verifyInfo"
		}
		if (getters?.allQuestionsAnswered && getters.selectedProduct && customerVerification) {
			result = "infoConfirmation"
		}
		if (getters?.fnaPdf && annualPremiumOfProductSelected && getters?.conversationResult) {
			result = "finish"
		}
		return result
	},
	customerDetails: (state, getters) => {
		const holder = state?.caseData?.holder
		return {
			gender: holder?.gender || state.customerDetails?.gender,
			age: holder?.facts?.find(byType("age"))?.value?.numberValue || state.customerDetails.age,
			numberOfDependents:
				holder?.facts?.find(byType("numberOfDependents"))?.value?.numberValue || state.customerDetails.numberOfDependents,
		}
	},
	customerInfo: (state, getters) => {
		const holder = state?.caseData?.holder
		const facts = state?.caseData?.facts
		const dependentListFromFactValue = holder?.facts?.find(byType("dependentList"))?.value?.arrayValue
		const dependentList = dependentListFromFactValue?.map((item) => {
			const value = item.objectValue
			return {
				name: get(value, "name", "stringValue"),
				age: get(value, "age", "numberValue"),
				schoolType: get(value, "schoolType", "stringValue"),
			}
		})
		const addressFromFactValue = holder?.facts?.find(byType("address"))?.value?.objectValue

		const mapAddress = {
			street: get(addressFromFactValue, "street", "stringValue"),
			city: get(addressFromFactValue, "city", "stringValue"),
			district: get(addressFromFactValue, "district", "stringValue"),
			ward: get(addressFromFactValue, "ward", "stringValue"),
			normalizedAddress: get(addressFromFactValue, "normalizedAddress", "stringValue"),
		}

		const occupationFromFact = holder?.facts?.find(byType("occupation"))?.value?.stringValue

		return {
			fullName: holder?.facts?.find(byType("fullName"))?.value?.stringValue || state.customerInfo.fullName,
			citizenIdType: holder?.facts?.find(byType("citizenIdType"))?.value?.stringValue || state.customerInfo.citizenIdType,
			citizenId: holder?.facts?.find(byType("citizenId"))?.value?.stringValue || state.customerInfo.citizenId,
			phoneNumber: holder?.facts?.find(byType("phoneNumber"))?.value?.stringValue || state.customerInfo.phoneNumber,
			email: holder?.facts?.find(byType("email"))?.value?.stringValue || state.customerInfo.email,
			gender: holder?.facts?.find(byType("gender"))?.value?.stringValue || state.customerInfo.gender,
			dateOfBirth: holder?.facts?.find(byType("dateOfBirth"))?.value?.stringValue || state.customerInfo.dateOfBirth,
			maritalStatus: holder?.facts?.find(byType("maritalStatus"))?.value?.stringValue || state.customerInfo.maritalStatus,
			spouseName: holder?.facts?.find(byType("spouseName"))?.value?.stringValue || state.customerInfo.spouseName,
			spouseAge: holder?.facts?.find(byType("spouseAge"))?.value?.numberValue || state.customerInfo.spouseAge,
			dependentList: (dependentList?.length && dependentList) || state.customerInfo.dependentList,
			staffId: holder?.facts?.find(byType("staffId"))?.value?.stringValue || state.customerInfo.staffId,
			branchCode:
				holder?.facts?.find(byType("branchCode"))?.value?.stringValue ||
				facts?.find(byType("branchCode"))?.value?.stringValue ||
				state.customerInfo.branchCode,
			indirectReferrer:
				holder?.facts?.find(byType("indirectReferrer"))?.value?.stringValue || state.customerInfo.indirectReferrer,
			confirmTimeline: holder?.facts?.find(byType("confirmTimeline"))?.value?.stringValue || state.customerInfo.confirmTimeline,
			bankStaffName: holder?.facts?.find(byType("bankStaffName"))?.value?.stringValue || state.customerInfo.bankStaffName,
			customerId: facts?.find(byType("customerId"))?.value?.stringValue || state.customerInfo.customerId,
			meetingMethod: facts?.find(byType("meetingMethod"))?.value?.stringValue || state.customerInfo.meetingMethod,
			leadId:
				holder?.facts?.find(byType("leadId"))?.value?.stringValue ||
				facts?.find(byType("leadGCM"))?.value?.stringValue ||
				state.customerInfo.leadId,
			idIssuedDate: holder?.facts?.find(byType("idIssuedDate"))?.value?.stringValue || state.customerInfo?.idIssuedDate,
			idIssuedPlace: holder?.facts?.find(byType("idIssuedPlace"))?.value?.stringValue || state.customerInfo?.idIssuedPlace,
			occupation: occupationFromFact || state.customerInfo?.occupation,
			occupationClass:
				mapOccupationClass({
					occupationSelected: occupationFromFact,
					occupationList: occupations,
				}) || state.customerInfo?.occupationClass,
			address: mapAddress.street && mapAddress.normalizedAddress ? mapAddress : state.customerInfo?.address,
			smokingHabit: holder?.facts?.find(byType("smokingHabit"))?.value?.stringValue || state.customerInfo.smokingHabit,
			uid: holder?.facts?.find(byType("uid"))?.value?.stringValue || state.customerInfo.uid,
			relatedAccountId:
				holder?.facts?.find(byType("relatedAccountId"))?.value?.stringValue || state.customerInfo.relatedAccountId,
			/**
			 * Concept of dealCloser:
			 * Scenario 1: Manual flow
			 * - dealCloser has value from CRM info: save onto customerInfo.dealCloser, dont save to state.tisAgentCode -> it will impact to the REFER_TIS default
			 * Scenario 2: Resuming case
			 * - use state.tisAgentCode from store
			 */
			dealCloser:
				holder?.facts?.find(byType("dealCloser"))?.value?.stringValue || state.tisAgentCode || state.customerInfo.dealCloser,
			directReferralAgentCode:
				holder?.facts?.find(byType("directReferralAgentCode"))?.value?.stringValue || state.customerInfo.directReferralAgentCode,
			/**
			 * isAgentPO will be a boolean if user chosen true/false option, otherwise will be null formatted , not allow to be undefined
			 */
			isAgentPO: holder?.facts?.find(byType("isAgentPO"))?.value?.booleanValue || state.customerInfo.isAgentPO,
			customerConsentType:
				holder?.facts?.find(byType("customerConsentType"))?.value?.stringValue || state.customerInfo.customerConsentType,
		}
	},
	individualFactsByType: (state, getters) => {
		const individualFactMapping = {
			age: "numberValue",
			numOfIndependents: "numberValue",
			fullName: "stringValue",
			citizenIdType: "stringValue",
			citizenId: "stringValue",
			phoneNumber: "stringValue",
			email: "stringValue",
			gender: "stringValue",
			dateOfBirth: "stringValue",
			maritalStatus: "stringValue",
			spouseName: "stringValue",
			spouseAge: "numberValue",
			customerId: "stringValue",
			dependentList: "arrayValue",
			meetingMethod: "stringValue",
			staffId: "stringValue",
			branchCode: "stringValue",
			indirectReferrer: "stringValue",
			confirmTimeline: "stringValue",
			bankStaffName: "stringValue",
			idIssuedDate: "stringValue",
			idIssuedPlace: "stringValue",
			occupation: "stringValue",
			address: "objectValue",
			smokingHabit: "stringValue",
			uid: "stringValue",
			relatedAccountId: "stringValue",
			leadId: "stringValue",
			dealCloser: "stringValue",
			directReferralAgentCode: "stringValue",
			isAgentPO: "booleanValue",
		}
		const individualFacts = state?.caseData?.holder?.facts
		return individualFacts.reduce((acc, fact) => {
			acc[fact.type] = { ...fact, val: fact.value?.[individualFactMapping[fact.type]] || null }
			return acc
		}, {})
	},
	annualPremiumFact: (state, getters) => {
		return {
			type: "annualPremium",
			value: {
				numberValue: getters.selectedProductDetail.premium || getters?.annualPremium,
			},
		}
	},
	annualPremiumFactId: (state, getters) => {
		const { facts } = state?.caseData
		const annualPremiumFact = facts.find((fact) => fact.type === "annualPremium")
		return annualPremiumFact.id
	},
	baseProductIdFact: (state, getters) => {
		const originalPrice = getters.selectedProductDetail?.originalPrice
		return {
			type: "baseProductId",
			value: {
				numberValue: originalPrice,
			},
		}
	},
	baseProductIdFactId: (state, getters) => {
		const { facts } = state?.caseData
		const baseProductId = facts.find((fact) => fact.type === "baseProductId")
		return baseProductId.id
	},
	ePosLinkCustomerDBFact: (state, getters) => {
		return {
			type: "ePosLinkCustomerDB",
			value: {
				stringValue: getters.ePosLinkCustomerDB,
			},
		}
	},
	ePosLinkCustomerDBFactId: (state, getters) => {
		const { facts } = state?.caseData
		const ePosLinkCustomerDB = facts.find((fact) => fact?.type === "ePosLinkCustomerDB")
		return ePosLinkCustomerDB.id
	},
	selectedProductFactId: (state, getters) => {
		const { facts } = state?.caseData
		const selectedProductFact = facts?.find((fact) => fact?.type === "selectedProduct")
		return selectedProductFact.id
	},
	selectedProductFact: (state, getters) => {
		const buildFactsStructure = factsStructure(getters)
		return buildFactsStructure
	},
	selectedProductFromFact: (state, getters) => {
		const { facts } = state?.caseData
		const selectedProductFact = facts?.find((fact) => fact.type === "selectedProduct")?.value?.objectValue
		const mFactsDetail = getSelectedProductFromFacts({ selectedProductFact, facts })
		return mFactsDetail
	},
	branchCodeFactId: (state, getters) => {
		const { facts } = state.caseData ?? {}
		const branchCodeFact = facts?.find((fact) => fact.type === "branchCode")
		return branchCodeFact?.id
	},
	branchCodeFact: (state, getters) => {
		return {
			type: "branchCode",
			value: {
				stringValue: getters.customerInfo.branchCode,
			},
		}
	},
	answersFactId: (state, getters) => {
		const { facts } = state?.caseData
		const answersFact = facts?.find((fact) => fact.type === "rboAnswers")
		return answersFact?.id
	},
	answersFromFact: (state, getters) => {
		const answersFact = state?.caseData?.facts?.find((fact) => fact?.type === "rboAnswers")
		const answersFactValue = answersFact?.value?.objectValue
		return {
			having: getHavingFromFact(answersFactValue?.find(byKey("having"))),
		}
	},
	answersFact: (state, getters) => {
		const { having } = state?.answers
		const havingFact = having ? getHavingFact(having) : null
		return {
			type: "rboAnswers",
			value: {
				objectValue: [
					{
						key: "having",
						value: havingFact,
					},
				],
			},
		}
	},
	answers: (state, getters) => {
		return { ...state.answers }
	},

	selectedProductDetail: (state, getters) => {
		if (!getters.selectedProduct) return null
		const productName = getters?.selectedProduct
		const productSelectedDetail = getters.overwriteProductOptions?.[productName] // this returned a product code
		/** Base on step 3
		 * 1/ Total premium = total premium (APE) of all items that be selected

		2/ Total benefit of products: = total protection amount of all items that be selected

		3/ Sum assured = Total selected items of BASE PROTECTION (life protection)
		 */
		const { amountProtection: totalBenefit, APEYear: totalPremium, financialAffordability } =
			productSelectedDetail?.totalBenefit || {}
		const baseProtection = productSelectedDetail?.benefitsData?.find((b) => b?.name === "lifeProtection")
		const originalPrice = baseProtection?.dataOptions?.find(({ isCheckedMainBenefit }) => isCheckedMainBenefit)?.APEYear
		const totalSumAssured = calculateTotalBenefitByAmountOfBaseProduct(baseProtection?.dataOptions)
		const isTitanProduct = productName === PRODUCT_NAME.titan_6years || productName === PRODUCT_NAME.titan_10years
		let paymentProduct = PERIODIC_PAYMENT_TERM
		if (isTitanProduct) paymentProduct = TITAN_PAYMENT_TERM[productName]
		const riders = mRidersOfProduct({ mainBenefitList: productSelectedDetail?.benefitsData })

		const investmentPreference = productSelectedDetail?.investmentPreference
		const estimatedPremiumYear = productSelectedDetail?.estimatedPremiumYear

		return {
			...productSelectedDetail,
			// Exceptional fields
			sa: totalPremium,
			premium: totalPremium || 0,
			originalPrice: originalPrice || 0,
			totalBenefit: totalBenefit || 0,
			totalSumAssured,
			riders,
			paymentTerm: paymentProduct,
			investmentPreference,
			financialAffordability,
			financialAffordabilityAmount: productSelectedDetail?.financialAffordabilityAmount,
			estimatedPremiumYear,
		}
	},
	prices: (state, getters) => state.prices,

	defaultSA: (state, getters) => state.defaultSA,
	selectedProduct: (state, getters) => state.selectedProduct,
	customizingProduct: (state, getters) => state.customizingProduct,
	conversationResult: (state, getters) => state.conversationResult,
	tisAgentCode: (state, getters) => state.tisAgentCode,
	productCode: (state, getters) => state.productCode,
	conversationId: (state, getters) => state?.caseData?.caseNumber || state?.caseData?.id,
	dropOffReason: (state, getters) => state.dropOffReason,
	otherDropOffReason: (state, getters) => state.otherDropOffReason,
	leadGCM: (state, getters) => state.leadGCM,
	referLeadFna: (state, getters) => state.referLeadFna,
	recentLeadApiStatus: (state, getters) => state?.caseData?.fields || "{}",
	planCode: (state, getters) => state.planCode,
	mvpVersion: (state, getters) => state.mvpVersion,
	flowIdToResume: (state, getters) => state.flowIdToResume,

	overwriteProductOptions: (state, getters) => state.overwriteProductOptions,

	faceAmount: (state, getters) => {
		const { overwriteProductOptions, selectedProduct } = getters
		// return roundSA(overwriteProductOptions?.[selectedProduct]?.totalBenefit?.amountProtection)
		return overwriteProductOptions?.[selectedProduct]?.totalBenefit?.amountProtection
	},
	protectCurrentPlan: (state, getters) => getters.shortFall < 500000000 && getters.shortFall < getters.sumHaving,
	pdfTemplates: (state) => state.pdfTemplates,
	isResumeRBO: (state) => state.isResumeRBO,
	resumeFlag: (state) => state.resumeFlag,
	rboBackDestination: (state) => state.rboBackDestination,
	ePosLinkCustomerDB: (state) => state.ePosLinkCustomerDB,
	baseProductId: (state) => state.baseProductId,
	fnaPdf: (state) => state.fnaPdf,
	annualPremium: (state) => state.annualPremium,
}
