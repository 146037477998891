export const offerPremiumMachine = {
	id: "offerPremium",
	initial: "reading",
	states: {
		reading: {
			id: "read",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "readOfferPremiumService",
						src: "readOfferPremium",
						onDone: { target: "fetched", actions: "setResults" },
						onError: { target: "failure", actions: "setMessage" },
					},
				},
				fetched: {
					id: "fetched",
					on: {
						ADD_DISCOUNT: "#offerPremium.adding",
						REMOVE_DISCOUNT: "#offerPremium.removing",
						RESET: "#offerPremium.resetting",
					},
				},
				failure: {
					on: { RETRY: "fetching" },
				},
			},
		},
		adding: {
			id: "add",
			initial: "formFilling",
			states: {
				formFilling: {
					initial: "manual",
					invoke: {
						id: "openAddDiscountModalService",
						src: "openAddDiscountModal",
					},
					states: {
						manual: {
							invoke: {
								id: "setManualInputService",
								src: "setManualInput",
							},
							on: {
								SET_CODE: "code",
							},
						},
						code: {
							invoke: {
								id: "setCodeInputService",
								src: "setCodeInput",
							},
							on: {
								SET_MANUAL: "manual",
							},
						},
					},
					on: {
						SAVE: [
							{
								target: "#offerPremium.adding.addManualDiscount",
								actions: "buildInputForm",
								cond: { type: "isManualDiscount" },
							},
							{
								target: "#offerPremium.adding.addCodeDiscount",
								actions: "buildInputForm",
								cond: { type: "isCodeDiscount" },
							},
						],
						CANCEL: {
							target: "#offerPremium.reading",
							actions: "closeAddDiscountModal",
						},
					},
				},
				addManualDiscount: {
					invoke: {
						id: "addManualDiscountService",
						src: "addManualDiscount",
						onDone: { target: "#offerPremium.reading", actions: ["setAddDiscountSuccessMessage", "closeAddDiscountModal"] },
						onError: { target: "failure", actions: "setMessage" },
					},
				},
				addCodeDiscount: {
					invoke: {
						id: "addCodeDiscountService",
						src: "addCodeDiscount",
						onDone: { target: "#offerPremium.reading", actions: ["setAddDiscountSuccessMessage", "closeAddDiscountModal"] },
						onError: { target: "failure", actions: "setMessage" },
					},
				},
				failure: {
					on: { RETRY: "formFilling" },
				},
			},
		},
		removing: {
			id: "remove",
			initial: "removeDiscount",
			states: {
				removeDiscount: {
					invoke: {
						id: "removeDiscountService",
						src: "removeDiscount",
						onDone: { target: "#offerPremium.reading", actions: "setRemoveDiscountSuccessMessage" },
						onError: { target: "failure", actions: "setMessage" },
					},
				},
				failure: {
					on: { RETRY: "removeDiscount" },
				},
			},
		},
		resetting: {
			id: "reset",
			initial: "resetPremium",
			states: {
				resetPremium: {
					invoke: {
						id: "resetPremiumService",
						src: "resetPremium",
						onDone: { target: "#offerPremium.reading", actions: "setResetPremiumSuccessMessage" },
						onError: { target: "failure", actions: "setMessage" },
					},
				},
				failure: {
					on: { RETRY: "resetPremium" },
				},
			},
		},
	},
}
