// Base Components
import {
	JButton,
	JCheckbox,
	JCheckboxGroup,
	JDatePicker,
	JHtmlRenderer,
	JInputDateOfBirth,
	JInputLongText,
	JInputNumber,
	JInputNumberStepper,
	JInputText,
	JMessage,
	JRadios,
	JSelect,
	JSpinner,
	JSlider,
	JToggle,
	JModalSimple,
	JMultiSelect,
	JCalendar,
	JBox,
	JFlex,
	JText,
	JHeadline,
	JLabel,
	JIcon,
} from "@covergo/cover-components"

// Core
import CAccordion from "@/modules/core/components/CAccordion"
import CDroppableZone from "@/modules/core/components/CDroppableZone"
import CEditableField from "@/modules/core/components/CEditableField"
import CFileRow from "@/modules/core/components/CFileRow"
import CFormInput from "@/modules/core/components/CFormInput"
import CAmountInput from "@/modules/core/components/CAmountInput"
import CRadios from "@/modules/core/components/CRadios"
import CSlider from "@/modules/core/components/CSlider"
import CRangeInput from "@/modules/core/components/CRangeInput"
import CLayout from "@/modules/core/components/CLayout"
import CLayoutTwoCol from "@/modules/core/components/CLayoutTwoCol"
import CPagination from "@/modules/core/components/CPagination"
import CPaginationStatus from "@/modules/core/components/CPaginationStatus"
import CProgressBar from "@/modules/core/components/CProgressBar"
import CSearchBox from "@/modules/core/components/CSearchBox"
import CSidebar from "@/modules/core/components/CSidebar"
import CTable from "@/modules/core/components/CTable/CTable"
import CTableAction from "@/modules/core/components/CTable/CTableAction"
import CTableCell from "@/modules/core/components/CTable/CTableCell"
import CTableHeaderCell from "@/modules/core/components/CTable/CTableHeaderCell"
import CTableHeaderRow from "@/modules/core/components/CTable/CTableHeaderRow"
import CTableRow from "@/modules/core/components/CTable/CTableRow"
import CTabs from "@/modules/core/components/CTabs"
import CTreeView from "@/modules/core/components/CTreeView"
import CUploadFiles from "@/modules/core/components/CUploadFiles"
import CEntityCard from "@/modules/core/components/CEntityCard"
import CEntityPicker from "@/modules/core/components/CEntityPicker"
import CNoteCard from "@/modules/core/components/CNoteCard"
import CFileCard from "@/modules/core/components/CFileCard"
import CMessage from "@/modules/core/components/CMessage"
import CDatePickerAdvanced from "@/modules/core/components/CDatePickerAdvanced"
import CPremiumCard from "@/modules/core/components/CPremiumCard"
import CCommissionSplitRules from "@/modules/core/components/CCommissionSplitRules"
import CExclusionCard from "@/modules/core/components/CExclusionCard"
import CFactCard from "@/modules/core/components/CFactCard"
import CPaymentCard from "@/modules/core/components/CPaymentCard"
import CHolderCard from "@/modules/core/components/CHolderCard"
import CInsuredCard from "@/modules/core/components/CInsuredCard"
import CBeneficiaryCard from "@/modules/core/components/CBeneficiaryCard"
import CCheckBoxGroup from "@/modules/core/components/CCheckBoxGroup"
import CCheckbox from "@/modules/core/components/CCheckbox"
import CMultiSelectAndSearch from "@/modules/core/components/CMultiSelectAndSearch"
import CCarousel from "@/modules/core/components/CCarousel"
import CDatePicker from "@/modules/core/components/CDatePicker"
import CSingleSelect from "@/modules/core/components/CSingleSelect"
import CSelectNumberValue from "@/modules/core/components/CSelectNumberValue"
import CModalSimple from "@/modules/core/components/CModalSimple"
import CIcon from "@/modules/core/components/CIcon"
import CExpansionPanel from "@/modules/core/components/CExpansionPanel.vue"
import CDropdown from "@/modules/core/components/CDropdown.vue"
import CSAddressInput from "@/modules/customerSupport/CSAddressInput.vue"
import CSelect from "@/modules/core/components/CSelect.vue"
// Register components globally
const baseComponents = {
	CSAddressInput,
	CDropdown,
	JButton,
	JCheckbox,
	JCheckboxGroup,
	JHtmlRenderer,
	JInputDateOfBirth,
	JInputLongText,
	JInputNumber,
	JInputNumberStepper,
	JInputText,
	JMessage,
	JRadios,
	JSelect,
	JSpinner,
	JSlider,
	JToggle,
	JModalSimple,
	JCalendar,
	JDatePicker,
	JMultiSelect,
	JBox,
	JFlex,
	JText,
	JHeadline,
	JLabel,
	JIcon,
}

const coreComponents = {
	CExpansionPanel,
	CAccordion,
	CDroppableZone,
	CEditableField,
	CFileRow,
	CRadios,
	CSlider,
	CRangeInput,
	CFormInput,
	CLayout,
	CLayoutTwoCol,
	CPagination,
	CPaginationStatus,
	CProgressBar,
	CSearchBox,
	CSidebar,
	CTable,
	CTableAction,
	CTableCell,
	CTableHeaderCell,
	CTableHeaderRow,
	CTableRow,
	CTabs,
	CTreeView,
	CUploadFiles,
	CEntityCard,
	CEntityPicker,
	CNoteCard,
	CFileCard,
	CMessage,
	CDatePickerAdvanced,
	CPremiumCard,
	CCommissionSplitRules,
	CExclusionCard,
	CFactCard,
	CPaymentCard,
	CHolderCard,
	CInsuredCard,
	CBeneficiaryCard,
	CCheckBoxGroup,
	CCheckbox,
	CAmountInput,
	CMultiSelectAndSearch,
	CCarousel,
	CDatePicker,
	CSingleSelect,
	CSelectNumberValue,
	CModalSimple,
	CIcon,
	CSelect,
}

// Inject variant resolver function into base components
Object.keys(baseComponents).forEach((fileName) => {
	const component = baseComponents[fileName]
	const currentInject = component?.inject ?? []
	component.inject = [...currentInject, "getComponentVariants"] // this is provided on app level
})

// Register
const components = { ...baseComponents, ...coreComponents }
export default (app) => {
	Object.keys(components).forEach((fileName) => {
		const componentConfig = components[fileName]
		app.component(fileName, componentConfig.default || componentConfig)
	})
}
