import ViewDashboard from "../views/ViewDashboard.vue"
import ViewContracts from "../views/contractsManagement/ViewContracts.vue"
import ViewManagementPolicyDetail from "../views/contractsManagement/ViewManagementPolicyDetail.vue"
import ViewManageAgentCode from "../views/contractsManagement/ViewManageAgentCode.vue"
import { POLICY_INQUIRY_ROLES_PERMISSION, DASH_BOARD_ROLES_PERMISSION } from "@/tenants/use/pagesAccessingByRolesAndPermissions"
export default [
	{
		path: "/dashboard",
		component: ViewDashboard,
		name: "ViewDashboard",
		props: false,
		meta: { module: "dashboard", component: "ViewDashboard", roles: DASH_BOARD_ROLES_PERMISSION },
	},
	{
		path: "/contracts",
		component: ViewContracts,
		name: "ViewContracts",
		props: true,
		meta: { module: "dashboard", component: "ViewContracts", roles: POLICY_INQUIRY_ROLES_PERMISSION },
	},
	{
		path: "/policy-management",
		component: ViewManagementPolicyDetail,
		name: "ViewManagementPolicyDetail",
		props: true,
		meta: { module: "dashboard", component: "ViewManagementPolicyDetail" },
		children: [
			{
				path: "contractor-detail",
				name: "ContractorDetail",
				component: "ContractorDetail",
			},
			{
				path: "contract-info",
				name: "ContractDetail",
				component: "ContractDetail",
			},
			{
				path: "contractor-claim",
				name: "ContractClaim",
				component: "ContractClaim",
			},
		],
	},
	{
		path: "/management-agent-services",
		component: ViewManageAgentCode,
		name: "ViewManageAgentCode",
		props: true,
		meta: { module: "dashboard", component: "ViewManageAgentCode" },
	},
]
