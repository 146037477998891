import { assign } from "xstate"
import { uniq } from "lodash"

export const stepMachine = {
	id: "step",
	initial: "customerDetails",
	context: {
		completedSteps: [],
	},
	on: {
		goTo_customerDetails: {
			target: "customerDetails",
		},
		goTo_goals: {
			target: "goals",
			cond: (context, event) => context.completedSteps.includes("customerDetails"),
		},
		goTo_questions: {
			target: "questions",
			cond: (context, event) => context.completedSteps.includes("goals"),
		},
		goTo_having: {
			target: "having",
			cond: (context, event) => context.completedSteps.includes("questions"),
		},
		goTo_summary: {
			target: "summary",
			cond: (context, event) => context.completedSteps.includes("having"),
		},
		goTo_selectProduct: {
			target: "selectProduct",
			cond: (context, event) => context.completedSteps.includes("summary"),
		},
		goTo_finish: {
			target: "finish",
			cond: (context, event) => context.completedSteps.includes("selectProduct"),
		},
	},
	states: {
		customerDetails: {
			id: "customerDetails",
			initial: "fillingForm",
			entry: ["updateRoute"],
			meta: {
				query: {
					stage: "customerDetails",
				},
			},
			states: {
				fillingForm: {
					on: {
						NEXT: "savingData",
					},
				},
				savingData: {
					invoke: {
						id: "saveCustomerDetailsService",
						src: "saveCustomerDetails",
						onDone: { target: "#step.goals", actions: "onSaveCustomerDetailsCompleted" },
						onError: { target: "fillingForm", actions: "onSaveStepDataFailed" },
					},
				},
			},
		},
		goals: {
			id: "goals",
			initial: "selectingGoals",
			entry: ["updateRoute"],
			meta: {
				query: {
					stage: "goals",
				},
			},
			states: {
				selectingGoals: {
					on: {
						BACK: "#step.customerDetails",
						NEXT: "savingData",
						VIEW_PRODUCT: { target: "#step.selectProduct", actions: "setDefaultAnswers" },
					},
				},
				savingData: {
					invoke: {
						id: "saveGoalsService",
						src: "saveGoals",
						onDone: {
							target: "#step.questions",
							actions: assign({
								completedSteps: (context) => uniq([...context.completedSteps, "goals"]),
							}),
						},
						onError: { target: "selectingGoals", actions: "onSaveStepDataFailed" },
					},
				},
			},
		},
		questions: {
			id: "questions",
			entry: ["updateRoute"],
			meta: {
				query: {
					stage: "questions",
				},
			},
			on: {
				BACK: "goals",
				NEXT: {
					target: "having",
					actions: assign({
						completedSteps: (context) => uniq([...context.completedSteps, "questions"]),
					}),
				},
			},
		},
		having: {
			id: "having",
			entry: ["updateRoute"],
			meta: {
				query: {
					stage: "having",
				},
			},
			on: {
				BACK: "questions",
				NEXT: {
					target: "summary",
					actions: assign({
						completedSteps: (context) => uniq([...context.completedSteps, "having"]),
					}),
				},
			},
		},
		summary: {
			id: "summary",
			entry: ["updateRoute"],
			meta: {
				query: {
					stage: "summary",
				},
			},
			initial: "finalizingOptions",
			states: {
				finalizingOptions: {
					on: {
						BACK: "#step.having",
						NEXT: "savingSummarizedOptions",
					},
				},
				savingSummarizedOptions: {
					invoke: {
						id: "saveSummarizedOptionsService",
						src: "saveSummarizedOptions",
						onDone: { target: "#step.selectProduct", actions: "onSaveSummarizedOptionsCompleted" },
						onError: { target: "finalizingOptions", actions: "onSaveStepDataFailed" },
					},
				},
			},
		},
		selectProduct: {
			id: "selectProduct",
			entry: ["updateRoute"],
			meta: {
				query: {
					stage: "selectProduct",
				},
			},
			initial: "selectRecommendedProduct",
			states: {
				selectRecommendedProduct: {
					on: {
						BACK: "#step.summary",
						NEXT: "savingSelectedProduct",
						VIEW_DETAIL: "viewDetailProduct",
						BACK_TO_QUESTIONS: "#step.questions",
					},
				},
				viewDetailProduct: {
					on: {
						BACK: "selectRecommendedProduct",
					},
				},
				savingSelectedProduct: {
					invoke: {
						id: "saveSelectedProductService",
						src: "saveSelectedProduct",
						onDone: { target: "#step.finish", actions: "onSaveSelectedProduct" },
						onError: { target: "selectRecommendedProduct", actions: "onSaveStepDataFailed" },
					},
				},
			},
		},
		finish: {
			id: "finish",
			entry: ["updateRoute"],
			meta: {
				query: {
					stage: "finish",
				},
			},
			initial: "finalizing",
			states: {
				finalizing: {
					on: {
						BACK: "#step.selectProduct",
						FINISH: "finishing",
						FINISH_WITH_REFER: "finishingWithRefer",
					},
				},

				finishing: {
					invoke: {
						id: "finishFNAService",
						src: "finishFNA",
						onDone: { target: "done", actions: "onFinishFNA" },
						onError: { target: "finalizing", actions: "onSaveStepDataFailed" },
					},
				},
				finishingWithRefer: {
					invoke: {
						id: "finishFNAWithReferService",
						src: "finishFNAWithRefer",
						onDone: { target: "done", actions: "onFinishFNA" },
						onError: { target: "finalizing", actions: "onSaveStepDataFailed" },
					},
				},

				done: {
					on: {
						CONFIRM: "finalizing",
					},
				},
			},
		},
	},
}
