<template>
	<JBox variant="card-5">
		<JFlex class="flex-col space-y-6">
			<!-- Applied only for Manage role -->
			<JFlex v-if="isLocationManagers" class="flex-col space-y-10">
				<JBox class="w-full">
					<SearchPolicyOptions
						:searchBy="searchBy"
						:options="searchTypeOptions"
						:notAllowToggleOptions="isLeadDepartment || clearFilterLoader.loading || loadingStates.fetchPolicies"
						@update:searchBy="$emit('searchPolicyBy', $event)"
					/>
				</JBox>
				<JBox class="w-full grid grid-cols-3 xl:grid-cols-5 gap-10" style="min-height: 120px">
					<!-- Role -->
					<JBox v-show="disabledFeaturesFields.show.staffRole" class="common-fields">
						<JText class="font-semibold block pointer-events-none text-md mb-2">
							{{ $t("dashboard.staffRole") }}<span class="text-c1-600 ml-1">*</span></JText
						>
						<CFormInput
							componentName="CDropdown"
							:modelValue="staffTypes"
							:options="filterOptions.staffRoles"
							@update:modelValue="$emit('update:staffTypes', $event)"
							@show="$emit('show:roleOptions', staffTypes)"
							@hide="$emit('hide:roleOptions', staffTypes)"
							:validator="validator"
							field="staffTypes"
							:placeholder="$t('crm.selectType')"
							maxHeightDropdown="165px"
						/>
					</JBox>
					<!-- Area -->
					<JBox v-show="disabledFeaturesFields.show.area" class="common-fields">
						<CFormInput
							componentName="CMultiSelectAndSearch"
							:modelValue="areas"
							:optionAllIsVisible="!disabledFeaturesFields.optionAll.areas"
							:isSingleSelect="disabledFeaturesFields.optionAll.areas"
							:labelIsRequired="areasIsRequiredByRole"
							:disabledAllOption="disabledFeaturesFields.toggleOption.area"
							:disableToggleOption="disabledFeaturesFields.toggleOption.area"
							:disabledSearch="disabledFeaturesFields.search.area"
							:titleBox="$t('dashboard.area')"
							selectedItemsText="dashboard.areasSelected"
							field="areas"
							:validator="validator"
							valueKey="value"
							nameKey="name"
							:placeholder="$t('dashboard.typeToSearch')"
							:options="filterOptions.areas"
							@update:modelValue="$emit('update:areas', $event)"
							@toggle:all="$emit('toggle:allArea', $event)"
							@show="$emit('show:areaList', areas)"
							@hide="$emit('hide:areaList', areas)"
						/>
					</JBox>
					<!-- Region -->
					<JBox v-show="disabledFeaturesFields.show.region" class="common-fields">
						<CMultiSelectAndSearch
							:modelValue="regions"
							:disabledOptions="!areas?.length"
							:disabledAllOption="disabledFeaturesFields.toggleOption.region"
							:disableToggleOption="disabledFeaturesFields.toggleOption.region"
							:disabledSearch="disabledFeaturesFields.search.region"
							:titleBox="$t('dashboard.region')"
							selectedItemsText="dashboard.regionsSelected"
							field="region"
							:validator="validator"
							valueKey="value"
							nameKey="name"
							:placeholder="$t('dashboard.typeToSearch')"
							:options="filterOptions.regions"
							@update:modelValue="$emit('update:regions', $event)"
							@toggle:all="$emit('toggle:allRegion', $event)"
						/>
					</JBox>
					<!-- Branch -->
					<JBox v-show="disabledFeaturesFields.show.branch" class="common-fields">
						<CFormInput
							componentName="CMultiSelectAndSearch"
							:modelValue="branches"
							labelIsRequired
							:optionAllIsVisible="!disabledFeaturesFields.optionAll.branches"
							:isSingleSelect="disabledFeaturesFields.optionAll.branches"
							:disabledOptions="!regions?.length"
							:disabledAllOption="disabledFeaturesFields.toggleOption.branch"
							:disableToggleOption="disabledFeaturesFields.toggleOption.branch"
							:options="filterOptions.branches"
							:disabledSearch="disabledFeaturesFields.search.branch"
							:titleBox="$t('dashboard.branch')"
							selectedItemsText="dashboard.branchesSelected"
							valueKey="value"
							nameKey="name"
							field="branches"
							:validator="validator"
							:placeholder="$t('dashboard.typeToSearch')"
							@update:modelValue="$emit('update:branches', $event)"
							@toggle:all="$emit('toggle:allBranch', $event)"
							@show="$emit('show:branchList', branches)"
							@hide="$emit('hide:branchList', branches)"
						/>
					</JBox>

					<!-- Agent code -->
					<JBox v-show="disabledFeaturesFields.show.agent" class="common-fields">
						<CFormInput
							componentName="CMultiSelectAndSearch"
							labelIsRequired
							:modelValue="contractManagesByAgent"
							:options="agentCodeOptions"
							:disabledOptions="!staffTypes?.length"
							valueKey="value"
							nameKey="value"
							:disabledSearch="disabledFeaturesFields.search.agent"
							:isLoading="loadingStates && loadingStates.fetchAgents"
							@update:modelValue="$emit('update:agentCode', $event)"
							:titleBox="$t('dashboard.contractManagesByAgent')"
							:validator="validator"
							field="contractManagesByAgent"
							:placeholder="$t('user.label.pleaseSelect')"
							selectedItemsText="dashboard.agentsSelected"
						/>
					</JBox>
				</JBox>
			</JFlex>

			<JHeadline variant="h3">{{ $t("dashboard.contractSearchCriteria") }}</JHeadline>
			<!-- FRONTLINE & Manager role -->
			<JBox class="grid w-full grid-cols-3 xl:grid-cols-5 gap-10">
				<JBox class="common-fields">
					<CFormInput
						:modelValue="customerIdentify"
						:validator="validator"
						field="customerIdentify"
						componentName="JInputText"
						:label="$t('dashboard.findCustomerIdentify')"
						:placeholder="$t('dashboard.findCustomerIdentifyBy')"
						@input="$emit('update:customerIdentify', $event.target.value)"
					/>
				</JBox>
				<!-- Contract No -->
				<JFlex class="common-fields">
					<CFormInput
						:modelValue="contractNo"
						:validator="validator"
						field="contractNo"
						:label="$t('dashboard.contractNo')"
						componentName="JInputText"
						@input="$emit('update:contractNo', $event.target.value)"
					/>
				</JFlex>
				<!-- Contract Status -->
				<JBox class="common-fields">
					<CFormInput
						:modelValue="contractStatus"
						componentName="JSelect"
						:validator="validator"
						field="contractStatus"
						:options="contractStatusesOfTCB"
						:label="$t('dashboard.contractStatus')"
						:allowSelectNothing="false"
						:placeholder="defaultPlaceHolder"
						@input="$emit('update:contractStatus', $event.target.value)"
					/>
				</JBox>
				<!-- Creation date -->
				<JBox class="w-full col-span-2 relative">
					<RangeDatePicker
						:modelValue="createdAt"
						formatValueDate="YYYY-MM-DD"
						:customMaxDate="customMaxDate"
						:validator="validator?.createdAt"
						field="createdAt"
						:label="$t('dashboard.findTheDate')"
						:clearLabel="$t('dashboard.clear')"
						:dateTypeOptions="customDateTypeOptions"
						:customAllowSelectNoValue="true"
						:disabledDatePicker="!createdAt.dateType"
						customStyleDateType="width: 50%"
						customStyleDatePicker="width: 50%"
						:customPlaceholderDateType="defaultPlaceHolder"
						:quickOptions="quickOptionsDatePicker"
						@update:modelValue="$emit('update:createdAt', $event)"
					/>
				</JBox>
			</JBox>
		</JFlex>

		<!-- Filter Actions -->
		<JFlex class="justify-end items-end mt-4 space-x-3">
			<!-- ROLE: manager -> Exporting files -->
			<!-- temporarily hide this function for isLocationManagers roles -->
			<JBox v-if="false" style="width: 176px; height: 44px">
				<JButton
					variant="primary-outline"
					:class="{ 'cursor-not-allowed': notAllowToExportFile }"
					:style="{ 'border: 1px solid transparent': notAllowToExportFile }"
					style="height: 100%; width: 100%"
					@click="submitToExport($event)"
				>
					<JSpinner v-if="loadingStates.isExportingFile" type="dots"></JSpinner>
					<span v-else>{{ $t("case.exportData") }}</span></JButton
				>
			</JBox>
			<!-- Filter function -->
			<JFlex class="justify-end items-end">
				<!-- Clear -->
				<JBox>
					<JButton variant="primary-outline" @click="handleClickClearFilter" v-loading="clearFilterLoader">
						{{ $t("case.clear") }}
					</JButton>
				</JBox>
				<!-- Search -->
				<JBox>
					<JButton @click="handleClickApplyFilter" :variant="isDisabledSearchBtn ? 'disabled-primary' : 'primary'" class="ml-3">
						<JFlex class="justify-center w-full gap-2">
							<JSpinner v-if="loadingStates.fetchPolicies"></JSpinner>
							<JText>{{ $t("case.apply") }}</JText>
						</JFlex>
					</JButton>
				</JBox>
			</JFlex>
		</JFlex>

		<!-- Warning -->
		<JBox v-if="customWarningMessage">
			<JText class="text-c1-400 ml-6">{{ customWarningMessage }}</JText>
		</JBox>
	</JBox>
</template>

<script setup>
import { ref, watch, computed } from "vue"
import { useI18n } from "vue-i18n"
import { useContractInfoDetail } from "@/modules/dashboard/use/useContractInfoDetail"
import RangeDatePicker from "./RangeDatePicker"
import { checkRangeDate } from "../../helpers"
import dayjs from "dayjs"
import SearchPolicyOptions from "@/modules/dashboard/components/contracts/SearchPolicyOptions"

const emit = defineEmits([
	"update:agentCode",
	"update:customerIdentify",
	"update:contractNo",
	"update:contractStatus",
	"update:createdAt",
	"update:areas",
	"update:regions",
	"update:branches",
	"update:staffTypes",
	"toggle:allAgent",
	"toggle:allArea",
	"toggle:allBranch",
	"toggle:allRegion",
	"filter:apply",
	"filter:clear",
	"export:contractList",
	"show:roleOptions",
	"hide:roleOptions",
	"show:branchList",
	"hide:branchList",
	"searchPolicyBy",
	"show:areaList",
	"hide:areaList",
])
const props = defineProps({
	defaultPlaceHolder: {
		type: String,
		default: "",
	},
	roleCanExportData: {
		type: Boolean,
		default: false,
	},
	contractManagesByAgent: {
		type: Array,
		default: () => [],
	},
	customerIdentify: {
		type: String,
		default: "",
	},
	contractNo: {
		type: String,
		default: "",
	},
	contractStatus: {
		type: String,
		default: "",
	},
	createdAt: {
		type: Object,
		default: () => {},
	},
	areas: {
		type: Array,
		default: () => [],
	},
	regions: {
		type: Array,
		default: () => [],
	},
	staffTypes: {
		type: [String, Array],
	},
	branches: {
		type: Array,
		default: () => [],
	},
	validator: {
		type: Object,
		default: () => {},
	},
	agentCodeOptions: {
		type: Array,
		default: () => [],
	},
	loadingStates: {
		type: Object,
		default: () => {},
	},
	filterOptions: {
		type: Object,
		default: () => {},
	},
	disabledFeaturesFields: {
		type: Object,
		default: () => {},
	},
	pageLoader: {
		type: Boolean,
		default: false,
	},
	warningNoAgentCodeMatch: {
		type: Boolean,
		default: false,
	},
	clearFilterLoader: {
		type: Object,
		default: () => {},
	},
	searchBy: {
		type: String,
		default: "",
	},
	areasIsRequiredByRole: {
		type: Boolean,
		default: false,
	},
})
const { t } = useI18n()

const isFilter = ref(false)
const warningUserToInputSearchFields = ref(false)
const isInvalidDate = ref(false)
const {
	quickOptionsDatePicker,
	customDateTypeOptions,
	contractStatusesOfTCB,
	isLocationManagers,
	searchTypeOptions,
	isLeadDepartment,
} = useContractInfoDetail()

// validate missing fields
const isInvalidFields = computed(() => {
	/**
	 * Applying for FrontLine and Manager: 4 fields
	 * customerIdentify
	 * contractNo
	 * contractStatus
	 * Search by date
	 */
	return !props.contractStatus && !props.customerIdentify && !props.contractNo && !props.createdAt?.value.length > 0
})
const checkDate = computed(() => {
	return checkRangeDate({
		dateBefore: props?.createdAt.value?.[0],
		dateAfter: props?.createdAt.value?.[1],
	})
})

const customWarningMessage = computed(() => {
	if (warningUserToInputSearchFields.value) return t("dashboard.pleaseInputSearch")
	else if (isInvalidDate.value) return t("dashboard.invalidDateOver3Months")
	else if (!props.pageLoader && props.warningNoAgentCodeMatch && !props.loadingStates.fetchAgents) {
		return t("dashboard.noAgentsMatch")
	}
	return ""
})
const notAllowToExportFile = computed(() => {
	return props.loadingStates.fetchPolicies || !isFilter.value || isInvalidDate.value
})
const customMaxDate = dayjs().add(19, "year")

const isDisabledSearchBtn = computed(() => {
	return (
		props.loadingStates.fetchAgents ||
		props.warningNoAgentCodeMatch ||
		props.clearFilterLoader.loading ||
		props.loadingStates.fetchPolicies ||
		props.validator?.$invalid
	)
})
const handleClickApplyFilter = async () => {
	props.validator.$touch()
	if (isDisabledSearchBtn.value) return
	warningUserToInputSearchFields.value = false
	isInvalidDate.value = false

	if (isInvalidFields.value) {
		warningUserToInputSearchFields.value = true
		return
	}
	if (checkDate.value > 92) {
		isInvalidDate.value = true
		return
	}
	isFilter.value = true
	emit("filter:apply")
}

const handleClickClearFilter = () => {
	isFilter.value = false
	emit("filter:clear")
}

const submitToExport = (event) => {
	if (warningUserToInputSearchFields.value) {
		return
	}
	if (isInvalidDate.value) {
		return
	}
	if (isFilter.value && !notAllowToExportFile.value) emit("export:contractList")
}

watch(
	() => props.createdAt,
	() => {
		isFilter.value = false
	}
)
</script>
<style scoped>
.common-fields {
	min-width: 20%;
}
</style>
