<template>
	<FormKit
		outerClass="c-form-field"
		v-model="model"
		v-bind="{
			...computedProps,
		}"
		ref="fieldRef"
	>
		<template v-slot:help="{ node }">
			<div v-if="Object.keys(node.context.messages).length" class="c-form-field__messages">
				<FormKitMessages :node="node" />
			</div>
		</template>
		<template v-slot:messages="{ classes, help }">
			<div v-if="classes.help" :class="classes.help">{{ help }}</div>
		</template>
	</FormKit>
</template>

<script setup>
import { ref, useAttrs } from "vue"

import { useProxiedModel } from "@/composables/proxiedModel.js"
import useFormKit from "@/composables/formKit.js"
import { genVariantProps } from "@/composables/variant"

import { FormKitMessages } from "@formkit/vue"

const props = defineProps({
	...genVariantProps(),
	modelValue: null,
})

defineEmits(["update:modelValue"])

const fieldRef = ref()
const model = useProxiedModel(props, "modelValue")
const attrs = useAttrs()
const { computedProps } = useFormKit(
	props,
	{ attrs },
	{
		componentName: "FormField",
	}
)

defineExpose({
	fieldRef,
	model,
})
</script>
