<template>
	<JBox variant="card-5">
		<JHeadline as="h4" variant="h4" class="uppercase font-bold pb-10">{{ $t("dashboard.agentCodeHistoryUpdated") }}</JHeadline>
		<JBox class="grid w-full grid-cols-3 gap-3">
			<!-- Applied only for Manage role -->
			<JBox v-if="isManagerPosition" class="w-full mb-6">
				<CFormInput
					componentName="CInputSearchSelect"
					v-model="agentCodeSelected"
					:hasRemoveItem="true"
					field="contractManagesByAgent"
					:validator="validator"
					valueKey="value"
					nameKey="value"
					selectedNameKey="code"
					:lookupFields="['code', 'name']"
					:label="$t('dashboard.contractManagesByAgent')"
					:placeholder="$t('dashboard.searchAgent')"
					:options="agentCodeOptions"
					:searchFunction="onHandleSearchAgentCode"
					maxHeightDropdown="165px"
					:isSearchLoading="isLoadingSearchAgentCode"
				/>
			</JBox>
			<!-- Contract No -->
			<JFlex class="w-full mb-6">
				<CFormInput
					:modelValue="contractNo"
					:validator="validator"
					field="contractNo"
					:label="$t('dashboard.contractNo')"
					componentName="JInputText"
					@input="$emit('update:contractNo', $event.target.value)"
				/>
			</JFlex>
			<!-- Agent service updating Status -->
			<JBox class="mb-6 col-span-1">
				<CFormInput
					:modelValue="agentCodeUpdateStatus"
					:validator="validator"
					field="agentCodeUpdateStatus"
					componentName="JSelect"
					:options="agentCodeUpdatedStatusOptions"
					:label="$t('dashboard.status')"
					:placeholder="$t('fna.selectType')"
					@input="$emit('update:agentCodeUpdateStatus', $event.target.value)"
				/>
			</JBox>
			<!-- Updated date -->
			<JBox class="col-span-2">
				<CDatePickerAdvanced
					:modelValue="updatedDateAt"
					:label="$t('dashboard.changeDate')"
					:clearLabel="$t('dashboard.clear')"
					:validator="validator?.updatedDateAt"
					field="updatedDateAt"
					:dateTypeOptions="dateTypeOptions"
					:quickOptions="quickOptionsDatePicker"
					:customAllowSelectNoValue="false"
					customStyleDateType="width: 50%"
					customStyleDatePicker="width: 50%"
					@update:modelValue="$emit('update:updatedDateAt', $event)"
				/>
			</JBox>
		</JBox>
		<!-- Filter Actions -->
		<JFlex class="justify-end items-end mt-4 space-x-3">
			<!-- Filter function -->
			<JFlex class="justify-end items-end">
				<!-- Search -->
				<JBox>
					<JButton @click="handleClickApplyFilter" style="height: auto; border: 1px solid transparent">
						{{ $t("case.apply") }}
					</JButton>
				</JBox>
			</JFlex>
		</JFlex>
		<!-- Warning -->
		<JBox v-if="customWarningMessage">
			<JText class="text-c1-400 ml-6">{{ customWarningMessage }}</JText>
		</JBox>
	</JBox>
</template>

<!-- eslint-disable no-tabs -->
<script>
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"
import { useAgentCodeHistoryUpdated } from "@/modules/dashboard/use/useAgentCodeHistoryUpdated"
import { useContractInfoDetail } from "../../use/useContractInfoDetail"
import { useSearchAgentCode } from "@/modules/dashboard/use/useSearchAndFetchAgentCode"
import { checkRangeDate } from "../../helpers"
export default {
	name: "AgentCodeHistoryListFilter",
	emits: ["update:agentCode", "update:contractNo", "update:agentCodeUpdateStatus", "update:updatedDateAt", "filter:applyFilter"],
	components: {},
	props: {
		validator: {
			type: Object,
			default: () => {},
		},
		contractManagesByAgent: {
			type: String,
			default: "",
		},
		contractNo: {
			type: String,
			default: "",
		},
		agentCodeUpdateStatus: {
			type: String,
			default: "",
		},
		updatedDateAt: {
			type: Object,
			default: () => {},
		},
	},
	setup(props, { emit }) {
		const { t, locale } = useI18n()

		const isFilter = ref(false)
		const { v } = useAgentCodeHistoryUpdated()
		const { agentCodeOptions, isLoadingSearchAgentCode, onHandleSearchAgentCode } = useSearchAgentCode()
		const { quickOptionsDatePicker, dateTypeOptions, agentCodeUpdatedStatusOptions, isManagerPosition } = useContractInfoDetail()
		const agentCodeSelected = computed({
			get: () => props?.contractManagesByAgent,
			set: (val) => {
				emit("update:agentCode", val)
			},
		})

		// validate missing fields
		const warningUserToInputSearchFields = ref(false)
		const isInvalidDate = ref(false)

		const isInvalidFields = computed(() => {
			return (
				!props.contractManagesByAgent &&
				!props.agentCodeUpdateStatus &&
				!props.contractNo &&
				!props.updatedDateAt?.value.length > 0
			)
		})
		const checkDate = computed(() => {
			return checkRangeDate({
				dateBefore: props.updatedDateAt?.value[0],
				dateAfter: props.updatedDateAt?.value[1],
			})
		})
		const customWarningMessage = computed(() => {
			if (warningUserToInputSearchFields.value) return t("dashboard.pleaseInputSearch")
			else if (isInvalidDate.value) return t("dashboard.invalidDateOver3Months")
			return ""
		})

		// options
		const agentListOption = computed(() => {
			return []
		})

		const handleClickApplyFilter = () => {
			props.validator.$touch()
			if (props.validator?.$invalid) return
			warningUserToInputSearchFields.value = false
			isInvalidDate.value = false

			if (isInvalidFields.value) {
				warningUserToInputSearchFields.value = true
				return
			}
			if (checkDate.value > 92) {
				isInvalidDate.value = true
				return
			}
			emit("filter:applyFilter")
		}
		const handleClickClearFilter = () => {
			emit("filter:clear")
		}

		return {
			v,
			t,
			locale,
			isFilter,
			isManagerPosition,
			agentListOption,
			quickOptionsDatePicker,
			dateTypeOptions,
			agentCodeUpdatedStatusOptions,
			handleClickApplyFilter,
			handleClickClearFilter,
			agentCodeSelected,
			agentCodeOptions,
			isLoadingSearchAgentCode,
			onHandleSearchAgentCode,
			customWarningMessage,
		}
	},
}
</script>
