<template>
	<tr :class="headerCellClass">
		<slot />
	</tr>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
	customClassContainer: {
		type: String,
		default: "",
	},
})

const headerCellClass = computed(() => {
	return ["overflow-hidden text-c0-500", props.customClassContainer]
})
</script>
