import { gql } from "@covergo/cover-composables"
import { fetcher } from "../../case/api/fetcher"

const renderWkhtmltopdfTemplate = gql`
	query renderWkhtmltopdfTemplate($templateId: String!, $input: templateRenderParametersInput) {
		renderWkhtmltopdfTemplate(templateId: $templateId, input: $input) {
			value
			status
			errors
		}
	}
`

const renderPdfDrawingTemplate = gql`
	query renderPdfDrawingTemplate($templateId: String!) {
		renderPdfDrawingTemplate(templateId: $templateId) {
			value
			status
			errors
		}
	}
`

const renderHtmlTemplate = gql`
	query renderHtmlTemplate($templateId: String!, $input: templateRenderParametersInput!) {
		renderHtmlTemplate(templateId: $templateId, input: $input) {
			status
			errors
			value
		}
	}
`

export const useHTMLtoPDF = () => {
	const fetchPdfTemplate = async ({ templateId, input }) => {
		const res = await fetcher({
			query: renderWkhtmltopdfTemplate,
			variables: {
				templateId,
				input,
			},
		})

		return res.data.renderWkhtmltopdfTemplate.value
	}

	const fetchHTMLTemplate = async ({ templateId, input }) => {
		const res = await fetcher({
			query: renderHtmlTemplate,
			variables: {
				templateId,
				input,
			},
		})

		return res.data.renderHtmlTemplate.value
	}

	const fetchExcelTemplateData = async (data) => {
		const query = `
		query templates{
			templates(
			where:{
			type:"dynamic"
			name:"${data}"
			}
			)
			{
			list{
			...on dynamicTemplate {
			dynamicValues{
			valueId
			valueJsonString
			}
			}
			}
			}
			}
		`
		const { data: res } = await fetcher({
			query,
			variables: {},
		})

		return res?.templates?.list[0]?.dynamicValues
	}

	const fetchPdfDrawingTemplateData = async ({ templateId }) => {
		const res = await fetcher({
			query: renderPdfDrawingTemplate,
			variables: {
				templateId,
			},
		})
		return new Uint8Array(res?.data?.renderPdfDrawingTemplate?.value)
	}

	return {
		fetchPdfTemplate,
		fetchExcelTemplateData,
		fetchPdfDrawingTemplateData,
		fetchHTMLTemplate,
	}
}
