import { assign } from "xstate"

export const fnaMachine = {
	id: "fna",
	initial: "idle",
	context: {
		holderId: null,
		caseId: null,
		dropOffStatus: null,
	},
	states: {
		idle: {
			on: {
				START_FNA: "starting",
				RESUME_FNA: "preparing",
			},
		},
		starting: {
			initial: "createHolder",
			states: {
				createHolder: {
					invoke: {
						id: "createNewIndividualService",
						src: "createNewIndividual",
						onDone: { target: "createCase", actions: "setHolderId" },
						onError: { target: "#fna.idle", actions: "onApiFailed" },
					},
				},
				createCase: {
					invoke: {
						id: "createNewCaseService",
						src: "createNewCase",
						onDone: { target: "initAnswersFact", actions: "setCaseId" },
						onError: { target: "#fna.idle", actions: "onApiFailed" },
					},
				},
				initAnswersFact: {
					invoke: {
						id: "initCaseFactsService",
						src: "initCaseFacts",
						onDone: { target: "#fna.preparing" },
						onError: { target: "#fna.idle", actions: "onApiFailed" },
					},
				},
			},
		},
		preparing: {
			invoke: {
				id: "loadFNADataService",
				src: "loadFNAData",
				onDone: { target: "running", actions: "onLoadFNADataSuccess" },
				onError: { target: "idle", actions: "onApiFailed" },
			},
		},
		running: {
			on: {
				STOP: "confirmStop",
				FORCE_STOP: { target: "idle", actions: "onFNAStop" },
			},
		},
		confirmStop: {
			initial: "confirming",
			on: {
				CANCEL: "#fna.running",
			},
			states: {
				confirming: {
					on: {
						QUIT: "deletingConversation",
						QUIT_AND_SAVE: {
							target: "selectDropOffReason",
							actions: assign({
								dropOffStatus: () => "IN PROGRESS",
							}),
						},
					},
				},
				selectDropOffReason: {
					on: {
						SUBMIT_REASONS: "submittingDropOff",
						SUBMIT_CANCELED_REASONS: {
							target: "submittingDropOff",
							actions: assign({
								dropOffStatus: () => "CANCELED",
							}),
						},
					},
				},
				submittingDropOff: {
					invoke: {
						id: "dropOffConversationService",
						src: "dropOffConversation",
						onDone: { target: "conclusionMessage" },
						onError: { target: "selectDropOffReason", actions: "onApiFailed" },
					},
				},
				conclusionMessage: {
					on: {
						CANCEL: { target: "#fna.idle", actions: "onFNAStop" },
					},
				},
				deletingConversation: {
					invoke: {
						id: "deletingConversationService",
						src: "deletingConversation",
						onDone: { target: "#fna.idle", actions: "onFNAStop" },
						onError: { target: "confirming", actions: "onApiFailed" },
					},
				},
			},
		},
	},
}
