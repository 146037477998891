import { isString } from "lodash"

export function normalizeParams(...args) {
	return args.map((params) => {
		if (isString(params)) {
			try {
				const parsed = JSON.parse(params)

				return normalizeParams(parsed)
			} catch (err) {
				const arr = params.split(",")

				return arr.reduce((acc, item) => {
					const [key, value] = item.split(":")

					acc[key] = value

					return acc
				}, {})
			}
		} else if (Array.isArray(params)) {
			return params.reduce((acc, [key, value]) => {
				acc[key] = value

				return acc
			}, {})
		}

		return params
	})
}
