<template>
	<div>
		<!-- Overlay -->
		<transition
			:enterFromClass="variantCls.overlayEnter"
			:enterActiveClass="variantCls.overlayEnterActive"
			:leaveActiveClass="variantCls.overlayLeaveActive"
			:leaveToClass="variantCls.overlayLeaveTo"
		>
			<div v-if="isVisible" :class="variantCls.overlay" :style="modalZIndex" />
		</transition>

		<!-- Modal -->
		<transition
			:enterFromClass="variantCls.enter"
			:enterActiveClass="variantCls.enterActive"
			:leaveActiveClass="variantCls.leaveActive"
			:leaveToClass="variantCls.leaveTo"
		>
			<JFlex
				v-if="isVisible"
				:class="variantCls.modalWrapper"
				@click.self="handleClickOverlay"
				:style="modalZIndex"
				v-scroll-lock="vScrollLock"
			>
				<div :class="[isCustomClassRoot ? variantCls.customRoot : variantCls.root]">
					<slot />
				</div>
			</JFlex>
		</transition>
	</div>
</template>

<script>
export default {
	name: "CModalSimple",
	emits: ["overlay-click", "overlay:click"],
	props: {
		variant: {
			type: String,
			default: "default",
		},
		isVisible: {
			type: Boolean,
			default: false,
		},
		zIndex: {
			type: String,
			default: "",
		},
		vScrollLock: {
			type: Boolean,
			default: true,
		},
		isCustomClassRoot: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		variants() {
			return {
				"center-3xl": {
					el: {
						root:
							"bg-cWhite shadow rounded-lg border border-c0-200 m-auto h-auto w-full overflow-y-auto max-w-3xl max-h-modal pt-2 sm:px-24",
						overlayEnter: "opacity-0",
						overlayEnterActive: "transition duration-200 ease-ease-in",
						overlayLeaveActive: "transition duration-200 ease-ease-out",
						overlayLeaveTo: "opacity-0",
						overlay: "fixed top-0 left-0 w-full h-screen bg-cBlack bg-opacity-50",
						enter: "opacity-0",
						enterActive: "transition duration-200 ease-ease-in",
						leaveActive: "transition duration-200 ease-ease-out",
						leaveTo: "opacity-0",
						modalWrapper:
							"fixed top-0 left-0 w-full h-screen items-center justify-center bg-transparent overflow-y-auto p-3 sm:p-8",
					},
				},
				"center-max-w": {
					el: {
						root:
							"bg-cWhite shadow rounded-lg border border-c0-200 m-auto h-auto w-full overflow-y-auto max-h-modal pt-2 sm:px-24",
						customRoot:
							"bg-cWhite shadow rounded-lg border border-c0-200 m-auto min-h-full h-full w-full overflow-y-auto max-h-modal pt-2 sm:px-24",
						overlayEnter: "opacity-0",
						overlayEnterActive: "transition duration-200 ease-ease-in",
						overlayLeaveActive: "transition duration-200 ease-ease-out",
						overlayLeaveTo: "opacity-0",
						overlay: "fixed top-0 left-0 w-full h-screen bg-cBlack bg-opacity-50",
						enter: "opacity-0",
						enterActive: "transition duration-200 ease-ease-in",
						leaveActive: "transition duration-200 ease-ease-out",
						leaveTo: "opacity-0",
						modalWrapper:
							"fixed top-0 left-0 w-full h-screen items-center justify-center bg-transparent overflow-y-auto p-3 sm:p-8",
					},
				},
				"healthcare-options": {
					el: {
						root:
							"bg-cWhite shadow rounded-lg border border-c0-200 m-auto h-auto w-full overflow-y-auto max-w-3xl max-h-modal pt-2 sm:px-16",
						overlayEnter: "opacity-0",
						overlayEnterActive: "transition duration-200 ease-ease-in",
						overlayLeaveActive: "transition duration-200 ease-ease-out",
						overlayLeaveTo: "opacity-0",
						overlay: "fixed top-0 left-0 w-full h-screen bg-cBlack bg-opacity-50",
						enter: "opacity-0",
						enterActive: "transition duration-200 ease-ease-in",
						leaveActive: "transition duration-200 ease-ease-out",
						leaveTo: "opacity-0",
						modalWrapper:
							"fixed top-0 left-0 w-full h-screen items-center justify-center bg-transparent overflow-y-auto p-3 sm:p-8",
					},
				},
			}
		},
		variantCls() {
			return this.getComponentVariants()?.el
		},
		assets() {
			return this.getComponentVariants()?.assets || {}
		},
		modalZIndex() {
			return {
				zIndex: this.zIndex,
			}
		},
	},
	methods: {
		getComponentVariants() {
			return this.variants[this.variant]
		},
		handleClickOverlay() {
			this.$emit("overlay-click")
			this.$emit("overlay:click")
		},
	},
}
</script>
