<template>
	<JFlex class="relative flex-col justify-center">
		<CFormInput
			v-model="model.street"
			componentName="JInputText"
			:validator="validator"
			field="address.street"
			:placeholder="$t('fna.addressStreet')"
			@input="validator.address.street.$touch()"
		/>
		<JButton variant="custom-as-input-text" class="px-5 mt-4" @click="onToggleModal">
			<JText class="text-c0-500 normal-case">{{ normalizedAddress ? normalizedAddress : $t("fna.addressFields") }}</JText>
		</JButton>
	</JFlex>
</template>

<script setup>
import { ref } from "vue"
import useModal from "@/composables/modal"
import { useProxiedModel } from "@/composables/proxiedModel.js"
import useAddresses from "@/modules/customerSupport/composables/addresses.js"
import CSAddressModal from "@/modules/customerSupport/CSAddressModal.vue"

const props = defineProps({
	modelValue: {
		type: Object,
		default: () => ({}),
	},
	validator: {
		type: Object,
		default: () => ({}),
	},
})
const model = useProxiedModel(props, "modelValue")
const modal = useModal()
const normalizedAddress = ref(model.value.normalizedAddress || "")

if (!normalizedAddress.value) {
	getNormalizedAddress()
}

const csAddressModal = modal.create({
	name: "CSAddressModal",
	content: CSAddressModal,
	address: model.value,
	onCancel() {
		csAddressModal.close()
	},
	onSave({ value, toCloseModal = true }) {
		const { city, district, ward } = value

		model.value.city = city
		model.value.district = district
		model.value.ward = ward

		updateNormalizedAddress(value)

		if (toCloseModal) {
			csAddressModal.close()
		}
	},
})

function normalizeAddress({ cityName, districtName, wardName }) {
	if (wardName || districtName || cityName) return `${wardName || "--- "}, ${districtName || "--- "}, ${cityName || "---"}`
	else return null
}

function updateNormalizedAddress(value) {
	model.value.normalizedAddress = normalizeAddress(value)
	normalizedAddress.value = model.value.normalizedAddress
}

function getNormalizedAddress() {
	const { provinces, normalizeDistricts, normalizeWards, normalizeProvinces } = useAddresses()

	if (model.value?.city) {
		const normalizedProvinces = normalizeProvinces(provinces)
		const selectedProvince = normalizedProvinces.find((i) => i.value === model.value?.city)
		model.value.cityName = selectedProvince?.name

		if (model.value?.district) {
			const normalizedDistricts = normalizeDistricts(selectedProvince.item.districts)
			const selectedDistrict = normalizedDistricts.find((i) => i.value === model.value?.district)
			model.value.districtName = selectedDistrict?.name

			if (model.value?.ward) {
				const normalizedWards = normalizeWards(selectedDistrict.item.wards)
				const selectedWard = normalizedWards.find((i) => i.value === model.value?.ward)
				model.value.wardName = selectedWard?.name
			}
		}

		updateNormalizedAddress(model.value)
	}
}

function onToggleModal() {
	csAddressModal.open()
}
</script>
