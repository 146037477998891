<template>
	<JBox>
		<JFlex class="items-center">
			<JText v-if="label" class="mb-2 font-semibold pointer-events-none mr-2"> {{ label }} </JText>
			<slot></slot>
		</JFlex>
		<JFlex :class="inputStringClass">
			<JBox :class="classClickBtn" style="border-radius: 8px 0px 0px 8px" @click="updateValue('-')">
				<svg class="absolute" width="16" height="4" viewBox="0 0 16 4" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M9.03401 0.966003H14.966C15.5371 0.966003 16 1.42895 16 2.00002C16 2.57109 15.5371 3.03403 14.966 3.03403H9.03401H6.96599H1.03401C0.462944 3.03403 0 2.57109 0 2.00002C0 1.42895 0.462944 0.966003 1.03401 0.966003H6.96599H9.03401Z"
						fill="black"
					/>
				</svg>
			</JBox>
			<input
				v-model="inputValue"
				type="text"
				max="999"
				maxlength="3"
				:class="`${variants[variant].item}`"
				@input="updateValue()"
				:disabled="disabled"
				:placeholder="placeholder"
			/>
			<JBox :class="classClickBtn" style="border-radius: 0px 8px 8px 0px" @click="updateValue('+')">
				<svg class="absolute" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						fill-rule="evenodd"
						clip-rule="evenodd"
						d="M8 0C7.42893 0 6.96599 0.462943 6.96599 1.03401V6.96599H1.03401C0.462943 6.96599 0 7.42893 0 8C0 8.57107 0.462943 9.03401 1.03401 9.03401H6.96599V14.966C6.96599 15.5371 7.42893 16 8 16C8.57107 16 9.03401 15.5371 9.03401 14.966V9.03401H14.966C15.5371 9.03401 16 8.57107 16 8C16 7.42893 15.5371 6.96599 14.966 6.96599H9.03401V1.03401C9.03401 0.462943 8.57107 0 8 0Z"
						fill="black"
					/>
				</svg>
			</JBox>
		</JFlex>
		<!-- Error message -->
		<JBox v-if="isDirty && hasError" class="mt-2">
			<JBox>
				<!-- Goes through messages and if message exists in validation definition, it will display message based on if it's valid or not -->
				<JText v-for="key in Object.keys(messages)" :key="key" class="text-cError-500 text-sm mt-1">
					{{ getMessage(getField, key) }}
				</JText>
			</JBox>
		</JBox>
	</JBox>
</template>

<script>
import get from "lodash/get"

export default {
	name: "CRangeInput",
	emits: ["update:modelValue"],
	props: {
		variant: {
			type: String,
			default: "default",
		},
		label: {
			type: String,
			required: false,
			default: "",
		},
		modelValue: {
			type: Number,
			required: false,
			default: 0,
		},
		options: {
			required: false,
			default: () => [],
			type: Array,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * @description Dot notation of the field we are supposed to process. This field is also used for id for label
		 * @example "clients[1].name"
		 */
		field: {
			type: null,
			default: null,
		},
		/**
		 * @description Instance of vue-lidate from parent
		 * Simply in parent, we refer to it as $v
		 */
		validator: {
			type: Object,
			default: () => {},
		},
		placeholder: {
			type: String,
			default: "",
		},
		inputStringClass: {
			type: String,
		},
		limit: {
			type: Number,
			default: 999,
		},
	},
	data() {
		return {
			selected: "",
			inputValue: this.$props.modelValue,
			messageKeys: [
				"required",
				"requiredIf",
				"email",
				"age",
				"hkid",
				"alpha",
				"alphaNum",
				"maxLength",
				"minLength",
				"sameAs",
				"noNumber",
				"phone",
				"validAge",
				"validDependent",
				"validNumber",
				"minNumber",
				"maxNumber",
				"maxDependants",
				"errorMessagePhoneNumber",
				"minAge",
				"maxAge",
			],
		}
	},
	computed: {
		variants() {
			return {
				default: {
					item: "bg-cWhite text-c0-500 h-10 border border-c3-50 focus:outline-none w-32 text-center px-2",
				},
				primary: {
					item: "bg-cWhite text-c0-500 h-10 border border-c3-50 focus:outline-none w-16 text-center px-2",
				},
			}
		},
		messages() {
			const result = {}
			this.messageKeys.forEach((key) => {
				result[key] = this.$t(`core.${key}`)
			})
			return result
		},
		// Validator
		getField() {
			return get(this.validator, this.field)
		},
		/**
		 * Check if the field was already touched by user
		 */
		isDirty() {
			if (Array.isArray(this.field)) {
				return get(this.validator, [...this.field, "$dirty"])
			}
			return get(this.validator, `${this.field}.$dirty`)
		},
		/**
		 * Check if the field has errors
		 */
		hasError() {
			if (Array.isArray(this.field)) {
				return get(this.validator, [...this.field, "$error"])
			}
			return get(this.validator, `${this.field}.$error`)
		},
		classClickBtn() {
			return [
				"border border-c3-50 h-10 w-8 py-4 px-5 flex justify-center items-center bg-c3-50 cursor-pointer",
				this.disabled ? "pointer-events-none" : "",
			]
		},
	},
	methods: {
		updateValue(type = "") {
			if (this.disabled) return
			let newValue = +this.inputValue

			if (
				newValue.toString().includes("-") ||
				newValue.toString().includes(".") ||
				newValue.toString().includes(",") ||
				!/^[0-9]+$/.test(newValue.toString())
			) {
				newValue = 0
			} else if (newValue >= 0 && type === "+") {
				newValue++
			} else if (newValue > 0 && type === "-") newValue--

			if (this.limit && newValue > this.limit) {
				newValue = this.limit
			}

			this.inputValue = newValue
			this.$emit("update:modelValue", newValue)
		},
		getMessage(fieldObject = {}, key = "") {
			const found = Object.keys(fieldObject).find((item) => item === key)
			if (found) {
				return fieldObject[key].$invalid ? this.interpolate(this.messages[key], fieldObject, key) : null
			} else return ""
		},

		/**
		 * String interpolation when we replace {0} with a specific parameter
		 */
		interpolate(str, fieldObject, key) {
			// Go to $params[key] and get possible values that will be added to the str
			const values = fieldObject[key].$message

			// Some validators don't have values, in that case return str
			if (!values) return str

			// Find all {} to replace
			const reg = /({.*?})/gi
			const toReplace = str.match(reg)
			if (!toReplace) return str

			// Replace all {} with values
			let output = str

			// function to allow retrieving nested values
			function findValueFromKey(key, values) {
				return key
					.replace(/{|}/g, "")
					.split(".")
					.reduce((acc, cur) => acc[cur], values)
			}
			toReplace.forEach((item) => {
				// const valueKey = item.replace("{", "").replace("}", ""); // not needed with fn
				output = output.replace(item, findValueFromKey(item, values)) // replaces {count} with passed parameter e.g. 2
			})

			return output
		},
	},
}
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>
