<template>
	<JBox>
		<CAccordion :title="paymentInfo.name">
			<CEditableField
				v-for="(item, key) in computedPayment"
				:key="key"
				:label="item.label"
				:value="item.displayValue"
				:canDelete="false"
				@edit="handleClickEdit(key)"
			/>
			<JFlex class="mt-3 pt-3 border-t border-c0-200 justify-end">
				<JButton variant="warning" @click="handleClickRemove">{{ $t("core.remove") }}</JButton>
			</JFlex>
		</CAccordion>
		<!-- Modals -->
		<teleport to="#layer2">
			<!-- Modal Update -->
			<JModalSimple :isVisible="isUpdateModalOpen" variant="center-md" @overlay-click="handleCloseModalUpdate">
				<JBox class="mb-6">
					<JText class="text-c0-300 font-medium">{{ $t("core.editFor") }}</JText>
					<JHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
						{{ $t("core.paymentInfo") }}
					</JHeadline>
				</JBox>
				<JBox class="mb-10">
					<CFormInput
						v-if="selectedField.type === 'select'"
						v-model="newPaymentData"
						componentName="JSelect"
						:placeholder="$t('core.pleaseSelect')"
						:options="selectOptions[selectedKey]"
						:label="selectedField.label"
						:validator="v"
						field="newPaymentData"
						@input="v.newPaymentData.$touch()"
					/>
					<CFormInput
						v-else-if="selectedField.type === 'number'"
						v-model="newPaymentData"
						:label="selectedField.label"
						componentName="JInputNumber"
						type="float"
						:validator="v"
						field="newPaymentData"
						@input="v.newPaymentData.$touch()"
					/>
					<CFormInput
						v-else-if="selectedField.type === 'text'"
						v-model="newPaymentData"
						:label="selectedField.label"
						componentName="JInputText"
						:validator="v"
						field="newPaymentData"
						@input="v.newPaymentData.$touch()"
					/>
				</JBox>
				<JFlex v-if="!isUpdateLoading">
					<JButton class="mr-4" variant="primary" @click="handleUpdatePayment">
						{{ $t("core.update") }}
					</JButton>
					<JButton variant="tertiary-outline" @click="handleCloseModalUpdate">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
				<JFlex v-else class="justify-center items-center mt-6 h-10">
					<JSpinner variant="secondary-lg" type="dots" />
				</JFlex>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import { computed, ref } from "vue"
import { Machine } from "xstate"
import {
	apiPromise,
	apiUpdatePaymentInfo as updatePaymentOfPolicy,
	apiUpdatePaymentInfoOfCase as updatePaymentOfCase,
	apiRemovePaymentInfo as removePaymentFromPolicy,
	apiRemovePaymentInfoFromCase as removePaymentFromCase,
} from "@covergo/cover-composables"
import { useMachine } from "@/modules/core/composables/useMachine"
import { capitalize } from "@/modules/core/composables"
import { fetcher } from "./../../api/fetcher"
import { handleErrorForUser } from "./../../api/handleErrorForUser"
import { useI18n } from "vue-i18n"
import useVuelidate from "@vuelidate/core"
import { requiredIf } from "@vuelidate/validators"

const paymentInfoMachine = {
	id: "paymentInfo",
	initial: "dataListing",
	states: {
		dataListing: {
			on: {
				UPDATE: "updating",
				REMOVE: "removing",
			},
		},
		updating: {
			id: "update",
			initial: "formFilling",
			states: {
				formFilling: {
					on: {
						CONFIRM_UPDATE: { target: "fetching", cond: { type: "updateGuard" } },
						CANCEL_UPDATE: "#paymentInfo.dataListing",
					},
				},
				fetching: {
					invoke: {
						id: "updatePaymentService",
						src: "updatePayment",
						onDone: { target: "#paymentInfo.dataListing", actions: ["clearForm", "emitChanged"] },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { RETRY: "fetching" },
				},
			},
		},
		removing: {
			id: "remove",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "removePaymentService",
						src: "removePayment",
						onDone: { target: "#paymentInfo.dataListing", actions: "emitChanged" },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { RETRY: "fetching" },
				},
			},
		},
	},
}

export default {
	name: "PaymentInfo",
	emits: ["payment:changed"],
	props: {
		paymentInfo: {
			type: Object,
			required: true,
		},
		paymentFor: {
			type: String,
			required: true,
			validation(val) {
				return ["policy", "case"].includes(val)
			},
		},
		parentId: {
			type: String,
			required: true,
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()

		const selectedKey = ref("")
		const selectedField = ref({})
		const newPaymentData = ref(null)

		const fn = {
			updatePaymentOfPolicy,
			updatePaymentOfCase,
			removePaymentFromPolicy,
			removePaymentFromCase,
		}

		const selectOptions = {
			currencyCode: [
				{ name: t("core.HKD"), value: "HKD" },
				{ name: t("core.USD"), value: "USD" },
			],
			method: [
				{ name: t("core.CASH"), value: "CASH" },
				{ name: t("core.CREDIT_CARD"), value: "CREDIT_CARD" },
				{ name: t("core.GIRO"), value: "GIRO" },
				{ name: t("core.ATM"), value: "ATM" },
				{
					name: t("core.SELF_SERVICE_PAYMENT_MACHINE"),
					value: "SELF_SERVICE_PAYMENT_MACHINE",
				},
				{ name: t("core.BANK_TRANSFER"), value: "BANK_TRANSFER" },
				{ name: t("core.CHEQUE"), value: "CHEQUE" },
			],
			frequency: [
				{ name: t("core.ONE_TIME"), value: "ONE_TIME" },
				{ name: t("core.MONTHLY"), value: "MONTHLY" },
				{ name: t("core.QUARTERLY"), value: "QUARTERLY" },
				{ name: t("core.SEMI_ANNUALLY"), value: "SEMI_ANNUALLY" },
				{ name: t("core.ANNUALLY"), value: "ANNUALLY" },
			],
		}

		const rules = {
			newPaymentData: {
				requiredIf: requiredIf(
					() =>
						selectedKey.value === "name" ||
						selectedKey.value === "amount" ||
						selectedKey.value === "currencyCode" ||
						selectedKey.value === "method" ||
						selectedKey.value === "frequency"
				),
			},
		}

		const v = useVuelidate(rules, { newPaymentData })

		const updatePaymentFunction = fn[`updatePaymentOf${capitalize(props.paymentFor)}`]
		const removePaymentFunction = fn[`removePaymentFrom${capitalize(props.paymentFor)}`]

		// Computed Data
		const isUpdateModalOpen = computed(() => state.value.matches("updating"))
		const isUpdateLoading = computed(() => state.value.matches("updating.fetching"))
		const computedPayment = computed(() => ({
			name: {
				label: t("core.name"),
				value: props.paymentInfo.name,
				displayValue: props.paymentInfo.name,
				type: "text",
			},
			comment: {
				label: t("core.comment"),
				value: props.paymentInfo.comment,
				displayValue: props.paymentInfo.comment,
				type: "text",
			},
			amount: {
				label: t("core.amount"),
				value: props.paymentInfo.amount,
				displayValue: props.paymentInfo.amount,
				type: "number",
			},
			currencyCode: {
				label: t("core.currencyCode"),
				value: props.paymentInfo.currencyCode,
				displayValue: t(`core.${props.paymentInfo.currencyCode}`),
				type: "select",
			},
			method: {
				label: t("core.paymentMethod"),
				value: props.paymentInfo.method,
				displayValue: t(`core.${props.paymentInfo.method}`),
				type: "select",
			},
			frequency: {
				label: t("core.paymentFrequency"),
				value: props.paymentInfo.frequency,
				displayValue: t(`core.${props.paymentInfo.frequency}`),
				type: "select",
			},
		}))

		// Methods
		function handleClickEdit(key) {
			selectedKey.value = key
			selectedField.value = computedPayment.value[key]
			newPaymentData.value = selectedField.value.value
			send("UPDATE")
		}

		function handleClickRemove() {
			send("REMOVE")
		}

		function handleCloseModalUpdate() {
			// Reset form
			send("CANCEL_UPDATE")
		}

		function handleUpdatePayment() {
			send("CONFIRM_UPDATE")
		}

		const options = {
			services: {
				async updatePayment() {
					const variables = {
						[props.paymentFor === "case" ? "caseId" : "id"]: props.parentId,
						[props.paymentFor === "case" ? "paymentInfoId" : "paymentId"]: props.paymentInfo.id,
						[props.paymentFor === "case" ? "input" : "updateIssuedPaymentInput"]: {
							[selectedKey.value]: newPaymentData.value,
						},
					}
					return await apiPromise(updatePaymentFunction, {
						variables,
						fetcher,
					})
				},
				async removePayment() {
					const variables = {
						[props.paymentFor === "case" ? "caseId" : "id"]: props.parentId,
						[props.paymentFor === "case" ? "paymentInfoId" : "paymentId"]: props.paymentInfo.id,
					}
					return await apiPromise(removePaymentFunction, {
						variables,
						fetcher,
					})
				},
			},
			actions: {
				clearForm() {
					// Reset validation
					v.value.$reset()
				},
				emitChanged() {
					emit("payment:changed")
				},
				setErrorMessage(ctx, event) {
					handleErrorForUser({ error: event?.data, $t: t })
				},
			},
			guards: {
				updateGuard() {
					v.value.$touch()
					return !v.value.$invalid
				},
			},
		}

		const { state, send } = useMachine(Machine(paymentInfoMachine, options), { devTools: process.env.NODE_ENV === "development" })

		return {
			state,
			send,
			v,
			selectedKey,
			selectedField,
			newPaymentData,
			selectOptions,
			// Computed
			isUpdateModalOpen,
			isUpdateLoading,
			computedPayment,
			// Methods
			handleClickEdit,
			handleClickRemove,
			handleCloseModalUpdate,
			handleUpdatePayment,
		}
	},
}
</script>
