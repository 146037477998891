import {
	USER_LIST_ROLES,
	UPDATE_STAFF_PERMISSIONS,
	CREATE_STAFF_PERMISSIONS,
} from "@/tenants/use/pagesAccessingByRolesAndPermissions"
const ViewUserList = () => import(/* webpackChunkName: "group-users" */ "../views/ViewUserList.vue")
const ViewUserNew = () => import(/* webpackChunkName: "group-users" */ "../views/ViewUserNew.vue")
const ViewUserDetail = () => import(/* webpackChunkName: "group-users" */ "../views/ViewUserDetail.vue")
const ViewUpdateStaffInfo = () => import(/* webpackChunkName: "group-users" */ "../views/ViewUpdateStaffInfo.vue")
const ViewCreateNewAccount = () => import(/* webpackChunkName: "group-users" */ "../views/ViewCreateNewAccount.vue")
export default [
	{
		path: "/users",
		component: ViewUserList,
		name: "ViewUserList",
		props: true,
		meta: {
			isPublic: true,
			module: "user",
			component: "ViewUserList",
			roles: USER_LIST_ROLES,
		},
	},
	{
		path: "/users/new",
		component: ViewUserNew,
		name: "ViewUserNew",
		props: true,
		meta: {
			isPublic: true,
			module: "user",
			roles: USER_LIST_ROLES,
		},
	},
	{
		path: "/users/:userId",
		component: ViewUserDetail,
		name: "ViewUserDetail",
		props: true,
		meta: {
			isPublic: true,
			module: "user",
			roles: USER_LIST_ROLES,
		},
	},
	{
		path: "/update-staff",
		component: ViewUpdateStaffInfo,
		name: "ViewUpdateStaffInfo",
		props: true,
		meta: {
			isPublic: true,
			module: "user",
			roles: UPDATE_STAFF_PERMISSIONS,
		},
	},
	{
		path: "/create-new-staff",
		component: ViewCreateNewAccount,
		name: "ViewCreateNewAccount",
		props: true,
		meta: {
			isPublic: true,
			module: "user",
			roles: CREATE_STAFF_PERMISSIONS,
		},
	},
]
