<template>
	<JBox>
		<JText class="mb-2 font-semibold"> {{ label }} </JText>
		<div :class="inputStringCss">
			<JFlex
				class="relative"
				v-for="item in options"
				:key="item"
				@click="onSelectItem(item)"
				:class="[
					`${variants[variant].item} ${compare(item, modelValue) ? variants[variant].selected : ''}`,
					customStyleSelectionBox,
				]"
			>
				<JBox class="w-4">
					<JBox v-if="!compare(item, modelValue)" class="rounded-full px-2 py-2 w-1 h-1 border border-c0-500"></JBox>
					<JBox v-else>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M12 21.0001C14.3869 21.0001 16.6761 20.0518 18.364 18.364C20.0518 16.6762 21 14.387 21 12.0001C21 9.61311 20.0518 7.32393 18.364 5.6361C16.6761 3.94827 14.3869 3.00006 12 3.00006C9.61305 3.00006 7.32387 3.94827 5.63604 5.6361C3.94821 7.32393 3 9.61311 3 12.0001C3 14.387 3.94821 16.6762 5.63604 18.364C7.32387 20.0518 9.61305 21.0001 12 21.0001ZM16.1704 10.5454C16.3753 10.3333 16.4887 10.0491 16.4861 9.75411C16.4836 9.45914 16.3653 9.17697 16.1567 8.96839C15.9481 8.75981 15.6659 8.64149 15.371 8.63893C15.076 8.63636 14.7918 8.74976 14.5796 8.95469L10.875 12.6593L9.42037 11.2047C9.2082 10.9998 8.92402 10.8864 8.62905 10.8889C8.33408 10.8915 8.05191 11.0098 7.84333 11.2184C7.63475 11.427 7.51643 11.7091 7.51387 12.0041C7.5113 12.2991 7.6247 12.5833 7.82963 12.7954L10.0796 15.0454C10.2906 15.2563 10.5767 15.3748 10.875 15.3748C11.1733 15.3748 11.4594 15.2563 11.6704 15.0454L16.1704 10.5454Z"
								fill="#1476CC"
							/>
						</svg>
					</JBox>
				</JBox>
				<JText class="ml-4"> {{ item.name }} {{ value }} </JText>
				<slot />
			</JFlex>
		</div>
	</JBox>
</template>

<script>
import { nextTick } from "vue"

export default {
	name: "CCheckBoxGroup",
	emits: ["update:modelValue", "optionAdded", "optionRemoved"],
	props: {
		variant: {
			type: String,
			default: "default",
		},
		label: {
			type: String,
			required: false,
			default: "",
		},
		options: {
			required: true,
			default: () => [],
			type: Array,
		},
		validator: {
			type: Object,
			default: () => {},
		},
		modelValue: {
			required: true,
			default: () => [],
			validator(val) {
				return val === null || Array.isArray(val)
			},
		},
		inputStringCss: {
			type: String,
			default: "",
		},
		customStyleSelectionBox: {
			type: String,
		},
	},
	computed: {
		variants() {
			return {
				default: {
					item: "items-center mr-4 rounded-lg cursor-pointer px-5 py-2 border border-c3-50 bg-cWhite w-full",
					selected: "border border-c3-100",
				},
				primary: {
					item: "items-center mr-4 rounded-lg cursor-pointer px-8 py-3 border border-c3-50",
					selected: "border border-c3-100",
				},
				nonBorder: {
					item: "items-center mr-4 rounded-lg cursor-pointer px-8 py-3",
					selected: "border-transparent",
				},
				secondary: {
					item: "items-center mb-4 rounded-lg cursor-pointer px-5 py-2 border border-c3-50 bg-cWhite",
					selected: "bg-cWhite border border-c3-100",
				},
				customBox: {
					item: "items-center rounded-lg cursor-pointer border border-c3-50 bg-cWhite px-5 py-2 lg:px-2 lg:py-2 xl:px-5",
					selected: "bg-cWhite border border-c3-100",
				},
			}
		},
	},
	methods: {
		compare(option, valueTwo) {
			if (valueTwo.includes(option.value)) return true
			return false
		},
		async onSelectItem(option) {
			const newValue = this.modelValue
			if (newValue) {
				let isAdding = false
				if (newValue.find((item) => item === option.value)) {
					newValue.splice(newValue.indexOf(option.value), 1)
				} else {
					newValue.push(option.value)
					isAdding = true
				}
				this.$emit("update:modelValue", newValue)
				if (isAdding) {
					await nextTick()
					this.$emit("optionAdded", option.value)
				} else {
					this.$emit("optionRemoved", option.value)
				}
			}
		},
	},
}
</script>
