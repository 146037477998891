import getEnv from "@/utils/getEnv"
export default {
	tenantId: null,
	clientId: null,
	accessToken: null,
	tokenExpired: false,
	// Cross route messages for notifications
	messages: [],
	dateFormat: "DD/MM/YYYY",
	dateTimeFormat: "DD/MM/YYYY HH:mm:ss",
	tenantSettings: null,
	me: {},
	appConfig: null,
	leadGCM: null,
	msalConfig: {
		auth: {
			clientId: getEnv("VUE_APP_COVERGO_CLIENTID"),
			authority: getEnv("VUE_APP_COVERGO_AUTHORITY"),
			redirectUri: getEnv("VUE_APP_COVERGO_REDIRECT_URI"),
		},
		cache: {
			cacheLocation: "localStorage",
		},
	},
}
