<template>
	<teleport to="#layer2">
		<JModalSimple :isVisible="isVisible" @overlay-click="onCancel()">
			<JBox class="mb-6">
				<JText v-if="label" class="text-c0-300 font-medium"> {{ label }} </JText>
				<JHeadline as="h2" variant="h1" class="text-c1-500"> {{ title || $t("core.notes") }} </JHeadline>
			</JBox>
			<JBox class="mb-6">
				<CFormInput
					v-model="form.title"
					componentName="JInputText"
					:label="$t('core.title')"
					:validator="vuelidate"
					field="form.title"
				/>
			</JBox>
			<JBox class="mb-6">
				<CFormInput
					v-model="form.content"
					componentName="JInputLongText"
					:rows="3"
					:label="$t('core.content')"
					:validator="vuelidate"
					field="form.content"
				/>
			</JBox>
			<JFlex>
				<JButton v-if="submitBtn" @click="onSubmit()" class="mr-2">
					{{ submitBtn }}
				</JButton>
				<JButton variant="tertiary-outline" @click="onCancel()">
					{{ $t("core.cancel") }}
				</JButton>
			</JFlex>
		</JModalSimple>
	</teleport>
</template>
<script>
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { reactive, onMounted } from "vue"

export default {
	emits: ["submit", "cancel"],
	props: {
		isVisible: {
			type: Boolean,
			default: false,
		},

		note: {
			type: Object,
			default: () => {
				return {
					title: "",
					content: "",
				}
			},
		},

		label: {
			type: String,
		},

		title: {
			type: String,
		},

		submitBtn: {
			type: String,
		},
	},

	setup(props, { emit }) {
		const form = reactive({
			title: "",
			content: "",
		})

		// Validation Logics
		const rules = {
			form: {
				title: { required },
				content: { required },
			},
		}
		const vuelidate = useVuelidate(rules, { form })

		function onCancel() {
			vuelidate._value.form.$reset()
			emit("cancel")
			loadForm()
		}

		function onSubmit() {
			vuelidate._value.form.$touch()
			if (vuelidate._value.form.$invalid) return

			emit("submit", form)
		}

		function loadForm() {
			const { title, content } = props.note
			form.title = title
			form.content = content
		}

		onMounted(() => {
			loadForm()
		})

		return { form, vuelidate, onCancel, onSubmit }
	},
}
</script>
