<template>
	<JBox>
		<JFlex class="mx-5 mt-6 mb-6 text-c1-800 font-bold text-lg">
			<JText class="font-bold text-lg">{{ $t("core.relationships") }}</JText>
			<JButton v-if="isEditable" variant="tertiary-rounded" class="ml-auto" style="padding: 0.25rem" @click="toggleModal()">
				<JIcon class="text-c1-500" icon="Plus" />
			</JButton>
		</JFlex>
		<CEditableField
			v-for="(item, index) in computedList"
			:key="index"
			:label="item.label"
			:value="item.type"
			@on-click-value="goToEntity(item.id)"
			@edit="showUpdateModal(item)"
			@delete="handleRemove(item)"
			:canEdit="false"
			:canDelete="isEditable"
		/>
		<JBox v-if="computedList.length === 0" class="mx-5">
			<JText class="text-c0-500"> {{ $t("core.noDataHere") }}</JText>
		</JBox>
		<!-- Modal to handle enity select  -->
		<teleport to="#layer2">
			<JModalSimple zIndex="100" :isVisible="showModal" @overlay-click="toggleModal()">
				<CEntityPicker @selected-entity="handleEntitySelect($event)" :allowedEntityTypes="['individual', 'company', 'object']" />
			</JModalSimple>
		</teleport>

		<teleport to="#layer2">
			<JModalSimple variant="fixed-left" zIndex="100" :isVisible="showTypeModal" @overlay-click="toggleTypeModal()">
				<JBox class="px-10 mt-4">
					<CFormInput
						v-model="relationshipType"
						componentName="JInputText"
						:label="$t('core.relationshipType')"
						placeholder="Brother, Sister, Uncle etc."
						:validator="v"
						field="relationshipType"
					/>
					<JFlex class="justify-end mt-6">
						<JButton @click="handleAdd()" class="mr-2">
							{{ $t("core.add") }}
						</JButton>
						<JButton variant="tertiary-outline" @click="toggleTypeModal()">
							{{ $t("core.cancel") }}
						</JButton>
					</JFlex>
				</JBox>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import { apiAddLink, apiRemoveLink } from "@covergo/cover-composables"
import { fetcher } from "../../api/fetcher"
import { handleErrorForUser } from "../../api/handleErrorForUser"
import { required } from "@vuelidate/validators"
import { ref, computed, watch } from "vue"
import useVuelidate from "@vuelidate/core"
import { useI18n } from "vue-i18n"

export default {
	props: {
		fields: {
			type: Array,
		},
		entityId: {
			type: String,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const { t } = useI18n()
		const list = ref([])
		const computedList = computed(() => list?.value?.map((item) => ({ ...item, label: item?.entity?.name })) || [])
		const showModal = ref(false)
		const showTypeModal = ref(false)
		const selectedEntity = ref(null)
		const relationshipType = ref(null)

		/** Vuelidate */
		const rules = {
			selectedEntity: { required },
			relationshipType: { required },
		}
		const v = useVuelidate(rules, { selectedEntity, relationshipType })
		const checkFormValid = () => {
			v.value.relationshipType.$touch()
			return !v.value.relationshipType.$invalid
		}

		const clearForms = () => {
			v.value.$reset()
			selectedEntity.value = null
			relationshipType.value = null
		}

		const goToEntity = () => {
			// Go to entity
		}

		const toggleModal = () => {
			showModal.value = !showModal.value
		}
		const toggleTypeModal = () => {
			showTypeModal.value = !showTypeModal.value
		}
		const handleEntitySelect = (entity) => {
			selectedEntity.value = entity
			toggleModal()
			toggleTypeModal()
		}

		// managing local state
		const updateList = (payload) => {
			const array = list.value
			array.push(payload)
		}
		const removeFromList = (relationshipId) => {
			list.value = list.value?.filter((item) => item.id !== relationshipId)
		}

		// add
		const handleAdd = async () => {
			const isFormValid = checkFormValid()
			if (!isFormValid) return
			const variables = {
				input: {
					sourceId: props?.entityId,
					link: relationshipType.value,
					targetId: selectedEntity.value?.id,
				},
			}
			const { data, error } = await apiAddLink({
				variables,
				fetcher,
			})
			if (error) {
				handleErrorForUser({ error, $t: t })
				return
			}
			toggleTypeModal()
			const createdStatusId = data?.createdStatus?.id
			updateList({ id: createdStatusId, entity: selectedEntity.value, type: relationshipType.value })
			clearForms()
		}

		// remove
		const handleRemove = async (relationship) => {
			const variables = {
				input: {
					sourceId: props?.entityId,
					link: relationship?.type,
					targetId: relationship?.entity?.id,
				},
				id: relationship?.id,
			}
			const { error } = await apiRemoveLink({
				variables,
				fetcher,
			})
			if (error) {
				handleErrorForUser({ error, $t: t })
				return
			}
			removeFromList(relationship?.id)
		}

		// watcher
		watch(
			() => props.fields,
			(updatedVal) => {
				list.value = updatedVal || []
			},
			{
				deep: true,
				immediate: true,
			}
		)
		return {
			computedList,
			showModal,
			showTypeModal,
			selectedEntity,
			relationshipType,
			v,
			clearForms,
			goToEntity,
			toggleModal,
			toggleTypeModal,
			handleEntitySelect,
			handleAdd,
			handleRemove,
		}
	},
}
</script>
