import { ref } from "vue"
import { Machine } from "xstate"
import { useMachine } from "@/modules/core/composables/useMachine"
import { commissionSplitMachine } from "../machine/commissionSplitMachine"
import {
	gql,
	apiPromise,
	apiPolicies,
	apiAddCommission,
	apiRemoveCommission,
	apiUpdateCommission,
} from "@covergo/cover-composables"
import { fetcher } from "../api/fetcher"
import { handleErrorForUser } from "../api/handleErrorForUser"
import { useI18n } from "vue-i18n"
import { useRoute } from "vue-router"

export function usePolicyCommissionSplit() {
	const { t } = useI18n()
	const route = useRoute()

	const commissionData = ref([])

	const queryVariables = ref({})
	const idOfRuleToDelete = ref(null)

	const policyId = route?.params?.policyId

	const options = {
		services: {
			async readCommissionSplitData() {
				const variables = {
					where: {
						id: policyId,
					},
				}
				const fragment = gql`
					fragment result on policies {
						list {
							...policy
						}
					}
					fragment policy on policy {
						commissionSplitRules {
							id
							entity {
								__typename
								id
								name
							}
							amount
							jsonRule
							type
							currencyCode
							ratio
							remark
							baseCommissionRatio {
								ratio
							}
						}
					}
				`
				return await apiPromise(apiPolicies, {
					variables,
					fetcher,
					fragment,
				})
			},
			async addCommissionSplitRule() {
				const variables = {
					policyId,
					...queryVariables.value,
				}
				return await apiPromise(apiAddCommission, {
					variables,
					fetcher,
				})
			},
			async removeCommissionSplitRule() {
				const variables = {
					policyId,
					id: idOfRuleToDelete.value,
				}
				return await apiPromise(apiRemoveCommission, {
					variables,
					fetcher,
				})
			},
			async updateCommissionSplitRule() {
				const variables = {
					policyId,
					...queryVariables.value,
				}
				return await apiPromise(apiUpdateCommission, {
					variables,
					fetcher,
				})
			},
		},
		actions: {
			setResults(ctx, event) {
				commissionData.value = event?.data?.list[0]?.commissionSplitRules || []
			},
			setFailureMessage(ctx, event) {
				handleErrorForUser({ error: event?.data?.errors, $t: t })
			},
		},
	}

	const { state, send } = useMachine(Machine(commissionSplitMachine, options), {
		devTools: process.env.NODE_ENV === "development",
	})

	return {
		state,
		send,
		queryVariables,
		commissionData,
		idOfRuleToDelete,
	}
}
