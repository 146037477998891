<template>
	<JBox>
		<JFlex class="mx-5 mt-6 mb-6 text-c1-800 font-bold text-lg">
			<JText class="font-bold text-lg">{{ $t("core.addresses") }}</JText>
			<JButton v-if="isEditable" variant="tertiary-rounded" class="ml-auto" style="padding: 0.25rem" @click="openAddModal()">
				<JIcon class="text-c1-500" icon="Plus" />
			</JButton>
		</JFlex>
		<CAccordion
			class="mb-2 border border-c0-200 border-opacity-25"
			v-for="(item, index) in computedList"
			:key="index"
			headVariant="h4"
			:title="item.type"
			:defaultClose="true"
		>
			<CEditableField
				v-for="(field, f) in item.fields2"
				:key="f"
				:label="field.type"
				:value="field.value"
				:canEdit="isEditable"
				:canDelete="isEditable"
				@edit="initUpdateField({ field, id: item.id, type: item.type, fieldIndex: f })"
				@delete="handleRemoveField({ id: item.id, type: item.type, fieldIndex: f })"
			/>
			<JFlex
				class="justify-end items-center cursor-pointer px-5 py-4 rounded-lg text-c0-500 leading-tight hover:bg-c0-100"
				@click="initAddField({ id: item.id, type: item.type })"
			>
				<JIcon class="mr-3" icon="plus" />
				<JText class="font-medium text-md">{{ $t("core.addField") }}</JText>
			</JFlex>
			<JFlex class="justify-end items-center cursor-pointer text-cError-500 mt-4 px-4 py-2" @click="removeAddress(item.id)">
				<JIcon class="mr-3" icon="Trash" />
				<JText class="font-medium">{{ $t("core.deleteAddress") }}</JText>
			</JFlex>
		</CAccordion>

		<JBox v-if="computedList.length === 0" class="mx-5">
			<JText class="text-c0-500"> {{ $t("core.noDataHere") }}</JText>
		</JBox>

		<!-- Handle adding of address type -->
		<teleport to="#layer2">
			<JModalSimple zIndex="50" :isVisible="showAddModal" @overlay-click="closeAddModalAndClear()">
				<CFormInput
					v-model="addressType"
					componentName="JInputText"
					:label="$t('core.addressType')"
					placeholder="Home Address, Work Address, etc."
					class="mb-6"
					:validator="v"
					field="addressType"
				/>
				<JFlex>
					<JButton @click="addAddress()" class="mr-2">
						{{ $t("core.add") }}
					</JButton>
					<JButton variant="tertiary-outline" @click="closeAddModalAndClear()">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
			</JModalSimple>
		</teleport>

		<!-- Modal to handle update and add of address fields -->
		<teleport to="#layer2">
			<JModalSimple zIndex="50" :isVisible="showUpdateModal" @overlay-click="closeUpdateModalAndClear()">
				<CFormInput
					v-model="form.type"
					componentName="JInputText"
					:label="$t('core.type')"
					class="mb-6"
					:validator="v"
					field="form.type"
				/>
				<CFormInput
					v-model="form.value"
					componentName="JInputText"
					:label="$t('core.value')"
					class="mb-6"
					:validator="v"
					field="form.value"
				/>
				<JFlex>
					<JButton @click="handleUpdateField()" class="mr-2">
						{{ $t("core.ok") }}
					</JButton>
					<JButton variant="tertiary-outline" @click="closeUpdateModalAndClear()">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import { apiAddAddress, apiRemoveAddress, apiUpdateAddress } from "@covergo/cover-composables"

import { fetcher } from "../../api/fetcher"
import { handleErrorForUser } from "../../api/handleErrorForUser"
import { required } from "@vuelidate/validators"
import { ref, computed, watch } from "vue"
import { useI18n } from "vue-i18n"
import useVuelidate from "@vuelidate/core"

export default {
	props: {
		fields: {
			type: Array,
		},
		entityId: {
			type: String,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const { t } = useI18n()

		const showAddModal = ref(false)
		const showUpdateModal = ref(false)
		const selectedObj = ref(null)
		const addressType = ref(null)
		const form = ref({
			type: null,
			value: null,
		})

		/** Vuelidate */
		const rules = {
			addressType: { required },
			form: {
				type: {
					required,
				},
				value: {
					required,
				},
			},
		}
		const v = useVuelidate(rules, { addressType, form })

		const openAddModal = () => {
			showAddModal.value = true
		}
		const closeAddModalAndClear = () => {
			v.value.$reset()
			showAddModal.value = false
			addressType.value = null
		}
		const openUpdateModal = () => {
			showUpdateModal.value = true
		}
		const closeUpdateModalAndClear = () => {
			v.value.$reset()
			showUpdateModal.value = false
			form.value = {
				type: null,
				value: null,
			}
			selectedObj.value = null
		}

		// manage local state
		const list = ref([])
		const computedList = computed(() => list.value || [])
		const addToList = (item) => {
			const array = list?.value
			array.push(item)
		}
		const removeFromList = (id) => {
			list.value = list?.value?.filter((item) => item.id !== id)
		}
		const updateList = (newFields) => {
			const index = list?.value?.findIndex((item) => item.type === selectedObj?.value?.type)
			list.value[index].fields2 = newFields
		}

		// validate form
		const checkFormValid = () => {
			v.value.form.$touch()
			return !v.value.form.$invalid
		}
		const checkAddressTypeValid = () => {
			v.value.addressType.$touch()
			return !v.value.addressType.$invalid
		}

		const addAddress = async () => {
			const isValid = checkAddressTypeValid()
			if (!isValid) return
			const variables = {
				entityId: props?.entityId,
				input: {
					type: addressType.value,
					fields: [],
				},
			}
			const { error, data } = await apiAddAddress({
				variables,
				fetcher,
			})
			if (error) {
				handleErrorForUser({ error, $t: t })
				return
			}
			const createdId = data?.createdStatus?.id
			addToList({ id: createdId, type: addressType.value, fields2: [] })
			closeAddModalAndClear()
		}

		const removeAddress = async (id) => {
			const variables = {
				entityId: props?.entityId,
				id,
			}
			const { error } = await apiRemoveAddress({
				variables,
				fetcher,
			})
			if (error) {
				handleErrorForUser({ error, $t: t })
				return
			}
			removeFromList(id)
		}

		// updateAddress api input takes in field arg that accepts array of field objects
		// by adding or updating field in UI we still use updateAddress api call
		const initAddField = ({ id, type }) => {
			selectedObj.value = { id, type }
			openUpdateModal()
		}
		const initUpdateField = ({ field, id, type, fieldIndex }) => {
			selectedObj.value = { id, type, fieldIndex }
			form.value = { ...field }
			openUpdateModal()
		}

		const handleUpdateField = async () => {
			const isFormValid = checkFormValid()
			if (!isFormValid) return
			const { type, fieldIndex, id } = selectedObj.value
			let newFields = [...list?.value?.find((item) => item.type === type)?.fields2]
			if (fieldIndex === 0 || fieldIndex) {
				// update existing field
				newFields[fieldIndex] = { ...form?.value }
			} else if (!fieldIndex) {
				// add new field
				newFields = [...newFields, form?.value]
			}
			const hasError = await updateAddress(newFields, id)
			if (hasError) {
				handleErrorForUser({ error: hasError, $t: t })
				return
			}
			updateList(newFields)
			closeUpdateModalAndClear()
		}

		const handleRemoveField = async ({ id, type, fieldIndex }) => {
			selectedObj.value = { id, type, fieldIndex }
			const foundFields = [...list?.value?.find((item) => item.type === type)?.fields2]
			const newFields = foundFields?.filter((item, index) => index !== fieldIndex)
			const hasError = await updateAddress(newFields, id)
			if (hasError) {
				handleErrorForUser({ error: hasError, $t: t })
				return
			}
			updateList(newFields)
			closeUpdateModalAndClear()
		}
		const updateAddress = async (newFields, id) => {
			const variables = {
				entityId: props?.entityId,
				input: {
					id,
					type: selectedObj?.value?.type,
					fields: [...newFields],
				},
			}
			const { error } = await apiUpdateAddress({
				variables,
				fetcher,
			})
			return error
		}

		// watcher
		watch(
			() => props.fields,
			(updatedVal) => {
				list.value = updatedVal || []
			},
			{
				deep: true,
				immediate: true,
			}
		)

		return {
			v,
			list,
			showAddModal,
			showUpdateModal,
			selectedObj,
			addressType,
			form,
			computedList,
			openAddModal,
			closeAddModalAndClear,
			openUpdateModal,
			closeUpdateModalAndClear,
			addAddress,
			removeAddress,
			initAddField,
			initUpdateField,
			handleUpdateField,
			handleRemoveField,
			updateAddress,
		}
	},
}
</script>
