import { fetcher } from "../api/fetcher"
import { gql, apiIndividuals, apiPromise } from "@covergo/cover-composables"
import { renderSystemTimeout } from "../helpers"
import { parseJSON } from "@/modules/core/composables"

export default function useApis({ t } = {}) {
	async function readIndividualAgentCode(variables = {}) {
		const query = gql`
			query individualAgentCode2($where: individualWhereInput, $limit: Int, $skip: Int) {
				individualAgentCode(where: $where, limit: $limit, skip: $skip) {
					totalCount
					list {
						englishFirstName
						englishLastName
						fields
						source
					}
				}
			}
		`
		const { data, error } = await fetcher({ variables, query })
		if (error) throw error
		else return data
	}
	const fetchDataPolicyByServicingAgent = async ({ params = {} }) => {
		const query = `query integrate {
			integrate(
				commandType: "getServicingAgent"
				inputJson:${JSON.stringify(JSON.stringify(params))}
			){
				errors
				status
				value
			}
		}`
		const { data } = await fetcher({
			query,
		})
		const integrateResponse = data?.integrate?.value
		const backslashRemoved = integrateResponse?.replace(/\\/g, "")
		const filteredData = parseJSON(backslashRemoved || "{}")
		return {
			status: data?.integrate?.status,
			errors: data?.integrate?.errors,
			value: filteredData?.document,
		}
	}
	const sendRequestToUpdateAgentCode = async ({ params = {} }) => {
		const query = `
			query integrate {
				integrate(
					commandType: "updateServicingAgent"
					inputJson:${JSON.stringify(JSON.stringify(params))}
				){
					errors
					status
					value
				}
			}
		`
		const { data } = await fetcher({
			query,
		})
		const integrateResponse = data?.integrate?.value
		const backslashRemoved = integrateResponse?.replace(/\\/g, "")
		const dataResponse = parseJSON(backslashRemoved || "{}")
		return {
			status: data?.integrate?.status,
			errors: data?.integrate?.errors,
			value: dataResponse?.document,
		}
	}

	const fetchReminderList = async ({ params = {} }) => {
		const query = `query integrate {
			integrate(
				commandType: "getReminders"
				inputJson:${JSON.stringify(JSON.stringify(params))}
			){
				errors
				status
				value
			}
		}`
		const { data } = await fetcher({
			query,
		})
		const integrateResponse = data?.integrate?.value
		const backslashRemoved = integrateResponse?.replace(/\\/g, "")
		const filteredData = parseJSON(backslashRemoved || "{}")
		return {
			status: data?.integrate?.status,
			totalElements: filteredData?.totalElements,
			data: filteredData?.data || [],
		}
	}

	const fetchAgentRemindersQuery = async (variables = {}) => {
		/**
		 * field on query: orderBy: users_OrderByInput -> fieldName: "from" : to get latest 5 items
		 */
		/**
		 * TO FETCH DATA OF PAYMENT DUE DATE: 2 fields paidToDate are null
			const params = {
				branchCode: "BRA01" || userBranch.value,
				poDobFrom: "01-01",
				poDobTo: "12-31",
				paidToDateFrom: null,
				paidToDateTo: null,
				pageSize: limit.value,
				pageNumber: currentPage.value
			}
			TO FETCH DATA OF DOB DUE: (2 fields DOB are null)
			const params = {
				branchCode: "BRA01" || userBranch.value,
				poDobFrom: null,
				poDobTo: null,
				paidToDateFrom: "2021-01-01",
				paidToDateTo: "2021-12-31",
				pageSize: limit.value,
				pageNumber: currentPage.value
			}
		 */
		const query = gql`
			query agentRemindersQuery($where: users_GenericAgentReminderQueryInput) {
				agentRemindersQuery(where: $where) {
					list {
						id
						agentCode
						branchCode
						type
						from
						to
						createdAt
						lastModifiedAt
						isChecked
					}
					totalCount
				}
			}
		`
		const { data, error } = await fetcher({ variables, query })
		if (error) throw error
		else return data?.agentRemindersQuery || []
	}

	const updateNotiHasRead = async (variables) => {
		if (!variables && variables?.update?.id) return Promise.reject(new Error("missingId"))

		const query = gql`
			mutation agentReminderMutationUpdate($update: users_AgentReminderUpsertInput) {
				agentReminderMutationUpdate(update: $update) {
					status
					errors
				}
			}
		`
		const { errors } = await fetcher({ variables, query })
		if (errors) throw errors
	}

	const readInfoContract = async ({ policyNo }) => {
		const params = {
			policyNo,
		}
		const query = `query integrate {
		integrate(
			commandType: "getPolicyDetail"
			inputJson:${JSON.stringify(JSON.stringify(params))}
		){
			errors
			status
			value
		}
	}`
		const { data } = await fetcher({
			query,
		})
		const integrateResponse = data?.integrate?.value
		const backslashRemoved = integrateResponse?.replace(/\\/g, "")
		const dataResponse = parseJSON(backslashRemoved || "{}")

		if (dataResponse?.statusCode === "timeout") {
			await renderSystemTimeout({ code: dataResponse?.statusCode, message: dataResponse?.message })
			return {
				status: "error",
				value: null,
			}
		}

		return {
			status: "success",
			value: dataResponse,
		}
	}

	const inquiryPolicies = async ({ params }) => {
		/**
		 * expectedParam = {
			poId: null,
			agentCode: ["B7945"], // basing on user Role -> map the agent code list, if user is FRONTLINE / user doesnt input an agent code value-> default will be agent code of login user
			branchCode: ["CGY"], // format to array
			poDobFrom: "1980-05-23", // format is MM-DD
			poDobTo: "1980-05-23", // format is MM-DD
			policyNo: "2951060051",
			policyStatus: ["1"] || [] || null,
			submissionDateFrom: "2018-08-31",
			submissionDateTo: "2018-08-31",
			passFreeLookDateFrom: "2018-10-03",
			passFreeLookDateTo: "2018-10-03",
			paidToDateFrom: null,
			paidToDateTo: null,
			"issuanceDateFrom": "2018-01-01",
 			"issuanceDateTo": "2018-12-12",
			pageNumber: 0,
			pageSize: 10,
		}
		 */
		const query = `query integrate {
			integrate(
				commandType: "inquiryPolicy"
				inputJson:${JSON.stringify(JSON.stringify(params))}
			){
				errors
				status
				value
			}
		}`
		const { data } = await fetcher({
			query,
		})
		const integrateResponse = data?.integrate?.value
		const backslashRemoved = integrateResponse?.replace(/\\/g, "")
		const filteredData = parseJSON(backslashRemoved || "{}")

		if (filteredData?.statusCode === "timeout") {
			await renderSystemTimeout({ code: filteredData?.statusCode, message: filteredData?.message })
			return {
				status: "error",
				value: null,
			}
		}

		return {
			status: "success",
			value: filteredData, // null is default
		}
	}

	const fetchIndividuals = async (staffIds) => {
		const mStaffIdList = staffIds?.map((sId) => ({ stringValue: sId }))
		const fragment = gql`
			fragment result on individuals {
				list {
					id
					fields
					name
				}
			}
		`
		const variables = {
			where: {
				fields: {
					path: "fields.staffId",
					condition: "IN",
					value: {
						arrayValue: mStaffIdList,
					},
				},
			},
		}
		const data = await apiPromise(apiIndividuals, { variables, fetcher, fragment })

		return data?.list || []
	}

	async function queryAgentList(variables = {}) {
		// Check filter condition exists to avoid fetch all agents (https://covergo.atlassian.net/browse/TEC-1938)
		if (!variables.optionsSelectedByRole?.length) return

		const params = {
			limit: variables.limit,
			skip: variables.skip,
			where: {
				and: [
					{
						or: [
							{
								hasEnglishFirstNameNotNull: true,
							},
							{ hasEnglishLastNameNotNull: true },
						],
					},
					{ or: variables.roles },
					{ or: variables.optionsSelectedByRole },
				],
			},
		}

		return await readIndividualAgentCode(params)
	}

	return {
		readIndividualAgentCode,
		fetchDataPolicyByServicingAgent,
		sendRequestToUpdateAgentCode,
		fetchReminderList,
		fetchAgentRemindersQuery,
		updateNotiHasRead,
		readInfoContract,
		inquiryPolicies,
		fetchIndividuals,
		queryAgentList,
	}
}
