import { getElement } from "@/utils"

export function scrollToFirstError(elementOrSelector, options = {}) {
	const container = getElement(elementOrSelector)
	const messages = container && container.querySelector(".c-form-field__messages")

	if (messages) {
		const field = messages.closest(".c-form-field")

		field.scrollIntoView({ inline: "start" })
	}
}
