export const commissionSplitMachine = {
	id: "commissionSplit",
	initial: "reading",
	states: {
		reading: {
			id: "reading",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "readCommissionSplitDataService",
						src: "readCommissionSplitData",
						onDone: { target: "fetched", actions: "setResults" },
						onError: { target: "failure", actions: "setFailureMessage" },
					},
				},
				fetched: {
					on: {
						ADD_RULE: "#commissionSplit.adding",
						UPDATE_RULE: "#commissionSplit.updating",
						CONFIRM_REMOVE: "#commissionSplit.removing",
					},
				},
				failure: {
					on: { RETRY: "fetching" },
				},
			},
		},
		adding: {
			id: "adding",
			initial: "confirming",
			states: {
				confirming: {
					on: {
						CONFIRM_ADD: "fetching",
						CANCEL_ADD: "#reading.fetched",
					},
				},
				fetching: {
					invoke: {
						id: "addCommissionSplitService",
						src: "addCommissionSplitRule",
						onDone: "#commissionSplit.reading",
						onError: { target: "failure", actions: "setFailureMessage" },
					},
				},
				failure: {
					on: { RETRY: "confirming" },
				},
			},
		},
		updating: {
			id: "updating",
			initial: "confirming",
			states: {
				confirming: {
					on: {
						CONFIRM_UPDATE: "fetching",
						CANCEL_UPDATE: "#reading.fetched",
					},
				},
				fetching: {
					invoke: {
						id: "updateCommissionSplitService",
						src: "updateCommissionSplitRule",
						onDone: "#commissionSplit.reading",
						onError: { target: "failure", actions: "setFailureMessage" },
					},
				},
				failure: {
					on: { RETRY: "confirming" },
				},
			},
		},
		removing: {
			id: "removing",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "removeCommissionSplitService",
						src: "removeCommissionSplitRule",
						onDone: "#commissionSplit.reading",
						onError: { target: "failure", actions: "setFailureMessage" },
					},
				},
				failure: {
					on: { RETRY: "fetching" },
				},
			},
		},
	},
}
