<template>
	<JBox
		:class="isShowMore ? 'bg-c0-100' : ''"
		class="group relative px-5 py-4 rounded-lg text-c0-500 leading-tight hover:bg-c0-100"
	>
		<JFlex class="items-center">
			<JBox class="w-4/12 pr-2">
				<slot name="label">
					<JText>{{ label ? label : "-" }}</JText>
				</slot>
			</JBox>
			<JBox class="px-2" :class="isReadOnly ? 'w-8/12' : 'w-7/12'">
				<slot name="value">
					<JText v-if="!isList" class="font-medium break-all" :class="inputCustomClass" @click="handleClickValue">
						{{ formattedValue }}
					</JText>
					<JBox v-if="isList" class="pl-4">
						<ul style="list-style-type: disc">
							<li v-for="(item, idx) in value" :key="idx">
								{{ item }}
							</li>
						</ul>
					</JBox>
				</slot>
			</JBox>
			<JBox v-if="!isReadOnly" class="ml-auto invisible group-hover:visible">
				<!-- Edit Only -->
				<JButton v-if="canEdit && !canDelete" variant="transparent-rounded" style="padding: 0.25rem" @click="handleClickEdit">
					<JIcon icon="Pencil" />
				</JButton>
				<!-- Delete Only -->
				<JButton v-if="canDelete && !canEdit" variant="transparent-rounded" style="padding: 0.25rem" @click="handleClickDelete">
					<JIcon class="text-cError-500" icon="Trash" />
				</JButton>
				<!-- Edit & Delete -->
				<JButton
					v-if="canDelete && canEdit"
					v-click-outside="hideShowMore"
					variant="transparent-rounded"
					style="padding: 0.25rem; min-height: 28px"
					@click="handleClickMore"
				>
					<JIcon icon="DotsHorizontal" />
				</JButton>
			</JBox>
			<JBox v-if="isShowMore" class="absolute z-10 right-0 py-2 bg-cWhite rounded-lg shadow" style="top: 2rem">
				<JFlex class="items-center cursor-pointer text-c0-500 px-4 py-2 hover:bg-c0-100" @click="handleClickEdit">
					<JIcon class="mr-3" icon="Pencil" />
					<JText class="font-medium"> {{ $t("core.edit") }} </JText>
				</JFlex>
				<JFlex class="items-center cursor-pointer text-cError-500 px-4 py-2 hover:bg-c0-100" @click="handleClickDelete">
					<JIcon class="mr-3" icon="Trash" />
					<JText class="font-medium"> {{ $t("core.delete") }} </JText>
				</JFlex>
			</JBox>
		</JFlex>
	</JBox>
</template>

<script>
import dayjs from "dayjs"

export default {
	name: "CEditableField",
	emits: ["edit", "delete", "value-click"],
	props: {
		/**
		 * @description Field Label
		 */
		label: {
			type: String,
			required: false,
			default: "",
		},
		/**
		 * @description Field value
		 */
		value: {
			required: true,
		},
		/**
		 * @description Field id to call functionalities
		 */
		itemId: {
			type: String,
			default: "",
		},
		/**
		 * @description Field type
		 */
		type: {
			type: String,
			default: "string",
		},
		/**
		 * @description Date time format
		 */
		dateFormat: {
			type: String,
			default: "DD/MM/YYYY",
		},
		/**
		 * @description Hide/Show showMore button
		 */
		isReadOnly: {
			type: Boolean,
			default: false,
		},
		/**
		 * @description Render value in a list
		 */
		isList: {
			type: Boolean,
			default: false,
		},
		/**
		 * @description Enable/Disable Edit function
		 */
		canEdit: {
			type: Boolean,
			default: true,
		},
		/**
		 * @description Enable/Disable Delete function
		 */
		canDelete: {
			type: Boolean,
			default: true,
		},
		/**
		 * @description Custom label class
		 */
		inputCustomClass: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			isShowMore: false,
		}
	},
	computed: {
		formattedValue() {
			if (this.type !== "boolean" && this.type !== "booleanValue") {
				if (!this.value) return "-"
				if (dayjs.isDayjs(this.value) || this.type === "dateValue") {
					return dayjs(this.value).format(this.dateFormat)
				} else {
					return this.value
				}
			} else {
				return this.value ? this.$t("core.yes") : this.$t("core.no")
			}
		},
	},
	methods: {
		handleClickMore() {
			this.isShowMore = !this.isShowMore
		},
		hideShowMore() {
			this.isShowMore = false
		},
		handleClickEdit() {
			this.$emit("edit", {
				id: this.itemId,
				type: this.type,
				label: this.label,
				value: this.value,
			})
		},
		handleClickDelete() {
			this.$emit("delete", this.itemId)
		},
		handleClickValue() {
			this.$emit("value-click")
		},
	},
}
</script>
