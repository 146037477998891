import { useI18n } from "vue-i18n"
import { computed } from "vue"
import { useStore } from "vuex"
import {
	MANAGER_POSITIONS,
	FRONTLINE_BY_SOURCE,
	LEAD_DEPARTMENT,
	RBO_SRBO_FRONT_LINE_OPTIONS,
	TIS_PRM_FRONT_LINE_OPTIONS,
	SUPERVISOR_DEPT,
	BRANCH_MANAGER,
	FRONTLINE_LEAD_RBOSRBO,
	FRONTLINE_LEAD_TISPRM,
	FRONTLINE_PRM,
	FRONTLINE_TIS,
	FRONTLINE_RBO,
	FRONTLINE_SRBO,
	FRONTLINE_DRBO,
	INSURANCE_EXPERT_USER,
	SEARCH_TYPES,
} from "@/modules/dashboard/utils/constant"

import { splitAgentCodeValue } from "@/helpers/splitAgentCodeValue.js"
import useSourceOfLoginUser from "@/modules/core/use/useUserSource"
export function useContractInfoDetail() {
	const { t } = useI18n()
	const store = useStore()
	const yearMonthDayFormat = computed(() => "YYYY-MM-DD")
	const dayMonthYearFormat = computed(() => "DD/MM/YYYY")

	const { userPosition, userArea, userRegion, userBranch, userLocatedByBranchOrArea } = useSourceOfLoginUser()

	// user info
	const userRole = computed(() => store.getters.user_role)
	const userLogin = computed(() => store.getters.me)
	const agentCodeOfUserLogin = computed(() => JSON.parse(store.getters.me?.associatedUser?.fields || "{}")?.agentCode || "")

	const isBranchManager = computed(() => userPosition === "branchManager")
	const isExecutiveRole = computed(() => userPosition === "executive")
	const isRegionManager = computed(() => userPosition === "regionalManager")
	const isAreaManager = computed(() => userPosition === "areaManager")
	const isLeadDepartment = computed(() => LEAD_DEPARTMENT.includes(userPosition))
	const isHoManager = computed(() => userPosition === "hoManager")
	const isFrontline = computed(() => FRONTLINE_BY_SOURCE.includes(userPosition))
	const isInsuranceExpert = computed(() => userPosition === INSURANCE_EXPERT_USER.NewTIS)
	const isManagerPosition = computed(() => {
		return (
			userPosition === "executive" ||
			userPosition === "branchManager" ||
			userPosition === "regionalManager" ||
			userPosition === "areaManager" ||
			userPosition === "RBO.SRBO" ||
			userPosition === "TIS.PRM"
		)
	})
	const isLocationManagers = computed(() => {
		const roleList = MANAGER_POSITIONS.filter((i) => i !== "executive")
		return roleList.includes(userPosition)
	})

	const isSupervisorDept = computed(() => {
		return SUPERVISOR_DEPT.includes(userPosition)
	})
	const isFrontlineAndExpertDept = computed(() => isFrontline.value || isInsuranceExpert.value)

	const setDefaultUserLoggedInRole = () => {
		if (isExecutiveRole.value || isHoManager.value) return "ho" // head office
		if (isBranchManager.value) return "branch"
		if (isRegionManager.value) return "region"
		if (isAreaManager.value) return "area"
		if (isLeadDepartment.value) return "lead"
		return "staff"
	}

	const defaultStaffRoles = computed(() => {
		let listedByRoles = []
		if (isHoManager.value || isAreaManager.value) {
			listedByRoles = [...FRONTLINE_BY_SOURCE, INSURANCE_EXPERT_USER.NewTIS]
		}
		if (isRegionManager.value || isBranchManager.value) {
			listedByRoles = FRONTLINE_BY_SOURCE
		}
		if (userPosition === FRONTLINE_LEAD_RBOSRBO) {
			listedByRoles = RBO_SRBO_FRONT_LINE_OPTIONS
		}
		if (userPosition === FRONTLINE_LEAD_TISPRM) {
			listedByRoles = TIS_PRM_FRONT_LINE_OPTIONS
		}
		return listedByRoles.map((item) => ({ name: item, value: item, label: item }))
	})

	const quickOptionsDatePicker = computed(() => {
		return [
			{ label: t("core.today"), value: "today" },
			{ label: t("core.lastWeek"), value: "lastWeek" },
			{ label: t("core.lastMonth"), value: "lastMonth" },
			{ label: t("core.lastThreeMonths"), value: "last3Months" },
		]
	})
	const customDateTypeOptions = computed(() => {
		return [
			{ name: t("dashboard.submissionDate"), value: "submissionDate" },
			{ name: t("dashboard.issuedDate"), value: "issuedDate" },
			{ name: t("dashboard.passFreeLookDateSearch"), value: "passFreeLookDate" },
			{ name: t("dashboard.paidToDate"), value: "paidToDate" },
			{ name: t("dashboard.customerDob"), value: "customerDob" },
		]
	})
	const dateTypeOptions = computed(() => [{ name: t("core.between"), value: "between" }])
	const contractStatusesOfMVL = computed(() => {
		return [
			{ order: 1, value: "1", name: `${t("dashboard.payPremium")}` },
			{ order: 2, value: "2", name: `${t("dashboard.freeCharge")}` },
			{ order: 3, value: "3", name: `${t("dashboard.fullFee")}` },
			{ order: 4, value: "4", name: `${t("dashboard.extendedTermIns")}` },
			{ order: 5, value: "5", name: `${t("dashboard.decreaseInsurance")}` },
			{ order: 6, value: "6", name: `${t("dashboard.waitingForBeingPaidInsuranceFee")}` },
			{ order: 7, value: "7", name: `${t("dashboard.supportToApplyFee")}` },
			{ order: 8, value: "8", name: `${t("dashboard.waitingToProvideAContract")}` },
			{ order: 9, value: "9", name: `${t("dashboard.supportToApplyRiskyFee")}` },
			{ order: 10, value: "A", name: `${t("dashboard.customerRefuseAContract")}` },
			{ order: 11, value: "B", name: `${t("dashboard.invalid")}` },
			{ order: 12, value: "C", name: `${t("dashboard.converted")}` },
			{ order: 13, value: "D", name: `${t("dashboard.death")}` },
			{ order: 14, value: "E", name: `${t("dashboard.cancelMidway")}` },
			{ order: 15, value: "F", name: `${t("dashboard.renewal")}` },
			{ order: 16, value: "H", name: `${t("dashboard.renewal")}` },
			{ order: 17, value: "L", name: `${t("dashboard.cancel")}` },
			{ order: 18, value: "M", name: `${t("dashboard.criticalIllness")}` },
			{ order: 19, value: "N", name: `${t("dashboard.refuseAContract")}` },
			{ order: 20, value: "R", name: `${t("dashboard.contractHasBeenRefused")}` },
			{ order: 21, value: "T", name: `${t("dashboard.permanentDisability")}` },
			{ order: 22, value: "X", name: `${t("dashboard.cancelAContract")}` },
		]
	})
	const contractStatusesOfTCB = computed(() => {
		return [
			{ order: 0, value: "all", name: `${t("dashboard.all")}` },
			{ order: 1, value: "PEND", name: `${t("dashboard.tcbStatusPend")}` },
			{ order: 2, value: "ISSUED", name: `${t("dashboard.tcbStatusIssued")}` },
			{ order: 3, value: "ISAF21", name: `${t("dashboard.tcbStatusCode")}` },
			{ order: 4, value: "CANCEL", name: `${t("dashboard.tcbStatusCancel")}` },
			{ order: 5, value: "REJECT", name: `${t("dashboard.tcbStatusReject")}` },
			{ order: 6, value: "NTAKEN", name: `${t("dashboard.tcbStatusNTaken")}` },
		]
	})
	const agentCodeUpdatedStatusOptions = computed(() => {
		return [
			{ value: "Success", name: `${t("dashboard.successfully")}` },
			{ value: "Failed", name: `${t("dashboard.unsuccessfully")}` },
		]
	})
	const frontLineTableHeaderContractList = computed(() => [
		{
			name: "contractNo",
			label: t("dashboard.contractNo"),
			order: 1,
		},
		{
			name: "policyOwner",
			label: t("dashboard.policyOwner"),
			order: 2,
		},
		{
			name: "leadGCM",
			label: t("dashboard.leadGCM_CRM"),
			order: 3,
		},
		{
			name: "contractApplyDate",
			label: t("dashboard.contractApplyDate"),
			order: 4,
		}, // submission date
		{
			name: "issuedDate",
			label: t("dashboard.issuedDate"),
			order: 5,
		}, // issuance date
		{
			name: "mainProductAPE",
			label: t("dashboard.mainProductAPE"),
			order: 6,
		},
		{
			name: "contractStatus",
			label: t("dashboard.contractStatus"),
			order: 7,
		},
		{}, // view more
	])
	const managerTableHeaderContractList = computed(() => [
		{
			name: "staffName",
			label: t("dashboard.staffName"),
			order: 1,
		},
		{
			name: "agentCode",
			label: t("dashboard.agentServiceCode"),
			order: 2,
		},
		{
			name: "contractNo",
			label: t("dashboard.contractNo"),
			order: 3,
		},
		{
			name: "policyOwner",
			label: t("dashboard.policyOwner"),
			order: 4,
		},
		{
			name: "leadGCM",
			label: t("dashboard.leadGCM_CRM"),
			order: 5,
		},
		{
			name: "contractApplyDate",
			label: t("dashboard.contractApplyDate"),
			order: 6,
		}, // submission date
		{
			name: "issuedDate",
			label: t("dashboard.issuedDate"),
			order: 7,
		}, // issuance date
		{
			name: "mainProductAPE",
			label: t("dashboard.mainProductAPE"),
			order: 8,
		},
		{
			name: "contractStatus",
			label: t("dashboard.contractStatus"),
			order: 9,
		},
		{}, // view more
	])
	const updateAgentServiceTableHeaderList = computed(() => [
		{
			name: "contractNo",
			label: "dashboard.contractNo",
		},
		{
			name: "policyOwner",
			label: "dashboard.policyOwner",
		},
		{
			name: "servicingAgentCode",
			label: "dashboard.contractManagesByAgent",
		},
		{
			name: "agentCode",
			label: "dashboard.agentCode",
		},
		{
			name: "alterTheServicingAgent",
			label: "dashboard.alterTheServicingAgent",
		},
		{
			name: "updatedStatus",
			label: "dashboard.updatedStatus",
		},
		{
			name: "errorLogs",
			label: "dashboard.errorLogs",
		},
	])
	const agentServiceHistoryListTableHeader = computed(() => [
		{
			name: "contractNo",
			label: t("dashboard.contractNo"),
		},
		{
			name: "contractor",
			label: t("dashboard.contractor"),
		},
		{
			name: "contractManagesByAgent",
			label: t("dashboard.contractManagesByAgent"),
		},
		{
			name: "agentCodeOfAgentService",
			label: t("dashboard.agentCodeOfAgentService"),
		},
		{
			name: "alterTheServicingAgent",
			label: t("dashboard.alterTheServicingAgent"),
		},
		{
			name: "agentCodeOfAgentServiceChanged",
			label: t("dashboard.agentCodeOfAgentServiceChanged"),
		},
		{
			name: "timeUpdated",
			label: t("dashboard.timeUpdated"),
		},
		{
			name: "status",
			label: t("dashboard.status"),
		},
		{
			name: "errorLogs",
			label: t("dashboard.errorLogs"),
		},
	])
	const genderMappingValue = computed(() => [
		{ value: "F", name: t("dashboard.genderF") },
		{ value: "M", name: t("dashboard.genderM") },
		{ value: "G", name: t("dashboard.genderG") },
	])
	const getValPolicyStatusByCodeMVL = ({ status }) => {
		if (!status) return ""
		return contractStatusesOfMVL.value?.find((i) => i?.value === status)?.name
	}
	const getValPolicyStatusByCodeTCB = ({ status }) => {
		if (!status) return ""
		return contractStatusesOfTCB.value?.find((i) => i?.value === status)?.name
	}
	const mapPaymentModes = computed(() => [
		{ value: "01", name: t("dashboard.monthly") },
		{ value: "03", name: t("dashboard.quarterly") },
		{ value: "06", name: t("dashboard.semiAnnually") },
		{ value: "12", name: t("dashboard.annually") },
	])
	const freeLookIndicatorOptions = computed(() => [
		{ value: "Y", name: t("dashboard.freeLookIndicatorAnsY") },
		{ value: "N", name: t("dashboard.freeLookIndicatorAnsN") },
	])
	const mValueUserPosition = computed(() => {
		return userPosition === FRONTLINE_LEAD_TISPRM
			? [
					{
						source_contains: FRONTLINE_TIS,
					},
					{
						source_contains: FRONTLINE_PRM,
					},
			  ]
			: userPosition === FRONTLINE_LEAD_RBOSRBO
			? [
					{
						source_contains: FRONTLINE_RBO,
					},
					{
						source_contains: FRONTLINE_SRBO,
					},
					{
						source_contains: FRONTLINE_DRBO,
					},
			  ]
			: userPosition === BRANCH_MANAGER
			? [
					{
						source_contains: FRONTLINE_RBO,
					},
					{
						source_contains: FRONTLINE_SRBO,
					},
					{
						source_contains: FRONTLINE_DRBO,
					},
					{
						source_contains: FRONTLINE_TIS,
					},
					{
						source_contains: FRONTLINE_PRM,
					},
			  ]
			: []
	})
	const claimStatus = computed(() => [
		{ value: "A", name: t("dashboard.approved") },
		{ value: "D", name: t("dashboard.declined") },
		{ value: "P", name: t("dashboard.pending") },
		{ value: "H", name: t("dashboard.approvedWithPartialPayment") },
		{ value: "I", name: t("dashboard.inactive") },
	])
	const claimTypes = computed(() => [
		{ value: "1", name: t("dashboard.hospitalizationAllowance") },
		{ value: "2", name: t("dashboard.healthGift") },
		{ value: "3", name: t("dashboard.injuryBenefit") },
		{ value: "4", name: t("dashboard.deathBenefit") },
		{ value: "5", name: t("dashboard.criticalIllness") },
		{ value: "6", name: t("dashboard.freeFeeBenefit") },
		{ value: "7", name: t("dashboard.othersBenefit") },
	])
	const mapNbPendingReason = computed(() => [
		{ value: "09", name: t("dashboard.notEnoughDocuments") },
		{ value: "10", name: t("dashboard.consultToExpert") },
		{ value: "11", name: t("dashboard.alterProduct") },
		{ value: "12", name: t("dashboard.missInfor") },
		{ value: "13", name: t("dashboard.complementDocuments") },
		{ value: "14", name: t("dashboard.lackOfFee") },
		{ value: "15", name: t("dashboard.medicalExamination") },
		{ value: "16", name: t("dashboard.acceptanceLetter") },
		{ value: "17", name: t("dashboard.waitingDocumentsFromAnotherContract") },
		{ value: "18", name: t("dashboard.underwriting") },
		{ value: "19", name: t("dashboard.RCCWaiting") },
	])

	const remindersContractPaymentDueTableHeader = computed(() => [
		{
			name: "contractNo",
			label: t("notification.policyNo"),
		},
		{
			name: "contractor",
			label: t("notification.contractor"),
		},
		{
			name: "paidToDate",
			label: t("notification.paidToDate"),
		},
		{
			name: "paidAmount",
			label: t("notification.paidAmount"),
		},
	])

	const reminderUserDOBTableHeader = computed(() => [
		{
			name: "userFullName",
			label: t("notification.userFullName"),
		},
		{
			name: "userDOB",
			label: t("notification.userDOB"),
		},
		{
			name: "phoneNo",
			label: t("notification.phoneNo"),
		},
		{
			name: "userIdentify",
			label: t("notification.userIdentify"),
		},
		{
			name: "address",
			label: t("notification.address"),
		},
	])

	const contractsTableHeader = computed(() => {
		if (isLocationManagers.value) return managerTableHeaderContractList.value
		else return frontLineTableHeaderContractList.value
	})

	const searchTypeOptions = computed(() => {
		return [
			{ name: t("dashboard.searchByBranch"), value: SEARCH_TYPES.BRANCH, disabled: isLeadDepartment.value },
			{ name: t("dashboard.searchByAgent"), value: SEARCH_TYPES.AGENT, disabled: false },
		]
	})

	function mapValueList({ list, key }) {
		return (list || [])?.map((item) => item?.[key])
	}
	function mapAgentCodeList({ list }) {
		if (!list.length) return []
		return list?.map((i) => i?.fields && JSON.parse(i?.fields || {})?.agentCode)?.filter(Boolean)
	}
	function mapValueAgentCodeSelected({ agentCode }) {
		return [splitAgentCodeValue(agentCode)?.code]
	}

	return {
		yearMonthDayFormat,
		dayMonthYearFormat,
		userRole,
		userLogin,
		agentCodeOfUserLogin,
		userArea,
		userRegion,
		userBranch,
		userPosition,
		isManagerPosition,
		isLocationManagers,
		isBranchManager,
		isExecutiveRole,
		isRegionManager,
		isAreaManager,
		isLeadDepartment,
		isHoManager,
		isFrontline,
		isInsuranceExpert,
		isFrontlineAndExpertDept,
		setDefaultUserLoggedInRole,
		isSupervisorDept,
		userLocatedByBranchOrArea,
		// Methods
		getValPolicyStatusByCodeMVL,
		getValPolicyStatusByCodeTCB,

		// Options
		quickOptionsDatePicker,
		dateTypeOptions,
		contractStatusesOfMVL,
		managerTableHeaderContractList,
		frontLineTableHeaderContractList,
		updateAgentServiceTableHeaderList,
		agentServiceHistoryListTableHeader,
		agentCodeUpdatedStatusOptions,
		customDateTypeOptions,
		genderMappingValue,
		mapPaymentModes,
		freeLookIndicatorOptions,
		mValueUserPosition,
		contractStatusesOfTCB,
		claimStatus,
		claimTypes,
		mapNbPendingReason,
		remindersContractPaymentDueTableHeader,
		reminderUserDOBTableHeader,
		defaultStaffRoles,
		mapValueList,
		mapAgentCodeList,
		mapValueAgentCodeSelected,
		contractsTableHeader,
		SEARCH_TYPES,
		searchTypeOptions,
	}
}
