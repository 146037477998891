/* eslint-disable camelcase */
import { byKey } from "../helpers"

function mapBenefitDataOptions({ list = [] }) {
	if (!list.length) return []
	const values = list?.map((sb) => {
		const isCheckedMainBenefit = sb?.value?.objectValue?.find(byKey("isCheckedMainBenefit"))?.value?.booleanValue
		const amountProtection = sb?.value?.objectValue?.find(byKey("amountProtection"))?.value?.numberValue
		const year = sb?.value?.objectValue?.find(byKey("year"))?.value?.numberValue
		const APEYear = sb?.value?.objectValue?.find(byKey("APEYear"))?.value?.numberValue
		const APEIncome = sb?.value?.objectValue?.find(byKey("APEIncome"))?.value?.numberValue
		const otherValue = sb?.value?.objectValue?.find(byKey("otherValue"))?.value?.stringValue
		const list = {
			benefitName: sb?.key,
			isCheckedMainBenefit,
			year,
			amountProtection,
			APEYear,
			APEIncome,
			isLoadingAPE: false,
			otherValue,
		}
		const riders = sb?.value?.objectValue?.find(byKey("riders"))?.value?.objectValue
		if (riders?.length > 0) {
			list.benefitOptions = riders?.map((r) => {
				const rider = r?.value?.objectValue
				const amountProtection = rider?.find(byKey("amountProtection"))?.value?.numberValue
				return {
					riderName: r?.key,
					isCheckedRider: rider?.find(byKey("isCheckedRider"))?.value?.booleanValue,
					amountProtection,
					APEYear: rider?.find(byKey("APEYear"))?.value?.numberValue,
					APEIncome: rider?.find(byKey("APEIncome"))?.value?.numberValue,
					isLoadingAPE: false,
				}
			})
		}

		return list
	})
	return values
}
export default function getSelectedProductFromFacts({ selectedProductFact, facts }) {
	// base product
	const productName = selectedProductFact?.find(byKey("name"))?.value?.stringValue // product name
	const paymentTerm = selectedProductFact?.find(byKey("productPeriodicPayment"))?.value?.numberValue
	const sa = selectedProductFact?.find(byKey("sa"))?.value?.numberValue
	const totalAPEIncome = selectedProductFact?.find(byKey("totalAPEIncome"))?.value?.numberValue
	const totalAPEYear = selectedProductFact?.find(byKey("totalAPEYear"))?.value?.numberValue
	const investmentPreference = selectedProductFact?.find(byKey("investmentProfile"))?.value?.stringValue
	const financialAffordability = selectedProductFact?.find(byKey("financialAffordability"))?.value?.numberValue
	const financialAffordabilityAmount = selectedProductFact?.find(byKey("financialAffordabilityAmount"))?.value?.numberValue
	const estimatedPremiumYear = selectedProductFact?.find(byKey("estimatedPremiumYear"))?.value?.numberValue
	// product benefits and riders

	// lifeProtection
	const lifeProtectionFromFacts = selectedProductFact?.find(byKey("lifeProtection"))?.value?.objectValue
	const mLifeProtectionOptionsFacts = mapBenefitDataOptions({ list: lifeProtectionFromFacts })

	// accident
	const accidentProtectionFromFacts = selectedProductFact?.find(byKey("accidentProtection"))?.value?.objectValue
	const mAccidentProtectionFromFacts = mapBenefitDataOptions({ list: accidentProtectionFromFacts })
	// illness
	const illnessProtectionFromFacts = selectedProductFact?.find(byKey("illnessProtection"))?.value?.objectValue
	const mIllnessProtectionFromFacts = mapBenefitDataOptions({ list: illnessProtectionFromFacts })
	// health care
	const healthcareProtectionFromFacts = selectedProductFact?.find(byKey("healthcareProtection"))?.value?.objectValue
	const mHealthcareProtectionFromFacts = mapBenefitDataOptions({ list: healthcareProtectionFromFacts })
	// medical care
	const medicalCareFromFacts = selectedProductFact?.find(byKey("medicalCare"))?.value?.objectValue
	const mMedicalCareFromFacts = mapBenefitDataOptions({ list: medicalCareFromFacts })
	// cancer protection
	const cancerProtectionFromFacts = selectedProductFact?.find(byKey("lateStageCancer"))?.value?.objectValue
	const mCancerProtectionFromFacts = mapBenefitDataOptions({ list: cancerProtectionFromFacts })

	return {
		productName,
		paymentTerm,
		investmentPreference,
		estimatedPremiumYear,
		financialAffordability,
		financialAffordabilityAmount,
		totalBenefit: {
			amountProtection: sa,
			APEYear: totalAPEYear,
			APEIncome: totalAPEIncome,
		},
		benefitsData: [
			{
				name: "lifeProtection", // BASE PROTECTION
				title: "rbo.lifeProtection",
				isCollapse: false,
				dataOptions: [...mLifeProtectionOptionsFacts],
			},
			{
				name: "accidentProtection", // ADD rider
				title: "rbo.accidentProtection",
				isCollapse: false, // always false toggle
				dataOptions: [...mAccidentProtectionFromFacts],
			},
			{
				name: "illnessProtection", // CI rider
				title: "rbo.illnessProtection",
				isCollapse: false, // always false toggle
				dataOptions: [...mIllnessProtectionFromFacts],
			},
			{
				name: "healthcareProtection", // HC rider
				title: "rbo.medicalCostProtection", // medical cash
				isCollapse: false,
				dataOptions: [...mHealthcareProtectionFromFacts],
			},
			{
				name: "medicalCare", // MC
				title: "rbo.medicalAllowanceProtection",
				isCollapse: false,
				dataOptions: [...mMedicalCareFromFacts],
			},
			{
				name: "benefitCancerProtection", // cancer benefit
				title: "rbo.benefitCancerProtection",
				isCollapse: false, // always false toggle
				dataOptions: [...mCancerProtectionFromFacts],
			},
		],
	}
}
