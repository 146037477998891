<template>
	<JBox v-click-outside="hideShowMore" class="relative w-10 h-10" @click="handleClickMore()">
		<JButton variant="transparent-rounded" class="h-full">
			<JIcon icon="DotsHorizontal" />
		</JButton>
		<JBox v-if="isShowMore" class="absolute z-10 right-0 py-2 bg-cWhite rounded-lg shadow" style="top: 2rem">
			<slot></slot>
		</JBox>
	</JBox>
</template>

<script>
export default {
	name: "CoreTableAction",

	data() {
		return {
			isShowMore: false,
		}
	},
	methods: {
		handleClickMore() {
			this.isShowMore = !this.isShowMore
		},
		hideShowMore() {
			this.isShowMore = false
		},
	},
}
</script>
