<template>
	<JBox>
		<JFlex variant="basic">
			<JLabel class="font-bold">{{ label }}</JLabel>
			<JText class="text-cError-500 cursor-pointer select-none focus:outline-none" @click="clearDate">
				{{ clearLabel }}
			</JText>
		</JFlex>
		<!-- Date Picker Group -->
		<JFlex class="-mx-2 flex-wrap">
			<JBox class="w-full sm:w-4/12 px-2" :style="customStyleDateType">
				<CFormInput
					:variant="variant"
					:modelValue="modelValue.dateType"
					:validator="validator"
					field="dateType"
					componentName="JSelect"
					:allowSelectNothing="customAllowSelectNoValue"
					:options="dateTypeOptions"
					:placeholder="customPlaceholderDateType"
					@update:modelValue="handleChangeDateType($event)"
				/>
			</JBox>
			<JBox class="w-full sm:w-8/12 px-2" :style="customStyleDatePicker">
				<CFormInput
					:variant="variant"
					:modelValue="modelValue.value"
					:disabled="disabledDatePicker"
					:validator="validator"
					field="value"
					componentName="JDatePicker"
					:mode="modelValue.type === 'between' ? 'range' : 'single'"
					:placeholder="customPlaceHolderDate"
					iconSuffix="Calendar"
					:minDate="minDate"
					:maxDate="customMaxDate"
					:years="years"
					@update:modelValue="handleInputDatePicker($event)"
				/>
			</JBox>
			<slot></slot>
		</JFlex>
		<!-- Quick options -->
		<JFlex class="flex-wrap mt-2">
			<JBox v-for="item in quickOptions" :key="item.value" :class="getQuickOptionClass(item)" @click="quickSet(item.value)">
				<JText>{{ item.label }}</JText>
			</JBox>
		</JFlex>
	</JBox>
</template>
<!-- eslint-disable no-tabs -->
<script>
import dayjs from "dayjs"
import { computed } from "vue"
import { formatData } from "@/modules/core/composables"
export default {
	name: "RangeDatePicker",
	emits: ["update:modelValue"],
	props: {
		/**
		 * Date picker value
		 * - type // between - before - after
		 * - value
		 * - quickOptionValue
		 */
		modelValue: {
			type: Object,
			default: () => {},
		},
		label: {
			type: String,
			default: "",
		},
		clearLabel: {
			type: String,
			default: "Clear",
		},
		dateTypeOptions: {
			type: Array,
			default: () => [],
		},
		quickOptions: {
			type: Array,
			default: () => [],
		},
		variant: {
			type: String,
			default: "primary",
		},
		customPlaceHolderDate: {
			type: String,
			default: "",
		},
		customPlaceholderDateType: {
			type: String,
			default: "",
		},
		customAllowSelectNoValue: {
			type: Boolean,
			default: false,
		},
		customStyleDateType: {
			type: String,
			default: "",
		},
		customStyleDatePicker: {
			type: String,
			default: "",
		},
		validator: {
			type: Object,
			default: () => {},
		},
		disabledDatePicker: {
			type: Boolean,
			default: false,
		},
		formatValueDate: {
			type: String,
			default: "",
		},
		customMaxDate: {
			type: [Object, String],
			default: dayjs(),
		},
	},
	setup(props, { emit }) {
		const minDate = computed(() => {
			return dayjs().subtract(65, "year")
		})
		const years = [
			...[...Array(20)].map((_, i) => new Date().getFullYear() + i).reverse(),
			...[...Array(42)].map((_, i) => new Date().getFullYear() - i),
		]

		// LOGICS
		const clearDate = () => {
			emit("update:modelValue", {
				dateType: "",
				type: "between",
				value: [],
				quickOptionValue: "",
			})
		}
		const handleChangeDateType = (val) => {
			emit("update:modelValue", {
				dateType: val === props.customPlaceholderDateType ? "" : val,
				value: val === props.customPlaceholderDateType ? [] : props.modelValue?.value,
				type: "between",
				quickOptionValue: "",
			})
		}
		const handleInputDatePicker = (value) => {
			emit("update:modelValue", {
				dateType: props.modelValue?.dateType,
				type: props.modelValue?.type,
				value,
				quickOptionValue: "",
			})
		}
		const getQuickOptionClass = (item) => {
			return [
				"text-sm cursor-pointer px-3 py-2 rounded-full select-none",
				item.value === props.modelValue?.quickOptionValue ? "bg-c1-500 text-cWhite" : "bg-cWhite text-cBlack",
				props.disabledDatePicker ? "cursor-not-allowed" : "cursor-auto",
			]
		}
		const quickSet = (quickOptionValue) => {
			if (props.disabledDatePicker) return
			const computedValue = {
				dateType: props.modelValue?.dateType,
				type: "between",
				value: null,
				quickOptionValue,
			}
			const today = dayjs().set("hour", 0).set("minute", 0).set("second", 0)

			if (quickOptionValue === "today") {
				computedValue.value = [today, today]
			} else if (quickOptionValue === "lastWeek") {
				computedValue.value = [today.add(-6, "day"), today]
			} else if (quickOptionValue === "lastMonth") {
				computedValue.value = [today.add(-1, "month"), today]
			} else if (quickOptionValue === "last3Months") {
				// it is return 93 days so have to subtract 1 day
				computedValue.value = [today.add(-3, "month").subtract(-1, "day"), today]
			}
			if (props?.formatValueDate) {
				computedValue.value[0] = formatData(computedValue.value[0], props?.formatValueDate)
				computedValue.value[1] = formatData(computedValue.value[1], props?.formatValueDate)
			}
			emit("update:modelValue", computedValue)
		}

		return {
			minDate,
			handleInputDatePicker,
			getQuickOptionClass,
			quickSet,
			handleChangeDateType,
			clearDate,
			years,
		}
	},
}
</script>
