export default {
	resumeFlag: null,
	initialActiveStep: "customerDetails",
	initialCompletedSteps: [],
	caseData: null,
	prices: {},
	defaultSA: null,
	overwriteProductOptions: null,
	selectedProduct: null,
	customizingProduct: null,
	customerDetails: {
		gender: null,
		age: null,
		numberOfDependents: 0,
	},
	customerInfo: {
		uid: "",
		relatedAccountId: "",
		leadId: "",
		branchCode: "",
		fullName: "",
		citizenIdType: null, // 1: citizen id || 4: passport
		citizenId: "",
		phoneNumber: "",
		email: "",
		gender: "",
		dateOfBirth: "",
		maritalStatus: "",
		spouseName: "",
		spouseAge: null,
		customerId: "",
		dependentList: [],
		meetingMethod: "",
		staffId: "",
		bankStaffName: "",
		idIssuedDate: null,
		idIssuedPlace: "",
		occupation: "",
		address: {
			street: "",
			district: "",
			ward: "",
			city: "",
			normalizedAddress: "",
		},
		smokingHabit: "",
		isAgentPO: false,
	},
	ratesFactId: null,
	answersFactId: null,
	dropOffReason: null,
	otherDropOffReason: null,
	pauseReason: null,
	conversationResult: null,
	tisAgentCode: null,
	leadGCM: null,
	gcmLeadIdFromRoute: null,
	referLeadFna: null,
	recentLeadApiStatus: null,
	planCode: null,
	mvpVersion: null,
	completedDate: null,
	rates: {
		interest: 5,
		inflation: 5,
		educationInflation: 4,
	},
	productCode: null,
	answers: {},
	isResumeRBO: false,
	titanPdf: null,
	fnaPdf: null,
	isAnswerDefault: false,
	rboBackDestination: null,
	ePosLinkCustomerDB: "",
	baseProductId: null, // logic at June 2022 when fetching the baseProduct for a specific product
	baseProductList: [],
	flowIdToResume: "",
	GCMLeadOptions: [],
	existenceUserInfo: {
		citizenId: false, // only need to check idNumber (if user selects idType -> also require to fill idNumber)
		phoneNumber: false,
	},
	directReferralCode: "",
	refererBranchCode: "",
	customerAccountsBySearching: null,
	annualPremium: null,
}
