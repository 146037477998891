export const VERSION = {
	version_1: "1",
	version_2: "2",
	version_3: "3",
	version_4: "4",
	version_5: "5",
	version_6: "6",
	version_7: "7",
	version_8: "8",
}

export const RIDER_OPTIONS = { option_0: "option_0", option_1: "option_1", option_2: "option_2" }

export const CONVERSATION_REFER_TYPES = {
	REFER_TIS: "referToTIS",
	REFER_AWS: "agreeToApply",
}

export const CONVERSATION_RESULT_KEYS = {
	REFER_TIS: "referToTIS",
	REFER_AWS: "agreeToApply",
	CUSTOMER_CONSIDER: "onConsider",
	CUSTOMER_NO_DEMAND: "noDemand",
	CUSTOMER_NOT_INTEREST: "notInterested",
}
export const CUSTOMER_CITIZEN_ID = {
	"01": "poCitizenId",
	"02": "cusId", // maybe key does not exist
	"03": "poPhoneNumber",
}

export const CRM_TYPES = {
	"01": "Lead",
	"02": "Opportunity",
}

export const CRM_KEY_TYPES = {
	LEAD: "01",
	OPP: "02",
}

export const NEW_CUSTOMER_UID = "newCustomer"
export const RELATED_ACCOUNT_UID = "related_account"
export const LEAD_OPP_CUSTOMER_UID = "lead_opp_customer"

export const MARITAL_STATUS_OPTIONS_MAPPING = {
	single: "1",
	married: "2",
	divorced: "3",
	widowed: "4",
}
/**
 * PRODUCT information
 */

export const PRODUCT_NAME = {
	ul048: "ul048", // An Tâm Vững Bước
	titan_6years: "titan_6years",
	titan_10years: "titan_10years",
	ul038: "ul038", // UL2019 - Saving
	ul040: "ul040", // UL2019 - Protect
	edu: "edu",
	titanium: "titanium", // is titan_6years and titan_10years -> special case of using titan_1
	titanium_2: "titanium_2", // Legacy
}

export const PRODUCT_TYPES = {
	[PRODUCT_NAME.titanium_2]: "medical",
	[PRODUCT_NAME.ul038]: "universal_life", // UL2019 - Saving
	[PRODUCT_NAME.ul040]: "universal_life", // UL2019 - Protect
	[PRODUCT_NAME.ul048]: "universal_life",
	[PRODUCT_NAME.titan_6years]: "medical",
	[PRODUCT_NAME.titan_10years]: "medical",
	[PRODUCT_NAME.titanium]: "medical", // is titan_6years and titan_10years: but we use it for a special case on RBO flow
}

export const PRODUCT_PLANS = {
	[PRODUCT_NAME.titanium_2]: "titanium_2",
	[PRODUCT_NAME.ul038]: "ul038", // UL2019 - Saving
	[PRODUCT_NAME.ul040]: "ul040", // UL2019 - Protect
	[PRODUCT_NAME.ul048]: "ul048",
	[PRODUCT_NAME.titan_6years]: "titanium",
	[PRODUCT_NAME.titan_10years]: "titanium",
	[PRODUCT_NAME.titanium]: "titanium", // is titan_6years and titan_10years: but we use it for a special case on RBO flow
}

export const PRODUCT_CODE = {
	[PRODUCT_NAME.titanium_2]: "1030030030200", // legacy
	[PRODUCT_NAME.edu]: "PFS53",
	[PRODUCT_NAME.ul038]: "1030030030014",
	[PRODUCT_NAME.ul040]: "1030030030014", // TEC-1684: apply the same as UL038
	[PRODUCT_NAME.ul048]: "1030030030229", // titanium 1.1 : An Tam Vung Buoc
	[PRODUCT_NAME.titan_6years]: "1030030030009", // titan_1
	[PRODUCT_NAME.titan_10years]: "1030030030009", // titan_1
}

export const PRODUCT_ORDER = {
	[PRODUCT_NAME.ul038]: 1,
	[PRODUCT_NAME.ul040]: 2,
	[PRODUCT_NAME.titanium_2]: 3,
	[PRODUCT_NAME.ul048]: 4,
	[PRODUCT_NAME.titan_10years]: 5,
	[PRODUCT_NAME.titan_6years]: 6,
}
export const DRBO_PRODUCT_ORDER = {
	[PRODUCT_NAME.ul048]: 1,
	[PRODUCT_NAME.titan_10years]: 2,
	[PRODUCT_NAME.titan_6years]: 3,
	[PRODUCT_NAME.ul038]: 4,
	[PRODUCT_NAME.ul040]: 5,
}

/**
 * Product plan code is defined by TCB
 */
export const TITAN_2_PLAN_CODE = "RUV07" // Titan 2.0 (ILP2021)

export const UL038_PLAN_CODE = "UL038"

export const UL040_PLAN_CODE = "UL040"

export const UL048_PLAN_CODE = "UL048"

export const TITAN_6_YEARS_PLAN_CODE = "UL046"

export const TITAN_10_YEARS_PLAN_CODE = "UL047"

// Mapping titan_1 plan code by payment term
export const TITAN_1_PLAN_CODE_BY_PAYMENT_TERM = {
	6: TITAN_6_YEARS_PLAN_CODE,
	10: TITAN_10_YEARS_PLAN_CODE,
}

// CAN06-late stage cancer on product (can be a benefit or rider on product)
export const CAN06_ON_PRODUCTS = [PRODUCT_NAME.ul038, PRODUCT_NAME.ul040, PRODUCT_NAME.ul048, PRODUCT_NAME.titanium_2]
export const CAN06_IS_RIDER_ON_PRODUCTS = [PRODUCT_NAME.ul038, PRODUCT_NAME.ul048, PRODUCT_NAME.titanium_2]

// Validate products with investmentPreference (at step 6 of FNA-Goal based and RBO-income & Expense flow)
export const INVESTMENT_PREFERENCE_KEYS = {
	high: "High",
	low: "Low",
}

export const LOW_PRODUCTS_INVESTMENT_PREFERENCE = [
	PRODUCT_NAME.ul038,
	PRODUCT_NAME.ul040,
	PRODUCT_NAME.titan_6years,
	PRODUCT_NAME.titan_10years,
	PRODUCT_NAME.ul048,
]
export const HIGH_PRODUCTS_INVESTMENT_PREFERENCE = [PRODUCT_NAME.titanium_2]

// Financial Affordability rate - percent rate
export const FINANCIAL_AFFORDABILITY_RATE = {
	min: 1,
	max: 30,
}

export const GOAL_NAMES_MAPPING_BY_BENEFIT_NAMES = {
	savings: "savings", // explainKey: " Grow asset/ Investment/ Gia tăng tài sản",
	lifeProtection: "lifeProtection", // explainKey: "Finance protection/Life Protection/ Bảo vệ tài chính",
	education: "education", // explainKey: "Education/ Giáo dục",
	retirement: "retirement", // explainKey: "Retirement/ Hưu trí an nhàn",
	healthProtection: "healthProtection", // explainKey: Health care/Health Protection/ Chăm sóc sức khỏe
}

export const GOAL_KEYS_EPOS = [
	GOAL_NAMES_MAPPING_BY_BENEFIT_NAMES.education,
	GOAL_NAMES_MAPPING_BY_BENEFIT_NAMES.retirement,
	GOAL_NAMES_MAPPING_BY_BENEFIT_NAMES.savings,
	GOAL_NAMES_MAPPING_BY_BENEFIT_NAMES.healthProtection,
	GOAL_NAMES_MAPPING_BY_BENEFIT_NAMES.lifeProtection,
]

export const DIRECT_REFERRAL_FULL_NAME_LIMIT_LENGTH = 100
export const DIRECT_REFERRAL_AGENT_CODE_LIMIT_LENGTH = 20
export const DIRECT_REFERRAL_MAX_LENGTH = 5

export const CASE_STATUSES = {
	COMPLETED: "COMPLETED",
	CANCELED: "CANCELED",
}

export const STEP_IDS = ["customerDetails", "goals", "questions", "having", "summary", "selectProduct", "finish"]

export const GROW_ASSET_TARGET_AMOUNT_MAX_VALUE = 50000000000 // 50 bil
export const PRODUCT_GOAL_TARGET_AMOUNT_MAX_VALUE = 100000000000 // 100 bil
