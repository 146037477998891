import {
	get,
	byKey,
	byType,
	getChildrenEducationFact,
	getGrowWealthAndAssetsFact,
	getFinanceUncertaintyFact,
	getHealthAndMedicalFact,
	getRetirementFact,
	getHavingFact,
	roundSA,
	roundSAByRange,
	getTargetEducationFact,
	getTargetGrowWealthAndAssetsFact,
	getTargetHealthAndMedicalFact,
	getTargetRetirementFact,
	getTargetFinanceFact,
	getAnswersFromFacts,
} from "@/modules/fna/helpers"

import {
	round,
	getSumChildrenEducation,
	getSumGrowWealthAndAssets,
	getSumFinanceUncertainty,
	getSumHealthAndMedical,
	getSumRetirement,
	getSumChildrenEducationDetail,
	getSumGrowWealthAndAssetsDetail,
	getSumHealthAndMedicalDetail,
	getOriginalSumChildrenEducation,
	getOriginalSumGrowWealthAndAssets,
	getOriginalSumFinanceUncertainty,
	getOriginalSumRetirement,
	getSumHealthcare,
} from "../calculation"
import { getRecommendedProducts } from "../productRecommendation"
import { benefitsCalculate, getADD, getMedicalSupport, getCI } from "../benefitsCalculation"
import { PERIODIC_PAYMENT_TERM, TITAN_PAYMENT_TERM } from "@/modules/fna/utils/constants"
import { PRODUCT_NAME, CAN06_ON_PRODUCTS } from "@/modules/customerSupport/constants"
import { mapOccupationClass } from "@/modules/customerSupport/utils"
import occupations from "@/mock/occupationsList.json"

export default {
	caseId: (state, getters) => {
		return state?.caseData?.id
	},
	caseStatus: (state) => {
		return state?.caseData?.status
	},
	caseData: (state, getters) => {
		return state?.caseData
	},
	individualId: (state, getters) => {
		return state?.caseData?.holder?.id
	},
	ratesFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("rates"))?.id
	},
	ratesFromFact: (state, getters, rootState) => {
		const rates = rootState?.appConfig
		const interest = rates?.interest || 5
		const inflation = rates?.inflation || 5
		const caseRatesFact = state?.caseData?.facts?.find(byType("rates"))?.value?.objectValue
		return caseRatesFact?.reduce(
			(acc, fact) => {
				acc[fact.key] = fact.value.numberValue
				return acc
			},
			{ interest, inflation, educationInflation: 4 }
		)
	},
	ratesFact: (state, getters) => {
		const { interest, inflation, educationInflation } = state.rates
		return {
			type: "rates",
			value: {
				objectValue: [
					{
						key: "inflation",
						value: { numberValue: inflation },
					},
					{
						key: "interest",
						value: { numberValue: interest },
					},
					{
						key: "educationInflation",
						value: { numberValue: educationInflation },
					},
				],
			},
		}
	},
	conversationResultFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("conversationResult"))?.id
	},
	conversationResultFact: (state, getters) => {
		return {
			type: "conversationResult",
			value: { stringValue: getters.conversationResult },
		}
	},
	conversationResultFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("conversationResult"))?.value?.stringValue
	},
	staffIdLeadFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("staffIdLead"))?.value?.stringValue
	},

	createdDateLeadFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("createdDateLead"))?.value?.stringValue
	},
	bankStaffNameFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("bankStaffName"))?.value?.stringValue
	},

	updatedDateLeadFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("updatedDateLead"))?.value?.stringValue
	},

	referDateLeadFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("referDateLead"))?.value?.stringValue
	},

	tisAgentCodeFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("tisAgentCode"))?.id
	},
	tisAgentCodeFact: (state, getters) => {
		return {
			type: "tisAgentCode",
			value: { stringValue: getters.tisAgentCode },
		}
	},
	tisAgentCodeFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("tisAgentCode"))?.value?.stringValue
	},
	dropOffReasonFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("dropOffReason"))?.id
	},
	dropOffReasonFact: (state, getters) => {
		return {
			type: "dropOffReason",
			value: { stringValue: getters.dropOffReason },
		}
	},
	productCodeFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("productCode"))?.id
	},
	productCodeFact: (state, getters) => {
		return {
			type: "productCode",
			value: { stringValue: getters.productCode },
		}
	},
	dropOffReasonFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("dropOffReason"))?.value?.stringValue
	},
	otherDropOffReasonFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("otherDropOffReason"))?.id
	},
	otherDropOffReasonFact: (state, getters) => {
		return {
			type: "otherDropOffReason",
			value: { stringValue: getters.otherDropOffReason },
		}
	},
	otherDropOffReasonFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("otherDropOffReason"))?.value?.stringValue
	},
	leadGCMFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("leadGCM"))?.id
	},
	leadGCMFact: (state, getters) => {
		return {
			type: "leadGCM",
			value: { stringValue: getters.leadGCM },
		}
	},
	leadGCMFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("leadGCM"))?.value?.stringValue
	},
	referLeadFnaFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("referLeadFna"))?.id
	},
	referLeadFnaFact: (state, getters) => {
		return {
			type: "referLeadFna",
			value: { stringValue: getters.referLeadFna },
		}
	},
	referLeadFnaFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("referLeadFna"))?.value?.stringValue
	},
	planCodeFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("planCode"))?.id
	},
	planCodeFact: (state, getters) => {
		return {
			type: "planCode",
			value: { stringValue: getters.planCode },
		}
	},
	planCodeFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("planCode"))?.value?.stringValue
	},
	mvpVersionFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("mvpVersion"))?.id
	},
	mvpVersionFact: (state, getters) => {
		return {
			type: "mvpVersion",
			value: { stringValue: getters.mvpVersion },
		}
	},
	mvpVersionFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("mvpVersion"))?.value?.stringValue
	},
	completedDateFactId: (state, getters) => {
		return state?.caseData?.facts?.find(byType("completedDate"))?.id
	},
	completedDateFact: (state, getters) => {
		return {
			type: "completedDate",
			value: { dateValue: new Date() },
		}
	},
	completedDateFromFact: (state, getters) => {
		return state?.caseData?.facts?.find(byType("completedDate"))?.value?.dateValue
	},
	allQuestionsAnswered: (state, getters) => {
		const goals = getters.goalsFromFact
		if (goals.length === 0) return false
		const answers = getters.answersFromFact
		let unAnsweredQuestion = null
		goals.forEach((goal) => {
			const notCompletedAnswer = (obj) => !obj || Object.keys(obj).find((key) => obj[key] === null || obj[key] === undefined)
			if (goal === "childrenEducation") {
				if (
					!answers?.childrenEducation ||
					answers?.childrenEducation?.length === 0 ||
					answers?.childrenEducation?.find(notCompletedAnswer)
				) {
					unAnsweredQuestion = true
				}
			} else if (goal === "growWealthAndAssets") {
				const { growWealth: growWealthAnswers } = answers.growWealthAndAssets
				unAnsweredQuestion = growWealthAnswers && notCompletedAnswer(growWealthAnswers)
			} else if (goal === "financeUncertainty") {
				const financeUncertaintyAnswers = answers.financeUncertainty
				if (notCompletedAnswer(financeUncertaintyAnswers)) {
					unAnsweredQuestion = true
				}
			} else if (goal === "healthAndMedical") {
				const healthAndMedicalAnswers = answers.healthAndMedical
				const { interestedBenefits = [] } = answers.healthAndMedical
				const medicalCareQuestionNotCompleted =
					interestedBenefits.includes("medicalCare") && !healthAndMedicalAnswers.hospitalisedRoomAndBoard
				// roomAndBoardExpense will be based on inpatient
				const healthCareQuestionNotCompleted =
					interestedBenefits.includes("healthCare") && !healthAndMedicalAnswers.roomAndBoardExpense?.inpatient
				const accidentAndDisabilityQuestionNotCompleted =
					interestedBenefits.includes("accidentAndDisability") && !healthAndMedicalAnswers.accidentAndDisabilityCoverAmount
				const CIQuestionNotCompleted = interestedBenefits.includes("CI") && !healthAndMedicalAnswers.critticalIllnessCoverAmount
				if (
					medicalCareQuestionNotCompleted ||
					healthCareQuestionNotCompleted ||
					accidentAndDisabilityQuestionNotCompleted ||
					CIQuestionNotCompleted ||
					interestedBenefits.length === 0
				) {
					unAnsweredQuestion = true
				}
			} else if (goal === "retirement") {
				const retirementAnswers = answers.retirement
				if (notCompletedAnswer(retirementAnswers)) {
					unAnsweredQuestion = true
				}
			}
		})
		return !unAnsweredQuestion
	},
	completedHavingQuestions: (state, getters) => {
		const atLeast1Answer = (obj) =>
			!obj || Object.keys(obj).find((key) => obj[key] && key !== "householdIncomeDetails" && key !== "householdExpenseDetails")
		return atLeast1Answer(getters?.answersFromFact?.having)
	},
	initialCompletedSteps: (state, getters) => {
		const ratesSaved = state?.caseData?.facts?.find(byType("rates"))?.value?.objectValue?.length > 0
		const completedSteps = []
		if (getters?.customerDetails?.gender) {
			completedSteps.push("customerDetails")
		}
		if (getters?.goalsFromFact?.length) {
			completedSteps.push("goals")
		}
		if (getters.allQuestionsAnswered || ratesSaved || getters.selectedProduct) {
			completedSteps.push("questions")
			completedSteps.push("having")
		}
		if (ratesSaved) {
			completedSteps.push("summary")
		}
		if (getters.selectedProduct) {
			completedSteps.push("selectProduct")
		}
		return completedSteps
	},
	initialStep: (state, getters) => {
		let result = "customerDetails"
		const questionsCompleted = getters.allQuestionsAnswered
		const goalsCompleted = getters?.goalsFromFact?.length
		const ratesSaved = state?.caseData?.facts?.find(byType("rates"))?.value?.objectValue?.length > 0
		const customerDetailsCompleted = getters?.customerDetails?.gender

		if (customerDetailsCompleted) {
			result = "goals"
		}
		if (goalsCompleted) {
			result = "questions"
		}
		if (questionsCompleted) {
			result = "having"
		}
		if (getters.completedHavingQuestions) {
			result = "summary"
		}
		if (ratesSaved) {
			result = "selectProduct"
		}
		if (getters.selectedProduct) {
			result = "finish"
		}
		return result
	},
	customerDetails: (state, getters) => {
		const holder = state?.caseData?.holder
		return {
			gender: holder?.gender || state.customerDetails.gender,
			age: holder?.facts?.find(byType("age"))?.value?.numberValue || state.customerDetails.age,
			numberOfDependents:
				holder?.facts?.find(byType("numberOfDependents"))?.value?.numberValue || state.customerDetails.numberOfDependents,
		}
	},
	customerInfo: (state, getters) => {
		const holder = state?.caseData?.holder
		const facts = state?.caseData?.facts
		const dependentListFromFactValue = holder?.facts?.find(byType("dependentList"))?.value?.arrayValue
		const dependentList = dependentListFromFactValue?.map((item) => {
			const value = item.objectValue
			return {
				name: get(value, "name", "stringValue"),
				age: get(value, "age", "numberValue"),
			}
		})
		const addressFromFactValue = holder?.facts?.find(byType("address"))?.value?.objectValue
		const mapAddress = {
			street: get(addressFromFactValue, "street", "stringValue"),
			city: get(addressFromFactValue, "city", "stringValue"),
			district: get(addressFromFactValue, "district", "stringValue"),
			ward: get(addressFromFactValue, "ward", "stringValue"),
			normalizedAddress: get(addressFromFactValue, "normalizedAddress", "stringValue"),
		}
		const occupationFromFact = holder?.facts?.find(byType("occupation"))?.value?.stringValue
		return {
			fullName: holder?.facts?.find(byType("fullName"))?.value?.stringValue || state.customerInfo.fullName,
			citizenIdType: holder?.facts?.find(byType("citizenIdType"))?.value?.stringValue || state.customerInfo.citizenIdType,
			citizenId: holder?.facts?.find(byType("citizenId"))?.value?.stringValue || state.customerInfo.citizenId,
			phoneNumber: holder?.facts?.find(byType("phoneNumber"))?.value?.stringValue || state.customerInfo.phoneNumber,
			email: holder?.facts?.find(byType("email"))?.value?.stringValue || state.customerInfo.email,
			gender: holder?.facts?.find(byType("gender"))?.value?.stringValue || state.customerInfo.gender,
			dateOfBirth: holder?.facts?.find(byType("dateOfBirth"))?.value?.stringValue || state.customerInfo.dateOfBirth,
			maritalStatus: holder?.facts?.find(byType("maritalStatus"))?.value?.stringValue || state.customerInfo.maritalStatus,
			spouseName: holder?.facts?.find(byType("spouseName"))?.value?.stringValue || state.customerInfo.spouseName,
			spouseAge: holder?.facts?.find(byType("spouseAge"))?.value?.numberValue || state.customerInfo.spouseAge,
			dependentList: (dependentList?.length && dependentList) || state.customerInfo.dependentList,
			staffId: holder?.facts?.find(byType("staffId"))?.value?.stringValue || state.customerInfo.staffId, // direct-referral field
			branchCode:
				holder?.facts?.find(byType("branchCode"))?.value?.stringValue ||
				facts?.find(byType("branchCode"))?.value?.stringValue ||
				state.customerInfo.branchCode,
			indirectReferrer:
				holder?.facts?.find(byType("indirectReferrer"))?.value?.stringValue || state.customerInfo.indirectReferrer,
			confirmTimeline: holder?.facts?.find(byType("confirmTimeline"))?.value?.stringValue || state.customerInfo.confirmTimeline,
			bankStaffName: holder?.facts?.find(byType("bankStaffName"))?.value?.stringValue || state.customerInfo.bankStaffName,
			customerId: facts?.find(byType("customerId"))?.value?.stringValue || state.customerInfo.customerId,
			meetingMethod: facts?.find(byType("meetingMethod"))?.value?.stringValue || state.customerInfo.meetingMethod,
			leadId:
				holder?.facts?.find(byType("leadId"))?.value?.stringValue ||
				facts?.find(byType("leadGCM"))?.value?.stringValue ||
				state.customerInfo.leadId,
			idIssuedDate: holder?.facts?.find(byType("idIssuedDate"))?.value?.stringValue || state.customerInfo?.idIssuedDate,
			idIssuedPlace: holder?.facts?.find(byType("idIssuedPlace"))?.value?.stringValue || state.customerInfo?.idIssuedPlace,
			occupation: occupationFromFact || state.customerInfo?.occupation,
			occupationClass:
				mapOccupationClass({
					occupationSelected: occupationFromFact,
					occupationList: occupations,
				}) || state.customerInfo?.occupationClass,
			address: mapAddress.street && mapAddress.normalizedAddress ? mapAddress : state.customerInfo?.address,
			smokingHabit: holder?.facts?.find(byType("smokingHabit"))?.value?.stringValue || state.customerInfo.smokingHabit,
			uid: holder?.facts?.find(byType("uid"))?.value?.stringValue || state.customerInfo.uid,
			relatedAccountId:
				holder?.facts?.find(byType("relatedAccountId"))?.value?.stringValue || state.customerInfo.relatedAccountId,
			/**
			 * Concept of dealCloser:
			 * Scenario 1: Manual flow
			 * - dealCloser has value from CRM info: save onto customerInfo.dealCloser, dont save to state.tisAgentCode -> it will impact to the REFER_TIS default
			 * Scenario 2: Resuming case
			 * - use state.tisAgentCode from store
			 */
			dealCloser:
				holder?.facts?.find(byType("dealCloser"))?.value?.stringValue || state.tisAgentCode || state.customerInfo.dealCloser,
			directReferralAgentCode:
				holder?.facts?.find(byType("directReferralAgentCode"))?.value?.stringValue || state.customerInfo.directReferralAgentCode,
			/**
			 * isAgentPO will be a boolean if user chosen true/false option, otherwise will be null formatted , not allow to be undefined
			 */
			isAgentPO: holder?.facts?.find(byType("isAgentPO"))?.value?.booleanValue || state.customerInfo.isAgentPO,
			customerConsentType:
				holder?.facts?.find(byType("customerConsentType"))?.value?.stringValue || state.customerInfo.customerConsentType,
		}
	},
	individualFactsByType: (state, getters) => {
		const individualFactMapping = {
			age: "numberValue",
			numOfIndependents: "numberValue",
			fullName: "stringValue",
			citizenIdType: "stringValue",
			citizenId: "stringValue",
			phoneNumber: "stringValue",
			email: "stringValue",
			gender: "stringValue",
			dateOfBirth: "stringValue",
			maritalStatus: "stringValue",
			spouseName: "stringValue",
			spouseAge: "numberValue",
			customerId: "stringValue",
			dependentList: "arrayValue",
			meetingMethod: "stringValue",
			staffId: "stringValue",
			branchCode: "stringValue",
			indirectReferrer: "stringValue",
			confirmTimeline: "stringValue",
			bankStaffName: "stringValue",
			idIssuedDate: "stringValue",
			idIssuedPlace: "stringValue",
			occupation: "stringValue",
			address: "objectValue",
			smokingHabit: "stringValue",
			uid: "stringValue",
			relatedAccountId: "stringValue",
			leadId: "stringValue",
			dealCloser: "stringValue",
			directReferralAgentCode: "stringValue",
			isAgentPO: "booleanValue",
		}
		const individualFacts = state?.caseData?.holder?.facts
		return individualFacts.reduce((acc, fact) => {
			acc[fact.type] = { ...fact, val: fact.value?.[individualFactMapping[fact.type]] || null }
			return acc
		}, {})
	},
	annualPremiumFact: (state, getters) => {
		return {
			type: "annualPremium",
			value: {
				numberValue: getters.selectedProductDetail.premium || getters?.annualPremium,
			},
		}
	},
	annualPremiumFactId: (state, getters) => {
		const { facts } = state?.caseData
		const annualPremiumFact = facts.find((fact) => fact.type === "annualPremium")
		return annualPremiumFact.id
	},
	baseProductIdFact: (state, getters) => {
		return {
			type: "baseProductId",
			value: {
				numberValue: getters.baseProductId?.pricing?.originalPrice,
			},
		}
	},
	baseProductIdFactId: (state, getters) => {
		const { facts } = state?.caseData
		const baseProductId = facts.find((fact) => fact.type === "baseProductId")
		return baseProductId.id
	},
	ePosLinkCustomerDBFact: (state, getters) => {
		return {
			type: "ePosLinkCustomerDB",
			value: {
				stringValue: getters.ePosLinkCustomerDB,
			},
		}
	},
	ePosLinkCustomerDBFactId: (state, getters) => {
		const { facts } = state?.caseData
		const ePosLinkCustomerDB = facts.find((fact) => fact?.type === "ePosLinkCustomerDB")
		return ePosLinkCustomerDB.id
	},
	selectedProductFact: (state, getters) => {
		const toStringFactValue = (value) => ({ stringValue: value })
		const overwriteProductOptions = getters?.overwriteProductOptions?.[getters.selectedProduct]
		const ridersKey = overwriteProductOptions?.riders
		const mapRiderKeys = ridersKey?.map(toStringFactValue)
		const { healthAndMedical } = getters.answers
		const { inpatient: inpatientSA, outpatient: outpatientSA, dental: dentalSA } = healthAndMedical.roomAndBoardExpense
		const factList = {
			type: "selectedProduct",
			value: {
				objectValue: [
					{
						key: "name",
						value: { stringValue: getters?.selectedProduct },
					},
					{
						key: "sa",
						value: { numberValue: overwriteProductOptions?.sa || getters.shortFall },
					},
					{
						key: "paymentTerm",
						value: { numberValue: overwriteProductOptions?.paymentTerm || PERIODIC_PAYMENT_TERM },
					},
					{
						key: "productPeriodicPayment",
						value: { numberValue: overwriteProductOptions?.paymentTerm || PERIODIC_PAYMENT_TERM },
					},
					{
						key: "investmentProfile",
						value: { stringValue: overwriteProductOptions?.investmentPreference },
					},
					{
						key: "financialAffordability",
						value: { numberValue: overwriteProductOptions?.financialAffordability },
					},
					{
						key: "financialAffordabilityAmount",
						value: { numberValue: overwriteProductOptions?.financialAffordabilityAmount },
					},
					{
						key: "estimatedPremiumYear",
						value: { numberValue: overwriteProductOptions?.estimatedPremiumYear },
					},
					{
						key: "riders",
						value: {
							arrayValue:
								mapRiderKeys ||
								getters.recommendedProducts?.find((i) => i.name === getters.selectedProduct)?.riders?.map(toStringFactValue),
						},
					},
					{
						key: "sa_add",
						value: {
							objectValue: [
								{
									key: "ADD",
									value: {
										numberValue:
											overwriteProductOptions?.sa_add || getters.selectedProductDetail?.benefits?.accidentalDeath?.value,
									},
								},
							],
						}, // this is benefit
					},
				],
			},
		}

		// Do this way because in case doesnt have these riders -> causing the updating factOfcase if one of them is null or undefined
		if (
			getters.selectedProduct === PRODUCT_NAME.titanium_2 ||
			getters.selectedProduct === PRODUCT_NAME.ul038 ||
			getters.selectedProduct === PRODUCT_NAME.ul040
		) {
			const prenatalValue = overwriteProductOptions?.sa_prenatal || getters.selectedProductDetail?.benefits?.prenatal?.value
			if (prenatalValue) {
				factList.value.objectValue.push({
					key: "sa_prenatal",
					value: {
						numberValue: prenatalValue,
					},
				})
			}
			const accidentDismembermentValue =
				overwriteProductOptions?.sa_add_plus || getters.selectedProductDetail?.benefits?.accidentDismemberment?.value
			if (accidentDismembermentValue) {
				factList.value.objectValue.push({
					key: "sa_add_plus",
					value: {
						numberValue: accidentDismembermentValue,
					},
				})
			}
		}
		if (ridersKey && ridersKey?.find((key) => key === "CI" || key === "CI-plus")) {
			/**
			 * This fallback value will be handling the case of returning from step 7 to 6, or user only clicks on the CHOOSE button (product view) without customize a product
			 */
			const CIValue =
				overwriteProductOptions?.sa_ci ||
				getters.selectedProductDetail?.benefits?.criticalIllnessPlus?.originalValue ||
				getters.selectedProductDetail?.benefits?.criticalIllness?.originalValue
			if (CIValue) {
				factList.value.objectValue.push({
					key: "sa_ci",
					value: {
						objectValue: [
							{
								key: ridersKey?.find((key) => key === "CI" || key === "CI-plus"),
								value: {
									numberValue: CIValue,
								},
							},
						],
					}, // rider: critical illness CI or CI-plus
				})
			}
		}
		if (ridersKey && ridersKey?.find((key) => key === "MC")) {
			const MCValue = overwriteProductOptions?.sa_mc || getters.selectedProductDetail?.benefits?.medicalSupport?.value
			if (MCValue) {
				factList.value.objectValue.push({
					key: "sa_mc",
					value: {
						objectValue: [
							{
								key: ridersKey?.find((key) => key === "MC"),
								value: { numberValue: MCValue },
							},
						],
					}, // rider: critical illness CI or CI-plus
				})
			}
		}
		if (ridersKey?.find((key) => key === "HC")) {
			const inpatientValue = overwriteProductOptions?.sa_hc_inpatient || inpatientSA
			const outpatientValue = overwriteProductOptions?.sa_hc_outpatient || outpatientSA
			const dentalValue = overwriteProductOptions?.sa_hc_dental || dentalSA
			if (inpatientValue) {
				factList.value.objectValue.push({
					key: "sa_hc_inpatient",
					value: {
						objectValue: [
							{
								key: ridersKey?.find((key) => key === "HC"),
								value: { numberValue: inpatientValue },
							},
						],
					}, // rider: health care inpatient
				})
			}
			if (outpatientValue) {
				factList.value.objectValue.push({
					key: "sa_hc_outpatient",
					value: {
						objectValue: [
							{
								key: ridersKey?.find((key) => key === "HC"),
								value: { numberValue: outpatientValue },
							},
						],
					}, // rider: health care outpatient
				})
			}
			if (dentalValue) {
				factList.value.objectValue.push({
					key: "sa_hc_dental",
					value: {
						objectValue: [
							{
								key: ridersKey?.find((key) => key === "HC"),
								value: { numberValue: dentalValue },
							},
						],
					}, // rider: health care dental
				})
			}
		}
		if (CAN06_ON_PRODUCTS.includes(getters.selectedProduct)) {
			if (ridersKey && ridersKey?.find((key) => key === "CAN06")) {
				const lateStageCancerValue =
					overwriteProductOptions?.saCAN06 || getters.selectedProductDetail?.benefits?.lateStageCancer?.value
				if (lateStageCancerValue) {
					factList.value.objectValue.push({
						key: "saCAN06",
						value: {
							numberValue: lateStageCancerValue,
						},
					})
				}
			}
		}
		return factList
	},
	selectedProductFactId: (state, getters) => {
		const { facts } = state?.caseData
		const selectedProductFact = facts?.find((fact) => fact?.type === "selectedProduct")
		return selectedProductFact.id
	},
	selectedProductFromFact: (state, getters) => {
		const { facts } = state?.caseData || {}
		const selectedProductFact = facts?.find((fact) => fact.type === "selectedProduct")?.value?.objectValue
		const name = selectedProductFact?.find(byKey("name"))?.value?.stringValue
		// By the value from getters.overwriteProductOptions?.[name]: when user resuming a completed FNA case => will be undefined
		const riders = selectedProductFact?.find(byKey("riders"))?.value?.arrayValue?.map((i) => i.stringValue)
		const paymentTerm = selectedProductFact?.find(byKey("paymentTerm"))?.value?.numberValue
		const investmentPreference = selectedProductFact?.find(byKey("investmentProfile"))?.value?.stringValue
		const financialAffordability = selectedProductFact?.find(byKey("financialAffordability"))?.value?.numberValue
		const financialAffordabilityAmount = selectedProductFact?.find(byKey("financialAffordabilityAmount"))?.value?.numberValue
		const estimatedPremiumYear = selectedProductFact?.find(byKey("estimatedPremiumYear"))?.value?.numberValue

		// Product riders
		const sa = selectedProductFact?.find(byKey("sa"))?.value?.numberValue
		const fnaAnswersFact = facts?.find((fact) => fact?.type === "fnaAnswers")?.value?.objectValue
		const healthAndMedical = fnaAnswersFact?.find(byKey("healthAndMedical"))?.value?.objectValue
		const roomAndBoardExpense = healthAndMedical?.find(byKey("roomAndBoardExpense"))?.value?.objectValue
		const inpatient = roomAndBoardExpense?.find(byKey("inpatient"))?.value?.numberValue
		const healthCareOutpatientBenefit = roomAndBoardExpense?.find(byKey("outpatient"))?.value?.numberValue
		const dentalBenefit = roomAndBoardExpense?.find(byKey("dental"))?.value?.numberValue
		const prenatalSA =
			getters.overwriteProductOptions?.[name]?.sa_prenatal || selectedProductFact?.find(byKey("sa_prenatal"))?.value?.numberValue
		// healthcare package
		const inpatientKeyBySelectedProductFact = selectedProductFact?.find(byKey("sa_hc_inpatient"))?.value?.objectValue
		const inpatientHcSA =
			getters.overwriteProductOptions?.[name]?.sa_hc_inpatient ||
			inpatientKeyBySelectedProductFact?.find(byKey("HC"))?.value?.numberValue ||
			inpatient
		const outpatientKeyBySelectedProductFact = selectedProductFact?.find(byKey("sa_hc_outpatient"))?.value?.objectValue
		const outpatientHcSA =
			getters.overwriteProductOptions?.[name]?.sa_hc_outpatient ||
			outpatientKeyBySelectedProductFact?.find(byKey("HC"))?.value?.numberValue ||
			healthCareOutpatientBenefit
		const dentalKeyBySelectedProductFact = selectedProductFact?.find(byKey("sa_hc_dental"))?.value?.objectValue
		const dentalHcSA =
			getters.overwriteProductOptions?.[name]?.sa_hc_dental ||
			dentalKeyBySelectedProductFact?.find(byKey("HC"))?.value?.numberValue ||
			dentalBenefit

		//
		const accidentDismembermentSA =
			getters.overwriteProductOptions?.[name]?.sa_add_plus ||
			selectedProductFact?.find(byKey("sa_add_plus"))?.value?.numberValue ||
			healthAndMedical?.find(byKey("accidentAndDisabilityCoverAmount"))?.value?.numberValue

		const hospitalisedRoomAndBoardFact = healthAndMedical?.find(byKey("hospitalisedRoomAndBoard"))?.value?.numberValue
		const criticalIllnessCoverAmountAnswerFact = healthAndMedical?.find(byKey("critticalIllnessCoverAmount"))?.value?.numberValue
		const criticalIllnessBySelectedProductFactKey = selectedProductFact?.find(byKey("sa_ci"))?.value?.objectValue
		const criticalIllnessSAByProductFact =
			criticalIllnessBySelectedProductFactKey?.find(byKey("CI-plus"))?.value?.numberValue ||
			criticalIllnessBySelectedProductFactKey?.find(byKey("CI"))?.value?.numberValue
		const saMCFromProductFact = selectedProductFact?.find(byKey("sa_mc"))?.value?.objectValue?.find(byKey("MC"))?.value
			?.numberValue
		const medicalSA = getters.overwriteProductOptions?.[name]?.sa_mc || saMCFromProductFact || hospitalisedRoomAndBoardFact
		const criticalIllnessCoverAmountSA =
			getters.overwriteProductOptions?.[name]?.sa_ci || criticalIllnessSAByProductFact || criticalIllnessCoverAmountAnswerFact
		const saCAN06 =
			getters.overwriteProductOptions?.[name]?.saCAN06 || selectedProductFact?.find(byKey("saCAN06"))?.value?.numberValue
		return {
			name,
			sa,
			riders,
			paymentTerm,
			investmentPreference,
			financialAffordability,
			financialAffordabilityAmount,
			estimatedPremiumYear,
			// Product benefits & riders
			sa_add: getADD(sa),
			sa_mc: medicalSA || getMedicalSupport(sa),
			sa_ci: criticalIllnessCoverAmountSA || getCI(sa),
			sa_hc: getSumHealthcare({ roomAndBoardExpense: inpatientHcSA, healthCareOutpatientBenefit: outpatientHcSA, dentalHcSA }),
			sa_hc_outpatient: outpatientHcSA,
			// when we access to the product of UL2019 (Protect or Saving) or titanium2 : prenatal will be showing up
			sa_prenatal: prenatalSA,
			sa_hc_inpatient: inpatientHcSA,
			sa_hc_dental: dentalHcSA,
			sa_add_plus: accidentDismembermentSA,
			saCAN06,
		}
	},
	goalsFactId: (state, getters) => {
		const { facts } = state?.caseData
		const goalsFact = facts?.find((fact) => fact.type === "goals")
		return goalsFact?.id
	},
	goalsFact: (state, getters) => {
		return {
			type: "goals",
			value: {
				arrayValue: getters.selectedGoals.map((goal) => ({ stringValue: goal })),
			},
		}
	},
	branchCodeFactId: (state, getters) => {
		const { facts } = state.caseData ?? {}
		const branchCodeFact = facts?.find((fact) => fact.type === "branchCode")
		return branchCodeFact?.id
	},
	branchCodeFact: (state, getters) => {
		return {
			type: "branchCode",
			value: {
				stringValue: getters.customerInfo.branchCode,
			},
		}
	},
	goalsFromFact: (state, getters) => {
		const { facts } = state?.caseData
		const goalsFact = facts?.find((fact) => fact?.type === "goals")
		return goalsFact?.value?.arrayValue?.map((i) => i.stringValue) ?? []
	},
	answersFactId: (state, getters) => {
		const { facts } = state?.caseData
		const answersFact = facts?.find((fact) => fact.type === "fnaAnswers")
		return answersFact?.id
	},
	answersFromFact: (state, getters) => {
		const { facts } = state?.caseData
		const answersFact = facts?.find((fact) => fact?.type === "fnaAnswers")
		return getAnswersFromFacts({ facts: answersFact })
	},
	answersFact: (state, getters) => {
		const {
			childrenEducation,
			growWealthAndAssets,
			financeUncertainty,
			healthAndMedical,
			retirement,
			having,
			targetEducation,
			targetGrowWealthAndAssets,
			targetHealthAndMedical,
			targetRetirement,
			targetFinance,
		} = state.answers || {}
		const childrenEducationFact = childrenEducation ? getChildrenEducationFact(childrenEducation) : null
		const growWealthAndAssetsFact = growWealthAndAssets ? getGrowWealthAndAssetsFact(growWealthAndAssets) : null
		const financeUncertaintyFact = financeUncertainty ? getFinanceUncertaintyFact(financeUncertainty) : null
		const healthAndMedicalFact = healthAndMedical ? getHealthAndMedicalFact(healthAndMedical) : null
		const retirementFact = retirement ? getRetirementFact(retirement) : null
		const havingFact = having ? getHavingFact(having) : null

		const targetEducationFact = targetEducation ? getTargetEducationFact(targetEducation) : null
		const targetGrowWealthAndAssetsFact = targetGrowWealthAndAssets
			? getTargetGrowWealthAndAssetsFact(targetGrowWealthAndAssets)
			: null
		const targetHealthAndMedicalFact = targetHealthAndMedical ? getTargetHealthAndMedicalFact(targetHealthAndMedical) : null
		const targetRetirementFact = targetRetirement ? getTargetRetirementFact(targetRetirement) : null
		const targetFinanceFact = targetFinance ? getTargetFinanceFact(targetFinance) : null
		return {
			type: "fnaAnswers",
			value: {
				objectValue: [
					{
						key: "childrenEducation",
						value: childrenEducationFact,
					},
					{
						key: "growWealthAndAssets",
						value: growWealthAndAssetsFact,
					},
					{
						key: "financeUncertainty",
						value: financeUncertaintyFact,
					},
					{
						key: "healthAndMedical",
						value: healthAndMedicalFact,
					},
					{
						key: "retirement",
						value: retirementFact,
					},
					{
						key: "having",
						value: havingFact,
					},
					{
						key: "targetEducation",
						value: targetEducationFact,
					},
					{
						key: "targetGrowWealthAndAssets",
						value: targetGrowWealthAndAssetsFact,
					},
					{
						key: "targetHealthAndMedical",
						value: targetHealthAndMedicalFact,
					},
					{
						key: "targetRetirement",
						value: targetRetirementFact,
					},
					{
						key: "targetFinance",
						value: targetFinanceFact,
					},
				],
			},
		}
	},
	answers: (state, getters) => {
		return { ...state.answers }
	},
	meetingMethodFactId: (state, getters) => {
		const { facts } = state.caseData ?? {}
		const meetingMethodFact = facts?.find((fact) => fact.type === "meetingMethod")
		return meetingMethodFact?.id
	},
	meetingMethodFact: (state, getters) => {
		return {
			type: "meetingMethod",
			value: {
				stringValue: getters.customerInfo.meetingMethod,
			},
		}
	},
	shortFall: (state, getters) => {
		const totalSum = state.selectedGoals.reduce((sum, goal) => sum + getters.sumGoals[goal], 0)
		return Math.round(totalSum - getters.sumHaving)
	},
	originalShortFall: (state, getters) => {
		const totalSum = state.selectedGoals.reduce((sum, goal) => sum + getters.originalSumGoals[goal], 0)
		return Math.round(totalSum - getters.originalSumHaving)
	},
	timePerGoals: (state, getters) => {
		const currentAge = state.customerDetails.age
		const { childrenEducation, growWealthAndAssets, financeUncertainty, retirement } = state.answers
		return {
			childrenEducation: childrenEducation ? Math.min(...childrenEducation.map((item) => item.ageToUseFund - item.age)) : 0,
			growWealthAndAssets:
				growWealthAndAssets?.bigPurchases?.length > 0
					? Math.min(...growWealthAndAssets.bigPurchases.map((item) => item.yearsPlannedToBuy)) || 0
					: growWealthAndAssets?.growWealth?.expectedTimeToReachTargetSaving || 0,
			financeUncertainty: financeUncertainty ? financeUncertainty.protectionTime : 0,
			healthAndMedical: 0,
			retirement: retirement ? retirement.retirementAge - currentAge : 0,
		}
	},
	timeToUseSaving: (state, getters) => {
		const currentAge = state.customerDetails.age
		const { childrenEducation, growWealthAndAssets, financeUncertainty, retirement } = state.answers
		const timePerGoals = {
			childrenEducation: childrenEducation ? Math.min(...childrenEducation.map((item) => item.ageToUseFund - item.age)) : 0,
			growWealthAndAssets:
				growWealthAndAssets?.bigPurchases?.length > 0
					? Math.min(...growWealthAndAssets.bigPurchases.map((item) => item.yearsPlannedToBuy)) || 0
					: growWealthAndAssets?.growWealth?.expectedTimeToReachTargetSaving || 0,
			financeUncertainty: financeUncertainty ? financeUncertainty.protectionTime : 0,
			healthAndMedical: 0,
			retirement: retirement ? retirement.retirementAge - currentAge : 0,
		}
		if (state.selectedGoals.length === 1) {
			return timePerGoals[state.selectedGoals[0]]
		}
		const timeToUses = state.selectedGoals.filter((goal) => goal !== "healthAndMedical").map((goal) => timePerGoals[goal])
		return Math.min(...timeToUses)
	},
	sumHaving: (state, getters) => {
		const { timeToUseSaving } = getters
		const { rates } = state

		const { yourAccumulatedSaving, currentProtectionCoverage } = state?.answers?.having || {}
		return round(yourAccumulatedSaving * Math.pow(1 + rates.interest / 100, timeToUseSaving) + currentProtectionCoverage, 6)
	},
	originalSumHaving: (state, getters) => {
		const { timeToUseSaving } = getters
		const { rates } = state
		const { yourAccumulatedSaving, currentProtectionCoverage } = state?.answers?.having || {}
		return yourAccumulatedSaving * Math.pow(1 + rates.interest / 100, timeToUseSaving) + currentProtectionCoverage
	},
	sumDetails: (state, getters) => {
		const { childrenEducation, growWealthAndAssets, healthAndMedical } = state.answers
		return {
			childrenEducation: getSumChildrenEducationDetail(childrenEducation, state.rates, state),
			growWealthAndAssets: getSumGrowWealthAndAssetsDetail(growWealthAndAssets, state.rates, state),
			healthAndMedical: getSumHealthAndMedicalDetail(healthAndMedical, state.rates, state),
		}
	},
	sumGoals: (state, getters) => {
		const { childrenEducation, growWealthAndAssets, financeUncertainty, healthAndMedical, retirement } = state.answers
		return {
			childrenEducation: getSumChildrenEducation(childrenEducation, state.rates, state),
			growWealthAndAssets: getSumGrowWealthAndAssets(growWealthAndAssets, state.rates, state),
			financeUncertainty: getSumFinanceUncertainty(financeUncertainty, state.rates, state),
			healthAndMedical: getSumHealthAndMedical(healthAndMedical, state.rates, state),
			retirement: getSumRetirement(retirement, state.rates, state),
		}
	},
	sumGoalsWithNoCoefficient: (state, getters) => {
		const { childrenEducation, growWealthAndAssets, financeUncertainty, healthAndMedical, retirement } = state.answers
		return {
			childrenEducation: getOriginalSumChildrenEducation(childrenEducation),
			growWealthAndAssets: getOriginalSumGrowWealthAndAssets(growWealthAndAssets),
			financeUncertainty: getOriginalSumFinanceUncertainty(financeUncertainty),
			healthAndMedical: getSumHealthAndMedical(healthAndMedical, state.rates, state),
			retirement: getOriginalSumRetirement(retirement),
		}
	},
	originalSumGoals: (state, getters) => {
		const { childrenEducation, growWealthAndAssets, financeUncertainty, healthAndMedical, retirement } = state.answers
		return {
			childrenEducation: getSumChildrenEducation(childrenEducation, state.rates, state, true),
			growWealthAndAssets: getSumGrowWealthAndAssets(growWealthAndAssets, state.rates, state, true),
			financeUncertainty: getSumFinanceUncertainty(financeUncertainty, state.rates, state, true),
			healthAndMedical: getSumHealthAndMedical(healthAndMedical, state.rates, state, true),
			retirement: getSumRetirement(retirement, state.rates, state, true),
		}
	},
	recommendedProducts: getRecommendedProducts,
	prices: (state, getters) => state.prices,
	selectedGoals: (state, getters) => state.selectedGoals,
	defaultSA: (state, getters) => state.defaultSA,
	selectedProduct: (state, getters) => state.selectedProduct,
	customizingProduct: (state, getters) => state.customizingProduct,
	conversationResult: (state, getters) => state.conversationResult,
	tisAgentCode: (state, getters) => state.tisAgentCode,
	productCode: (state, getters) => state.productCode,
	conversationId: (state, getters) => state?.caseData?.caseNumber || state?.caseData?.id,
	dropOffReason: (state, getters) => state.dropOffReason,
	otherDropOffReason: (state, getters) => state.otherDropOffReason,
	leadGCM: (state, getters) => state.leadGCM,
	referLeadFna: (state, getters) => state.referLeadFna,
	recentLeadApiStatus: (state, getters) => state?.caseData?.fields || "{}",
	planCode: (state, getters) => state.planCode,
	mvpVersion: (state, getters) => state.mvpVersion,
	isMultiGoals: (state, getters) => state.selectedGoals.length > 1,
	overwriteProductOptions: (state, getters) => state.overwriteProductOptions,
	selectedProductDetail: (state, getters) => {
		if (!getters.selectedProduct) return null
		const productName = getters.selectedProduct
		const productSelectedDetail = getters.overwriteProductOptions?.[productName]

		const riders = productSelectedDetail?.riders
		const sa = productSelectedDetail?.sa || getters.initialSA
		/* eslint-disable camelcase */
		const sa_add = productSelectedDetail?.sa_add
		const sa_mc = productSelectedDetail?.sa_mc
		const sa_ci = productSelectedDetail?.sa_ci
		const sa_hc = productSelectedDetail?.sa_hc
		const sa_prenatal = productSelectedDetail?.sa_prenatal
		const sa_add_plus = productSelectedDetail?.sa_add_plus
		const saCAN06 = productSelectedDetail?.saCAN06

		const isTitanProduct = productName === PRODUCT_NAME.titan_6years || productName === PRODUCT_NAME.titan_10years
		let paymentProduct = PERIODIC_PAYMENT_TERM
		if (isTitanProduct) paymentProduct = TITAN_PAYMENT_TERM[productName]
		const investmentPreference = productSelectedDetail?.investmentPreference
		const financialAffordability = productSelectedDetail?.financialAffordability

		const financialAffordabilityAmount = productSelectedDetail?.financialAffordabilityAmount
		const estimatedPremiumYear = productSelectedDetail?.estimatedPremiumYear
		return {
			name: productName,
			riders,
			sa,
			premium: getters.prices[productName],
			benefits: benefitsCalculate({
				productName,
				riders,
				sa,
				sa_add,
				sa_mc,
				sa_ci,
				sa_hc,
				sa_prenatal,
				sa_add_plus,
				saCAN06,
				t: () => {},
			}),
			paymentTerm: paymentProduct,
			investmentPreference,
			financialAffordability,
			financialAffordabilityAmount,
			estimatedPremiumYear,
		}
	},
	initialSA: (state, getters) => {
		const { timeToUseSaving } = getters
		const { rates } = state
		const yourAccumulatedSaving = state?.answers?.having?.yourAccumulatedSaving || 0

		const savingInFuture = yourAccumulatedSaving * Math.pow(1 + rates.interest / 100, timeToUseSaving)
		let result = getters.originalShortFall
		const healthCareGoal = getSumHealthAndMedical(getters.answers?.healthAndMedical || {})
		const currentProtectionCoverage = getters.answers?.having?.currentProtectionCoverage || 0
		if (getters.selectedGoals.length > 1 && getters.selectedGoals.includes("healthAndMedical")) {
			if (getters.originalShortFall > 500000000) {
				result = getters.originalShortFall - healthCareGoal
			} else if (getters.originalShortFall < 500000000 && getters.originalShortFall >= savingInFuture) {
				result = getters.originalShortFall - healthCareGoal
			} else if (getters.originalShortFall < 500000000 && getters.originalShortFall < savingInFuture) {
				result = getters.originalShortFall - currentProtectionCoverage
			}
		} else if (getters.originalShortFall > 500000000) {
			result = getters.originalShortFall
		} else if (getters.originalShortFall < 500000000 && getters.originalShortFall > savingInFuture) {
			result = getters.originalShortFall
		} else if (getters.originalShortFall < 500000000 && getters.originalShortFall < savingInFuture) {
			result = savingInFuture
		}
		return roundSAByRange(result)
	},
	originalFaceAmount: (state, getters) => {
		const { overwriteProductOptions, initialSA, customizingProduct, selectedProduct } = getters
		const productName = customizingProduct || selectedProduct
		return overwriteProductOptions?.[productName]?.sa || initialSA
	},
	faceAmount: (state, getters) => {
		return roundSA(getters.originalFaceAmount)
	},
	protectCurrentPlan: (state, getters) => getters.shortFall < 500000000 && getters.shortFall < getters.sumHaving,
	pdfTemplates: (state) => state.pdfTemplates,
	isResumeFNA: (state) => state.isResumeFNA,
	resumeFlag: (state) => state.resumeFlag,
	fnaBackDestination: (state) => state.fnaBackDestination,
	ePosLinkCustomerDB: (state) => state.ePosLinkCustomerDB,
	baseProductId: (state) => state.baseProductId,
	ridersValid: (state) => state.ridersValid,
	promotions: (state) => state.promotions,
	annualPremium: (state) => state.annualPremium,
}
