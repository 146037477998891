export default {
	core: {
		numberGreaterThan: "Number must be greater than {gt}",
		numberLessThan: "Number must be less than {lt}",
		home: "Home",
		dashboard: "Dashboard",
		cases: "Cases",
		policies: "Policies",
		clients: "Clients",
		product: "Product",
		users: "Users",
		participants: "Participants",
		organizations: "Organizations",
		report: "Report",
		chatRooms: "Chat Rooms",
		logout: "Log Out",
		order: "order",
		edit: "Edit",
		delete: "Delete",
		required: "This field is required!",
		requiredIf: "This field is required!",
		email: "Invalid email",
		age: "Your age must be between 18 and 65",
		hkid: "Please enter a valid HKID Card Number.",
		alpha: "This field may only contain alphabetic characters",
		alphaNum: "Accepts only alphanumerics",
		maxLength: "This field exceeds the maximum length",
		minLength: "This field needs at least 8 characters",
		sameAs: "This field does not match",
		noNumber: "This field can't contain number",
		phone: "Please provide a valid phone number",
		validAge: "Age must be between 18 and 99",
		validChildAge: "Age must be between 0 and 99",
		validDependent: "Number of children must be equal or less than 3",
		minNumber: "Number must be greater than 0",
		maxNumber: "Number must be less than 99",
		validPercent: "Number must be between 0 and 100",
		validNumber: "Invalid number",
		noDataHere: "No data available",
		notifications: "Notifications",
		credentials: "Credentials",
		name: "Name",
		gender: "Gender",
		salutation: "Salutation",
		englishFirstName: "English First Name",
		englishLastName: "English Last Name",
		chineseFirstName: "Chinese First Name",
		chineseLastName: "Chinese Last Name",
		dateOfBirth: "Date Of Birth",
		maritalStatus: "Marital Status",
		countryOfResidence: "Country Of Residence",
		acceptsMarketing: "Accepts Marketing",
		preferredCommunicationChannel: "Preffered Communication Channel",
		preferredLanguage: "Preferred Language",
		contacts: "Contacts",
		addresses: "Addresses",
		identities: "Identities",
		facts: "Facts",
		relationships: "Relationships",
		natureOfBusiness: "Nature Of Business",
		registrationNumber: "Registration Number",
		cancel: "Cancel",
		update: "Update",
		add: "Add",
		confirm: "Confirm",
		type: "Type",
		value: "Value",
		addField: "Add Field",
		deleteAddress: "Delete Address",
		addressType: "Address Type",
		ok: "Ok",
		individuals: "Individuals",
		companies: "Companies",
		objects: "Objects",
		internals: "Internals",
		select: "Select",
		selectEntityType: "Please select an Entity Type",
		selectEntity: "Select an Entity",
		noEntityFound: "No Entity Found",
		relationshipType: "Relationship Type",
		individual: "Individual",
		company: "Company",
		object: "Object",
		internal: "Internal",
		organization: "Organization",
		yes: "Yes",
		no: "No",
		or: "or",
		browse: "Browse",
		documents: "Documents",
		noFiles: "There are no files yet",
		noFiles_upload: "No file to upload",
		dragDropHere: "Drag and Drop Files Here",
		upload: "Upload",
		uploading: "Uploading...",
		failed: "Failed",
		inProgress: "In progress",
		ready: "Ready to upload",
		uploaded: "Uploaded",
		count: "Count",
		showing: "Showing",
		of: "of",
		fileNotSupported_1: "The file in",
		fileNotSupported_2: "type is not supported!",
		label: "Label",
		text: "Text",
		number: "Number",
		boolean: "Boolean",
		date: "Date",
		code: "Code",
		activeReferral: "Active Referral",
		notes: "Notes",
		notesNotFound: "Notes not found",
		filesNotFound: "Files Not Found",
		addNew: "Add new",
		editFor: "Edit for",
		addNotes: "Add Notes",
		countryOfResidency: "Country Of Residency",
		shareToClient: "Share to Client",
		transactions: "Transactions",
		today: "Today",
		lastWeek: "Last Week",
		lastMonth: "Last Month",
		lastThreeMonths: "Last 3 Months",
		between: "Between",
		before: "Before",
		after: "After",
		clear: "Clear",
		premium: "Premium",
		discountCodes: "Discount Codes",
		premiumBreakdown: "Premium Breakdown",
		reset: "Reset",
		paymentFrequency: "Payment Frequency",
		grossPremium: "Gross Premium",
		netPremium: "Net Premium",
		commissionSplit: "Commission Split",
		ofPremium: "of Premium",
		ofRule: "of Rule",
		addRule: "Add Rule",
		addNewRule: "Add New Rule",
		organizationOrParticipant: "Organization or Participant",
		flat: "Flat",
		flatFee: "Flat Fee",
		percentage: "Percentage",
		percentageOfResultOfRule: "Percentage of Result of Rule",
		rule: "Rule",
		updateRule: "Update Rule",
		calculator: "Calculator",
		calculatorNote:
			"This is a helper used to calculate commission splits using a defined function template, and you can adjust rules according to results.",
		functionTemplate: "Function Template",
		outputs: "Outputs",
		currencyCode: "Currency Code",
		amount: "Amount",
		stakeholders: "Stakeholders",
		addStakeholder: "Add Stakeholder",
		deleteStakeholderSuccess: "Stakeholder has been deleted successfully!",
		addStakeholderSuccess: "Stakeholder has been added successfully!",
		stakeholdersNotFound: "Stakeholders not found",
		noName: "No Name",
		title: "Title",
		content: "Content",
		entity: "Entity",
		exclusion: "Exclusion",
		addExclusion: "Add Exclusion",
		remark: "Remark",
		deleteExclusionSuccess: "Exclusion has been deleted successfully!",
		addFacts: "Add Facts",
		fact: "Fact",
		pleaseSelect: "Please Select",
		list: "List",
		addRow: "Add Row",
		remove: "Remove",
		deleteFactSuccess: "Fact has been deleted successfully!",
		source: "Source",
		paymentInfo: "Payment Info",
		addPayment: "Add Payment",
		payment: "Payment",
		comment: "Comment",
		paymentMethod: "Payment Method",
		HKD: "HKD",
		USD: "USD",
		CASH: "Cash",
		CREDIT_CARD: "Credit Card",
		GIRO: "Giro",
		ATM: "ATM",
		SELF_SERVICE_PAYMENT_MACHINE: "Self service payment machine",
		BANK_TRANSFER: "Bank Transfer",
		CHEQUE: "Cheque",
		ONE_TIME: "One Time",
		MONTHLY: "Monthly",
		QUARTERLY: "Quarterly",
		SEMI_ANNUALLY: "Semi Annually",
		ANNUALLY: "Annually",
		discount: "Discount",
		manual: "Manual",
		discountCode: "Discount Code",
		addDiscountSuccess: "Discount has been added successfully!",
		removeDiscountSuccess: "Discount has been removed successfully!",
		resetPremiumSuccess: "Premium has been reset successfully!",
		healthRoomBoardAmount: "Please enter an mount from 200,000 to 3,000,000",
		vietnamese: "VIETNAMESE",
		eng: "ENGLISH",
		restricLeadGCM: "Please match the requested format",
		restricPassword:
			"Password must be at least 8 characters long contain a number, an uppercase letter, an lowercase letter, and special character.",
		warningMessageOfNavigation: "Please close the current conversation before navigate to other page!",
		selectedDate: "Selected Date",
		goToPage: "Go to page",
		maxDependants: "Number of children should not exceed 10",
		maxLengthTitle: "This field exceeds the maximum allowable of 100 characters",
		maxLengthSubTitle: "This field exceeds the maximum allowable of 100 characters",
		noCodeFound: "No results matches.",
		errorMessageFullName: "Please enter customer’s full name",
		alphaWithSpace: "This field contain alphabetic characters only",
		errorMessagePhoneNumber: "Please enter correct phone’s number format, for example, 0123456789",
		errorDob: "Invalid value",
		minAge: "Age must be between 18 and 99",
		maxAge: "Age must be between 18 and 99",
		invalidIssuedDate: "Invalid value",
		accidentDeathMinAmount: "Number must be greater than 100.000.000 VND",
		criticalIllnessMinAmount: "Number must be greater than 100.000.000 VND",
		requiredToInputAgentCode: "Please input Agent information",
		requiredValueGreaterThan0: "This field is required",
		invalidAmount: "Invalid amount",
		insuranceAmountIsRequired: "Please input the total sum assured amount",
		invalidDirectReferralCode: "Please input the correct employee ID of referrer",
		agentsSelected: "Agents selected",
		inputAtLeast1ExpenseField: "Must input at least 1 expense item",
		inputAtLeast1IncomeField: "Must input at least 1 income item",
		selectAtLeast1Agent: "Please select at least 1 agent!",
		fullNameMaxLength: "Full name cannot exceed 255 characters",
		errorMessageUserFullName: "Please enter Full name",
		emailContainsMissingText: "To ensure the email address is complete and accurate, please recheck and confirm.",
		directReferralAgentCodeMaxLength: " Please input equal or less than 20 characters",
		livingExpenseAmountWarningMessage: "Living expense must be equal or greater than 3.600.000đ/month (~43.000.000đ/year)",
		investmentPreferenceWarningMessage: "Please select before consulting the product",
		productIsNotSuitableToInvestmentPreference: "The product is not suitable",
		totalIncomeMinValue: "Total income must be equal or greater than 	8.750.000đ/month (~105.000.000đ/year)",
		totalExpenseInvalidAmount: "Total expense must be equal or greater than 5.000.000đ/month (~60.000.000đ/year)",
		expectedPremiumPaymentWarning: "Expected premium payment period is from 3-100 years.",
		financialYearsWarning:
			"Minimum is APE. Maximum of 30% of Total annual income, and less than or equal Annual net disposable income.",
		exceedMaxLength: "Exceed max length!",
		duplicateStaffId: "Staff ID is duplicated!",
		duplicateUsername: "Username is duplicated!",
		duplicateEmail: "Email is duplicated!",
		thisFieldIsRequired: "This field is required!",
		incorrectFormatMail: "Email is not in correct format!",
	},
}
