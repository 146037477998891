/* eslint-disable no-tabs */
import store from "../store"
import { apiMe, apiPromise } from "@covergo/cover-composables"
import { fetcherWithToken } from "../modules/auth/api/fetcher"
import getTokenAuth from "../utils/getTokenAuth"
import { i18n } from "../initialize"
let _retry = false

const getMe = async () => {
	const query = `
	fragment result on login {
		id

		permissionGroups {
			...permissionGroup
		}
		associatedUser {
			...entity
		}
		targettedPermissions{
			targetIds
			permission{
				id
			}
		}
		email
		username
		isEmailConfirmed
		createdAt
	}



	fragment entity on entityInterface {
		id
		name
		source
		internalCode
		fields
	}

	fragment permissionGroup on permissionGroup {
		id
		name
		createdAt
		targettedPermissions {
			...targettedPermission
		}
	}

	fragment targettedPermission on targettedPermission {
		permission {
			id
			name
			description
		}
		targetIds
	}

	query me {
		me {
			...result
		}
	}`
	const me = await apiPromise(apiMe, { variables: {}, queryOverride: query, fetcher: fetcherWithToken })
	store.dispatch("setMe", me)
}

const isAuthenticated = async () => {
	// Check if token key was save in localeStorage and it doesn't expired
	try {
		const token = getTokenAuth()
		if (token && !_retry) {
			_retry = true
			await getMe()
		}
		return token
	} catch (err) {
		return false
	}
}

const checkAuthGuard = (router) => {
	router.beforeEach(async (to, from, next) => {
		// const routeRoles = to.meta.roles
		const { t } = i18n.global
		const authorized = await isAuthenticated()
		const tokenExpired = store.getters.tokenExpired
		if (!authorized && !to.meta.isPublic) {
			if (tokenExpired) {
				store.dispatch("addToastMessage", {
					type: "error",
					content: {
						type: "message",
						text: t("errors.token"),
					},
				})
				store.dispatch("setTokenExpired", false)
			}
			// Store entered link into window.PATH
			// Navigate user to the page was stored after login (ViewLogin.vue)
			window.PATH = to.path
			next({ name: "ViewLogin" })
		} else {
			next()
		}
	})
}

export {
	// setupMockedAuthGuard,
	checkAuthGuard,
}
