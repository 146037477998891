<template>
	<FormInput
		class="c-checkbox"
		v-bind="{
			...computedProps,
		}"
		label=""
	>
		<template v-slot:input="{ classes, inputAttrs }">
			<div :class="classes.input">
				<CIcon v-if="isActive" :width="assets.iconWitdh" :height="assets.iconHeight" :class="classes.icon" icon="Check"></CIcon>
			</div>

			<label :for="labelFor" :class="classes.label">
				{{ computedProps.label }}
			</label>

			<input
				:class="classes.nativeInput"
				type="checkbox"
				v-model="isActive"
				v-bind="{
					...eventHandlers,
					...inputAttrs,
				}"
				ref="inputRef"
			/>
		</template>
	</FormInput>
</template>

<script setup>
import { useAttrs, computed } from "vue"

import useFormKitInput, { EMITS } from "@/composables/formKitInput.js"
import { propsFactory } from "@/helpers"
import { genVariantProps } from "@/composables/variant.js"

import CIcon from "@/modules/core/components/CIcon"

const props = defineProps({
	context: Object,
})
const emit = defineEmits([...EMITS, "update:context_value"])
const attrs = useAttrs()

const { computedProps, assets, inputRef, eventHandlers, setNodeValue, getNodeValue } = useFormKitInput(
	props.context,
	{ attrs, emit },
	{
		componentName: "CCheckbox",
	}
)
const checkedValue = computed(() => (computedProps.value.checkedValue !== undefined ? computedProps.value.checkedValue : true))
const isActive = computed({
	get() {
		return getNodeValue() === checkedValue.value
	},
	set(val) {
		setNodeValue(val ? checkedValue.value : false)
	},
})
const isDisabled = computed(() => !!computedProps.value.disabled)
const labelFor = computed(() => props.context.for || props.context.id)

defineExpose({
	isActive,
	isDisabled,
})
</script>

<script>
export default {
	genProps: propsFactory({
		...genVariantProps(),
		checkedValue: null,
	}),
}
</script>
