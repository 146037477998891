export const appEditMachine = {
	id: "appEdit",
	initial: "idle",
	states: {
		idle: {
			id: "idle",
			on: {
				FETCH: "#readingApp",
			},
		},
		readingApp: {
			id: "readingApp",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "readAppService",
						src: "readApp",
						onDone: { target: "#formFilling", actions: "setAppInfo" },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { FETCH: "fetching" },
				},
			},
		},
		formFilling: {
			id: "formFilling",
			on: {
				SAVE: { target: "#updatingApp", cond: { type: "updateGuard" } },
			},
		},
		updatingApp: {
			id: "updatingApp",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "updateAppService",
						src: "updateApp",
						onDone: { target: "#readingApp", actions: "setUpdateAppSuccess" },
						onError: { target: "#formFilling", actions: "setErrorMessage" },
					},
				},
			},
		},
	},
}
