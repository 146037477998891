export default {
	auth: {
		loginTitle: "Login iTCBLife",
		loginTo: "Login iTCBLife",
		toolName: "",
		loginSubtitle: "Enterprise Policy Admin Solution",
		email: "Email",
		password: "Password",
		login: "Login",
		forgotPassword: "FORGOT PASSWORD?",
		loginError: "Login Error",
		send: "Send",
		backToLogin: "Back To Login",
		success: "Success!",
		fail: "Fail!",
		sendMailSuccessNote: "An email has been sent to your inbox. Please follow the instruction to recover your password.",
		sendMailFailNote: "Oops something wrong. Please try again later.",
		forgotPasswordNote: "No worries! Please enter your user name bellow, we will send you a password recover email.",
		newPassword: "New Password",
		updatePasswordSuccessNote: "Your password has been update successfully.",
		updatePasswordFailNote: "Something wrong when updating your password. Please try again!",
		updatePasswordNote: "Set a new password for your account.",
		confirm: "Confirm",
		goToLogin: "Go To Login",
		confirmPassword: "Confirm Password",
		invalid_username_or_password: "Username or password is not correct",
		userNameToLogin: "User name",
		invalid_current_password: "Incorrect current password",
		userLockoutMessage: "Your account is locked. Please contact admin for more details.",
		invalid_username: "User name doesn't exist",
		invalidAccount:
			"<div>\n" +
			"    <div>Your iTCBLife account is automatically synced with the Microsoft account logged in from the device.</div>\n" +
			"    <div>The account {userId} you are using does not yet have credentials to access iTCBLife.</div>\n" +
			"    <div style='margin-left: 20px'>- If this is the RIGHT account, please contact <a style='text-decoration: underline;' href={mailTo}>{supportEmail}</a> for more support.</div>\n" +
			"    <div style='margin-left: 20px'>\n" +
			"        - If this is not the RIGHT account, please log out of Microsoft accounts from devices by using this link <a style=\"text-decoration: underline;\" href=\"https://www.office.com\">https://www.office.com</a> and log in again.\n" +
			"    </div>\n" +
			"</div>",
		systemErrorLogin: "Can not login due to network issues, please check network connection or change network/device to access",
	},
}
