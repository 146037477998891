export default {
	setComponent(state, component) {
		state.isComponent = component || "materialProduct"
	},
	setProductKey(state, key) {
		state.keyProduct = key
	},
	setKeyFAQ(state, key) {
		state.keyFAQ = key
	},
	setComponentFAQ(state, component) {
		state.isComponentFAQ = component || "materialFAQ"
	},
}
