/* eslint-disable quotes */
export default {
	pages: {
		financialNeedsAnalysis: "Home",
		library: "Library",
		// Campaign Page
		promotion: "Campaign",
		ourPromotions: "Our Promotions",
		promotionName1: "KHAI XUÂN BÌNH AN, NHẬN QUÀ NHƯ Ý (HẾT HẠN)",
		promotionheadline1: "Thời gian áp dụng: 01/01/2022 đến hết ngày 31/03/2022",
		promotionContent1: "Give money to customer's account or gift of equivalent value",
		promotionName2: "ƯU ĐÃI DÀNH CHO CÁN BỘ, NHÂN VIÊN THAM GIA SẢN PHẨM BẢO HIỂM NHÂN THỌ (HẾT HẠN)",
		promotionheadline2: "Thời gian áp dụng: 01/01/2022 đến hết ngày 31/12/2022",
		promotionContent2:
			"Get instant cashback up to 35%/Premium. Join Techcombank to choose a solid lifestyle for the journey to experience and enjoy life to the fullest!",
		// Product Page
		product: "Product",
		ourProducts: "Our Products",
		productLibrary: "Product library",
		downloadBrochure: "Download Brochure",
		product1Name: "Titanium",
		product1Headline: "Titanium",
		product1Content:
			" You will feel secure when life is protected and assets are accumulated over 15 years with a premium period of only 6 years or 10 years, An Gia Nhu Y - the preeminent solution combining insurance and savings in Same product, delivering financial peace of mind in every stage of your life.",
		product2Name: "Unit Link",
		product2Headline: "Unit Link",
		product2Content: "With Unit Link, firmly in every step forward to the future as well as enjoy every peaceful moment of life.",
		product3Name: "ILP (Stop distributing)",
		product3Headline: "ILP",
		product3Content:
			"With ILP, you can invest in increasing assets easily and conveniently thanks to the support of experts; at the same time, financial plans are still maintained against risks in life.",
		product4Name: "Edu (Stop distributing)",
		product4Headline: "Edu",
		product4Content:
			"Getting married and having children are important events that open up a wonderful new page in your life. With the wish to join your wings for high-flying children's dreams, Manulife and Techcombank are honored introduces Edu solution with many special benefits.",
		mainProduct: "Main products",
		mainProductContent: "Product brochures and terms & conditions",
		riders: "Riders",
		ridersContent: "Terms & conditions",
		search: "Search",
		productBrochure: "Brochure",
		rulesTermsSavings: "Product rules and terms - Savings",
		rulesTermsProtection: "Product rules and terms - Protection",
		annualReport: "LKC Fund Annual Report 2020",
		// Forms Page
		referenceMaterial: "Reference material",
		requestForm: "Forms",
		requestForm0112: "Manulife forms",
		requestForm0210: "Techcombank Forms for individuals",
		appraisalDocuments: "Appraisal documents",
		handbookOfContractAppraisalAndIssuance: "Handbook on evaluation and contract issuance",
		instructionsToOvercomeCommonEvaluationErrors: "Instructions to overcome common evaluation errors when submitting Application",
		contactPointToResolveIssues: "Contact point to resolve issues",
		customerContractsPage: "Customer's Policy Page",
		customerContractsPageLink: "https://hopdongcuatoi.manulife.com.vn/login",
		customerHandbook: "Customer Handbook",
		customerHandbookLink: "https://www.manulife.com.vn/vi/dich-vu/so-tay-khach-hang.html",
		unitPriceOfManulifeFund: "Unit price of Manulife Fund",
		unitPriceOfManulifeFundLink: "https://www.manulife.com.vn/vi/san-pham-dau-tu/bao-hiem-dau-tu/gia-don-vi-quy.html",
		interestIndicators: "Manulife Product Interest Indicators",
		interestIndicatorsLink: "https://www.manulife.com.vn/vi/bao-hiem-tiet-kiem/cac-chi-so-lai-suat.html",
		QLBHTheGift: "Hospital fee guarantee",
		QLBHTheGiftLink: "https://insmart.com.vn/lien-he/",
		listOfClinicsNationwide: "List of clinics",
		listOfClinicsNationwideContent: "List of clinics (Updated October 2020)",
		fundReport: "Báo cáo thường niên Quỹ",
		// Forms FAQ
		faq: "FAQ",
		faqFrequentlyAccountAccess: "FAQ about access and accounts",
		accountAccessDevicesUsed: "On what devices can this tool be used?",
		accountAccessBrowsersUsed: "Công cụ này có thể sử dụng trên những trình duyệt nào?",
		accountAccessBrowsersUsedContent1:
			"Có thể sử dụng iTCBLife trên trình duyệt Chrome/ Firefox/ Safari/Microsoft Edge, KHÔNG sử dụng được trên trình duyệt Internet Explorer.",
		accountAccessBrowsersUsedContent2:
			"Trong trường hợp không truy cập được bằng trình duyệt đang sử dụng, vui lòng chuyển sang sử dụng bằng các trình duyệt khác.",
		userAndpassword: "User và mật khẩu đăng nhập của tôi là gì?",
		userAndpasswordContent1: "User: email Techcombank",
		userAndpasswordContent2: "Mật khẩu: mật khẩu đăng nhập máy tính",
		forgotMyPassword: "Trường hợp nếu tôi quên mật khẩu cần làm thế nào?",
		forgotMyPasswordContent1: "Nếu người dùng quên mật khẩu thì gửi yêu cầu reset lên IT Support",
		iPadDeviceForConsultation: "Khi sử dụng chung thiết bị iPad để tư vấn, tôi cần phải lưu ý những gì?",
		iPadDeviceForConsultationContent1: "Sau khi sử dụng, TVV lưu ý phải đăng xuất khỏi iTCBLife & tài khoản Microsoft",
		accountAccessUser: "Những ai được cung cấp user để thực hiện tư vấn trên iTCBlife?",
		accountAccessUserContent1: "Lực lượng tư vấn (PRM, RBO/SRBO, TIS) đã được cấp mã đại lý bảo hiểm của Manulife",
		accountAccessRegister: "Khi nào tôi sẽ được đăng ký user?",
		accountAccessRegisterContent1:
			"iTCBLife đăng ký định kỳ hàng tháng theo danh sách cập nhật từ Nhân sự (HR) với các bạn đã được cấp mã đại lý bảo hiểm của Manulife. Khi đăng ký thành công, TVV sẽ nhận được email thông báo từ hòm thư {email}",
		accountAccessDevicesUsedContent: "Ứng dụng có thể truy cập trên các thiết bị sau:",
		accountAccessDevicesUsedContent1: "- iPad, điện thoại thông minh",
		accountAccessDevicesUsedContent2:
			"- Laptop và máy tính bàn (Trường hợp sử dụng mạng ngoài TCB (public wifi) thì cần kết nối VPN)",

		faqInsuranceConsultationITCBLife: "Câu hỏi thường gặp về luồng tư vấn bảo hiểm trên iTCBlife",
		errorStep1: "Tại Bước 1, khi tìm kiếm thông tin Khách hàng, hệ thống báo lỗi thì tôi phải làm gì?",
		errorStep1Content1:
			"Trong trường hợp này, TVV thoát khỏi Bước 1 (Đóng hội thoại) và quay trở lại Trang chủ. Thực hiện tìm kiếm lại tại Bước 1.",
		howToCreateLead: "Làm cách nào để tạo/ gắn lead CRM với cuộc hội thoại iTCBlife?",
		howToCreateLeadContent: "TVV có thể thực hiện tạo/gắn lead CRM theo 1 trong các cách như sau:",
		howToCreateLeadContent1:
			"1. Bước 1 (Màn hình Thông tin khách hàng): Tìm kiếm khách hàng, chọn lead phù hợp trong danh sách lead được hiển thị",
		howToCreateLeadContent2:
			"2. Trường hợp chưa gắn lead tại bước 1, thực hiện tạo lead tại bước 7 (Màn hình Kết thúc) hoặc tại màn hình Dừng cuộc hội thoại giữa chừng",
		howToCreateLeadContent3:
			"3. Trường hợp chưa tạo/gắn lead theo 2 cách nêu trên, TVV có thể truy cập Màn hình Truy vấn và thực hiện gắn lead",
		howToCreateLeadContent4: "(Chi tiết tao tác thực hiện vui lòng xem hướng dẫn sử dụng iTCBLife)",
		saveInStep2: "Khi lựa chọn luồng tắt (shortcut) bằng cách ấn 'Lựa chọn giải pháp' tại bước 2 cần lưu ý những điểm gì?",
		saveInStep2Content1: "- Hệ thống sẽ đưa ra giải pháp tại Bước 6 với giá trị bảo vệ mặc định là 1 tỷ đồng",
		saveInStep2Content2:
			"- Sau khi khách hàng đồng ý với giải pháp, TVV cần kiểm tra/ điền lại các thông tin của khách hàng từ bước 3 'Am Hiểu Mục Tiêu'",
		customerWantsToAdjustBenefits: "Nếu Khách hàng muốn điều chỉnh quyền lợi thì TVV có thể làm gì?",
		customerWantsToAdjustBenefitsContent:
			"TVV có thể hỗ trợ Khách hàng điều chỉnh các giá trị sau tại chức năng Tùy chỉnh ở Bước 6:",
		customerWantsToAdjustBenefitsContent1: "- Thay đổi giá trị bảo vệ",
		customerWantsToAdjustBenefitsContent2: "- Thêm/bớt, điều chỉnh giá trị quyền lợi bổ trợ",
		customerWantsToAdjustBenefitsContent3: "- Thời gian thanh toán (Áp dụng riêng đối với An gia Như ý)",
		iTCBifeTool: "Khi sử dụng công cụ iTCBife , tư vấn viên có cần upload file Phân tích nhu cầu tài chính (FNA) lên ePOS không?",
		iTCBifeToolContent1:
			"Không, hệ thống sẽ tự động chuyển FNA đã thực hiện trên iTCBLife sang phần Tài liệu đính kèm trên Epos.",
		iTCBifeToolContent2: "KH không cần cung cấp chữ ký tươi đối với file FNA này.",
		TVVEmail: "Những trường hợp nào thì TVV nhận được email về cuộc hội thoại với Khách hàng?",
		TVVEmailContent: "Có 2 trường hợp:",
		TVVEmailContent1: "1. TVV chọn 'Kết thúc' thành công buổi tư vấn ở bước 7.",
		TVVEmailContent2: "2. TVV dừng giữa chừng buổi tư vấn, chọn 'Thoát và Lưu' thông tin cuộc hội thoại thành công",
		customerEmail: "Trường hợp nào KH nhận được Email/SMS từ hệ thống iTCBLife?",
		customerEmailContent:
			"Sau khi kết thúc cuộc tư vấn trên iTCBlife & đẩy thông tin sang hệ thống Manulife, KH sẽ nhận được email/SMS nếu KH thuộc 1 trong các trường hợp sau:",
		customerEmailContent1: "- KH có tài khoản mở tại TCB trước 5/10/2013 và không có FMB/TCB Mobile",
		customerEmailContent2: "- Có FMB/TCB Mobile mở trước ngày 5/10/2013 và không có tài khoản tại TCB",
		customerEmailContent3: "- Có tài khoản tại TCB và FMB/TCB Mobile mở trước ngày 5/10/2013",
		customerEmailContent4: "- Khách hàng không có tài khoản và FMB/TCB Mobile",
		customerEmailContent5: "- Khách hàng mới",
		iquiryScreen: "Trên màn hình 'Truy vấn', có thể thực hiện những thao tác gì?",
		iquiryScreenContent: "Có thể thực hiện các thao tác sau:",
		iquiryScreenContent1:
			"- Tìm kiếm cuộc hội thoại theo Ngày thực hiện, Mã hội thoại, Trạng thái, Kết quả tư vấn, Mã Lead CRM, Thông tin khách hàng",
		iquiryScreenContent2: "- Xem chi tiết cuộc hội thoại và tiếp tục các cuộc hội thoại còn dang dở",
		iquiryScreenContent3: "- Tạo/Gắn mã lead CRM với các cuộc hội thoại chưa có mã lead",
		iTCBLifeSupport: "iTCBLife có hỗ trợ tạo Bảng Minh Họa Quyền Lợi Sản Phẩm không?",
		iTCBLifeSupportContent:
			"iTCBLife chỉ  hỗ trợ tạo Bảng Minh Họa Quyền Lợi rút gọn với Sản phẩm An Gia Như ý. Hiện chưa có Bảng minh họa cho các sản phẩm còn lại.",
		createLeadInvite: "Có thể tạo lead gắn với chiến dịch trên iTCBLife ko?",
		createLeadInviteContent:
			"Hiện tại iTCBLife chưa hỗ trợ tạo lead gắn với chiến dịch kinh doanh. Trường hợp muốn gắn lead với chiến dịch, TVV vui lòng tạo lead trên CRM, gắn với đúng chiến dịch, để trạng thái 'KH hẹn gặp' hoặc 'KH đang cân nhắc'.  Sau đó, TVV truy cập iTCBlife và chọn lead đó để tiếp tục tư vấn",

		faqRuleAndChange: "FAQ about product rules and how to charge",
		solutionScreen: "Tại sao tại màn hình 'Giải pháp' bước 6 hệ thống báo không có sản phẩm phù hợp với khách hàng?",
		solutionScreenContent1:
			"- Vì mỗi sản phẩm có quy định về độ tuổi, giá trị bảo vệ, người tham gia… khác nhau nên trường hợp nếu thông tin Khách hàng điền không phù hợp với quy định các sản phẩm khác, Hệ thống  sẽ báo chưa có sản phẩm phù hợp với Khách hàng.",
		solutionScreenContent2: "- TVV cần Xem lại thông tin Khách hàng đã điền và điều khoản điều kiện sản phẩm để chỉnh sửa",
		customerAgeLessThan18: "Tại sao khi nhập tuổi của khách hàng nhỏ hơn 18 tuổi, hệ thống không đưa ra đề xuất sản phẩm nào?",
		customerAgeLessThan18Content1:
			"Theo quy định của Luật, chủ hợp đồng phải đủ 18 tuổi trở lên. Vì vậy, nếu khách hàng nhỏ hơn 18 tuổi, hệ thống sẽ không đưa ra đề xuất sản phẩm nào phù hợp. Riêng đối với sản phẩm An Gia Như Ý, độ tuổi của chủ hợp đồng phải từ 18-50 tuổi",
		policyholderAgeRegulation:
			"Quy định về tuổi của chủ hợp đồng đối với mục tiêu giáo dục là gì? Tại sao tôi chọn tuổi của chủ hợp đồng là 18 tuổi mà hệ thống vẫn không đề xuất ra sản phẩm nào?",
		policyholderAgeRegulationContent1:
			"Theo quy định của luật, tuổi kết hôn là 18 đối với nữ và 20 tuổi đối với nam. Do vậy, cần đảm bảo người bố/mẹ (Chủ hợp đồng) có con sau 2 mức tuổi nêu trên.",
		policyholderAgeRegulationContent2:
			"Ví dụ: Phụ nữ kết hôn năm 18 tuổi thì 19 tuổi là tuổi bắt đầu có con. Nếu số tuổi hiện tại của con là 3, thì người mẹ ít nhất phải là 21 thì mới đúng quy định của luật và hệ thống mới hiển thị sản phẩm phù hợp.",
		policyholderAgeRegulationContent3:
			"Tương tự với nam giới, nếu kết hôn năm 20 tuổi, thì 21 tuổi bắt đầu có thể có con. Nếu số tuổi hiện tại của con là 3, thì người cha ít nhất phải là 24 thì mới đúng quy định của luật và hệ thống mới hiển thị sản phẩm phù hợp",
		theSystemRecommendsProducts:
			"Tại sao hệ thống có đề xuất sản phẩm An Gia Như Ý nhưng khi click vào biểu tượng tạo bảng minh họa thì ko thể tạo được bảng minh họa?",
		theSystemRecommendsProductsContent:
			"Hiện tại, hệ thống chỉ hỗ trợ tạo Bảng Minh Họa trong trường hợp Số tiền bảo vệ nằm trong khoảng 100 triệu - 5 tỷ đồng. Do vậy, các trường hợp khác sẽ ko hiển thị lên BMH. Việc bổ sung sẽ được thực hiện trong các giai đoạn tiếp theo",
		aggregateStep:
			"Tại bước 'Tổng hợp' làm thế nào để hệ thống tính ra số tiền 'Mục Tiêu', 'Tiết kiệm', 'Thiếu hụt' của khách hàng?",
		aggregateStepContent1:
			"- Để tính ra số tiền 'Mục Tiêu' và 'Tiết kiệm' ,  hệ thống căn cứ theo thông tin khách hàng nhập ở bước 3 và 4 và theo công thức tính giá trị tương lai với số năm khách hàng cần sử dụng tiền hoặc tiết kiệm và theo mức lãi suất/ lạm phát dự kiến",
		aggregateStepContent2: "- Số tiền 'Thiếu hụt' = 'Mục tiêu' - 'Tiết kiệm' - 'Bảo hiểm'",
		aggregateStepContent3:
			"- Khách hàng có thể điều chỉnh số tiền bằng thay đổi các thông tin nhu cầu, số tiền tiết kiệm, bảo hiểm, lãi suất, lạm phát trên màn hình nếu cần",
		notedAboutPolicyholder: "Cần lưu ý gì về thông tin của Chủ hợp đồng và Người được bảo hiểm trên iTCBlife?",
		notedAboutPolicyholderContent1:
			"1. Đối với An gia Như ý, Bách lộc toàn gia vượt trội, Đắc lộc tâm an và SuperLink: Khách hàng vừa là chủ hợp đồng, vừa là Người được bảo hiểm.",
		notedAboutPolicyholderContent2: "2. Đối với An nhi Bảo phúc Ưu việt (EDU):",
		notedAboutPolicyholderContent2Sub1: "+ Khách hàng là chủ hợp đồng, con Khách hàng là Người được bảo hiểm thứ nhất",
		notedAboutPolicyholderContent2Sub2:
			"+ EDU chưa được áp dụng đối với trường hợp Khách hàng muốn bảo hiểm cho hơn một người con",
		notedAboutPolicyholderContent3:
			"=>  Do đó, thông tin của Người được bảo hiểm và Chủ hợp đồng sẽ được dựa trên những giả định này. Nguyên tắc sẽ được áp dụng theo điều kiện tương ứng của từng sản phẩm.",
		hospitalRoomClassOf750:
			"Tại sao khi tôi chọn hạng phòng nằm viện là 750 nghìn/ ngày, phần tổng quyền lợi cho hạng mục này tại Bước 6 lại là 125 triệu?",
		hospitalRoomClassOf750Content1:
			"Ngoài chi phí giường phòng với giới hạn 750 nghìn/ ngày, quyền lợi bổ trợ này còn có các hạng mục khác như xét nghiệm, phẫu thuật. Tổng quyền lợi lên đến 125 triệu.",
		TVVStep6: "Tại Bước 6, TVV cần lưu ý gì về Chi phí Hỗ trợ y tế ?",
		TVVStep6Content: "Tùy thuộc vào mức phí bảo hiểm KH tham gia để đưa ra quyền lợi trợ cấp y tế/nằm viện:",
		TVVStep6Content1: "• Không xét APE: ≤ 1 triệu",
		TVVStep6Content2: "• ≥ 20 triệu: < 2 triệu",
		TVVStep6Content3: "• ≥ 40 triệu: < 3 triệu",
		TVVStep6Content4: "• ≥ 80 triệu: 3 triệu",
		theSystemSuggestInsuranceValue: "Cách hệ thống đề xuất ra giá trị bảo hiểm ở phần giải pháp?",
		theSystemSuggestInsuranceValueContent:
			"Căn cứ vào số thiếu hụt ở Bước Tổng hợp, hệ thống sẽ đề xuất ra số tiền bảo hiểm tương ứng. Cụ thể:",
		theSystemSuggestInsuranceValueContent1:
			"- Trường hợp 1: Số tiền thiếu hụt > Tổng (Số tiền tích lũy + Số tiền bảo hiểm đã có), hệ thống sẽ đề xuất giải pháp có số tiền bảo hiểm bằng với số tiền thiếu hụt",
		theSystemSuggestInsuranceValueContent2:
			"- Trường hợp 2: Số tiều thiếu hụt < Tổng (Số tiền tích lũy + Số tiền bảo hiểm đã có), hệ thống sẽ đề xuất giải pháp có số tiền bảo hiểm bằng với số tiền tích lũy.",
		sumMoneyEdu: "Cách hệ thống tính ra số tiền mục tiêu 'Giáo dục'",
		sumMoneyEduContent:
			"Số tiền mục tiêu Giáo dục được tính dựa trên tổng mức học phí đại học/ sau đại học mà khách hàng cần cho con bao gồm lạm phát giáo dục. Lạm phát giáo dục tính từ thời điểm tư vấn KH đến thời điểm KH cần sử dụng Quỹ.",
		sumMoneyIncreaseAssets: "Cách hệ thống tính ra số tiền mục tiêu 'Gia Tăng Tài sản'",
		sumMoneyIncreaseAssetsContent:
			"Số tiền mục tiêu Gia tăng Tài sản được tính bằng trên số tiền tích lũy KH mong mong đạt được trong tương lai.",
		sumMoneyIncomeProtection: "Cách hệ thống tính ra số tiền mục tiêu 'Bảo vệ thu nhập'",
		sumMoneyIncomeProtectionContent:
			"Số tiền mục tiêu 'Bảo vệ thu nhập' bằng tổng giá trị trong tương lai của số chi phí hàng tháng cần đảm bảo * số năm cần hỗ trợ sinh hoạt phí và dư nợ hiện tại",
		sumMoneyHealthCare: "Cách hệ thống tính ra số tiền mục tiêu 'Chăm sóc sức khỏe'",
		sumMoneyHealthCareContent:
			"Số tiền mục tiêu 'Chăm sóc sức khỏe' được tính bằng tổng các nhu cầu của KH về Trợ cấp y tế, Chi phí y tế, Thương tật do tai nạn,  Điều trị bệnh hiểm nghèo. Trong đó:",
		sumMoneyHealthCareContent1: "- Trợ cấp y tế: Mức trợ cấp mất giảm thu nhập/ngày *1000 ngày",
		sumMoneyHealthCareContent2:
			"- Chi phí y tế: Số tiền bảo hiểm (nội trú) căn cứ theo hạng phòng BV khách hàng lựa chọn (Bạc, titan, vàng, bạch kim, kim cương)",
		sumMoneyHealthCareContent3: "- Thương tật và tai nạn: Căn cứ theo nhu cầu của KH",
		sumMoneyHealthCareContent4: "- Điều trị bệnh hiểm nghèo: Căn cứ theo nhu cầu của KH",
		sumMoneyPeacefulRetirement: "Cách hệ thống tính ra số tiền mục tiêu 'Hưu trí an nhàn'",
		sumMoneyPeacefulRetirementContent:
			"Số tiền mục tiêu 'Hưu trí an nhàn' bằng Giá trị trong tương lai của chi phí hàng tháng * số năm cần hỗ trợ chi phí sau sau khi nghỉ hưu",
		// Guidance Page
		guidance: "Guidance",
		ourGuidance: "Our Guidance",
		guidanceiTCBLife: "Guide for using iTCB Life",
		guideToStopConversation: "To stop the conversation midway",
		guideTheBriefFlow: "Guide for the brief flow",
		guideTheStraightFlow: "Guide for the fully flow",
		guideTheEditFlow: "Guide for the editing flow",
		// Contact Page
		contact: "Contact",
		contactUs: "Contact Us",

		contactTechcombank: "Đầu mối hỗ trợ tại techcombank",
		contactTechcombankOption1: "Dự án số hóa Banca - iTCBlife",
		contactTechcombankOption1Content: "Hỗ trợ giải đáp thông tin, hướng dẫn sử dụng liên quan hệ thống iTCBlife",
		contactTechcombankOption2: "BỘ PHẬN HỖ TRỢ KHIẾU NẠI DỊCH VỤ SAU BÁN",
		contactTechcombankOption2Content: "Tiếp nhận, giải quyết các trường hợp khách hàng khiếu nại, khiếu kiện…",
		contactTechcombankOption3: "BỘ PHẬN HỖ TRỢ VỀ SẢN PHẨM/CHƯƠNG TRÌNH ƯU ĐÃI",
		contactTechcombankOption3Content: "Giải đáp thông tin về các sản phẩm bảo hiểm và chương trình ưu đãi cho KH",
		contactTechcombankOption4: "BỘ PHẬN HỖ TRỢ VỀ ĐÀO TẠO",
		contactTechcombankOption4Content1: "- Giải đáp về kế hoạch dào tạo MIT/Cấp code",
		contactTechcombankOption4Content2: "- Giải đáp về AWS (Chạy Bảng minh họa/Code chung)",
		contactTechcombankOption4Content3: "- Các khóa học đào tạo online trên Manulearn",
		contactTechcombankOption5: "ĐẦU MỐI HỖ TRỢ NHẬP LIỆU TRÊN AWS",
		contactTechcombankOption5Content: "",
		contactTechcombankOption6: "BỘ PHẬN HỖ TRỢ GIẢI ĐÁP VỀ CHÍNH SÁCH KPI",
		contactTechcombankOption6Content: "Thông báo, điều chỉnh, giải đáp về chính sách KPI",
		contactTechcombankOption7: "BỘ PHẬN HỖ TRỢ VỀ TECHCOMBANK APP",
		contactTechcombankOption7Content1: "Truy vấn HĐBH trên { name } Mobile/Techcombank mobile",
		contactTechcombankOption7Content2: "Thông tin thanh toán phí, trích phí tự động trên { name } Mobile/Techcombank mobile",

		contactMVL: "Đầu mối hỗ trợ Manulife",
		contactMVLOption1: "ĐẦU MỐI HỖ TRỢ CHUNG",
		contactMVLOption1Content: "Các vấn đề liên quan đến nhập dữ liệu lên AWS, hợp đồng in lại, in thẻ y tế…)",
		contactMVLOption1ContentHotline: "Hotline cho Tư vấn viên Techcombank : { phone } (#9 > #5)",
		contactMVLOption2: "BỘ PHẬN HỖ TRỢ KÊNH PHÂN PHỐI BH",
		contactMVLOption2Content1: "- Thực hiện hỗ trợ công việc hành chính",
		contactMVLOption2Content2: "- Giải đáp thắc mắc cho NVNH liên quan đến thông tin/tình trạng hợp đồng..",
		contactMVLOption2Content3: "- Nhận hồ sơ, đăng ký học MIT cho NVNH",
		contactMVLOption2ContentHotline: "Hotline: { phone } (#9 > #5)",
		contactMVLOption3: "BỘ PHẬN THẨM ĐỊNH HĐ MỚI",
		contactMVLOption3Content: "Thực hiện thẩm định HSYCBH bao gồm đơn YCBH và các giấy tờ liên quan khác",
		contactMVLOption4: "BỘ PHẬN GIẢI QUYẾT KHIẾU NẠI",
		contactMVLOption4Content: "Tiếp nhận, giải quyết các trường hợp khách hàng khiếu nại, khiếu kiện…",
		contactMVLOption5: "BỘ PHẬN GIẢI QUYẾT QLBH CHO KH",
		contactMVLOption5Content: "Thực hiện giải quyết QLBH cho KH khi xảy ra sự kiện bảo hiểm",
		contactMVLOption5ContentHotline: "Hotline cho Tư vấn viên Techcombank : { phone } (#9 > #2)",
		contactMVLOption6: "BỘ PHẬN ĐIỀU CHỈNH HỢP ĐỒNG",
		contactMVLOption6Content1: "Áp dụng cho cả Khách hàng và TVV",
		contactMVLOption6Content2: "Thực hiện ghi nhận phí thanh toán cho HĐ",
		contactMVLOption6Content3: "Thực hiện điều chỉnh, HĐBH, thanh toán các quyền lợi cho NĐBH...",
		contactMVLOption7: "ĐẦU MỐI LIÊN HỆ KHI CẦN BẢO LÃNH VIỆN PHÍ",
		contactMVLOption7Content: "Liên hệ Công ty TNHH Insmart - Bộ phận Chăm sóc Khách hàng",
		contactMVLOption7ContentHotline: "Hotline: { phone }",
		contactMVLOption8: "ĐẦU MỐI LIÊN HỆ CHO KHÁCH HÀNG THAM GIA BH QUA TECHCOMBANK",
		contactMVLOption8Content: "",
		contactMVLOption8ContentHotline: "Hotline cho Tư vấn viên Techcombank : { phone } (bấm phím 1, sau đó bấm phím 1)",
		contactMVLOption9: "BỘ PHẬN DỊCH VỤ KHÁCH HÀNG",
		contactMVLOption9Content:
			"Tiếp nhận hồ sơ yêu cầu bảo hiểm, các giấy tờ bổ sung - Tư vấn, giải đáp mọi thắc mắc của KH liên quan đến HĐBH",
		contactMVLOption10: "HỖ TRỢ EPOS/AWS/MANULEARN",
		contactMVLOption10Content: "Hỗ trợ giải đáp các lỗi, thông tin liên quan hệ thống ePOS/AWS/Manulearn",
		contactMVLOption10ContentHotline: "Hotline: { phone }",
		contactMVLOption11: "HỖ TRỢ POS",
		contactMVLOption11Content1: "- Máy hỏng phần mềm, lỗi kỹ thuật, hết mực, hết giấy",
		contactMVLOption11Content2: "- Máy hỏng, rơi vỡ, thất lạc",
		contactInfoSub: "Digital BANCA project team:",
		// About Us Page
		aboutUs: "About Us",
		aboutTcb: "About Techcombank",
		tcbMission: "Message from Techcombank’s General Director",
		tcbMsgFromVicePresident: "Message from Techcombank’s Deputy Director",
		aboutManulife: "About Manulife",
		tcbHeadline:
			"Techcombank is one of the leading commercial banks in Vietnam with a sustainable financial foundation and outstanding product - service portfolio.",
		visionHeadline: "Vision",
		visionDetail: "Techcombank aspires to be the best bank and a leading business in Vietnam.",
		missionHeadline: "Missions",
		missionDetail1:
			"To be the preferred and most trusted financial partner of our customers, providing them with a full range of financial products and services through a personalized/customer centric relationship.",
		missionDetail2:
			"To provide our employees with a great working environment where they have multiple opportunities to develop, contribute, and build a successful career.",
		missionDetail3:
			"To offer our shareholders superior long term returns by executing a fast growth strategy while enforcing rigorous corporate governance and risk management best practices.",
		coreValueHeadline: "Core Values",
		coreValueStatement:
			"Techcombank’s “corporate culture” is based on 5 core values which create strength for Techcombank and is the foundation for the organization’s sustainable development, as well as bring greater success for customers.",
		coreValue1Headline: "1. Customer centricity",
		coreValue1Quote: '"Because we only succeed when customers succeed"',
		coreValue1Detail:
			"Techcombank always put ourselves in customers’ position in every thought and action to bring the best benefits and experience for customers. We protect our customers' interests by always complying with the laws and the Bank’s regulations.",
		coreValue2Headline: "2. Innovation & Creativeness",
		coreValue2Quote: '"To always lead"',
		coreValue2Detail:
			"All Techcomers are always ready to lead the change to create new results with new working ways, and great results with breakthrough ways.",
		coreValue3Headline: "3. Collaboration for common objectives",
		coreValue3Quote: '"To form a collective strength for a sustainable development and greater success with the Bank"',
		coreValue3Detail: "Techcombank's strength is created from a collective with the foundation of unity in thinking, in action.",
		coreValue4Headline: "4. Self-development",
		coreValue4Quote: '"To be able to grasp the opportunity of development with the Bank"',
		coreValue4Detail:
			"Techcomers always actively learn to improve their capabilities and to continually set higher goals for themselves along with the development and success of the Bank. Techcombank also creates conditions and opportunities for the staff to develop and to succeed.",
		coreValue5Headline: "5. Work efficiently",
		coreValue5Quote: '"To bring about greater success with suitable resources"',
		coreValue5Detail:
			'With the goal of "All actions are directed towards specific and clear results", we always work with a plan and discipline in implementation, as well as ensure resources are allocated optimally.',
		tcbFullName: "Vietnam Technological and Commercial Joint Stock Bank Head Office",
		tcbAddress: "Techcombank Tower, 191 Ba Trieu, Hai Ba Trung District, Ha Noi",
		tcbTel: "Tel: +84 (24) 3944 6368",
		tcbFax: "+84 (24) 3944 6395",
		tcbSwiftCode: "SWIFT code: VTCBVNVX",
		bankingHourTitle: "Banking hours",
		bankingHourMonToFri: "8:00am to 5:00pm Monday to Friday",
		bankingHourSat: "8:00am to 12:00pm on Saturday",
		// Inquiry
		inquiry: "Conversation Inquiry",
		manulifeContent: `Manulife Vietnam is the first foreign life insurer to be present in Vietnam since 1999 and is currently the largest Life Insurance Company in Vietnam.
		In September 2017, Manulife and Techcombank officially announced the signing of an exclusive cooperation agreement to distribute Manulife insurance products through the TCB network lasting 15 years.`,
		product5Name: "Titanium 2.0",
		product5Headline: "Titanium 2.0",
		product5Content:
			"Looking forward to accompanying you on your journey to a sustainable prosperous future, Techcombank and Manulife offer you an Investment Linked Insurance SUPERLINK, an outstanding investment insurance solution which commits to bring you outstanding benefits.",
		// Following text of tcb-1.5 (terminal)
		product1TC: "Terms & Conditions",
		product1AnnualReport: "2020 Annual report Universal Life",
		product2TCSaving: "Terms & Conditions , Saving",
		product2TCProtection: "Terms & Conditions , Protect",
		product2AnnualReport: "2020 Annual report Universal Life",
		unitLinkedTC: "Terms & Conditions",
		unitLinkedAnnualReport: "2020 Annual report  Investment Link",
		unitLinkedUnitPrice: "Unit Price updated",
		eduTC: "Terms & Conditions",
		superLinkTC: "Terms & Conditions",
		superLinkAnnualReport: "Annual report UL 2020",
		superLinkUnitPrice: "Unit Price updated",
		criticalIllness: "Critical Illness",
		criticalIllnessUDR: "Critical Illness UDR",
		criticalIllnessPlusUDR: "Critical Illness Plus UDR",
		criticalIllnessShortTerm: "Critical illness",
		cancerUDR: "Cancer UDR",
		medicalCash: "Medical support",
		medicalCashUDR: "Medical Cash UDR",
		medicalCashPlus: "Medical Cash Plus",
		medicalCash2014: "Medical Cash 2014",
		medicalCashShortTerm: "Medical Cash",
		accidentDeathAndDisablement: "Accidental dismemberment coverage",
		accidentDeathAndDisablementUDR: "Accident death and disablement - UDR",
		accidentDeathAndDisablementRPD: "Advanced accidental death and disability insurance - Risk premium deductible",
		accidentDeathAndDisablementShortTerm: "Accidental death and disability short-term premium",

		premiumWaive: "Premium Wave",
		premiumWaiveDeductBMBH: "Bảo hiểm hỗ trợ đóng phí cho BMBH hoặc người hôn phối - Khấu trừ phí rủi ro",
		premiumWaiveSpouse: "Bảo hiểm hỗ trợ đóng phí cho NĐBH - Khấu trừ phí rủi ro",
		premiumWaiveWaiveDeduction: "Bảo hiểm miễn nộp phí",
		premiumWaiveCIAnnualRenew: "Bảo hiểm miễn nộp phí đặc biệt",
		premiumWaiveTDAnnualRenew: "Bảo hiểm miễn nộp phí cho bên mua bảo hiểm",
		premiumWaiveTDCIAnnualRenew: "Bảo hiểm miễn nộp phí đóng phí ngắn hạn",
		premiumWaiveForPO: "Bảo hiểm hỗ trợ đóng phí do BLNT của NĐBH",

		healthcare: "Healthcare",
		healthcareTC: "Terms & Conditions",
		healthcareBrochure: "eBook",
		promotionName3: "ƯU ĐÃI DÀNH CHO CÁN BỘ NHÂN VIÊN CỦA TẬP ĐOÀN VINGROUP, MASAN, ONE MOUNT (HẾT HẠN)",
		promotionheadline3: "Thời gian áp dụng: 14/01/2022 đến hết ngày 14/04/2022",
		promotionContent3: "Exclusive offers for partners' employees.",

		TotalPermanentDisability: "Financial support with Total permanent disability",
		TotalPermanentDisabilityShortTermFeePayment: "Bảo hiểm thương tật toàn bộ vĩnh viễn - Đóng phí ngắn hạn",
		TotalPermanentDisabilityDeductTheRiskFee: "Bảo hiểm thương tật toàn bộ vĩnh viễn - Khấu trừ phí rủi ro",

		DeathBenefitCoverage: "Death benefit coverage",
		DeathBenefitCoverageShortTermFeePayment: "Bảo hiểm tử vong - Đóng phí ngắn hạn",
		DeathBenefitCoverageLimitTime: "Bảo hiểm tử vong có thời hạn",
		// promotion page
		promotionName4: "ƯU ĐÃI NGẬP TRÀN, YÊU THƯƠNG VẸN TOÀN (HẾT HẠN)",
		promotionheadline4: "Thời gian áp dụng: 01/04/2022 đến hết ngày 30/06/2022",
		promotionContent4: `Let's share the love to your family and loved ones with Techcombank life insurance products. More specifically, from April 1, 2022, when participating in life insurance, you will receive a discount of up to 20% of the insurance premium.`,

		prenatalTitle: "Bảo hiểm trợ cấp thai sản",

		// Legacy (updated on July 2022)
		legacyProductName: "Legacy",
		legacySubtitle: "Comprehensive fulcrum – For thousand steps forward",
		legacyContent:
			"Flexible financial investment solution, provision for health and wealth; support you moving forward with firmly fulcrum.",
		legacyAnnualReport: "Annual report UL 2021",

		// Material Reference
		mainMaterialReference: "Successful Usecase material",
		introductionMaterialReference: "Introduction about material reference",
		introductionVideo: "Introduction video",
		typicalCases: "Typical cases",
		bancaLending: "01. BANCA LENDING - Nguyen Hai Dang - AFF - Business Owner - 2.5 bil VND",
		bancaLendingOption1: "Persona & Consultation Guidance",
		bancaLendingOption2: "Illustration",
		bancaLendingOption3: "Summary video",
		bancaMH: "02. BANCA MH - Luu Quang Phuong - AFF - Business Owner - 922mil VND (3 policies)",
		bancaMHOption1: "Persona & Consultation Guidance",
		bancaMHOption2: "Illustration",
		bancaMHOption3: "T&C Masterise Homes v.3 (1.12.2021)",
		bancaMH02: "03. BANCA MH - Tran Kieu Loan - AFF - Business Owner - 450mil VND (2 policies)",
		bancaMH02Option1: "Persona & Consultation Guidance",
		bancaMH02Option2: "Illustration",
		bancaMH02Option3: "T&C Masterise Homes v.3 (1.12.2021)",
		bancaMH02Option4: "Summary video",
		bancaMH03: "4. BANCA MH - Le Nguyen Trung - AFF - Business Owner - 464mil VND (2 policies)",
		bancaMH03Option2: "Illustration for father +elder child",
		bancaMH03Option3: "Illustration for mother +younger child ",
		bancaTD: "05. BANCA TD- Nguyen Huy Toan - AFF - Business Owner - 100mil VND",
		bancaTDOption2: "Illustration APE 44",
		bancaTDOption3: "Illustration APE 56",
		bancaTDOption4: "T&C Banca TD",
		bancaDreamCity: "06. BANCA DREAM CITY - Hoang Van Trinh - AFF - Business Owner - 100mil VND",
		bancaDreamCityOption2: "Illustration APE 64",
		bancaDreamCityOption3: "Illustration APE 100",
		bancaDreamCityOption4: "T&C Vin Dream City",
		bancaDreamCity2: "07. BANCA DREAM CITY - Tran Huyen My - AFF (VIP2) - Chief Accountant - 100mil VND",
		bancaLending2: "08. BANCA LENDING - Hoàng Tú Linh - AFF (VIP3) - Chủ doanh nghiệp - 30trđ",
		bancaLending2Option3: "T&C Banca Lending",
		bancaLending3: "09. BANCA LENDING - Tran Minh Quang - MAFF - Salaried Earner - 30mil VND",
		bancaTD2: "10. BANCA TD - Lê Thanh Trà- MASS - Salaried Earner - 25mil VND",
		bancaTD3: "11. BANCA TD - Nguyen Dieu Minh - AFF (VIP2) - Business Owner - 50mil VND",
		bancaTD4: "12. BANCA TD - Nham Manh Dung - MAFF -Executive board - 100mil VND+ 400mil VND top-up",
		bancaTD4Option4: "T&C Cashback Q2.2022",

		// Campaign page
		promotionName5: "ƯU ĐÃI NGẬP TRÀN, YÊU THƯƠNG VẸN TOÀN (HẾT HẠN)",
		promotionHeadline5: "Thời gian áp dụng: 07/07/2022 đến hết ngày 30/09/2022",
		promotionContent5: "Tặng tiền vào tài khoản của khách hàng hoặc quà tặng có giá trị tương đương",

		// Material Reference Info
		introductionMaterialWithFullPackage: "Full package",

		// Guidance page
		guideTheHomePage: "Hướng dẫn chức năng ở màn hình trang chủ",

		// Titanium 1.1 (new updated 2023)
		titanium11ProductName: "Titanium 1.1",
		titanium11Subtitle: "Peace of mind – Firmly step into the future",
		titanium11Content:
			"Titanium 1.1 will be a trusted companion to help you to keep reaching out and enjoying every minute of life.",
		titanium11Rider: "Late-stage cancer rider",

		// Campaign page
		promotionName6: "ƯU ĐÃI HOÀN TIỀN (HẾT HẠN)",
		promotionHeadline6: "Thời gian áp dụng: {startDate} đến hết {endDate}",
		promotionContent6: "Tặng tiền vào tài khoản của khách hàng hoặc quà tặng có giá trị tương đương",

		// Campaign page
		promotionName7: "ƯU ĐÃI HOÀN TIỀN",
		promotionHeadline7: "Thời gian áp dụng: {startDate} đến hết {endDate}",
		promotionContent7: "Tặng tiền vào tài khoản của khách hàng hoặc quà tặng có giá trị tương đương",
	},
}
