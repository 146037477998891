<template>
	<JBox>
		<CAccordion :title="$t('core.exclusion')">
			<JBox class="px-5">
				<JBox v-for="(item, idx) in exclusions" :key="idx" class="text-c0-500 mb-6">
					<JFlex>
						<JBox class="w-full">
							<JBox class="font-medium">
								{{ formatData(item?.codeName?.code) }}
							</JBox>
							<JBox class="mt-3">{{ formatData(item.remark) }}</JBox>
						</JBox>
						<JBox class="ml-auto">
							<JBox class="text-cError-500 cursor-pointer p-2 rounded-full hover:bg-c0-100" @click="handleDeleteExclusion(item)">
								<JIcon width="20" height="20" icon="Trash" />
							</JBox>
						</JBox>
					</JFlex>
				</JBox>
				<JBox v-if="!exclusions.length" class="text-c0-500">
					{{ $t("core.noDataHere") }}
				</JBox>
				<JBox class="mt-8">
					<JButton variant="primary-sm" iconPrefix="PlusCircle" @click="handleClickAddExclusion">
						{{ $t("core.addExclusion") }}
					</JButton>
				</JBox>
			</JBox>
		</CAccordion>
		<!-- Modals -->
		<teleport to="#layer2">
			<!-- Modal Add Exclusion -->
			<JModalSimple :isVisible="isAddExclusionModalOpen" variant="center-3xl" @overlay-click="handleCancelAddExclusion">
				<!-- Modal Header -->
				<JBox class="mb-6">
					<JText class="text-c0-300 font-medium">
						{{ $t("core.addNew") }}
					</JText>
					<JHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
						{{ $t("core.exclusion") }}
					</JHeadline>
				</JBox>

				<!-- Modal form -->
				<JBox class="w-full mt-5">
					<CFormInput v-model="exclusionCode" componentName="JInputText" :label="$t('core.code')" />
					<CFormInput
						v-model="exclusionRemark"
						componentName="JInputLongText"
						class="mt-4"
						:rows="5"
						:label="$t('core.remark')"
					/>
				</JBox>

				<!-- Modal actions -->
				<JFlex v-if="!isAddExclusionLoading" class="mt-10">
					<JButton class="mr-2 flex items-center" variant="primary" @click="handleConfirmAddExclusion">
						{{ $t("core.confirm") }}
					</JButton>
					<JButton class="ml-2" variant="tertiary-outline" @click="handleCancelAddExclusion">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
				<JFlex v-else class="items-center mt-6 h-10">
					<JSpinner variant="secondary-lg" type="dots" />
				</JFlex>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import { computed, onMounted, ref } from "vue"
import { Machine } from "xstate"
import { useI18n } from "vue-i18n"
import { formatData } from "@/modules/core/composables"
import {
	gql,
	apiPromise,
	apiCases,
	apiAddExclusionToProposalOffer,
	apiRemoveExclusionFromProposalOffer,
} from "@covergo/cover-composables"
import { useMachine } from "@/modules/core/composables/useMachine"
import { fetcher } from "./../../api/fetcher"
import { handleErrorForUser } from "./../../api/handleErrorForUser"
import { exclusionCardMachine } from "./machine"
import { useStore } from "vuex"

export default {
	/**
		Reusable component that used by policy and case offer.
	 */
	// TODO: Handle add exclusion to policy
	name: "CExclusionCard",
	props: {
		typename: {
			type: String,
			required: true,
			validation(val) {
				return ["policy", "offer"].includes(val)
			},
		},
		/**
		 * For offer type:
		 *   parentId: {
		 *     caseId,
		 *     proposalId,
		 *     offerId
		 *   }
		 * For policy type
		 *    parentId: {}
		 */
		parentId: {
			type: Object,
			required: true,
			default: () => {},
		},
	},
	setup(props) {
		// Initial data
		const exclusions = ref([])
		const exclusionCode = ref("")
		const exclusionRemark = ref("")
		const selectedExclusionId = ref("")

		const { t } = useI18n()
		const store = useStore()

		// Computed
		const isAddExclusionModalOpen = computed(() => state.value.matches("creating"))
		const isAddExclusionLoading = computed(() => state.value.matches("creating.fetching"))

		function handleClickAddExclusion() {
			send("CREATE")
		}
		function handleConfirmAddExclusion() {
			send("SAVE")
		}
		function handleCancelAddExclusion() {
			// Reset form data
			exclusionCode.value = ""
			exclusionRemark.value = ""
			send("CANCEL")
		}
		function handleDeleteExclusion(item) {
			selectedExclusionId.value = item.id
			send("DELETE")
		}

		const options = {
			services: {
				async readExclusion() {
					const variables = {
						where: {
							id: props.parentId.caseId,
						},
						proposalWhere: {
							id: props.parentId.proposalId,
						},
						offerWhere: {
							id: props.parentId.offerId,
						},
					}
					const fragment = gql`
						fragment result on cases {
							list {
								proposals(where: $proposalWhere) {
									basket(where: $offerWhere) {
										exclusions {
											id
											codeName {
												code
												name
											}
											remark
										}
									}
								}
							}
						}
					`
					return await apiPromise(apiCases, {
						variables,
						fetcher,
						fragment,
					})
				},
				async createExclusion() {
					const variables = {
						caseId: props.parentId.caseId,
						proposalId: props.parentId.proposalId,
						offerId: props.parentId.offerId,
						input: {
							code: exclusionCode.value,
							remark: exclusionRemark.value,
						},
					}
					return await apiPromise(apiAddExclusionToProposalOffer, {
						variables,
						fetcher,
					})
				},
				async deleteExclusion() {
					const variables = {
						caseId: props.parentId.caseId,
						proposalId: props.parentId.proposalId,
						offerId: props.parentId.offerId,
						exclusionId: selectedExclusionId.value,
					}
					return await apiPromise(apiRemoveExclusionFromProposalOffer, {
						variables,
						fetcher,
					})
				},
			},
			actions: {
				setResults(ctx, event) {
					exclusions.value = event?.data?.list?.[0].proposals?.[0]?.basket?.[0]?.exclusions || []
				},
				clearForm() {
					// Clear form data
					exclusionCode.value = ""
					exclusionRemark.value = ""
				},
				setDeleteSuccessMessage() {
					store.dispatch("addToastMessage", {
						type: "success",
						content: {
							type: "message",
							text: t("core.deleteExclusionSuccess"),
						},
					})
				},
				setErrorMessage(ctx, event) {
					handleErrorForUser({ error: event?.data, $t: t })
				},
			},
		}

		onMounted(() => {
			send("FETCH")
		})

		const { state, send } = useMachine(Machine(exclusionCardMachine, options), {
			devTools: process.env.NODE_ENV === "development",
		})

		return {
			state,
			send,
			exclusions,
			exclusionCode,
			exclusionRemark,
			selectedExclusionId,
			// Computed
			isAddExclusionModalOpen,
			isAddExclusionLoading,
			// Methods
			formatData,
			handleClickAddExclusion,
			handleConfirmAddExclusion,
			handleCancelAddExclusion,
			handleDeleteExclusion,
		}
	},
}
</script>
