<template>
	<div class="c-form-input" :class="[attrs.class, computedClasses.root]" ref="wrapperRef">
		<slot name="outer" v-bind="scopedSlotProps">
			<div :class="computedClasses.outer">
				<slot name="wrapper" v-bind="scopedSlotProps">
					<div :class="computedClasses.wrapper">
						<slot name="label" v-bind="scopedSlotProps">
							<label v-if="label" :class="computedClasses.label" :for="inputAttrs.id">{{ label }}</label>
						</slot>
						<slot name="inner" v-bind="scopedSlotProps">
							<div :class="computedClasses.inner">
								<slot name="prefixIcon" v-bind="scopedSlotProps">
									<CIcon v-if="prefixIcon" :class="computedClasses.prefixIcon" :icon="prefixIcon" />
								</slot>
								<slot name="prefix" v-bind="scopedSlotProps" />
								<slot name="input" v-bind="scopedSlotProps">
									<input
										ref="inputRef"
										v-bind="{
											...inputAttrs,
											...eventHandlers,
										}"
										:class="computedClasses.input"
										:type="type"
										v-model="model"
									/>
								</slot>
								<slot name="suffix" v-bind="scopedSlotProps" />
								<slot name="suffixIcon" v-bind="scopedSlotProps">
									<CIcon v-if="suffixIcon" :class="computedClasses.suffixIcon" :icon="suffixIcon" />
								</slot>
							</div>
						</slot>
					</div>
				</slot>

				<slot name="help" v-bind="scopedSlotProps">
					<div v-if="help" :class="computedClasses.help">{{ help }}</div>
				</slot>
			</div>
		</slot>

		<slot />
	</div>
</template>

<script setup>
import { computed, ref, useAttrs } from "vue"
import { omit } from "lodash"

import { useProxiedModel } from "@/composables/proxiedModel.js"
import useInput, { EMITS } from "@/composables/input.js"

import CIcon from "@/modules/core/components/CIcon"

const props = defineProps({
	classes: {
		type: Object,
		default: () => {},
	},
	help: String,
	label: String,
	prefixIcon: String,
	suffixIcon: String,
	type: {
		type: String,
		default: "text",
	},
	modelValue: null,
	messages: Array,
	node: Object,
})

const emit = defineEmits([...EMITS, "update:modelValue"])
const attrs = useAttrs()
const { SECTIONS, eventHandlers } = useInput(props, { attrs, emit })
const model = useProxiedModel(props, "modelValue")
const wrapperRef = ref()
const inputRef = ref()
const DEFAULT_CLASSES = SECTIONS.reduce((acc, item) => {
	acc[item] = `c-form-input__${item}`

	return acc
}, {})
const computedClasses = computed(() =>
	Object.keys(DEFAULT_CLASSES).reduce((acc, key) => {
		const value = props.classes[key]
		const defaultValue = DEFAULT_CLASSES[key]

		return {
			...acc,
			[key]: value ? [defaultValue, defaultValue, value] : defaultValue,
		}
	}, props.classes)
)
const inputAttrs = computed(() => omit(attrs, ["class", "style", "variant", "modelModifiers", "size"]))
const scopedSlotProps = computed(() => ({
	...props,
	classes: computedClasses.value,
	inputAttrs: inputAttrs.value,
	attrs,
}))

defineExpose({
	wrapperRef,
	inputRef,
})
</script>

<script>
export default {
	inheritAttrs: false,
}
</script>
