<template>
	<td :class="tableCellClass" :style="tableCellStyle">
		<slot />
	</td>
</template>

<script>
export default {
	name: "CTableCell",
	props: {
		isTruncate: {
			type: Boolean,
			default: true,
		},
		variant: {
			type: String,
			default: "default",
		},
		customClassContainer: {
			type: String,
			default: "",
		},
	},
	computed: {
		tableCellClass() {
			return [
				{
					"whitespace-no-wrap h-16 align-middle max-w-xs w-auto bg-c15 pl-6": true,
					"absolute top-auto right-0 px-4": this.variant === "gradient",
					truncate: this.isTruncate,
				},
				this.customClassContainer,
			]
		},
		tableCellStyle() {
			return this.variant === "gradient" ? "background: linear-gradient(270deg, #FFFFFF 85%, rgba(255, 255, 255, 0) 100%)" : ""
		},
	},
}
</script>
