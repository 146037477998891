<template>
	<JFlex
		class="invisible lg:visible lg:flex h-screen w-full md:w-64 bg-c1-800 fixed left-0 top-0 shadow px-4 py-4 lg:pt-12 lg:pb-4 flex-col rounded-r-2xl"
	>
		<JFlex class="justify-center my-4">
			<img :src="computedLogo" alt="Logo" :class="logoClass" :style="logoStyle" />
			<!-- class="flex-0 w-16 cursor-pointer" style="min-height: 78px" -->
		</JFlex>
		<CSidebarMenu />
	</JFlex>
</template>

<script>
import CSidebarMenu from "./CSidebarMenu"
import { onBeforeMount, shallowRef, computed, ref } from "vue"
import { useStore } from "vuex"

export default {
	name: "CSidebar",
	components: {
		CSidebarMenu,
	},
	setup(props) {
		const store = useStore()
		const tenantId = store?.state?.tenantId
		const clientId = store?.state?.clientId
		const logoImg = shallowRef(null)
		const logoClass = ref(null)
		const logoStyle = ref(null)
		const decideImageToRender = async () => {
			let tenantConfig
			try {
				tenantConfig = await import(`@/tenants/${tenantId}/configs/${clientId}.js`)
				const logoConfig = tenantConfig?.default?.logo
				const fileName = logoConfig?.filename
				const image = await import(`@/tenants/${tenantId}/assets/${fileName}`)
				logoImg.value = image?.default
				logoClass.value = logoConfig?.class
				logoStyle.value = logoConfig?.style
				// eslint-disable-next-line no-empty
			} catch (error) {
				console.groupCollapsed(
					"%c Tenant config file does not exist",
					"padding: 1px 6px 1px 0px; background: yellow; color: black"
				)
				console.log(`tenantId: ${tenantId}`)
				console.log(`clientId: ${clientId}`)
				console.log(error)
				console.groupEnd()
			}
		}
		onBeforeMount(async () => {
			await decideImageToRender()
		})
		const computedLogo = computed(() => logoImg.value || "https://via.placeholder.com/64x78")
		return {
			logoImg,
			logoClass,
			logoStyle,
			computedLogo,
		}
	},
}
</script>
