<template>
	<button class="bg-c0-600 text-cWhite p-4" @click="updateIndividuals">Update Individuals</button>
</template>

<script>
import { apiUpdateIndividual } from "@covergo/cover-composables"
import { fetcher } from "./api/fetcher"
export default {
	setup() {
		const userList = []

		const updateIndividuals = async () => {
			for (const user of userList) {
				const variables = {
					id: user.id,
					input: {},
				}
				if (user.fields) variables.input.fields = user.fields
				await apiUpdateIndividual({
					variables,
					fetcher,
				})
			}
		}
		return { updateIndividuals }
	},
}
</script>

<style></style>
