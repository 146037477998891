/* eslint-disable no-tabs */
import ViewTokenSetting from "../views/ViewTokenSetting.vue"
import { CONFIG_SYSTEM_ROLES_PERMISSION } from "@/tenants/use/pagesAccessingByRolesAndPermissions"
export default [
	{
		path: "/settings",
		component: ViewTokenSetting,
		name: "ViewTokenSetting",
		props: false,
		meta: {
			module: "setting",
			component: "ViewTokenSetting",
			roles: CONFIG_SYSTEM_ROLES_PERMISSION,
		},
	},
]
