const ViewAbout = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewAbout.vue")
const ViewPromotion = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewPromotion.vue")
const ViewContact = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewContact.vue")
const ViewGuidance = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewGuidance.vue")
const ViewProduct = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewProduct.vue")
const ViewForms = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewForms.vue")
const ViewFAQ = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewFAQ.vue")
export default [
	{
		path: "/about",
		component: ViewAbout,
		name: "ViewAbout",
		props: true,
		meta: { module: "pages", component: "ViewAbout", isPublic: true },
	},
	{
		path: "/promotion",
		component: ViewPromotion,
		name: "ViewPromotion",
		props: true,
		meta: { module: "pages", component: "ViewPromotion", isPublic: true },
	},
	{
		path: "/contact",
		component: ViewContact,
		name: "ViewContact",
		props: true,
		meta: { module: "pages", component: "ViewContact", isPublic: true },
	},
	{
		path: "/product",
		component: ViewProduct,
		name: "ViewProduct",
		props: true,
		meta: { module: "pages", component: "ViewProduct", isPublic: true },
	},
	{
		path: "/guidance",
		component: ViewGuidance,
		name: "ViewGuidance",
		props: true,
		meta: { module: "pages", component: "ViewGuidance", isPublic: true },
	},
	{
		path: "/forms",
		component: ViewForms,
		name: "ViewForms",
		props: true,
		meta: { module: "pages", component: "ViewForms", isPublic: true },
	},
	{
		path: "/faq",
		component: ViewFAQ,
		name: "ViewFAQ",
		props: true,
		meta: { module: "pages", component: "ViewFAQ", isPublic: true },
	},
]
