// Issue: TRFM-479
import store from "@/store"
import router from "@/router"
import axios from "axios"
import getEnv from "@/utils/getEnv"
import { makeAxiosGraphQLFetcher } from "../fetcher"
// Timeout for session show confirm modal: 15 minutes (900000 miliseconds)
const defaultWarningTimeout = process.env.NODE_ENV === "development" ? 86400000 : 900000
// Timeout for the modal showing but inactive status
let duration
let lastActiveTime
let intervalID

const fetcher = makeAxiosGraphQLFetcher({
	url: getEnv("VUE_APP_COVERGO_GRAPHQL"),
	axios,
	getToken() {
		return ""
	},
	getLocale() {
		return "en-US"
	},
	debug: true,
})

async function getRefreshTokenLifetime() {
	const query = `
		query getAppInfo {
			apps{
				list{
					slidingRefreshTokenLifetime
				}
			}
		}
	`
	const { data } = await fetcher({
		query,
		variables: {
			tenantId: "tcb_uat",
			clientId: "covergo_crm",
		},
	})

	try {
		const secondTimeRes = data.apps.list[0].slidingRefreshTokenLifetime
		return Number.isInteger(secondTimeRes) ? secondTimeRes * 1000 : defaultWarningTimeout
	} catch {
		return defaultWarningTimeout
	}
}

function resetTimer() {
	lastActiveTime = Date.now()
}

// Logout the user.
function logOutAccount() {
	console.log("%c Session timeout. Auto logout ", "background: red; color: white")
	store.dispatch("logout")
	router.push({
		name: "ViewLogin",
	})
	store.dispatch("addToastMessage", {
		type: "warning",
		content: {
			type: "message",
			text: "Your session has timed out",
		},
	})
}

const startSessionTimeout = async () => {
	duration = await getRefreshTokenLifetime()
	lastActiveTime = Date.now()

	intervalID = setInterval(() => {
		if (Date.now() - lastActiveTime > duration) logOutAccount()
	}, 10000)

	document.addEventListener("mousemove", resetTimer)
	document.addEventListener("mousedown", resetTimer)
	document.addEventListener("keypress", resetTimer)
	document.addEventListener("touchmove", resetTimer)
	document.addEventListener("onscroll", resetTimer)
}

const clearSessionTimeout = () => {
	clearInterval(intervalID)
	document.removeEventListener("mousemove", resetTimer)
	document.removeEventListener("mousedown", resetTimer)
	document.removeEventListener("keypress", resetTimer)
	document.removeEventListener("touchmove", resetTimer)
	document.removeEventListener("onscroll", resetTimer)
}

export { startSessionTimeout, clearSessionTimeout }
