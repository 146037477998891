import { roundSA } from "@/modules/fna/helpers"
import { productsMapping, productsDRBOMapping } from "./productRecommendationMapping"
import { GOALS_ORDER as goalsOrder } from "./utils/constants"
import { PRODUCT_NAME } from "@/modules/customerSupport/constants"
import { PRODUCT_MIN_SA, PRODUCT_MAX_SA } from "@/modules/fna/use/useProductsRule.js"

export const getRecommendedProducts = (state, getters, rootState) => {
	const isDRBOUser = rootState?.me?.associatedUser?.source?.split("-").slice(-1)[0] === "DRBO"
	const dataProductsMapping = isDRBOUser ? productsDRBOMapping : productsMapping
	const noRecommendedProductsErrorMessageByAge = isDRBOUser
		? "noRecommendedProductsByAgeOfDRBO"
		: "noRecommendedProductsByAgeOfRBO"
	const noRecommendedProductsErrorMessageBySA = isDRBOUser ? "noRecommendedProductsBySAOfDRBO" : "noRecommendedProductsBySAOfRBO"
	if (state.selectedGoals.length < 1) return []

	const goalNamesMapping = {
		childrenEducation: "EDU",
		retirement: "RET",
		growWealthAndAssets: "GRO",
		financeUncertainty: "PRO",
	}

	const productHasSelected = getters.selectedProduct || getters.customizingProduct
	const overwriteProductOptionsFromStored = getters.overwriteProductOptions

	const { age } = state.customerDetails
	const customerAgeValidation = {
		titan_6years: () => {
			return age >= 18 && age <= 50
		},
		titan_10years: () => {
			return age >= 18 && age <= 50
		},
		ul048: () => {
			return age >= 18 && age <= 50
		},
		ul038: () => {
			return age >= 18 && age <= 65
		},
		ul040: () => {
			return age >= 18 && age <= 65
		}, // TEC-1684: min: 1 month to max: 65
		edu: () => {
			if (getters?.answers?.childrenEducation?.length !== 1) return false
			const secondInsuredAge = state?.customerDetails?.age * 1
			const secondInsuredGender = state?.customerDetails?.gender
			const { age, ageToUseFund, studyTime } = getters.answers.childrenEducation[0]
			const policyTerm = ageToUseFund + studyTime * 1 - age
			// const premiumTerm = policyTerm - 4
			const rulesMapping = {
				10: { maxSecondInsuredAge: 55, insuredMinAge: 12, insuredMaxAge: 17 },
				11: { maxSecondInsuredAge: 55, insuredMinAge: 11, insuredMaxAge: 16 },
				12: { maxSecondInsuredAge: 55, insuredMinAge: 10, insuredMaxAge: 15 },
				13: { maxSecondInsuredAge: 55, insuredMinAge: 9, insuredMaxAge: 14 },
				14: { maxSecondInsuredAge: 55, insuredMinAge: 8, insuredMaxAge: 13 },
				15: { maxSecondInsuredAge: 55, insuredMinAge: 7, insuredMaxAge: 12 },
				16: { maxSecondInsuredAge: 54, insuredMinAge: 6, insuredMaxAge: 11 },
				17: { maxSecondInsuredAge: 53, insuredMinAge: 5, insuredMaxAge: 10 },
				18: { maxSecondInsuredAge: 52, insuredMinAge: 4, insuredMaxAge: 9 },
				19: { maxSecondInsuredAge: 51, insuredMinAge: 3, insuredMaxAge: 8 },
				20: { maxSecondInsuredAge: 50, insuredMinAge: 2, insuredMaxAge: 7 },
				21: { maxSecondInsuredAge: 49, insuredMinAge: 1, insuredMaxAge: 6 },
				22: { maxSecondInsuredAge: 48, insuredMinAge: 0, insuredMaxAge: 5 },
				23: { maxSecondInsuredAge: 47, insuredMinAge: 0, insuredMaxAge: 4 },
				24: { maxSecondInsuredAge: 46, insuredMinAge: 0, insuredMaxAge: 3 },
				25: { maxSecondInsuredAge: 45, insuredMinAge: 0, insuredMaxAge: 2 },
				26: { maxSecondInsuredAge: 44, insuredMinAge: 0, insuredMaxAge: 1 },
				27: { maxSecondInsuredAge: 43, insuredMinAge: 0, insuredMaxAge: 0 },
			}
			if (!rulesMapping[policyTerm]) return false
			const { maxSecondInsuredAge, insuredMinAge, insuredMaxAge } = rulesMapping[policyTerm]
			if (
				(secondInsuredAge - age < 18 && secondInsuredGender === "female") ||
				(secondInsuredAge - age < 20 && secondInsuredGender === "male") ||
				policyTerm + age < 22 ||
				policyTerm + age > 28 ||
				policyTerm < 10 ||
				policyTerm > 27 ||
				age < insuredMinAge ||
				age > insuredMaxAge
			)
				return false
			return maxSecondInsuredAge >= secondInsuredAge && secondInsuredAge + policyTerm <= 70
		},
		titanium_2: () => {
			return age >= 18 && age <= 69
		},
	}
	const faceAmountValidation = {
		titan_6years: () => {
			const mFaceAmount =
				productHasSelected && overwriteProductOptionsFromStored?.[PRODUCT_NAME.titan_6years]
					? overwriteProductOptionsFromStored?.[PRODUCT_NAME.titan_6years]?.sa
					: getters.initialSA
			return roundSA(mFaceAmount) >= PRODUCT_MIN_SA.titan_6years && roundSA(mFaceAmount) <= PRODUCT_MAX_SA.titan_6years
		},
		titan_10years: () => {
			const mFaceAmount =
				productHasSelected && overwriteProductOptionsFromStored?.[PRODUCT_NAME.titan_10years]
					? overwriteProductOptionsFromStored?.[PRODUCT_NAME.titan_10years]?.sa
					: getters.initialSA
			return roundSA(mFaceAmount) >= PRODUCT_MIN_SA.titan_10years && roundSA(mFaceAmount) <= PRODUCT_MAX_SA.titan_10years
		},
		ul048: () => {
			const mFaceAmount =
				productHasSelected && overwriteProductOptionsFromStored?.[PRODUCT_NAME.ul048]
					? overwriteProductOptionsFromStored?.[PRODUCT_NAME.ul048]?.sa
					: getters.initialSA
			return roundSA(mFaceAmount) >= PRODUCT_MIN_SA.ul048 && roundSA(mFaceAmount) <= PRODUCT_MAX_SA.ul048
		},
		ul038: () => {
			const mFaceAmount =
				productHasSelected && overwriteProductOptionsFromStored?.[PRODUCT_NAME.ul038]
					? overwriteProductOptionsFromStored?.[PRODUCT_NAME.ul038]?.sa
					: getters.initialSA

			return roundSA(mFaceAmount) >= PRODUCT_MIN_SA.ul038
		},
		ul040: () => {
			const mFaceAmount =
				productHasSelected && overwriteProductOptionsFromStored?.[PRODUCT_NAME.ul040]
					? overwriteProductOptionsFromStored?.[PRODUCT_NAME.ul040]?.sa
					: getters.initialSA

			return roundSA(mFaceAmount) >= PRODUCT_MIN_SA.ul040
		},
		edu: () => {
			const mFaceAmount =
				productHasSelected && overwriteProductOptionsFromStored?.[PRODUCT_NAME.edu]
					? overwriteProductOptionsFromStored?.[PRODUCT_NAME.edu]?.sa
					: getters.initialSA

			return roundSA(mFaceAmount) >= PRODUCT_MIN_SA.edu
		},
		titanium_2: () => {
			const mFaceAmount =
				productHasSelected && overwriteProductOptionsFromStored?.[PRODUCT_NAME.titanium_2]
					? overwriteProductOptionsFromStored?.[PRODUCT_NAME.titanium_2]?.sa
					: getters.initialSA

			return roundSA(mFaceAmount) >= PRODUCT_MIN_SA.titanium_2
		},
	}

	function filterResults(results, validation, errorMessage) {
		results.recommendedProducts = results?.recommendedProducts?.filter((product) => {
			return validation?.[product.name]()
		})

		/** check in case user source is DRBO and recommendList is []
		 * Rule of DRBO product recommendations: only show 1 product at the time
		 * if goal is single or even multi goals
		 * (check list productMapping, for case of multi goals will show up 1 or 2 products - tcb rule applied)
		 */
		if (isDRBOUser && !results.recommendedProducts?.length) {
			const ul038 = (...riders) => ({ name: PRODUCT_NAME.ul038, riders })
			const ul040 = (...riders) => ({ name: PRODUCT_NAME.ul040, riders })

			const isUL038Valid = validation?.ul038()
			const isUL040Valid = validation?.ul040()

			if (isUL038Valid) results.recommendedProducts.push(ul038("MC"))
			if (isUL040Valid) results.recommendedProducts.push(ul040("MC"))
		}

		// Common rule: check for all products/ accounts
		if (results.recommendedProducts?.length > 0) {
			if (age > 65) {
				results.recommendedProducts = results?.recommendedProducts?.map((i) => ({
					...i,
					riders: i?.riders?.filter((r) => r !== "HC"),
				}))
			}
			return true
		}

		results.error = errorMessage
		return false
	}

	const getSelectedGoals = function () {
		const healthAndMedicalBenefitNamesMapping = {
			accidentAndDisability: "ADD",
			medicalCare: "MC",
			CI: "CI",
			"CI-plus": "CI-plus",
			healthCare: "HC",
		}
		const healthAndMedicalBenefitsOrder = {
			accidentAndDisability: 1,
			CI: 2,
			"CI-plus": 2,
			medicalCare: 3,
			healthCare: 4,
		}
		return state.selectedGoals
			?.sort((item1, item2) => goalsOrder[item1] - goalsOrder[item2])
			?.map((item) => {
				if (item === "healthAndMedical") {
					return state.answers?.healthAndMedical?.interestedBenefits
						?.sort((item1, item2) => healthAndMedicalBenefitsOrder[item1] - healthAndMedicalBenefitsOrder[item2])
						?.map((benefit) => {
							return healthAndMedicalBenefitNamesMapping[benefit]
						})
						.join("_")
				}
				return goalNamesMapping[item]
			})
			.join("_")
	}

	const selectedGoals = getSelectedGoals()
	const results = {
		recommendedProducts: dataProductsMapping[selectedGoals],
		error: "",
	}

	filterResults(results, customerAgeValidation, `${noRecommendedProductsErrorMessageByAge}`) &&
		filterResults(results, faceAmountValidation, `${noRecommendedProductsErrorMessageBySA}`)

	return {
		...results,
		recommendedProducts:
			results.recommendedProducts.map((product) => ({ ...product, pricing: getters?.prices?.[product.name] })) || [],
	}
}
