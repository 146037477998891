export default {
	dashboard: {
		dashboard: "Dashboard",
		clients: "Clients",
		policies: "Policies",
		cases: "Cases",
		typeToSearch: "Type to search",
		ho: "HO", // head office
		area: "Area",
		region: "Region",
		branch: "Branch",
		lead: "Lead",
		staff: "Staff",
		indicator: "Indicator",
		staffType: "Staff Type",
		sumBy: "Sum by",
		conversation: "Conversation",
		leadGCM: "Lead CRM",
		rates: "Rates",
		conversationWithLeadGCM: "Conversation With Lead CRM",
		APE: "Average Expected APE",
		viewChart: "View Chart",
		millionDong: "Million Vietnam Dong",
		activeUser: "Active Users",
		total: "Total",
		week: "Week",
		month: "Month",
		groupBy: "Group by",
		weekTooltipMessage: "Week {week} starts from {startDate} to {endDate}",
		started: "Started",
		completed: "Completed",
		completedRate: "Completed Rate (%)",
		activeLogin: "Active Login",
		activeSale: "Active Sale",
		activeLoginRate: "Active Login Rate (%)",
		activeSaleRate: "Active Sale Rate (%)",
		incompleted: "Incompleted",
		sales: "Sales",

		// Contract page
		leadGCM_CRM: "GCM/CRM Lead ID",
		contracts: "Policy management",
		findCustomerIdentify: "Customer Information",
		findCustomerIdentifyBy: "Customer Citizen ID/Passport",
		contractNo: "Policy Number",
		contractStatus: "Policy status",
		findTheDate: "Search by date",
		clear: "Clear",
		pleaseSelectDate: "Please select",
		submissionDate: "Submission date",
		issuedDate: "Issuance date",
		passFreeLookDateSearch: "Pass free-look date",
		paidToDate: "Paid to date",
		customerDob: "Customer DOB",
		pleaseInputSearch: "Please input search criteria",
		warningMessageToValidateDate: "Please select the dates within 3 months.",
		resultNotMatch: "No results matches.",
		// Contract statuses
		payPremium: "Trả phí BH",
		freeCharge: "Miễn nộp phí",
		fullFee: "Trả phí toàn phần",
		extendedTermIns: "Extended Term Ins",
		decreaseInsurance: "Bảo hiểm giảm",
		waitingForBeingPaidInsuranceFee: "Chờ trả phí BH",
		supportToApplyFee: "Hỗ trợ nộp phí bảo hiểm",
		waitingToProvideAContract: "Chờ cấp HĐ",
		supportToApplyRiskyFee: "Hỗ trợ nộp phí bảo hiểm rủi ro",
		customerRefuseAContract: "KH từ chối hợp đồng",
		invalid: "Mất hiệu lực",
		converted: "Converted",
		death: "Tử vong",
		cancelMidway: "Huỷ ngang",
		renewal: "Đáo hạn",
		cancel: "Huỷ bỏ",
		criticalIllness: "Bệnh lý nghiêm trọng",
		refuseAContract: "Từ chối HĐ",
		contractHasBeenRefused: "Bị từ chối cấp HĐ",
		permanentDisability: "Thương tật toàn bộ & vĩnh viễn",
		cancelAContract: "Huỷ HĐ",

		// Contract list
		contractor: "Contractor",
		contractApplyDate: "Submission date",
		contractIssueDate: "Issuance date",
		annualPremium: "APE",

		// Contractor detail
		personalInfo: "Personal information",
		contract: "Policy information",
		compensate: "Claim",
		fullName: "Customer Name",
		gender: "Gender",
		dob: "Date of birth",
		phoneNumber: "Phone number",
		email: "Email",
		occupation: "Occupation",
		address: "Address",
		closeBtn: "Close",
		policyOwner: "Policy Owner",
		genderM: "Male",
		genderF: "Female",
		genderG: "Not disclosed",

		// Contract info
		years: "year(s)",
		productName: "Product",
		policyNo: "Policy Number",
		APEByYear: "APE",
		apeModalPremium: "APE Modal Premium",
		paymentMode: "Payment mode",
		modalPremium: "Modal premium",
		topUpFee: "Total Paid Top-up Premium",
		mainProduct: "Main product",
		insuredPerson: "Insured name",
		riderProducts: "Riders",
		insuranceFee: "Sum Assured",
		pendingReason: "Pending reason",
		overDateOfConsideration: "Free look Indicator",
		policyEffectiveDate: "Policy Effective date",
		paymentDueDate: "Paid to Date",
		contractConsultant: "Sales agent",
		customerCare: "Servicing agent",
		accidentalDeathAndDisabilityInsurance: "Bảo hiểm tử vong và thương tật do tai nạn",
		supportingCriticalIllness: "Hỗ trợ Bệnh lý nghiêm trọng",
		effectiveDate: "Policy Effective date",
		status: "Status",
		compensationCode: "Claim ID",
		dateApplyAClaim: "Claim Submission Date",
		claimStatus: "Status",
		claimReason: "Decline reason",
		compensationType: "Claim type",
		approvedOrDeclinedDate: "Approved/Declined Date",
		mainProductAPE: "APE",
		riderEffectiveDate: "Coverage Effective date",
		confirmationDate: "Confirmation Date",
		passFreeLookDate: "Pass Free Look Date",
		policyIssuanceDate: "Policy Issuance Date",
		branchCodeOfSalesAgent: "Branch code of Sales agent",
		policySubmitChannel: "Policy submit channel",
		policyTerminatedDate: "Policy terminated date",
		coveragePremium: "Coverage Premium",
		premiumTerm: "Premium Term",
		planCode: "Plan code",
		firstEventDateOfClaim: "1st Event Date of Claim",
		firstSubmissionMethod: "First Submission Method",
		documentRequestedDate: "Document Requested Date",
		documentReceivedDate: "Document Received Date",
		notificationDate: "Notification Date",

		// Quarter
		monthly: "Monthly",
		quarterly: "Quarterly",
		semiAnnually: "Semi-annually",
		annually: "Annually",
		freeLookIndicatorAnsY: "Yes",
		freeLookIndicatorAnsN: "No",

		policyManagement: "Policy management",
		updateServicingAgent: "Update servicing agent",
		contractManagesByAgent: "Sale agent",
		searchAgent: "Search agent",
		agentCode: "Servicing Agent code",
		updateAgentService: "Update Servicing Agent",
		alterTheServicingAgent: "New Servicing Agent",
		updatedStatus: "Update status",
		hasSucceeded: "Successful",
		hasNotSucceeded: "Unsuccessful",
		wayOutBtn: "Quit",
		pleaseSelectAnAgentServiceCode: "Please input the new servicing agent for selected policies.",
		agentCodeUpdatedSuccessfully: "Successfully update",
		updateAnAgentCodeHasFailed:
			"Updating the information for one/several/all policy(ies) unsuccessfully. Please check and try again.",
		changeInfo: "Change information",
		sendRequestToMVL: "Send request to Manulife",
		confirmBtn: "Confirm",
		okBtn: "OK",
		warningSelectItem: "Please select at least 1 policy.",
		warningSelectAgentCode: "Please input New servicing agent for all selected policies.",
		warningCoincidentAgentCode: "Input New servicing agent is same as current servicing agent for at least one policy.",
		agentCodeHistoryUpdated: "Servicing Agent Updated History",
		updatedDate: "Updated date",
		timeUpdated: "Updated date",
		agentCodeOfAgentService: "Servicing agent code",
		agentCodeOfAgentServiceChanged: "New servicing agent code",
		invalidDateOver3Months: "Please select the dates within 3 months.",
		successfully: "Successfully",
		unsuccessfully: "Unsuccessfully",
		changeDate: "Change date",
		errorLogs: "Errors information",

		// TCB statuses
		tcbStatusPend: "Pending",
		tcbStatusIssued: "Phát hành trước 21 ngày",
		tcbStatusCode: "Phát hành sau 21 ngày",
		tcbStatusCancel: "Cancel",
		tcbStatusReject: "Reject",
		tcbStatusNTaken: "Not Taken",

		requestToChangeInfoTab: "Request to change information",
		requestToChangeHistoryTab: "Information Change History",
		viewMore: "View more",
		staffName: "Staff name",
		agentServiceCode: "Agent code",
		systemError: "System error",

		// claim status
		approved: "Approved",
		declined: "Declined",
		pending: "Pending",
		approvedWithPartialPayment: "Approved with partial payment",
		inactive: "Inactive",

		// claim types
		hospitalizationAllowance: "Hospitalization allowance",
		healthGift: "Health gift",
		injuryBenefit: "Injury benefit",
		deathBenefit: "Death benefit",
		freeFeeBenefit: "Free fee benefit",
		othersBenefit: "Other benefits",

		// REASON PENDING
		notEnoughDocuments: "Not receiving enough test results",
		consultToExpert: "Consult senior appraisal",
		alterProduct: "Product exchange due to appraisal decision",
		missInfor: "Missing information on application",
		complementDocuments: "Additional medical documents",
		lackOfFee: "Lack of fee",
		medicalExamination: "Medical examination",
		acceptanceLetter: "Sign the letter of agreement",
		waitingDocumentsFromAnotherContract: "Waiting for examination results in another Contract",
		underAppraisal: "Under appraisal",
		RCCWaiting: "Waiting for RCC",

		searchAgentCode: "Search agent",
		otherReason: "Other reasons",
		underwriting: "Under appraisal",

		// Contract view
		contractSearchCriteria: "PLEASE INPUT AT LEAST 1 SEARCH CRITERIA BELOW",
		citizenId: "Citizen ID/ Passport",

		staffRole: "Role",
		all: "All",
		areasSelected: "Areas selected",
		regionsSelected: "Regions selected",
		branchesSelected: "Branches selected",
		rolesSelected: "Roles selected",
		agentsSelected: "Agents selected",
		noAgentsMatch: "No agent match the conditions",

		searchByAgent: "Search by Agent",
		searchByBranch: "Search by Branch",
	},
}
