<template>
	<tr>
		<slot />
	</tr>
</template>

<script>
export default {
	name: "CTableHeaderRow",
}
</script>
