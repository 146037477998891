/* eslint-disable no-tabs */
export const round = (num, pre) => {
	const x = Math.pow(10, pre)
	return Math.round(num / x) * x
}

export const getSumChildrenEducation = (childrenEducation, { educationInflation }, _, dontRound) => {
	if (!childrenEducation) return 0
	return childrenEducation?.reduce((sum, child) => {
		const { age, ageToUseFund, studyTime, expectedEducationFee } = child
		const childSum = expectedEducationFee * Math.pow(1 + educationInflation / 100, ageToUseFund - age) * studyTime
		const value = dontRound ? childSum : round(childSum, 6)
		return (sum += value)
	}, 0)
}

export const getSumChildrenEducationDetail = (childrenEducation, { educationInflation }) => {
	if (!childrenEducation) return {}
	return (
		childrenEducation?.reduce((acc, child, idx) => {
			const { age, ageToUseFund, studyTime, expectedEducationFee } = child
			const childSum = expectedEducationFee * Math.pow(1 + educationInflation / 100, ageToUseFund - age) * studyTime
			acc[`child${idx + 1}`] = round(childSum, 6)
			return acc
		}, {}) || {}
	)
}

export const getSumGrowWealthAndAssets = (growWealthAndAssets, { inflation }, _, dontRound) => {
	if (!growWealthAndAssets) return 0
	const { bigPurchases, growWealth } = growWealthAndAssets
	if (growWealth && growWealth.targetSaving) return growWealth.targetSaving
	else
		return (
			bigPurchases?.reduce((sum, purchase) => {
				const { amount, yearsPlannedToBuy, selected } = purchase
				const bigPurchaseValue = selected ? amount * Math.pow(1 + inflation / 100, yearsPlannedToBuy) : 0
				const value = dontRound ? bigPurchaseValue : round(bigPurchaseValue, 6)
				return (sum += value)
			}, 0) || 0
		)
}

export const getSumGrowWealthAndAssetsDetail = (growWealthAndAssets, { inflation }) => {
	if (!growWealthAndAssets) return 0
	const { bigPurchases, growWealth } = growWealthAndAssets
	if (growWealth && growWealth.targetSaving) return { growWealth: growWealth.targetSaving }
	else
		return (
			bigPurchases?.reduce((acc, purchase) => {
				const { amount, yearsPlannedToBuy, selected } = purchase
				const value = amount * Math.pow(1 + inflation / 100, yearsPlannedToBuy)
				if (selected) {
					acc[purchase.asset] = round(value, 6)
				}
				return acc
			}, {}) || {}
		)
}

export const getSumFinanceUncertainty = (financeUncertainty, { inflation }, _, dontRound) => {
	if (!financeUncertainty) return 0
	const { monthlyIncomeNeeded, currentLoan, protectionTime } = financeUncertainty
	const financeNeed = fv(inflation / 100, protectionTime, -monthlyIncomeNeeded * 12) + currentLoan
	return dontRound ? financeNeed : round(financeNeed, 6)
}
function valueOfHealthcareRoomBoardExpense(roomAndBoardExpense = {}) {
	let totalHealthcare = null
	if (typeof roomAndBoardExpense === "object") {
		const hasValue = !!(roomAndBoardExpense?.inpatient || roomAndBoardExpense.outpatient || roomAndBoardExpense.dental)
		if (hasValue) {
			const inpatient = roomAndBoardExpense?.inpatient || 0
			const healthCareOutpatientBenefit = roomAndBoardExpense?.outpatient || 0
			const dentalBenefit = roomAndBoardExpense?.dental || 0
			totalHealthcare = getSumHealthcare({
				roomAndBoardExpense: inpatient,
				healthCareOutpatientBenefit,
				dentalBenefit,
			})
		} else {
			totalHealthcare = 0
		}
	} else {
		totalHealthcare = roomAndBoardExpense
	}
	return totalHealthcare
}
export const getSumHealthAndMedical = (healthAndMedical, _, dontRound) => {
	if (!healthAndMedical) return 0
	const {
		hospitalisedRoomAndBoard,
		roomAndBoardExpense,
		accidentAndDisabilityCoverAmount,
		critticalIllnessCoverAmount,
	} = healthAndMedical

	const totalHealthcare = valueOfHealthcareRoomBoardExpense(roomAndBoardExpense)
	return hospitalisedRoomAndBoard * 1000 + totalHealthcare + accidentAndDisabilityCoverAmount + critticalIllnessCoverAmount
}

export const getSumHealthAndMedicalDetail = (healthAndMedical) => {
	if (!healthAndMedical) return 0
	const {
		interestedBenefits,
		hospitalisedRoomAndBoard,
		roomAndBoardExpense,
		accidentAndDisabilityCoverAmount,
		critticalIllnessCoverAmount,
	} = healthAndMedical
	const totalHealthcare = valueOfHealthcareRoomBoardExpense(roomAndBoardExpense)

	return (
		interestedBenefits?.reduce((acc, item) => {
			const sumMapping = {
				accidentAndDisability: accidentAndDisabilityCoverAmount,
				CI: critticalIllnessCoverAmount,
				medicalCare: hospitalisedRoomAndBoard * 1000,
				healthCare: totalHealthcare,
			}
			acc[item] = sumMapping[item]
			return acc
		}, {}) || {}
	)
}

export const getSumRetirement = (retirement, { inflation }, state, dontRound) => {
	if (!retirement) return 0
	const currentAge = state.customerDetails.age
	const { retirementAge, expectedMonthlyExpenseAfterRetirement, howLongRetirement } = retirement
	return (
		expectedMonthlyExpenseAfterRetirement * Math.pow(1 + inflation / 100, retirementAge - currentAge) * 12 * howLongRetirement
	)
}

export const getOriginalSumChildrenEducation = (childrenEducation) => {
	if (!childrenEducation) return 0
	return childrenEducation?.reduce((sum, child) => {
		const { studyTime, expectedEducationFee } = child
		const childSum = expectedEducationFee * studyTime
		const value = round(childSum, 6)
		return (sum += value)
	}, 0)
}

export const getOriginalSumGrowWealthAndAssets = (growWealthAndAssets) => {
	if (!growWealthAndAssets) return 0
	const { bigPurchases, growWealth } = growWealthAndAssets
	if (growWealth && growWealth.targetSaving) return growWealth.targetSaving
	else
		return (
			bigPurchases?.reduce((sum, purchase) => {
				const { amount, selected } = purchase
				const bigPurchaseValue = selected ? amount : 0
				const value = round(bigPurchaseValue, 6)
				return (sum += value)
			}, 0) || 0
		)
}

export const getOriginalSumFinanceUncertainty = (financeUncertainty) => {
	if (!financeUncertainty) return 0
	const { monthlyIncomeNeeded, currentLoan, protectionTime } = financeUncertainty
	const financeNeed = fv(0, protectionTime, -monthlyIncomeNeeded * 12) + currentLoan
	return round(financeNeed, 6)
}

export const getOriginalSumRetirement = (retirement) => {
	if (!retirement) return 0
	const { expectedMonthlyExpenseAfterRetirement, howLongRetirement } = retirement
	return expectedMonthlyExpenseAfterRetirement * 12 * howLongRetirement
}

function fv(rate, nper, pmt, pv = 0) {
	rate = parseFloat(rate)
	nper = parseFloat(nper)
	pmt = parseFloat(pmt)
	pv = parseFloat(pv)
	let fvValue = 0
	if (nper === 0) {
		alert("Why do you want to test me with zeros?")
		return 0
	}
	if (rate === 0) {
		// Interest rate is 0
		fvValue = -(pv + pmt * nper)
	} else {
		const x = Math.pow(1 + rate, nper)
		fvValue = -(-pmt + x * pmt + rate * x * pv) / rate
	}
	// fvValue = convNumber(fvValue, 2)
	return fvValue * 1
}

// calculate total healthcare benefit
// inpatient + optional outpatient + dental

export const getSumHealthcare = ({ roomAndBoardExpense: saInpatientBenefit, healthCareOutpatientBenefit, dentalBenefit }) => {
	const inpatient = saInpatientBenefit || 0
	const outpatient = healthCareOutpatientBenefit || 0
	const dental = dentalBenefit || 0
	return Number(inpatient) + Number(outpatient) + Number(dental)
}
