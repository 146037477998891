import { isNumeric } from "@/utils"

const casts = {
	string(value) {
		return value !== null ? toString(value) : null
	},
	number(value) {
		return isNumeric(value) ? +value : null
	},
	boolean(value) {
		return !!(value === true || value === "true")
	},
	date(value) {
		const date = new Date(value)

		return !isNaN(date.getTime()) ? date : null
	},
}

function castPlugin(node) {
	const cast = casts[node.props.attrs.cast]

	if (cast) {
		node.hook.input((value, next) => next(cast(value)))
	}
}

export default castPlugin
