<template>
	<component :width="width" :height="height" :is="icon" />
</template>

<script>
import { defineAsyncComponent } from "vue"
const lazyLoad = (iconName) => defineAsyncComponent(() => import(`./icons/${iconName}.vue`))
export default {
	name: "CIcon",
	props: {
		icon: {
			type: String,
			required: true,
			default: "Default",
		},
		width: {
			type: String,
			default: "24",
		},
		height: {
			type: String,
			default: "24",
		},
	},
	components: {
		Check: lazyLoad("Check"),
		Trash: lazyLoad("Trash"),
		CaretDown: lazyLoad("CaretDown"),
		LateStageCancer: lazyLoad("LateStageCancer"),
		Burger: lazyLoad("Burger"),
		Default: lazyLoad("Default"),
		Minus: lazyLoad("Minus"),
		Plus: lazyLoad("Plus"),
		Info: lazyLoad("Info"),
		MedicalSupport: lazyLoad("MedicalSupport"),
		Healthcare: lazyLoad("MedicalSupport"),
		DeathCoverage: lazyLoad("DeathCoverage"),
		AccidentalDeath: lazyLoad("AccidentalDeath"),
		CriticalIllness: lazyLoad("CriticalIllness"),
		AccidentDismemberment: lazyLoad("AccidentDismemberment"),
		PermanentDisability: lazyLoad("PermanentDisability"),
		FinanceSupport: lazyLoad("FinanceSupport"),
		ChildrenEduGoal: lazyLoad("ChildrenEduGoal"),
		WealthAndAssetsGoal: lazyLoad("WealthAndAssetsGoal"),
		RetirementGoal: lazyLoad("RetirementGoal"),
		HealthAndMedical: lazyLoad("HealthAndMedical"),
		FinanceUncertainty: lazyLoad("FinanceUncertainty"),
		EyeCrossed: lazyLoad("EyeCrossed"),
		StartFNA: lazyLoad("StartFNA"),
		ReferLead: lazyLoad("ReferLead"),
		Copy: lazyLoad("Copy"),
		Prenatal: lazyLoad("DeathCoverage"),
		Bell: lazyLoad("Bell"),
		WarningCircledOutline: lazyLoad("WarningCircledOutline"),
		Dot: lazyLoad("Dot"),
		Minimize: lazyLoad("Minimize"),
		EllipsisVertical: lazyLoad("EllipsisVertical"),
		Warning: lazyLoad("Warning"),
		Coverage: lazyLoad("Coverage"),
		PromotionBox: lazyLoad("PromotionBox"),
		InfoWhite: lazyLoad("InfoWhite"),
		CheckboxUnchecked: lazyLoad("CheckboxUnchecked"),
		CheckboxChecked: lazyLoad("CheckboxChecked"),
		CheckboxDisabled: lazyLoad("CheckboxDisabled"),
		Attention: lazyLoad("Attention"),
		Checked: lazyLoad("Checked"),
	},
}
</script>
