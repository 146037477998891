<template>
	<JFlex class="flex-col max-w-lg p-10" style="background: rgb(250, 252, 252); box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px">
		<JHeadline variant="headline" class="mb-6">{{ $t("setting.configApplication") }}</JHeadline>
		<JFlex class="flex-col max-w-md justify-around">
			<JFlex v-if="!isLoading" class="flex-col mb-10">
				<JHeadline>{{ $t("setting.interest") }}</JHeadline>
				<JFlex class="w-full items-start mb-4 md:mb-0 justify-between">
					<JBox class="w-2/3">
						<CFormInput
							componentName="JInputNumber"
							field="form.interest"
							v-model="form.interest"
							@input="v.form.interest.$touch()"
							thousandSeparator=""
							:validator="v"
							type="float"
						/>
					</JBox>
					<!-- Button -->
					<JBox v-if="!isUpdating" class="mt-2" :class="disableInterestButton && 'cursor-not-allowed'">
						<JButton
							variant="primary"
							@click="toggleUpdateModal({ type: 'interest', status: 'open' })"
							:class="disableInterestButton && 'opacity-50 pointer-events-none'"
						>
							{{ $t("button.update") }}
						</JButton>
					</JBox>
					<JBox v-else class="items-center">
						<JSpinner variant="secondary-lg" type="dots" />
					</JBox>
				</JFlex>
			</JFlex>
			<!-- Inflation rate -->
			<JFlex v-if="!isLoading" class="flex-col mb-10">
				<JHeadline>{{ $t("setting.inflation") }}</JHeadline>
				<JFlex class="w-full items-start mb-4 md:mb-0 justify-between">
					<JBox class="w-2/3">
						<CFormInput
							componentName="JInputNumber"
							field="form.inflation"
							v-model="form.inflation"
							@input="v.form.inflation.$touch()"
							thousandSeparator=""
							:validator="v"
							type="float"
						/>
					</JBox>
					<JBox v-if="!isUpdating" class="mt-2" :class="disableInflationButton && 'cursor-not-allowed'">
						<JButton
							variant="primary"
							@click="toggleUpdateModal({ type: 'inflation', status: 'open' })"
							:class="disableInflationButton && 'opacity-50 pointer-events-none'"
						>
							{{ $t("button.update") }}
						</JButton>
					</JBox>
					<JBox v-else class="items-center">
						<JSpinner variant="secondary-lg" type="dots" />
					</JBox>
				</JFlex>
			</JFlex>
		</JFlex>
		<!-- MODALS UPDATE -->
		<!-- Interest -->
		<teleport to="#layer2">
			<JModalSimple
				zIndex="50"
				:isVisible="showUpdateModal.interest"
				@overlay-click="toggleUpdateModal({ type: 'interest', status: 'close' })"
			>
				<JFlex class="flex-col items-center">
					<JHeadline variant="headline">{{ $t("setting.confirmToUpdateInterestRate") }}</JHeadline>
					<JFlex class="justify-center pt-8">
						<JButton @click="handleClickSaveInterest" class="mr-2">
							{{ $t("user.button.confirm") }}
						</JButton>
						<JButton variant="tertiary-outline" @click="toggleUpdateModal({ type: 'interest', status: 'close' })">
							{{ $t("user.button.cancel") }}
						</JButton>
					</JFlex>
				</JFlex>
			</JModalSimple>
		</teleport>
		<!-- Inflation -->
		<teleport to="#layer2">
			<JModalSimple
				zIndex="50"
				:isVisible="showUpdateModal.inflation"
				@overlay-click="toggleUpdateModal({ type: 'inflation', status: 'close' })"
			>
				<JFlex class="flex-col items-center">
					<JHeadline variant="headline">{{ $t("setting.confirmToUpdateInflationRate") }}</JHeadline>
					<JFlex class="justify-center pt-8">
						<JButton @click="handleClickSaveInflation" class="mr-2">
							{{ $t("user.button.confirm") }}
						</JButton>
						<JButton variant="tertiary-outline" @click="toggleUpdateModal({ type: 'inflation', status: 'close' })">
							{{ $t("user.button.cancel") }}
						</JButton>
					</JFlex>
				</JFlex>
			</JModalSimple>
		</teleport>
	</JFlex>
</template>

<script>
import { reactive, computed, onMounted } from "vue"
import { useStore } from "vuex"
import { useMachine } from "@/modules/core/composables/useMachine"
import { required } from "@vuelidate/validators"
import { useVuelidate } from "@vuelidate/core"
import { Machine } from "xstate"
import { configRateMachine } from "@/modules/setting/machine/configRateMachine"
import { gql, apiPromise, apiUpdateDynamicValueOfTemplate, apiTemplates } from "@covergo/cover-composables"
import { fetcher } from "@/modules/setting/api/fetcher"
import { handleErrorForUser } from "@/modules/setting/api/handleErrorForUser"
import { useI18n } from "vue-i18n"
import getEnv from "@/utils/getEnv"

export default {
	name: "ViewConfigRates",
	props: {
		allowToReadSystemConfig: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	setup(props) {
		const { t } = useI18n()
		const store = useStore()

		const isLoading = computed(() => state.value.matches("readingApp"))
		const isUpdating = computed(() => state.value.matches("updatingApp"))
		const disableInterestButton = computed(() => v.value.form.interest.$invalid)
		const disableInflationButton = computed(() => v.value.form.inflation.$invalid)
		const showUpdateModal = reactive({
			interest: false,
			inflation: false,
		})
		const valueIdDynamicTemplate = computed(() => store.getters?.appConfig?.list?.[0]?.dynamicValues?.[0]?.valueId || "")
		const form = reactive({
			interest: null,
			inflation: null,
		})
		// Validation
		const rules = {
			form: {
				interest: {
					required,
				},
				inflation: {
					required,
				},
			},
		}
		const v = useVuelidate(rules, { form })
		onMounted(() => {
			if (props.allowToReadSystemConfig) {
				send("FETCH")
			}
		})
		// METHODS
		const toggleUpdateModal = ({ type, status }) => {
			showUpdateModal[type] = status === "open"
		}
		const handleClickSaveInterest = () => {
			send("SAVE")
			toggleUpdateModal({ type: "interest", status: "close" })
		}
		const handleClickSaveInflation = () => {
			send("SAVE")
			toggleUpdateModal({ type: "inflation", status: "close" })
		}
		const options = {
			services: {
				async readApp() {
					const variables = {
						where: {
							id: `${getEnv("VUE_APP_CONFIG_TEMPLATEID")}`,
						},
					}
					const fragment = gql`
						fragment result on templates {
							list {
								... on dynamicTemplate {
									name
									dynamicValues {
										valueId
										valueJsonString
									}
								}
							}
						}
					`
					return await apiPromise(apiTemplates, { variables, fetcher, fragment })
				},
				async updateApp() {
					const originalValueJsonString = JSON.parse(store?.getters?.appConfig?.list?.[0]?.dynamicValues?.[0]?.valueJsonString)
					const updateForm = {
						...originalValueJsonString,
						rate: {
							interest: form?.interest,
							inflation: form?.inflation,
						},
					}
					const variables = {
						templateId: `${getEnv("VUE_APP_CONFIG_TEMPLATEID")}`,
						valueId: valueIdDynamicTemplate?.value,
						input: {
							valueJsonString: JSON.stringify(updateForm),
						},
					}
					return await apiPromise(apiUpdateDynamicValueOfTemplate, { variables, fetcher })
				},
			},
			actions: {
				setAppInfo(_, event) {
					const fields = event?.data?.list?.[0]
					const valueJsonString = JSON.parse(fields?.dynamicValues?.[0]?.valueJsonString || "{}")
					form.interest = valueJsonString?.rate?.interest
					form.inflation = valueJsonString?.rate?.inflation
				},
				setUpdateAppSuccess() {
					store.dispatch("addToastMessage", {
						type: "success",
						content: {
							type: "message",
							text: t("setting.updateSettingSuccess"),
						},
					})
				},
				setErrorMessage(_, event) {
					handleErrorForUser({ error: event?.data, $t: t })
				},
			},
			guards: {
				updateGuard() {
					v.value.$touch()
					return !v.value.$invalid
				},
			},
		}

		const { state, send } = useMachine(Machine(configRateMachine, options), { devTools: process.env.NODE_ENV === "development" })

		return {
			state,
			send,
			v,
			form,
			showUpdateModal,
			// Computed
			isLoading,
			isUpdating,
			disableInterestButton,
			disableInflationButton,
			// Methods
			handleClickSaveInterest,
			handleClickSaveInflation,
			toggleUpdateModal,
		}
	},
}
</script>
<style scoped></style>
