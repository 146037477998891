<template>
	<JFlex class="relative flex-wrap mx-auto justify-center w-4/5">
		<JBox class="inline-block text-center my-4">
			<JHeadline as="h2" variant="h3" class="text-c0-800 pointer-events-none">
				{{ $t("fna.address") }}
			</JHeadline>
		</JBox>
		<JFlex class="flex-col w-full items-center space-y-4 mt-6 mb-10">
			<!-- City -->
			<CFormInput
				variant="tcb-rule"
				componentName="CSingleSelect"
				:modelValue="model.city"
				:placeholder="$t('fna.pleaseSelectACity')"
				:options="normalizedProvinces"
				@update:modelValue="onCityChange"
				:label="$t('fna.city')"
				class="w-full"
			/>
			<!-- District -->
			<CFormInput
				variant="tcb-rule"
				componentName="CSingleSelect"
				:modelValue="model.district"
				:placeholder="$t('fna.pleaseSelectADistrict')"
				:options="normalizedDistricts"
				:label="$t('fna.district')"
				@update:modelValue="onDistrictChange"
				class="w-full"
			/>
			<!-- Ward -->
			<CFormInput
				variant="tcb-rule"
				componentName="CSingleSelect"
				:modelValue="model.ward"
				:placeholder="$t('fna.pleaseSelectAWard')"
				:options="normalizedWards"
				:label="$t('fna.ward')"
				@update:modelValue="onWardChange"
				class="w-full"
			/>
			<!-- Submit btn -->
			<JBox class="pt-10">
				<JButton @click="onSave">{{ $t("fna.ok") }}</JButton>
			</JBox>
		</JFlex>
	</JFlex>
</template>

<script setup>
import { reactive, ref } from "vue"
import useAddresses from "@/modules/customerSupport/composables/addresses.js"

const props = defineProps({
	address: {
		type: Object,
		default: () => ({}),
	},
})
const emit = defineEmits(["save"])

const model = reactive(props.address)
const { provinces, normalizeDistricts, normalizeWards, normalizeProvinces } = useAddresses()
const normalizedProvinces = normalizeProvinces(provinces)
const normalizedDistricts = ref([])
const normalizedWards = ref([])
if (model.city) {
	const selectedProvince = normalizedProvinces.find((i) => i.value === model.city) ?? {}
	onCityChange(null, selectedProvince)

	if (model.district) {
		const selectedDistrict = normalizedDistricts.value.find((i) => i.value === model.district) ?? {}
		onDistrictChange(null, selectedDistrict)

		if (model.ward) {
			const selectedWard = normalizedWards.value.find((i) => i.value === model.ward) ?? {}
			onWardChange(null, selectedWard)
		}
	}
}

function onSave() {
	emit("save", { value: model, toCloseModal: true })
}

function onCityChange(_, option) {
	const { name, value, item } = option || {}
	model.cityName = name
	model.city = value
	normalizedDistricts.value = normalizeDistricts(item?.districts)
	// check if selected district is not in current city
	if (model.district && !normalizedDistricts.value.some((i) => i.value === model.district)) {
		model.district = null
		model.districtName = null
		model.ward = null
		model.wardName = null
	}
	normalizedWards.value = []
}

function onDistrictChange(_, option) {
	const { name, value, item } = option || {}

	model.districtName = name
	model.district = value
	normalizedWards.value = normalizeWards(item?.wards)
	// check if selected ward is not in current district
	if (model.ward && !normalizedWards.value.some((i) => i.value === model.ward)) {
		model.ward = null
		model.wardName = null
	}
}

function onWardChange(_, option) {
	const { name, value } = option || {}

	model.wardName = name
	model.ward = value

	emit("save", { value: model, toCloseModal: false })
}
</script>
