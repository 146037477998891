<template>
	<JFlex class="flex-row items-center max-w-6xl space-x-6 pl-6">
		<!-- Agent code -->
		<JFlex class="flex-row items-start space-x-6 w-2/3" style="max-width: 650px; min-height: 130px">
			<JBox class="w-full pb-6" style="max-width: 310px">
				<CFormInput
					componentName="CInputSearchSelect"
					v-model="agentCodeSelected"
					:hasRemoveItem="true"
					:label="$t('dashboard.contractManagesByAgent')"
					:placeholder="$t('dashboard.searchAgent')"
					field="servicingAgentCode"
					:validator="validator"
					valueKey="value"
					nameKey="value"
					selectedNameKey="code"
					:lookupFields="['code', 'name']"
					:options="agentCodeOptions"
					:searchFunction="onHandleSearchAgentCode"
					maxHeightDropdown="165px"
					:isSearchLoading="isLoadingSearchAgentCode"
				/>
			</JBox>
			<!-- Contract No -->
			<JFlex class="w-full pb-6" style="max-width: 310px">
				<CFormInput
					:modelValue="contractNo"
					:validator="validator"
					field="contractNo"
					:label="$t('dashboard.contractNo')"
					componentName="JInputText"
					@input="$emit('update:contractNo', $event.target.value)"
				/>
			</JFlex>
		</JFlex>

		<JBox class="w-64 pb-6">
			<JButton @click="handleClickApplyFilter">
				{{ $t("case.apply") }}
			</JButton>
		</JBox>
	</JFlex>

	<!-- Warning: messages -->
	<JBox v-if="customWarningMessage">
		<JText class="text-c1-400 ml-6">{{ customWarningMessage }}</JText>
	</JBox>
</template>
<script>
import { computed, ref } from "vue"
import { useI18n } from "vue-i18n"
export default {
	name: "UpdateAgentInfoOnContract",
	components: {},
	emits: ["update:agentCode", "update:contractNo", "filter:apply"],
	props: {
		servicingAgentCode: {
			type: String,
			default: "",
		},
		contractNo: {
			type: String,
			default: "",
		},
		validator: {
			type: Object,
			default: () => {},
		},
		agentCodeOptions: {
			type: Array,
			default: () => [],
		},
		isLoadingSearchAgentCode: {
			type: Boolean,
			default: false,
		},
		onHandleSearchAgentCode: {},
	},
	setup(props, { emit }) {
		const { t, locale } = useI18n()
		const agentCodeSelected = computed({
			get: () => props?.servicingAgentCode,
			set: (val) => {
				emit("update:agentCode", val)
			},
		})
		// validate missing fields
		const warningUserToInputSearchFields = ref(false)
		const isInvalidFields = computed(() => {
			return !props?.servicingAgentCode && !props?.contractNo
		})

		const customWarningMessage = computed(() => {
			if (warningUserToInputSearchFields.value) return t("dashboard.pleaseInputSearch")
			return ""
		})
		// Methods
		const handleClickApplyFilter = async () => {
			props.validator.$touch()
			if (props.validator?.$invalid) return
			warningUserToInputSearchFields.value = false
			if (isInvalidFields.value) {
				warningUserToInputSearchFields.value = true
				return
			}
			emit("filter:apply")
		}

		const handleClickClearFilter = () => {
			emit("filter:clear")
		}

		return {
			t,
			locale,
			handleClickApplyFilter,
			handleClickClearFilter,
			agentCodeSelected,
			customWarningMessage,
		}
	},
}
</script>
