import { fetcher } from "../../api/fetcher"
import {
	gql,
	// Create
	apiAddEntityNote,
	apiAddPolicyNote,
	apiAddNoteToCase,
	apiAddTransactionNote,
	// Read
	apiIndividuals,
	apiCompanies,
	apiObjects,
	apiInternals,
	apiOrganizations,
	apiPolicies,
	apiCases,
	apiTransactions,
	// Update
	apiUpdateEntityNote,
	apiUpdatePolicyNote,
	apiUpdateNoteOfCase,
	apiUpdateTransactionNote,
	// Delete
	apiRemoveEntityNote,
	apiRemovePolicyNote,
	apiRemoveNoteFromCase,
	apiRemoveTransactionNote,
} from "@covergo/cover-composables"

const apiKeychain = {
	individuals: {
		idType: "entityId",
		create: apiAddEntityNote,
		read: apiIndividuals,
		update: apiUpdateEntityNote,
		delete: apiRemoveEntityNote,
	},
	companies: {
		idType: "entityId",
		create: apiAddEntityNote,
		read: apiCompanies,
		update: apiUpdateEntityNote,
		delete: apiRemoveEntityNote,
	},
	objects: {
		idType: "entityId",
		create: apiAddEntityNote,
		read: apiObjects,
		update: apiUpdateEntityNote,
		delete: apiRemoveEntityNote,
	},
	internals: {
		idType: "entityId",
		create: apiAddEntityNote,
		read: apiInternals,
		update: apiUpdateEntityNote,
		delete: apiRemoveEntityNote,
	},
	organizations: {
		idType: "entityId",
		create: apiAddEntityNote,
		read: apiOrganizations,
		update: apiUpdateEntityNote,
		delete: apiRemoveEntityNote,
	},
	policies: {
		idType: "policyId",
		create: apiAddPolicyNote,
		read: apiPolicies,
		update: apiUpdatePolicyNote,
		delete: apiRemovePolicyNote,
	},
	cases: {
		idType: "caseId",
		create: apiAddNoteToCase,
		read: apiCases,
		update: apiUpdateNoteOfCase,
		delete: apiRemoveNoteFromCase,
	},
	transactions: {
		idType: "transactionId",
		create: apiAddTransactionNote,
		read: apiTransactions,
		update: apiUpdateTransactionNote,
		delete: apiRemoveTransactionNote,
	},
}

export async function apiCreateNote(id, typename, title, content) {
	const { create: apiKey = null, idType = null } = apiKeychain[typename]
	if (!apiKey || !idType) return

	const variables = {
		[idType]: id,
		input: {
			title,
			content,
		},
	}
	return await apiKey({ variables, fetcher })
}

export async function apiReadNote(id, typename) {
	const { read: apiKey = null } = apiKeychain[typename]
	if (!apiKey) return

	const variables = {
		where: {
			id,
		},
	}

	const fragment = gql`
        fragment result on ${typename} {
            list {
                notes {
                    id
                    title
                    content
                    createdAt
                    createdBy {
                        email
                    }
                }
            }
            totalCount
        }
    `

	return await apiKey({
		variables,
		fetcher,
		fragment,
	})
}

export async function apiUpdateNote(id, typename, noteId, title, content) {
	const { update: apiKey = null, idType = null } = apiKeychain[typename]
	if (!apiKey || !idType) return

	const variables = {
		[idType]: id,
		input: {
			id: noteId,
			title,
			content,
		},
	}
	if (["cases"].includes(typename)) variables.noteId = noteId

	return await apiKey({
		variables,
		fetcher,
	})
}

export async function apiDeleteNote(id, typename, noteId) {
	const { delete: apiKey = null, idType = null } = apiKeychain[typename]
	if (!apiKey || !idType) return

	const variables = { [idType]: id }
	if (["cases", "transactions"].includes(typename)) variables.noteId = noteId
	else variables.id = noteId

	return await apiKey({
		variables,
		fetcher,
	})
}
