<template>
	<JHeadline variant="h4" as="h4" class="text-cSuccess-400 font-bold">{{ $t("fna.medicalPlus") }}</JHeadline>
	<JBox class="mb-1">
		<JText class="font-bold mb-1" style="font-size: 0.85rem">{{ $t("fna.medicalCash") }}</JText>
		<table class="text-xs text-center">
			<thead>
				<tr>
					<th class="border border-slate-100 w-2/6"></th>
					<th class="border border-slate-100 font-light">{{ $t("fna.first5days") }}</th>
					<th class="border border-slate-100 font-light">{{ $t("fna.from6thdayOnward") }}</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="border border-slate-100">{{ $t("fna.medicalCashContractYear1st") }}</td>
					<td class="border border-slate-100">{{ $t("fna.medicalCashContractYearPercent", { percent: "100%" }) }}</td>
					<td rowspan="3" class="border border-slate-100">{{ $t("fna.medicalCashContractYearPercent", { percent: "240%" }) }}</td>
				</tr>
				<tr>
					<td class="border border-slate-100">{{ $t("fna.medicalCashContractYear2nd") }}</td>
					<td class="border border-slate-100">{{ $t("fna.medicalCashContractYearPercent", { percent: "150%" }) }}</td>
				</tr>
				<tr>
					<td class="border border-slate-100">{{ $t("fna.medicalCashContractYear3rd") }}</td>
					<td class="border border-slate-100">{{ $t("fna.medicalCashContractYearPercent", { percent: "200%" }) }}</td>
				</tr>
			</tbody>
		</table>
	</JBox>
	<ul style="color: #003956" class="flex flex-col text-xs list-disc pl-5 mb-1">
		<li class="cursor-pointer select-none relative font-normal" style="min-width: 5rem">
			<div v-html="$t('fna.specialCareDepartment')"></div>
		</li>
	</ul>
	<JBox v-for="(item, index) in benefitTooltip" :key="index" class="mb-1">
		<JHeadline variant="h4" as="h4" class="text-cSuccess-400 font-bold">{{ $t(item.headline) }}</JHeadline>
		<JText style="font-size: 0.85rem">{{ $t(item.content) }}</JText>
		<ul v-if="item.options" style="color: #003956" class="flex flex-col text-xs list-disc pl-5 mb-1">
			<li
				v-for="option in item.options"
				:key="option"
				class="cursor-pointer select-none relative font-normal"
				style="min-width: 5rem"
			>
				<div>{{ $t(option) }}</div>
			</li>
		</ul>
	</JBox>
</template>
<script>
import { ref } from "vue"
export default {
	name: "MCPlusSupportToolTip",
	setup() {
		const benefitTooltip = ref([
			{
				headline: "fna.specialMedicalBenefit",
				content: "fna.specialMedicalBenefitPayMCPlus",
				options: ["fna.specialMedicalBenefitPay1", "fna.specialMedicalBenefitPay2"],
			},
			{
				headline: "fna.deathBenefit",
				content: "fna.deathBenefitPayMCPlus",
			},
		])
		return {
			benefitTooltip,
		}
	},
}
</script>
