import { reactive, ref, toRefs } from "vue"
import { fetcher } from "./../api/fetcher"
import { gql } from "@covergo/cover-composables"
import { useI18n } from "vue-i18n"
import dayjs from "dayjs"
import isBetween from "dayjs/plugin/isBetween"
import { useVuelidate } from "@vuelidate/core"
import { splitAgentCodeValue } from "@/helpers/splitAgentCodeValue.js"
import { maxLength } from "@vuelidate/validators"
dayjs.extend(isBetween)

export function useAgentCodeHistoryUpdated() {
	// initialize hook
	const { t } = useI18n()
	// Initial data
	const isLoadingFetchAllCases = ref(false)
	const totalItems = ref(0)
	const currentPage = ref(1)
	const limit = ref(10)

	// Reactive data
	const filterData = reactive({
		contractManagesByAgent: "", // only show if user is manager
		contractNo: "",
		agentCodeUpdateStatus: "",
		updatedDateAt: {
			type: "between", // use crunchDate from composables file to search createAt field
			value: [],
			quickOptionValue: "",
		},
	})
	// validation form
	const filterDataFormToRefs = toRefs(filterData)
	const rules = {
		contractNo: {
			maxLength: maxLength(10),
		},
	}

	const v = useVuelidate(rules, filterDataFormToRefs)
	// Options
	const contractListAreEmpty = ref(null)
	const agentCodeHistoryUpdatedList = ref([])

	const fetchAllCase = async ({ variables = {} }) => {
		const query = gql`
			query servicingAgentChangeLogsQuery($where: users_GenericServicingAgentChangeLogQueryInput) {
				servicingAgentChangeLogsQuery(where: $where) {
					totalCount
					list {
						id
						policyNo
						policyName
						errorCode
						message
						newServicingAgentCode
						newServicingAgentName
						currentServicingAgentCode
						currentServicingAgentName
						status
						lastModifiedAt
						createdAt
						lastModifiedById
						createdById
					}
				}
			}
		`
		const { data, error } = await fetcher({ variables, query })
		if (error) throw error
		return data?.servicingAgentChangeLogsQuery
	}

	const readAllContractData = async () => {
		try {
			isLoadingFetchAllCases.value = true

			const variables = {
				where: {
					skip: (currentPage.value - 1) * limit.value,
					first: limit.value,
					orderBy: {
						fieldName: "createdAt",
						type: "DSC",
					},
					where: {
						and: [],
					},
				},
			}

			if (filterData?.contractManagesByAgent) {
				variables.where.where.and.push({
					where: { currentServicingAgentCode_contains: splitAgentCodeValue(filterData?.contractManagesByAgent)?.code },
				})
			}

			if (filterData?.agentCodeUpdateStatus) {
				variables.where.where.and.push({
					where: { status: filterData?.agentCodeUpdateStatus },
				})
			}
			if (filterData?.contractNo) {
				const mPolicyNo = filterData?.contractNo?.trim()
				variables.where.where.and.push({
					where: { policyNo: mPolicyNo },
				})
			}
			if (filterData?.updatedDateAt?.value?.length > 0) {
				variables.where.where.and.push(
					{
						where: { createdAt_gt: dayjs(filterData?.updatedDateAt?.value[0]) },
					},
					{
						where: { createdAt_lt: dayjs(filterData?.updatedDateAt?.value[1]).add(1, "day") },
					}
				)
			}

			const data = await fetchAllCase({ variables })
			if (!data?.list?.length > 0) contractListAreEmpty.value = t("dashboard.resultNotMatch")
			totalItems.value = data?.totalCount
			agentCodeHistoryUpdatedList.value = data?.list || []
		} catch (error) {
			console.error(error)
		} finally {
			isLoadingFetchAllCases.value = false
		}
	}

	return {
		v,
		limit,
		isLoadingFetchAllCases,
		agentCodeHistoryUpdatedList,
		contractListAreEmpty,
		currentPage,
		totalItems,
		readAllContractData,
		...filterDataFormToRefs,
	}
}
