const ViewNotificationItemList = () => import(/* webpackChunkName: "group-policy" */ "../views/ViewNotificationItemList.vue")
const ViewPolicyPaymentDueDateList = () =>
	import(/* webpackChunkName: "group-policy" */ "../views/ViewPolicyPaymentDueDateList.vue")
const ViewReminderUserDOBList = () => import(/* webpackChunkName: "group-policy" */ "../views/ViewReminderUserDOBList.vue")
export default [
	{
		path: "/notification-list",
		component: ViewNotificationItemList,
		name: "ViewNotificationItemList",
		props: true,
		meta: { module: "pages", component: "ViewNotificationItemList", isPublic: false },
	},
	{
		path: "/policy-payment-due-date-list",
		component: ViewPolicyPaymentDueDateList,
		name: "ViewPolicyPaymentDueDateList",
		props: true,
		meta: { module: "pages", component: "ViewPolicyPaymentDueDateList", isPublic: false },
	},
	{
		path: "/user-dob-list",
		component: ViewReminderUserDOBList,
		name: "ViewReminderUserDOBList",
		props: true,
		meta: { module: "pages", component: "ViewReminderUserDOBList", isPublic: false },
	},
]
