<template>
	<div class="c-expansion-panel">
		<div class="c-expansion-panel__bar" @click="onToggle" :class="classes.bar">
			<span class="c-expansion-panel__bar-icon" :class="classes.barIcon">
				<slot name="bar-icon" :classes="classes" :assets="assets">
					<CIcon :icon="assets.barIcon" :width="assets.width" :height="assets.height" />
				</slot>
			</span>

			<span :class="classes.title">{{ title }}</span>

			<span class="c-expansion-panel__action" :class="classes.action">
				<slot name="bar-action" :classes="classes" :assets="assets">
					<JButton v-if="onActionClick" @click="$emit('actionClick', $event)" variant="icon">
						<CIcon :icon="assets.actionIcon" :width="assets.width" :height="assets.height" />
					</JButton>
				</slot>
			</span>
		</div>

		<CExpandTransition>
			<div class="c-expansion-panel__content" :class="classes.content" v-show="isActive">
				<slot />
			</div>
		</CExpandTransition>
	</div>
</template>

<script setup>
import { useVariant, genVariantProps } from "@/composables/variant"
import { useProxiedModel } from "@/composables/proxiedModel.js"

import CExpandTransition from "@/modules/core/components/CExpandTransition.vue"
import CIcon from "@/modules/core/components/CIcon"

const props = defineProps({
	...genVariantProps(),
	title: String,
	active: {
		type: Boolean,
		default: false,
	},
	onActionClick: Function,
})
const emit = defineEmits(["update:active", "toggle", "actionClick"])

const { classes, assets } = useVariant("CExpansionPanel", props)
const isActive = useProxiedModel(props, "active")

function onToggle() {
	isActive.value = !isActive.value

	emit("toggle", isActive.value)
}

defineExpose({
	isActive,
})
</script>
