<template>
	<div :class="classes.root">
		<select
			:class="classes.select"
			:disabled="disabled"
			@change="onChangeOption"
			@focus="emit('focus')"
			@blur="emit('blur')"
			:value="internalValue"
		>
			<option v-for="option in options" :key="option[valueKey]" :value="option[valueKey]" :disabled="option.disabled">
				{{ option[nameKey] }}
			</option>
		</select>
		<div :class="classes.iconRoot">
			<slot name="icon" :variantCls="classes">
				<JIcon :class="classes.icon" :icon="assets.iconName" />
			</slot>
		</div>
	</div>
</template>

<script setup>
import { ref, watch } from "vue"
import { useVariant, genVariantProps } from "@/composables/variant"

const emit = defineEmits(["update:modelValue", "blur", "focus"])
const props = defineProps({
	...genVariantProps(),
	modelValue: {
		required: true,
		default: "",
		validator(val) {
			return val === null || typeof val === "string" || typeof val === "number"
		},
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	options: {
		required: true,
		default: () => [],
		type: Array,
	},
	nameKey: {
		type: String,
		default: "name",
	},
	valueKey: {
		type: String,
		default: "value",
	},
})
const { classes, assets } = useVariant("CSelect", props)
const internalValue = ref(props.modelValue)

function onChangeOption(event) {
	internalValue.value = event.target.value

	emit("update:modelValue", internalValue.value)
}
watch(
	() => props.modelValue,
	(val) => {
		internalValue.value = val
		emit("update:modelValue", internalValue.value)
	}
)
</script>
