import { useStore } from "vuex"
import { useRouter, useRoute } from "vue-router"
import { computed } from "vue"
import { USER_ROLES } from "@/modules/dashboard/utils/constant"

export default function validateToAccessPage() {
	const store = useStore()
	const router = useRouter()
	const route = useRoute()
	const userRole = computed(() => store.getters.user_role)
	const userDoesNotHavePermissionAndRole = computed(() => !userRole.value)
	const associatedUser = computed(() => store.getters.me.associatedUser)
	const isAdmin = computed(() => (userRole.value && associatedUser.value === null ? "admin" : ""))
	const isExecutive = computed(() => userRole.value === USER_ROLES.EXECUTIVE)
	const isSecurity = computed(() => userRole.value === USER_ROLES.SECURITY)

	const invalidUserRoleToAccessPage = computed(() => {
		// Roles are defined in the routes
		const roleToAccess = route && route.meta?.roles ? route.meta.roles : []
		if (userRole.value && roleToAccess.length > 0) {
			return !roleToAccess.includes(userRole.value)
		} else return false
	})
	function goToHomePageIfUserDoesNotHavePermission() {
		if (userDoesNotHavePermissionAndRole.value || invalidUserRoleToAccessPage.value) {
			router.push({
				name: "ViewHome",
			})
		}
	}
	return {
		userDoesNotHavePermissionAndRole,
		goToHomePageIfUserDoesNotHavePermission,
		invalidUserRoleToAccessPage,
		isAdmin,
		isSecurity,
		isExecutive,
	}
}
