<template>
	<JBox class="min-h-screen" style="background: linear-gradient(180deg, #ffdfdf 9.51%, #fffafa 100%)">
		<JBox
			class="main-tcb-wrapper h-screen md:h-auto absolute left-0 right-0 mx-auto xl:rounded-2xl bg-cWhite overflow-y-auto max-w-6xl xl:max-w-full xl:mx-5 top-12 xl:top-20 bottom-0"
		>
			<slot />
		</JBox>
		<CTopNav />
	</JBox>
</template>

<script>
import CTopNav from "./CTopNav"

export default {
	name: "CLayout",
	props: ["maxSpaces"],
	components: {
		CTopNav,
	},
}
</script>
<style scoped>
@media screen and (min-width: 768px) and (max-width: 1366px) {
	.layout_wrapper {
		margin: 0 24px;
	}
}
@media screen and (min-width: 1281px) {
	.main-tcb-wrapper {
		bottom: 1rem;
	}
}
</style>
