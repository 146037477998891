<template>
	<JBox>
		<JFlex class="mx-5 mt-6 mb-6 text-c1-800 font-bold text-lg">
			<JText class="font-bold text-lg">{{ $t("core.identities") }}</JText>
			<JButton v-if="isEditable" variant="tertiary-rounded" class="ml-auto" style="padding: 0.25rem" @click="openModal()">
				<JIcon class="text-c1-500" icon="Plus" />
			</JButton>
		</JFlex>
		<CEditableField
			v-for="(item, index) in computedList"
			:key="index"
			:label="item.type"
			:value="item.value"
			:canEdit="isEditable"
			:canDelete="isEditable"
			@edit="showUpdateModal(item)"
			@delete="handleRemove(item.id)"
		/>
		<JBox v-if="computedList.length === 0" class="mx-5">
			<JText class="text-c0-500"> {{ $t("core.noDataHere") }}</JText>
		</JBox>
		<!-- Modal to handle add and update -->
		<teleport to="#layer2">
			<JModalSimple :isVisible="showModal" @overlay-click="closeModalAndClearForm()">
				<CFormInput
					v-model="form.type"
					componentName="JInputText"
					placeholder="HKID, Passport, etc."
					:label="$t('core.type')"
					class="mb-6"
					:validator="v"
					field="form.type"
				/>
				<CFormInput
					v-model="form.value"
					componentName="JInputText"
					:label="$t('core.value')"
					class="mb-6"
					:validator="v"
					field="form.value"
				/>
				<JFlex>
					<JButton @click="selectedId ? handleUpdate() : handleAdd()" class="mr-2">
						{{ selectedId ? $t("core.update") : $t("core.add") }}
					</JButton>
					<JButton variant="tertiary-outline" @click="closeModalAndClearForm()">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import { apiAddIdentity, apiUpdateIdentity, apiRemoveIdentity } from "@covergo/cover-composables"
import { fetcher } from "../../api/fetcher"
import { handleErrorForUser } from "../../api/handleErrorForUser"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { ref, watch, computed } from "vue"
import { useI18n } from "vue-i18n"

export default {
	props: {
		fields: {
			type: Array,
		},
		entityId: {
			type: String,
		},
		isEditable: {
			type: Boolean,
			default: true,
		},
	},
	setup(props) {
		const { t } = useI18n()
		const list = ref([])
		const computedList = computed(() => list.value || [])
		const showModal = ref(false)
		const form = ref({
			type: null,
			value: null,
		})
		const selectedId = ref(null)

		/** Vuelidate */
		const rules = {
			form: {
				type: {
					required,
				},
				value: {
					required,
				},
			},
		}
		const v = useVuelidate(rules, { form })
		const checkFormValid = () => {
			v.value.form.$touch()
			return !v.value.form.$invalid
		}
		const clearForm = () => {
			form.value = {
				type: null,
				value: null,
			}
			selectedId.value = null
		}
		const openModal = () => {
			showModal.value = true
		}
		const closeModalAndClearForm = () => {
			v.value.$reset()
			showModal.value = false
			clearForm()
		}

		// managing local state
		const addToList = (item) => {
			const array = list.value
			array.push(item)
		}
		const removeFromList = (id) => {
			list.value = list.value?.filter((item) => item.id !== id)
		}
		const updateList = (id, updatedObj) => {
			const found = list.value?.findIndex((item) => item?.id === id)
			list.value[found] = { id, ...updatedObj }
		}

		// add
		const addIdentity = async () => {
			const variables = {
				entityId: props?.entityId,
				input: { ...form.value },
			}
			const { error, data } = await apiAddIdentity({
				variables,
				fetcher,
			})
			return { error, data }
		}
		const handleAdd = async () => {
			const isFormValid = checkFormValid()
			if (!isFormValid) return
			const { error, data } = await addIdentity()
			if (error) {
				handleErrorForUser({ error, $t: t })
				return
			}
			addToList({ id: data?.createdStatus?.id, type: form.value?.type, value: form.value?.value })
			closeModalAndClearForm()
		}

		// update
		const showUpdateModal = (item) => {
			selectedId.value = item.id
			form.value = { type: item?.type, value: item.value }
			openModal()
		}
		const updateIdentity = async () => {
			const variables = {
				entityId: props?.entityId,
				input: { id: selectedId.value, ...form.value },
			}
			const { error } = await apiUpdateIdentity({
				variables,
				fetcher,
			})
			return error
		}
		const handleUpdate = async () => {
			const isFormValid = checkFormValid()
			if (!isFormValid) return
			const hasError = await updateIdentity()
			if (hasError) {
				handleErrorForUser({ error: hasError, $t: t })
				return
			}
			updateList(selectedId.value, form.value)
			closeModalAndClearForm()
		}
		const removeIdentity = async (id) => {
			const variables = {
				entityId: props?.entityId,
				id,
			}
			const { error } = await apiRemoveIdentity({
				variables,
				fetcher,
			})
			return error
		}

		const handleRemove = async (id) => {
			const hasError = await removeIdentity(id)
			if (hasError) {
				handleErrorForUser({ error: hasError, $t: t })
				return
			}
			removeFromList(id)
		}

		// watcher
		watch(
			() => props.fields,
			(updatedVal) => {
				list.value = updatedVal || []
			},
			{
				deep: true,
				immediate: true,
			}
		)

		return {
			computedList,
			showModal,
			form,
			selectedId,
			v,
			clearForm,
			openModal,
			closeModalAndClearForm,
			handleAdd,
			showUpdateModal,
			handleUpdate,
			handleRemove,
		}
	},
}
</script>
