import { plugin, defaultConfig } from "@formkit/vue"
import { createVueApp } from "@/helpers/createVueApp"

import FormField from "@/modules/forms/FormField.vue"
import FormInput from "@/modules/forms/FormInput.vue"
import FormOptionsField from "@/modules/forms/FormOptionsField.vue"

import CNumber from "@/modules/forms/CNumber.vue"
import CAmount from "@/modules/forms/CAmount.vue"
import CCheckbox from "@/modules/forms/CCheckbox.vue"

import customInputsPlugin, { registerComponents } from "@/forms/plugins/customInputs.js"
import listPlugin from "@/forms/plugins/list.js"
import i18nPlugin from "@/forms/plugins/i18n.js"
import fns from "@/forms/plugins/fns.js"
import cast from "@/forms/plugins/cast.js"

export default function createForm(app) {
	app.component("FormField", FormField)
	app.component("FormInput", FormInput)
	app.component("FormOptionsField", FormOptionsField)

	registerComponents({
		CNumber,
		CAmount,
		CCheckbox,
	})

	app.use(
		plugin,
		defaultConfig({
			async iconLoader(iconName) {
				if (iconName) {
					const Icon = await import(`@/modules/core/components/icons/${iconName}.vue`)
					const app = createVueApp(Icon.default)
					const icon = app.mount(document.createElement("div"))

					return icon.$el.outerHTML
				}

				return iconName
			},
			plugins: [i18nPlugin, fns, listPlugin, customInputsPlugin, cast],
		})
	)
}
