/* eslint-disable quotes */
export default {
	rbo: {
		CRM_ID: "Mã CRM",
		noInformation: "Không có thông tin",
		sa: "Giá trị bảo vệ",
		title: "Xin chao",
		step1: "Bước 1",
		gender: "Giới tính",
		age: "Tuổi",
		ageTooltip:
			"Tuổi tính theo ngày sinh nhật gần nhất của KH. Ví dụ: Khách hàng sinh ngày 24/12/2002, nếu ngày điền thông tin trước ngày 24/12/2020 thì khách hàng điền tuổi 17, nếu ngày điền thông tin từ ngày 24/12/2020, khách hàng điền tuổi 18.",
		validAge: "Tuổi phải từ 18 đến 99",
		plsSelectDate: "Chọn ngày sinh",
		plsTypeAge: "Nhập tuổi của bạn",
		dependent: "Người phụ thuộc {number}",
		numberOfDependents: "Số con",
		fullNameOfDependent: "Họ tên của người phụ thuộc",
		ageOfDependent: "Tuổi của con { number }",
		ageOfSpouse: "Tuổi của vợ/chồng",
		numberOfDependentsTooltip:
			"Người phụ thuộc là người có quan hệ nhân thân như bố mẹ, vợ chồng, con đẻ, con nuôi, bố mẹ của vợ/chồng, anh/chị em ruột mà Anh/Chị đang có trách nhiệm về mặt tài chính.",
		spouseDependentName: "Vợ/chồng của khách hàng",
		firstDependentName: "Người phụ thuộc thứ nhất",
		secondDependentName: "Người phụ thuộc thứ hai",
		thirdDependentName: "Người phụ thuộc thứ ba",
		updateCustomerInfoOnCRM:
			"Nếu bạn thay đổi thông tin khách hàng vừa nhận từ CRM, vui lòng cập nhật lại trên T24/CRM sau cuộc hội thoại này",
		successCustomerInfoForm: "Đã lưu thông tin khách hàng thành công!",
		errorCustomerInfoForm: "Vui lòng điền thông tin khách hàng",
		errorGoToNextStepWithCanceledStatus: "Không thể đi tới bước tiếp theo vì cuộc hội thoại đã được huỷ bỏ",
		tellUsAboutYourSelf: "Kể cho chúng tôi về bạn",
		close: "Đóng",
		general: "Trang chủ",
		conversationInfo: "Cuộc Hội Thoại",
		gcmLeadInfo: "CRM Lead",
		welcomeToApp: "Chào mừng bạn đến với iTCBLife",
		planningForFuture: "Hoạch định hôm nay - An nhàn ngày mai",
		tcbInfo:
			"Techcombank là một trong những ngân hàng thương mại hàng đầu tại Việt Nam với nền tảng tài chính bền vững và danh mục sản phẩm - dịch vụ vượt trội.",
		tcbDetailInfo:
			"Tại đây, bạn có thể nhận được sản phẩm TCB tốt nhất của mình chỉ trong 4 bước. Chúng tôi sẽ đi qua một số bộ câu hỏi để biết bạn nhiều hơn.",
		aboutTcb: "Về Techcombank",
		tcbMission: "Thông điệp từ Ban Lãnh Đạo Techcombank",
		business: "Kinh doanh",
		promotionTitle: "Khai xuân bình an, nhận quà như ý",
		promotionContent: "Tặng tiền vào tài khoản của khách hàng hoặc quà tặng có giá trị tương đương",
		businessData:
			"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi obcaecati ex culpa eum veritatis hic deleniti optio aliquid. Voluptatem, fugiat illum. Modi minima voluptatum nam alias eius quo autem veritatis.",
		futureGoals: "Mục tiêu ưu tiên của Khách hàng trong 5-10 năm tới:",
		step: "Bước",
		chooseMoreThanOnePrio: " (Để lên kế hoạch hiệu quả, vui lòng lựa chọn tối đa 3 mục tiêu ưu tiên)",
		childrenEducation: "Giáo dục",
		growWealthAndAssets: "Gia tăng tài sản",
		financeUncertainty: "Bảo vệ tài chính",
		healthAndMedical: "Chăm sóc sức khỏe",
		retirement: "Hưu trí an nhàn",
		back: "Quay lại",
		next: "Tiếp tục",
		QaChildrenOld: "Tuổi của con",
		addChildrenFact: "Anh/Chị vui lòng cho biết tuổi hiện tại của con Anh/Chị",
		childrenAgeTips: "Thêm tối đa 3 con mà anh chị muốn lên kế hoạch cho việc học hành",
		addChildBtn: "Thêm con",
		addAChild: "Thêm tối đa 1 con mà anh chị muốn lên kế hoạch cho việc học hành",
		child: "Con thứ",
		childAge: "CON THỨ {childNumber}:",
		childAgeOrder: "CON THỨ {order}: {age} tuổi",
		saMinError: "Tối thiểu {min}đ",
		saMinErrorUnitLink: "Tối thiểu {min}đ, hoặc một số làm cho phí bảo hiểm lớn hơn 7 triệu 1 năm.",
		saMaxError: "Tối đa {max}",
		saAddError: "Tối thiểu {min} và tối đa {max}",
		saMcError: "Tối thiểu {min} và tối đa {max}",
		saCiError: "Tối thiểu {min} và tối đa {max}",
		generalNullError: "Thông tin nhập vào không hợp lệ",
		childAgeRestrictionError: "Tuổi phải nhỏ hơn 99",
		childAgeToStudyErrorMessage: "Thời gian học nên trong khoảng [0 : 99]",
		QaProtectionTime: "Số năm cần hỗ trợ chi phí sinh hoạt cho gia đình",
		QaProvideIncomeMonthly: "Chi phí sinh hoạt hàng tháng cần bảo đảm",
		obligationLiability: "Tình trạng dư nợ hiện tại",
		obligationLiabilityFact: "Tổng dư nợ hiện tại của Anh/Chị là bao nhiêu?",
		obligationLiabilityTip: "Hiện anh/chị có phải trả góp cho khoản vay mua nhà, nợ mua xe hay nợ tín dụng nào không?",
		QaProvideIncomeToDependants: "Anh chị muốn đảm bảo nguồn thu nhập này cho gia đình trong vòng bao nhiêu năm?",
		provideIncomeMonthlyFact:
			"Anh/Chị mong muốn có khoản đảm bảo sinh hoạt phí hàng tháng để duy trì chất lượng cuộc sống trong mọi tình huống là bao nhiêu?",
		yearProtectionForDependantsFact: "Số năm cần khoản đảm bảo sinh hoạt phí cho gia đình",
		amount: "Số tiền",
		currency: "đ",
		tip: "Tips",
		financeMonthlyIncomeTips: `Các khoản chi phí sinh hoạt hàng tháng bao gồm giáo dục, mua sắm, sinh hoạt, ăn uống, giải trí, hiếu hỉ, làm đẹp, thuốc men,...
			Không bao gồm các khoản nợ phải trả như trả góp mua nhà.`,
		obligationLoanTips: "",
		protectionTimeTips: "",
		saving: "Lưu lại",
		QaChildrenAgeToUseFund: "Độ tuổi bắt đầu sử dụng quỹ giáo dục",
		expectedAgeToUseFundFact: "Anh/ Chị dự định cho con đi học Đại học/ Cao học/ trường nghề tại năm cháu bao nhiêu tuổi?",
		childrenAgeToUseFundTips: "Độ tuổi thông thường bắt đầu đại học là 18",
		QaChildrenTimeToStudy: "Số năm học dự kiến",
		expectedTimeToStudyFact: "Anh/Chị dự kiến thời gian học của con là bao lâu?",
		childrenTimeToStudyTips: "Thông thường thời gian học Đại học là 4 năm, Thạc sĩ là 2 năm (hoặc 1 năm nếu học ở Anh)",
		QaChildrenAnnualEduFree: "Số tiền dự kiến cho mỗi năm học",
		expectedChildrenAnnualEduFeeFact: "Anh/Chị dự định dành cho con khoản ngân sách bao nhiêu mỗi năm?",
		childrenAnnualEduFeeTips: "Hầu hết các trường đại học ở Việt Nam có học phí 100.000 USD / năm",
		conversations: "Truy vấn",
		questionBuyWealthOrPurchase: "Mục tiêu là tích luỹ đầu tư hay gia tăng tài sản?",
		step2: "Bước 2",
		step3: "Bước 3",
		youCanChooseMore: "Bạn có thể chọn nhiều hơn một",
		QaAssetCost: "Loại tài sản và giá trị dự kiến mua",
		bigPurchaseFact: "Anh/Chị đang dự kiến mua tài sản gì và có biết giá trị thời điểm hiện tại khoảng bao nhiêu?",
		typeOfItem: "Loại tài sản",
		nameOfItem: "Tên tài sản",
		plsPickResult: "Chọn kết quả tư vấn",
		tips: "Mẹo",
		addItem: "THÊM MỤC",
		QaWhenWillMakePurchase: "Dự kiến mua trong bao nhiêu năm nữa?",
		planToBuy: "Dự kiến mua trong bao nhiêu năm nữa?",
		planToBuyFact: "",
		quesTargetSaving: "Mục tiêu tích luỹ/đầu tư của Khách hàng",
		expectedSaving: "Tiết kiệm dự kiến của bạn",
		expectedSavingFact: "Anh/Chị mong muốn giá trị của khoản tích lũy/đầu tư trong tương lai là bao nhiêu?",
		quesReachTargetSaving: "Thời gian dự kiến đạt kế hoạch",
		numberOfYearReachTargetSaving: "Số năm để đạt được mục tiêu tiết kiệm của bạn",
		yearReachTargetSavingsFact: "Anh/Chị mong muốn đạt được kế hoạch này trong bao nhiêu năm?",
		areYouSureYouWantToQuit: "Bạn có chắc chắn muốn thoát khỏi cuộc trò chuyện hiện tại không?",
		quitAndDontSave: "Thoát & Không Lưu",
		quitAndSave: "Thoát & Lưu",
		dropoutReason: "Lý do thoát khỏi?",
		submit: "Hoàn tất",
		ok: "OK",
		notInterested: "Khách hàng không quan tâm",
		productNotSuit: "Khách hàng chưa có nhu cầu",
		customerBusy: "Khách hàng bận",
		otherDropOffReason: "Khác",
		typeReasonPlaceholder: "Vui lòng nhập lý do",
		otherReasonHeader: "Ghi chú",
		otherReasonNote: "Ghi chú và lý do nếu có...",
		createLead: "Tạo Lead",
		createLeadSuccessMessage: "Tạo mã lead thành công!",
		referLeadSuccessMessage: "Refer mã lead thành công!",
		referLeadFNASuccessMessage: "Tạo file FNA thành công!",
		createLeadErrorMessage: "Có lỗi khi tạo mã lead.",
		referLeadErrorMessage: "Có lỗi khi refer mã lead.",
		referLeadFNAErrorMessage: "Có lỗi khi tạo file FNA.",
		itcblifeSystemErrorMessage: "Lỗi liên kết với hệ thống TCB. Vui lòng liên hệ admin để được giải đáp và hướng dẫn.",
		itcblifeSystemTimeoutMessage: "Tạm thời hệ thống không xử lí được yêu cầu của bạn, vui lòng thử lại sau vài phút nữa.",
		itcblifeRecaptchaMessage:
			"Có lỗi khi kiểm tra reCAPTCHA. Vui lòng thực hiện lại việc tìm kiếm lead (1). Trong trường hợp đã thực hiện (1) nhưng vẫn gặp lỗi này, vui lòng gửi email tới TO_itcblife@techcombank.com.vn để được trợ giúp!",
		mvpVersionErrorMessage: "Không thể tích hợp được với hệ thống TCB vì cuộc hội thoại này được tạo từ mvp 1.5",
		noConversationResultSelectedMessage: "Cuộc hội thoại này chưa có kết quả xử lý. Hãy truy cập vào bước 7 để lựa chọn kết quả.",
		selectConversationResultBeforeMappingLeadMessage: "Vui lòng tiếp tục thực hiện cuộc hội thoại trước khi gắn mã Lead CRM",
		QaBenefitsHealthInterested: "Quyền lợi bảo vệ khách hàng quan tâm",
		expectedBenefitsHealthFact: "Bạn có thể chọn nhiều lợi ích",
		medicalCare: "Trợ cấp y tế",
		healthCare: "Chi phí y tế",
		accidentAndDisability: "Thương tật do tai nạn",
		CI: "Điều trị bệnh hiểm nghèo",
		benefitsHealthInterestedTips: "lorem lorem",
		QaHealthRoomNBoard: "Trợ cấp y tế",
		expectedHealthRoomNBoardFact: "Mức trợ cấp mất giảm thu nhập/ngày anh chị thấy phù hợp?",
		expectedHealthRoomNBoardTip: "Mức trợ cấp thông thường bằng 75% thu nhập theo ngày, và không vượt quá 3 triệu VNĐ/ngày.",
		QaHealthcare: "Chi phí y tế",
		roomAndBoardExpense: "Quyền lợi y tế anh chị đang quan tâm?",
		outpatientBenefitQuestion: "Anh chị có muốn mua thêm quyền lợi Điều trị ngoại trú?",
		QaHealthAccidentCover: "Trợ cấp thương tật do tai nạn",
		expectedHealthAccidentCoverFact: "Mức bồi thường cho rủi ro tai nạn Anh/Chị mong muốn là bao nhiêu?",
		healthAccidentCoverTips:
			"Số liệu: Toàn quốc có 14,000 vụ tai nạn giao thông năm 2020.\n" + "Nguồn: Ủy ban an toàn giao thông quốc gia 2020",
		QaHealthCriticalIllness: "Điều trị bệnh hiểm nghèo",
		expectedHealthCriticalIllnessFact: "Ngân quỹ dành riêng cho bệnh lý nghiêm trọng mà anh chị cần là bao nhiêu?",
		healthCriticalIllnessTips1: "22% Bệnh nhân gặp khó khăn về tài chính sau một năm mắc bệnh.",
		healthCriticalIllnessTips2: "34% Bệnh nhân không thể chi trả tiền thuốc.",
		healthCriticalIllnessTips3: "25% Bệnh nhân không thể chi trả chi phí gia tăng.",
		healthCriticalIllnessTips4: "21% Bệnh nhân không thể chi trả chi phí đi lại.",
		QARetirement: "Tuổi dự kiến về hưu",
		retirementAgeFact: "Anh chị mong muốn về hưu năm bao nhiêu tuổi?",
		retirementAgeTip: "Độ tuổi về hưu tại Việt Nam là 65 đối với nam, và 60 đối với nữ.",
		QaRetirementMonthlyExpense: "Chi phí sinh hoạt hàng tháng khi về hưu",
		retirementMonthlyExpenseFact: "Khoản thu nhập hàng tháng sau khi về hưu mà anh chị mong muốn là bao nhiêu?",
		retirementMonthlyExpenseTip:
			"Khoản thu nhập này dùng để chi trả những chi phí cần thiết như du lịch, nghỉ dưỡng, ăn uống, chăm sóc sức khỏe và nợ (nếu có).",
		retirementTypicallyIncomeFact: "Anh/Chị mong muốn được hỗ trợ chi phí sinh hoạt sau khi về hưu là bao nhiêu?",
		QaHowLongRetirement: "Thời gian sử dụng quỹ hưu trí sau khi về hưu",
		howLongRetirementFact: "Số năm dự kiến sử dụng quỹ hưu trí?",
		howLongRetirementTip:
			"Trung bình thời gian sử dụng quỹ hưu trí là 20 năm đối với nam, và 25 năm đối với nữ.\n" +
			"Căn cứ theo độ tuổi về hưu và tuổi thọ trung bình của người Việt Nam.",
		step4: "Bước 4",
		QaCurrentMonthlyIncome: "Mức thu nhập hàng tháng hiện tại",
		QaCurrentMonthlyIncomeFact: "Anh/Chị có thể chia sẻ về tổng thu nhập hàng tháng của cả gia đình mình?",
		detailOfIncomeIfAny: "Chi tiết các khoản thu nhập (nếu có)",
		investmentBusinessIncome: "Thu nhập từ đầu tư, kinh doanh",
		salaryBonusIncome: "Thu nhập từ lương, tiền thưởng",
		rentalIncome: "Cho thuê bất động sản",
		bankInterestIncome: "Tiền lãi ngân hàng",
		dividendIncome: "Cổ tức",
		otherIncome: "Các khoản thu khác",
		totalIncome: "Tổng thu nhập",
		incomeDetailsEqualToTotalMonthlyIncome: "Tổng các khoản mục chi tiết phải bẳng mức thu nhập hàng tháng",
		selectApproximateFact: "Vui lòng chọn một phạm vi gần đúng",
		plsSelect: "Vui lòng chọn",
		QaCurrentHouseHoldExpenses: "Thông tin thu nhập - chi phí",
		QaCurrentHouseHoldExpensesFact: "Anh/Chị có thể chia sẻ một số thông tin thu nhập - chi phí của gia đình mình?",
		QaCurrentExpensesFact: "Anh/Chị có thể chia sẻ một số thông tin thu nhập - chi phí hàng tháng?",
		detailOfExpenseIfAny: "Chi tiết các khoản chi phí (nếu có)",
		householdLivingExpense: "1. Chi phí sinh hoạt hộ gia đình/tháng",
		livingExpense: "1. Chi phí sinh hoạt/tháng",
		pleaseInputFields: "2. Vui lòng điền 1 trong 2 thông tin sau",
		educationExpense: "Chi phí giáo dục",
		contingencyBudget: "Khoản dự phòng",
		entertainmentExpense: "Chi phí giải trí, giao tế, hiếu hỷ,...",
		insuranceExpense: "Phí bảo hiểm",
		liabilityExpense: "Nợ phải trả",
		otherExpense: "Chi phí khác",
		totalExpense: "Tổng chi phí",
		expenseDetailsEqualToTotalMonthlyExpense: "Tổng các khoản mục chi tiết phải bẳng mức chi phí sinh hoạt hàng tháng",
		QaAccumulatedSavings: "Số tiền tích lũy hiện tại cho mục tiêu ưu tiên",
		currentSavingFact:
			"Hiện tại, khoản tích lũy/đầu tư hay tài sản mà Anh/Chị hoạch định riêng để thực hiện cho mục tiêu là bao nhiêu?",
		currentSavingTip: "Bao gồm cổ phiếu, trái phiếu hay bất động sản có thể thanh khoản,...",
		QaPersonalLifeInsuranceCoverage: "Giá trị gói bảo hiểm nếu Khách hàng đang có",
		insuranceTotalSumFact:
			"Anh/Chị đã tham gia gói bảo hiểm nhân thọ nào trước đây chưa? Tổng giá trị bảo vệ của các gói bảo hiểm nhân thọ Anh/Chị đã tham gia là bao nhiêu?",
		editModalRetirementTitle: "Hưu trí",
		editModalRetirementAge: "Tuổi nghỉ hưu",
		editModalRetirementExpenses: "Chi phí hàng tháng",
		editModalRetirementPeriod: "Thời gian nghỉ hưu",
		closeBtn: "Đóng",
		editModalWealthAssets: "Tích lũy & Gia tăng tài sản",
		house: "Nhà",
		car: "Xe hơi",
		others: "Món khác",
		currentValue: "Giá trị dự kiến",
		yearsAmount: "Số năm",
		asset: "Tiêu sản",
		targetSaving: "Khoản tiết kiệm hiện tại của bạn",
		expectedTimeToReachTargetSaving: "Thời gian dự kiến để đạt được mục tiêu tiết kiệm",
		editModalChildrenEduAge: "Giáo dục",
		editModalChildrenEduChildAge: "Tuổi của trẻ",
		editModalChildrenEduAgeToUseFund: "Tuổi bắt đầu học",
		editModalChildrenEduExpectedEducationFee: "Mức chi phí hằng năm",
		editModalChildrenEduStudyTime: "Số năm học",
		editModalFinanceUncertainTitle: "Bảo đảm tài chính cho gia đình",
		editModalFUMonthlyIncomeNeeded: "Chi phí sinh hoạt hàng tháng",
		editModalFUMCurrentLoan: "Giá trị khoản vay",
		editModalFUMProtectionTime: "Số năm cần hỗ trợ",
		editModalHealthTitle: "Chi phí y tế",
		editModalHealthHospitalised: "Trợ cấp y tế",
		editModalHealthcare: "Chi phí y tế",
		editModalHealthAccidentNDisabilityCoverAmount: "Trợ cấp điều trị thương tật do tai nạn",
		editModalHealthCriticalIllnessAmount: "Trợ cấp trước rủi ro bệnh lý",
		step5: "Bước 5",
		summary: "Tổng hợp",
		titleSummary: "Với tình hình tài chính hiện tại, Anh/Chị mới đạt được",
		titleSummaryCongrats:
			"Chúc mừng anh chị đã có kế hoạch tốt cho tương lai, chúng tôi sẽ giúp anh chị bảo vệ mục tiêu đó cho tương lai",
		titleSummaryOfPercent: "70%",
		titleSummarySub: "mục tiêu của mình, chúng tôi sẽ hỗ trợ Anh/Chị đạt được mục tiêu với giải pháp phù hợp nhất.",
		edit: "Sửa",
		currentProtection: "Phạm vi bảo vệ hiện tại",
		interest: "Quan tâm",
		inflation: "Lạm phát",
		thanksForChoosingUs: "Cảm ơn vì đã chọn chúng tôi!",
		yourConversationId: "Mã hội thoại:",
		titleFinishStep: "Hãy để chúng tôi tiếp tục với đơn bảo hiểm của bạn và cung cấp cho bạn mức bảo hiểm & bảo vệ cần thiết.",
		yourGoal: "Mục tiêu ưu tiên",
		iWantToGrow:
			"Tôi muốn gia tăng sự giàu có và tài sản của mình, bảo vệ tài chính của mình khỏi tác động của bệnh tật / sức khỏe xấu và lên kế hoạch cho việc nghỉ hưu của tôi.",
		yourProduct: "Sản phẩm lựa chọn",
		finish: "Kết thúc",
		downloadReport: "Tải xuống",
		step6: "Bước 6",
		customizeProduct: "Tùy chỉnh sản phẩm",
		selectYourProduct: "Lựa chọn giải pháp",
		coverYourShortfall: "Để trang trải khoản thiếu hụt 5.000.000đ của bạn, chúng tôi nên gợi ý những sản phẩm sau",
		choose: "Chọn",
		highlyRecommend: "SẢN PHẨM PHÙ HỢP",
		paymentTerm: "Thời hạn thanh toán",
		chooseOneOfAssetsFact: "Trong 2 mục tiêu dưới đây, Anh/Chị đang ưu tiên mục tiêu nào hơn?",
		assetsTips: "Anh/Chị đang dự kiến mua tài sản gì và giá trị thời điểm hiện tại khoảng bao nhiêu?",
		assetsBigPurchaseTips: "Lựa chọn tối đa 3 tài sản",
		assetHouse: "Nhà",
		assetCar: "Ô tô",
		assetOthers: "Khác",
		reachTargetSavingTips: "Lorem lorem",
		assetPlanToBuyTips: "Lorem lorem",
		assetTargetSavingTips: "Lorem lorem",
		accumulatedSavingTips: "",
		currentProtectionCoverageTips: "",
		targetSavingTip: "Tích lũy tài sản là một ưu tiên dài hạn. Thông thường thời gian tích lũy là 10-15 năm.",
		householdExpenseTips:
			"Các khoản chi phí sinh hoạt hàng tháng bao gồm: Giáo dục, mua sắm, sinh hoạt, ăn uống, giải trí, giao tế, hiếu hỉ, làm đẹp, thuốc men,...",
		householdIncome: "Lorem",
		householdIncomeTips:
			"Thu nhập của KH bao gồm: thu nhập chính (tiền lương, thưởng, thu nhập từ hoạt động kinh doanh chính), thu nhập phụ (cho thuê nhà, đầu tư BĐS, chứng khoán,...)",
		retirementTips: "Độ tuổi về hưu trung bình tại Việt Nam là 65 tuổi",
		retirementMonthlyExpenseTips:
			"Khoản thu nhập này dùng để chi trả những chi phí cần thiết như du lịch, nghỉ dưỡng, ăn uống, chăm sóc sức khỏe và nợ (nếu có).",
		retirementTimeTips: "Thông thường thời gian sử dụng quỹ hưu trí là 20-30 năm",
		logoutText: "Đăng xuất",
		areYouSureToLogOut: "Bạn có chắc chắn muốn thoát khỏi phiên đăng nhập này không?",
		cancelBtn: "Huỷ",
		learnMore: "Xem thêm",
		loading: "TẢI...",
		getStarted: "BẮT ĐẦU",
		male: "Nam",
		female: "Nữ",
		search: "Tìm kiếm",
		searchCustomer: "Tìm kiếm khách hàng",
		createNewCustomer: "Tạo khách hàng mới",
		selectType: "Hãy lựa chọn",
		pleaseSelect: "Hãy lựa chọn",
		citizenId: "CMTND/CCCD",
		phoneNumber: "Số điện thoại",
		email: "Email",
		customerId: "Mã khách hàng",
		passportId: "Hộ chiếu",
		gcmLeadId: "Mã CRM Lead",
		leadId: "Mã Lead",
		createdDate: "Ngày tạo",
		status: "Trạng thái",
		vietnameseToneRequired: "(Tiếng Việt có dấu)",
		systemFailedToGetLeadInfo: "Hệ thống không tìm được thông tin của mã Lead này",
		leadPending: "Chờ xử lý",
		leadProcessing: "Đang xử lý",
		leadCompleteProcessing: "Kết thúc xử lý",
		gcmLeadIdNote: "Vui lòng chọn 1 mã lead CRM phù hợp hoặc tạo mới",
		gcmLeadIdNotFoundNote: "Khách hàng chưa có bất kì mã lead nào. Vui lòng tạo mới.",
		gcmLeadIdFoundNote: "Không có thông tin CRM Lead. Vui lòng tạo lead CRM mới để tiếp tục",
		customerInfoNote: "Chưa có thông tin khách hàng trên hệ thống Techcombank. Vui lòng nhập thông tin bên dưới",
		customerInfoWithNoLeadIdNote: "Chưa có lead CRM. Vui lòng điền các thông tin bên dưới",
		gcmLeadIdCreatedBeforeFinish: "Vui lòng tạo mã lead CRM trước khi kết thúc cuộc hội thoại",
		gcmLeadIdCreatedBeforeReferral: "Vui lòng tạo mã lead CRM trước khi đồng ý refer",
		caseFromMVP15WithNoLead: "Vui lòng nhập đầy đủ thông tin khách hàng tại bước 1 trước khi tạo lead.",
		caseFromMVP15WithNoLeadRefer: "Vui lòng nhập đầy đủ thông tin khách hàng tại bước 1 trước khi refer lead.",
		caseFromMVP15WithNoLeadPopup: "Vui lòng nhập đầy đủ thông tin khách hàng trước khi thực hiện gắn lead CRM.",
		caseFromMVP15WithLead:
			"Đối với các cuộc hội thoại đã gắn lead và chưa có thông tin định danh khách hàng, bạn chỉ có thể xem chứ không thể tiếp tục hoặc chỉnh sửa cuộc hội thoại. Vui lòng tạo cuộc hội thoại mới để tư vấn khách hàng.",
		caseWithNoAgentCode: "Vui lòng nhập agent code trước khi refer.",
		fullName: "Họ tên",
		idNumber: "Mã số cá nhân",
		dob: "Ngày sinh",
		maritalStatus: "Tình trạng hôn nhân",
		single: "Độc thân",
		married: "Đã kết hôn",
		divorced: "Ly hôn",
		widowed: "Goá phụ",
		meetingMethod: "Phương thức tiếp xúc",
		meetOnline: "Gặp online",
		meetOffline: "Gặp offline",
		customerDetails: "Thông tin cơ bản",
		goals: "Mục tiêu ưu tiên",
		questions: "Am hiểu mục tiêu",
		having: "Tài chính hiện tại",
		selectProduct: "Đề xuất giải pháp",
		deathCoverageBenefit: "Bảo vệ trước rủi ro tử vong",
		accidentalDeathBenefit: "Bảo vệ trước rủi ro tử vong do tai nạn",
		medicalSupportBenefit: "Trợ cấp y tế mở rộng",
		healthcareBenefit: "Sống khoẻ mỗi ngày",
		criticalIllnessBenefit: "Bảo hiểm bệnh lý nghiêm trọng nâng cao",
		criticalIllnessPlusBenefit: "Bảo hiểm bệnh lý nghiêm trọng mở rộng",
		accidentDismembermentBenefit: "Bảo hiểm Tử vong và Thương tật do tai nạn nâng cao",
		permanentDisabilityBenefit: "Bảo vệ trước rủi ro thương tật toàn bộ và vĩnh viễn",
		financeSupportBenefit: "Hỗ trợ tài chính",
		titanProductDescription:
			"Giải pháp bảo hiểm kết hợp tích lũy an toàn 15 năm với thời hạn đóng phí ngắn và quyền lợi bảo vệ toàn diện trước y tế, tai nạn.",
		ul038ProductDescription:
			"Giải pháp tích lũy an toàn đáp ứng đa mục tiêu với thời gian tham gia linh hoạt. Bảo vệ trước rủi ro tai nạn, bệnh hiểm nghèo, trợ cấp y tế và chi phí y tế bao gồm Nội trú, ngoại trú và nha khoa.",
		unitLinkedProductDescription:
			"Giải pháp đầu tư hiệu quả với thời gian tham gia linh hoạt. Bảo vệ toàn diện trước tai nạn, bệnh hiểm nghèo, trợ cấp y tế, và chi phí y tế.",
		eduProductDescription:
			"Giải pháp xây dựng kế hoạch học vấn cho con, kết hợp bảo vệ toàn diện trước rủi ro tai nạn, trợ cấp y tế và chi phí y tế.",
		titanProductName: "An Gia Như Ý",
		titan_6yearsProductName: "An Gia Như Ý (6 năm)",
		titan_10yearsProductName: "An Gia Như Ý (10 năm)",
		titan_6ProductName: "An Gia Như Ý (6 năm)",
		titan_10ProductName: "An Gia Như Ý (10 năm)",
		superLink: "Legacy",
		superLinkHeadline: "Bảo hiểm kết hợp đầu tư LEGACY. Điểm tựa toàn diện – Cho vạn bước tiến",
		expectedProduct: "Sản phẩm dự kiến",
		titanProductHeadline: "Tâm An Hiện Tại, Điểm Tựa Tương Lai",
		ul038ProductName: "Bách Lộc Toàn Gia Vượt Trội (Tiết Kiệm)",
		ul040ProductName: "Bách Lộc Toàn Gia Vượt Trội (Bảo vệ)",
		unitLinkedProductName: "Đắc Lộc Tâm An",
		eduProductName: "An Nhi Bảo Phúc Ưu Việt",
		chooseOneOfOptions: "Vui lòng chọn 1 lựa chọn phù hợp",
		finishFNAConfirmationMessage: "Xác nhận dừng cuộc trò chuyện!",
		finishFNACongratsMessage: "Chúc mừng! Bạn vừa hoàn thành xong một FNA.",
		totalBenefitValue: "Tổng quyền lợi bảo hiểm là {amount}",
		benefitMaximumPay: "Chi trả lên đến {amount} cho mỗi ngày nằm viện",
		benefitDoublePayment: "Chi trả gấp đôi khi nằm tại khoa chăm sóc đặc biệt",
		benefitPaymentRefund: "Hoàn lại {amount} khi không có yêu cầu bồi thường y tế trong năm",
		minValueForProduct: "Sản phẩm",
		accidentalDeathCoverage: "Chi trả từ {min} đến {max} nếu tử vong do tai nạn",
		accidentalDeathMaxCoverage: "Chi trả lên đến {max} nếu tử vong do tai nạn",
		generalAccidentalDeathCoverage: "Chi trả {amount} nếu tử vong do tai nạn thông thường",
		publicDeathCoverage:
			"Chi trả {amount} nếu tử vong do tai nạn giao thông, tai nạn thang máy hoặc hỏa hoạn tại các tòa nhà công cộng",
		planeCrashCoverage: "Chi trả {amount} nếu tử vong do tai nạn máy bay",
		healthcareCoverage:
			"Quyền lợi bảo hiểm chăm sóc sức khỏe bao gồm: chi phí phòng giường/ngày, chi phí phẫu thuật và nội trú khác, chi phí khám và điều trị ngoại trú (nếu có).",
		accidentalDismembermentCoverage: "Chi trả lên đến {amount} trước các rủi ro thương tật, gãy tay, bỏng, tổn thương nội tạng",
		criticalIllnessProtection: "Chi trả lên đến {amount} trước 134 bệnh lý nghiêm trọng qua 3 giai đoạn",
		criticalIllnessProtectionForEdu: "Chi trả lên đến {amount} đối với bệnh lý nghiêm trọng cho trẻ em",
		financialSupport: "Chi trả mỗi năm cho đến hết thời hạn đóng phí với khoản hỗ trợ {amount}, đồng thời miễn đóng phí hợp đồng",
		setting: "Thiết lập",
		dropOutReason: "Lý do dừng",
		conversationResult: "Kết quả",
		gcmLeadInfoTableNote: "CRM Lead đang xử lý",
		conversationInfoTableNote:
			'Cuộc hội thoại đang xử lý và cuộc hội thoại đã hoàn tất với trạng thái "Khách hàng đang cân nhắc"',
		leadGCM: "Mã Lead CRM",
		conversationId: "Mã hội thoại",
		customerName: "Tên Khách Hàng",
		transactionDate: "Ngày Giao Dịch",
		actions: "Thao Tác",
		startFNA: "Bắt đầu FNA",
		referLead: "Refer Lead",
		inProgress: "Đang xử lý",
		"IN PROGRESS": "Đang thực hiện",
		completed: "Hoàn thành",
		canceled: "Huỷ bỏ",
		COMPLETED: "Hoàn thành",
		paused: "Tạm dừng",
		dropoff: "Huỷ",
		buy: "Mua",
		wait: "Chờ",
		notBuy: "Không mua",
		growWealth: "Tích luỹ đầu tư",
		bigPurchase: "Gia tăng tài sản",
		buyWealthOrPurchaseTips: "Tích luỹ đầu tư: tiết kiệm/đầu tư cho tương lai.",
		buyWealthOrPurchaseTips1: "Gia tăng tài sản: mua sắm tài sản lớn như mua nhà, mua xe.",
		yourAccumulatedSaving: "Số tiền tích luỹ",
		currentProtectionCoverage: "Số tiền bảo hiểm đã có",
		interestRate: "Lãi suất",
		inflationRate: "Lạm phát",
		educationInflation: "Lạm phát giáo dục",
		productSelectionMessage: "Để bù đắp khoản thiếu hụt",
		productSelectionMessageSub: "chúng tôi xin gợi ý các giải pháp sau",
		totalBenefits: "Tổng quyền lợi",
		productFeatures: "Quyền lợi khác",
		promotion: "Ưu đãi",
		estPremium: "Phí bảo hiểm dự kiến",
		customize: "Tuỳ chỉnh",
		year: "Năm",
		month: "Tháng",
		accidentalDeathRider: "Bảo hiểm tử vong và thương tật do tai nạn nâng cao",
		medicalRider: "Trợ cấp y tế mở rộng",
		healthcareRider: "Sống khỏe mỗi ngày",
		outpatientRider: "Sống khỏe mỗi ngày - Ngoại trú",
		yes: "Có",
		no: "Không",
		conversationResultOnceFinished: "Kết quả tư vấn",
		agentCode: "Người chốt deal",
		searchAgentCode: "Chọn người nhận refer",
		sumAssured: "Giá trị bảo vệ",
		childrenEducationGoal: "Giáo dục",
		growWealthAndAssetsGoal: "Gia tăng tài sản",
		financeUncertaintyGoal: "Bảo vệ tài chính",
		healthAndMedicalGoal: "Chăm sóc sức khỏe",
		retirementGoal: "Hưu trí an nhàn",
		childrenEducationChartLabel: "Giáo dục",
		growWealthAndAssetsChartLabel: "Tích luỹ",
		growWealthChartLabel: "Gia tăng tài sản",
		financeUncertaintyChartLabel: "Bảo vệ tài chính",
		healthAndMedicalChartLabel: "Y tế",
		healthCareChartLabel: "Chi phí y tế",
		retirementChartLabel: "Nghỉ hưu",
		currentCoverageChartLabel: "Bảo hiểm",
		savingChartLabel: "Tiết kiệm",
		shortfallChartLabel: "Thiếu hụt",
		houseChartLabel: "Nhà",
		othersChartLabel: "Khoản khác",
		carChartLabel: "Ô tô",
		child1ChartLabel: "Con thứ nhất",
		child2ChartLabel: "Con thứ hai",
		child3ChartLabel: "Con thứ ba",
		medicalCareChartLabel: "Trợ cấp y tế",
		accidentAndDisabilityChartLabel: "Tai nạn",
		CIChartLabel: "Bệnh nặng",
		expectedGoalMessage: "Tôi muốn lên kế hoạch cho ",
		and: "và",
		event: "Giáng Sinh",
		descriptionProductFeaturesUnitLinked:
			"Hưởng toàn bộ kết quả đầu tư của các Quỹ Liên kết đơn vị (6 quỹ), cùng với các khoản thưởng duy trì hợp đồng hấp dẫn",
		descriptionProductFeaturesEdu: "Bảo tức định kỳ hàng năm cùng Quyền lợi Đăng Khoa 100.000.000đ",
		descriptionProductFeaturesTitan:
			"Hưởng mức lãi suất thực tế từ Quỹ Liên kết chung, cùng với các khoản thưởng duy trì hợp đồng",
		descriptionProductFeaturesTitanSub: "Mức lãi suất thực tế không thấp hơn mức lãi suất cam kết",
		descriptionProductFeaturesUl038:
			"Hưởng mức lãi suất thực tế từ Quỹ Liên kết chung, cùng với các khoản thưởng duy trì hợp đồng",
		descriptionProductFeaturesUl038Sub: "Mức lãi suất thực tế không thấp hơn mức lãi suất cam kết",
		criticalIllnessRider: "Bảo hiểm bệnh lý nghiêm trọng nâng cao",
		criticalIllnessRiderPlus: "Bảo hiểm bệnh lý nghiêm trọng mở rộng",
		role: "Vị trí",
		branch: "Tên chi nhánh",
		username: "Tên đăng nhập",
		emailAddress: "Địa chỉ email",
		englishFirstName: "Tên",
		englishLastName: "Họ",
		englishFullName: "Họ và tên",
		changePassword: "Đổi mật khẩu",
		update: "Cập nhật",
		currentPassword: "Mật khẩu hiện tại",
		newPassword: "Mật khẩu mới",
		confirmPassword: "Xác nhận mật khẩu",
		updatedSuccessfully: "Cập nhật thành công",
		failToUpdate: "Cập nhật thất bại",
		initializePDF: "Đang khởi tạo PDF",
		interestToolTip:
			"Là mức lãi suất sinh lời dự kiến cho các khoản đầu tư hiện tại. Mức lãi suất đang thể hiện căn cứ theo mức lãi suất lãi suất tiền gửi 12 tháng 5 ngân hàng TCB, VCB, VTB, BIDV, AGRI tại 05/2021.",
		interestToolTipSub1: "2019: 5.8%",
		interestToolTipSub2: "2018: 6.4%",
		interestToolTipSub3: "2017: 7.25%",
		interestToolTipSub4: "Nguồn: thư viện pháp luật",
		inflationRateToolTip:
			"Là tốc độ tăng mặt bằng giá của nền kinh tế. Tỉ lệ đang hiển thị căn cứ theo mức lạm phát bình quân các năm gần đây. Ví dụ:",
		inflationRateToolTipSub1: "2017: 4.2%",
		inflationRateToolTipSub2: "2016: 2.7%",
		inflationRateToolTipSub3: "2015: 0.6%",
		inflationRateToolTipSub4: "2014: 4.1%",
		inflationRateToolTipSub5: "2013: 6.6%",
		inflationRateToolTipSub6: "Nguồn: Ngân hàng quốc tế",
		userSetting: "Thông tin cá nhân",
		yesQes: "Có",
		noQes: "Không",
		tcbsEmailAddress: "Địa chỉ mail TCB",
		dropOffReason: "Lý do khác",
		errorMessageSelection: "Vui lòng chọn ít nhất 1 sản phẩm",
		errorMessageRequired: "Vui lòng điền đầy đủ thông tin",
		errorMessageForValidNumber: "Vui lòng nhập từ 0 đến 99",
		unit: "Đơn vị: Triệu đồng",
		goal: "Mục tiêu",
		shortfall: "Thiếu hụt",
		customizeProductDescription: "Bạn có thể thay đổi các thông tin sau để tuỳ chỉnh sản phẩm.",
		conversationResultOfNotInterested: "Khách hàng không quan tâm",
		noDemand: "Khách hàng chưa có nhu cầu",
		anotherDemand: "Khách hàng có nhu cầu sản phẩm khác",
		onConsider: "Khách hàng đang cân nhắc",
		referToTIS: "Thiết kế giải pháp cùng chuyên gia",
		agreeToApply: "Chuyển EPOS/AWS thiết kế bảng minh họa",
		conclusionMessage:
			"Cuộc hội thoại của bạn đã tạm dừng. Bạn có thể tìm lại và tiếp tục cuộc hội thoại bằng cách truy cập vào mục Truy vấn với mã cuộc hội thoại: {conversationId}",
		canceledConclusionMessage:
			"Cuộc hội thoại của bạn đã bị huỷ. Bạn có thể tìm lại cuộc hội thoại bằng cách truy cập vào mục Truy vấn với mã cuộc hội thoại: {conversationId}",
		summaryTitle: "Bảng tổng hợp",
		SATitle: "Số tiền bảo vệ cơ bản",
		protectCurrentSituation: "Chúng tôi xin gợi ý các giải pháp sau giúp Anh/Chị bảo vệ kế hoạch của mình",
		finishMessage:
			"Thông tin cuộc hội thoại đã được gửi tới hòm thư của bạn. Bạn có thể tìm lại cuộc hội thoại bằng cách truy cập vào mục Truy Vấn.",
		finishMessageYesLeadNoReferral:
			"<div class='text-center'>Thông tin cuộc hội thoại đã được gửi tới email của bạn. Bạn có thể tìm lại cuộc hội thoại bằng cách truy cập vào mục Truy vấn.</div>",
		finishMessageNoLeadNoReferral:
			"<div><div>Thông tin cuộc hội thoại đã được gửi tới email của bạn. Vui lòng tạo lead CRM và gắn lead CRM với cuộc hội thoại này bằng cách:</div><div>(1) Tạo lead trên CRM sau đó gắn cho Cuộc hội thoại tại màn hình Truy vấn</div><div>(2) Tạo lead trực tiếp trên iTCBLife tại mục Truy vấn</div></div>",
		finishMessageNoLeadYesReferral:
			"<div class='leading-relaxed'><div>Thông tin cuộc hội thoại đã được gửi tới email của bạn. Bạn có thể tìm thấy cuộc hội thoại này tại màn hình “Truy vấn” với ID <span class='font-bold'>{conversationId}</span>.</div><div>Thông tin FNA chưa được chuyển sang hệ thống MVL, vui lòng tạo CRM Lead trước khi refer sang Manulife theo 1 trong 2 cách sau:</div><div>- Tạo lead trên CRM sau đó gắn cho Cuộc hội thoại tại màn hình “Truy vấn”</div><div>- Tạo lead trực tiếp trên iTCBLife tại mục “Truy vấn”</div></div>",
		finishMessageYesLeadYesReferral:
			"<div>Thông tin cuộc tư vấn đã được gửi đến hòm thư của bạn. Bạn có thể tìm lại cuộc hội thoại bằng cách truy cập vào mục \"Truy vấn\" với mã cuộc hội thoại <span class='font-bold'>{conversationId}</span>.</div>",
		finishMessageFromPopupYesLeadYesReferral:
			"<div>Cuộc hội thoại đã được gắn mã lead CRM thành công. Vui lòng truy cập hệ thống của Manulife để tiếp tục.</div>",
		finishMessageFromTab3Referral: "<div>Refer thành công. Vui lòng tải biểu mẫu FNA lên hệ thống Manulife.</div>",
		createLeadErrorRetryAgain:
			"<div><div>Tạo lead CRM bị lỗi, bạn có muốn thử lại? Nếu không tạo lại, bạn có thể thực hiện bằng 1 trong 2 cách như sau:</div><div>(1) Tạo lead trên CRM sau đó gán cho Cuộc hội thoại tại màn hình Truy vấn</div><div>(2) Tạo lead trực tiếp trên iTCBLife tại mục Truy vấn</div></div>",
		createLeadFromPopupErrorRetryAgain: "<div>Tạo lead bị lỗi. Bạn có muốn thử lại không?</div>",
		referLeadBancaAndManulifeError:
			"<div><div>Thông tin cuộc hội thoại đã được gửi tới email của bạn.</div><div>Có lỗi khi refer sang Manulife: <span class='text-c1-500'>[error code: {statusCode}] [error content: {statusDescription}]</span>.</div><div>Bạn có muốn thực hiện refer lại?</div></div>",
		referLeadRetry1:
			"<div><div>Có lỗi khi refer sang Manulife: <span class='text-c1-500'>[error code: {statusCode}] [error content: {statusDescription}]</span>.</div><div>Bạn có muốn thực hiện refer lại?</div></div>",
		referLeadRetry2:
			"<div><div>Có lỗi khi refer sang Manulife: <span class='text-c1-500'>[error code: {statusCode}] [error content: {statusDescription}]</span>.</div><div>Vui lòng truy cập CRM để thực hiện bước refer.</div></div>",
		errorSytemGCM: "Có lỗi xảy ra khi refer sang bên hệ thống Manulife (Internal Server Error)",
		finishedMessageSuccessLeadIdMapping: "<div>Cuộc hội thoại đã được gắn mã lead CRM thành công.</div>",
		searchLeadFailed: "Tìm kiếm lead bị lỗi.",
		createLeadFailed: "Tạo lead bị lỗi.",
		updateLeadFailed: "Cập nhật lead bị lỗi.",
		referLeadFailed: "Refer lead bị lỗi.",
		england: "Anh",
		america: "Mỹ",
		australia: "Úc",
		singapore: "Singapore",
		vietnam: "Việt Nam",
		livingCost: "Chi phí sinh hoạt",
		highSchoolFee: "Trung học",
		collegeFee: "Đại học",
		masterScienceFee: "Thạc sĩ",
		reference: "Nguồn: Manulife - Một khảo sát dành cho 2000 bệnh nhân tại 3 bệnh viện Ung thư lớn nhất Việt Nam",
		eduReference: "Nguồn: EF Edu: https://www.ef.com.vn/pg/chi-phi-du-hoc/",
		referenceFee: "Chi phí hàng năm cho giáo dục đại học tại một số quốc gia",
		inpatientStaying: "Lưu viện nội trú",
		vinmec: "Vinmec",
		vietphap: "Việt Pháp",
		hongngoc: "Hồng Ngọc",
		hanhphuc: "Hạnh Phúc",
		hospitalFeeOfficial: "Biểu phí dịch vụ chính thức của các bệnh viện",
		referenceCostBedroom: "Chi phí phòng giường tham khảo",
		publicHospitalStandardRoom: "Phòng tiêu chuẩn Bệnh viện Công",
		publicHospitalVoluntaryStandardRoom: "Phòng tiêu chuẩn Tự nguyện Bệnh viện Công",
		privateHospitalStandardRoom: "Phòng tiêu chuẩn Bệnh viện Tư",
		internationalHospitalStandardRoom: "Phòng tiêu chuẩn Bệnh viện Quốc tế",
		healthcareReferenceSource: "Nguồn: Bệnh viện Vinmec, Hồng Ngọc, Thu Cúc, Đại học Y",
		noRecommendedProducts:
			"Hiện chưa có sản phẩm phù hợp với nhu cầu của khách hàng do điều kiện sản phẩm . Anh/Chị vui lòng hướng dẫn khách hàng điều chỉnh nhu cầu để giới thiệu giải pháp tốt nhất.",
		educationPlanError:
			"Xin lỗi quý khách, hiện tại chúng tôi không có sản phẩm bảo hiểm về giáo dục nào phù hợp với con của anh chị. Nhân viên của chúng tôi sẽ giải thích rõ hơn cho quý khách. Xin cảm ơn",
		atLeast1RiderSelected: "Sản phẩm này yêu cầu tối thiểu 1 sản phẩm đi kèm.",
		save: "Lưu",
		minProductSA: "Sản phẩm này không phù hợp với giá trị bạn đang chọn!",
		warningProductsSuggestion:
			"Hệ thống sẽ xây dựng một số giá trị mặc định để giới thiệu sản phẩm. Bạn có thể quay lại để nhập thông tin chính xác theo nhu cầu khách hàng",
		estPremiumProduct: "Phí bảo hiểm",
		totalBenefitOfProduct: "Quyền lợi sản phẩm",
		needsAnalytics: "Bảng Phân Tích Yêu Cầu",
		titanProductDetail: "An Gia Như Ý",
		backToHome: "Trở lại trang chủ",
		errorMinProtectionValueForTitan: "Chi tiết sản phẩm Titan không thể khởi tạo do Giá trị bảo vệ nhỏ hơn {amount}.",
		descriptionProductFeaturesEdu2: "Quỹ học vấn đảm bảo {value}đ",
		errorMessageIfDontHaveAsset: "Vui lòng chọn sản phẩm",
		titanTooltip:
			"<div>LƯU Ý: Khách hàng cần thỏa mãn điều kiện sức khỏe theo 3 câu hỏi thẩm định dưới đây để tham gia sản phẩm An Gia Như Ý.</div>\n" +
			"<div>\n" +
			"    <div>\n" +
			"        1. Ông/Bà đã bao giờ bị từ chối yêu cầu bảo hiểm hoặc bị loại trừ/hạn chế quyền lợi khi tham gia bảo hiểm nhân thọ.\n" +
			"    </div>\n" +
			"    <div>\n" +
			"        2. Ông/Bà đã bao giờ được chẩn đoán hay đang điều trị:\n" +
			"        <div>\n" +
			"            <div>\n" +
			"                &emsp;• Bệnh về tim, não, mạch máu (bệnh van tim, rối loạn nhịp tim, bệnh mạch vành, thiếu máu cơ tim, nhồi máu cơ tim, bệnh thấp tim, suy tim, dị dạng mạch máu, xơ vữa động mạch, viêm tắc động tĩnh mạch, đột quỵ, cao huyết áp,\n" +
			"                động kinh)\n" +
			"            </div>\n" +
			"            <div>&emsp;• Bệnh thận (bí tiểu, phù, tiểu không tự chủ, sỏi/viêm/dị dạng đường tiết niệu/thận, suy thận)</div>\n" +
			"            <div>&emsp;• Ung thư hoặc khối u bất thường</div>\n" +
			"            <div>&emsp;• Tiểu đường</div>\n" +
			"            <div>&emsp;• Bệnh phổi (suy hô hấp, viêm phổi, lao phổi, áp xe phổi, tràn dịch màng phổi, tràn khí màng phổi, giãn phế quản, tâm phế mạn)</div>\n" +
			"            <div>&emsp;• Bệnh gan (viêm gan siêu vi B, viêm gan siêu vi C, gan to, xơ gan)</div>\n" +
			"            <div>&emsp;• AIDS, hoặc nhiễm HIV</div>\n" +
			"        </div>\n" +
			"    </div>\n" +
			"    <div>3. Trong suốt 3 năm vừa qua, Ông/Bà đã bao giờ uống thuốc kê đơn liên tục quá hai tuần (ngoại trừ thuốc tránh thai và thuốc hỗ trợ sinh sản) hoặc nằm viện liên tục quá hai tuần.</div>\n" +
			"    <br />\n" +
			"    <div>\n" +
			"        Tôi/chúng tôi cam kết không đang làm những việc làm sau đây: Công việc nặng nhọc hay nguy hiểm như: làm việc ở độ cao trên 6 mét; làm việc trong hầm mỏ; đào hầm; làm việc dưới nước; thợ lặn; đánh bắt thủy hải sản; thợ hồ; công nhân\n" +
			"        quét rác; thu gom rác; công nhân bốc dỡ; cảnh sát điều tra hình sự; cảnh sát đặc nhiệm; người đóng phim thay trong những cảnh nguy hiểm; quân đội (ngoại trừ công việc văn phòng); Công việc liên quan đến bom/mìn: cảnh sát phá dỡ\n" +
			"        bom/mìn; công nhân phá dỡ bom/mìn.\n" +
			"    </div>\n" +
			"</div>\n",
		quit: "Thoát",
		productBenefit: "Quyền Lợi Sản Phẩm",
		totalBenefit_titan:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện (tối đa cho phép)",
		totalBenefit_ul038: "Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn",
		totalBenefit_ul038_MC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện (tối đa cho phép)",
		totalBenefit_ul038_CI: "Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT",
		totalBenefit_ul038_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 100% STBH Nội trú {outpatient} {dental}",
		totalBenefit_ul038_MC_CI:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 1000 ngày nằm viện",
		totalBenefit_ul038_CI_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 100% STBH Nội trú {outpatient} {dental}",
		totalBenefit_ul038_MC_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện + 100% STBH Nội trú {outpatient} {dental}",
		totalBenefit_ul038_MC_CI_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 1000 ngày nằm viện + 100% STBH Nội trú {outpatient} {dental}",

		totalBenefit_unitLinked: "",
		totalBenefit_unitLinked_ADD: "Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn",
		totalBenefit_unitLinked_MC: "",
		totalBenefit_unitLinked_CI: "",
		totalBenefit_unitLinked_ADD_MC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện (tối đa cho phép)",
		totalBenefit_unitLinked_ADD_CI:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT",
		totalBenefit_unitLinked_ADD_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 100 STBH NỘI TRÚ",
		totalBenefit_unitLinked_ADD_MC_CI:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 1000 ngày nằm viện",
		totalBenefit_unitLinked_ADD_CI_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + STBH_NỘI TRÚ",
		totalBenefit_unitLinked_ADD_MC_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện + STBH_NỘI TRÚ",
		totalBenefit_unitLinked_ADD_MC_CI_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 1000 ngày nằm viện + STBH_NỘI TRÚ",
		totalBenefit_edu: "",
		totalBenefit_edu_ADD: "Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn",
		totalBenefit_edu_MC: "",
		totalBenefit_edu_ADD_MC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện (tối đa cho phép)",
		totalBenefit_edu_ADD_HC: "Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + STBH_NỘI TRÚ",
		totalBenefit_edu_ADD_MC_CI:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 1000 ngày nằm viện",
		totalBenefit_edu_ADD_CI_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + STBH_NỘI TRÚ",
		totalBenefit_edu_ADD_MC_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện + STBH_NỘI TRÚ",
		totalBenefit_edu_ADD_MC_CI_HC:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 1000 ngày nằm viện + STBH_NỘI TRÚ",
		premiumMinError: "Phí bảo hiểm phải lớn hơn {min}đ/năm",
		staffId: "Mã nhân viên",
		branchCode: "Mã chi nhánh",
		customerType: "Thông tin khách hàng",
		determineCustomerInfo:
			"Hệ thống đang minh họa các quyền lợi theo giá trị mặc định, bạn có muốn quay lại để nhập thông tin chính xác theo nhu cầu của khách hàng không?",
		yesDetermineCustomerInfo: "Có, Quay lại",
		noDetermineCustomerInfo: "Không, Đi tiếp",
		interestedBenefitsHealthCare: "Chi phí y tế",
		maxText: "Tối đa",
		silver: "Bạc",
		titan: "Titan",
		gold: "Vàng",
		platinum: "Bạch Kim",
		diamond: "Kim Cương",
		hasGuaranteedInterestRate: "Có lãi suất cam kết tối thiểu.",
		descriptionProductFeaturesEduData1:
			"Chi trả mỗi năm cho đến hết thời hạn đóng phí với khoản hỗ trợ {value}đ đồng thời miễn đóng phí hợp đồng",
		descriptionProductFeaturesEduData2: "Bảo vệ trước rủi ro thương tật toàn bộ và vĩnh viễn {value}đ",

		totalBenefit_titanium_2: "Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong",
		totalBenefit_titanium_2_MC: "Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong  + 1000 ngày nằm viện",
		totalBenefit_titanium_2_CI: "Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 125% STBH BLNT",
		totalBenefit_titanium_2_HC: "Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 100% STBH NỘI TRÚ {outpatient} {dental}",
		totalBenefit_titanium_2_MC_CI: "Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 125% STBH BLNT + 1000 ngày nằm viện ",
		totalBenefit_titanium_2_CI_HC:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 125% STBH BLNT + 100% STBH Nội trú {outpatient} {dental}",
		totalBenefit_titanium_2_MC_HC:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 1000 ngày nằm viện + 100 % STBH Nội trú {outpatient} {dental}",
		totalBenefit_titanium_2_MC_CI_HC:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong+ 125% STBH BLNT + 1000 ngày nằm viện + 100% STBH Nội trú {outpatient} {dental}",
		payDueToAccident: "Chi trả theo bảng tỷ lệ thương tật với thương tật do tai nạn",
		maxPaidDay: "Tối đa 1000 ngày.",
		doublePayment: "Thanh toán gấp 2 lần nếu nằm tại khoa chăm sóc đặc biệt.",
		titanium_2ProductDescription:
			"Sản phẩm đầu tư vượt trội và linh hoạt với số lượng 12 Quỹ nhiều nhất thị trường, cung cấp quyền lợi bảo vệ cao trước các rủi ro tử vong, thương tật và tử vong do tai nạn.",
		descriptionProductFeaturestitanium_2:
			"Hưởng toàn bộ kết quả đầu tư của các Quỹ Liên kết đơn vị (12 quỹ), cùng với các khoản thưởng duy trì hợp đồng hấp dẫn.",
		titanium_2ProductName: "Legacy",
		noRecommendedProductsByAgeOfRBO:
			"<ul class='pl-4 pr-2 list-disc'>Độ tuổi của khách hàng hiện chưa phù hợp với các sản phẩm bảo hiểm hiện có. Vui lòng tham khảo các điều kiện về độ tuổi của người được bảo hiểm như sau:<div class='pl-10'><li>An Gia Như Ý: 18 đến 50 tuổi</li><li>Bách Lộc Toàn Gia Vượt Trội: 18 đến 65 tuổi</li><li>Legacy: 18 đến 69 tuổi</li></div></ul>",
		noRecommendedProductsByPremium:
			"Hiện chưa có sản phẩm phù hợp với nhu cầu của khách hàng do điều kiện sản phẩm về phí bảo hiểm. Anh/Chị vui lòng hướng dẫn khách hàng điều chỉnh nhu cầu để giới thiệu giải pháp tốt nhất.",
		createNewLeadId: "Tạo Lead ID mới",
		income: "Thu nhập",
		iTCBLifeToEPOS: "Chuyển iTCBlife sang Manulife",
		errorMessageLeadCannotAssociatedConversation: "Không thể gắn lead này với kết quả cuộc hội thoại đã chọn.",
		criticalIllness: "Quyền Lợi Bệnh Lý Nghiêm Trọng",
		criticalIllnessPlus: "Quyền Lợi Bệnh Lý Nghiêm Trọng Mở Rộng",
		criticalIllnessEarlyStage: "Giai đoạn sớm: 25% STBH, tối đa 4 lần",
		criticalIllnessMiddleStage: "Giai đoạn giữa: 50% STBH, tối đa 2 lần",
		criticalIllnessLateStage: "Giai đoạn cuối: 100% STBH, tối đa 1 lần",
		criticalIllnessByGender: "Theo giới tính: chi trả thêm 25% STBH",
		specialMedicalBenefit: "Quyền Lợi Bảo Hiểm Y Tế Đặc Biệt",
		specialMedicalBenefitPayCIPlus: "Chi trả 100% STBH (tối đa 2 tỷ) nếu:",
		specialMedicalBenefitPayMCPlus: "Chi trả 500 lần STBH (tối đa 2 tỷ) nếu:",
		specialMedicalBenefitPay1:
			"Nằm viện tại khoa CSĐB tối thiểu 12 ngày liên tục và có sử dụng công cụ hỗ trợ sự sống có xâm lấn trong quá trình điều trị; hoặc",
		specialMedicalBenefitPay2: "Nằm viện tối thiểu 21 ngày liên tục để tiến hành phẫu thuật",
		deathBenefit: "Quyền Lợi Bảo Hiểm Tử Vong",
		deathBenefitPayCIPlus: "Chi trả 100% STBH khi tử vong từ tuổi 70",
		deathBenefitPayMCPlus: "Chi trả 500 lần STBH khi tử vong sau tuổi 70",
		medicalPlus: "Quyền lợi Bảo hiểm trợ cấp y tế mở rộng",
		medicalCash: "Quyền Lợi Trợ Cấp Nằm Viện",
		medicalCashContractYear1st: "Năm HĐ 1",
		medicalCashContractYear2nd: "Năm HĐ 2",
		medicalCashContractYear3rd: "Năm HĐ 3 trở đi",
		medicalCashContractYearPercent: "{percent} STBH/ ngày",
		first5days: "5 ngày nằm viện đầu tiên/ đợt nằm viện",
		from6thdayOnward: "Từ ngày nằm viện thứ 6 trở đi/ đợt nằm viện",
		specialCareDepartment: "<div>QL trợ cấp tại khoa: <span class='text-c4-50'>chăm sóc đặc biệt</span> 400% STBH/ngày</div>",
		idIssuedDate: "Ngày cấp",
		idIssuedPlace: "Nơi cấp",
		plsChooseIssuedPlace: "Vui lòng chọn nơi cấp",
		address: "Địa chỉ thường trú",
		province: "Tỉnh/Thành Phố",
		street: "",
		district: "Quận/ Huyện",
		ward: "Phường/ Xã",
		city: "Tỉnh/ Thành phố",
		askedVietnamese: "Tiếng Việt có dấu",
		occupation: "Nghề nghiệp",
		pleaseChooseOccupation: "Vui lòng chọn nghề nghiệp",
		smokingHabit: "Thói quen hút thuốc",
		neverSmoking: "Chưa bao giờ hút thuốc",
		smokeFewerOrUpto30cigas: "Hút ít hơn hoặc bằng (≤) 30 điếu/ngày",
		smokeGreaterThan30cigas: "Hút trên (˃) 30 điếu/ngày",
		pleaseSelectACity: "Vui lòng chọn Tỉnh/ Thành phố",
		pleaseSelectADistrict: "Vui lòng chọn Quận/ Huyện",
		pleaseSelectAWard: "Vui lòng chọn Phường/ xã",
		pleaseSelectSmokingHabit: "Vui lòng chọn thói quen hút thuốc",
		addressFields: "Chọn Phường/Xã - Quận/Huyện - Tỉnh/Thành phố",
		addressStreet: "Điền số nhà, Đường",
		addressTooltip: "Nếu địa chỉ không có số nhà và tên đường, cung cấp thêm tên Tổ/Đội và tên Khu phố/Ấp/Thôn/Xóm",
		firstIssuedPlaceId12digits: "Cục CS ĐKQL Cư Trú Và DLQG Về Dân Cư",
		secondIssuedPlaceId12digits: "Cục CS Quản lý hành chính về trật tự xã hội",
		goToEpos: "Truy cập ePOS",
		referAwsToCreateIllustrationBtn: "Tạo Bảng Minh Họa",
		// promotion page
		promotionName4: "Ưu đãi ngập tràn, yêu thương vẹn toàn",
		promotionheadline4: "Thời gian áp dụng: 01/04/2022 đến hết ngày 30/06/2022",
		promotionContent4: "Thời gian áp dụng: 07/07/2022 đến hết ngày 30/09/2022",
		offerProductsAtProductStep: "Dựa trên thông tin bạn cung cấp, hệ thống đề xuất giải pháp phù hợp nhất với bạn.",
		agreeBtn: "Đồng ý",
		prenatal: "Prenatal",
		prenatalRider: "Trợ cấp thai sản",
		prenatalBenefit: "Trợ cấp thai sản",
		prenatalTooltip: "TBD",
		inpatientBenefit: "Quyền lợi Điều trị Nội trú",
		outpatientBenefit: "Quyền lợi Điều trị Ngoại trú",
		dentalBenefit: "Quyền lợi Điều trị Nha Khoa",
		inpatient: "Điều trị Nội trú",
		outpatient: "Điều trị Ngoại trú",
		dental: "Điều trị Nha Khoa",
		insuranceBenefit: "Quyền lợi bảo hiểm",
		insurancesPromo: "Chương trình bảo hiểm",
		sumOutpatientBenefit: "Quyền lợi Điều trị Ngoại trú",
		sumInpatientBenefit: "Quyền lợi Điều trị Nội trú",
		sumDentalBenefit: "Quyền lợi Điều trị Nha Khoa",
		millionPerYear: "triệu/ năm",
		billionPerYear: "tỷ/ năm",
		notApplying: "Không áp dụng",
		basic: "Bạc",
		advance: "Titan",
		premier: "Vàng",
		elite: "Bạch kim",
		suite: "Kim cương",
		referenceBenefits: "Gói quyền lợi tham khảo",
		eliteAndSuite: "Bạch Kim/Kim Cương",
		prenatalTotalBenefits: "Quyền lợi bảo hiểm Trợ cấp thai sản: {prenatalAmount}/ngày.",
		prenatalMaxAmount: "Tối đa {prenatalMaxAmount}",
		prenatalDeathCoverage: "Quyền lợi bảo hiểm Tử vong: {prenatalDeathAmount} ",
		prenatalCongenitalDisorder: "Quyền lợi bảo hiểm Dị tật bẩm sinh: {prenatalDisorderAmount}",
		totalBenefit_ul038_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 30 * STBH Thai sản",
		totalBenefit_ul038_MC_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện (tối đa cho phép) + 30 * STBH Thai sản",
		totalBenefit_ul038_HC_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 100% STBH Nội trú {outpatient} {dental} + 30 * STBH Thai sản",
		totalBenefit_ul038_CI_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 30 * STBH Thai sản",
		totalBenefit_ul038_MC_CI_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 1000 ngày nằm viện + 30 * STBH Thai sản",
		totalBenefit_ul038_MC_HC_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 1000 ngày nằm viện + 100% STBH Nội trú {outpatient} {dental} + 30 * STBH Thai sản",
		totalBenefit_ul038_HC_CI_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 100% STBH Nội trú {outpatient} {dental} + 30 * STBH Thai sản",
		totalBenefit_ul038_MC_HC_CI_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 100% STBH Tử vong + 100% STBH Tử vong do tai nạn + 125% STBH BLNT + 1000 ngày nằm viện + 100% STBH Nội trú {outpatient} {dental} + 30 * STBH Thai sản",
		totalBenefit_titanium_2_PRENATAL: "Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 30 * STBH Thai sản",
		totalBenefit_titanium_2_MC_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong  + 1000 ngày nằm viện + 30 * STBH Thai sản",
		totalBenefit_titanium_2_CI_PRENATAL: "Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 125% STBH BLNT + 30 * STBH Thai sản",
		totalBenefit_titanium_2_HC_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 100% STBH Nội trú {outpatient} {dental} + 30 * STBH Thai sản",
		totalBenefit_titanium_2_MC_CI_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 125% STBH BLNT + 1000 ngày nằm viện + 30 * STBH Thai sản",
		totalBenefit_titanium_2_MC_HC_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 1000 ngày nằm viện + 100% STBH Nội trú {outpatient} {dental} + 30 * STBH Thai sản",
		totalBenefit_titanium_2_HC_CI_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong + 125% STBH BLNT + 100 % STBH Nội trú {outpatient} {dental} + 30 * STBH Thai sản",
		totalBenefit_titanium_2_MC_CI_HC_PRENATAL:
			"Tổng quyền lợi bảo hiểm bằng 200% STBH Tử vong+ 125% STBH BLNT + 1000 ngày nằm viện + 100% STBH Nội trú {outpatient} {dental} + 30 * STBH Thai sản",
		day: "ngày",
		totalBenefitHealthcareTT: "Quyền lợi bảo hiểm chăm sóc sức khỏe bao gồm:",
		inpatientMaxAmountTT: "Quyền lợi nội trú tối đa: {amount}/năm",
		outpatientMaxAmountTT: "Quyền lợi ngoại trú tối đa: {amount}/năm",
		dentalMaxAmountTT: "Quyền lợi nha khoa tối đa: {amount}/năm",
		accidentDismemberment: "Bảo hiểm Tử vong và Thương tật do tai nạn nâng cao",
		completedFNA: "Đang tạo bảng minh họa",
		applicationInprogress: "Đang nộp hợp đồng",
		lateSubmission: "Nộp hợp đồng muộn",
		applicationSubmitted: "Đã nộp hợp đồng",
		noRecommendedProductsOfRBO:
			"<ul class='pl-4 pr-2 list-disc'>Số tiền bảo hiểm của khách hàng hiện chưa phù hợp với các sản phẩm bảo hiểm hiện có. Vui lòng tham khảo các điều kiện về số tiền bảo hiểm như sau:<div class='pl-10'><li>An Gia Như Ý: 100.000.000đ đến 5.000.000.000đ</li><li>Bách Lộc Toàn Gia Vượt Trội: tối thiểu 100.000.000đ</li><li>Legacy: tối thiểu 300.000.000đ</li></div></ul>",
		noRecommendedProductsOfDRBO:
			"<ul class='pl-4 pr-2 list-disc'>Số tiền bảo hiểm của khách hàng hiện chưa phù hợp với các sản phẩm bảo hiểm hiện có. Vui lòng tham khảo các điều kiện về số tiền bảo hiểm như sau:<div class='pl-10'><li>An Gia Như Ý: 100.000.000đ đến 5.000.000.000đ</li><li>Bách Lộc Toàn Gia Vượt Trội: tối thiểu 100.000.000đ</li></div></ul>",
		noRecommendedProductsByAgeOfDRBO:
			"<ul class='pl-4 pr-2 list-disc'>Độ tuổi của khách hàng hiện chưa phù hợp với các sản phẩm bảo hiểm hiện có. Vui lòng tham khảo các điều kiện về độ tuổi của người được bảo hiểm như sau:<div class='pl-10'><li>An Gia Như Ý: 18 đến 50 tuổi</li><li>Bách Lộc Toàn Gia Vượt Trội: 18 đến 65 tuổi</li></div></ul>",
		noRecommendedProductsByBasePremiumDRBO:
			"<ul class='pl-4 pr-2 list-disc'>Phí bảo hiểm dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu. Vui lòng tham khảo các điều kiện về số phí bảo hiểm tối thiểu như sau:<div class='pl-10'><li>An Gia Như Ý & Bách Lộc Toàn Gia Vượt Trội: Trên 7.000.000đ</li></div></ul>",
		noRecommendedProductsByBasePremiumRBO:
			"<ul class='pl-4 pr-2 list-disc'>Phí bảo hiểm dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu. Vui lòng tham khảo các điều kiện về số phí bảo hiểm tối thiểu như sau:<div class='pl-10'><li>An Gia Như Ý & Bách Lộc Toàn Gia Vượt Trội: Trên 7.000.000đ</li><li>Legacy: Trên 10.000.000đ</li></div></ul>",
		medicalCashTooltipTitan:
			"<div>Quyền Lợi Trợ Cấp Nằm Viện:</div>\n" +
			"    <div>\n" +
			"       - Chi trả 0.2% x {amount}/ ngày nằm viện (tối đa 3.000.000 đ/ ngày).\n" +
			"    </div>\n" +
			"    <div>\n" +
			"       - Chi trả gấp đôi khi nằm viện tại khoa chăm sóc đặc biệt.\n" +
			"    </div>\n" +
			"    <br />\n" +
			"<div>Quyền lợi trợ cấp thu nhập:</div>\n" +
			"    <div>\n" +
			"      - Chi trả thêm 20% Quyền lợi Trợ Cấp Nằm Viện cho mỗi ngày nằm viện từ ngày nằm viện thứ 6.\n" +
			"    </div>\n",
		generalAddDeathTooltipOfLegacy: "Chi trả {amount} nếu tử vong do tai nạn.",
		publicAddDeathTooltipOfLegacy: "Chi trả theo bảng tỷ lệ thương tật với thương tật do tai nạn.",
		amountInpatient: "+ 100% STBH Nội trú",
		amountOutpatient: "+ 100% STBH Ngoại trú",
		amountDental: "+ 100% STBH Nha khoa",
		chooseGoalsWarningMessageByDRBO: "(Để lên kế hoạch hiệu quả, vui lòng lựa chọn tối đa 2 mục tiêu ưu tiên)",
		monthly: "tháng",
		equivalent: "Tương ứng",
		publicSchool: "Trường công",
		privateSchool: "Trường tư thục",
		internationalSchool: "Trường quốc tế",
		schoolType: "Loại trường học",
		incomes: "Thu nhập - chi phí",
		houseHoldIncomeMonth: "- Thu nhập hộ gia đình/tháng",
		houseHoldLivingExpenseRatioMonth: "- % chi phí sinh hoạt/thu nhập hộ gia đình",
		customerIncomeHouseholdIncomeRatio: "3. % thu nhập khách hàng/thu nhập hộ gia đình",
		loanOutstandingBalance: "4. Dư nợ khoản vay (nếu có)",

		// Product recommendation
		solutionsSuggestion: "Đề xuất giải pháp",
		purposeOfProtection: "Mục tiêu bảo vệ",
		protectionValue: "Giá trị bảo vệ (VND)",
		premiumPerYear: "Số tiền tham gia/ năm",
		premiumPerIncome: "% Số tiền tham gia/ thu nhập",
		appear: "Hiển thị",
		disappear: "Ẩn",

		// Rider name
		lifeProtection: "Bảo vệ rủi ro sinh mạng",
		accidentProtection: "Bảo vệ rủi ro do tai nạn",
		illnessProtection: "Bảo vệ rủi ro do bệnh hiểm nghèo",
		medicalCostProtection: "Bảo vệ chi phí y tế (ốm đau)",
		medicalAllowanceProtection: "Trợ cấp y tế",

		// Rider: Life protection
		incomeProtection1: "Bảo vệ toàn bộ thu nhập khách hàng trong",
		incomeProtection2: "năm",
		monthlyExpenseProtection1: "Bảo vệ Chi phí sinh hoạt trong",
		monthlyExpenseProtection2: "năm (theo % trách nhiệm KH)",
		childrenEduProtection1: "Bảo vệ Chi phí giáo dục cho con trong",
		childrenEduProtection2: "năm (theo % trách nhiệm KH)",
		currentBalanceProtection1: "Bảo vệ tương đương với dư nợ khoản vay trong",
		currentBalanceProtection2: "năm",
		otherProtections1: "Bảo vệ khác",
		otherProtections2: "năm",
		selectAtLeast1ItemOnBaseProtection: "Vui lòng chọn ít nhất 1 lựa chọn tại mục Bảo vệ rủi ro sinh mạng",
		productToSelect: "Sản phẩm lựa chọn",
		in: "trong",

		// Rider: Accident protection
		reducingIncomeProtection1: "Bảo vệ thu nhập giảm",
		reducingIncomeProtection2: "do thương tật trong",
		reducingIncomeProtection3: "năm",
		reduceExpenseProtection1: "Bảo vệ chi phí sinh hoạt giảm",
		reduceExpenseProtection2: "trong",
		reduceExpenseProtection3: "năm",
		childrenEduByAccidentProtection1: "Bảo vệ chi phí giáo dục cho con giảm",
		childrenEduByAccidentProtection2: "do thương tật trong",
		childrenEduByAccidentProtection3: "năm",
		accidentExpenseProtection: "Bảo vệ chi phí điều trị 1 đợt do tai nạn",

		// Rider: Illness protection
		incomeProtectionByIllness1: "Bảo vệ thu nhập giảm",
		incomeProtectionByIllness2: "do bệnh hiểm nghèo trong",
		incomeProtectionByIllness3: "năm",
		medicalProtectionByIllness: "Bảo vệ chi phí điều trị 1 đợt do Bệnh hiểm nghèo",

		// Rider: Medical cost protection
		publicHospitalProtection: "Bảo vệ chi phí y tế bệnh viện công (hạn mức/năm)",
		privateHospitalProtection: "Bảo vệ chi phí y tế bệnh viện tư (hạn mức/năm)",
		internationalHospitalProtection: "Bảo vệ chi phí y tế bệnh viện quốc tế (hạn mức/năm)",
		inpatientBenefits: "Quyền lợi điều trị nội trú",
		outpatientBenefits: "Quyền lợi điều trị ngoại trú",
		dentalBenefits: "Quyền lợi điều trị nha khoa",

		// Rider: medical allowance
		medicalCashAllowance: "Trợ cấp y tế mở rộng",
		prenatalAllowance: "Trợ cấp thai sản",
		incomeMonth: "- Thu nhập/tháng",
		livingExpenseRatioMonth: "- % chi phí sinh hoạt/thu nhập",
		summaryIncomeExpense: "Bảng tổng hợp Thu nhập - Chi phí",
		summaryTotalHouseholdIncome: "1. Tổng thu nhập hiện tại của gia đình khách hàng",
		summaryCustomerIncome: "1.1 Thu nhập khách hàng",
		summaryTotalHouseholdExpense: "2. Tổng chi phí hiện tại của gia đình khách hàng",
		summaryEssentialExpense: "2.1 Chi phí thiết yếu",
		summaryLivingExpense: "2.1.1 Chi phí sinh hoạt",
		summaryEducationExpense: "2.1.2 Chi phí học hành cho con cái",
		summaryLoanCost: "2.2 Chi phí khoản vay",
		summaryPrinciplePayable: "2.2.1 Gốc phải trả",
		summaryInterestCost: "2.2.2 Lãi phải trả",
		summaryReferenceInterestRate: "Lãi suất tham chiếu/năm",
		summaryOtherExpense: "2.3 Chi phí khác",
		summaryNetSaving: "Mức tích luỹ của gia đình",
		summaryTotalIncome: "1. Tổng thu nhập hiện tại của khách hàng",
		summaryTotalExpense: "2. Tổng chi phí hiện tại",
		verifyInfo: "Xác nhận thông tin",
		infoConfirmation: "Xác nhận FNA",
		netSavingSingleLife: "Mức tích luỹ",
		total: "Tổng",
		warningToCheckLifeProtectionBenefit: "Vui lòng chọn ít nhất 1 lựa chọn tại mục Bảo vệ rủi ro sinh mạng",
		milVND: "triệu đồng",
		VND: "đồng",
		customerConfirmation: "Xác nhận thông tin khách hàng",
		analysisPdf: "Bảng phân tích tài chính",
		confirmToCreateNewLeadGCM: "Bạn có chắc muốn tạo lead CRM mới?",

		// products validation
		baseAmountIsInvalid: "Giá trị chưa phù hợp",
		baseProtectionErrorMessageLegacy:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm Legacy (300.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionErrorMessageUL038:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm Bách Lộc Toàn Gia Vượt Trội - Tiết Kiệm (100.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionLessThanErrorMessageTitan:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm An Gia Như Ý (100.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionGreaterThanErrorMessageTitan:
			"Giá trị bảo vệ đang lớn hơn số tiền bảo hiểm cơ bản tối đa của sản phẩm An Gia Như Ý (5.000.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		basePremiumErrorMessageLegacy:
			"Phí bảo hiểm cơ bản dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu của sản phẩm Legacy (10.000.000). Vui lòng điều chỉnh giá trị bảo vệ cơ bản để đáp ứng điều kiện trên.",
		basePremiumErrorMessageUL038:
			"Phí bảo hiểm cơ bản dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu của sản phẩm Bách Lộc Toàn Gia Vượt Trội - Tiết Kiệm (7.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ cơ bản để đáp ứng điều kiện trên.",
		basePremiumErrorMessageTitan:
			"Phí bảo hiểm cơ bản dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu của sản phẩm An Gia Như Ý (7.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ cơ bản để đáp ứng điều kiện trên.",
		meetingType: "Phương thức gặp",
		invalidTotalBasePremium: "Giá trị chưa phù hợp",
		totalBasePremiumErrorMessageUL038:
			"Phí bảo hiểm cơ bản + bổ trợ dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu (10.000.000 VND). Vui lòng điều chỉnh giá trị bảo vệ cơ bản để đáp ứng điều kiện trên.",
		totalBasePremiumErrorMessageLegacy:
			"Phí bảo hiểm cơ bản + bổ trợ dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu (10.000.000 VND). Vui lòng điều chỉnh giá trị bảo vệ cơ bản để đáp ứng điều kiện trên.",
		confirmToSelectNewLeadGCM: "Bạn có chắc chắn muốn chọn Lead {leadGCM}",
		directReferralPlaceholder: "Điền mã nhân viên của người refer",
		directReferral: "Người refer trực tiếp",

		// Benefit cancer protection
		benefitCancerProtection: "Bảo vệ rủi ro do bệnh ung thư giai đoạn cuối",
		lateStageCancer: "Bảo vệ chi phí điều trị bệnh ung thư giai đoạn cuối",
		lateStageCancerProtectionAmountErrorMessage:
			"Số tiền bảo hiểm Bệnh ung thư giai đoạn cuối bằng Số tiền bảo hiểm sản phẩm chính và không vượt quá { amountProtection } VND",
		ul048ProductName: "An Tâm Vững Bước",
		UL048lateStageCancerProtectionAmountErrorMessage:
			"Số tiền bảo hiểm Bệnh ung thư giai đoạn cuối tối đa là 5* Số tiền bảo hiểm chính và không vượt quá 500.000.000 VND",
		baseProtectionLessThanErrorMessageUL048:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm An Tâm Vững Bước (100.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionGreaterThanErrorMessageUL048:
			"Giá trị bảo vệ đang lớn hơn số tiền bảo hiểm cơ bản tối đa của sản phẩm An Tâm Vững Bước (1.000.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionErrorMessageUL040:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm Bách Lộc Toàn Gia Vượt Trội - Bảo Vệ (100.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		basePremiumErrorMessageUL040:
			"Phí bảo hiểm cơ bản dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu của sản phẩm Bách Lộc Toàn Gia Vượt Trội - Tiết Kiệm (7.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ cơ bản để đáp ứng điều kiện trên.",
		totalBasePremiumErrorMessageUL040:
			"Phí bảo hiểm cơ bản + bổ trợ dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu (7.000.000 VND). Vui lòng điều chỉnh giá trị bảo vệ cơ bản để đáp ứng điều kiện trên.",
		baseProtectionADDRiderGeaterThanErrorMessageUL040:
			"Giá trị bảo vệ đang lớn hơn số tiền bảo hiểm cơ bản tối đa của sản phẩm Bách Lộc Toàn Gia Vượt Trội - Bảo Vệ (10.000.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionADDRiderLessThanErrorMessageUL040:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm Bách Lộc Toàn Gia Vượt Trội - Bảo Vệ (100.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionADDRiderGreaterThanErrorMessageUL038:
			"Giá trị bảo vệ đang lớn hơn số tiền bảo hiểm cơ bản tối đa của sản phẩm Bách Lộc Toàn Gia Vượt Trội - Tiết Kiệm (10.000.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionADDRiderLessThanErrorMessageUL038:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm Bách Lộc Toàn Gia Vượt Trội - Tiết Kiệm (100.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionADDRiderGreaterThanErrorMessageLegacy:
			"Giá trị bảo vệ đang lớn hơn số tiền bảo hiểm cơ bản tối đa của sản phẩm Legacy (10.000.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionADDRiderLessThanErrorMessageLegacy:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm Legacy (100.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		basePremiumErrorMessageUL048:
			"Phí bảo hiểm cơ bản dự kiến hiện thấp hơn số phí bảo hiểm tối thiểu của sản phẩm An Tâm Vững Bước (7.000.000đ). Vui lòng điều chỉnh giá trị bảo vệ cơ bản để đáp ứng điều kiện trên.",
		netSavingInvalidAmount: "Mức tích lũy phải lớn hơn hoặc bằng {netSaving}/tháng (~{totalNetSaving}/năm)",
		otherProtectionGrowAssetOption: "Gia tăng tài sản",
		otherProtectionFinanceProtectionOption: "Bảo vệ tài chính",
		otherProtectionEducationOption: "Giáo dục",
		otherProtectionRetirementOption: "Hưu trí an nhàn",
		selectGoalWarningMessage: "Vui lòng chọn 1 mục tiêu",
		totalExpenseInvalidAmount: "Tổng chi phí phải lớn hơn hoặc bằng {minExpense}/tháng (~{totalExpense}/năm)",

		// Dynamic amount of CI rider
		productNameFormattedOnValidationMessageUL038: "Bách Lộc Toàn Gia Vượt Trội - Tiết Kiệm",
		productNameFormattedOnValidationMessageUL040: "Bách Lộc Toàn Gia Vượt Trội - Bảo Vệ",
		baseProtectionRiderLessThanErrorMessage:
			"Giá trị bảo vệ đang nhỏ hơn số tiền bảo hiểm cơ bản tối thiểu của sản phẩm { productNameSelected } ({ minAmount }). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		baseProtectionRiderGreaterThanErrorMessage:
			"Giá trị bảo vệ đang lớn hơn số tiền bảo hiểm cơ bản tối đa của sản phẩm { productNameSelected } ({ maxAmount }). Vui lòng điều chỉnh giá trị bảo vệ để đáp ứng điều kiện trên.",
		// Base product
		targetAmountMax10BillionWarning: "Số tiền mục tiêu phải lớn hơn 0 và nhỏ hơn 10 tỷ VND",
		targetAmountMax50BillionWarning: "Số tiền mục tiêu phải lớn hơn 0, bằng hoặc nhỏ hơn 50 tỷ VND",
	},
}
