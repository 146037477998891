<template>
	<JFlex class="relative flex-col space-y-4 w-full justify-center py-4">
		<JFlex class="absolute justify-end w-full mb-2" style="top: -20px; right: -80px">
			<JButton @click="$emit('closeModal')" iconPrefix="X" variant="tertiary"></JButton>
		</JFlex>
		<JText v-if="errorItemId?.message">{{ errorItemId?.message }}</JText>
	</JFlex>
</template>
<script>
export default {
	name: "ModalCasesErrorInfo",
	props: {
		errorItemId: {
			type: Object,
			default: () => {},
		},
	},
	emits: ["closeModal"],
	setup() {},
}
</script>
