<template>
	<JBox
		:class="onDragging ? activeDropZoneCls : dropZoneCls"
		:style="dropZoneStyles"
		class="relative"
		@dragenter="handleDragEnter"
		@dragleave="handleDragLeave"
		@drop.prevent="handleDrop($event)"
		@dragover.prevent
	>
		<input
			:id="inputId"
			ref="fileInput"
			class="invisible absolute w-full h-full top-0 left-0"
			type="file"
			:accept="accept.map((ext) => `.${ext}`).join(', ')"
			:multiple="multiple && html5"
			@input="handleInputChange"
		/>
		<slot :handleClickBrowse="handleClickBrowse">
			<JFlex class="flex-col justify-center items-center">
				<JText class="font-semibold">{{ $t("core.dragDropHere") }}</JText>
				<JText class="mt-4">{{ $t("core.or") }}</JText>
				<JButton class="mt-4" @click="handleClickBrowse()">{{ $t("core.browse") }}</JButton>
			</JFlex>
		</slot>
	</JBox>
</template>

<script>
export default {
	name: "CDroppableZone",
	emits: ["change"],
	props: {
		dropZoneCls: {
			type: String,
			default: "p-10",
		},
		activeDropZoneCls: {
			type: String,
			default: "",
		},
		dropZoneStyles: {
			type: String,
			default: "",
		},
		inputId: {
			type: String,
			default: "files-input",
		},
		accept: {
			type: Array,
			default: () => {
				return ["jpg", "jpeg", "png", "gif", "tiff", "webp", "pdf", "doc", "xdoc", "csv", "xls", "xlsx", "txt"]
			},
		},
		multiple: {
			type: Boolean,
			default: true,
		},
		size: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			onDragging: 0,
		}
	},
	computed: {
		html5() {
			const inputEl = document.createElement("input")
			inputEl.type = "file"
			inputEl.multiple = true
			return window.FormData && inputEl.files
		},
	},
	methods: {
		handleInputChange(e) {
			this.onDragging = 0
			const acceptedFiles = this.filterValidFiles(e.target.files)
			this.$emit("change", acceptedFiles)
		},
		handleDragEnter() {
			this.onDragging++
		},
		handleDragLeave() {
			this.onDragging--
		},
		handleDrop(e) {
			this.onDragging = 0
			const acceptedFiles = this.filterValidFiles(e.dataTransfer.files)
			this.$emit("change", acceptedFiles)
		},
		handleClickBrowse() {
			this.$refs.fileInput.click()
		},
		filterValidFiles(fileList) {
			// Exclude files that not in accept type list
			const acceptedFiles = []
			Array.prototype.slice.call(fileList).forEach((file) => {
				const extension = file.name.match(/\.([\w]+)$/)?.[1]
				if (this.accept.includes(extension.toLowerCase())) {
					acceptedFiles.push(file)
				} else {
					this.$store.dispatch("addToastMessage", {
						type: "error",
						content: `${this.$t("core.fileNotSupported_1")} ${extension} ${this.$t("core.fileNotSupported_2")}`,
					})
				}
			})
			return acceptedFiles
		},
	},
}
</script>
