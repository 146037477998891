<template>
	<JBox class="relative" :class="{ 'px-8': enableClick > 0 }">
		<JBox class="overflow-hidden w-full">
			<JFlex :style="listStyle" class="transition-all duration-300">
				<template v-for="(item, idx) in list" :key="item.key ?? idx">
					<JBox class="flex-shrink-0 px-2" :style="{ width: 100 / itemsToShow + '%' }">
						<slot :isActive="idx === isActiveSlide" :item="item" :idx="idx" :currentClick="currentClick"></slot>
					</JBox>
				</template>
			</JFlex>
			<JButton
				v-if="currentClick > 0"
				variant="circle-sm"
				@click="onPrev"
				style="left: 3px"
				class="absolute top-1/2 -translate-y-1/2"
			>
				<JIcon width="24" height="24" icon="ChevronLeft"></JIcon>
			</JButton>
			<JButton
				style="right: 3px"
				v-if="enableClick > 0 && currentClick < enableClick"
				variant="circle-sm"
				@click="onNext"
				class="absolute top-1/2 right-0 -translate-y-1/2"
			>
				<JIcon width="24" height="24" icon="Plus"></JIcon>
			</JButton>
		</JBox>
	</JBox>
</template>

<script>
import { computed, ref } from "vue"
export default {
	name: "CCarousel",
	props: {
		list: {
			type: Array,
			default: () => [],
		},
		itemsToShow: {
			type: Number,
			default: 1,
		},
	},
	setup(props) {
		const listStyle = ref("")
		const isActiveSlide = ref(0)

		const currentClick = ref(0)
		const enableClick = computed(() => props.list.length - 2)

		const onNext = () => {
			if (currentClick.value >= enableClick.value) return
			currentClick.value++
			isActiveSlide.value++
			listStyle.value = `transform: translateX(-${currentClick.value * (100 / props.itemsToShow)}%)`
		}

		const onPrev = () => {
			if (currentClick.value <= 0) return
			currentClick.value--
			isActiveSlide.value--
			listStyle.value = `transform: translateX(-${currentClick.value * (100 / props.itemsToShow)}%)`
		}
		return { listStyle, enableClick, currentClick, isActiveSlide, onNext, onPrev }
	},
}
</script>
