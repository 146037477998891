import { useStore } from "vuex"
import { useI18n } from "vue-i18n"
export const checkRangeDate = ({ dateBefore = "", dateAfter = "" }) => {
	const theDateBefore = new Date(dateBefore)
	const theDateAfter = new Date(dateAfter)
	const calculateTheTime = theDateAfter.getTime() - theDateBefore.getTime()
	return calculateTheTime / (1000 * 3600 * 24) + 1
}

export const renderSystemTimeout = async ({ code, message }) => {
	const store = useStore()
	const { t } = useI18n()
	// log it for tcb to check
	await store.dispatch("addToastMessage", {
		type: "error",
		content: {
			type: "message",
			text: t("fna.itcblifeSystemTimeoutMessage"),
		},
	})
}

export const sortListByName = ({ list, key }) => {
	if (!list?.length) return
	return list?.sort((a, b) => {
		const nameA = a?.[key]
		const nameB = b?.[key]
		if (nameA < nameB) {
			return -1
		}
		if (nameA > nameB) {
			return 1
		}
		return 0
	})
}

export function filterListByKey({ list, field, fieldKey }) {
	return list.filter((item) => item?.[fieldKey] === field)
}
