<template>
	<JFlex class="w-full justify-between">
		<JBox class="w-1/2">
			<CRadios
				v-model:modelValue="internalValue"
				:options="options"
				:disabled="notAllowToggleOptions"
				variant="nonBorder"
				@update:modelValue="$emit('update:searchBy', $event)"
			/>
		</JBox>
	</JFlex>
</template>
<script setup>
import { ref } from "vue"

defineEmits(["update:searchBy"])

const props = defineProps({
	searchBy: {
		type: String,
		default: "",
	},
	options: {
		type: Array,
		default: () => [],
		required: true,
	},
	notAllowToggleOptions: {
		type: Boolean,
		default: false,
	},
})

const internalValue = ref(props.searchBy)
</script>
