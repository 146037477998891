<template>
	<JFlex class="mt-4 md:mt-10 -m-4 flex-wrap lg:max-w-6xl">
		<JBox class="md:w-6/12 p-4 w-full">
			<CDroppableZone
				dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite md:py-12 p-4"
				activeDropZoneCls="border-c0-500 border-2 bg-cWhite opacity-50 md:py-12 p-4"
				dropZoneStyles="border-radius: 3rem"
				@change="handleInputFiles($event)"
			>
				<template v-slot="props">
					<JFlex class="flex-col justify-center items-center">
						<JIcon class="text-c1-500 hidden md:block" width="116" height="116" icon="CloudUpload" />
						<JIcon class="text-c1-500 md:hidden" width="50" height="50" icon="CloudUpload" />
						<JText class="mt-8 font-medium text-lg text-center text-c0-500">
							{{ $t("core.dragDropHere") }}
						</JText>
						<JText class="mt-4 font-medium text-c0-500"> {{ $t("core.or") }} </JText>
						<JButton variant="secondary" class="mt-4" @click="props.handleClickBrowse()">
							{{ $t("core.browse") }}
						</JButton>
					</JFlex>
				</template>
			</CDroppableZone>
		</JBox>
		<JFlex class="w-full md:w-6/12 p-4 flex-col justify-between">
			<JBox>
				<JHeadline variant="h2">{{ $t("core.documents") }}</JHeadline>
				<JBox>
					<JText v-if="!fileList.length" class="text-c0-500 mt-4">
						{{ $t("core.noFiles") }}
					</JText>
					<CFileRow
						v-for="(fileObj, index) in fileList"
						:key="index"
						class="mt-8"
						:file="fileObj"
						@re-upload="handleReupload(fileObj)"
						@remove="handleRemoveFile(fileObj, index)"
					/>
				</JBox>
			</JBox>
			<JBox class="mt-10">
				<JText v-if="error" class="text-cError-500 mb-4">
					{{ error }}
				</JText>
				<JButton v-if="fileList && fileList.length" class="w-full justify-center" @click="handleClickUpload()">
					{{ uploading ? $t("core.uploading") : $t("core.upload") }}
				</JButton>
			</JBox>
		</JFlex>
	</JFlex>
</template>

<script>
import { restFetcher } from "../api/fetcher"
import { handleErrorForUser } from "../api/handleErrorForUser"
import { apiUploadFile } from "@covergo/cover-composables"
export default {
	name: "CUploadFiles",
	props: {
		filesOf: {
			validator(val) {
				return (
					val === "entity" ||
					val === "policy" ||
					val === "claim" ||
					val === "template" ||
					val === "templateAssets" ||
					val === "case" ||
					val === "proposals" ||
					val === "custom"
				)
			},
			required: true,
		},
		entityId: {
			type: String,
			required: true,
		},
		customPath: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			error: "",
			FILE_STATUS: {
				FAILED: this.$t("core.failed"),
				IN_PROGRESS: this.$t("core.inProgress"),
				READY: this.$t("core.ready"),
				UPLOADED: this.$t("core.uploaded"),
			},
			fileList: [],
			uploading: false,
		}
	},
	computed: {
		getUrl() {
			let path = ""

			switch (this.filesOf) {
				case "entity":
					path = encodeURIComponent(`entities/${this.entityId}/attachments`)
					break
				case "claim":
					path = encodeURIComponent(`claims/${this.entityId}`)
					break
				case "templateAssets":
					path = encodeURIComponent("templates/assets")
					break
				case "case":
					path = encodeURIComponent(`case/${this.entityId}`)
					break
				case "proposals":
					path = encodeURIComponent(`proposals/${this.entityId}`)
					break
				case "policy":
					path = encodeURIComponent(`policies/${this.entityId}/attachments`)
					break
				case "template":
					path = "templates/"
					break
				case "custom":
					path = this.customPath
					break
			}

			return path
		},
		filesToBeUploaded() {
			return this.fileList.filter((file) => file.status !== this.FILE_STATUS.UPLOADED)
		},
	},
	methods: {
		handleInputFiles(files) {
			this.fileList = [...this.fileList, ...this.buildFileObject(files)]
		},
		handleClickUpload() {
			if (this.uploading) return
			this.error = ""
			if (this.filesToBeUploaded.length === 0) {
				this.error = this.$t("core.noFiles_upload")
				return
			}
			this.uploading = true
			const uploadQueue = []
			this.fileList.forEach((fileObj) => {
				if (fileObj.status !== this.FILE_STATUS.UPLOADED) {
					uploadQueue.push(this.uploadFile(fileObj))
				}
			})
			Promise.all(uploadQueue).finally(() => {
				this.uploading = false
			})
		},
		handleReupload(fileObj) {
			this.error = ""
			this.uploadFile(fileObj)
		},
		handleRemoveFile(fileObj, index) {
			this.fileList.splice(index, 1)
		},
		/* eslint-disable */
		async uploadFile(fileObj) {
			fileObj.status = this.FILE_STATUS.IN_PROGRESS
			fileObj.percentage = 0
			fileObj.error = ""
			const formData = new FormData()
			formData.append("file", fileObj.file)
			const options = {
				onUploadProgress(progressEvent) {
					fileObj.percentage = Math.round((progressEvent.loaded * 100) / progressEvent.total)
				},
			}
			const { error } = await apiUploadFile({ url: this.getUrl, data: formData, options, fetcher: restFetcher })
			if (error) {
				fileObj.status = this.FILE_STATUS.FAILED
				fileObj.error = error?.message || this.$t("errors.general")
				handleErrorForUser({ error, $t: this.$t })
			} else {
				fileObj.status = this.FILE_STATUS.UPLOADED
			}
		},
		/* eslint-enable */
		buildFileObject(fileList) {
			return [...fileList].map((f) => ({
				status: this.FILE_STATUS.READY,
				percentage: 0,
				name: f.name,
				file: f,
			}))
		},
	},
}
</script>
