/* eslint-disable no-tabs */
export const get = (objValue, key, type) => objValue?.find(byKey(key))?.value?.[type] ?? null
export const formatMoney = (money) => {
	if (isNaN(Math.round(money))) return "..."
	return (
		Math.round(money)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "đ"
	)
}
export const formatMoneyWithoutCurrency = (money) => {
	if (isNaN(Math.round(money))) return "..."
	return Math.round(money)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
export const roundSAByRange = (sa) => {
	if (!sa) return
	const RANGE_TO_ROUND = 50000000
	if (sa % RANGE_TO_ROUND) {
		return sa - (sa % RANGE_TO_ROUND) + RANGE_TO_ROUND
	} else {
		return (sa / 1000000).toFixed() * 1000000
	}
}
export const formatToThousandAndRoundUpAmount = (amount) => {
	if (!amount) return
	return Math.round(amount / 1000)
}
// ex: 50000000 => "50.00" (mil VND)
export const formatNumber = ({ number, monthCount = 1 }) => {
	if (number === null) {
		return "0.00"
	}
	return ((number / 1000000) * monthCount).toFixed(2).toLocaleString()
}
export const getAge = (dateString) => {
	// ex: convert "1982-06-16" to current age
	const today = new Date()
	const birthDate = new Date(dateString)

	let age = today.getFullYear() - birthDate.getFullYear()
	const m = today.getMonth() - birthDate.getMonth()

	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--
	}

	return age
}
export const byKey = (key) => (item) => item.key === key
export const byType = (type) => (item) => item.type === type
export const getChildrenEducationFact = (childrenEducation) => {
	const values = childrenEducation.map((item) => {
		return {
			objectValue: [
				{
					key: "age",
					value: { numberValue: item.age },
				},
				{
					key: "ageToUseFund",
					value: { numberValue: item.ageToUseFund },
				},
				{
					key: "studyTime",
					value: { numberValue: item.studyTime },
				},
				{
					key: "expectedEducationFee",
					value: { numberValue: item.expectedEducationFee },
				},
			],
		}
	})
	return {
		arrayValue: values,
	}
}
export const getTargetEducationFact = (targetEducation) => {
	return {
		objectValue: [
			{
				key: "targetAmount",
				value: { numberValue: targetEducation.targetAmount }, // TEC-1849: (Sum of (Annual expenses of Child * Study duration of Child) of all added children-  (Current accumulated savings for future goals at Step4.2 * % Allocation of this goal at Step4.2 + Sum Assured amount of  life insurance package at Step4.2 * % Allocation of this goal at Step4.2))
			},
			{
				key: "targetDuration",
				value: { numberValue: targetEducation.targetDuration }, // TEC-1849:  Min of (Age to study of Child - Current Age of Child) of all added children at Step3
			},
		],
	}
}

export const getTargetGrowWealthAndAssetsFact = (targetGrowWealthAndAssets) => {
	return {
		objectValue: [
			{
				key: "targetAmount",
				value: { numberValue: targetGrowWealthAndAssets.targetAmount }, // TEC-1849 (Future saving/investment amount at Step3 - (Current accumulated savings for future goals at Step4.2 * % Allocation of this goal at Step4.2 + Sum Assured amount of  life insurance package at Step4.2 * % Allocation of this goal at Step4.2))
			},
			{
				key: "targetDuration",
				value: { numberValue: targetGrowWealthAndAssets.targetDuration }, // TEC-1849: Number of Years needed at Step3
			},
		],
	}
}

export const getTargetHealthAndMedicalFact = (targetHealthAndMedical) => {
	/**
	 * targetAmount: 
	 * ((Sum of 4 sub-goals) -  (Current accumulated savings for future goals at Step4.2 * % Allocation of this goal at Step4.2 + Sum Assured amount of  life insurance package at Step4.2 * % Allocation of this goal at Step4.2)), rounded 1000
			+ Sub-goal1 = Medical cash = 1000* Daily cash allowance amount at Step3
			+ Sub-goal2 = Healthcare = Inpatient +Outpatient + Dental chosenat Step3
			+ Sub-goal3 = Accident & Disability = Compensation Amount for accidental risk at Step3
			+ Sub-goal4 = Critical illness = Amount needed for critical illness purpose at Step3
	 */
	return {
		objectValue: [
			{
				key: "targetAmount",
				value: { numberValue: targetHealthAndMedical.targetAmount },
			},
			{
				key: "targetDuration",
				value: { numberValue: targetHealthAndMedical.targetDuration }, // TEC-1849: default is 0 if target amount has value
			},
		],
	}
}
export const getTargetRetirementFact = (targetRetirement) => {
	return {
		objectValue: [
			{
				key: "targetAmount",
				value: { numberValue: targetRetirement.targetAmount }, // (Number of years expected to use the pension fund at Step3 * Monthly expense after retirement at Step3 * 12 -  (Current accumulated savings for future goals at Step4.2 * % Allocation of this goal at Step4.2 + Sum Assured amount of  life insurance package at Step4.2 * % Allocation of this goal at Step4.2))
			},
			{
				key: "targetDuration",
				value: { numberValue: targetRetirement.targetDuration }, // Age plan to retire at Step3 - Current age of the customer at Step1
			},
		],
	}
}
export const getTargetFinanceFact = (targetFinance) => {
	/**
	 * targetAmount:
	 * (Monthly Living Expense coverage at Step3 * 12 * Number of years needed at Step3) + Current total outstanding loan at Step3  - (Current accumulated savings for future goals at Step4.2 * % Allocation of this goal at Step4.2 + Sum Assured amount of  life insurance package at Step4.2 * % Allocation of this goal at Step4.2)
	 */
	return {
		objectValue: [
			{
				key: "targetAmount",
				value: { numberValue: targetFinance.targetAmount },
			},
			{
				key: "targetDuration",
				value: { numberValue: targetFinance.targetDuration }, // Number of years needed at Step3
			},
		],
	}
}
export const getGrowWealthAndAssetsFact = (growWealthAndAssets) => {
	const growWealthValues = growWealthAndAssets?.growWealth
		? {
				objectValue: [
					{
						key: "targetSaving",
						value: {
							numberValue: growWealthAndAssets?.growWealth?.targetSaving || null,
						},
					},
					{
						key: "expectedTimeToReachTargetSaving",
						value: {
							numberValue: growWealthAndAssets?.growWealth?.expectedTimeToReachTargetSaving || null,
						},
					},
				],
				// eslint-disable-next-line no-mixed-spaces-and-tabs
		  }
		: null
	const bigPurchasesValues =
		growWealthAndAssets?.bigPurchases?.map((item) => {
			return {
				objectValue: [
					{
						key: "asset",
						value: {
							stringValue: item.asset,
						},
					},
					{
						key: "amount",
						value: {
							numberValue: item.amount,
						},
					},
					{
						key: "name",
						value: {
							stringValue: item.name || item.asset,
						},
					},
					{
						key: "yearsPlannedToBuy",
						value: {
							numberValue: item.yearsPlannedToBuy,
						},
					},
				],
			}
		}) ?? null
	return {
		objectValue: [
			{
				key: "goal",
				value: {
					stringValue: growWealthAndAssets.goal,
				},
			},
			{
				key: "bigPurchases",
				value: {
					arrayValue: bigPurchasesValues,
				},
			},
			{
				key: "growWealth",
				value: growWealthValues,
			},
		],
	}
}
export const getFinanceUncertaintyFact = ({ monthlyIncomeNeeded, currentLoan, protectionTime }) => {
	return {
		objectValue: [
			{
				key: "monthlyIncomeNeeded",
				value: {
					numberValue: monthlyIncomeNeeded,
				},
			},
			{
				key: "currentLoan",
				value: {
					numberValue: currentLoan,
				},
			},
			{
				key: "protectionTime",
				value: {
					numberValue: protectionTime,
				},
			},
		],
	}
}
export const getHealthAndMedicalFact = ({
	interestedBenefits,
	hospitalisedRoomAndBoard,
	roomAndBoardExpense,
	// healthCareOutpatientBenefit,
	accidentAndDisabilityCoverAmount,
	critticalIllnessCoverAmount,
}) => {
	return {
		objectValue: [
			{
				key: "interestedBenefits",
				value: {
					arrayValue:
						interestedBenefits?.map((benefit) => ({
							stringValue: benefit,
						})) ?? null,
				},
			},
			{
				key: "hospitalisedRoomAndBoard",
				value: {
					numberValue: hospitalisedRoomAndBoard,
				},
			},
			{
				key: "roomAndBoardExpense",
				value: {
					objectValue: [
						{
							key: "inpatient",
							value: {
								numberValue: roomAndBoardExpense?.inpatient,
							},
						},
						{
							key: "outpatient",
							value: {
								numberValue: roomAndBoardExpense?.outpatient,
							},
						},
						{
							key: "dental",
							value: {
								numberValue: roomAndBoardExpense?.dental,
							},
						},
					],
				},
			},
			{
				key: "accidentAndDisabilityCoverAmount",
				value: {
					numberValue: accidentAndDisabilityCoverAmount,
				},
			},
			{
				key: "critticalIllnessCoverAmount",
				value: {
					numberValue: critticalIllnessCoverAmount,
				},
			},
		],
	}
}
export const getRetirementFact = ({ retirementAge, expectedMonthlyExpenseAfterRetirement, howLongRetirement }) => {
	return {
		objectValue: [
			{
				key: "retirementAge",
				value: {
					numberValue: retirementAge,
				},
			},
			{
				key: "expectedMonthlyExpenseAfterRetirement",
				value: {
					numberValue: expectedMonthlyExpenseAfterRetirement,
				},
			},
			{
				key: "howLongRetirement",
				value: {
					numberValue: howLongRetirement,
				},
			},
		],
	}
}
export const getHavingFact = ({
	householdIncome,
	householdIncomeDetails,
	householdExpense,
	householdExpenseDetails,
	yourAccumulatedSaving,
	currentProtectionCoverage,
	allocationPercentage,
	allocationCalculatedAmount,
	totalNetSaving,
}) => {
	return {
		objectValue: [
			{
				key: "householdIncome",
				value: {
					numberValue: householdIncome, // total household income field at step 4
				},
			},
			{
				key: "householdIncomeDetails",
				value: {
					objectValue: [
						{
							key: "investmentBusinessIncome",
							value: {
								numberValue: householdIncomeDetails?.investmentBusinessIncome,
							},
						},
						{
							key: "salaryBonusIncome",
							value: {
								numberValue: householdIncomeDetails?.salaryBonusIncome,
							},
						},
						{
							key: "rentalIncome",
							value: {
								numberValue: householdIncomeDetails?.rentalIncome,
							},
						},
						{
							key: "bankInterestIncome",
							value: {
								numberValue: householdIncomeDetails?.bankInterestIncome,
							},
						},
						{
							key: "dividendIncome",
							value: {
								numberValue: householdIncomeDetails?.dividendIncome,
							},
						},
						{
							key: "otherIncome",
							value: {
								numberValue: householdIncomeDetails?.otherIncome,
							},
						},
					],
				},
			},
			{
				key: "householdExpense",
				value: {
					numberValue: householdExpense, // total expense
				},
			},
			{
				key: "householdExpenseDetails",
				value: {
					objectValue: [
						{
							key: "livingExpense",
							value: {
								numberValue: householdExpenseDetails?.livingExpense,
							},
						},
						{
							key: "educationExpense",
							value: {
								numberValue: householdExpenseDetails?.educationExpense,
							},
						},
						{
							key: "contingencyBudget",
							value: {
								numberValue: householdExpenseDetails?.contingencyBudget,
							},
						},
						{
							key: "entertainmentExpense",
							value: {
								numberValue: householdExpenseDetails?.entertainmentExpense,
							},
						},
						{
							key: "insuranceExpense",
							value: {
								numberValue: householdExpenseDetails?.insuranceExpense,
							},
						},
						{
							key: "liabilityExpense",
							value: {
								numberValue: householdExpenseDetails?.liabilityExpense,
							},
						},
						{
							key: "otherExpense",
							value: {
								numberValue: householdExpenseDetails?.otherExpense,
							},
						},
					],
				},
			},
			{
				key: "yourAccumulatedSaving",
				value: {
					numberValue: yourAccumulatedSaving, // Gia tri khoan tich luy - Current accumulated saving for future goals at Step4.2
				},
			},
			{
				key: "currentProtectionCoverage", // Life insurance package at Step4.2
				value: {
					numberValue: currentProtectionCoverage,
				},
			},
			{
				key: "allocationCalculatedAmount",
				value: {
					stringValue: allocationCalculatedAmount
						? typeof allocationCalculatedAmount === "string"
							? allocationCalculatedAmount
							: JSON.stringify(allocationCalculatedAmount)
						: null,
				},
			},
			{
				key: "allocationPercentage",
				value: {
					stringValue: allocationPercentage
						? typeof allocationPercentage === "string"
							? allocationPercentage
							: JSON.stringify(allocationPercentage)
						: null,
				},
			},
			{
				key: "totalNetSaving",
				value: {
					numberValue: totalNetSaving,
				},
			},
		],
	}
}

export const getChildrenEducationFromFact = (childrenEducationFromFact) => {
	const childrenEducationFromFactValue = childrenEducationFromFact?.value?.arrayValue
	return childrenEducationFromFactValue?.map((item) => {
		const value = item.objectValue
		return {
			age: get(value, "age", "numberValue"),
			ageToUseFund: get(value, "ageToUseFund", "numberValue"),
			studyTime: get(value, "studyTime", "numberValue"),
			expectedEducationFee: get(value, "expectedEducationFee", "numberValue"),
		}
	})
}

export const getTargetEducationFromFact = (targetEducationFromFact) => {
	const targetEducationFromFactValue = targetEducationFromFact?.value?.objectValue
	return {
		targetAmount: get(targetEducationFromFactValue, "targetAmount", "numberValue"),
		targetDuration: get(targetEducationFromFactValue, "targetDuration", "numberValue"),
	}
}

export const getTargetHealthAndMedicalFromFact = (targetHealthAndMedicalFromFact) => {
	const targetHealthAndMedicalFromFactValue = targetHealthAndMedicalFromFact?.value?.objectValue
	return {
		targetAmount: get(targetHealthAndMedicalFromFactValue, "targetAmount", "numberValue"),
		targetDuration: get(targetHealthAndMedicalFromFactValue, "targetDuration", "numberValue"),
	}
}

export const getTargetGrowWealthAndAssetsFromFact = (targetGrowWealthAndAssetsFromFact) => {
	const targetGrowWealthAndAssetsFromFactValue = targetGrowWealthAndAssetsFromFact?.value?.objectValue
	return {
		targetAmount: get(targetGrowWealthAndAssetsFromFactValue, "targetAmount", "numberValue"),
		targetDuration: get(targetGrowWealthAndAssetsFromFactValue, "targetDuration", "numberValue"),
	}
}

export const getTargetFinanceFromFact = (targetFinanceFromFact) => {
	const targetFinanceFromFactValue = targetFinanceFromFact?.value?.objectValue
	return {
		targetAmount: get(targetFinanceFromFactValue, "targetAmount", "numberValue"),
		targetDuration: get(targetFinanceFromFactValue, "targetDuration", "numberValue"),
	}
}

export const getTargetRetirementFromFact = (targetRetirementFromFact) => {
	const targetRetirementFromFactValue = targetRetirementFromFact?.value?.objectValue
	return {
		targetAmount: get(targetRetirementFromFactValue, "targetAmount", "numberValue"),
		targetDuration: get(targetRetirementFromFactValue, "targetDuration", "numberValue"),
	}
}

export const getGrowWealthAndAssetsFromFact = (growWealthAndAssetsFromFact) => {
	const growWealthAndAssetsFromFactValue = growWealthAndAssetsFromFact?.value?.objectValue
	const goalValue = get(growWealthAndAssetsFromFactValue, "goal", "stringValue")
	const bigPurchasesValue = get(growWealthAndAssetsFromFactValue, "bigPurchases", "arrayValue")
	const growWealthValue = get(growWealthAndAssetsFromFactValue, "growWealth", "objectValue")
	return {
		goal: goalValue,
		bigPurchases: bigPurchasesValue?.map((item) => {
			const value = item?.objectValue
			return {
				selected: true,
				asset: get(value, "asset", "stringValue"),
				amount: get(value, "amount", "numberValue"),
				name: get(value, "name", "stringValue"),
				yearsPlannedToBuy: get(value, "yearsPlannedToBuy", "numberValue"),
			}
		}),
		growWealth: {
			targetSaving: get(growWealthValue, "targetSaving", "numberValue"),
			expectedTimeToReachTargetSaving: get(growWealthValue, "expectedTimeToReachTargetSaving", "numberValue"),
		},
	}
}

export const getFinanceUncertaintyFromFact = (financeUncertaintyFromFact) => {
	const financeUncertaintyFromFactValue = financeUncertaintyFromFact?.value?.objectValue
	return {
		monthlyIncomeNeeded: get(financeUncertaintyFromFactValue, "monthlyIncomeNeeded", "numberValue"),
		currentLoan: get(financeUncertaintyFromFactValue, "currentLoan", "numberValue"),
		protectionTime: get(financeUncertaintyFromFactValue, "protectionTime", "numberValue"),
	}
}

export const getHealthAndMedicalFromFact = (healthAndMedicalFromFact) => {
	const healthAndMedicalFromFactvalue = healthAndMedicalFromFact?.value?.objectValue
	const interestedBenefits = get(healthAndMedicalFromFactvalue, "interestedBenefits", "arrayValue")
	const roomAndBoardExpenseBenefits = get(healthAndMedicalFromFactvalue, "roomAndBoardExpense", "objectValue")
	return {
		interestedBenefits: interestedBenefits?.map((item) => item.stringValue),
		hospitalisedRoomAndBoard: get(healthAndMedicalFromFactvalue, "hospitalisedRoomAndBoard", "numberValue"),
		roomAndBoardExpense: {
			inpatient: get(roomAndBoardExpenseBenefits, "inpatient", "numberValue"),
			outpatient: get(roomAndBoardExpenseBenefits, "outpatient", "numberValue"),
			dental: get(roomAndBoardExpenseBenefits, "dental", "numberValue"),
		},
		accidentAndDisabilityCoverAmount: get(healthAndMedicalFromFactvalue, "accidentAndDisabilityCoverAmount", "numberValue"),
		critticalIllnessCoverAmount: get(healthAndMedicalFromFactvalue, "critticalIllnessCoverAmount", "numberValue"),
	}
}

export const getRetirementFromFact = (retirementFromFact) => {
	const retirementFromFactValue = retirementFromFact?.value?.objectValue
	return {
		retirementAge: get(retirementFromFactValue, "retirementAge", "numberValue"),
		expectedMonthlyExpenseAfterRetirement: get(retirementFromFactValue, "expectedMonthlyExpenseAfterRetirement", "numberValue"),
		howLongRetirement: get(retirementFromFactValue, "howLongRetirement", "numberValue"),
	}
}

export const getHavingFromFact = (havingFromFact) => {
	const havingFromFactValue = havingFromFact?.value?.objectValue

	const householdIncomeDetailsFromFact = get(havingFromFactValue, "householdIncomeDetails", "objectValue")
	const householdIncomeDetails = {
		investmentBusinessIncome: get(householdIncomeDetailsFromFact, "investmentBusinessIncome", "numberValue"),
		salaryBonusIncome: get(householdIncomeDetailsFromFact, "salaryBonusIncome", "numberValue"),
		rentalIncome: get(householdIncomeDetailsFromFact, "rentalIncome", "numberValue"),
		bankInterestIncome: get(householdIncomeDetailsFromFact, "bankInterestIncome", "numberValue"),
		dividendIncome: get(householdIncomeDetailsFromFact, "dividendIncome", "numberValue"),
		otherIncome: get(householdIncomeDetailsFromFact, "otherIncome", "numberValue"),
	}

	const householdExpenseDetailsFromFact = get(havingFromFactValue, "householdExpenseDetails", "objectValue")
	const householdExpenseDetails = {
		livingExpense: get(householdExpenseDetailsFromFact, "livingExpense", "numberValue"),
		educationExpense: get(householdExpenseDetailsFromFact, "educationExpense", "numberValue"),
		contingencyBudget: get(householdExpenseDetailsFromFact, "contingencyBudget", "numberValue"),
		entertainmentExpense: get(householdExpenseDetailsFromFact, "entertainmentExpense", "numberValue"),
		insuranceExpense: get(householdExpenseDetailsFromFact, "insuranceExpense", "numberValue"),
		liabilityExpense: get(householdExpenseDetailsFromFact, "liabilityExpense", "numberValue"),
		otherExpense: get(householdExpenseDetailsFromFact, "otherExpense", "numberValue"),
	}

	return {
		householdIncome: get(havingFromFactValue, "householdIncome", "numberValue"),
		householdIncomeDetails,
		householdExpense: get(havingFromFactValue, "householdExpense", "numberValue"),
		householdExpenseDetails,
		yourAccumulatedSaving: get(havingFromFactValue, "yourAccumulatedSaving", "numberValue"),
		currentProtectionCoverage: get(havingFromFactValue, "currentProtectionCoverage", "numberValue"),
		totalNetSaving: get(havingFromFactValue, "totalNetSaving", "numberValue"),
		allocationCalculatedAmount: get(havingFromFactValue, "allocationCalculatedAmount", "stringValue"),
		allocationPercentage: get(havingFromFactValue, "allocationPercentage", "stringValue"),
	}
}

export const downloadFile = (name, blob) => {
	const link = document.createElement("a")
	link.href = window.URL.createObjectURL(blob)
	link.download = name
	document.body.appendChild(link)
	setTimeout(() => {
		link.click()
		document.body.removeChild(link)
	}, 200)
}

export const detectBrowser = () => {
	const { userAgent } = navigator

	if (userAgent.includes("Firefox/")) {
		return "firefox"
	} else if (userAgent.includes("Edg/")) {
		return "edge"
	} else if (userAgent.includes("Chrome/")) {
		return "chrome"
	} else if (userAgent.includes("Safari/")) {
		return "safari"
	}
}

export const openFileInNewTab = ({ name, blob, eposUrl }) => {
	const link = document.createElement("a")
	link.href = blob ? window.URL.createObjectURL(blob) : eposUrl
	link.target = "_blank"
	link.download = name
	document.body.appendChild(link)
	setTimeout(() => {
		link.click()
		document.body.removeChild(link)
	}, 200)
}

export const roundFaceAmount = (fa) => {
	if (fa < 1000000) {
		// fa = 580000
		// 500000 ~ 600000
		return (fa / 100000).toFixed(1) * 100000
	}
	return (fa / 1000000).toFixed(1) * 1000000
}

export const roundSA = (sa) => {
	return (sa / 1000000).toFixed() * 1000000
}

// eslint-disable-next-line no-unused-vars
const exampleFact = {
	type: "fnaAnswers",
	value: {
		objectValue: [
			{
				key: "childrenEducation",
				value: {
					arrayValue: [
						{
							objectValue: [
								{ key: "age", value: { numberValue: 12 } },
								{ key: "ageToUseFund", value: { numberValue: 12 } },
								{ key: "studyTime", value: { numberValue: 12 } },
								{ key: "expectedEducationFee", value: { numberValue: 12 } },
							],
						},
					],
				},
			},
			{
				key: "growWealthAndAssets",
				value: {
					objectValue: [
						{
							key: "bigPurchases",
							value: {
								arrayValue: [
									{
										objectValue: [
											{ key: "asset", value: { stringValue: "house" } },
											{ key: "amount", value: { numberValue: 1000000 } },
											{ key: "yearsPlannedToBuy", value: { numberValue: 10 } },
											{ key: "targetSaving", value: { numberValue: 50000 } },
											{ key: "expectedTimeToReachTargetSaving", value: { numberValue: 10 } },
										],
									},
								],
							},
						},
						{ key: "growWealth", value: null },
					],
				},
			},
			{
				key: "financeUncertainty",
				value: {
					objectValue: [
						{ key: "monthlyIncomeNeeded", value: { numberValue: 4000 } },
						{ key: "currentLoan", value: { numberValue: 0 } },
						{ key: "protectionTime", value: { numberValue: 20 } },
					],
				},
			},
			{
				key: "healthAndMedical",
				value: {
					objectValue: [
						{
							key: "interestedBenefits",
							value: {
								arrayValue: [{ stringValue: "accidentAndDisability" }, { stringValue: "CI" }, { stringValue: "medicalCare" }],
							},
						},
						{
							key: "hospitalisedRoomAndBoard",
							value: {
								objectValue: [
									{
										key: "inpatient",
										value: {
											numberValue: 125000000,
										},
									},
									{
										key: "outpatient",
										value: {
											numberValue: 0,
										},
									},
									{
										key: "dental",
										value: {
											numberValue: 0,
										},
									},
								],
							},
						},
						{ key: "accidentAndDisabilityCoverAmount", value: { numberValue: 50000 } },
						{ key: "critticalIllnessCoverAmount", value: { numberValue: 50000 } },
					],
				},
			},
			{
				key: "retirement",
				value: {
					objectValue: [
						{ key: "retirementAge", value: { numberValue: 65 } },
						{ key: "expectedMonthlyExpenseAfterRetirement", value: { numberValue: 2000 } },
						{ key: "howLongRetirement", value: { numberValue: 10 } },
					],
				},
			},
			{
				key: "having",
				value: {
					objectValue: [
						{ key: "yourAccumulatedSaving", value: { numberValue: 5000 } },
						{ key: "currentProtectionCoverage", value: { numberValue: 0 } },
					],
				},
			},
		],
	},
}

export function randomString(length) {
	let result = ""
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

// "sài gòn" => "sai gon"
export function removeVietnameseTone(str) {
	return str
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/đ/g, "d")
		.replace(/Đ/g, "D")
}

export function getHavingFactsFromCaseAtConversationInquiryPage({ answersFacts }) {
	const answersFact = answersFacts?.find((fact) => fact?.type === "fnaAnswers")?.value?.objectValue
	return getHavingFromFact(answersFact?.find(byKey("having")))
}

export function countTargetAmount({
	sumGoals,
	yourAccumulatedSaving,
	accumulatedSavingPercent = 0,
	currentProtectionCoverage,
	insurancePercent = 0,
}) {
	const coverageFromAccumulatedSavingAndInsurance =
		yourAccumulatedSaving * (accumulatedSavingPercent / 100) + currentProtectionCoverage * (insurancePercent / 100)
	return sumGoals - coverageFromAccumulatedSavingAndInsurance
}

export function getAnswersFromFacts({ facts }) {
	const answersFactValue = facts?.value?.objectValue
	return {
		childrenEducation: getChildrenEducationFromFact(answersFactValue?.find(byKey("childrenEducation"))),
		growWealthAndAssets: getGrowWealthAndAssetsFromFact(answersFactValue?.find(byKey("growWealthAndAssets"))),
		financeUncertainty: getFinanceUncertaintyFromFact(answersFactValue?.find(byKey("financeUncertainty"))),
		healthAndMedical: getHealthAndMedicalFromFact(answersFactValue?.find(byKey("healthAndMedical"))),
		retirement: getRetirementFromFact(answersFactValue?.find(byKey("retirement"))),
		having: getHavingFromFact(answersFactValue?.find(byKey("having"))),
		targetEducation: getTargetEducationFromFact(answersFactValue?.find(byKey("targetEducation"))),
		targetGrowWealthAndAssets: getTargetGrowWealthAndAssetsFromFact(answersFactValue?.find(byKey("targetGrowWealthAndAssets"))),
		targetHealthAndMedical: getTargetHealthAndMedicalFromFact(answersFactValue?.find(byKey("targetHealthAndMedical"))),
		targetRetirement: getTargetRetirementFromFact(answersFactValue?.find(byKey("targetRetirement"))),
		targetFinance: getTargetFinanceFromFact(answersFactValue?.find(byKey("targetFinance"))),
	}
}
