/* eslint-disable quotes */
export default {
	rbo: {
		CRM_ID: "CRM ID",
		noInformation: "No information",
		sa: "Sum assured",
		title: "Hello",
		step1: "Step 1",
		step5: "Step 5",
		gender: "Gender",
		age: "Age",
		plsPickResult: "What is the result?",
		ageTooltip:
			"Age is based on the last birthday of the customer. Eg: If the customer was born on 24 Dec 2002, if today is before 24 Dec 2020 then input 17, if today is from 24 Dec 2020 then input 18.",
		validAge: "Age must be between 18 and 99",
		plsSelectDate: "Please select date",
		plsTypeAge: "Please type age",
		dependent: "Dependent {number}",
		numberOfDependents: "Number of children",
		fullNameOfDependent: "Full name of dependent",
		ageOfDependent: "Age of child { number }",
		ageOfSpouse: "Age of spouse",
		numberOfDependentsTooltip:
			"Dependent is a person having a personal relationship such as parent, spouse, natural child, adopted child, parent of the spouse, or siblings for whom he / she is financially responsible.",
		spouseDependentName: "Customer's spouse name",
		firstDependentName: "The first dependent",
		secondDependentName: "The second dependent",
		thirdDependentName: "The third dependent",
		updateCustomerInfoOnCRM:
			"If you adjust some customer information that gotten from CRM, please update that change on T24/CRM after this conversation",
		successCustomerInfoForm: "Saved customer information successfully!",
		errorCustomerInfoForm: "Please fill in the required fields",
		errorGoToNextStepWithCanceledStatus: "You can't go to next step because conversation status is canceled",
		tellUsAboutYourSelf: "Tell us about yourself",
		general: "General",
		conversationInfo: "Conversation Information",
		gcmLeadInfo: "CRM Lead Information",
		welcomeToApp: "Welcome to iTCBLife",
		planningForFuture: "Plan today - Peaceful tomorrow",
		tcbInfo:
			"Techcombank is one of the leading commercial banks in Vietnam with a sustainable financial foundation and outstanding product - service portfolio.",
		tcbDetailInfo:
			"Here you can get your best TCB product in just 4 steps. We will go through a few sets of questions to know you more.",
		aboutTcb: "About Techcombank",
		tcbMission: "Message from Techcombank's Board Of Management",
		promotionTitle: "Yêu thương đầy tim, tiền hoàn đầy túi",
		promotionContent: "Give money to customer's account or gift of equivalent value",
		businessData:
			"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi obcaecati ex culpa eum veritatis hic deleniti optio aliquid. Voluptatem, fugiat illum. Modi minima voluptatum nam alias eius quo autem veritatis.",
		futureGoals: "Customer goals & objectives in 5 - 10 years are:",
		step: "Step",
		chooseMoreThanOnePrio: "(Please select maximum 3 priority goals)",
		childrenEducation: "Education",
		growWealthAndAssets: "Grow asset",
		financeUncertainty: "Finance Protection",
		healthAndMedical: "Healthcare",
		retirement: "Retirement",
		childrenEducationGoal: "Education",
		growWealthAndAssetsGoal: "Grow asset",
		financeUncertaintyGoal: "Finance Protection",
		healthAndMedicalGoal: "Healthcare",
		retirementGoal: "Retirement",
		childrenEducationChartLabel: "Education",
		growWealthAndAssetsChartLabel: "Grow asset",
		growWealthChartLabel: "Grow wealth",
		financeUncertaintyChartLabel: "Finance Protection",
		healthAndMedicalChartLabel: "Healthcare",
		healthCareChartLabel: "Healthcare",
		retirementChartLabel: "Retirement",
		currentCoverageChartLabel: "Current coverage",
		savingChartLabel: "Saving",
		houseChartLabel: "House",
		othersChartLabel: "Others",
		carChartLabel: "Car",
		child1ChartLabel: "Child 1",
		child2ChartLabel: "Child 2",
		child3ChartLabel: "Child 3",
		medicalCareChartLabel: "Medical",
		accidentAndDisabilityChartLabel: "Accident & Disability",
		CIChartLabel: "Critical illness",
		yourAccumulatedSaving: "Your accumulated saving",
		currentProtectionCoverage: "Current protection coverage",
		back: "Back",
		next: "Next",
		QaChildrenOld: "Customer's children age",
		addChildrenFact: "Please tell us the current age of the child",
		childrenAgeTips: "Add up to 3 additional children that you want to plan for your studies",
		addChildBtn: "Add more children",
		addAChild: "Thêm tối đa 1 con mà anh chị muốn lên kế hoạch cho việc học hành",
		child: "Child",
		childAge: "CHILDREN {childNumber}:",
		childAgeOrder: "CHILDREN {order}: {age} year(s) old",
		saMinError: "Minimum {min}đ",
		saMinErrorUnitLink: "Minimum is {min}đ, or a number that makes premium greater than 7.000.000 VND/year.",
		saMaxError: "Maximum {max}đ",
		saAddError: "Minimum is {min} and maximum is {max}",
		saMcError: "Minimum is {min} and maximum is {max}",
		saCiError: "Minimum is {min} and maximum is {max}",
		generalNullError: "Please make sure all fields are filled in correctly",
		childAgeRestrictionError: "Age must be less than 99",
		childAgeToStudyErrorMessage: "Study duration should be within [0 : 99]",
		QaProtectionTime: "Years of support",
		QaProvideIncomeMonthly: "Monthly expense coverage",
		QaProvideIncomeToDependants: "How many years should income be provided for your dependants after you're gone?",
		obligationLiability: "Obligation (current liability/loan)",
		obligationLiabilityFact: "What is your current total outstanding loan?",
		obligationLiabilityTip: "Do you currently have to pay installments for your home loan, car loan or credit?",
		provideIncomeMonthlyFact: "How much would you like to have monthly to maintain your quality of life in all situations?",
		yearProtectionForDependantsFact: "Number of years needed to ensure living expenses for the family",
		amount: "Amount",
		currency: "đ",
		tip: "Tips",
		financeMonthlyIncomeTips: `The monthly living expenses include education, shopping, living, meals, entertainment, beauty, medicine,...
		Do not include liabilities such as mortgage installments.`,
		obligationLoanTips: "",
		protectionTimeTips: "",
		saving: "Saving",
		QaChildrenAgeToUseFund: "Age to use the fund",
		expectedAgeToUseFundFact: "At what age of your children you intend to send them to university / college / vocational school?",
		childrenAgeToUseFundTips: "The usual age for starting college is 18",
		QaChildrenTimeToStudy: "Study duration in years",
		expectedTimeToStudyFact: "How long do you expect your child to study?",
		childrenTimeToStudyTips:
			"Normally, the duration of the university study is 4 years, the Master is 2 years (or 1 year if studying in the UK)",
		QaChildrenAnnualEduFree: "Expected annual education fee",
		expectedChildrenAnnualEduFeeFact:
			"How much per year do want to provide for your child, including tuition and living expenses?",
		childrenAnnualEduFeeTips: "Most of the university in Vietnam cost $100,000 per year",
		conversations: "Conversations",
		questionBuyWealthOrPurchase: "Grow wealth or make big purchase?",
		step2: "Step 2",
		step3: "Step 3",
		youCanChooseMore: "You can choose more than one",
		QaAssetCost: "What assets do you want to buy?",
		bigPurchaseFact: "What assets are you planning to buy and how much is it worth at present?",
		typeOfItem: "Type of item",
		nameOfItem: "Name of item",
		tips: "Tips",
		addItem: "ADD ITEM",
		QaWhenWillMakePurchase: "Number of years to buy",
		planToBuy: "How much longer do you expect to buy?",
		planToBuyFact: "",
		quesTargetSaving: "Target savings/investment",
		expectedSaving: "Your expected savings",
		expectedSavingFact: "How much value do you want for saving/investment to be in the future?",
		quesReachTargetSaving: "Number of years to reach target savings",
		numberOfYearReachTargetSaving: "Number of years to reach your target savings",
		yearReachTargetSavingsFact: "How many years do you wish to achieve this plan?",
		areYouSureYouWantToQuit: "Are you sure you want to quit the current conversation?",
		quitAndDontSave: "Quit & Don't Save",
		quitAndSave: "Quit & Save",
		dropoutReason: "What is the drop-out reason?",
		submit: "Done",
		ok: "OK",
		notInterested: "Customer is not interested",
		productNotSuit: "Customer has no need",
		customerBusy: "Customer is busy",
		otherDropOffReason: "Other",
		typeReasonPlaceholder: "Please type reason",
		otherReasonHeader: "Note",
		otherReasonNote: "Please specify other reason (if any)",
		createLead: "Create Lead",
		createLeadSuccessMessage: "Lead ID created successfully!",
		referLeadSuccessMessage: "Lead ID referred successfully!",
		referLeadFNASuccessMessage: "Generated FNA file successfully!",
		createLeadErrorMessage: "Failed to create Lead ID.",
		referLeadErrorMessage: "Failed to refer Lead ID.",
		referLeadFNAErrorMessage: "Failed to generate FNA file.",
		itcblifeSystemErrorMessage: "The TCB system doesn't respond. Please contact your administrator for supporting.",
		itcblifeSystemTimeoutMessage: "The connection has timed out. Please try again in a few moments.",
		itcblifeRecaptchaMessage: "Issue when verify reCAPTCHA. Please check your network. Make sure you are using public internet.",
		mvpVersionErrorMessage: "Can't integrate with TCB system because this case is created from mvp 1.5",
		noConversationResultSelectedMessage: "No conversation result is found for this case. Please go to step 7 to select it.",
		selectConversationResultBeforeMappingLeadMessage: "Please continue conducting this conversation before mapping Lead CRM",
		QaBenefitsHealthInterested: "Benefits customer interested in?",
		expectedBenefitsHealthFact: "You can select multiple benefits",
		medicalCare: "Medical cash",
		healthCare: "Healthcare",
		accidentAndDisability: "Accident & Disability",
		CI: "Critical illness",
		benefitsHealthInterestedTips: "lorem lorem",
		QaHealthRoomNBoard: "Medical cash",
		expectedHealthRoomNBoardFact: "How much do you want for daily cash allowance?",
		expectedHealthRoomNBoardTip: "The normal allowance is 75% of daily income, and does not exceed 3 million VND/day.",
		QaHealthcare: "Healthcare",
		roomAndBoardExpense: "Which medical benefits that customer is interested?",
		outpatientBenefitQuestion: "Do you want to have outpatient benefit?",
		QaHealthAccidentCover: "Accident & Disability",
		expectedHealthAccidentCoverFact: "How much compensation for accidental risk do you want?",
		healthAccidentCoverTips: "Data: 14,000 traffic accidents in 2020.\n" + "Source: National Traffic Safety Committee 2020",
		QaHealthCriticalIllness: "Critical illness",
		expectedHealthCriticalIllnessFact: "How much do you need for critical illness purpose?",
		healthCriticalIllnessTips1: "22% of patients have financial difficulties after one year of illness.",
		healthCriticalIllnessTips2: "34% of patients cannot pay for the drug.",
		healthCriticalIllnessTips3: "25% of patients cannot pay the increased cost.",
		healthCriticalIllnessTips4: "21% of patients cannot pay the transportation cost.",
		QARetirement: "Target retirement age",
		retirementAgeFact: "What age do you plan to retire?",
		retirementAgeTip: "The retirement age in Vietnam is 65 for men, and 60 for women.",
		QaRetirementMonthlyExpense: "Monthly expense after retirement",
		retirementMonthlyExpenseFact: "How much monthly income after retirement do you want?",
		retirementMonthlyExpenseTip:
			"This income is used to cover essential expenses such as travel, vacation, meals, health care and debt (if any).",
		retirementTypicallyIncomeFact: "How much cost of living support do you want after your retirement?",
		QaHowLongRetirement: "Retirement period",
		howLongRetirementFact: "How many years do you expect to use the pension fund?",
		howLongRetirementTip:
			"The average life span of using pension fund is 20 years for men, and 25 years for women based on Vietnamese lifespan.",
		step4: "Step 4",
		QaCurrentMonthlyIncome: "Current monthly household income",
		QaCurrentMonthlyIncomeFact: "Could you share about the total monthly income of your whole family?",
		detailOfIncomeIfAny: "Detail of income (if any)",
		investmentBusinessIncome: "Income from investment, business",
		salaryBonusIncome: "Income from salary, bonus",
		rentalIncome: "Rental income",
		bankInterestIncome: "Bank's interest",
		dividendIncome: "Dividend",
		otherIncome: "Others",
		totalIncome: "Total income",
		incomeDetailsEqualToTotalMonthlyIncome: "Total of details must be equal to Current monthly household income",
		selectApproximateFact: "Please enter an approximate range",
		plsSelect: "Please Select",
		QaCurrentHouseHoldExpenses: "Income - Expense Information",
		QaCurrentHouseHoldExpensesFact: "Could you share some information about your household income and expense?",
		QaCurrentExpensesFact: "Could you share some information about your monthly income and expense?",
		detailOfExpenseIfAny: "Detail of expense (if any)",
		householdLivingExpense: "1. Living expense of household/month",
		livingExpense: "1. Living expense/month",
		pleaseInputFields: "1. Please input 1 in 2 fields below",
		educationExpense: "Education",
		contingencyBudget: "Contingency",
		entertainmentExpense: "Entertainment, social relationship,...",
		insuranceExpense: "Insurance",
		liabilityExpense: "Liabilities",
		otherExpense: "Others",
		totalExpense: "Total expense",
		expenseDetailsEqualToTotalMonthlyExpense: "Total of details must be equal to Current monthly household expense",
		QaAccumulatedSavings: "Current accumulated savings for future goals",
		currentSavingFact: "Currently, how much accumulation / investment or assets do you have to achieve your goal?",
		currentSavingTip: "Including stocks, bonds or liquid real estate,...",
		QaPersonalLifeInsuranceCoverage: "Personal life insurance coverage",
		insuranceTotalSumFact:
			"Have you participated in any life insurance package before? What is the total value of protection of life insurance packages that you have participated in?",
		editModalRetirementTitle: "Retirement",
		editModalRetirementAge: "Your target Retirement age",
		editModalRetirementExpenses: "Your monthly expenses after retirement",
		editModalRetirementPeriod: "Your retirement period",
		closeBtn: "Close",
		editModalWealthAssets: "Grow wealth & assets",
		house: "House",
		car: "Car",
		others: "Others",
		currentValue: "Target savings",
		yearsAmount: "Number of years",
		asset: "Asset",
		targetSaving: "Your current savings",
		expectedTimeToReachTargetSaving: "Expected time to reach target saving",
		editModalChildrenEduAge: "Child education",
		editModalChildrenEduChildAge: "Child age",
		editModalChildrenEduAgeToUseFund: "Age to study",
		editModalChildrenEduExpectedEducationFee: "Annual expenses",
		editModalChildrenEduStudyTime: "Study duration",
		editModalFinanceUncertainTitle: "Protect dependants from financial uncertainty (for adjustment)",
		editModalFUMonthlyIncomeNeeded: "Expected monthly coverage",
		editModalFUMCurrentLoan: "Obligation (Current liability/loan)",
		editModalFUMProtectionTime: "Year of coverage",
		editModalHealthTitle: "Health & Medical",
		editModalHealthHospitalised: "Medical cash",
		editModalHealthcare: "Healthcare",
		editModalHealthAccidentNDisabilityCoverAmount: "Accident & Disability",
		editModalHealthCriticalIllnessAmount: "Critical Illness",
		summary: "Summary",
		titleSummaryCongrats:
			"Congratulation, you have a good plan for your future, we will help you to protect it for your future plan",
		titleSummary: "With your current situation, you have achieved only",
		titleSummaryOfPercent: "70%",
		titleSummarySub: "of your goal(s), we can support you to achieve your goal with our suitable solution.",
		edit: "Edit",
		currentProtection: "Current protection coverage",
		interest: "Interest",
		inflation: "Inflation",
		thanksForChoosingUs: "Thank you for choosing us!",
		yourConversationId: "Conversation ID:",
		titleFinishStep: "Let us continue with your insurance application and provide you the required coverage & protection.",
		yourGoal: "Your Goal",
		iWantToGrow:
			"I want to grow my wealth & assets, protect my finances from impact of illness/bad health and plan for my retirement.",
		yourProduct: "Your Product",
		finish: "Finish",
		downloadReport: "Download",
		step6: "Step 6",
		customizeProduct: "Customize Your Product",
		selectYourProduct: "select your product",
		coverYourShortfall: "To cover your shortfall of 5.000.000đ, we should suggest the following products",
		choose: "Choose",
		highlyRecommend: "HIGHLY RECOMMEND",
		paymentTerm: "Payment term",
		chooseOneOfAssetsFact: "For below goals, which one are you prioritizing?",
		assetsBigPurchaseTips: "You can select up to 3 assets",
		assetHouse: "House",
		assetCar: "Car",
		assetOthers: "Others",
		reachTargetSavingTips: "Lorem lorem",
		assetPlanToBuyTips: "How much longer do you expect to buy?",
		assetTargetSavingTips: "Lorem lorem",
		accumulatedSavingTips: "",
		currentProtectionCoverageTips: "",
		targetSavingTip: "Asset accumulation is a long-term priority. The cumulative time should is 10-15 years.",
		householdExpenseTips:
			"The monthly living expenses include: Education, shopping, living, dining, entertainment, socializing, filial piety, beauty, medicine,...",
		householdIncome: "Lorem",
		householdIncomeTips:
			"Client income includes: main income (salary, bonus, income from main business), supplementary income (house rental, real estate investment, securities,...)",
		retirementTips: "The average retirement age in Vietnam is 65 years old",
		retirementMonthlyExpenseTips:
			"This income is used to cover essential expenses such as travel, vacation, meals, health care and debt (if any).",
		retirementTimeTips: "The average retirement period is 20-30 years",
		logoutText: "Log out",
		areYouSureToLogOut: "Are you sure you want to log out?",
		cancelBtn: "Cancel",
		learnMore: "Learn More",
		loading: "LOADING...",
		getStarted: "GET STARTED",
		male: "Male",
		female: "Female",
		customerDetails: "Basic information",
		search: "Search",
		searchCustomer: "Please search your customer",
		createNewCustomer: "Create new customer",
		selectType: "Select type",
		pleaseSelect: "Please select",
		citizenId: "Citizen ID",
		phoneNumber: "Phone number",
		email: "Email",
		customerId: "Customer ID",
		passportId: "Passport",
		gcmLeadId: "CRM Lead ID",
		leadId: "Lead ID",
		createdDate: "Created Date",
		status: "Status",
		vietnameseToneRequired: "(Vietnamese tone required)",
		systemFailedToGetLeadInfo: "System can't retrieve CRM Lead information",
		leadPending: "Pending",
		leadProcessing: "Processing",
		leadCompleteProcessing: "Complete processing",
		gcmLeadIdNote: "Please select an existing CRM Lead ID or create a new customer profile",
		gcmLeadIdNotFoundNote: "Customer does not have any leads. Please create a new one.",
		gcmLeadIdFoundNote: "No CRM Lead found. Please create new CRM lead",
		customerInfoNote:
			"Customer information is not available in Techcombank systems. Please enter a new customer information below.",
		customerInfoWithNoLeadIdNote: "CRM lead ID is not available, please complete to fill out information below",
		gcmLeadIdCreatedBeforeFinish: "Please create CRM Lead ID before completing this conversation",
		gcmLeadIdCreatedBeforeReferral: "Please create CRM Lead ID first for referral",
		caseFromMVP15WithNoLead: "Please input customer information at step 1 before creating a lead.",
		caseFromMVP15WithNoLeadRefer: "Please input customer information at step 1 before referring a lead.",
		caseFromMVP15WithNoLeadPopup: "Please input customer information before mapping CRM lead.",
		caseFromMVP15WithLead:
			"For conversations that have been mapped lead and haven’t had customer identification information, you can only view, but not continue or edit the conversation. Please create a new conversation to advise customers.",
		caseWithNoAgentCode: "Please select agent code first for referral.",
		fullName: "Full name",
		idNumber: "ID number",
		dob: "Date of birth",
		maritalStatus: "Marital status",
		single: "Single",
		married: "Married",
		divorced: "Divorced",
		widowed: "Widowed",
		meetingMethod: "Meeting method",
		meetOnline: "Meet online",
		meetOffline: "Meet offline",
		goals: "Future Goals",
		questions: "Understand Goals",
		having: "Having",
		selectProduct: "Recommendation",
		deathCoverageBenefit: "Death benefit coverage",
		accidentalDeathBenefit: "Accident death coverage",
		medicalSupportBenefit: "Medical Cash plus",
		healthcareBenefit: "Healthcare coverage",
		criticalIllnessBenefit: "Critical Illness",
		criticalIllnessPlusBenefit: "Critical Illness plus",
		accidentDismembermentBenefit: "Accidental dismemberment coverage",
		permanentDisabilityBenefit: "Finance support with total permanent disability",
		financeSupportBenefit: "Financial support",
		titanProductDescription:
			"15-year saving and protection plan, with short premium paying tern and comprehensive coverage for medical and accident.",
		ul038ProductDescription:
			"Multi-purposes long term saving and protection plan with flexible policy term and payment plan. Coverage for critical illnesses and medical and healthcare benefit which will pay actual medical expense for Inpatient, Outpatient or Dental",
		unitLinkedProductDescription:
			"Effective investment plan with high flexibility. Coverage against critical illnesses,accidents and healthcare benefit which will pay actual medical expense for Inpatient, Outpatient or Dental.",
		eduProductDescription:
			"Education plan for your child with coverage against critical illnesses, medical, accident  and healthcare benefit which will pay actual medical expense for Inpatient, Outpatient or Dental.",
		titanProductName: "Titanium",
		titan_6yearsProductName: "Titan 1.0 (6 years)",
		titan_10yearsProductName: "Titan 1.0 (10 years)",
		titan_6ProductName: "Titanium (6 years)",
		titan_10ProductName: "Titanium (10 years)",
		superLink: "Legacy",
		superLinkHeadline: "Bảo hiểm kết hợp đầu tư LEGACY. Điểm tựa toàn diện – Cho vạn bước tiến",
		expectedProduct: "Expected Product",
		titanProductHeadline: "Tâm An Hiện Tại, Điểm Tựa Tương Lai",
		ul038ProductName: "UL2019 (Saving)",
		ul040ProductName: "UL2019 (Protect)",
		unitLinkedProductName: "Unit Linked",
		eduProductName: "Edu",
		medicalRider: "Medical Cash plus",
		healthcareRider: "Healthcare", // default healthcare rider is inpatient
		outpatientRider: "Healthcare coverage - Outpatient",
		yes: "Yes",
		no: "No",
		criticalIllnessRider: "Critical Illness Rider",
		criticalIllnessRiderPlus: "Critical Illness plus",
		accidentalDeathRider: "Death or permanent disability due to accident coverage",
		chooseOneOfOptions: "Please choose one of the following options",
		finishFNAConfirmationMessage: "Are you sure you want to finish this conversation?",
		finishFNACongratsMessage: "Congratulations! You just completed FNA.",
		totalBenefitValue: "Total benefit is equal to {amount}",
		benefitMaximumPay: "Pay up to {amount} for each day in hospital",
		benefitDoublePayment: "Double payment in intensive care unit",
		benefitPaymentRefund: "Refund {amount} if there is no medical claim made in previous year",
		accidentalDeathCoverage: "Pay from {min} to {max} if death due to accidents",
		accidentalDeathMaxCoverage: "Pay up to {max} if death due to accidents",
		generalAccidentalDeathCoverage: "Pay {amount} to all accidental death",
		publicDeathCoverage: "Pay {amount} to public transportation, public elevator, fire of public building death",
		planeCrashCoverage: "Pay {amount} to air crush death",
		healthcareCoverage:
			"Total Healthcare benefit includes: daily hospital expense, surgery and other expenses, outpatient expense (if any).",
		accidentalDismembermentCoverage: "Pay up to {amount} against accidental injuries, bone fractures, burns, organ damages",
		criticalIllnessProtection: "Cover up to {amount} against 134 critical illnesses in 3 stages",
		criticalIllnessProtectionForEdu: "Pay up to {amount} for Juvenile critical illnesses",
		financialSupport: "Yearly support of {amount} until end of payment term, also waive remaining premiums",
		setting: "Setting",
		dropOutReason: "Drop out reason",
		conversationResult: "Conversation result",
		gcmLeadInfoTableNote: "In progress CRM Lead",
		conversationInfoTableNote: 'In progress conversation and completed conversation with status "Customer is considering"',
		leadGCM: "CRM Lead ID",
		conversationId: "Conversation ID",
		customerName: "Customer Name",
		transactionDate: "Transaction Date",
		actions: "Actions",
		startFNA: "Start FNA",
		referLead: "Refer Lead",
		inProgress: "In Progress",
		completed: "Completed",
		canceled: "Canceled",
		paused: "Paused",
		dropoff: "Drop off",
		dropOffReason: "Drop off reason",
		buy: "Buy",
		wait: "Wait",
		notBuy: "Not buy",
		growWealth: "Grow Wealth",
		bigPurchase: "Big Purchase",
		buyWealthOrPurchaseTips: "Grow Wealth: saving/investment for the future.",
		buyWealthOrPurchaseTips1: "Big Purchase: buy big assets such as house, car.",
		interestRate: "Interest Rate",
		inflationRate: "Inflation Rate",
		educationInflation: "Education Inflation",
		productSelectionMessage: "To cover your shortfall of",
		productSelectionMessageSub: "we would like to suggest the following products:",
		totalBenefits: "Total benefits",
		productFeatures: "Product features",
		promotion: "Promotion",
		estPremium: "Est. Premium",
		customize: "Customize",
		year: "Year",
		month: "Month",
		conversationResultOnceFinished: "Conversation result",
		agentCode: "Deal Closer",
		searchAgentCode: "Select Sales Agent",
		sumAssured: "Sum Assured",
		expectedGoalMessage: "I want to plan for ",
		and: "and",
		event: "Christmas",
		descriptionProductFeaturesUnitLinked:
			"Enjoy the full investment results of Affiliate Funds (6 funds), along with attractive contract maintenance bonuses",
		descriptionProductFeaturesEdu: "Annual periodical guarantee with Dang Khoa Benefits 100,000,000 VND ",
		descriptionProductFeaturesTitan:
			"Enjoy the real interest rate from the Universal Life Fund, along with contract maintenance bonuses",
		descriptionProductFeaturesTitanSub: "The real interest rate is not lower than the committed interest rate",
		descriptionProductFeaturesUl038:
			"Enjoy the real interest rate from the Universal Life Fund, along with contract maintenance bonuses",
		descriptionProductFeaturesUl038Sub: "The real interest rate is not lower than the committed interest rate",
		role: "Role",
		branch: "Branch",
		username: "User name",
		emailAddress: "Email address",
		englishFirstName: "English First Name",
		englishLastName: "English Last Name",
		englishFullName: "English Full Name",
		changePassword: "Change password",
		update: "Update",
		currentPassword: "Current password",
		newPassword: "New password",
		confirmPassword: "Confirm password",
		updatedSuccessfully: "Updated successfully",
		failToUpdate: "Fail to update",
		initializePDF: "Initializing PDF document",
		interestToolTip:
			"Is the expected interest rate for the current investments. The interest rate shown is based on the average investment interest rate of 5 banks TCB, VCB, VTB, BIDV, AGRI on May 2021.",
		interestToolTipSub1: "2019: 5.8%",
		interestToolTipSub2: "2018: 6.4%",
		interestToolTipSub3: "2017: 7.25%",
		interestToolTipSub4: "Source: law library",
		inflationRateToolTip:
			"Is the growth rate of the price level of the economy. The rate displayed is based on the average inflation rate in recent years. For example:",
		inflationRateToolTipSub1: "2017: 4.2%",
		inflationRateToolTipSub2: "2016: 2.7%",
		inflationRateToolTipSub3: "2015: 0.6%",
		inflationRateToolTipSub4: "2014: 4.1%",
		inflationRateToolTipSub5: "2013: 6.6%",
		inflationRateToolTipSub6: "Source: International Bank",
		userSetting: "Setting",
		yesQes: "Yes",
		noQes: "No",
		tcbsEmailAddress: "TCB's email address",
		errorMessageSelection: "Please select at least 1 goal",
		shortfallChartLabel: "Shortfall",
		errorMessageRequired: "Please make sure all fields are filled in correctly",
		errorMessageForValidNumber: "Please enter a valid number between 0 and 99",
		unit: "Unit: Million Dong",
		goal: "Goal",
		shortfall: "Shortfall",
		customizeProductDescription: "You can adjust the following fields to customize your product",
		conversationResultOfNotInterested: "Customer is not interested",
		noDemand: "Customer has no need",
		anotherDemand: "Customer have need with other product",
		onConsider: "Customer is considering",
		referToTIS: "Design solution with expert",
		agreeToApply: "Transfer EPOS/AWS to design illustration",
		conclusionMessage:
			"Your conversation has been paused. You can search and continue this conversation in Inquiry module with conversation ID: {conversationId}",
		canceledConclusionMessage:
			"Your conversation has been canceled. You can search this conversation in Inquiry module with conversation ID: {conversationId}",
		summaryTitle: "Summary",
		SATitle: "Base protection",
		protectCurrentSituation: "We would like to suggest the following product to help you protect your plan:",
		finishMessage: "Conversation information is already sent to your email. You can find the conversation by accessing Inquiry.",
		finishMessageYesLeadNoReferral:
			"<div class='text-center'>Conversation information is already sent to your email. You can find the conversation by accessing Inquiry.</div>",
		finishMessageNoLeadNoReferral:
			"<div><div>Information is already sent to your email. Please create a CRM Lead and then map to this conversation by:</div><div>(1) Create lead on CRM then map on Inquiry screen</div><div>(2) Directly create lead on Inquiry screen</div></div>",
		finishMessageNoLeadYesReferral:
			"<div class='leading-relaxed'><div>Conversation information is already sent to your email. You can find this conversation on “Inquiry” screen with ID <span class='font-bold'>{conversationId}</span>.</div><div>The FNA information haven’t been transferred to MVL system, please create CRM lead before referring to MVL as the followings:</div><div>- Create lead on CRM then map on “Inquiry” screen</div><div>- Directly create lead on “Inquiry” screen</div></div>",
		finishMessageYesLeadYesReferral:
			"<div>Conversation information is already sent to your email. You can find the conversation by searching on \"Inquiry\" screen with ID <span class='font-bold'>{conversationId}</span>.</div>",
		finishMessageFromPopupYesLeadYesReferral:
			"<div>The conversation has been successfully mapped with the CRM lead ID. Please access Manulife's system to continue.</div>",
		finishMessageFromTab3Referral: "<div>Successful refer. Please manually upload FNA form to MVL systems.</div>",
		createLeadErrorRetryAgain:
			"<div><div>Creating lead CRM is failed, do you want to retry? In case you don't want to retry, you can continue with 1 of 2 options below:</div><div>(1) Create lead on CRM then map on Inquiry screen</div><div>(2) Directly create lead on Inquiry screen</div></div>",
		createLeadFromPopupErrorRetryAgain: "<div>Lead creation failed. Do you want to retry?</div>",
		referLeadBancaAndManulifeError:
			"<div><div>Information is already sent to your email.</div><div>The system can’t make referral to Manulife: <span class='text-c1-500'>[error code: {statusCode}] [error content: {statusDescription}]</span>.</div><div>Do you want to refer again?</div></div>",
		referLeadRetry1:
			"<div><div>The system can’t make referral to Manulife: <span class='text-c1-500'>[error code: {statusCode}] [error content: {statusDescription}]</span>.</div><div>Do you want to refer again?</div></div>",
		referLeadRetry2:
			"<div><div>The system can’t make referral to Manulife: <span class='text-c1-500'>[error code: {statusCode}] [error content: {statusDescription}]</span>.</div><div>Please refer this customer in CRM system.</div></div>",
		errorSytemGCM: "An error occurred while referencing the Manulife system (Internal Server Error)",
		finishedMessageSuccessLeadIdMapping: "<div>The conversation has been successfully mapped with CRM Lead ID.</div>",
		searchLeadFailed: "Searching lead is failed.",
		createLeadFailed: "Creating lead is failed.",
		updateLeadFailed: "Updating lead is failed.",
		referLeadFailed: "Referring lead is failed.",
		england: "England",
		america: "America",
		australia: "Australia",
		singapore: "Singapore",
		vietnam: "Vietnam",
		livingCost: "Living cost",
		highSchoolFee: "High School",
		collegeFee: "College",
		masterScienceFee: "Master Science",
		reference: "Source: Manulife - A survey of 2000 patients at the 3 largest cancer hospitals in Vietnam.",
		eduReference: "Source: EF Edu: https://www.ef.com.vn/pg/chi-phi-du-hoc/",
		referenceFee: "Annual education expenses at some countries",
		inpatientStaying: "Inpatient Staying",
		vinmec: "Vinmec",
		vietphap: "Viet Phap",
		hongngoc: "Hong Ngoc",
		hanhphuc: "Hanh Phuc",
		hospitalFeeOfficial: "The hospitals official service fee",
		referenceCostBedroom: "Cost of reference bed room",
		publicHospitalStandardRoom: "Public hospital Standard room",
		publicHospitalVoluntaryStandardRoom: "Public hospital Voluntary standard room",
		privateHospitalStandardRoom: "Private hospital Standard room",
		internationalHospitalStandardRoom: "International hospital Standard room",
		healthcareReferenceSource: "Reference source: Vinmec, Hong Ngoc, Thu Cuc, DHY Hospital",
		noRecommendedProducts:
			"There is no product that match with customer's need due to product conditions. Please guide customers to adjust their needs to introduce the best solution.",
		educationPlanError:
			"Sorry, we currently don't have any appropriate education insurance plans for your children. Our representatives will explain to you more. Thank you.",
		atLeast1RiderSelected: "This product requires at least one add-on rider.",
		save: "Save",
		warningProductsSuggestion:
			"We will suggest the products based on default values. You can come back Step 3 - 5 to input the information",
		estPremiumProduct: "Estimated premium",
		totalBenefitOfProduct: "Total benefit",
		needsAnalytics: "Needs Analytics",
		titanProductDetail: "Titan Product Detail",
		backToHome: "Back to Homepage",
		errorMinProtectionValueForTitan:
			"The Titan product detail can't be generated because Value of protection is less than {amount}.",
		descriptionProductFeaturesEdu2: "Guaranteed educational fund {value}đ",
		errorMessageIfDontHaveAsset: "Must choose an item",
		titanTooltip:
			"<div>NOTE: Customer needs to pass these questions to apply for Titanium product.</div>\n" +
			"<div>\n" +
			"    <div>1. I haven’t been declined any life insurance application or offered life insurance benefit with exclusions.</div>\n" +
			"    <div>\n" +
			"        2. I haven’t suffered from or am under investigation or treatment for:\n" +
			"        <div>\n" +
			"            <div>\n" +
			"                &emsp;• Heart, brain, and blood vessel disease (heart valve disorder, arrhythmia, coronary disease, myocardial ischemia, myocardial infarction, rheumatic heart disease, heart failure, blood vessel malformation, atherosclerosis,\n" +
			"                thrombophlebitis, Stroke, High blood pressure, Epilepsy)\n" +
			"            </div>\n" +
			"            <div>&emsp;• Kidney disease (retention of urine, edema, urinary incontinence, urinary tract stone/inflamation/ Malformation, renal failure)</div>\n" +
			"            <div>&emsp;• Cancer or abnormal growth</div>\n" +
			"            <div>&emsp;• Diabetes</div>\n" +
			"            <div>&emsp;• Lung disease (Respiratory failure, pneumonia, pulmonary tuberculosis, lung abscess, pleural effusion, pneumothorax, bronchiectasis, mention of chronic cor pulmonale)</div>\n" +
			"            <div>&emsp;• Liver disease (Hepatitis B or C, hepatomegaly, cirrhosis)</div>\n" +
			"            <div>&emsp;• AIDS, or been infected with the HIV virus</div>\n" +
			"        </div>\n" +
			"    </div>\n" +
			"    <div>\n" +
			"        3. During the past 3 years, I have not been recommended to take any medication continuously for more than 14 days (except oral contraceptives and fertility drugs) as prescription or have not been hospitalized continuously for more\n" +
			"        than 14 days.\n" +
			"    </div>\n" +
			"    <br />\n" +
			"    <div>\n" +
			"        I/We, the Insured and the Policyowner, confirm that I am not doing or not planning to do the following work / participate in the following activities: I undertake not to do the following: Heavy or dangerous work such as: working at\n" +
			"        a height of over 6 meters; work in mines; trench; work under water; diver; caught seafood; Mason; Workers sweeper; garbage collection; loading and unloading workers; criminal investigation police; special police; cascadeur; military\n" +
			"        (except office jobs); Mine / UXO work: the bomb / mine demolition police; bomb / mine demolition worker.\n" +
			"    </div>\n" +
			"</div>\n",
		quit: "Quit",
		productBenefit: "Product Benefit",
		totalBenefit_titan: "Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days (max allowed)",
		totalBenefit_ul038: "Total benefit is equal to 100% SA + 100% SA Accident",
		totalBenefit_ul038_MC: "Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days (max allowed)",
		totalBenefit_ul038_CI: "Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI UDR",
		totalBenefit_ul038_HC: "Total benefit is equal to 100% SA + 100% SA Accident + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_ul038_MC_CI: "Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 1000 hospital days",
		totalBenefit_ul038_CI_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_ul038_MC_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_ul038_MC_CI_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental} {outpatient} {dental}",

		totalBenefit_unitLinked: "",
		totalBenefit_unitLinked_ADD: "Total benefit is equal to 100% SA + 100% SA Accident",
		totalBenefit_unitLinked_MC: "",
		totalBenefit_unitLinked_CI: "",
		totalBenefit_unitLinked_ADD_MC: "Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days (max allowed)",
		totalBenefit_unitLinked_ADD_CI: "Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI",
		totalBenefit_unitLinked_ADD_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_unitLinked_ADD_MC_CI: "Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 1000 hospital days",
		totalBenefit_unitLinked_ADD_CI_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_unitLinked_ADD_MC_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_unitLinked_ADD_MC_CI_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_edu: "",
		totalBenefit_edu_ADD: "Total benefit is equal to 100% SA + 100% SA Accident",
		totalBenefit_edu_MC: "",
		totalBenefit_edu_ADD_MC: "Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days (max allowed)",
		totalBenefit_edu_ADD_HC: "Total benefit is equal to 100% SA + 100% SA Accident + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_edu_ADD_MC_CI: "Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI YRT + 1000 hospital days",
		totalBenefit_edu_ADD_CI_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI YRT + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_edu_ADD_MC_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_edu_ADD_MC_CI_HC:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI YRT + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental}",
		staffId: "Staff ID",
		branchCode: "Branch code",
		customerType: "Customer Type",
		determineCustomerInfo:
			"System is using default values for benefit illustration, do you want to go back to input information according to customer’s needs?",
		yesDetermineCustomerInfo: "Yes, go back",
		noDetermineCustomerInfo: "No, Continue",
		interestedBenefitsHealthCare: "Healthcare",
		maxText: "Maximum",
		silver: "Silver",
		titan: "Titan",
		gold: "Gold",
		platinum: "Platinum",
		diamond: "Diamond",
		premiumMinError: "Minimum Premium is {min}đ",
		hasGuaranteedInterestRate: "Has guaranteed interest rate.",
		descriptionProductFeaturesEduData1: "Yearly support of {value}đ until end of payment term, also waive remaining premiums",
		descriptionProductFeaturesEduData2: "Finance support with total permanent disability {value}đ",
		totalBenefit_titanium_2: "Total benefit is equal to 200% SA_titanium_2",
		totalBenefit_titanium_2_MC: "Total benefit is equal to 200% SA_titanium_2 + 1000 hospital days",
		totalBenefit_titanium_2_CI: "Total benefit is equal to 200% SA_titanium_2 + 125% SA_CI",
		totalBenefit_titanium_2_HC: "Total benefit is equal to 200% SA_titanium_2+ 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_titanium_2_MC_CI: "Total benefit is equal to 200% SA_Titan + 125%SA_CI + 1000 hospital days ",
		totalBenefit_titanium_2_CI_HC:
			"Total benefit is equal to 200% SA_titanium_2  + 125%SA_CI + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_titanium_2_MC_HC:
			"Total benefit is equal to 200% SA_titanium_2 + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental}",
		totalBenefit_titanium_2_MC_CI_HC:
			"Total benefit is equal to 200% SA_titanium_2 + 125%SA_CI + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental} ",
		payDueToAccident: "Pay % SA due to accident",
		maxPaidDay: "Max 1000 days per policy.",
		doublePayment: "Double payment in intensive care unit",
		titanium_2ProductDescription:
			"Outstanding and flexible investment product with the largest number of 12 funds in the market, providing high protection against the risks of death, injury and accidental death.",
		descriptionProductFeaturestitanium_2:
			"Receive all returns and risk associated with unit-linked funds (12 funds), together with loyalty bonuses.",
		titanium_2ProductName: "Legacy",
		noRecommendedProductsByAgeOfRBO:
			"<ul class='pl-4 pr-2 list-disc'>The age of the customer is not appropriate for the existing insurance products. Please refer to the age requirements of the insured below:<div class='pl-10'><li>Titan: 18 years to 50 years old</li><li>UL38: 18 to 65 years old</li><li>Legacy: 18 to 69 years old</li></div></ul>",
		noRecommendedProductsByPremium:
			"There is no product that matches with the customer's needs due to product conditions about annual premium. Please guide customers to adjust their needs to introduce the best solution.",
		createNewLeadId: "Create New Lead ID",
		income: "Income",
		iTCBLifeToEPOS: "Transfer from iTCBlife to Manulife",
		errorMessageLeadCannotAssociatedConversation: "Can’t map lead with current conversation result.",
		criticalIllness: "Critical Illness",
		criticalIllnessPlus: "Critical Illness Plus",
		criticalIllnessEarlyStage: "Early stage: 25% Sum Insured, max 4 times",
		criticalIllnessMiddleStage: "Middle stage: 50% Sum Insured, max 2 times",
		criticalIllnessLateStage: "Late stage: 100% Sum Insured, max 1 time",
		criticalIllnessByGender: "By gender: pay 25% Sum Insured more",
		specialMedicalBenefit: "Special Medical Benefit",
		specialMedicalBenefitPayCIPlus: "Pay up to 100% Sum Insured (Max 2 Billions VND) if: ",
		specialMedicalBenefitPayMCPlus: "Pay up to 500 times of Sum Insured (Max 2 Billions VND) if: ",
		specialMedicalBenefitPay1:
			"Inpatient treatment at ICU (Intensive Care Unit) for at least 12 days in a row AND have used an invasive life support device during treatment; OR",
		specialMedicalBenefitPay2: "Inpatient treatment for at least 21 days for surgery",
		deathBenefit: "Death Benefit",
		deathBenefitPayCIPlus: "Pay up to 100% Sum Insured upon death from the age of 70",
		deathBenefitPayMCPlus: "Pay up to 500 times of Sum Insured upon death after age of 70",
		medicalPlus: "Medical Plus",
		medicalCash: "Medical Cash",
		medicalCashContractYear1st: "1st year",
		medicalCashContractYear2nd: "2nd year",
		medicalCashContractYear3rd: "3rd year onward",
		medicalCashContractYearPercent: "{percent} Sum Insured/ day",
		first5days: "First 5 days/ inpatient period",
		from6thdayOnward: "From 6th day onward/ inpatient period",
		specialCareDepartment: "<div>Department: <span class='text-c4-50'>special care</span> 400% Sum Insured/ day</div>",
		idIssuedDate: "Issued Date",
		idIssuedPlace: "Issued Place",
		plsChooseIssuedPlace: "Please select issued place",
		address: "Address",
		province: "province",
		street: "Street",
		city: "City",
		district: "District",
		ward: "Ward",
		askedVietnamese: "Tiếng Việt có dấu",
		occupation: "Occupation",
		pleaseChooseOccupation: "Please select occupation",
		smokingHabit: "Smoking habit",
		neverSmoking: "Never smoke",
		smokeFewerOrUpto30cigas: "Smoke up to 30 cigarettes per day",
		smokeGreaterThan30cigas: "Smoke more than 30 cigarettes per day",
		pleaseSelectACity: "Please select a city",
		pleaseSelectADistrict: "Please select a district",
		pleaseSelectAWard: "Please select a ward",
		pleaseSelectSmokingHabit: "Please select Smoking habit",
		addressFields: "Choose Province/City/District",
		addressStreet: "Input House No & Street",
		addressTooltip: "If the address does not have house number and street name, please add the name of the neighborhood.",
		firstIssuedPlaceId12digits: "Cục CS ĐKQL Cư Trú Và DLQG Về Dân Cư",
		secondIssuedPlaceId12digits: "Cục CS Quản lý hành chính về trật tự xã hội",
		goToEpos: "Go to ePOS",
		referAwsToCreateIllustrationBtn: "Create Illustration",
		promotionName4: "ƯU ĐÃI NGẬP TRÀN, YÊU THƯƠNG VẸN TOÀN",
		promotionheadline4: "Thời gian áp dụng: 01/04/2022 đến hết ngày 30/06/2022",
		promotionContent4: "Thời gian áp dụng: 07/07/2022 đến hết ngày 30/09/2022",
		offerProductsAtProductStep: "Dựa trên thông tin bạn cung cấp, hệ thống đề xuất giải pháp phù hợp nhất với bạn.",
		agreeBtn: "Đồng ý",
		prenatal: "Prenatal",
		prenatalRider: "Prenatal",
		prenatalTooltip: "TBD",
		prenatalBenefit: "Prenatal",
		inpatientBenefit: "Inpatient Benefit",
		outpatientBenefit: "Outpatient Benefit",
		dentalBenefit: "Dental Benefit",
		inpatient: "Inpatient",
		outpatient: "Outpatient",
		dental: "Dental",
		insuranceBenefit: "Insurance benefit",
		insurancesPromo: "Insurance benefits",
		sumOutpatientBenefit: "Outpatient benefit",
		sumInpatientBenefit: "Inpatient benefit",
		sumDentalBenefit: "Dental benefit",
		millionPerYear: "million/ year",
		billionPerYear: "billion/ year",
		notApplying: "Not applying",
		basic: "Basic",
		advance: "Advance",
		premier: "Premier",
		elite: "Elite",
		suite: "Suite",
		referenceBenefits: "Proposed Package",
		eliteAndSuite: "Elite/Suite",
		prenatalTotalBenefits: "Prenatal Medical Cash: {prenatalAmount}/ngày.",
		prenatalMaxAmount: "Maximum {prenatalMaxAmount}",
		prenatalDeathCoverage: "Death Benefit: {prenatalDeathAmount} ",
		prenatalCongenitalDisorder: "Birth Defect Benefit: {prenatalDisorderAmount}",
		totalBenefit_ul038_PRENATAL: "Total benefit is equal to 100% SA + 100% SA Accident + 30 * SA_PRENATAL",
		totalBenefit_ul038_MC_PRENATAL:
			"Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days (max allowed) + 30 * SA_PRENATAL",
		totalBenefit_ul038_HC_PRENATAL:
			"Total benefit is equal to 100% SA + 100% SA Accident + 100% SA_Inpatient {outpatient} {dental} + 30 * SA_PRENATAL",
		totalBenefit_ul038_CI_PRENATAL: "Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI UDR + 30 * SA_PRENATAL",
		totalBenefit_ul038_MC_CI_PRENATAL:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 1000 hospital days + 30 * SA_PRENATAL",
		totalBenefit_ul038_MC_HC_PRENATAL:
			"Total benefit is equal to 100% SA + 100% SA Accident + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental} + 30 * SA_PRENATAL",
		totalBenefit_ul038_HC_CI_PRENATAL:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 100% SA_Inpatient {outpatient} {dental} + 30 * SA_PRENATAL",
		totalBenefit_ul038_MC_HC_CI_PRENATAL:
			"Total benefit is equal to 100% SA + 100% SA Accident + 125% SA_CI + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental} + 30 * SA_PRENATAL",
		totalBenefit_titanium_2_PRENATAL: "Total benefit is equal to 200% SA_titanium_2 + 30 * SA_PRENATAL",
		totalBenefit_titanium_2_MC_PRENATAL: "Total benefit is equal to 200% SA_titanium_2 + 1000 hospital days + 30 * SA_PRENATAL",
		totalBenefit_titanium_2_CI_PRENATAL: "Total benefit is equal to 200% SA_titanium_2 + 325 SA_CI + 30 * SA_PRENATAL",
		totalBenefit_titanium_2_HC_PRENATAL:
			"Total benefit is equal to 200% SA_titanium_2+ 100% SA_Inpatient {outpatient} {dental} + 30 * SA_PRENATAL",
		totalBenefit_titanium_2_MC_CI_PRENATAL:
			"Total benefit is equal to 200% SA_Titan + 125%SA_CI + 1000 hospital days  + 30 * SA_PRENATAL",
		totalBenefit_titanium_2_MC_HC_PRENATAL:
			"Total benefit is equal to 200% SA_titanium_2 + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental} + 30 * SA_PRENATAL",
		totalBenefit_titanium_2_HC_CI_PRENATAL:
			"Total benefit is equal to 200% SA_titanium_2  + 125%SA_CI + 100% SA_Inpatient {outpatient} {dental} + 30 * SA_PRENATAL",
		totalBenefit_titanium_2_MC_CI_HC_PRENATAL:
			"Total benefit is equal to 200% SA_titanium_2 + 125%SA_CI + 1000 hospital days + 100% SA_Inpatient {outpatient} {dental} + 30 * SA_PRENATAL",
		day: "day",
		totalBenefitHealthcareTT: "Total Healthcare benefit includes:",
		inpatientMaxAmountTT: "Maximum Inpatient benefit: {amount}/year",
		outpatientMaxAmountTT: "Maximum Outpatient benefit: {amount}/year",
		dentalMaxAmountTT: "Maximum Dental benefit: {amount}/year",
		accidentDismemberment: "Accidental dismemberment coverage",
		completedFNA: "FNA Completed",
		applicationInprogress: "Application In-progress",
		lateSubmission: "Late Submission",
		applicationSubmitted: "Application Submited",
		noRecommendedProductsOfRBO:
			"<ul class='pl-4 pr-2 list-disc'>The sum insured is not appropriate for the existing insurance products. Please refer to the sum insured of the insured as below:<div class='pl-10'><li>Titan: 100.000.000đ to 5.000.000.000đ</li><li>UL2019: from 100.000.000đ</li><li>Legacy: from 300.000.000đ</li></div></ul>",
		noRecommendedProductsOfDRBO:
			"<ul class='pl-4 pr-2 list-disc'>The sum insured is not appropriate for the existing insurance products. Please refer to the sum insured of the insured as below:<div class='pl-10'><li>Titan: 100.000.000đ to 5.000.000.000đ</li><li>UL38: from 100.000.000đ</li></div></ul>",
		noRecommendedProductsByAgeOfDRBO:
			"<ul class='pl-4 pr-2 list-disc'>The age of customer is not appropriate for the existing insurance products. Please refer to the age requirements of the insured as below:<div class='pl-10'><li>Titan: 18 years to 50 years old</li><li>UL38: 18 to 65 years old</li></div></ul>",
		noRecommendedProductsByBasePremiumDRBO:
			"<ul class='pl-4 pr-2 list-disc'>The expected premium is lower than minimum premium. Please refer to minimum premium as below:<div class='pl-10'><li>Titan & UL38: from 7.000.000đ</li></div></ul>",
		noRecommendedProductsByBasePremiumRBO:
			"<ul class='pl-4 pr-2 list-disc'>The expected premium is lower than minimum premium. Please refer to minimum premium as below:<div class='pl-10'><li>Titan & UL38: from 7.000.000đ</li><li>Legacy: from 10.000.000đ</li></div></ul>",
		medicalCashTooltipTitan:
			"<div>Hospitalization Benefit:</div>\n" +
			"    <div>\n" +
			"       - Pay 0.2% x {amount}/day (maximum VND 3,000,000/day).\n" +
			"    </div>\n" +
			"    <div>\n" +
			"       - Double pay for intensive care unit.\n" +
			"    </div>\n" +
			"    <br />\n" +
			"<div>Income Subsidy Benefits:</div>\n" +
			"    <div>\n" +
			"      - Pay an additional 20% of the Hospitalization Benefit for each day from the 6th day of hospital stay.\n" +
			"    </div>\n",
		generalAddDeathTooltipOfLegacy: "Pay {amount} to all accident death.",
		publicAddDeathTooltipOfLegacy: "Pay % SA due to accident",
		amountInpatient: "+ 100% SA_Inpatient",
		amountOutpatient: "+ 100% SA_Outpatient",
		amountDental: "+ 100% SA_Dental",
		chooseGoalsWarningMessageByDRBO: "(Please select maximum 2 priority goals)",
		monthly: "month",
		equivalent: "Equivalent",
		publicSchool: " Public school",
		privateSchool: "Private school",
		internationalSchool: "International school",
		schoolType: "Type of school",
		incomes: "Income - Expense",
		houseHoldIncomeMonth: " - Household income/month",
		houseHoldLivingExpenseRatioMonth: "% living expense/household income",
		customerIncomeHouseholdIncomeRatio: "3. % customer income/household income",
		loanOutstandingBalance: "4. Loan outstanding balance (if any)",

		// Product recommendation
		solutionsSuggestion: "Solution Recommendation",
		purposeOfProtection: "Protection goal",
		protectionValue: "Protection amount",
		protectionSubLabel: " (VND)",
		premiumPerYear: "APE",
		premiumPerIncome: "APE/ Income",
		appear: "Show",
		disappear: "Hide",

		// Rider name
		lifeProtection: "Base Protection",
		accidentProtection: "Accident",
		illnessProtection: "Critical Illness",
		medicalCostProtection: "Healthcare",
		medicalAllowanceProtection: "Medical cash",

		// Rider: Life protection
		incomeProtection1: "To protect customer income in",
		incomeProtection2: "years",
		monthlyExpenseProtection1: "To protect living expense in",
		monthlyExpenseProtection2: "years (% customer)",
		childrenEduProtection1: "To protect Education expense in",
		childrenEduProtection2: "years (% customer)",
		currentBalanceProtection1: "To protect Loan outstanding balance in",
		currentBalanceProtection2: "years",
		otherProtections1: "Other protection",
		otherProtections2: "years",
		selectAtLeast1ItemOnBaseProtection: "Please select at least 1 item on Base Protection",
		productToSelect: "Selected product",
		in: "in",

		// Rider: Accident protection
		reducingIncomeProtection1: "To protect income decreased by",
		reducingIncomeProtection2: "due to accident in",
		reducingIncomeProtection3: "years",
		reduceExpenseProtection1: "To protect living expense decreased by",
		reduceExpenseProtection2: "due to accident in",
		reduceExpenseProtection3: "years",
		childrenEduByAccidentProtection1: "To protect education expense decreased by",
		childrenEduByAccidentProtection2: "due to accident in",
		childrenEduByAccidentProtection3: "years",
		accidentExpenseProtection: "To protect 1 treatment due to accident",

		// Rider: Illness protection
		incomeProtectionByIllness1: "To protect income decreased by",
		incomeProtectionByIllness2: "due to critical illness in",
		incomeProtectionByIllness3: "years",
		medicalProtectionByIllness: "To protect 1 treatment due to criticall illness",

		// Rider: Medical cost protection
		publicHospitalProtection: "Medical expense in public hospital (limit/ year)",
		privateHospitalProtection: "Medical expense in private hospital (limit/ year)",
		internationalHospitalProtection: "Medical expense in international hospital (limit/ year)",
		inpatientBenefits: "In-patient",
		outpatientBenefits: "Out-patient",
		dentalBenefits: "Dental",

		// Rider: Medical allowance
		medicalCashAllowance: "Medical cash",
		prenatalAllowance: "Prenatal",
		incomeMonth: " - income/month",
		livingExpenseRatioMonth: "% living expense/income",
		summaryIncomeExpense: "Income - Expense Summary",
		summaryTotalHouseholdIncome: "1. Total household income",
		summaryCustomerIncome: "1.1 Customer income",
		summaryTotalHouseholdExpense: "2. Total household expense",
		summaryEssentialExpense: "2.1 Essential expense",
		summaryLivingExpense: "2.1.1 Living expense",
		summaryEducationExpense: "2.1.2 Education Expense",
		summaryLoanCost: "2.2 Loan Cost",
		summaryPrinciplePayable: "2.2.1 Principle payable",
		summaryInterestCost: "2.2.2 Interest cost",
		summaryReferenceInterestRate: "Reference interest rate",
		summaryOtherExpense: "2.3 Other expense",
		summaryNetSaving: "Net saving",
		summaryTotalIncome: "1. Total income",
		summaryTotalExpense: "2. Total expense",
		verifyInfo: "Verfification",
		infoConfirmation: "FNA confirmation",
		netSavingSingleLife: "Net saving",
		total: "Total",
		warningToCheckLifeProtectionBenefit: "Please select at least 1 item on Base Protection",
		milVND: "million VND",
		VND: "VND",
		customerConfirmation: "Verify customer information",
		analysisPdf: "FNA Confirmation",
		confirmToCreateNewLeadGCM: "Are you sure you want to create a new CRM lead?",

		// products validation
		baseAmountIsInvalid: "Invalid amount",
		baseProtectionErrorMessageLegacy:
			"The protection amount is lower than the minimum base protection of Legacy (300.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionErrorMessageUL038:
			"The protection amount is lower than the minimum base protection of UL2019 - Saving (100.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionLessThanErrorMessageTitan:
			"The protection amount is lower than the minimum base protection of Titan 1 (100.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionGreaterThanErrorMessageTitan:
			"The protection amount is higher than the maximum base protection of Titan 1 (5.000.000.000đ). Please adjust protection amount to meet the above condition.",
		basePremiumErrorMessageLegacy:
			"The expected base premium is lower than minimum premium of Legacy (10.000.000). Please adjust base protection amount to meet the above condition.",
		basePremiumErrorMessageUL038:
			"The expected base premium is lower than minimum premium of UL2019 (7.000.000đ).. Please adjust base protection amount to meet the above condition.",
		basePremiumErrorMessageTitan:
			"The expected base premium is lower than minimum premium of Titan 1 (7.000.000đ). Please adjust base protection amount to meet the above condition.",
		meetingType: "Meet method",
		invalidTotalBasePremium: "Invalid amount",
		totalBasePremiumErrorMessageUL038:
			"The expected base + rider premium is lower than minimum premium (10.000.000 VND). Please adjust base protection amount to meet the above condition.",
		totalBasePremiumErrorMessageLegacy:
			"The expected base + rider premium is lower than minimum premium (10.000.000 VND). Please adjust base protection amount to meet the above condition.",
		confirmToSelectNewLeadGCM: "Are you sure that you want to choose Lead {leadGCM}",
		directReferralPlaceholder: "Input employee ID of referrer",
		directReferral: "Direct referrer",

		// Benefit cancer protection
		benefitCancerProtection: "Late-stage cancer coverage",
		lateStageCancer: "To protect 1 treatment due to late-stage cancer",
		lateStageCancerProtectionAmountErrorMessage:
			"SA for Late-stage cancer is equal to main product SA, but not over { amountProtection } VND ",
		ul048ProductName: "Titanium 1.1",
		UL048lateStageCancerProtectionAmountErrorMessage: "Max SA= 5*Death SA and not more than 500.000.000 VND",
		baseProtectionLessThanErrorMessageUL048:
			"The protection amount is lower than the minimum base protection of Titanium 1.1 (100.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionGreaterThanErrorMessageUL048:
			"The protection amount is higher than the maximum base protection of Titanium 1.1 (1.000.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionErrorMessageUL040:
			"The protection amount is lower than the minimum base protection of UL2019 - Protect (100.000.000đ). Please adjust protection amount to meet the above condition.",
		basePremiumErrorMessageUL040:
			"The expected base premium is lower than minimum premium of UL2019 (7.000.000đ).. Please adjust base protection amount to meet the above condition.",
		totalBasePremiumErrorMessageUL040:
			"The expected base + rider premium is lower than minimum premium (7.000.000 VND). Please adjust base protection amount to meet the above condition.",
		baseProtectionADDRiderGreaterThanErrorMessageUL040:
			"The protection amount is higher than the maximum base protection of UL2019 - Protect (10.000.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionADDRiderLessThanErrorMessageUL040:
			"The protection amount is lower than the minimum base protection of UL2019 - Protect (100.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionADDRiderGreaterThanErrorMessageUL038:
			"The protection amount is higher than the maximum base protection of UL2019 - Saving (10.000.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionADDRiderLessThanErrorMessageUL038:
			"The protection amount is lower than the minimum base protection of UL2019 - Saving (100.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionADDRiderGreaterThanErrorMessageLegacy:
			"The protection amount is higher than the maximum base protection of Legacy (10.000.000.000đ). Please adjust protection amount to meet the above condition.",
		baseProtectionADDRiderLessThanErrorMessageLegacy:
			"The protection amount is lower than the minimum base protection of Legacy (100.000.000đ). Please adjust protection amount to meet the above condition.",
		basePremiumErrorMessageUL048:
			"The expected base premium is lower than minimum premium of Titanium 1.1 (7.000.000đ). Please adjust base protection amount to meet the above condition.",
		netSavingInvalidAmount: " Net savings must be equal or greater than {netSaving}/month (~{totalNetSaving}/year)",
		otherProtectionGrowAssetOption: "Grow asset",
		otherProtectionFinanceProtectionOption: "Finance Protection",
		otherProtectionEducationOption: "Education",
		otherProtectionRetirementOption: "Retirement",
		selectGoalWarningMessage: "Please select a goal",
		totalExpenseInvalidAmount: "Total expense must be equal or greater than {minExpense}/month (~{totalExpense}/year)",

		// Dynamic Amount of CI rider
		productNameFormattedOnValidationMessageUL038: " UL2019 - Saving",
		productNameFormattedOnValidationMessageUL040: "UL2019 - Protect",
		baseProtectionRiderLessThanErrorMessage:
			"The protection amount is lower than the minimum base protection of { productNameSelected } ({ minAmount }). Please adjust protection amount to meet the above condition.",
		baseProtectionRiderGreaterThanErrorMessage:
			"The protection amount is higher than the maximum base protection of { productNameSelected } ({ maxAmount }). Please adjust protection amount to meet the above condition.",
		// Base product
		targetAmountMax10BillionWarning: "Target amount should be greater than 0 and less than 10 bil VND",
		targetAmountMax50BillionWarning: "Target amount should be greater than 0 and not more than 50 bil VND",
	},
}
