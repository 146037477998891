export default {
	dashboard: {
		dashboard: "Báo cáo - Thống kê",
		clients: "Khách hàng - HK",
		policies: "Chính sách - HK",
		cases: "Các trường hợp - HK",
		typeToSearch: "Nhập để tìm",
		ho: "Toàn hàng",
		area: "Miền",
		region: "Vùng",
		branch: "Chi nhánh",
		lead: "Mảng",
		staff: "Từng nhân viên",
		indicator: "Chỉ số đo lường",
		staffType: "Nhóm chức danh",
		sumBy: "Chiều báo cáo",
		conversation: "Số lượng cuộc hội thoại",
		leadGCM: "Số lượng lead CRM",
		rates: "Tỉ lệ",
		conversationWithLeadGCM: "Hội thoại có mã CRM",
		APE: "Case size trung bình dự kiến",
		viewChart: "Xem biểu đồ",
		millionDong: "Triệu đồng",
		activeUser: "User hoạt động",
		total: "Tổng",
		week: "Tuần",
		month: "Tháng",
		groupBy: "Tổng hợp theo",
		weekTooltipMessage: "Tuần {week} bắt đầu từ {startDate} đến {endDate}",
		started: "Bắt đầu",
		completed: "Hoàn thành",
		completedRate: "Tỉ lệ hoàn thành (%)",
		activeLogin: "Active Login",
		activeSale: "Active Sale",
		activeLoginRate: "Active Login Rate (%)",
		activeSaleRate: "Active Sale Rate (%)",
		incompleted: "Chưa hoàn thành",
		sales: "Sales",

		// Contract page
		leadGCM_CRM: "Mã Lead GCM/CRM",
		contracts: "Hợp đồng",
		findCustomerIdentify: "Thông tin khách hàng",
		findCustomerIdentifyBy: "CMTND/CCCD/Hộ chiếu khách hàng",
		contractNo: "Số hợp đồng",
		contractStatus: "Trạng thái hợp đồng",
		findTheDate: "Tìm kiếm theo ngày",
		clear: "Xoá",
		pleaseSelectDate: "Hãy lựa chọn",
		submissionDate: "Ngày nộp hợp đồng",
		issuedDate: "Ngày phát hành hợp đồng",
		passFreeLookDateSearch: "Ngày hết hạn cân nhắc",
		paidToDate: "Ngày đến hạn đóng phí",
		customerDob: "Ngày sinh nhật khách hàng",
		pleaseInputSearch: "Vui lòng nhập tiêu chí tìm kiếm",
		warningMessageToValidateDate: "Vui lòng tìm kiếm trong khoảng 3 tháng.",
		resultNotMatch: "Không tìm thấy kết quả phù hợp",
		// Contract statuses
		payPremium: "Trả phí BH",
		freeCharge: "Miễn nộp phí",
		fullFee: "Trả phí toàn phần",
		extendedTermIns: "Extended Term Ins",
		decreaseInsurance: "Bảo hiểm giảm",
		waitingForBeingPaidInsuranceFee: "Chờ trả phí BH",
		supportToApplyFee: "Hỗ trợ nộp phí bảo hiểm",
		waitingToProvideAContract: "Chờ cấp HĐ",
		supportToApplyRiskyFee: "Hỗ trợ nộp phí bảo hiểm rủi ro",
		customerRefuseAContract: "KH từ chối hợp đồng",
		invalid: "Mất hiệu lực",
		converted: "Converted",
		death: "Tử vong",
		cancelMidway: "Huỷ ngang",
		renewal: "Đáo hạn",
		cancel: "Huỷ bỏ",
		criticalIllness: "Bệnh lý nghiêm trọng",
		refuseAContract: "Từ chối HĐ",
		contractHasBeenRefused: "Bị từ chối cấp HĐ",
		permanentDisability: "Thương tật toàn bộ & vĩnh viễn",
		cancelAContract: "Huỷ HĐ",

		// Contract list
		contractor: "Chủ hợp đồng",
		contractApplyDate: "Ngày nộp HĐ",
		contractIssueDate: "Ngày phát hành",
		annualPremium: "Phí bảo hiểm quy năm",

		// Contractor detail
		years: "năm",
		personalInfo: "Thông tin cá nhân",
		contract: "Hợp đồng",
		compensate: "Bồi thường",
		fullName: "Họ và tên",
		gender: "Giới tính",
		dob: "Ngày sinh",
		phoneNumber: "Số điện thoại",
		email: "Email",
		occupation: "Nghề nghiệp",
		address: "Địa chỉ",
		closeBtn: "Đóng",
		policyOwner: "Chủ hợp đồng",
		genderM: "Nam",
		genderF: "Nữ",
		genderG: "Không tiết lộ",

		// Contract info
		productName: "Sản phẩm",
		policyNo: "Số Hợp Đồng",
		APEByYear: "Tổng phí bảo hiểm quy năm (APE)",
		apeModalPremium: "Phí bảo hiểm quy năm",
		paymentMode: "Định kỳ đóng phí",
		modalPremium: "Phí định kỳ",
		topUpFee: "Tổng phí Top-up đã đóng",
		mainProduct: "Sản phẩm chính",
		insuredPerson: "Người được bảo hiểm",
		riderProducts: "Sản phẩm bổ trợ",
		insuranceFee: "Số tiền bảo vệ cơ bản",
		pendingReason: "Lý do pending",
		overDateOfConsideration: "Hợp đồng qua 21 ngày cân nhắc",
		policyEffectiveDate: "Ngày hiệu lực",
		paymentDueDate: "Ngày đến hạn đóng phí tiếp theo",
		contractConsultant: "Chuyên viên tư vấn Hợp đồng",
		customerCare: "Chuyên viên chăm sóc",
		accidentalDeathAndDisabilityInsurance: "Bảo hiểm tử vong và thương tật do tai nạn",
		supportingCriticalIllness: "Hỗ trợ Bệnh lý nghiêm trọng",
		effectiveDate: "Ngày hiệu lực",
		status: "Trạng thái",
		compensationCode: "Mã yêu cầu bồi thường",
		dateApplyAClaim: "Ngày yêu cầu bồi thường",
		claimStatus: "Tình trạng",
		claimReason: "Lý do từ chối bồi thường",
		compensationType: "Loại bồi thường",
		approvedOrDeclinedDate: "Ngày chấp thuận/từ chối",
		mainProductAPE: "Phí bảo hiểm quy năm (APE)",
		riderEffectiveDate: "Ngày hiệu lực",
		confirmationDate: "Ngày xác nhận nhận được hợp đồng",
		passFreeLookDate: "Ngày cuối cùng của 21 ngày cân nhắc",
		policyIssuanceDate: "Ngày phát hành hợp đồng",
		branchCodeOfSalesAgent: "Mã chi nhánh của Chuyên viên tư vấn Hợp đồng",
		policySubmitChannel: "Kênh nộp hợp đồng",
		policyTerminatedDate: "Ngày hợp đồng bị mất hiệu lực",
		coveragePremium: "Phí định kỳ",
		premiumTerm: "Thời gian đóng phí",
		planCode: "Mã sản phẩm",
		firstEventDateOfClaim: "Ngày xảy ra sự kiện bảo hiểm đầu tiên",
		firstSubmissionMethod: "Phương thức yêu cầu bồi thường đầu tiên",
		documentRequestedDate: "Ngày MVL yêu cầu bổ sung hồ sơ",
		documentReceivedDate: "Ngày khách hàng bổ sung hồ sơ gần nhất",
		notificationDate: "Ngày MVL thông báo kết quả bồi thường",

		// Quarter
		monthly: "Tháng",
		quarterly: "Quý",
		semiAnnually: "Nửa năm",
		annually: "Năm",
		freeLookIndicatorAnsY: "Chưa qua",
		freeLookIndicatorAnsN: "Đã qua",

		policyManagement: "Tra cứu Hợp đồng",
		updateServicingAgent: "Cập nhật thông tin đại lý",
		contractManagesByAgent: "Tư vấn viên",
		searchAgent: "Tìm tư vấn viên",
		agentCode: "Mã đại lý",
		updateAgentService: "Cập nhật tư vấn viên quản lý hợp đồng",
		alterTheServicingAgent: "Tư vấn viên thay thế quản lý HĐ",
		updatedStatus: "Trạng thái cập nhật",
		hasSucceeded: "Thành công",
		hasNotSucceeded: "Không thành công",
		wayOutBtn: "Thoát",
		pleaseSelectAnAgentServiceCode: "Vui lòng điền thông tin người thay thế phụ trách các Hợp đồng đã lựa chọn",
		agentCodeUpdatedSuccessfully: "Cập nhật thông tin thành công",
		updateAnAgentCodeHasFailed:
			"Cập nhật thông tin không thành công cho một/một số/toàn bộ hợp đồng đã chọn. Vui lòng kiểm tra và thử lại.",
		changeInfo: "Thay đổi thông tin",
		sendRequestToMVL: "Gửi yêu cầu cho Manulife",
		confirmBtn: "Xác nhận",
		okBtn: "OK",
		warningSelectItem: "Vui lòng chọn ít nhất một hợp đồng.",
		warningSelectAgentCode: "Vui lòng chọn Tư vấn viên thay thế quản lý HĐ cho tất cả các hợp đồng cần thay đổi.",
		warningCoincidentAgentCode:
			"Có ít nhất một hợp đồng có Tư vấn viên thay thế quản lý HĐ là Tư vấn viên thay thế quản lý HĐ hiện thời, vui lòng kiểm tra lại.",
		agentCodeHistoryUpdated: "Lịch sử cập nhật tư vấn viên quản lý hợp đồng",
		updatedDate: "Ngày cập nhật",
		timeUpdated: "Thời gian cập nhật",
		agentCodeOfAgentService: "Mã đại lý TVV",
		agentCodeOfAgentServiceChanged: "Mã đại lý TVV thay thế",
		invalidDateOver3Months: "Vui lòng tìm kiếm trong khoảng 3 tháng.",
		successfully: "Thành công",
		unsuccessfully: "Không thành công",
		changeDate: "Ngày cập nhật",
		errorLogs: "Thông tin lỗi",

		// TCB statuses
		tcbStatusPend: "Pending",
		tcbStatusIssued: "Phát hành trước 21 ngày",
		tcbStatusCode: "Phát hành sau 21 ngày",
		tcbStatusCancel: "Cancel",
		tcbStatusReject: "Reject",
		tcbStatusNTaken: "Not Taken",

		requestToChangeInfoTab: "Yêu cầu thay đổi thông tin",
		requestToChangeHistoryTab: "Lịch sử thay đổi thông tin",
		viewMore: "Xem thêm",
		staffName: "Tên nhân viên",
		agentServiceCode: "Mã đại lý",
		systemError: "Lỗi hệ thống",

		// claim status
		approved: "Approved",
		declined: "Declined",
		pending: "Pending",
		approvedWithPartialPayment: "Approved with partial payment",
		inactive: "Inactive",

		// claim types
		hospitalizationAllowance: "Trợ cấp nằm viện",
		healthGift: "Món quà sức khoẻ",
		injuryBenefit: "Quyền lợi thương tật",
		deathBenefit: "Quyền lợi tử vong",
		freeFeeBenefit: "Quyền lợi miễn nộp phí",
		othersBenefit: "Quyền lợi khác",

		// REASON PENDING
		notEnoughDocuments: "Chưa nhận đủ kết quả khám",
		consultToExpert: "Tham khảo ý kiến thẩm định cấp cao",
		alterProduct: "Đổi sản phẩm do quyết định thẩm định",
		missInfor: "Thiếu thông tin trên đơn",
		complementDocuments: "Bổ sung chứng từ y tế",
		lackOfFee: "Thiếu phí",
		medicalExamination: "Khám y khoa",
		acceptanceLetter: "Ký thư thỏa thuận",
		waitingDocumentsFromAnotherContract: "Chờ kết quả khám ở Hợp Đồng khác",
		underwriting: "Đang thẩm định",
		RCCWaiting: "Chờ kết quả RCC",

		searchAgentCode: "Tìm tư vấn viên",
		otherReason: "Những vấn đề khác",

		// Contract view
		contractSearchCriteria: "VUI LÒNG NHẬP ÍT NHẤT MỘT TIÊU CHÍ TÌM KIẾM DƯỚI ĐÂY",
		citizenId: "CMTND/CCCD/Hộ chiếu",

		staffRole: "Chức danh",
		all: "Tất cả",
		areasSelected: "Miền được chọn",
		regionsSelected: "Vùng được chọn",
		branchesSelected: "Chi nhánh được chọn",
		rolesSelected: "Chức danh được chọn",
		agentsSelected: "Tư vấn viên được chọn",
		noAgentsMatch: "Không tìm thấy tư vấn viên thỏa điều kiện",

		searchByAgent: "Tìm theo Tư vấn viên",
		searchByBranch: "Tìm theo chi nhánh",
	},
}
