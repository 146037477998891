<template>
	<!-- Servicing Agent List -->
	<JBox class="">
		<SearchAgentCodeOptionsOnField
			v-model:newAgentCodeOnModal="agentCodeSelected"
			@newValAgentCode="newValAgentCode"
			:isDisabledSearch="isDisabledSelectNewAgentCode"
			:notAllowAgentCodeOnOptions="notAllowAgentCodeOnOptions"
			:agentCodeOptionList="agentCodeOptionList"
			@update:agentCodeSelectedTableItem="onUpdateAgentCode"
		/>
	</JBox>
</template>
<script>
import { useI18n } from "vue-i18n"
import { ref, computed } from "vue"
import SearchAgentCodeOptionsOnField from "../contracts/SearchAgentCodeOptionsOnField"
export default {
	name: "TableCellSearchAgentCode",
	components: { SearchAgentCodeOptionsOnField },
	props: {
		v: {
			type: Object,
		},
		newAgentCodeOnModal: {
			type: String,
			default: "",
		},
		agentCodeOptionList: {
			type: Array,
			default: () => [],
		},
		notAllowAgentCodeOnOptions: {
			type: String,
			default: "",
		},
		isDisabledSelectNewAgentCode: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["update:newAgentCode", "newAgentCodeOnCTable"],
	setup(props, { emit }) {
		const { t } = useI18n()
		const isLoading = ref(false)
		const agentCodeSelected = computed({
			get: () => props?.newAgentCodeOnModal,
			set: (val) => {
				// to update the value to the parent view
				emit("newAgentCodeOnCTable", val)
			},
		})

		// Methods
		const newValAgentCode = (val) => {
			agentCodeSelected.value = val
		}
		const onUpdateAgentCode = (newVal) => {
			emit("update:newAgentCode", newVal)
		}
		return {
			t,
			isLoading,
			agentCodeSelected,
			newValAgentCode,

			// Methods
			onUpdateAgentCode,
		}
	},
}
</script>
