<template>
	<JFlex class="flex-col space-y-4 w-full justify-center">
		<JHeadline as="h3" variant="h3" class="font-bold leading-relaxed">{{
			$t("dashboard.pleaseSelectAnAgentServiceCode")
		}}</JHeadline>
		<JFlex class="w-full flex-col space-y-10 items-center">
			<!-- Field -->
			<JFlex class="w-full flex-row items-center justify-center space-x-6">
				<JHeadline as="h4" variant="h4" class="font-bold">{{ $t("dashboard.alterTheServicingAgent") }}</JHeadline>
				<!-- Servicing Agent List -->
				<JBox class="w-2/6">
					<SearchAgentCodeOptionsOnField
						v-model:newAgentCodeOnModal="agentCodeSelected"
						@newValAgentCode="newValAgentCode"
						:notAllowAgentCodeOnOptions="notAllowAgentCodeOnOptions"
						:agentCodeOptionList="agentCodeOptionList"
						@update:agentCodeSelectedTableItem="onUpdateAgentCode"
					/>
				</JBox>
			</JFlex>
			<!-- Function -->
			<JFlex class="justify-center space-x-2">
				<!-- Close -->
				<JBox>
					<JButton
						variant="default-tertiary-bg-gray"
						style="padding: 20px; font-size: 16px; height: 44px"
						class="ml-3"
						@click="closeModalChangeServicingAgent"
					>
						{{ $t("dashboard.wayOutBtn") }}
					</JButton>
				</JBox>
				<!-- Confirm -->
				<JBox>
					<JButton @click="confirmToSelectAgentCode" style="height: auto; border: 1px solid transparent">
						{{ $t("dashboard.confirmBtn") }}
					</JButton>
				</JBox>
			</JFlex>
		</JFlex>
	</JFlex>
</template>
<script setup>
import { computed } from "vue"
import SearchAgentCodeOptionsOnField from "../contracts/SearchAgentCodeOptionsOnField"
const props = defineProps({
	newAgentCodeOnModal: {
		type: String,
		default: "",
	},
	agentCodeOptionList: {
		type: Array,
		default: () => [],
	},
	notAllowAgentCodeOnOptions: {
		type: String,
		default: "",
	},
})
const emits = defineEmits(["update:newAgentCode", "newAgentCodeOnModal", "confirm:changeAgentCode", "close:modalUpdateAgent"])
const agentCodeSelected = computed({
	get: () => props?.newAgentCodeOnModal,
	set: (val) => {
		emits("newAgentCodeOnModal", val)
	},
})

// Methods
const newValAgentCode = (val) => {
	agentCodeSelected.value = val
}
const onUpdateAgentCode = (newVal) => {
	emits("update:newAgentCode", newVal)
}
const confirmToSelectAgentCode = () => {
	emits("confirm:changeAgentCode")
}
const closeModalChangeServicingAgent = () => {
	emits("close:modalUpdateAgent")
}
</script>
