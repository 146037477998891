<template>
	<JBox v-click-outside="hideShowMore" class="relative ml-auto mr-0">
		<JBox class="cursor-pointer" @click="toggleShowMore()">
			<JIcon class="text-c0-300" icon="DotsHorizontal" />
		</JBox>
		<JBox v-if="isShowMore" class="absolute z-10 right-0 py-2 bg-cWhite rounded-lg shadow" style="top: 2rem">
			<!-- Share-to-client option -->
			<!-- <JFlex class="items-start cursor-pointer select-none text-c0-500 px-4 py-2 hover:bg-c0-100">
				<JIcon class="mr-3" icon="Share" />
				<JBox>
					<JText class="font-medium whitespace-no-wrap">{{ $t("core.shareToClient") }}</JText>

					<JFlex class="items-center justify-between">
						<JText class="flex-grow-1 capitalize font-semibold">
							<span v-if="isSharable" class="text-c1-500"> {{ $t("core.yes") }} </span>
							<span v-else class="text-c1-300"> {{ $t("core.no") }} </span>
						</JText>
						<JBox class="flex-grow-0 -mt-2">
							<CFormInput componentName="JToggle" variant="primary-sm" v-model="isSharable" />
						</JBox>
					</JFlex>
				</JBox>
			</JFlex> -->

			<!-- Delete -->
			<JFlex class="items-center cursor-pointer select-none text-cError-500 px-4 py-2 hover:bg-c0-100" @click="clickDelete(item)">
				<JIcon class="mr-2" width="24" height="24" icon="Trash" />
				<JText class="font-medium">{{ $t("core.delete") }}</JText>
			</JFlex>
		</JBox>
	</JBox>
</template>
<script>
import { ref, watch } from "vue"
export default {
	emits: ["update:sharable", "delete"],

	setup(_, { emit }) {
		const isSharable = ref(false) // Make it referencing props

		const isShowMore = ref(false)

		function showMore() {
			isShowMore.value = true
		}

		function hideShowMore() {
			isShowMore.value = false
		}

		function toggleShowMore(option) {
			if (option) isShowMore.value = option
			else isShowMore.value = !isShowMore.value
		}

		function clickDelete() {
			emit("delete")
		}

		watch(isSharable, (val) => {
			emit("update:sharable", val)
		})

		return { isSharable, isShowMore, showMore, hideShowMore, toggleShowMore, clickDelete }
	},
}
</script>
