import { CONVERSATION_INQUIRY_ROLES_PERMISSION } from "@/tenants/use/pagesAccessingByRolesAndPermissions"
const ViewCaseList = () => import(/* webpackChunkName: "group-case" */ "../views/ViewCaseList.vue")

export default [
	{
		path: "/cases",
		component: ViewCaseList,
		name: "ViewCaseList",
		props: true,
		meta: { module: "case", component: "ViewCaseList", roles: CONVERSATION_INQUIRY_ROLES_PERMISSION },
	},
]
