<template>
	<JBox>
		<JText v-if="label" class="mb-2 font-semibold pointer-events-none"> {{ label }} </JText>
		<JFlex :style="{ flexDirection: inputStringCss }">
			<JFlex
				:class="`${variants[variant].item}
        ${compare(item, modelValue) ? variants[variant].selected : null}`"
				v-for="(item, idx) in options"
				:key="idx"
				@click="toggleRadio(item)"
			>
				<button class="w-auto" style="min-width: 1rem" :class="(disabled || item.disabled) && 'opacity-50 pointer-events-none'">
					<JBox v-if="!compare(item, modelValue)" class="rounded-full px-2 py-2 w-1 h-1 border border-c0-500 m-1"> </JBox>
					<JBox v-else>
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path
								fill-rule="evenodd"
								clip-rule="evenodd"
								d="M12 21.0001C14.3869 21.0001 16.6761 20.0518 18.364 18.364C20.0518 16.6762 21 14.387 21 12.0001C21 9.61311 20.0518 7.32393 18.364 5.6361C16.6761 3.94827 14.3869 3.00006 12 3.00006C9.61305 3.00006 7.32387 3.94827 5.63604 5.6361C3.94821 7.32393 3 9.61311 3 12.0001C3 14.387 3.94821 16.6762 5.63604 18.364C7.32387 20.0518 9.61305 21.0001 12 21.0001ZM16.1704 10.5454C16.3753 10.3333 16.4887 10.0491 16.4861 9.75411C16.4836 9.45914 16.3653 9.17697 16.1567 8.96839C15.9481 8.75981 15.6659 8.64149 15.371 8.63893C15.076 8.63636 14.7918 8.74976 14.5796 8.95469L10.875 12.6593L9.42037 11.2047C9.2082 10.9998 8.92402 10.8864 8.62905 10.8889C8.33408 10.8915 8.05191 11.0098 7.84333 11.2184C7.63475 11.427 7.51643 11.7091 7.51387 12.0041C7.5113 12.2991 7.6247 12.5833 7.82963 12.7954L10.0796 15.0454C10.2906 15.2563 10.5767 15.3748 10.875 15.3748C11.1733 15.3748 11.4594 15.2563 11.6704 15.0454L16.1704 10.5454Z"
								fill="#1476CC"
							/>
						</svg>
					</JBox>
				</button>
				<JText class="ml-4 whitespace-nowrap"> {{ item.name }} </JText>
			</JFlex>
		</JFlex>
		<!-- Error message -->
		<JBox v-if="isDirty && hasError" class="mt-2">
			<JBox>
				<!-- Goes through messages and if message exists in validation definition, it will display message based on if it's valid or not -->
				<JText v-for="key in Object.keys(messages)" :key="key" class="text-cError-500 text-md mt-1">
					{{ getMessage(getField, key) }}
				</JText>
			</JBox>
		</JBox>
	</JBox>
</template>

<script>
import get from "lodash/get"

export default {
	name: "CRadios",
	emits: ["update:modelValue"],
	props: {
		variant: {
			type: String,
			default: "default",
		},
		label: {
			type: String,
			required: false,
			default: "",
		},
		modelValue: {
			type: [Boolean, String],
			required: false,
			default: "",
		},
		options: {
			required: true,
			default: () => [],
			type: Array,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		/**
		 * @description Dot notation of the field we are supposed to process. This field is also used for id for label
		 * @example "clients[1].name"
		 */
		field: {
			type: null,
			default: null,
		},
		/**
		 * @description Instance of vue-lidate from parent
		 * Simply in parent, we refer to it as $v
		 */
		validator: {
			type: Object,
			default: () => {},
		},
		inputStringCss: {
			type: String,
		},
	},
	data() {
		return {
			messageKeys: [
				"required",
				"requiredIf",
				"email",
				"age",
				"hkid",
				"alpha",
				"alphaNum",
				"maxLength",
				"minLength",
				"sameAs",
				"noNumber",
				"phone",
				"errorMessagePhoneNumber",
			],
		}
	},
	computed: {
		variants() {
			return {
				default: {
					item: "items-center mr-4 rounded-lg cursor-pointer px-5 py-2 border border-c3-50 bg-cWhite",
					selected: "border border-c3-100",
				},
				primary: {
					item: "items-center mr-4 rounded-lg cursor-pointer px-8 h-10 border border-c3-50",
					selected: "border border-c3-100",
				},
				nonBorder: {
					item: "items-center mr-6 rounded-lg cursor-pointer h-10",
					selected: "border-transparent",
				},
			}
		},
		messages() {
			const result = {}
			this.messageKeys.forEach((key) => {
				result[key] = this.$t(`core.${key}`)
			})
			return result
		},
		// Validator
		getField() {
			return get(this.validator, this.field)
		},
		/**
		 * Check if the field was already touched by user
		 */
		isDirty() {
			if (Array.isArray(this.field)) {
				return get(this.validator, [...this.field, "$dirty"])
			}
			return get(this.validator, `${this.field}.$dirty`)
		},
		/**
		 * Check if the field has errors
		 */
		hasError() {
			if (Array.isArray(this.field)) {
				return get(this.validator, [...this.field, "$error"])
			}
			return get(this.validator, `${this.field}.$error`)
		},
	},
	methods: {
		compare(option, valueTwo) {
			if (option.value === valueTwo) return true
			return false
		},
		toggleRadio(option) {
			if (this.disabled || option.disabled) return

			this.$emit("update:modelValue", option.value)
		},
		getMessage(fieldObject = {}, key = "") {
			const found = Object.keys(fieldObject).find((item) => item === key)
			if (found) {
				return fieldObject[key].$invalid ? this.interpolate(this.messages[key], fieldObject, key) : null
			} else return ""
		},

		/**
		 * String interpolation when we replace {0} with a specific parameter
		 */
		interpolate(str, fieldObject, key) {
			// Go to $params[key] and get possible values that will be added to the str
			const values = fieldObject[key].$message

			// Some validators don't have values, in that case return str
			if (!values) return str

			// Find all {} to replace
			const reg = /({.*?})/gi
			const toReplace = str.match(reg)
			if (!toReplace) return str

			// Replace all {} with values
			let output = str

			// function to allow retrieving nested values
			function findValueFromKey(key, values) {
				return key
					.replace(/{|}/g, "")
					.split(".")
					.reduce((acc, cur) => acc[cur], values)
			}
			toReplace.forEach((item) => {
				// const valueKey = item.replace("{", "").replace("}", ""); // not needed with fn
				output = output.replace(item, findValueFromKey(item, values)) // replaces {count} with passed parameter e.g. 2
			})

			return output
		},
	},
}
</script>
