import { round } from "lodash"

export function sortProductsRecommendation(products = []) {
	if (products.length) {
		return products.sort((a, b) => a.ord - b.ord)
	}
}

export function formattedCustomerIncomeYearlyByRoundToThousand({ value }) {
	return (value * 12) / 1000
}

export function formattedToThousand(val) {
	return val / 1000
}

export function directReferralMaxLength({ value, requiredOptions, limit }) {
	if (requiredOptions && value) {
		const valueLength = value?.replace("-", "")?.length
		return valueLength >= limit
	}
	return true
}

export function directReferralFullNameLimitLength({ value, limit }) {
	if (value && value.length > limit) {
		return value.slice(0, limit)
	}
	return value
}

export function directReferralAgentCodeMaxLength({ value, requiredOptions, limit }) {
	if (requiredOptions && value) {
		return value.length <= limit
	}
	return true
}

export function mapOccupationClass({ occupationSelected, occupationList }) {
	const occupation = occupationList.find((occ) => occupationSelected === occ["Code (ePOS)"])

	if (occupation) {
		return occupation["Class (ePOS)"]
	} else {
		return ""
	}
}

export function formatFinancialAffordabilityAmount({ totalEstimatedAnnualPremium }) {
	if (totalEstimatedAnnualPremium) {
		const valueFormattedByThousand = totalEstimatedAnnualPremium / 1000
		return round(valueFormattedByThousand, 0)
	} else {
		return null
	}
}

export function formattedToThousandAndRoundDownAmount(val) {
	if (!val) return null
	return Math.floor(val / 1000)
}
