<template>
	<JFlex class="flex-row items-center space-x-4">
		<ul class="flex">
			<li v-for="(p, idx) in paginationList" :key="idx">
				<button :class="getPageLinkClass(p)" class="w-8 h-8" @click="handleClickPage(p)">
					<template v-if="p.type == 'icon'">
						<JIcon class="text-c1-800 w-6" :icon="p.icon" />
					</template>
					<template v-else-if="p.type == 'three-dots'"> ... </template>
					<template v-else>
						{{ p.value }}
					</template>
				</button>
			</li>
		</ul>
		<JFlex v-if="isInputSearch && numberOfPage > 1" class="relative flex-row justify-start">
			<CFormInput
				class="mb-2"
				style="width: 160px"
				componentName="JInputNumber"
				:placeholder="$t('core.goToPage')"
				v-model="isPageSearching"
			/>
			<JBox
				class="absolute cursor-pointer hover:bg-c0-500"
				style="padding: 6px; border: 2px solid rgb(188, 182, 182); border-radius: 0 9px 9px 0; top: 1px; right: 0px"
				@click="goToSearchPage(isPageSearching)"
			>
				<JIcon icon="Search" class="text-c0-500 hover:text-c2-50" />
			</JBox>
		</JFlex>
	</JFlex>
</template>

<script>
export default {
	name: "CPagination",
	emits: ["update:modelValue", "searchPage"],
	props: {
		isInputSearch: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: Number,
			required: true,
			default: 1,
		},
		totalItems: {
			type: Number,
			required: true,
			default: 10,
		},
		itemPerPage: {
			type: Number,
			required: true,
			default: 10,
		},
	},
	data() {
		return {
			visiblePage: 2,
			isPageSearching: null,
		}
	},
	computed: {
		paginationList() {
			const list = []
			if (this.numberOfPage > 1) {
				let haveMore = false
				// Add go to first and prev item
				list.push({
					type: "icon",
					action: "goToFirst",
					icon: "ChevronDoubleLeft",
				})
				list.push({
					type: "icon",
					action: "prevPage",
					icon: "ChevronLeft",
				})
				if (this.numberOfPage > 6) {
					for (let i = 1; i <= this.numberOfPage; i++) {
						if (i < 1 + this.visiblePage || i > this.numberOfPage - this.visiblePage) {
							list.push({
								action: "goToPage",
								value: i,
							})
						} else {
							if (i === this.modelValue) {
								if (i > 1 + this.visiblePage && !haveMore) {
									list.push({
										type: "three-dots",
									})
								}
								list.push({
									action: "goToPage",
									value: i,
								})
								if (i < this.numberOfPage - this.visiblePage) {
									list.push({
										type: "three-dots",
									})
								}
								haveMore = true
							}
							if (!haveMore) {
								list.push({
									type: "three-dots",
								})
								haveMore = true
							}
						}
					}
				} else {
					for (let i = 1; i <= this.numberOfPage; i++) {
						list.push({
							action: "goToPage",
							value: i,
						})
					}
				}

				// Add go to last and next item
				list.push({
					type: "icon",
					action: "nextPage",
					icon: "ChevronRight",
				})
				list.push({
					type: "icon",
					action: "goToLast",
					icon: "ChevronDoubleRight",
				})
			}

			return list
		},
		numberOfPage() {
			return Math.ceil(this.totalItems / this.itemPerPage)
		},
	},
	methods: {
		handleClickPage(p) {
			let nextValue = this.modelValue
			switch (p.action) {
				case "goToPage":
					nextValue = p.value
					break
				case "goToFirst":
					nextValue = 1
					break
				case "goToLast":
					nextValue = this.numberOfPage
					break
				case "prevPage":
					nextValue = this.modelValue - 1
					break
				case "nextPage":
					nextValue = this.modelValue + 1
					break
				default:
					break
			}

			// Boundary
			if (nextValue < 1) {
				nextValue = 1
			}
			if (nextValue > this.numberOfPage) {
				nextValue = this.numberOfPage
			}

			this.$emit("update:modelValue", nextValue)
		},
		getPageLinkClass(p) {
			return {
				"flex justify-center items-center focus:outline-none rounded hover:text-c": true,
				"px-2 py-1": false,
				"bg-c1-800 text-cWhite": p.value === this.modelValue,
			}
		},
		goToSearchPage(page) {
			if (!page || this.isPageSearching > this.numberOfPage) return
			this.$emit("searchPage", page)
		},
	},
}
</script>
