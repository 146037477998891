<template>
	<input
		type="range"
		class="range"
		:min="min"
		:max="max"
		:step="step"
		:value="modelValue"
		:disabled="disabled"
		@change="onChange"
		@input="onInput"
		@focus="$emit('focus')"
		@blur="$emit('blur')"
	/>
</template>

<script>
export default {
	name: "CSlider",
	props: {
		variant: {
			type: String,
			default: "default",
		},
		modelValue: {
			type: Number,
		},
		min: {
			type: Number,
		},
		max: {
			type: Number,
		},
		step: {
			type: Number,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		onInput(e) {
			if (!e?.target?.value) return 0
			const valAsNumber = Number(e.target.value)
			this.$emit("update:modelValue", valAsNumber)
		},
		/** IE don't understand onInput so we have to use onChange instead */
		onChange(e) {
			if (!e?.target?.value) return 0
			const valAsNumber = Number(e.target.value)
			this.$emit("update:modelValue", valAsNumber)
		},
	},
}
</script>
<style scoped>
.range {
	width: 400px;
	height: 24px;
	-webkit-appearance: none;
	background: #111;
	outline: none;
	border-radius: none;
	overflow: hidden;
	background: #ffffff;
	border: 1px solid #e9e9e9;
	border-radius: 100px;
}

.range::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 24px;
	height: 24px;
	border-radius: 50%;
	background: #ce0015;
	border: 6px solid #fce4e4;
	box-shadow: -407px 0 0 400px #ffcacf;
	cursor: pointer;
}
</style>
