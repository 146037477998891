/* eslint-disable no-tabs */
export const get = (objValue, key, type) => objValue?.find(byKey(key))?.value?.[type] ?? null
export const formatMoney = (money) => {
	if (isNaN(Math.round(money))) return "..."
	return (
		Math.round(money)
			.toString()
			.replace(/\B(?=(\d{3})+(?!\d))/g, ".") + "đ"
	)
}
export const formatMoneyWithoutCurrency = (money) => {
	if (isNaN(Math.round(money))) return "..."
	return Math.round(money)
		.toString()
		.replace(/\B(?=(\d{3})+(?!\d))/g, ".")
}
export const roundSAByRange = (sa) => {
	if (!sa) return
	const RANGE_TO_ROUND = 50000000
	if (sa % RANGE_TO_ROUND) {
		return sa - (sa % RANGE_TO_ROUND) + RANGE_TO_ROUND
	} else {
		return (sa / 1000000).toFixed() * 1000000
	}
}
export const formatToThousandAndRoundUpAmount = (amount) => {
	if (!amount) return
	return Math.round(amount / 1000)
}
export const formatAmountToThousand = (amount) => {
	if (!amount) return 0
	return Math.round(amount / 1000) * 1000
}
// ex: 50000000 => "50.00" (mil VND)
export const formatNumber = ({ number, monthCount = 1 }) => {
	if (number === null) {
		return "0.00"
	}
	return ((number / 1000000) * monthCount).toFixed(2).toLocaleString()
}
export const getAge = (dateString) => {
	// ex: convert "1982-06-16" to current age
	const today = new Date()
	const birthDate = new Date(dateString)

	let age = today.getFullYear() - birthDate.getFullYear()
	const m = today.getMonth() - birthDate.getMonth()

	if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
		age--
	}

	return age
}
export const byKey = (key) => (item) => item.key === key
export const byType = (type) => (item) => item.type === type

export const getHavingFact = ({
	incomeExpenseDetails,
	householdIncomeExpenseDetails,
	yourAccumulatedSaving,
	currentProtectionCoverage,
}) => {
	return {
		objectValue: [
			{
				key: "householdIncomeExpenseDetails", // is married
				value: {
					objectValue: [
						{
							key: "livingExpense",
							value: {
								numberValue: householdIncomeExpenseDetails?.livingExpense,
							},
						},
						{
							key: "incomeMonth",
							value: {
								numberValue: householdIncomeExpenseDetails?.incomeMonth,
							},
						},
						{
							key: "livingExpenseRatio",
							value: {
								numberValue: householdIncomeExpenseDetails?.livingExpenseRatio,
							},
						},
						{
							key: "customerIncomeHouseholdIncomeRatio",
							value: {
								numberValue: householdIncomeExpenseDetails?.customerIncomeHouseholdIncomeRatio,
							},
						},
						{
							key: "loanOutstandingBalance",
							value: {
								numberValue: householdIncomeExpenseDetails?.loanOutstandingBalance,
							},
						},
						{
							key: "summaryDetail",
							value: {
								objectValue: [
									{
										key: "totalIncome",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.totalIncome,
										},
									},
									{
										key: "customerIncome",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.customerIncome,
										},
									},
									{
										key: "totalExpense",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.totalExpense,
										},
									},
									{
										key: "essentialExpense",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.essentialExpense,
										},
									},
									{
										key: "educationExpense",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.educationExpense,
										},
									},
									{
										key: "principlePayable",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.principlePayable,
										},
									},
									{
										key: "interestCost",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.interestCost,
										},
									},
									{
										key: "referenceInterestRate",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.referenceInterestRate,
										},
									},
									{
										key: "otherExpense",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.otherExpense,
										},
									},
									{
										key: "netSaving",
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.netSaving,
										},
									},
									{
										key: "loanCost", // total amount by principlePayable + interestCost
										value: {
											numberValue: householdIncomeExpenseDetails?.summaryDetail?.loanCost,
										},
									},
								],
							},
						},
					],
				},
			},
			{
				key: "incomeExpenseDetails", // is single
				value: {
					objectValue: [
						{
							key: "livingExpense",
							value: {
								numberValue: incomeExpenseDetails?.livingExpense,
							},
						},
						{
							key: "incomeMonth",
							value: {
								numberValue: incomeExpenseDetails?.incomeMonth,
							},
						},
						{
							key: "livingExpenseRatio",
							value: {
								numberValue: incomeExpenseDetails?.livingExpenseRatio,
							},
						},
						{
							key: "loanOutstandingBalance",
							value: {
								numberValue: incomeExpenseDetails?.loanOutstandingBalance,
							},
						},
						{
							key: "summaryDetail",
							value: {
								objectValue: [
									{
										key: "totalIncome",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.totalIncome,
										},
									},
									{
										key: "totalExpense",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.totalExpense,
										},
									},
									{
										key: "essentialExpense",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.essentialExpense,
										},
									},
									{
										key: "educationExpense",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.educationExpense,
										},
									},
									{
										key: "principlePayable",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.principlePayable,
										},
									},
									{
										key: "interestCost",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.interestCost,
										},
									},
									{
										key: "referenceInterestRate",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.referenceInterestRate,
										},
									},
									{
										key: "otherExpense",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.otherExpense,
										},
									},
									{
										key: "netSaving",
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.netSaving,
										},
									},
									{
										key: "loanCost", // total amount by principlePayable + interestCost
										value: {
											numberValue: incomeExpenseDetails?.summaryDetail?.loanCost,
										},
									},
								],
							},
						},
					],
				},
			},
			{
				key: "yourAccumulatedSaving",
				value: {
					numberValue: yourAccumulatedSaving,
				},
			},
			{
				key: "currentProtectionCoverage",
				value: {
					numberValue: currentProtectionCoverage,
				},
			},
		],
	}
}

export const getHavingFromFact = (havingFromFact) => {
	const havingFromFactValue = havingFromFact?.value?.objectValue

	// obj key fact: 1
	const householdIncomeExpenseFromFact = get(havingFromFactValue, "householdIncomeExpenseDetails", "objectValue")
	const householdIncomeExpenseDetailsFromFact = get(householdIncomeExpenseFromFact, "summaryDetail", "objectValue")
	const houseHoldDetailsFact = {
		totalIncome: get(householdIncomeExpenseDetailsFromFact, "totalIncome", "numberValue"),
		customerIncome: get(householdIncomeExpenseDetailsFromFact, "customerIncome", "numberValue"),
		totalExpense: get(householdIncomeExpenseDetailsFromFact, "totalExpense", "numberValue"),
		essentialExpense: get(householdIncomeExpenseDetailsFromFact, "essentialExpense", "numberValue"),
		educationExpense: get(householdIncomeExpenseDetailsFromFact, "educationExpense", "numberValue"),
		principlePayable: get(householdIncomeExpenseDetailsFromFact, "principlePayable", "numberValue"),
		interestCost: get(householdIncomeExpenseDetailsFromFact, "interestCost", "numberValue"),
		referenceInterestRate: get(householdIncomeExpenseDetailsFromFact, "referenceInterestRate", "numberValue"),
		otherExpense: get(householdIncomeExpenseDetailsFromFact, "otherExpense", "numberValue"),
		netSaving: get(householdIncomeExpenseDetailsFromFact, "netSaving", "numberValue"),
		loanCost: get(householdIncomeExpenseDetailsFromFact, "loanCost", "numberValue"),
	}
	const householdIncomeExpenseDetails = {
		livingExpense: get(householdIncomeExpenseFromFact, "livingExpense", "numberValue"),
		incomeMonth: get(householdIncomeExpenseFromFact, "incomeMonth", "numberValue"),
		livingExpenseRatio: get(householdIncomeExpenseFromFact, "livingExpenseRatio", "numberValue"),
		customerIncomeHouseholdIncomeRatio: get(householdIncomeExpenseFromFact, "customerIncomeHouseholdIncomeRatio", "numberValue"),
		loanOutstandingBalance: get(householdIncomeExpenseFromFact, "loanOutstandingBalance", "numberValue"),
		summaryDetail: houseHoldDetailsFact,
	}
	// obj key fact: 2
	const incomeExpenseFromFact = get(havingFromFactValue, "incomeExpenseDetails", "objectValue")
	const incomeExpenseDetailsFromFact = get(incomeExpenseFromFact, "summaryDetail", "objectValue")
	const incomeExpenseDetailFacts = {
		totalIncome: get(incomeExpenseDetailsFromFact, "totalIncome", "numberValue"),
		totalExpense: get(incomeExpenseDetailsFromFact, "totalExpense", "numberValue"),
		essentialExpense: get(incomeExpenseDetailsFromFact, "essentialExpense", "numberValue"),
		educationExpense: get(incomeExpenseDetailsFromFact, "educationExpense", "numberValue"),
		principlePayable: get(incomeExpenseDetailsFromFact, "principlePayable", "numberValue"),
		interestCost: get(incomeExpenseDetailsFromFact, "interestCost", "numberValue"),
		referenceInterestRate: get(incomeExpenseDetailsFromFact, "referenceInterestRate", "numberValue"),
		otherExpense: get(incomeExpenseDetailsFromFact, "otherExpense", "numberValue"),
		netSaving: get(incomeExpenseDetailsFromFact, "netSaving", "numberValue"),
		loanCost: get(incomeExpenseDetailsFromFact, "loanCost", "numberValue"),
	}
	const incomeExpenseDetails = {
		livingExpense: get(incomeExpenseFromFact, "livingExpense", "numberValue"),
		incomeMonth: get(incomeExpenseFromFact, "incomeMonth", "numberValue"),
		livingExpenseRatio: get(incomeExpenseFromFact, "livingExpenseRatio", "numberValue"),
		loanOutstandingBalance: get(incomeExpenseFromFact, "loanOutstandingBalance", "numberValue"),
		summaryDetail: incomeExpenseDetailFacts,
	}

	return {
		householdIncomeExpenseDetails,
		incomeExpenseDetails,
		yourAccumulatedSaving: get(havingFromFactValue, "yourAccumulatedSaving", "numberValue"), // obj key fact 3
		currentProtectionCoverage: get(havingFromFactValue, "currentProtectionCoverage", "numberValue"), // obj key fact 4
	}
}

export const downloadFile = (name, blob) => {
	const link = document.createElement("a")
	link.href = window.URL.createObjectURL(blob)
	link.download = name
	document.body.appendChild(link)
	setTimeout(() => {
		link.click()
		document.body.removeChild(link)
	}, 200)
}

export const detectBrowser = () => {
	const { userAgent } = navigator

	if (userAgent.includes("Firefox/")) {
		return "firefox"
	} else if (userAgent.includes("Edg/")) {
		return "edge"
	} else if (userAgent.includes("Chrome/")) {
		return "chrome"
	} else if (userAgent.includes("Safari/")) {
		return "safari"
	}
}

export const openFileInNewTab = ({ name, blob, eposUrl }) => {
	const link = document.createElement("a")
	link.href = blob ? window.URL.createObjectURL(blob) : eposUrl
	link.target = "_blank"
	link.download = name
	document.body.appendChild(link)
	setTimeout(() => {
		link.click()
		document.body.removeChild(link)
	}, 200)
}

export const roundFaceAmount = (fa) => {
	if (fa < 1000000) {
		// fa = 580000
		// 500000 ~ 600000
		return (fa / 100000).toFixed(1) * 100000
	}
	return (fa / 1000000).toFixed(1) * 1000000
}

export const roundSA = (sa) => {
	return (sa / 1000000).toFixed() * 1000000
}

// eslint-disable-next-line no-unused-vars
const exampleFact = {
	type: "rboAnswers",
	value: {
		objectValue: [
			{
				key: "having",
				value: {
					objectValue: [
						{ key: "yourAccumulatedSaving", value: { numberValue: 5000 } },
						{ key: "currentProtectionCoverage", value: { numberValue: 0 } },
						// {
						// 	key: "interestedBenefits",
						// 	value: {
						// 		arrayValue: [{ stringValue: "accidentAndDisability" }, { stringValue: "CI" }, { stringValue: "medicalCare" }],
						// 	},
						// },
					],
				},
			},
		],
	},
}

export function randomString(length) {
	let result = ""
	const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
	const charactersLength = characters.length
	for (let i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength))
	}
	return result
}

// "sài gòn" => "sai gon"
export function removeVietnameseTone(str) {
	return str
		.normalize("NFD")
		.replace(/[\u0300-\u036f]/g, "")
		.replace(/đ/g, "d")
		.replace(/Đ/g, "D")
}

export function mapMedicalOptions({ list, t }) {
	const currency = "đ"
	return list?.map((item) => {
		const amount = formatMoneyWithoutCurrency(item.value)
		return {
			...item,
			name: `${amount}${currency}/${t("rbo.day")}`,
		}
	})
}

export const findBenefitName = ({ listData = [], benefitName = "" }) => {
	return listData?.find(({ name }) => name === benefitName)
}
export const findRiderName = ({ listData = [], name = "" }) => {
	return listData?.find(({ riderName }) => riderName === name)
}
export function calculateTotalBenefitByAmountOfBaseProduct(list) {
	if (!list?.length) return 0
	const benefitIsSelected = list?.filter((r) => r?.isCheckedMainBenefit)
	return benefitIsSelected?.reduce((sum, curVal) => (sum += curVal?.amountProtection), 0)
}
export function mRidersOfProduct({ mainBenefitList = [] }) {
	const riders = mainBenefitList
		?.filter(({ name }) => name !== "lifeProtection")
		?.reduce((list, curBenefit) => {
			/**
			 * expected: ["MC", "HC", "ADD-plus", "ADD", "PRENATAL"]
			 */
			const optionSelected = curBenefit?.dataOptions
				?.filter(({ isCheckedMainBenefit }) => isCheckedMainBenefit)
				?.map((i) => i && i?.riderName)
			return list?.concat(optionSelected)
		}, [])
	return riders
}

export function checkMaxLengthOnAmountInput(val) {
	if (val) {
		const valToString = val.toString()
		return valToString.length < 17
	} else return true
}

export function checkExistingErrorOnErrorList({ valueIsValid, errorsListDefault, errorType }) {
	const hasValueError = errorsListDefault?.includes(errorType)

	if (!hasValueError) {
		if (!valueIsValid) {
			errorsListDefault.push(errorType)
		}
	} else if (valueIsValid) {
		const findGoalValueError = errorsListDefault?.indexOf(errorType)
		errorsListDefault.splice(findGoalValueError, 1)
	}
}

export function getHavingFactsFromCaseAtConversationInquiryPage({ answersFacts }) {
	const answersFact = answersFacts?.find((fact) => fact?.type === "rboAnswers")?.value?.objectValue
	return getHavingFromFact(answersFact?.find(byKey("having")))
}
