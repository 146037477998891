export default {
	cms: {
		contentConfiguration: "Quản lý nội dung",
		createNew: "Tạo mới",
		submit: "Lưu",
		cancel: "Huỷ",
		tenant: "Tenant",
		id: "ID",
		contentType: "Loại nội dung",
		language: "Ngôn ngữ",
		status: "Trạng thái",
		title: "Tiêu đề",
		contentTitle: "Nội dung tiêu đề",
		subTitile: "Phụ đề",
		contentSubtitle: "Nội dung phụ đề",
		thumbnail: "Thumbnail",
		thumbnailRec1: "Độ phân giải được đề xuất: 512x512 với kích thước tệp nhỏ hơn 5MB",
		thumbnailRec2: "Giữ các yếu tố hình ảnh làm trung tâm;",
		thumbnailRec3: "Chấp nhận định dạng tệp: PNG, JPG",
		description: "Mô tả",
		wordCount: "Ký tự",
		uploadFiles: "Đăng tải file",
		newContent: "Nội dung mới",
		home: "Trang chủ",
		product: "Sản phẩm",
		campaign: "Chương trình ưu đãi",
		english: "Tiếng Anh",
		vn: "Tiếng Việt",
		active: "Hoạt động",
		inactive: "Ngừng hoạt động",
		upload: "Đăng tải",
		url: "URL",
		updateCreateSuccess: "Create success",
		schemaManagement: "Quản lý nội dung",
		lastUpdated: "Lần cuối cập nhật",
		updateSuccess: "Cập nhật thành công!",
		delete: "Xoá",
		replace: "Thay đổi",
		action: "Actions",
		download: "Tải xuống",
		actionText: "Nội dung button",
		edit: "Chỉnh sửa",
		detailContent: "Nội dung chi tiết",
		editContent: "Chỉnh sửa nội dung",
		confirmToDeleteCMS: "Bạn có chắc bạn muốn xoá?",
		yesBtn: "Có",
		noBtn: "Không",
		"en-US": "Tiếng Anh",
		save: "Lưu",
		schemaType: "Nội dung",
		warningMessageToFillForm: "Vui lòng điền đầy đủ thông tin.",
		editItemSuccess: "Chỉnh sửa nội dung thành công",
		deleteSuccess: "Xoá nội dung thành công",
		createSuccess: "Tạo nội dung thành công",
		notAllowToDeleteItem: "Không thể xóa nội dung đang hoạt động",
		errorMessageUploadThumbnailFile: "Chỉ hỗ trợ định dạng file: JPG, JPEG, PNG",
		errorMessageUploadActionFile: "Chỉ hỗ trợ định dạng file: pdf, docs, excel, csv",
		noContentAvailableMessage: "Không có sẵn nội dung. Vui lòng liên hệ với quản trị viên.",
		guidance: "Hướng dẫn sử dụng",
		aboutUs: "Về chúng tôi",
		redirectLink: "Chuyển hướng đến trang",
		addUrl: "Thêm đường dẫn liên kết",
		copySuccess: "Đã sao chép nội dung thành công",
		typeToSearch: "Nhập để tìm",
		selectType: "Chọn loại nội dung",
		search: "Tìm kiếm",
		clear: "Xoá",
		createdAt: "Ngày thực hiện",
		all: "Tất cả",
	},
}
