<template>
	<JBox>
		<!-- Filter Options -->
		<AgentCodeHistoryListFilter
			class="relative"
			v-model:contractManagesByAgent="contractManagesByAgent"
			v-model:contractNo="contractNo"
			v-model:agentCodeUpdateStatus="agentCodeUpdateStatus"
			v-model:updatedDateAt="updatedDateAt"
			:validator="v"
			@update:agentCode="handleUpdateAgentCode"
			@update:contractNo="handleUpdateContractNo"
			@filter:applyFilter="handleApplyFilter"
		/>
		<JBox>
			<!-- Table -->
			<CTable
				:list="agentCodeHistoryUpdatedList"
				:isLoading="isLoadingFetchAllCases"
				:customErrorMessage="contractListAreEmpty"
				customClassErrorMessage="text-c1-400"
				class="mt-6 lg:mt-10"
				:isDisabledScrollable="true"
				:isApplyingBoxShadow="false"
			>
				<template #header>
					<CTableHeaderRow>
						<CTableHeaderCell v-for="header in agentServiceHistoryListTableHeader" :key="header.name">
							{{ header.label }}
						</CTableHeaderCell>
					</CTableHeaderRow>
				</template>
				<template v-slot="{ item, first, last }">
					<CTableRow>
						<!-- Contract No -->
						<CTableCell class="">
							<JText class="">{{ formatData(item.policyNo) }}</JText>
						</CTableCell>
						<!-- Contractor -->
						<CTableCell class="">{{ formatData(item.policyName) }}</CTableCell>
						<!-- Agent Service Name -->
						<CTableCell class="">{{ formatData(item.currentServicingAgentName) }}</CTableCell>
						<!-- Agent Service Code -->
						<CTableCell class="">{{ formatData(item.currentServicingAgentCode) }}</CTableCell>
						<!-- Agent Service Name has updated -->
						<CTableCell class="">{{ formatData(item.newServicingAgentName) }}</CTableCell>
						<!-- Agent Service Code has updated -->
						<CTableCell class="">{{ formatData(item.newServicingAgentCode) }}</CTableCell>
						<!-- Date has updated -->
						<CTableCell class="">{{ formatData(item.createdAt, "DD/MM/YYYY HH:mm:ss") }}</CTableCell>

						<!-- Status -->
						<CTableCell :class="{ 'rounded-tr-lg': first, 'rounded-br-lg': last }">
							<JText class="">{{ formatData(customStatus(item)) }}</JText>
						</CTableCell>

						<!-- Error case -->
						<CTableCell :isTruncate="false" :class="[{ 'rounded-tr-lg': first, 'rounded-br-lg': last }, 'max-w-sm']">
							<JFlex class="text-left space-x-2">
								<JText>{{ item?.errorCode ? `${item?.errorCode}: ${item?.message}` : "-" }}</JText>
							</JFlex>
						</CTableCell>
					</CTableRow>
				</template>
			</CTable>
			<!-- Pagination -->
			<JFlex
				v-if="totalItems && !isLoadingFetchAllCases"
				class="mt-8 flex-col px-4 md:pr-20 sm:mt-12 sm:flex-row"
				variant="basic"
			>
				<CPaginationStatus class="mb-4 sm:mb-0" :currentPage="currentPage" :totalCount="totalItems" :limit="limit" />
				<CPagination
					v-model:modelValue="currentPage"
					:totalItems="totalItems"
					:itemPerPage="limit"
					isInputSearch
					@update:modelValue="onHandleSearchPage($event)"
					@searchPage="onHandleSearchPage($event)"
				/>
			</JFlex>
		</JBox>
	</JBox>
</template>

<script>
import { formatData } from "@/modules/core/composables"
import { ref, computed } from "vue"
import { useAgentCodeHistoryUpdated } from "@/modules/dashboard/use/useAgentCodeHistoryUpdated"
import AgentCodeHistoryListFilter from "@/modules/dashboard/components/contracts/AgentCodeHistoryListFilter.vue"
import { useStore } from "vuex"
import { useI18n } from "vue-i18n"
import { useContractInfoDetail } from "../../use/useContractInfoDetail"
import debounce from "lodash/debounce"
export default {
	name: "AgentServiceHistoryUpdated",
	components: { AgentCodeHistoryListFilter },
	setup() {
		const store = useStore()
		const { t } = useI18n()

		const isOpenModalContractDetail = ref(false)
		// const warningUserToInputSearchFields = ref(false)
		const {
			v,
			isLoadingFetchAllCases,
			totalItems,
			// contract list
			agentCodeHistoryUpdatedList,
			contractListAreEmpty,
			currentPage,
			limit,
			// data (reactive)
			contractManagesByAgent,
			contractNo,
			agentCodeUpdateStatus,
			updatedDateAt,
			// methods
			readAllContractData,
		} = useAgentCodeHistoryUpdated()
		const { agentServiceHistoryListTableHeader, isManagerPosition } = useContractInfoDetail()

		// computed
		const dateTimeFormat = computed(() => store.state.dateTimeFormat)

		// METHODS
		const customStatus = (item) => {
			return item?.status === "Success" ? t("dashboard.hasSucceeded") : t("dashboard.hasNotSucceeded")
		}
		const handleUpdateAgentCode = (event) => {
			contractManagesByAgent.value = event
		}
		const handleUpdateContractNo = () => {
			v.value.contractNo.$touch()
		}
		const handleApplyFilter = debounce(async () => {
			currentPage.value = 1
			await readAllContractData()
		}, 400)

		const handleClearFilter = () => {
			// Reset all fields
			contractListAreEmpty.value = null
			contractManagesByAgent.value = ""
			contractNo.value = ""
			agentCodeUpdateStatus.value = ""
			updatedDateAt.value = {
				type: "between", // use crunchDate from composables file to search createAt field
				value: [],
				quickOptionValue: "",
			}
		}

		const onHandleSearchPage = debounce(async (pageToGo) => {
			currentPage.value = pageToGo
			await readAllContractData()
		}, 400)

		return {
			t,
			v,
			isLoadingFetchAllCases,
			isOpenModalContractDetail,
			agentServiceHistoryListTableHeader,
			agentCodeHistoryUpdatedList,
			contractListAreEmpty,
			isManagerPosition,
			// computed
			dateTimeFormat,

			// methods
			formatData,
			handleApplyFilter,
			handleClearFilter,
			handleUpdateAgentCode,
			handleUpdateContractNo,
			onHandleSearchPage,
			customStatus,
			// data
			totalItems,
			currentPage,
			limit,
			contractManagesByAgent,
			contractNo,
			agentCodeUpdateStatus,
			updatedDateAt,
		}
	},
}
</script>
