import { isString, merge } from "lodash"
import { token } from "@formkit/utils"

import { extend } from "@/forms/plugins/helpers.js"

function getAddedEventName(id) {
	return `${id}:added`
}

function isListOrGroup(type) {
	return ["list", "group"].includes(type)
}

function listPlugin(inputNode) {
	const { type } = inputNode.props

	if (isListOrGroup(type)) {
		inputNode.addProps(["_groups"])
	}

	inputNode.on("created", ({ payload: node }) => {
		const { type } = node.props

		if (type === "list") {
			node.props._groups = []

			inputNode.context = merge(inputNode.context, {
				getGroup(idOrIndex) {
					if (isString(idOrIndex)) {
						return inputNode.children.find((child) => child.props.id === idOrIndex)
					}

					return inputNode.at(["$self", idOrIndex])
				},
				getGroupChild(idOrIndex, id) {
					const group = this.getGroup(idOrIndex)

					return group && group.at(["$self", id])
				},
			})

			extend(node, {
				async addGroup() {
					const id = token()

					node.props._groups = [...node.props._groups, id]

					return new Promise((resolve) => {
						const receipt = node.on(getAddedEventName(id), ({ payload }) => {
							node.off(receipt)

							payload.props.isAdded = true

							resolve(payload)
						})
					})
				},
				removeGroup(idOrNode) {
					const id = isString(idOrNode) ? idOrNode : idOrNode.id || idOrNode.props.id
					const groups = [...node.props._groups]
					const index = groups.indexOf(id)

					if (index !== -1) {
						const [removed] = groups.splice(index, 1)

						node._value.splice(index, 1)

						node.props._groups = groups

						node.input(node._value)

						return removed
					}
				},
			})
		} else if (type === "group") {
			const { parent } = node

			if (parent) {
				const { children } = parent

				children.forEach((child) => {
					if (!child.props.isAdded) {
						parent.emit(getAddedEventName(child.props.id), child)
					}
				})
			}
		}
	})
}

export default listPlugin
