<template>
	<JFlex class="h-full flex-col justify-between" :class="menuDirection === 'rtl' ? 'text-right' : ''">
		<JBox>
			<JBox class="relative">
				<JBox
					v-for="item in menuItems"
					:id="targetIdItems(item)"
					:key="item.component"
					class="mt-2"
					@click="handleClickMenuItem(item)"
				>
					<JBox
						class="inline-flex w-full lg:flex items-center px-4 lg:px-6 py-3 rounded-lg justify-start cursor-pointer select-none"
						:class="[
							{ 'bg-c1-600': item.href === currentRouteName },
							{ 'hover:bg-c0-100': item.href !== currentRouteName },
							{ 'flex-row-reverse': menuDirection === 'rtl' },
						]"
					>
						<JText
							class="font-medium tracking-wider leading-tight text-lg truncate"
							:class="[{ 'text-cWhite': item.href === currentRouteName }, { 'text-cBlack': item.href !== currentRouteName }]"
						>
							{{ item.text }}
						</JText>
					</JBox>
					<JBox
						v-for="subItem in item.subs"
						:id="targetIdSubItems(subItem)"
						:key="subItem.component"
						class="mt-2"
						@click.stop="handleClickMenuItem(subItem)"
					>
						<JBox
							class="inline-flex w-full lg:flex items-center ml-4 px-4 lg:px-6 py-3 rounded-lg justify-start cursor-pointer select-none"
							:class="[
								{ 'bg-c1-600': subItem.href === currentRouteName },
								{ 'hover:bg-c0-100': item.href !== currentRouteName },
								{ 'flex-row-reverse': menuDirection === 'rtl' },
							]"
						>
							<JText
								class="font-medium tracking-wider leading-tight text-lg truncate"
								:class="[
									{ 'text-cWhite': subItem.href === currentRouteName },
									{ 'text-cBlack': subItem.href !== currentRouteName },
								]"
							>
								{{ t(subItem.text) }}
							</JText>
						</JBox>
					</JBox>
				</JBox>
			</JBox>
			<JBox class="mb-0 mt-auto">
				<JBox class="mt-2" @click="handleClickLogout()">
					<JBox
						class="inline-flex md:flex items-center px-4 lg:px-6 py-3 rounded-lg justify-end md:justify-start cursor-pointer select-none"
						:class="[{ 'flex-row-reverse': menuDirection === 'rtl' }]"
					>
						<JIcon
							icon="Logout"
							width="1.25rem"
							height="1.25rem"
							class="flex-shrink-0 flex-grow-0 text-cWhite"
							:class="[{ 'mr-4': menuDirection === 'ltr' }, { 'ml-4': menuDirection === 'rtl' }]"
						/>
						<JText class="font-medium tracking-wider leading-tight text-lg truncate text-cWhite">
							{{ $t("core.logout") }}
						</JText>
					</JBox>
				</JBox>
			</JBox>
		</JBox>
		<JBox class="mb-24 block sm:hidden"> <slot></slot> </JBox>
	</JFlex>
</template>

<script>
import { useI18n } from "vue-i18n"
import { useRouter, useRoute } from "vue-router"
import { computed } from "vue"
import { useStore } from "vuex"
import useSourceOfLoginUser from "@/modules/core/use/useUserSource.js"
export default {
	name: "CSidebarMenu",
	emits: ["click-menu-item"],
	props: {
		/**
		 * @description: Menu direction
		 * Supported: "ltr", "rtl"
		 */
		menuDirection: {
			type: String,
			default: "ltr",
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()
		const router = useRouter()
		const route = useRoute()
		const store = useStore()
		const { userPosition } = useSourceOfLoginUser()
		const defaultModules = [
			{
				module: "case",
				icon: "ClipboardList",
				text: "core.cases",
				href: "ViewCaseList",
			},
			{
				module: "user",
				icon: "Users",
				text: "core.users",
				href: "ViewUserList",
			},
			{
				module: "organization",
				icon: "OfficeBuilding",
				text: "core.organizations",
				href: "ViewOrganizationList",
			},
		]

		const tenantModules = computed(() => {
			const role = store?.getters?.user_role
			if (!role) {
				return []
			} else {
				return store?.state?.tenantSettings?.modules?.filter((m) => {
					return (
						m?.roles === null ||
						(m?.customViewByUserSource
							? m?.roles?.includes(role) && m?.customViewByUserSource?.includes(userPosition)
							: m?.roles?.includes(role))
					)
				})
			}
		})
		const componentName = computed(() => route?.name)
		const menuItems = computed(() => {
			const formattedOutput = (array) =>
				array?.map((item) => ({
					...item,
					text: t(item.text),
					subs: item.subs || [],
				}))
			return tenantModules.value === "default"
				? formattedOutput(defaultModules)
				: Array.isArray(tenantModules.value)
				? formattedOutput(tenantModules.value)
				: formattedOutput(defaultModules)
		})
		const targetIdItems = (item) => {
			return item
				? item.href === "ViewHome"
					? "Manhinhchinh_manhinhchinh"
					: item.href === "ViewAbout"
					? "Manhinhchinh_vechungtoi"
					: item.href === "ViewCaseList"
					? "Manhinhchinh_truyvan"
					: item.href === "ViewContact"
					? "Manhinhchinh_lienhe"
					: ""
				: ""
		}
		const targetIdSubItems = (item) => {
			return item
				? item.href === "ViewProduct"
					? "Manhinhchinh_sanpham"
					: item.href === "ViewGuidance"
					? "Manhinhchinh_huongdansudung"
					: item.href === "ViewPromotion"
					? "Manhinhchinh_chuongtrinhuudai"
					: ""
				: ""
		}
		const currentRouteName = computed(() => route?.meta?.component)

		const handleClickMenuItem = (item) => {
			if (!item.href) return
			if (router.currentRoute.value.name === "ViewFNA" || router.currentRoute.value.name === "ViewRBO") {
				alert(t("core.warningMessageOfNavigation"))
			} else {
				router.push({
					name: item.href,
				})
				emit("click-menu-item")
			}
		}

		const handleClickLogout = () => {
			store.dispatch("logout")
		}

		return {
			currentRouteName,
			componentName,
			menuItems,
			targetIdItems,
			targetIdSubItems,
			handleClickMenuItem,
			handleClickLogout,
			t,
		}
	},
}
</script>
