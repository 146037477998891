<template>
	<JFlex class="flex-col flex-start w-full pt-10 md:p-10 space-y-10">
		<!-- Filter Options -->
		<JFlex class="flex-col w-full space-y-6">
			<JHeadline as="h4" variant="h4" class="uppercase font-bold">{{ $t("dashboard.updateAgentService") }}</JHeadline>
			<UpdateAgentInfoOnContract
				v-model:servicingAgentCode="servicingAgentCode"
				:agentCodeOptions="agentCodeOptionsOnFilter"
				:isLoadingSearchAgentCode="isLoadingSearchAgentCode"
				:onHandleSearchAgentCode="onHandleSearchAgentCode"
				@update:agentCode="handleUpdateAgentCodeOnSearching"
				@update:contractNo="handleUpdateContractNo"
				v-model:contractNo="contractNo"
				:validator="v"
				@filter:apply="handleClickApplyFilter"
			/>
		</JFlex>

		<!-- Contract filtered -->
		<!-- Table -->
		<CTable
			:list="contractList"
			:isLoading="isLoadingFetchAllCases"
			class="pl-6"
			customClassTableContainer=" h-fit pb-28"
			:isDisabledScrollable="true"
			style="min-height: 120px"
			:customErrorMessage="contractListAreEmpty"
			customClassErrorMessage="text-c1-400"
			:isApplyingBoxShadow="false"
		>
			<template #header>
				<CTableHeaderRow>
					<!-- Multi CheckBox -->
					<CTableHeaderCell class="w-12">
						<JBox
							v-if="!isSelectedAllPolicies"
							class="flex justify-center items-center w-4 h-4 rounded-sm border border-c0-800 cursor-pointer focus:outline-none"
							@click="onToggleAllPolicies"
						>
						</JBox>
						<JBox
							v-else
							class="flex justify-center items-center w-4 h-4 rounded-sm border border-c0-800 cursor-pointer focus:outline-none"
							@click="resetToggleSelectAll"
						>
							<CIcon icon="Minimize" />
						</JBox>
					</CTableHeaderCell>
					<!-- Header -->
					<CTableHeaderCell v-for="header in updateAgentServiceTableHeaderList" :key="header.name" style="max-width: 290px">
						{{ $t(`${header.label}`) }}
					</CTableHeaderCell>
				</CTableHeaderRow>
			</template>
			<template v-slot="{ item, last, first }">
				<CTableRow class="cursor-default">
					<!-- Single checkbox item -->
					<CTableCell :class="{ 'rounded-tl-lg': first, 'rounded-bl-lg': last }" class="w-12">
						<JCheckbox
							:disabled="isDisabledCheckbox(item)"
							variant="custom-checkbox"
							id="item.id"
							:modelValue="item?.isChecked"
							@click="onToggleAPolicy(item)"
						/>
					</CTableCell>
					<!--1- Contract no -->
					<CTableCell>
						<JText :class="{ 'text-c0-400': item?.status === 'Success' }">{{ formatData(item.policyNumber) }}</JText>
					</CTableCell>
					<!--2- contractor name -->
					<CTableCell>
						<JText>
							{{ formatData(item.poName) }}
						</JText>
					</CTableCell>
					<!--3- agent service name -->
					<CTableCell>
						<JText>
							{{ formatData(item.servcingAgentName) }}
						</JText>
					</CTableCell>
					<!--4- agent code -->
					<CTableCell>
						<JText>
							{{ formatData(item.servcingAgentCode) }}
						</JText>
					</CTableCell>
					<!--5- alter an agent service -->
					<CTableCell :isTruncate="false" style="max-width: fit-content">
						<TableCellSearchAgentCode
							:isDisabledSelectNewAgentCode="!item?.isChecked || isDisabledCheckbox(item)"
							v-model:newAgentCodeOnModal="item.newAgentCode"
							@update:newAgentCode="updateNewAgentCodeOnCTable($event, item)"
							:agentCodeOptionList="defaultAgentCodeOptions"
							:notAllowAgentCodeOnOptions="notAllowAgentCodeOnOptions"
						/>
					</CTableCell>
					<!--6- updated a status after updating-->
					<CTableCell>
						<JText>{{ formatData(item?.caseStatus) }}</JText>
					</CTableCell>

					<!--7- Error case -->
					<CTableCell :isTruncate="false" :class="[{ 'rounded-tr-lg': first, 'rounded-br-lg': last }, 'max-w-sm']">
						<JFlex class="text-left space-x-2">
							<JText>{{ item?.errorCode ? `${item?.errorCode}: ${item?.message}` : "-" }}</JText>
						</JFlex>
					</CTableCell>
				</CTableRow>
			</template>
			<template #footer>
				<!-- Pagination -->
				<JFlex
					v-if="totalItems && !isLoadingFetchAllCases"
					class="mt-8 flex-col px-4 md:pr-20 sm:mt-12 sm:flex-row"
					variant="basic"
				>
					<CPaginationStatus class="mb-4 sm:mb-0" :currentPage="currentPage" :totalCount="totalItems" :limit="limit" />
					<CPagination
						v-model:modelValue="currentPage"
						:totalItems="totalItems"
						:itemPerPage="limit"
						isInputSearch
						@update:modelValue="handleSearchPage($event)"
						@searchPage="handleSearchPage($event)"
					/>
				</JFlex>
			</template>
		</CTable>

		<!-- Functions -->
		<JFlex v-if="contractList.length > 0 && !isLoadingFetchAllCases" class="flex-row justify-center space-x-6">
			<!-- change information -->
			<JFlex class="justify-center pointer-events">
				<JButton
					:disabled="notAllowToChangeNewAgentCodeModal"
					@click="onToggleModalUpdateAgentCode"
					variant="default-tertiary-bg-gray"
					:class="{ 'cursor-not-allowed': notAllowToChangeNewAgentCodeModal }"
					style="padding-left: 10px; padding-right: 10px; height: auto"
					>{{ $t("dashboard.changeInfo") }}</JButton
				>
			</JFlex>
			<!-- Send data to MVL -->
			<JFlex class="justify-center pointer-events" style="width: 260px">
				<JButton
					:variant="notAllowToSendRequest ? 'disabled' : 'primary'"
					:disabled="notAllowToSendRequest"
					@click="handleSendRequestToMVL"
					class="w-full justify-center"
					:class="{ 'cursor-not-allowed': notAllowToSendRequest }"
					style="padding-left: 10px; padding-right: 10px; height: auto; width: 100%"
				>
					<JSpinner v-if="isSendingRequest" type="dots"></JSpinner>
					<span v-else>{{ $t("dashboard.sendRequestToMVL") }}</span>
				</JButton>
			</JFlex>
		</JFlex>
	</JFlex>

	<!-- Modal to confirm changing an agent service -->
	<teleport to="#layer4">
		<JModalSimple zIndex="50" :isVisible="isShowModalAfterUpdatingAgentCode" variant="custom-modal-4xl-border-gray">
			<!-- Close btn -->
			<JFlex class="justify-end w-full mb-2" style="">
				<JButton @click="onToggleModalUpdateAgentCode" iconPrefix="X" variant="tertiary"></JButton>
			</JFlex>
			<ModalToUpdateAgentCode
				v-if="showModalUpdateAgent"
				v-model:newAgentCodeOnModal="newAgentCodeOnModal"
				@update:newAgentCode="onUpdateNewAgentCodeOnModal"
				@confirm:changeAgentCode="confirmNewAgentCodeOnModal"
				@close:modalUpdateAgent="onToggleModalUpdateAgentCode"
				:notAllowAgentCodeOnOptions="notAllowAgentCodeOnOptions"
				:agentCodeOptionList="defaultAgentCodeOptions"
			/>
			<JFlex v-if="alertMessageAfterUpdatedAgentCode" class="flex-col space-y-6 items-center mt-4">
				<JHeadline v-html="alertMessageAfterUpdatingAnAgentCode" class="text-xl leading-medium font-bold" />
				<JFlex class="justify-center pointer-events">
					<JButton
						@click="isShowModalAfterUpdatingAgentCode = false"
						variant="primary"
						style="padding-left: 10px; padding-right: 10px"
						>{{ $t("dashboard.okBtn") }}</JButton
					>
				</JFlex>
			</JFlex>
		</JModalSimple>
	</teleport>

	<!-- Modal Case's error info -->
	<teleport to="#layer4">
		<JModalSimple zIndex="50" :isVisible="isShowModalErrorInfo" variant="center-md">
			<ModalCasesErrorInfo :errorItemId="errorsLogItem" @closeModal="isShowModalErrorInfo = false" />
		</JModalSimple>
	</teleport>
</template>

<script>
import { useI18n } from "vue-i18n"
import { ref, computed, watch } from "vue"
import CIcon from "@/modules/core/components/CIcon"
import ModalToUpdateAgentCode from "../contractModals/ModalToUpdateAgentCode.vue"
import { useContractInfoDetail } from "@/modules/dashboard/use/useContractInfoDetail"
import { useToUpdateAgentInfo } from "../../use/useToUpdateAgentInfo"
import UpdateAgentInfoOnContract from "./UpdateAgentInfoOnContract.vue"
import { debounce, uniqBy } from "lodash"
import useApis from "../../use/useApis"
import ModalCasesErrorInfo from "../contractModals/ModalCasesErrorInfo.vue"
import { formatData, parseJSON } from "@/modules/core/composables"
import { splitAgentCodeValue } from "@/helpers/splitAgentCodeValue.js"
import TableCellSearchAgentCode from "./TableCellSearchAgentCode.vue"
export default {
	name: "UpdateAgentService",
	components: { CIcon, ModalToUpdateAgentCode, UpdateAgentInfoOnContract, ModalCasesErrorInfo, TableCellSearchAgentCode },
	setup() {
		const { t } = useI18n()
		const {
			v,
			contractListAreEmpty,
			contractList,
			servicingAgentCode,
			contractNo,
			isLoadingFetchAllCases,
			currentPage,
			totalItems,
			limit,
			agentCodeOptionsOnFilter,
			isLoadingSearchAgentCode,

			// methods
			readAllContractData,
			sendRequestToMVLToUpdateAgentCode,
			onHandleSearchAgentCode,
			renderSystemError,
			renderWarningToSelectPolicy,
		} = useToUpdateAgentInfo()

		const {
			updateAgentServiceTableHeaderList,
			userLocatedByBranchOrArea,
			agentCodeOfUserLogin,
			mValueUserPosition,
			isBranchManager,
		} = useContractInfoDetail()

		const { readIndividualAgentCode } = useApis({ t })

		// Validation rules
		const isShowModalAfterUpdatingAgentCode = ref(false)
		const alertMessageAfterUpdatedAgentCode = ref(false)
		const showModalUpdateAgent = ref(false)

		const notAllowToChangeNewAgentCodeModal = computed(() => !isBranchManager.value && !contractList.value?.length > 0)
		const isEmptyPolicyListToUpdate = computed(() => !contractList.value?.filter((i) => i?.isChecked)?.length > 0)
		const isSelectedNewAgentCode = computed(() => contractList.value?.filter((i) => i?.newAgentCode)?.length > 0)

		const notAllowToSendRequest = computed(() => {
			const checkedList = contractList.value?.filter((i) => i?.isChecked)

			return (
				!isBranchManager.value ||
				!contractList.value?.length > 0 ||
				isEmptyPolicyListToUpdate.value ||
				(!isEmptyPolicyListToUpdate.value && !isSelectedNewAgentCode.value) ||
				!!checkedList?.find((i) => !i?.newAgentCode)
			)
		})

		const hasAtLeast1caseFailed = ref("")
		const alertMessageAfterUpdatingAnAgentCode = computed(() => {
			if (hasAtLeast1caseFailed.value && hasAtLeast1caseFailed.value?.status === "False") {
				return t("dashboard.updateAnAgentCodeHasFailed")
			} else return t("dashboard.agentCodeUpdatedSuccessfully")
		})

		// Filter cases
		const handleClickApplyFilter = debounce(async () => {
			currentPage.value = 1
			isSelectedAllPolicies.value = false
			contractList.value = []
			newAgentCodeOnModal.value = ""
			await readAllContractData()
		}, 400)

		// On search page
		const handleSearchPage = debounce(async (pageToGo) => {
			currentPage.value = pageToGo
			isSelectedAllPolicies.value = false
			newAgentCodeOnModal.value = ""
			await readAllContractData()
		}, 400)

		// Logic check box
		const isSelectedAllPolicies = ref(false)

		function onToggleAllPolicies() {
			isSelectedAllPolicies.value = true
			contractList.value = contractList.value?.map((i) => {
				return {
					...i,
					isChecked: i?.isUpdatedAgentCodeSuccessful && i?.status === "Success" ? i?.isChecked : true,
				}
			})
		}

		function resetToggleSelectAll() {
			isSelectedAllPolicies.value = false
			/**
			 * isChecked: false,
			 * newAgentCode: "",
			 */
			contractList.value = contractList.value?.map((i) => {
				return {
					...i,
					isChecked: i?.isUpdatedAgentCodeSuccessful && i?.status === "Success" ? i?.isChecked : false,
					newAgentCode: i?.isUpdatedAgentCodeSuccessful && i?.status === "Success" ? i?.newAgentCode : "",
				}
			})
			newAgentCodeOnModal.value = ""
		}

		function onToggleAPolicy(item) {
			const policySelected = contractList.value?.find((c) => c?.policyNumber === item?.policyNumber)
			if (policySelected) {
				item.isChecked = !item?.isChecked
				contractList.value = contractList.value?.map((p) => ({
					...p,
					newAgentCode: p?.isChecked ? p?.newAgentCode : "",
				}))
			}
			if (!contractList.value?.find((p) => p?.isChecked)) {
				newAgentCodeOnModal.value = ""
			}
		}
		function isCheckedPolicy(item) {
			if (contractList.value.find((c) => c?.policyNumber === item?.policyNumber)) {
				return true
			} else {
				return false
			}
		}

		const isDisabledCheckbox = (item) => {
			const itemHasUpdatedSuccessful = contractList.value?.find((i) => i?.policyNumber === item?.policyNumber)
			return itemHasUpdatedSuccessful?.status === "Success"
		}

		// Methods

		const handleUpdateAgentCodeOnSearching = (val) => {
			servicingAgentCode.value = val
		}
		const handleUpdateContractNo = () => {
			v.value.contractNo.$touch()
		}

		// Update new Servicing Agent code
		const newAgentCodeOnModal = ref("")
		const updateNewAgentCodeOnCTable = (code, item) => {
			newAgentCodeOnModal.value = code
			contractList.value = contractList.value?.map((p) => ({
				...p,
				newAgentCode: item?.policyNumber === p?.policyNumber ? code : p?.newAgentCode,
			}))
		}
		const onUpdateNewAgentCodeOnModal = (code) => {
			newAgentCodeOnModal.value = code
		}
		const confirmNewAgentCodeOnModal = () => {
			isShowModalAfterUpdatingAgentCode.value = false
			contractList.value = contractList.value?.map((p) => ({
				...p,
				newAgentCode: (p?.isChecked && (!p?.status || p?.status !== "Success") && newAgentCodeOnModal.value) || p?.newAgentCode,
			}))
		}

		// updating a new agent code
		const isSendingRequest = ref(false)
		const getListToUpdate = () => {
			const list = contractList.value?.filter((i) => i?.isChecked && !i?.isUpdatedAgentCodeSuccessful)
			return list
		}
		const handleSendRequestToMVL = async () => {
			if (isEmptyPolicyListToUpdate.value) return
			isSendingRequest.value = true

			try {
				// this action is triggering to call API after user selected a new agent code to update
				const dataToUpdate = getListToUpdate()
				const mDataToRequest = dataToUpdate?.map((p) => {
					return {
						policyNumber: p?.policyNumber,
						newAgentCode: p?.newAgentCode && splitAgentCodeValue(p?.newAgentCode)?.code,
						newAgentName: p?.newAgentCode && splitAgentCodeValue(p?.newAgentCode)?.name,
						policyName: p?.poName,
						currentAgentCode: p?.servcingAgentCode,
						currentAgentName: p?.servcingAgentName,
					}
				})
				const dataResponse = await sendRequestToMVLToUpdateAgentCode({ data: mDataToRequest })
				// as long as 1 case has failed -> show failed message
				hasAtLeast1caseFailed.value = dataResponse?.value?.data?.find((p) => p?.status === "False")

				if (dataResponse?.status === "success") {
					isShowModalAfterUpdatingAgentCode.value = true
					alertMessageAfterUpdatedAgentCode.value = true
					showModalUpdateAgent.value = false
					contractList.value = contractList.value?.map((i) => {
						const findItemHasUpdated = dataResponse?.value?.data?.find((p) => p?.policyNumber === i?.policyNumber)
						return {
							...i,
							errorCode: findItemHasUpdated ? findItemHasUpdated?.errorCode : i?.errorCode ? i?.errorCode : undefined,
							status: findItemHasUpdated ? findItemHasUpdated?.status : i?.status ? i?.status : undefined,
							message: findItemHasUpdated && findItemHasUpdated?.message,
							caseStatus: findItemHasUpdated
								? findItemHasUpdated?.status === "Success"
									? t("dashboard.hasSucceeded")
									: t("dashboard.hasNotSucceeded")
								: i?.caseStatus
								? i?.caseStatus
								: undefined,
							isUpdatedAgentCodeSuccessful: findItemHasUpdated
								? findItemHasUpdated?.status === "Success"
								: i?.isUpdatedAgentCodeSuccessful
								? i?.isUpdatedAgentCodeSuccessful
								: undefined,
						}
					})
				} else {
					await renderSystemError(dataResponse?.errors?.[0], "updateAnAgentCodeHasFailed")
				}
			} catch (e) {
				console.error(e)
			} finally {
				isSendingRequest.value = false
			}
		}
		const onToggleModalUpdateAgentCode = () => {
			if (isEmptyPolicyListToUpdate.value) {
				renderWarningToSelectPolicy()
				return
			}
			showModalUpdateAgent.value = true
			isShowModalAfterUpdatingAgentCode.value = !isShowModalAfterUpdatingAgentCode.value
			alertMessageAfterUpdatedAgentCode.value = false
		}

		/**
		 * After updating an agent code successful:
		 * 1/ get value of error on case updated -> info will show on modal Error. Note: api is updating success but agent code is updated failed
		 * 2/ updating: newAgentCode (for servicingAgentCode)+ newAgentName (for servicingAgentName) on the table cell
		 * 3/ update: the status column of table for each case has failed / succeeded
		 */
		const errorsLogItem = ref({})
		const isShowModalErrorInfo = ref(false)
		const onViewErrorCase = (item) => {
			if (!item?.message) return
			isShowModalErrorInfo.value = true
			errorsLogItem.value = {
				errorsLog: [],
				message: contractList.value?.find((i) => i?.policyNumber === item?.policyNumber)?.message,
			}
		}

		/** Fetch Agent code options on each item
		 * This way to avoid many times call a box agentCodeOptions and also to filter the coincidence agent code on item
		 * */
		const defaultAgentCodeOptions = ref([])
		const notAllowAgentCodeOnOptions = ref("")
		async function fetchAgentCodeOptionsCTable({ notAllowAgentCode = "" }) {
			const dataResponse = await readIndividualAgentCode({
				where: {
					and: [{ source_contains: userLocatedByBranchOrArea }, { or: [...mValueUserPosition.value] }],
				},
			})

			if (Array.isArray(dataResponse?.individualAgentCode?.list)) {
				let dataList = dataResponse?.individualAgentCode?.list?.reduce((resultList, currentItem) => {
					const fields = parseJSON(currentItem.fields, "{}")
					if (!fields?.agentCodeName) return resultList
					const transformItem = {
						...currentItem,
						value: fields?.agentCodeName,
						code: fields?.agentCodeName,
						name: fields?.agentCode,
					}
					if (transformItem?.name === agentCodeOfUserLogin.value) return resultList
					if (transformItem?.name === notAllowAgentCode) return resultList
					if (isBranchManager.value) {
						const userSource = transformItem?.source?.split("-")
						const userPosition = userSource?.[userSource?.length - 1]
						if (!["RBO.SRBO", "TIS.PRM"]?.includes(userPosition)) {
							resultList.push(transformItem)
						}
					} else {
						resultList.push(transformItem)
					}
					return resultList
				}, [])

				dataList = uniqBy(dataList, "code")
				defaultAgentCodeOptions.value = dataList
			}
		}

		watch(
			() => totalItems.value,
			async (val) => {
				if (val) {
					notAllowAgentCodeOnOptions.value = contractList.value?.find((i) => i?.servcingAgentCode)?.servcingAgentCode
					await fetchAgentCodeOptionsCTable({ notAllowAgentCode: notAllowAgentCodeOnOptions.value })
				}
			}
		)
		return {
			v,
			t,
			isLoadingFetchAllCases,
			totalItems,
			limit,
			currentPage,
			servicingAgentCode,
			contractNo,
			isCheckedPolicy,
			isSelectedAllPolicies,
			alertMessageAfterUpdatingAnAgentCode,
			isShowModalAfterUpdatingAgentCode,
			alertMessageAfterUpdatedAgentCode,
			notAllowToChangeNewAgentCodeModal,
			notAllowToSendRequest,
			contractListAreEmpty,
			agentCodeOptionsOnFilter,
			isLoadingSearchAgentCode,
			isShowModalErrorInfo,
			errorsLogItem,
			isSendingRequest,
			isDisabledCheckbox,
			formatData,
			newAgentCodeOnModal,
			notAllowAgentCodeOnOptions,
			isSelectedNewAgentCode,
			showModalUpdateAgent,

			// Methods
			handleUpdateAgentCodeOnSearching,
			onToggleAllPolicies,
			resetToggleSelectAll,
			onToggleAPolicy,
			handleClickApplyFilter,
			handleSendRequestToMVL,
			handleSearchPage,
			onToggleModalUpdateAgentCode,
			updateNewAgentCodeOnCTable,
			confirmNewAgentCodeOnModal,
			onUpdateNewAgentCodeOnModal,
			isEmptyPolicyListToUpdate,
			onHandleSearchAgentCode,
			onViewErrorCase,
			handleUpdateContractNo,

			// Options
			updateAgentServiceTableHeaderList,
			contractList,
			defaultAgentCodeOptions,
		}
	},
}
</script>
