export default {
	case: {
		cases: "Cases",
		search: "Search",
		add: "Add",
		confirmToDelete: "Confirm to Delete?",
		youAreGoingToDelete: "You are going to delete",
		case: "Case",
		case_lowercase: "case",
		actionCannotBeRevert: "This action cannot be reverted. The record will be deleted permanently.",
		client: "Client",
		template: "Template",
		createdAt: "Created At",
		lastUpdated: "Last Updated",
		newCase: "New Case",
		create: "Create",
		cancel: "Cancel",
		createCaseSuccess: "Case has been created successfully!",
		delete: "Delete",
		deleteCaseSuccess: "Case has been deleted successfully!",
		caseId: "Case Id",
		proposal: "Proposal",
		proposal_lowercase: "proposal",
		addProposal: "Add Proposal",
		numberOfOffers: "Number of Offers",
		actions: "Actions",
		renew: "Renew",
		duplicate: "Duplicate",
		noDataHere: "No data available",
		addNew: "Add new",
		proposalName: "Proposal name",
		confirm: "Confirm",
		holder: "Holder",
		addHolder: "Add holder",
		insured: "Insured",
		addInsured: "Add Insured",
		beneficiaries: "Beneficiaries",
		addBeneficiaries: "Add Beneficiaries",
		information: "Information",
		deleteCase: "Delete Case",
		deleteNote:
			"You can permanently delete the case from database. This action cannot be reverted and you cannot restore the record anymore.",
		status: "Status",
		createdOn: "Created On",
		createdBy: "Created By",
		updatedOn: "Updated On",
		updatedBy: "Updated By",
		referral: "Referral",
		description: "Description",
		deleteProposalMessage: "Proposal has been deleted successfully!",
		offer: "Offer",
		offer_lowercase: "offer",
		addOffer: "Add Offer",
		sendToClient: "Send to Client",
		sendOfferNote: "Offer and all attachment will be sent to client. Client will received a notification.",
		rejectProposal: "Reject Proposal",
		rejectProposalNote:
			"You can reject the proposal by press the below button and giving a reason. A notification email will be sent out at the same time if you want.",
		offerName: "Offer Name",
		send: "Send",
		premium: "Premium",
		referralCode: "Referral Code",
		deleteOfferMessage: "Offer has been deleted successfully!",
		rejectProposalMessage: "Proposal has been rejected!",
		confirmToReject: "Confirm to Reject?",
		reject: "Reject",
		remarks: "Remarks",
		product: "Product",
		benefits: "Benefits",
		benefitOptions: "Benefit Options",
		reset: "Reset",
		stakeholders: "Stakeholders",
		addStakeholder: "Add Stakeholder",
		clauses: "Clauses",
		commissionSplit: "Commission Split",
		addRule: "Add Rule",
		exclusion: "Exclusion",
		addExclusion: "Add Exclusion",
		deleteOffer: "Delete Offer",
		deleteNoteOffer: "You can permanently delete the offer. This action cannot be undone.",
		addClauses: "Add Clauses",
		paymentFrequency: "Payment Frequency",
		grossPremium: "Gross Premium",
		discountCodes: "Discount Codes",
		premiumBreakdown: "Premium Breakdown",
		fact: "Fact",
		addFact: "Add Fact",
		netPremium: "Net Premium",
		stakeholder: "Stakeholder",
		stakeholderType: "Stakeholder Type",
		pleaseSelect: "Please select",
		stakeholderId: "Stakeholder Id",
		clause: "Clause",
		clause_lowercase: "clause",
		htmlOverride: "HTML Override",
		update: "Update",
		order: "Order",
		deleteClauseSuccess: "Clause has been deleted successfully!",
		renewProposalSuccess: "Proposal has been renewed successfully!",
		code: "Code",
		remark: "Remark",
		clientName: "Client Name",
		apply: "Search",
		clear: "Clear",
		renewal: "Renewal",
		duplicateProposalSuccess: "Proposal has been duplicated successfully!",
		updateProduct: "Update Product",
		addProduct: "Add Product",
		name: "Name",
		inProgress: "IN PROGRESS",
		completed: "COMPLETED",
		canceled: "CANCELED",
		leadGCMConfirmationToUpdate: "Are you sure you want to update Lead CRM for Conversation ID",
		notice: "Notice",
		noGCMConversationMessage: "You still have {count} conversation ID(s) that did not map with CRM Lead",
		exportData: "Export data",
		datesOlderThan90days: "Please choose 'From Date - To Date' not greater 31 days period.",
		searchByCustomerInfo: "Search by Phone number/ Citizen ID/ Customer ID/ Passport",
		creationDate: "Creation Date",
		FNAStatus: "FNA Status",
		dropOutAndConversationResult: "Conversation Result/ Drop out Reason",
		ePOSstatus: "ePOS status",
		FNACompleted: "FNA Completed",
		applicationInProgress: "Application In progress",
		lateSubmission: "Late Submission",
		applicationSubmitted: "Application Submitted",
	},
}
