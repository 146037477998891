import { createStore } from "vuex"
import globalStore from "./global"
import fna from "@/modules/fna/store"
import rbo from "@/modules/rbo/store"
import pages from "@/modules/pages/store"
import notification from "@/modules/notification/store"
const store = createStore({
	...globalStore,
	modules: {
		fna: {
			namespaced: true,
			...fna,
		},
		rbo: {
			namespaced: true,
			...rbo,
		},
		pages: {
			namespaced: true,
			...pages,
		},
		notification: {
			namespaced: true,
			...notification,
		},
	},
})

export default store
