export default {
	isComponent: "materialProduct",
	isComponentFAQ: "materialFAQ",
	keyProduct: null,
	keyFAQ: "materialFAQ",
	listFAQsDetail: {
		"Account-Access": {
			name: "pages.faqFrequentlyAccountAccess",
			Collapses: [
				{
					title: "pages.accountAccessDevicesUsed",
					ticked: false,
					contents: [
						{
							content: "pages.accountAccessDevicesUsedContent",
							optionsContent: ["pages.accountAccessDevicesUsedContent1", "pages.accountAccessDevicesUsedContent2"],
						},
					],
				},
				{
					title: "pages.accountAccessBrowsersUsed",
					ticked: false,
					contents: [
						{
							content: "pages.accountAccessBrowsersUsedContent1",
						},
						{
							content: "pages.accountAccessBrowsersUsedContent2",
						},
					],
				},
				{
					title: "pages.accountAccessUser",
					ticked: false,
					contents: [
						{
							content: "pages.accountAccessUserContent1",
						},
					],
				},
				{
					title: "pages.accountAccessRegister",
					ticked: false,
					contents: [
						{
							content: "pages.accountAccessRegisterContent1",
						},
					],
				},
				{
					title: "pages.userAndpassword",
					ticked: false,
					contents: [
						{
							content: "pages.userAndpasswordContent1",
						},
						{
							content: "pages.userAndpasswordContent2",
						},
					],
				},
				{
					title: "pages.forgotMyPassword",
					ticked: false,
					contents: [
						{
							content: "pages.forgotMyPasswordContent1",
						},
					],
				},
				{
					title: "pages.iPadDeviceForConsultation",
					ticked: false,
					contents: [
						{
							content: "pages.iPadDeviceForConsultationContent1",
						},
					],
				},
			],
		},
		"Insurance-Consultation": {
			name: "pages.faqInsuranceConsultationITCBLife",
			Collapses: [
				{
					title: "pages.errorStep1",
					ticked: false,
					contents: [
						{
							content: "pages.errorStep1Content1",
						},
					],
				},
				{
					title: "pages.howToCreateLead",
					ticked: false,
					contents: [
						{
							content: "pages.howToCreateLeadContent",
							optionsContent: [
								"pages.howToCreateLeadContent1",
								"pages.howToCreateLeadContent2",
								"pages.howToCreateLeadContent3",
								"pages.howToCreateLeadContent4",
							],
						},
					],
				},
				{
					title: "pages.saveInStep2",
					ticked: false,
					contents: [
						{
							content: "pages.saveInStep2Content1",
						},
						{
							content: "pages.saveInStep2Content2",
						},
					],
				},
				{
					title: "pages.customerWantsToAdjustBenefits",
					ticked: false,
					contents: [
						{
							content: "pages.customerWantsToAdjustBenefitsContent",
							optionsContent: [
								"pages.customerWantsToAdjustBenefitsContent1",
								"pages.customerWantsToAdjustBenefitsContent2",
								"pages.customerWantsToAdjustBenefitsContent3",
							],
						},
					],
				},
				{
					title: "pages.iTCBifeTool",
					ticked: false,
					contents: [
						{
							content: "pages.iTCBifeToolContent1",
						},
						{
							content: "pages.iTCBifeToolContent2",
						},
					],
				},
				{
					title: "pages.TVVEmail",
					ticked: false,
					contents: [
						{
							content: "pages.TVVEmailContent",
							optionsContent: ["pages.TVVEmailContent1", "pages.TVVEmailContent2"],
						},
					],
				},
				{
					title: "pages.customerEmail",
					ticked: false,
					contents: [
						{
							content: "pages.customerEmailContent",
							optionsContent: [
								"pages.customerEmailContent1",
								"pages.customerEmailContent2",
								"pages.customerEmailContent3",
								"pages.customerEmailContent4",
								"pages.customerEmailContent5",
							],
						},
					],
				},
				{
					title: "pages.iquiryScreen",
					ticked: false,
					contents: [
						{
							content: "pages.iquiryScreenContent",
							optionsContent: ["pages.iquiryScreenContent1", "pages.iquiryScreenContent2", "pages.iquiryScreenContent3"],
						},
					],
				},
				{
					title: "pages.iTCBLifeSupport",
					ticked: false,
					contents: [
						{
							content: "pages.iTCBLifeSupportContent",
						},
					],
				},
				{
					title: "pages.createLeadInvite",
					ticked: false,
					contents: [
						{
							content: "pages.createLeadInviteContent",
						},
					],
				},
			],
		},
		"Rule-Change": {
			name: "pages.faqRuleAndChange",
			Collapses: [
				{
					title: "pages.solutionScreen",
					ticked: false,
					contents: [
						{
							content: "pages.solutionScreenContent1",
						},
						{
							content: "pages.solutionScreenContent2",
						},
					],
				},
				{
					title: "pages.customerAgeLessThan18",
					ticked: false,
					contents: [
						{
							content: "pages.customerAgeLessThan18Content1",
						},
					],
				},
				{
					title: "pages.policyholderAgeRegulation",
					ticked: false,
					contents: [
						{
							content: "pages.policyholderAgeRegulationContent1",
						},
						{
							content: "pages.policyholderAgeRegulationContent2",
						},
						{
							content: "pages.policyholderAgeRegulationContent3",
						},
					],
				},
				{
					title: "pages.theSystemRecommendsProducts",
					ticked: false,
					contents: [
						{
							content: "pages.theSystemRecommendsProductsContent",
						},
					],
				},
				{
					title: "pages.aggregateStep",
					ticked: false,
					contents: [
						{
							content: "pages.aggregateStepContent1",
						},
						{
							content: "pages.aggregateStepContent2",
						},
						{
							content: "pages.aggregateStepContent3",
						},
					],
				},
				{
					title: "pages.notedAboutPolicyholder",
					ticked: false,
					contents: [
						{
							content: "pages.notedAboutPolicyholderContent1",
						},
						{
							content: "pages.notedAboutPolicyholderContent2",
							optionsContent: ["pages.notedAboutPolicyholderContent2Sub1", "pages.notedAboutPolicyholderContent2Sub2"],
						},
						{
							content: "pages.notedAboutPolicyholderContent3",
						},
					],
				},
				{
					title: "pages.hospitalRoomClassOf750",
					ticked: false,
					contents: [
						{
							content: "pages.hospitalRoomClassOf750Content1",
						},
					],
				},
				{
					title: "pages.TVVStep6",
					ticked: false,
					contents: [
						{
							content: "pages.TVVStep6Content",
							optionsContent: [
								"pages.TVVStep6Content1",
								"pages.TVVStep6Content2",
								"pages.TVVStep6Content3",
								"pages.TVVStep6Content4",
							],
						},
					],
				},
				{
					title: "pages.theSystemSuggestInsuranceValue",
					ticked: false,
					contents: [
						{
							content: "pages.theSystemSuggestInsuranceValueContent",
							optionsContent: ["pages.theSystemSuggestInsuranceValueContent1", "pages.theSystemSuggestInsuranceValueContent2"],
						},
					],
				},
				{
					title: "pages.sumMoneyEdu",
					ticked: false,
					contents: [
						{
							content: "pages.sumMoneyEduContent",
						},
					],
				},
				{
					title: "pages.sumMoneyIncreaseAssets",
					ticked: false,
					contents: [
						{
							content: "pages.sumMoneyIncreaseAssetsContent",
						},
					],
				},
				{
					title: "pages.sumMoneyIncomeProtection",
					ticked: false,
					contents: [
						{
							content: "pages.sumMoneyIncomeProtectionContent",
						},
					],
				},
				{
					title: "pages.sumMoneyHealthCare",
					ticked: false,
					contents: [
						{
							content: "pages.sumMoneyHealthCareContent",
							optionsContent: [
								"pages.sumMoneyHealthCareContent1",
								"pages.sumMoneyHealthCareContent2",
								"pages.sumMoneyHealthCareContent3",
								"pages.sumMoneyHealthCareContent4",
							],
						},
					],
				},
				{
					title: "pages.sumMoneyPeacefulRetirement",
					ticked: false,
					contents: [
						{
							content: "pages.sumMoneyPeacefulRetirementContent",
						},
					],
				},
			],
		},
	},
	listSupports: {
		"Contact-Techcombank": {
			titleContact: "pages.contactTechcombank",
			imageContact: "contact_tcb.png",
			listContacts: [
				{
					title: "pages.contactTechcombankOption1",
					contents: ["pages.contactTechcombankOption1Content"],
					bgIcon: "bg-c1-300",
					emails: ["TO_iTCBlife@techcombank.com.vn"],
				},
				{
					title: "pages.contactTechcombankOption2",
					contents: ["pages.contactTechcombankOption2Content"],
					bgIcon: "bg-c1-300",
					emails: ["khieunaikhachhang@techcombank.com.vn"],
				},
				{
					title: "pages.contactTechcombankOption3",
					contents: ["pages.contactTechcombankOption3Content"],
					bgIcon: "bg-c1-300",
					emails: ["RBG_SanphamvaquantrikinhdoanhBaohiem@techcombank.com.vn"],
				},
				{
					title: "pages.contactTechcombankOption4",
					contents: [
						"pages.contactTechcombankOption4Content1",
						"pages.contactTechcombankOption4Content2",
						"pages.contactTechcombankOption4Content3",
					],
					bgIcon: "bg-c1-300",
					emails: ["INS_Daotao@techcombank.com.vn"],
				},
				{
					title: "pages.contactTechcombankOption6",
					contents: ["pages.contactTechcombankOption6Content"],
					bgIcon: "bg-c1-300",
					emails: ["PFS.KPIs@techcombank.com.vn"],
				},
				{
					title: "pages.contactTechcombankOption7",
					contents: ["pages.contactTechcombankOption7Content1", "pages.contactTechcombankOption7Content2"],
					bgIcon: "bg-c1-300",
					emails: ["OT.IT.Support@techcombank.com.vn"],
				},
			],
		},
		"Contact-MVL": {
			titleContact: "pages.contactMVL",
			imageContact: "contact_manulife.png",
			listContacts: [
				{
					title: "pages.contactMVLOption1",
					contents: ["pages.contactMVLOption1Content"],
					hotline: "1900 1776",
					hotlineContent: "pages.contactMVLOption1ContentHotline",
				},
				{
					title: "pages.contactMVLOption2",
					contents: ["pages.contactMVLOption2Content1", "pages.contactMVLOption2Content2", "pages.contactMVLOption2Content3"],
					hotline: "1900 1776",
					hotlineContent: "pages.contactMVLOption2ContentHotline",
				},
				{
					title: "pages.contactTechcombankOption5",
					contents: ["pages.contactTechcombankOption5Content"],
					emails: ["Minh_Hoang_Cong@manulife.com"],
				},
				{
					title: "pages.contactMVLOption3",
					contents: ["pages.contactMVLOption3Content"],

					emails: ["nb-online@manulife.com"],
				},
				{
					title: "pages.contactMVLOption4",
					contents: ["pages.contactMVLOption4Content"],

					emails: ["CR_HCM@manulife.com"],
				},
				{
					title: "pages.contactMVLOption5",
					contents: ["pages.contactMVLOption5Content"],
					hotline: "1900 1776",
					hotlineContent: "pages.contactMVLOption5ContentHotline",
				},
				{
					title: "pages.contactMVLOption6",
					contents: ["pages.contactMVLOption6Content1", "pages.contactMVLOption6Content2", "pages.contactMVLOption6Content3"],

					emails: ["thaydoihopdong@manulife.com", "VN_POS_Collection@manulife.com"],
				},
				{
					title: "pages.contactMVLOption7",
					contents: ["pages.contactMVLOption7Content"],

					emails: ["dichvukhachhang@insmart.com.vn"],
					hotline: "1900 636 730",
					hotlineContent: "pages.contactMVLOption7ContentHotline",
				},
				{
					title: "pages.contactMVLOption8",
					contents: ["pages.contactMVLOption8Content"],
					hotline: "1900 1776",
					hotlineContent: "pages.contactMVLOption8ContentHotline",
				},
				{
					title: "pages.contactMVLOption9",
					contents: ["pages.contactMVLOption9Content"],

					emails: ["khachhang@manulife.com"],
				},
				{
					title: "pages.contactMVLOption10",
					contents: ["pages.contactMVLOption10Content"],
					emails: ["Hotro_ungdung@manulife.com.vn"],
					hotline: "086 951 0980",
					hotlineContent: "pages.contactMVLOption10ContentHotline",
				},
				{
					title: "pages.contactMVLOption11",
					contents: [
						{
							value: "pages.contactMVLOption11Content1",
							email: "VN_PD_support@manulife.com",
						},
						{
							value: "pages.contactMVLOption11Content2",
							email: "Ngoc_Tran_HD@manulife.com",
						},
					],
				},
			],
		},
	},
}
