<template>
	<JBox>
		<label
			v-if="titleBox"
			:for="labelField"
			:class="[dark ? 'text-cWhite' : 'text-cBlack']"
			class="font-semibold block pointer-events-none text-md mb-2"
		>
			<slot name="titleBox">
				{{ titleBox }}
				<span v-if="labelIsRequired" class="text-c1-500">*</span>
			</slot>
		</label>
		<JBox
			v-click-outside="onClickOutside"
			:class="['relative flex justify-between items-center', classes.root]"
			style="min-height: 2.5rem"
			@click="handleClickDropdown($event)"
		>
			<JFlex v-if="isLoading" class="justify-center w-full">
				<JSpinner></JSpinner>
			</JFlex>
			<!-- TAG Place holder -->
			<JFlex v-else-if="selectedOptions?.length === 0 && !showOptions" class="flex flex-wrap w-full pr-2">
				<span :class="['flex-1 truncate', classes.placeholder]">
					{{ placeholder }}
				</span>
				<JIcon class="text-cBlack" icon="ChevronDown" />
			</JFlex>

			<!-- Show Options selected -->
			<JBox v-else :class="classes.tagRoot">
				<!-- Option: items selected less than itemsLimited -->
				<JBox v-if="selectedOptions?.length <= itemsLimited">
					<JBox v-for="tag in selectedOptions" :key="tag[valueKey]" :class="classes.tag">
						<span>{{ tag[nameKey] }}</span>
					</JBox>
				</JBox>
				<!-- Show length limited -->
				<JBox v-else-if="selectedOptions?.length === internalOptions?.length" :class="classes.tag">
					<span>{{ $t("dashboard.all") }}</span>
				</JBox>
				<JBox v-else :class="classes.tag">
					<span>{{ selectedOptions?.length }} {{ $t(`${selectedItemsText}`) }}</span>
				</JBox>
			</JBox>

			<!-- Dropdown Option List -->
			<transition
				v-show="showOptions"
				class="shadow-5 rounded-md"
				:enterClass="classes.transition.enter"
				:enterActiveClass="classes.transition.enterActive"
				:leaveActiveClass="classes.transition.leaveActive"
				:leaveToClass="classes.transition.leave"
			>
				<div
					:style="[classes.optionsStyles, customOptionsHeight]"
					class="absolute overflow-y-auto z-50 scrolling-touch"
					:class="classes.options"
				>
					<!-- Input -->
					<JBox v-if="!disabledSearch" class="p-2 sticky top-0 border-c0-100 bg-c0-100">
						<input
							ref="searchInput"
							v-model="searchTerm"
							:class="[classes.input]"
							:placeholder="searchPlaceholder"
							:disabled="disabledSearch"
							@input="onInputSearch"
							@focus="handleFocus"
							@blur="handleBlur"
						/>
					</JBox>

					<JBox v-show="isLoading" class="text-center mt-2">
						<JFlex class="py-2 px-4 inline-flex items-center">
							<JSpinner variant="secondary" type="dots" />
						</JFlex>
					</JBox>
					<!--Selection-->
					<div>
						<!-- Select ALL -->
						<label
							v-if="optionAllIsVisible && !disabledAllOption && filteredOptions.length"
							class="flex items-center option w-full px-3 border-b"
							:class="classes.option"
						>
							<CCheckbox
								variant="primary"
								:modelValue="isAllSelected"
								:disabled="isSingleSelect || disableToggleOption"
								@click="onToggleSelectAll"
							/>

							<span class="ml-3">{{ $t("dashboard.all") }}</span>
						</label>

						<!-- options -->
						<div
							v-for="(option, idx) in filteredOptions"
							:key="idx"
							class="flex items-center option w-full px-3"
							:class="classes.option"
							@click="onToggleOption(option)"
						>
							<CCheckbox variant="primary" :modelValue="option.selected" :disabled="disabledToggleItem" />

							<span class="ml-3">
								{{ option[nameKey] }}
							</span>
						</div>
						<!-- No option was found -->
						<div v-if="!filteredOptions.length && searchTerm?.length" :class="classes.noOption">
							{{ $t("core.noCodeFound") }}
						</div>
					</div>
				</div>
			</transition>
		</JBox>
	</JBox>
</template>

<script setup>
import { debounce } from "lodash"
import { ref, nextTick, computed, watch } from "vue"
import { useVariant, genVariantProps } from "@/composables/variant"

const emit = defineEmits(["update:modelValue", "search", "focus", "blur", "toggle:all", "toggle:option", "show", "hide"])
const props = defineProps({
	...genVariantProps(),
	labelField: {
		type: null,
		default: null,
	},
	labelIsRequired: {
		type: Boolean,
		default: false,
	},
	dark: {
		type: Boolean,
		default: false,
	},
	titleBox: {
		type: String,
		default: "",
	},
	field: {
		type: String,
		default: "",
	},
	nameKey: {
		type: String,
		default: "name",
	},
	valueKey: {
		type: String,
		default: "value",
	},
	modelValue: {
		type: Array,
		default: () => [],
	},
	options: {
		type: Array,
		default: () => [],
	},
	searchFunction: {
		type: Function,
	},
	disabledSearch: {
		type: Boolean,
		default: false,
	},
	disabledOptions: {
		type: Boolean,
		default: false,
	},
	isSingleSelect: {
		type: Boolean,
		default: false,
	},
	iconCollapsed: {
		type: String,
	},
	iconExpanded: {
		type: String,
	},
	itemsLimited: {
		type: Number,
		default: 1,
	},
	placeholder: {
		type: String,
		default: "Please select",
	},
	searchPlaceholder: {
		type: String,
		default: "Type to search",
	},
	searchTimeout: {
		type: Number,
		default: 400,
	},
	customOptionsHeight: {
		type: String,
		default: "",
	},
	selectedItemsText: {
		type: String,
		default: "",
	},
	disabledAllOption: {
		type: Boolean,
		default: false,
	},
	disableToggleOption: {
		type: Boolean,
		default: false,
	},
	validator: {
		type: Object,
		default: () => {},
	},
	isLoading: {
		type: Boolean,
		default: false,
	},
	optionAllIsVisible: {
		type: Boolean,
		default: true,
	},
})
const { classes } = useVariant("CMultiSelectAndSearch", props)

const searchInput = ref(null)
const searchTerm = ref("")
const showOptions = ref(false)
const internalOptions = ref(props.options)
const isAllSelected = computed(() => internalOptions.value.every(({ selected }) => selected))
const selectedOptions = ref(props.modelValue)

const filteredOptions = computed(() => {
	if (!searchTerm.value) return internalOptions.value

	let filteredOptions = internalOptions.value?.filter((item) => {
		if (item && item[props.nameKey] && item[props.nameKey].toLowerCase().includes(searchTerm.value.toLowerCase())) {
			return true
		}
	})
	if (!filteredOptions?.length) {
		filteredOptions = internalOptions.value?.filter((item) => {
			const value = item && item[props.nameKey]
			if (!value) return

			return getMatchSearchTermSplit({ value, searchInput: searchTerm.value })
		})
	}
	return filteredOptions
})

const disabledToggleItem = computed(
	() => props.disableToggleOption || (props.isSingleSelect && selectedOptions.value?.length === 1)
)

function onToggleOption(option) {
	if (props.disableToggleOption) return

	if (option.selected) {
		unSelectOption(option)
	} else {
		selectOption(option)
	}
}

function onToggleSelectAll(e) {
	if (props.disableToggleOption || props.isSingleSelect) return
	const checked = e.target.checked

	internalOptions.value?.forEach((option) => {
		if (checked) {
			selectOption(option)
		} else {
			unSelectOption(option)
		}
	})

	emit("toggle:all", checked)
}

function selectOption(option) {
	if (props.isSingleSelect && selectedOptions.value?.length === 1) return

	if (!option.selected) {
		option.selected = true

		selectedOptions.value = [...selectedOptions.value, option]

		emit("toggle:option", option)
		emit("update:modelValue", selectedOptions.value)
	}
}
function unSelectOption(option) {
	if (option.selected) {
		option.selected = false

		selectedOptions.value = selectedOptions.value.filter((item) => item[props.valueKey] !== option[props.valueKey])

		emit("toggle:option", option)
		emit("update:modelValue", selectedOptions.value)
	}
}

const onInputSearch = debounce((evt) => {
	emit("search", evt?.target?.value)
}, props.searchTimeout)

function handleClickDropdown(event) {
	if (props.disabledOptions) return

	event.stopPropagation()

	if (!props.disabledSearch) {
		// Focus on search input after render it
		nextTick(function () {
			searchInput.value?.focus()
		})
	}
	showDropdownBox()
}

function getMatchSearchTermSplit({ value, searchInput }) {
	const searchTermSplit = searchInput?.toLowerCase().split(" ")
	return searchTermSplit.every((item) => value.toLowerCase().includes(item))
}

function handleFocus() {
	if (!props.disabledSearch) {
		emit("focus")
	}
}
function handleBlur() {
	emit("blur")
}
function clearSearch() {
	searchTerm.value = ""

	if (!props.options?.length) {
		onInputSearch(searchTerm.value)
	}
}
function showDropdownBox() {
	if (!showOptions.value) {
		showOptions.value = true
		emit("show")
	}
}
function hideDropdownBox() {
	if (showOptions.value) {
		showOptions.value = false
		emit("hide")
	}
}
function onClickOutside() {
	hideDropdownBox()

	if (!props.disabledSearch) {
		clearSearch()
	}
}

watch(
	() => props.modelValue,
	(options) => {
		selectedOptions.value = options
	}
)

watch(
	() => props.options,
	(options) => {
		internalOptions.value = options
	}
)
</script>
