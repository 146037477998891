/* eslint-disable quotes */
export default {
	notification: {
		clickToExpand: "View all notifications",
		noti: "Notification",
		policyNo: "Policy number",
		contractor: "Policy owner",
		paidToDate: "Paid to date",
		paidAmount: "Paid amount",
		policyPaymentDueDate: "Policies payment due date",
		userDOBByWeek: "Customers' birthday in",
		userFullName: "Customer full name",
		userIdentify: "Citizen Id/Passport",
		userDOB: "Birthday",
		phoneNo: "Phone number",
		address: "Address",

		// reminder list
		PaymentDueReminderWeekly: "Payment due of contract list Week",
		dobReminderWeekly: "Customer birthday list Week",
	},
}
