import { computed } from "vue"

export default function getLocationByBranches({ originalBranches, locale }) {
	// const { locale } = useI18n()

	/** BRANCH item formatted (should not be changed)
	 * {
    "order": 1,
    "value": "HDG",
    "name": "Hải Dương",
    "branchNameEn": "Hai Duong",
		"branchNameVn": "Hai Duong",
    "regionId": "1",
    "region": "region_1",
		"regionNameEn": "",
		"regionNameVn": "",
    "areaNameVN": "Miền Bắc",
    "areaNameEn": "North",
    "locationNo": 1,
    "locationNameEn": 1,
    "area": "north"
	}
	 */

	function mAreaCode({ areaValue }) {
		if (!areaValue) return null
		if (areaValue === "north") return "MB"
		if (areaValue === "south") return "MN"
		if (areaValue === "Hub_Mortgage") return "MORTGAGE"
		if (areaValue === "Hub_Private") return "PRIVATE"
		if (areaValue === "Hub_Insurance") return "INS"
	}

	function mOrderRegions(regionValue) {
		/**
		 * trim the region value to get the order
		 */
		if (!regionValue) return null
		let keyValue = null
		const specificRegions = ["Hub_Insurance", "Hub_Mortgage", "Hub_Private"]
		if (specificRegions.includes(regionValue)) {
			keyValue = regionValue
		} else {
			const region = regionValue.split("_")
			keyValue = region[region.length - 1]
		}
		return keyValue
	}
	const areas = computed(() =>
		originalBranches?.reduce((acc, branch) => {
			/**
			 * Item formatted should be
			 * { "name": "North", "value": "north", "code": "MB" }
			 * { "name": "South", "value": "south", "code": "MN" }
			 * { "name": "Hub_Mortgage", "value": "Hub_Mortgage", "code": "MORTGAGE" },
			 * { "name": "Hub_Private", "value": "Hub_Private", "code": "PRIVATE" },
			 * { "name": "Hub_Insurance", "value": "Hub_Insurance", "code": "INS" }
			 */
			const findItemValue = acc?.find((item) => item?.value?.toLowerCase() === branch.area?.toLowerCase())?.value
			if (!findItemValue) {
				const mapItem = {
					...branch,
					name: computed(() => (locale.value === "en-US" ? branch?.areaNameEn : branch?.areaNameVN)), // to rerender locale
					value: branch?.area || null,
					code: mAreaCode({ areaValue: branch.area }),
				}
				acc?.push(mapItem)
			}

			return acc
		}, [])
	)
	const regions = computed(() =>
		originalBranches?.reduce((acc, item) => {
			/**
			 * Item formatted should be
			 * { "id": "1", "name": "Region 1", "value": "region_1", "area": "north" }
			 * { "id": "10", "name": "Region 10", "value": "region_10", "area": "south" }
			 * { "id": "17", "name": "Hub_Insurance", "value": "Hub_Insurance", "area": "Hub_Insurance" },
			 * { "id": "18", "name": "Hub_Mortgage", "value": "Hub_Mortgage", "area": "Hub_Mortgage" },
			 * { "id": "19", "name": "Hub_Private", "value": "Hub_Private", "area": "Hub_Private" }
			 */
			const findItemValue = acc?.find((region) => region?.value?.toLowerCase() === item.region?.toLowerCase())?.value
			if (!findItemValue) {
				const mapItem = {
					...item,
					id: mOrderRegions(item?.region),
					name: computed(() => (locale.value === "en-US" ? item?.regionNameEn : item?.regionNameVn)), // to rerender locale
					value: item?.region,
					area: item?.area,
					regionNameEn: item?.regionNameEn,
					regionNameVn: item?.regionNameVn,
				}
				acc?.push(mapItem)
			}

			return acc
		}, [])
	)
	return {
		areas: areas.value,
		regions: regions.value,
		branches: originalBranches,
	}
}
