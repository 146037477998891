import { createNode } from "@formkit/core"

export function createEvent() {
	const node = createNode()
	const allReceipts = {}

	return {
		on(name, handler, ...args) {
			const receipts = allReceipts[name] || []
			const wrapper = ({ payload }) => handler(payload)
			const receipt = node.on(name, wrapper, ...args)

			handler.receipt = receipt

			receipts.push(receipt)

			allReceipts[name] = receipts

			return this
		},
		off(name, handler) {
			const receipts = handler && handler.receipt ? [handler.receipt] : allReceipts[name]

			receipts.map((receipt) => node.off(receipt))

			return this
		},
		emit(...args) {
			node.emit(...args)

			return this
		},
	}
}
