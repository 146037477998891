<template>
	<button
		v-click-outside="hideOptions"
		class="relative"
		:class="variantCls.root"
		style="min-height: 2.5rem"
		@click="handleClickSelect($event)"
		@focus="handleFocus"
		@blur="handleBlur"
		@keydown.self.down.prevent="pointerForward()"
		@keydown.self.up.prevent="pointerBackward()"
		@keypress.enter.tab.stop.self="addPointerElement($event)"
	>
		<!-- Tags -->
		<div v-if="!tag && !showOptions" class="flex flex-wrap w-full justify-between pr-2 items-center">
			<span :class="variantCls.placeholder">
				{{ placeholder }}
			</span>
			<JIcon icon="ChevronDown" class="inline-block cursor-pointer text-cBlack" />
		</div>
		<div v-else :class="variantCls.tagRoot">
			<div v-if="tag" :class="variantCls.tag">
				<span>{{ tag?.name }}</span>
				<span class="cursor-pointer" :class="variantCls.tagRemove" @click.stop="removeTag">&times;</span>
			</div>
		</div>
		<!-- Options -->
		<transition
			:enterClass="variantCls.transition.enterFrom"
			:enterActiveClass="variantCls.transition.enterActive"
			:leaveActiveClass="variantCls.transition.leaveActive"
			:leaveToClass="variantCls.transition.leaveTo"
		>
			<div
				v-if="showOptions"
				:style="[variantCls.optionsStyles, customStyleOptionList]"
				class="absolute overflow-y-auto z-10 scrolling-touch mb-4"
				:class="variantCls.options"
				@mousedown.prevent
				ref="list"
			>
				<!-- Input -->
				<input
					ref="searchInput"
					class="sticky top-0"
					v-model="searchTerm"
					:tabindex="tabindex"
					:class="variantCls.input"
					:placeholder="searchPlaceholder"
					:disabled="disabled"
					@input="handleInputSearch"
					@focus="handleFocus"
					@blur="handleBlur"
					@keydown.down.prevent="pointerForward()"
					@keydown.up.prevent="pointerBackward()"
					@keypress.enter.prevent.stop.self="addPointerElement($event)"
				/>
				<!-- Options -->
				<div
					v-for="(option, index) in filteredOptions"
					:key="option[valueKey]"
					@click="(event) => onOptionClick(event, option)"
					class="flex justify-between items-start text-left"
				>
					<slot :name="`option-${option[valueKey]}`">
						<p style="width: 100%; min-height: 2rem" :class="getOptionClass(index, option)">
							{{ option[nameKey] }}
						</p>
					</slot>
				</div>
				<!-- No option was found -->
				<div v-if="filteredOptions.length === 0" :class="variantCls.noOption">
					{{ noDataPlaceholder }}
				</div>
			</div>
		</transition>
	</button>
</template>

<script>
/* eslint-disable no-tabs */
import debounce from "lodash/debounce"

export default {
	name: "CSingleSelect",
	emits: ["update:modelValue", "search", "focus", "blur", "keyup:Enter"],
	props: {
		variant: {
			type: String,
			default: "primary",
		},
		nameKey: {
			type: String,
			default: "name",
		},
		valueKey: {
			type: String,
			default: "value",
		},
		modelValue: {
			type: [String, Number],
		},
		options: {
			type: Array,
			required: true,
			default: () => [],
		},
		placeholder: {
			type: String,
			default: "Please select",
		},
		searchPlaceholder: {
			type: String,
			default: "Type to search",
		},
		noDataPlaceholder: {
			type: String,
			default: () => "No data found",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		iconCollapsed: {
			type: String,
		},
		iconExpanded: {
			type: String,
		},
		visibleElements: {
			type: Number,
			default: 2,
		},
		optionHeight: {
			type: Number,
			default: 37,
		},
		locale: {
			type: String,
			default: "vn",
		},
		isTranslateTag: {
			type: Boolean,
			default: false,
		},
		tabindex: {
			type: [String, Number],
		},
		customStyleOptionList: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			tag: null,
			searchTerm: "",
			showOptions: false,
			pointer: 0,
		}
	},
	computed: {
		variants() {
			return {
				primary: {
					el: {
						root: "flex items-center rounded border border-c0-500 w-full whitespace-normal",
						tagRoot: "flex flex-wrap w-full",
						tag: "px-2 py-1 m-1 rounded bg-c1-400 text-cWhite",
						tagSingle: "px-2 py-1 m-1 rounded bg-c1-400 text-cWhite w-full flex justify-between",
						tagRemove: "ml-2",
						input: "bg-c0-100 border-b border-c0-300 apperance-none px-5 w-full h-10 focus:outline-none",
						placeholder: "text-c0-500 px-5 pointer-events-none",
						options: "bg-cWhite border border-c0-300 shadow",
						optionsStyles: "max-height: 12rem; top: 100%; left: -1px; right: -1px",
						option: "text-cBlack select-none hover:bg-c1-500 hover:text-cWhite px-5 py-2",
						selectedOption: "bg-c1-400 text-cWhite select-none px-5 py-2",
						highlightOption: "bg-c3-50 text-cWhite select-none px-5 py-2",
						noOption: "px-5 py-2",
						transition: {
							enterFrom: "opacity-0",
							leaveTo: "opacity-0",
							enterActive: "transition transition-medium transition-ease",
							leaveActive: "transition transition-short transition-ease-out",
						},
					},
				},
				"tcb-rule": {
					el: {
						root: "flex items-center rounded-lg border border-c0-300 w-full whitespace-normal",
						tagRoot: "flex flex-wrap w-full",
						tag: "flex flex-row justify-between w-full px-2 py-1 m-1 text-c0-500",
						tagSingle: "px-2 py-1 m-1 rounded bg-c1-400 text-cWhite w-full flex justify-between",
						tagRemove: "text-c0-300",
						input: "bg-c0-100 border-b border-c0-300 apperance-none px-5 w-full h-10 focus:outline-none",
						placeholder: "text-c0-500 px-5 pointer-events-none",
						options: "bg-cWhite border border-c0-300 shadow",
						optionsStyles: "max-height: 13rem; top: 100%; left: -1px; right: -1px",
						option: "text-c0-500 select-none hover:bg-c3-50 hover:text-c0-500 px-5 py-2",
						selectedOption: "text-c0-300 select-none px-5 py-2",
						highlightOption: "bg-c3-50 text-c0-500 select-none px-5 py-2",
						noOption: "px-5 py-2",
						transition: {
							enterFrom: "opacity-0",
							leaveTo: "opacity-0",
							enterActive: "transition transition-medium transition-ease",
							leaveActive: "transition transition-short transition-ease-out",
						},
					},
				},
			}
		},
		filteredOptions() {
			if (!this.searchTerm) return this.options
			let filteredOptions = this.options.filter((item) => {
				const value = item && item[this.nameKey]
				if (value && value.toLowerCase().includes(this.searchTerm.toLowerCase())) {
					return true
				}
			})
			if (!filteredOptions.length) {
				filteredOptions = this.options.filter((item) => {
					const value = item && item[this.nameKey]
					if (!value) return
					const searchTermSplit = this.searchTerm.toLowerCase().split(" ")
					return this.getMatchSearchTermSplit(value, searchTermSplit)
				})
			}
			return filteredOptions
		},
		variantCls() {
			return this.getComponentVariants()?.el
		},
		assets() {
			return this.getComponentVariants()?.assets || {}
		},
		pointerPosition() {
			return this.pointer * this.optionHeight
		},
	},
	watch: {
		modelValue: {
			handler() {
				if (this.modelValue) {
					const value = this.options?.find((item) => item?.value === this.modelValue)
					this.tag = value
				} else {
					this.tag = null
				}
			},
			immediate: true,
			deep: true,
		},
		locale(val) {
			if (this.isTranslateTag) {
				const mTag = this.options?.find(({ value }) => value === this.tag?.value)
				if (mTag) {
					this.tag = {
						...this.tag,
						name: val === "en-US" ? mTag?.desEn : mTag?.desVn,
					}
				}
			}
		},
	},
	methods: {
		getMatchSearchTermSplit(value, searchTermSplit) {
			for (let i = 0; i < searchTermSplit.length; i++) {
				const isMatch = value.toLowerCase().includes(searchTermSplit[i])
				if (isMatch) return true
			}
			return false
		},
		handleClickSelect(event) {
			event.stopPropagation()
			if (!this.disabled) {
				this.toggleOptions(true)
				// Focus on search input after render it
				this.$nextTick(
					function () {
						this.$refs.searchInput.focus()
					}.bind(this)
				)
			}
		},
		handleFocus() {
			if (!this.disabled) {
				this.$emit("focus")
				this.toggleOptions(true)
			}
		},
		handleBlur() {
			this.toggleOptions(false)
			this.$emit("blur")
		},
		emit(...args) {
			if (!this.disabled) {
				this.$emit(...args)
			}
		},
		toggleOptions(value) {
			if (value === undefined) this.showOptions = !this.showOptions
			this.showOptions = value
		},
		hideOptions(event) {
			if (event) {
				event.stopPropagation()
			}
			this.toggleOptions(false)
			this.clearSearch()
		},
		onOptionClick(event, option) {
			event.stopPropagation()
			this.tag = option
			this.$refs.searchInput.blur()
			this.hideOptions()
			this.$emit("update:modelValue", option?.value, option)
		},
		addPointerElement() {
			const option = this.filteredOptions[this.pointer]
			this.tag = option
			this.$refs.searchInput.blur()
			this.hideOptions()
			this.$emit("update:modelValue", option?.value, option)
			this.pointerReset()
		},
		removeTag() {
			if (this.disabled) return
			this.tag = null
			this.$emit("update:modelValue", this.tag)
		},
		clearSearch() {
			this.searchTerm = ""
		},
		handleInputSearch: debounce(function () {
			this.$emit("search", this.searchTerm)
		}, 400),
		getOptionClass(index, option) {
			return {
				[this.variantCls?.option]: this.tag?.value !== option?.value,
				[this.variantCls?.selectedOption]: this.tag?.value === option?.value,
				[this.variantCls?.highlightOption]: index === this.pointer,
			}
		},
		getComponentVariants() {
			return this.variants[this.variant]
		},
		pointerForward() {
			if (this.pointer < this.filteredOptions.length - 1) {
				this.pointer++
				/* istanbul ignore next */
				if (this.$refs.list.scrollTop <= this.pointerPosition - (this.visibleElements - 1) * this.optionHeight) {
					this.$refs.list.scrollTop = this.pointerPosition - (this.visibleElements - 1) * this.optionHeight
				}
			}
		},
		pointerBackward() {
			if (this.pointer > 0) {
				this.pointer--
				/* istanbul ignore else */
				if (this.$refs.list.scrollTop >= this.pointerPosition) {
					this.$refs.list.scrollTop = this.pointerPosition
				}
			}
		},
		pointerReset() {
			this.pointer = 0
			/* istanbul ignore else */
			if (this.$refs.list) {
				this.$refs.list.scrollTop = 0
			}
		},
	},
}
</script>
