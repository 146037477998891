import { createInput } from "@formkit/vue"
import { has, isPlainObject, merge, flattenDeep } from "lodash"

import { extend } from "@/forms/plugins/helpers.js"

const registeredComponents = {}
const dumpFn = () => {}
let reDrawTarget = {}

export function registerComponent(name, Component) {
	registeredComponents[name] = Component
}

export function registerComponents(Components) {
	Object.keys(Components).forEach((name) => registerComponent(name, Components[name]))
}

export function reDraw(node) {
	if (reDrawTarget.prop !== "_reDraw") {
		requestAnimationFrame(() => {
			node.root.props._reDraw = reDrawTarget
		})
	}
}

function customInputsPlugin(node) {
	const { type } = node.props
	const isRoot = node.root === node

	node.on("created", ({ payload }) => {
		const schema = payload.props.definition.schema

		payload.props.definition.schema = (sectionsSchema = {}) => {
			const { options, attrs, parsedRules } = node.props
			const isSelectable = ["checkbox", "radio"].includes(type)
			const isCheckboxAndRadioMultiple = (type === "checkbox" || type === "radio") && options
			const isRequired = attrs.mandatory || parsedRules.some((rule) => rule.name === "required")

			if (isRequired) {
				sectionsSchema[isCheckboxAndRadioMultiple ? "legend" : "label"] = {
					children: [
						"$label",
						{
							$el: "span",
							attrs: {
								class: "ml-1 text-c1-600",
							},
							children: "*",
						},
					],
				}
			}

			const ext = merge({}, sectionsSchema, {
				wrapper: {
					$el: isSelectable ? "label" : "div",
				},
				form: {
					$el: isRoot ? "form" : "div",
				},
			})

			return schema(ext)
		}
	})

	if (isRoot) {
		node.props._reDraw = reDrawTarget
	}

	extend(
		node,
		merge(
			{
				isValid() {
					return node.context.state.valid
				},
				clearValidationErrors() {
					node.walk((child) => {
						child.store.filter((message) => {
							return message.type === "validation"
						})
					})
				},
			},
			type === "form" && {
				submitAsync() {
					return new Promise((resolve, reject) => {
						const { onSubmit = dumpFn, onSubmitInvalid = dumpFn } = node.props

						node.props.onSubmit = async function (...args) {
							await onSubmit.call(this, ...args)

							node.emit("submit", ...args)

							resolve()
						}

						node.props.onSubmitInvalid = async function (...args) {
							await onSubmitInvalid.call(this, ...args)

							node.emit("submitInvalid", ...args)

							resolve()
						}

						node.submit()
					})
				},
			}
		)
	)

	node.hook.prop((payload, next) => {
		const prop = payload.prop

		reDrawTarget = { prop }

		if (prop === "inputProps") {
			payload.value = merge({}, node.props.attrs.defaultInputProps, payload.value)
		}

		reDraw(node)

		return next(payload)
	})

	node.config.rootClasses = (sectionKey, node) => {
		const { context, props } = node
		const { id, type } = props
		const { variants } = context.attrs
		const isRoot = !node.parent
		const classes = flattenDeep([
			`formkit-${sectionKey}`,
			variants?.classes[sectionKey],
			isRoot && `l-${props.attrs.layout}`,
			sectionKey === "wrapper" && [`c-form--${type}`, id && `c-form--${id}`],
		])
			.filter((value) => value)
			.join(" ")

		return {
			[classes]: node.root.props._reDraw,
		}
	}
}

customInputsPlugin.library = async (node) => {
	const { type } = node.props
	const component = registeredComponents[type]

	if (component) {
		node.define(createInput(component))
		node.addProps(["isCustom", "inputProps"])

		const { genProps = () => ({}) } = component
		const defaultProps = genProps()
		const defaultInputProps = Object.keys(defaultProps).reduce((acc, key) => {
			const prop = defaultProps[key]

			if (isPlainObject(prop) && has(prop, "default")) {
				acc[key] = prop.default
			}

			return acc
		}, {})

		node.props.isCustom = true
		node.props.attrs.defaultInputProps = defaultInputProps
		node.props.inputProps = merge({}, defaultInputProps, node.props.inputProps)
	}

	return node
}

export default customInputsPlugin
