export default {
	case: {
		status: "Trạng thái",
		createdAt: "Ngày thực hiện",
		lastUpdated: "Cập nhật gần nhất",
		clear: "Xoá",
		apply: "Tìm kiếm",
		inProgress: "Đang xử lý",
		completed: "Hoàn thành",
		canceled: "Huỷ bỏ",
		leadGCMConfirmationToUpdate: "Bạn muốn cập nhập Lead CRM của Conversation ID",
		notice: "Thông báo",
		noGCMConversationMessage: "Bạn vẫn còn {count} hội thoại chưa được gắn mã CRM Lead",
		exportData: "Xuất dữ liệu",
		datesOlderThan90days: "Vui lòng chọn thông tin 'Ngày thực hiện', thời gian không được lớn hơn 31 ngày",
		searchByCustomerInfo: "Số điện thoại/CMND/Mã khách hàng/Hộ chiếu",
		search: "Tìm kiếm",
		creationDate: "Ngày khởi tạo",
		FNAStatus: "Trạng thái FNA",
		dropOutAndConversationResult: "Lý do dừng/ Kết quả",
		ePOSstatus: "Trạng thái ePOS",
		FNACompleted: "Hoàn thành FNA",
		applicationInProgress: "Đang xử lý",
		lateSubmission: "Late Submission",
		applicationSubmitted: "Application Submitted",
	},
}
