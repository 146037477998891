import { isString } from "lodash"
import { isNullOrUndefined } from "@/utils"

const rnotnumber = /[^0-9-]+/g

export function parseNumberFromCurrencyString(value = "") {
	let number = NaN

	if (value && isString(value)) {
		const newValue = value.replace(rnotnumber, "")

		if (newValue.length) {
			number = Number(newValue)
		}
	}

	return number
}

export function toCurrencyString(value, { locale = "vi-VN" } = {}) {
	if (value === "" || isNaN(value) || isNullOrUndefined(value)) {
		return ""
	}

	return new Intl.NumberFormat(locale).format(+value)
}
