import { formatData } from "@/modules/core/composables"
import { SEARCH_TYPES } from "@/modules/dashboard/utils/constant"

export default function paramsToFetchPolicies({
	userLoginIsFrontlineAndExpertDept,
	searchTypeSelected,
	filterData,
	limitPageSize,
	currentPage,
	mAgentCodes,
	branchesSelected,
	policyStatusList,
	overridePageSize,
	overrideCurrentPage,
	overridePoIdFromQuery,
}) {
	/** Only submit Agent | Branch basing on selected by type
					* Exception: FrontLine sale + newTIS: branch = []| agentCode = agentCode of LoginUser
		 * Other roles are applied
		 * If search type is AGENT -> branchCode = [] or null
					* agentCode = agentCode selected

		 * If SearchType === BRANCH -> agentCode = [] or null
					* branch = userLoginBranch
		 */

	/** BRANCH CODE needs to send:
	 * 1/ branch === "all" -> send all branch list -> format: [] || null
	 * 2/ branch !== "all" -> send branches -> ["DDA", "AAD"...]
	 */

	const mapDataBranchAndAgents = {
		branchCode: [],
		agentCode: mAgentCodes,
	}

	if (!userLoginIsFrontlineAndExpertDept && searchTypeSelected === SEARCH_TYPES.BRANCH) {
		mapDataBranchAndAgents.branchCode = branchesSelected
		mapDataBranchAndAgents.agentCode = []
	}

	const existencePoId = filterData?.customerIdentify && filterData?.customerIdentify?.trim()
	const mPoId = overridePoIdFromQuery || existencePoId || null

	const params = {
		poId: mPoId,
		poDobFrom: filterData?.createdAt?.dateType === "customerDob" ? formatData(filterData?.createdAt?.value?.[0], "MM-DD") : null,
		poDobTo:
			filterData?.createdAt?.dateType === "customerDob"
				? filterData?.createdAt?.value?.[1]
					? formatData(filterData?.createdAt?.value?.[1], "MM-DD")
					: null
				: null,

		policyNo: filterData?.contractNo?.trim() || "",
		policyStatus: filterData?.contractStatus ? policyStatusList : null,

		submissionDateFrom: filterData?.createdAt?.dateType === "submissionDate" ? filterData?.createdAt?.value?.[0] : null,
		submissionDateTo: filterData?.createdAt?.dateType === "submissionDate" ? filterData?.createdAt?.value?.[1] : null,

		passFreeLookDateFrom: filterData?.createdAt?.dateType === "passFreeLookDate" ? filterData?.createdAt?.value?.[0] : null,
		passFreeLookDateTo: filterData?.createdAt?.dateType === "passFreeLookDate" ? filterData?.createdAt?.value?.[1] : null,

		paidToDateFrom: filterData?.createdAt?.dateType === "paidToDate" ? filterData?.createdAt?.value?.[0] : null,
		paidToDateTo: filterData?.createdAt?.dateType === "paidToDate" ? filterData?.createdAt?.value?.[1] : null,

		issuanceDateFrom: filterData?.createdAt?.dateType === "issuedDate" ? filterData?.createdAt?.value?.[0] : null,
		issuanceDateTo: filterData?.createdAt?.dateType === "issuedDate" ? filterData?.createdAt?.value?.[1] : null,
		pageSize: overridePageSize || limitPageSize,
		pageNumber: overrideCurrentPage || currentPage - 1, // TCB api count from 0

		agentCode: mapDataBranchAndAgents?.agentCode,
		branchCode: mapDataBranchAndAgents?.branchCode,
	}

	return params
}
