import { clearSessionTimeout } from "@/setups/session-timeout"

export default {
	addToastMessage({ commit }, { type, content, cb, delay = 10000, preventAutoClose = false }) {
		const key = new Date().getTime() + ~~(Math.random() * 100000)
		const obj = {}
		obj[key] = { type, content, key, cb }
		commit("addToastMessage", obj)
		// Automatically remove messages after a few seconds
		if (preventAutoClose === false) {
			const timeout = setTimeout(() => {
				commit("removeToastMessage", key)
				clearTimeout(timeout)
			}, delay)
		}
	},
	removeToastMessage({ commit }, payload) {
		commit("removeToastMessage", payload)
	},
	setTenantId({ commit }, payload) {
		commit("setTenantId", payload)
	},
	setAccessToken({ commit }, payload) {
		commit("setAccessToken", payload)
	},
	setTokenExpired({ commit }, payload) {
		commit("setTokenExpired", payload)
	},
	setClientId({ commit }, payload) {
		commit("setClientId", payload)
	},
	logout({ commit }) {
		clearSessionTimeout()
		commit("logout")
	},
	setMe({ commit }, payload) {
		commit("setMe", payload)
	},
	setAppConfig({ commit }, payload) {
		commit("setAppConfig", payload)
	},
	setLeadGCM({ commit }, payload) {
		commit("setLeadGCM", payload)
	},
}
