/* eslint-disable no-console */
import router from "./router"
import store from "./store"
import createI18n from "@/i18n.js"

const i18n = createI18n()

const fetchConfig = async ({ tenantId, clientId }) => {
	try {
		const config = await import(`@/tenants/${tenantId}/configs/${clientId}.js`)
		return config.default
		// eslint-disable-next-line no-empty
	} catch (error) {
		console.groupCollapsed("%c Tenant config file does not exist", "padding: 1px 6px 1px 0px; background: yellow; color: black")
		console.log(`tenantId: ${tenantId}`)
		console.log(`clientId: ${clientId}`)
		console.log(error)
		console.groupEnd()
		return {}
	}
}

const initializeVariants = async ({ tenantId, filename }) => {
	let defaultVariants
	let tenantVariants
	try {
		defaultVariants = await import("@/setups/variants/")
		defaultVariants = defaultVariants?.variants
		// eslint-disable-next-line no-empty
	} catch (error) {
		console.groupCollapsed("%c Default variant file does not exist", "padding: 1px 6px 1px 0px; background: yellow; color: black")
		console.log(`tenantId: ${tenantId}`)
		console.log(error)
		console.groupEnd()
		return defaultVariants
	}

	try {
		tenantVariants = await import(`@/tenants/${tenantId}/variants/${filename}`)
		tenantVariants = tenantVariants?.variants
		const dynamicKeys = Object.keys(tenantVariants)
		// override default variants based on passed in variant from tenant
		// example: if tenant variant has JButton object with "primary-lg" variant. It will only override the "primary-lg" variant in JButton
		dynamicKeys.map((key) => (defaultVariants[key] = { ...defaultVariants[key], ...tenantVariants[key] }))
		return defaultVariants
	} catch (error) {
		console.groupCollapsed("%c Tenant variant file does not exist", "padding: 1px 6px 1px 0px; background: yellow; color: black")
		console.log(`tenantId: ${tenantId}`)
		console.log(error)
		console.groupEnd()
		return defaultVariants
	}
}

// eslint-disable-next-line no-unused-vars
const initializeRoutes = async ({ routes = [] }) => {
	routes.map((route) => router?.addRoute(route))
}

const initializeTheme = async ({ tenantId, filename }) => {
	try {
		// only need to import tenant theme
		// to avoid globalTheme overrides tenant theme
		await import(`@/tenants/${tenantId}/assets/${filename}`) // duplicate variables will override existing
	} catch (error) {
		console.groupCollapsed("%c Tenant theme file does not exist", "padding: 1px 6px 1px 0px; background: yellow; color: black")
		console.log(`tenantId: ${tenantId}`)
		console.log(error)
		console.groupEnd()
	}
}

const initializeLocales = async ({ tenantId, filename, i18n }) => {
	let tenantLocales
	try {
		tenantLocales = await import(`@/tenants/${tenantId}/locales/${filename}`)
		tenantLocales = tenantLocales?.default
		const availableLocales = i18n?.global?.availableLocales || []
		// go through each locale and set overrides and new tenant locales
		availableLocales.map((locale) => {
			const dynamicKeys = Object.keys(tenantLocales?.[locale]) || []
			const tenantMessages = tenantLocales?.[locale]
			const defaultMessages = i18n.global.messages.value[locale]
			dynamicKeys.map((key) => (defaultMessages[key] = { ...defaultMessages[key], ...tenantMessages[key] }))
			i18n.global.setLocaleMessage(locale, defaultMessages)
		})
		// eslint-disable-next-line no-empty
	} catch (error) {
		console.groupCollapsed("%c Tenant locale file does not exist", "padding: 1px 6px 1px 0px; background: yellow; color: black")
		console.log(`tenantId: ${tenantId}`)
		console.log(error)
		console.groupEnd()
	}
}
const initializeTenant = async ({ tenantId, clientId }) => {
	console.log("%c Initializing", "padding: 1px 6px 1px 0px; background: green; color: white")
	const config = await fetchConfig({ tenantId, clientId })

	const themeFilename = config?.theme_filename
	const variantFilename = config?.variant_filename
	const routes = config?.routes
	const localeFilename = config?.locale_filename

	initializeTheme({ tenantId, filename: themeFilename })
	initializeRoutes({ routes })
	initializeLocales({ tenantId, filename: localeFilename, i18n })

	// save settings, tenantId, clientId in store
	store.commit("setTenantSettings", config)
	store.dispatch("setTenantId", tenantId)
	store.dispatch("setClientId", clientId)

	return { variants: await initializeVariants({ tenantId, filename: variantFilename }), i18n }
}

export { initializeTenant, i18n }
