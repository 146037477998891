<template>
	<button class="relative" v-click-outside="onClickOutside" @focus="onFocus($event)">
		<JBox
			class="relative w-full text-c0-500 bg-cWhite rounded-md leading-tight border border-c0-300 pl-5 pr-2 cursor-default h-10"
			@click="show"
		>
			<div v-if="hasRemoveItem" class="flex flex-wrap w-full">
				<JFlex v-if="!selected" class="flex flex-row justify-between w-full py-1 m-1">
					<JText class="flex-1 truncate">{{ placeholder }}</JText>
					<JIcon class="text-cBlack" icon="ChevronDown" />
				</JFlex>
				<div v-else class="flex flex-row justify-between w-full py-1 m-1">
					<span>{{ selectedLabel }}</span>
					<span class="cursor-pointer text-c0-300" @click.stop="removeTag">&times;</span>
				</div>
			</div>
			<JFlex v-else class="h-full items-center">
				<JText :class="['flex flex-start flex-1 truncate', disabled ? 'text-c0-500' : 'text-c0-600']">{{
					selectedLabel ? selectedLabel : placeholder
				}}</JText>
				<JIcon class="text-cBlack" icon="ChevronDown" />
			</JFlex>
		</JBox>
		<!-- Search Select Dropdown !-->
		<JFlex
			v-show="isActive"
			class="mt-1 w-full absolute z-10 shadow-5 rounded-md flex-col overflow-hidden bg-cWhite"
			:style="dropdownStyles"
		>
			<JBox class="px-4 py-2 bg-c0-200">
				<JInputText
					ref="inputSearch"
					v-model="inputSearchVal"
					spellcheck="false"
					@input="onInputSearch"
					:placeholder="searchPlaceholder"
				/>
			</JBox>
			<JBox class="overflow-y-auto">
				<JBox class="text-center mt-2" v-show="isSearchLoading">
					<JFlex class="py-2 px-4 inline-flex items-center">
						<JSpinner variant="secondary" type="dots" />
					</JFlex>
				</JBox>
				<ul class="pb-2 pt-2 text-base overflow-y-auto overflow-x-hidden" v-if="renderingOptions.length">
					<template v-for="(option, optionIdx) in renderingOptions" :key="optionIdx">
						<li
							class="text-gray-900 cursor-pointer relative px-4 py-2"
							:class="selected?.[valueKey] === option[valueKey] ? 'bg-c0-400 text-cWhite' : 'hover:bg-c0-200'"
							@click.prevent="select(option)"
						>
							<div class="flex items-center">
								<span class="block truncate">{{ option[nameKey] }}</span>
							</div>
						</li>
					</template>
				</ul>
				<template v-if="!renderingOptions.length && inputSearchVal.length">
					<JBox class="text-center py-2">
						<JText class="text-c0-500">{{ searchResultNothingText }}</JText>
					</JBox>
				</template>
			</JBox>
		</JFlex>
	</button>
</template>

<script setup>
import { watch, ref, computed, nextTick } from "vue"
import { useI18n } from "vue-i18n"
import { isFunction, debounce } from "lodash"
import Fuse from "fuse.js"

const props = defineProps({
	modelValue: {
		type: [String, Array],
	},
	placeholder: {
		type: String,
		default: "Please select",
	},
	options: {
		type: Array,
		default: () => [],
	},
	valueKey: {
		type: String,
		default: "value",
	},
	nameKey: {
		type: String,
		default: "label",
	},
	selectedNameKey: {
		type: String,
		default: "label",
	},
	searchPlaceholder: String,
	searchKeys: {
		type: Array,
		default: () => [],
	},
	maxHeightDropdown: {
		type: String,
		default: "300px",
	},
	fetchItems: {
		type: Function,
	},
	searchTimeout: {
		type: Number,
		default: 250,
	},
	isDisabledSearch: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	hasRemoveItem: {
		type: Boolean,
		default: false,
	},
	isSearchLoading: {
		type: Boolean,
		default: false,
	},
})
const emit = defineEmits(["update:modelValue", "search", "show", "hide"])
const { t } = useI18n()
const inputSearch = ref(null)
const isActive = ref(false)
const isLoading = ref(false)
const inputSearchVal = ref("")
const options = ref(props.options)
const renderingOptions = ref(options.value)

const selected = computed({
	get: () => {
		if (Array.isArray(props.modelValue)) {
			return props.modelValue
		}
		return renderingOptions.value.find((x) => x[props.valueKey] === props.modelValue)
	},
	set: (val) => {
		if (Array.isArray(props.modelValue)) {
			const selectedOption = renderingOptions.value.filter((x) => x[props.valueKey] === val[props.valueKey])
			emit("update:modelValue", selectedOption)
		} else emit("update:modelValue", val?.[props.valueKey])
	},
})

const selectedLabel = computed(() => {
	if (Array.isArray(props.modelValue)) {
		if (!selected.value?.length) return ""
		const value = selected.value[0]
		return value[props.selectedNameKey ? props.selectedNameKey : props.nameKey]
	} else {
		if (!selected.value) return ""
		return selected.value[props.selectedNameKey ? props.selectedNameKey : props.nameKey]
	}
})
const dropdownStyles = computed(() => {
	return {
		maxHeight: props.maxHeightDropdown,
	}
})
const searchResultNothingText = computed(() => t("core.noCodeFound"))

function show() {
	if (props.isDisabledSearch) return

	isActive.value = true

	nextTick(() => {
		if (inputSearch?.value?.$el?.tagName === "INPUT") {
			inputSearch.value.$el.focus()
		}
	})

	emit("show")
}

function hide() {
	isActive.value = false

	emit("hide")
}
function select(val) {
	selected.value = val

	hide()
}

function clearSearch() {
	inputSearchVal.value = ""
	renderingOptions.value = options.value
}

const onInputSearch = debounce(async () => {
	const items = await search(inputSearchVal.value)

	emit("search", items)
}, props.searchTimeout)

const removeTag = () => {
	if (props.disabled) return
	selected.value = ""

	emit("update:modelValue", "")
}

async function search(value) {
	let items = options.value

	if (isFunction(props.fetchItems)) {
		isLoading.value = true

		items = await props.fetchItems(value, items.value)

		isLoading.value = false
	} else if (value) {
		const fuse = new Fuse(items, {
			keys: [props.nameKey],
		})
		const result = fuse.search(value)

		items = result.map(({ item }) => item)
	}

	renderingOptions.value = items

	return items
}

function onFocus(e) {
	show()
}
function onClickOutside() {
	if (isActive.value) {
		hide()
	}
	if (!props.isDisabledSearch) {
		clearSearch()
	}
}
watch(
	() => props.options,
	async (newOptions) => {
		options.value = newOptions

		clearSearch()
	}
)
</script>
