import { merge } from "lodash"

function fnsPlugin(node) {
	node.context = merge(node.context, {
		bind(fn, context) {
			return fn.bind(context)
		},
		wrap(fn, ...wrapArgs) {
			return (...args) => fn(...wrapArgs, ...args)
		},
	})
}

export default fnsPlugin
