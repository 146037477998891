import UserSetting from "../UserSetting.vue"
import CreateNewUsers from "../CreateNewUsers"
import TCBScript from "../TCBScript"
import { DEFAULT_ROLES_PERMISSION } from "@/tenants/use/pagesAccessingByRolesAndPermissions"
export default [
	{
		path: "/setting",
		component: UserSetting,
		name: "UserSetting",
		props: true,
		meta: { module: "account", roles: DEFAULT_ROLES_PERMISSION },
	},
	{
		path: "/create-new-users",
		component: CreateNewUsers,
		name: "CreateNewUsers",
		props: true,
		meta: { module: "account", roles: ["admin"] },
	},
	{
		path: "/scripts",
		component: TCBScript,
		name: "TCBScript",
		props: true,
		meta: { module: "account", roles: ["admin"] },
	},
]
