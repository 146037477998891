<template>
	<JBox class="relative pl-4 ml-4">
		<JBox v-for="(item, idx) in list" :key="idx" class="relative mt-5">
			<!-- Vertical Line -->
			<JBox
				v-if="item.isShowSubNode && item.children && item.children.length > 0"
				class="absolute top-0 bg-c1-200 rounded-full"
				style="width: 2px; height: calc(100% - 2rem); top: 1.5rem; left: -1rem"
			/>
			<!-- Overlap Line -->
			<JBox
				v-if="isChild && idx === list.length - 1"
				class="absolute bg-cWhite"
				style="width: 2px; height: calc(100% - 1rem); top: 0.75rem; left: -3rem"
			/>
			<!-- Node Indicator -->
			<JBox class="absolute top-0 p-2" style="left: -30px">
				<JBox
					v-if="item.children && item.children.length > 0"
					class="cursor-pointer select-none"
					:style="getNodeIndicatorStyle(item.isShowSubNode)"
					@click="handleToggleSubNode(item)"
				>
					<JIcon icon="ChevronDown" width="16" height="16" />
				</JBox>
				<JBox v-else class="w-2 h-2 rounded-full bg-c1-800" style="margin: 2px" />
			</JBox>
			<!-- Node Line -->
			<JBox v-if="isChild" class="absolute top-0 my-3 bg-c1-200 w-4" style="height: 2px; left: -3rem" />
			<!-- Content Wrapper -->
			<JBox class="bg-c0-100 px-5 py-3 rounded-lg">
				<JText class="font-medium text-lg text-c1-800">{{ item.name }}</JText>
				<JText class="text-c0-500 mt-2">{{ item.value }}</JText>
			</JBox>
			<!-- Children Recurring -->
			<CTreeView v-if="item.isShowSubNode && item.children && item.children.length > 0" isChild :list="item.children" />
		</JBox>
	</JBox>
</template>

<script>
export default {
	name: "CTreeView",
	props: {
		list: {
			type: Array,
			required: true,
			default: () => [],
		},
		isChild: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {}
	},
	methods: {
		handleToggleSubNode(node) {
			node.isShowSubNode = !node.isShowSubNode
		},
		getNodeIndicatorStyle(isShowSubNode) {
			return isShowSubNode ? {} : { transform: "rotate(-90deg)" }
		},
	},
}
</script>
