export default {
	crm: {
		warningToSelectAnCrm: "Vui lòng chọn mã Lead/Opp phù hợp hoặc chọn tạo lead mới",
		dataNotFound: "Chưa có thông tin khách hàng trên hệ thống Techcombank. Vui lòng tìm kiếm lại hoặc tạo khách hàng mới.",
		account: "Tài khoản",
		poName: "Họ tên",
		citizenId: "CMND/CCCD/Hộ chiếu",
		phoneNumber: "Số điện thoại",
		CRM_ID: "Mã CRM",
		type: "Type",
		status: "Trạng thái",
		owner: "Lead/Opp Owner",
		createdDate: "Ngày tạo",
		createNewLead: "Tạo lead mới cho tài khoản này",
		createNewCustomer: "Tạo khách hàng mới",

		search: "Tìm kiếm",
		searchCustomer: "Tìm kiếm khách hàng",
		selectType: "Hãy lựa chọn",
		searchByCitizenId: "CMTND/CCCD",
		searchByPhoneNumber: "Số điện thoại",
		searchByCustomerId: "Mã khách hàng",
		searchByPassportId: "Hộ chiếu",

		// Lead status
		assigned: "Khởi tạo",
		working: "Chuẩn bị và tiếp cận",
		following: "Đánh giá và xác nhận nhu cầu",
		closed: "Đóng Lead",
		converted: "Được chuyển đổi",

		// Opp status
		targetedOpportunity: "Đặt mục tiêu",
		needsAnalysis: "Phân tích/ Đánh giá nhu cầu",
		solutionDesigned: "Thiết kế/ Lựa chọn giải pháp",
		solutionPresenting: "Trình bày giải pháp",
		submitApplicationDossier: "Nộp hồ sơ YCBH",
		underwriteApplication: "Thẩm định hồ sơ",
		issueContract: "Phát hành hợp đồng",
		freeLookPeriod: "Thời gian cân nhắc",
		closedWon: "Closed Won ",
		closedLost: "Closed Lost",

		warningMessageForNewCustomer: "Chưa có thông tin khách hàng trên hệ thống Techcombank. Vui lòng nhập thông tin bên dưới.",
		warningMessageCustomerDoesNotHaveLeadOrOpp:
			"Chưa có lead/opp nào được chọn. Vui lòng tạo lead trước khi hoàn thành cuộc hội thoại với khách hàng",
	},
	cs: {
		stopSearchingConfirmation: "Bạn có muốn dừng tìm kiếm?",
		editBtn: "Sửa",
	},
	product: {
		investmentPreferenceLabel: "Mức lãi suất đầu tư mong muốn",
		pleaseSelect: "Hãy lựa chọn",
		investmentPreferenceWarningMessage: "Vui lòng lựa chọn trước khi tư vấn sản phẩm",
		highInvestmentPreference: "Sản phẩm bảo hiểm với lãi suất không đảm bảo",
		lowInvestmentPreference: "Sản phảm bảo hiểm với lãi suất cam kết, thay đổi theo năm hợp đồng",
		goalTargetAmountMaxValueWarning: "Số tiền mục tiêu phải lớn hơn 0 và nhỏ hơn 100 tỷ VND",
	},
	otp: {
		otpPopupOptionsMessage:
			"Để thực hiện các thủ tục tiếp theo, khách hàng cần xác nhận đồng ý chia sẻ thông tin cá nhân và nhu cầu bảo hiểm tới đối tác Manulife Việt Nam qua mã OTP. Vui lòng chọn hình thức gửi mã OTP phù hợp nhất.",
		otpPopupOptionsMessageSub1: "Vui lòng chọn hình thức gửi mã OTP phù hợp nhất.",
		refuseToTransfer: "Từ chối",
		sendViaMail: "Email",
		sendSMS: "Tin nhắn SMS",
		confirm: "Xác nhận",
		retry: "Gửi lại mã OTP",

		// eslint-disable-next-line quotes
		missingEmailFNAFlow: 'Chưa có địa chỉ Email tại bước "Thông tin khách hàng"',
		// eslint-disable-next-line quotes
		missingEmailRBOFlow: 'Chưa có địa chỉ Email tại bước "Xác nhận thông tin"',
		// eslint-disable-next-line quotes
		missingPhoneFNAFlow: 'Chưa có thông tin số điện thoại tại bước "Thông tin khách hàng"',
		// eslint-disable-next-line quotes
		missingPhoneRBOFlow: 'Chưa có thông tin số điện thoại tại bước "Xác nhận thông tin"',

		otpConfirmationMessage: "Vui lòng nhập mã OTP đã được gửi tới khách hàng.",
		otpConfirmationMessageSub1: "Mã OTP có hiệu lực trong vòng 5 phút kể từ khi yêu cầu.",
		otpInputPlaceholder: "Nhập mã OTP",
		errorOTPaPIs: "Có lỗi xảy ra trong quá trình xử lý. Mã lỗi: {errorCode}. {errorMessageByLocale}",
		errorLimitOTPRequestSMS: "Yêu cầu gửi mã OTP qua SMS đã vượt quá {limit} lần. Vui lòng thử lại vào ngày mai!",
		otpIsExpired: " Mã OTP không đúng hoặc đã hết hiệu lực!",
		errorLimitOfVerifyOTP:
			"Xác minh OTP thất bại do nhập sai { limit } lần liên tiếp. Vui lòng thực hiện lại với giao dịch khác!",
		errorDroppedConsent:
			"Techcombank hiện chưa ghi nhận sự đồng ý của khách hàng về chia sẻ thông tin cá nhân tới đối tác Manulife.",
		otpIsInvalid: "Mã OTP không đúng hoặc đã hết hiệu lực!",
		errorLimitOTPRequestEmail: "Yêu cầu gửi mã OTP qua Email đã vượt quá {limit} lần. Vui lòng thử lại vào ngày mai!",
	},
}
