<template>
	<JFlex v-if="isReadingClaim" class="justify-center pt-10">
		<JSpinner variant="secondary-lg" type="dots" />
	</JFlex>
	<JFlex v-else class="flex-col">
		<JText v-if="!claimData">{{ t("fna.noInformation") }}</JText>
		<template v-else>
			<!-- Show the first claim, collapse the others -->
			<GroupCollapseContractInfo
				v-for="(item, index) in claimData"
				:key="index"
				:title="item.title"
				:options="item.options"
				:tick="item.ticked"
				:isTranslateTitle="false"
				@update:tick="item.ticked = $event"
				customClassOptions="grid grid-rows-4 grid-flow-col gap-x-1"
			/>
		</template>
	</JFlex>
</template>

<script>
import GroupCollapseContractInfo from "./GroupCollapseContractInfo"
import { useI18n } from "vue-i18n"
import { computed, ref, onMounted } from "vue"
import { fetcher } from "../../api/fetcher"
import { renderSystemTimeout } from "../../helpers"
import { formatData, parseJSON } from "@/modules/core/composables"
import { useContractInfoDetail } from "../../use/useContractInfoDetail"

export default {
	name: "ContractClaim",
	components: { GroupCollapseContractInfo },
	props: {
		contract: {
			type: Object,
			default: () => ({}),
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()
		const policyId = computed(() => props?.contract?.policyNo)
		const { dayMonthYearFormat, claimStatus, claimTypes } = useContractInfoDetail()
		const claimData = ref([])
		const isReadingClaim = ref(false)
		const getValueByCode = ({ status, options }) => {
			if (!status) return ""
			return options?.find((i) => i?.value === status)?.name
		}

		const readClaimData = async ({ policyNo }) => {
			const params = {
				policyNo,
			}
			const query = `
			query integrate {
				integrate(
					commandType: "getClaimInfo"
					inputJson:${JSON.stringify(JSON.stringify(params))}
				){
					errors
					status
					value
				}
			}`
			const { data } = await fetcher({
				query,
			})
			const integrateResponse = data?.integrate?.value
			const backslashRemoved = integrateResponse?.replace(/\\/g, "")
			const dataResponse = parseJSON(backslashRemoved || "{}")
			if (dataResponse?.statusCode === "timeout") {
				await renderSystemTimeout({ code: dataResponse?.statusCode, message: dataResponse?.message })
				return {
					status: "error",
					value: null,
				}
			}

			return {
				status: "success",
				value: dataResponse,
			}
		}
		async function getClaimInfo() {
			try {
				isReadingClaim.value = true
				const data = await readClaimData({ policyNo: policyId.value })

				const claimDetail = data?.value?.data?.map((_c, idx) => {
					return {
						title: `${t("dashboard.compensationCode")}: ${_c?.claimId}`,
						ticked: idx === 0,
						options: [
							// Row 1
							{
								value: _c?.claimSubmissionDate
									? formatData(_c?.claimSubmissionDate, dayMonthYearFormat.value)
									: _c?.claimSubmissionDate,
								customOrderList: t("dashboard.dateApplyAClaim"),
							},
							{
								value: formatData(getValueByCode({ status: _c?.claimType, options: claimTypes.value })),
								customOrderList: t("dashboard.compensationType"),
							},
							{
								value: formatData(getValueByCode({ status: _c?.claimStatus, options: claimStatus.value })),
								customOrderList: t("dashboard.claimStatus"),
							},
							{ value: formatData(_c?.declineReason), customOrderList: t("dashboard.claimReason") },
							// Row 2
							{
								value: _c?.docRequestedDate ? formatData(_c?.docRequestedDate, dayMonthYearFormat.value) : _c?.docRequestedDate,
								customOrderList: t("dashboard.documentRequestedDate"),
							},
							{
								value: _c?.docReceivedDate ? formatData(_c?.docReceivedDate, dayMonthYearFormat.value) : _c?.docReceivedDate,
								customOrderList: t("dashboard.documentReceivedDate"),
							},
							{
								value: _c?.decisionDate ? formatData(_c?.decisionDate, dayMonthYearFormat.value) : _c?.decisionDate,
								customOrderList: t("dashboard.approvedOrDeclinedDate"),
							},
							{
								value: _c?.notiDate ? formatData(_c?.notiDate, dayMonthYearFormat.value) : _c?.notiDate,
								customOrderList: t("dashboard.notificationDate"),
							},
							// Row 3
							{
								value: _c?.claimEventDate ? formatData(_c?.claimEventDate, dayMonthYearFormat.value) : _c?.claimEventDate,
								customOrderList: t("dashboard.firstEventDateOfClaim"),
							},
							{
								value: _c?.firstSubmissionMethod,
								customOrderList: t("dashboard.firstSubmissionMethod"),
							},
							{
								value: _c?.planCode,
								customOrderList: t("dashboard.planCode"),
							},
						],
					}
				})

				claimData.value = claimDetail
			} catch (e) {
				console.error(e)
			} finally {
				isReadingClaim.value = false
			}
		}
		onMounted(async () => {
			await getClaimInfo()
		})
		return {
			t,
			claimData,
			isReadingClaim,
		}
	},
}
</script>
