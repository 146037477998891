import getTokenAuth from "./utils/getTokenAuth"
import getEnv from "@/utils/getEnv"
export function makeAxiosGraphQLFetcher({
	// Required
	url,
	axios,

	// Optional but recommended
	getLocale = () => "", // can be customised so that it will always reach e.g. to store
	getToken = () => "", // can be customised so that it will always reach e.g. to localStorage

	// Optional
	timeout = 90000,
	headers = {},

	// What is acceptable status code
	// Everything between 200 and 403 inclusive is ok for CoverGo APIs
	minResponseStatusInclusive = 200,
	maxResponseStatusInclusive = 403,

	// Mostly for development, will show queries, variables and headers in the console
	debug = false,
}) {
	if (!axios) throw new Error("Axios dependency is required as a parameter")

	// Create axios instance for CoverGo GraphQL endpoint
	// Opinionated instance
	const coverGoGraphQL = axios.create({
		baseURL: url,
		method: "post",
		timeout,

		// Default headers needed for GraphQL endpoint
		headers: {
			"Content-Type": "application/json",
			...headers,
		},

		validateStatus: function (status) {
			return status >= minResponseStatusInclusive && status < maxResponseStatusInclusive // everything between 200 and 404 is ok for CoverGo APIs
		},
	})

	let _retry = false

	const caller = ({ query = null, variables = null, locale = null }) => {
		const headers = {}
		locale = locale || getLocale() // if consumer passes locale directly into fetcher, it overrides getLocale
		const token = getTokenAuth()
		if (locale) headers["Accept-Language"] = locale
		if (token && token.length > 20) headers.Authorization = `Bearer ${token}` // append this header only if token exists otherwise can cause weird issues with API
		// Login UAT (azure login as default) if (token) headers.Authorization = `Bearer ${token}`
		return new Promise((resolve, reject) => {
			const requestId = Math.random().toString().substr(2, 4)

			if (debug) {
				/* eslint-disable */
				console.groupCollapsed(`%c CoverGo Request ${requestId}`, "padding: 1px 6px 1px 0px; background: #0256ab; color: white")
				console.log(query)
				console.log(JSON.stringify(variables))
				console.log(variables)
				console.log(JSON.stringify(headers))
				console.groupEnd()
				/* eslint-enable */
			}
			coverGoGraphQL({ data: { query, variables }, headers })
				.then(async (response) => {
					if (debug) {
						/* eslint-disable */
						console.groupCollapsed(
							`%c CoverGo Response ${requestId}`,
							"padding: 1px 6px 1px 0px; color: #0256ab; background: white"
						)
						console.log(response)
						console.groupEnd()
						/* eslint-enable */
					}
					if (
						response?.data?.errors?.find(
							(error) => error?.extensions?.code === "authorization" && error?.message?.includes("invalid or expired token")
						) &&
						!_retry
					) {
						_retry = true
						const accessToken = getTokenAuth()
						if (!accessToken) {
							localStorage.setItem(getEnv("VUE_APP_TOKEN_KEY"), null)
							resolve({})
						}
						const retryData = await caller({ query, variables })
						resolve(retryData)
					}

					// Axios returns response.data.data, we want consumer to access directly response.data no response.data.data
					resolve(response?.data)
				})
				.catch((error) => {
					// eslint-disable-next-line no-console
					console.error("Axios error during fetching", error)
					if (error?.code === "ECONNABORTED") {
						resolve({ code: "timeout", message: error?.message })
					}
					// From Axios docs:
					// if (error.response) // The request was made and the server responded with a status code that falls out of the defined range
					// else if (error.request) The request was made but no response was received
					// else Something happened in setting up the request that triggered an Error

					// CoverGo expects fetcherErrorCodeResolver function that provides SERVER or NETWORK error codes
					// When error.status is undefined, it was most likely NETWORK error
					// When error.status is out of CoverGo defined range, it was most likely SERVER error
					// eslint-disable-next-line prefer-promise-reject-errors
					reject({
						fetcherErrorCodeResolver: () =>
							error?.status ? { code: "SERVER", message: error?.message } : { code: "NETWORK", message: error?.message },
					})
				})
		})
	}
	return caller
}
