/**
 * This role of manager by user login account -> get from permission groups name
 */

export const USER_ROLES = {
	DEFAULT: "default", // sale
	SECURITY: "security",
	MANAGER: "manager",
	EXECUTIVE: "executive",
	ADMIN: "admin",
	OPERATION: "operation",
} // placed by permission name

export const MANAGER_ROLE_BY_PERMISSION_GROUPS = [USER_ROLES.MANAGER, USER_ROLES.EXECUTIVE]

export const REGIONAL_MANAGER = "regionalManager"
export const AREA_MANAGER = "areaManager"
export const BRANCH_MANAGER = "branchManager"
export const HO_MANAGER = "hoManager"
export const FRONTLINE_LEAD_RBOSRBO = "RBO.SRBO"
export const FRONTLINE_LEAD_TISPRM = "TIS.PRM"

export const SUPERVISOR_DEPT = [REGIONAL_MANAGER, AREA_MANAGER, BRANCH_MANAGER]
export const LEAD_DEPARTMENT = [FRONTLINE_LEAD_RBOSRBO, FRONTLINE_LEAD_TISPRM]

export const MANAGER_POSITIONS = [...SUPERVISOR_DEPT, ...LEAD_DEPARTMENT, HO_MANAGER]

export const MANAGER_LEAD_KEYS_BY_SOURCE = {
	SECURITY: USER_ROLES.SECURITY,
	MANAGER: USER_ROLES.MANAGER,
	EXECUTIVE: USER_ROLES.EXECUTIVE,
	HO_MANAGER,
	BRANCH_MANAGER,
	REGION_MANAGER: REGIONAL_MANAGER,
	AREA_MANAGER,
	RBO_SRBO: FRONTLINE_LEAD_RBOSRBO,
	TIS_PRM: FRONTLINE_LEAD_TISPRM,
}
export const INSURANCE_EXPERT_USER = {
	NewTIS: "NewTIS",
}
export const USERS_DOES_NOT_HAVE_REGION = [AREA_MANAGER, INSURANCE_EXPERT_USER.NewTIS]
export const USERS_EMPTY_SOURCE = [USER_ROLES.EXECUTIVE, HO_MANAGER, USER_ROLES.SECURITY]

// Sale
export const FRONTLINE_PRM = "PRM"
export const FRONTLINE_TIS = "TIS"
export const FRONTLINE_RBO = "RBO"
export const FRONTLINE_SRBO = "SRBO"
export const FRONTLINE_DRBO = "DRBO"

export const FRONTLINE_BY_SOURCE = [FRONTLINE_PRM, FRONTLINE_TIS, FRONTLINE_RBO, FRONTLINE_SRBO, FRONTLINE_DRBO]

export const RBO_SRBO_FRONT_LINE_OPTIONS = [FRONTLINE_RBO, FRONTLINE_SRBO, FRONTLINE_DRBO]

export const TIS_PRM_FRONT_LINE_OPTIONS = [FRONTLINE_PRM, FRONTLINE_TIS]

export const USER_SOURCE_TO_VIEW_POLICY_MANAGEMENT = [
	...FRONTLINE_BY_SOURCE,
	...LEAD_DEPARTMENT,
	...SUPERVISOR_DEPT,
	INSURANCE_EXPERT_USER.NewTIS,
	HO_MANAGER,
]

export const DEPT_TYPES = {
	FRONTLINE: "frontLine",
	LOCATION_MANAGER: "locationManager",
	SUPERVISOR: "supervisor",
}

export const SEARCH_TYPES = {
	AGENT: "agent",
	BRANCH: "branch",
}
