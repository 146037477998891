import store from "@/store"

function logout() {
	store.dispatch("logout")
}

export function defaultErrorHandler({ error = {}, $t }) {
	const { code, userMessage } = error

	if (code === "NETWORK_OR_SERVER") {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: $t("errors.network"),
			},
		})
		return
	}

	if (code === "AUTH_INVALID_TOKEN") {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: $t("errors.token"),
			},
		})

		setTimeout(() => {
			logout()
		}, 1500)
		return
	}

	if (code === "AUTH_INSUFFICIENT_PERMISSIONS") {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: $t("errors.permission"),
			},
		})
		return
	}

	if (code === "QUERY") {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: `${$t("errors.query")} ${userMessage}`,
			},
		})
		return
	}

	if (error.type === "dynamicTemplates") {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: $t("errors.dynamicTemplatesQuery"),
			},
		})
		return
	}

	if (code === "invalid_grant") {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: userMessage.includes("not active") ? userMessage : $t(`auth.${userMessage}`),
			},
		})
		return
	}

	if (code === "invalid_de/reactivate_user") {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: `${userMessage}`,
			},
		})
		return
	}

	if (code === "invalid_email") {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: "Email is invalid",
			},
		})
		return
	}
	store.dispatch("addToastMessage", {
		type: "error",
		content: {
			type: "message",
			text: $t("errors.general"),
		},
	})
}
