<template>
	<JBox class="mb-8">
		<JFlex class="justify-between items-center flex-wrap mb-3">
			<JHeadline as="h2" variant="h2" class="text-c1-800 pr-4">
				{{ $t("policy.insured") }}
			</JHeadline>
			<JButton v-if="isEditable" variant="primary" iconPrefix="plus-circle" @click="handleClickAddInsured()">
				{{ $t("policy.addInsured") }}
			</JButton>
		</JFlex>
		<JBox v-for="(insured, index) in insuredList" :key="insured.id" class="mb-8 text-c1-800">
			<CEntityCard
				:entity="insured"
				:typename="insured.__typename"
				:title="`Insured ${index + 1}`"
				:isEditable="isEditable"
				@update:credentials="$emit('update')"
			>
				<template #footer>
					<JButton v-if="isEditable" class="mt-8 mx-5" variant="warning-sm" iconPrefix="trash" @click="removeInsured(insured)">
						{{ $t("policy.removeInsured") }}
					</JButton>
				</template>
			</CEntityCard>
		</JBox>
		<JBox v-if="insuredList.length === 0" variant="card-1" class="mt-4 text-c0-500">
			<JText class="pl-5">{{ $t("policy.noDataHere") }}</JText>
		</JBox>
	</JBox>

	<!-- Modal Add Entity -->
	<teleport to="#layer2">
		<JModalSimple :isVisible="isModalOpen" @overlay-click="handleModalCancel">
			<!-- Modal Header -->
			<JBox>
				<JText class="text-c0-300 font-medium">
					{{ $t("policy.addNew") }}
				</JText>
				<JHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
					{{ $t("policy.insured") }}
				</JHeadline>
			</JBox>

			<!-- Picker -->
			<CEntityPicker :allowedEntityTypes="['individual', 'company', 'internal']" @selected-entity="cacheEntity" />

			<!-- Modal actions -->
			<JFlex>
				<JButton class="mr-2 flex items-center" variant="primary" @click="handleModalConfirm">
					{{ $t("policy.confirm") }}
				</JButton>
				<JButton class="ml-2" variant="tertiary-outline" @click="handleModalCancel">
					{{ $t("policy.cancel") }}
				</JButton>
			</JFlex>
		</JModalSimple>
	</teleport>
</template>
<script>
import { apiUpdatePolicy, apiUpdateCase } from "@covergo/cover-composables"
import { fetcher } from "../api/fetcher"
import { handleErrorForUser } from "../api/handleErrorForUser"
export default {
	name: "CInsuredCard",

	props: {
		insuredOf: {
			type: String,
			required: true,
			validation(val) {
				return ["policy", "case"].includes(val)
			},
		},

		parentId: {
			type: String,
			required: true,
		},

		insuredList: {
			type: Array,
			default: () => [],
		},

		isEditable: {
			type: Boolean,
			default: false,
		},
	},

	emits: ["update"],

	data() {
		return {
			isModalOpen: false,
			entityCache: null,
		}
	},

	computed: {
		insuredIds() {
			return this.insuredList.map((insured) => insured?.id || "")
		},

		apiKeychain() {
			return {
				policy: {
					api: apiUpdatePolicy,
					idType: "policyId",
				},
				case: {
					api: apiUpdateCase,
					idType: "caseId",
				},
			}
		},
	},

	methods: {
		handleClickAddInsured() {
			this.isModalOpen = true
		},

		/** Entity Picker Actions */
		cacheEntity(event = null) {
			this.entityCache = event
		},

		async update(insuredIds) {
			const { api, idType } = this.apiKeychain[this.insuredOf]
			const variables = {
				[idType]: this.parentId,
				input: { insuredIds },
			}
			const { error } = await api({ variables, fetcher })
			if (error) handleErrorForUser({ error, $t: this.$t })
			return { error }
		},

		async addInsured() {
			const insuredIds = [...this.insuredIds, this.entityCache?.id] || null
			const { error } = await this.update(insuredIds)
			if (error) return

			this.$emit("update")
			this.addToast("success", this.$t("policy.addInsuredSuccess"))
		},

		async removeInsured(targetInsured) {
			const insuredIds = this.insuredIds.filter((id) => id !== targetInsured.id)
			const { error } = await this.update(insuredIds)
			if (error) return

			this.$emit("update")
			this.addToast("success", this.$t("policy.removeInsuredSuccess"))
		},

		/** Modal Actions */
		async handleModalConfirm() {
			await this.addInsured()
			this.handleModalCancel()
		},
		handleModalCancel() {
			this.isModalOpen = false
			this.entityCache = null
		},

		/** Toast Msg */
		addToast(type, message) {
			this.$store.dispatch("addToastMessage", {
				type,
				content: {
					type: "message",
					text: message,
				},
			})
		},
	},
}
</script>
