export const splitAgentCodeValue = (textString) => {
	const initValue = { code: "", name: "" }

	if (!textString) return initValue

	if (textString.includes("-")) {
		const [code, name] = textString.split("-")
		return { code: code.trim(), name: name.trim() }
	}

	return { code: textString, name: textString }
}
