import { gql, apiTemplates, apiPromise } from "@covergo/cover-composables"
import { fetcher } from "@/modules/setting/api/fetcher.js"
import { handleErrorForUser } from "@/modules/setting/api/handleErrorForUser.js"
import getEnv from "@/utils/getEnv"

const APP_CONFIGS = "app-config"
const BRANCH_LIST = "branch-list"
const defaultFragment = gql`
	fragment result on templates {
		list {
			logicalId
			lastModifiedAt
			... on dynamicTemplate {
				name
				dynamicValues {
					valueId
					valueJsonString
				}
			}
		}
	}
`
function getValueJSONString(data) {
	if (!data) return null
	return JSON.parse(data.dynamicValues?.[0]?.valueJsonString || "{}")
}

function messageTemplate({ message }) {
	const consoleStyleSuccess = "padding: 1px 6px 1px 0px; color: #00be90; font-weight:700;"
	console.group(`${message}`, consoleStyleSuccess)
	console.groupEnd()
}

export async function fetchRatesConfig({ t }) {
	const variables = {
		where: {
			id: getEnv("VUE_APP_CONFIG_TEMPLATEID"),
		},
	}
	const { data, error } = await apiTemplates({ variables, fragment: defaultFragment, fetcher })
	if (error) {
		error.type = "dynamicTemplates"
		return handleErrorForUser({ error, $t: t })
	} else {
		messageTemplate({ message: "%c Rates config initialized successfully!" })

		const templates = data?.list
		const appConfig = templates.find((item) => item.name === APP_CONFIGS)
		return getValueJSONString(appConfig) || []
	}
}
export async function fetchOriginalBranches() {
	const variables = {
		where: {
			id: getEnv("VUE_APP_BRANCHES_TEMPLATEID"),
		},
	}

	const response = await apiPromise(apiTemplates, { variables, fragment: defaultFragment, fetcher })
	if (response?.list?.length) {
		messageTemplate({ message: "%c Branches config initialized successfully!" })

		const templates = response?.list
		const branchList = templates?.find((item) => item.name === BRANCH_LIST)
		return getValueJSONString(branchList) || []
	} else return []
}
