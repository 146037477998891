<template>
	<transition
		name="expand"
		@beforeEnter="onBeforeEnter"
		@enter="onEnter"
		@leave="onLeave"
		@leaveCancelled="onAfterLeave"
		@enterCancelled="resetStyles"
		@afterEnter="resetStyles"
		@afterLeave="onAfterLeave"
	>
		<slot />
	</transition>
</template>

<script setup>
const props = defineProps({
	type: {
		type: String,
		default: "height",
	},
})

const offsetProperty = props.type === "height" ? "offsetHeight" : "offsetWidth"

function onBeforeEnter(el) {
	el._parent = el.parentNode
	el._initialStyle = {
		transition: el.style.transition,
		overflow: el.style.overflow,
		[props.type]: el.style[props.type],
	}
}

function onEnter(el) {
	const initialStyle = el._initialStyle

	el.style.setProperty("transition", "none", "important")
	// Hide overflow to account for collapsed margins in the calculated height
	el.style.overflow = "hidden"
	const offset = `${el[offsetProperty]}px`

	el.style[props.type] = "0"

	el.style.transition = initialStyle.transition

	requestAnimationFrame(() => {
		el.style[props.type] = offset
	})
}

function onLeave(el) {
	el._initialStyle = {
		transition: "",
		overflow: el.style.overflow,
		[props.type]: el.style[props.type],
	}

	el.style.overflow = "hidden"
	el.style[props.type] = `${el[offsetProperty]}px`

	requestAnimationFrame(() => {
		el.style[props.type] = "0"
	})
}

function onAfterLeave(el) {
	resetStyles(el)
}

function resetStyles(el) {
	const size = el._initialStyle[props.type]
	el.style.overflow = el._initialStyle.overflow
	if (size != null) el.style[props.type] = size
	delete el._initialStyle
}
</script>

<style scoped lang="postcss">
.expand {
	&-enter-active,
	&-leave-active {
		transition: 0.5s cubic-bezier(0.52, 0.16, 0.24, 1);
	}

	&-move {
		transition: transform 0.5s cubic-bezier(0.25, 0.8, 0.5, 1);
	}
}
</style>
