import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock"
import Inputmask from "inputmask"
import loading from "@/directives/loading.js"
import clickOutside from "@/directives/click-outside.js"

export default (app) => {
	app.directive("loading", loading)
	app.directive("click-outside", clickOutside)

	// Scroll Lock
	app.directive("scroll-lock", {
		mounted(el, binding) {
			if (binding.value) {
				disableBodyScroll(el, { reserveScrollBarGap: true })
			}
		},
		updated(el, binding) {
			clearAllBodyScrollLocks()
			if (binding.value) {
				disableBodyScroll(el, { reserveScrollBarGap: true })
			} else {
				enableBodyScroll(el, { reserveScrollBarGap: true })
			}
		},
		unmounted(el) {
			enableBodyScroll(el, { reserveScrollBarGap: true })
		},
	})

	// Input Mask
	app.directive("mask", {
		mounted(el, binding) {
			if (binding.value) {
				Inputmask(binding.value).mask(el)
			}
		},
	})
}
