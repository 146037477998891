<template>
	<JBox class="mt-6 border-t border-c1-200 pt-6">
		<JHeadline variant="h3" as="h3">{{ action === "add" ? $t("core.addNewRule") : $t("core.updateRule") }}</JHeadline>
		<!-- Entity -->
		<JText class="mt-6 mb-2">{{ $t("core.organizationOrParticipant") }}</JText>
		<JFlex
			class="bg-cWhite text-c0-500 rounded-lg leading-tight border border-c0-300 px-5 appearance-none w-full h-10 items-center"
			@click="toggleEntityPickerModal()"
		>
			{{ selectedEntity.name || "---" }}
		</JFlex>
		<!-- Type -->
		<CFormInput
			class="mt-6"
			:label="$t('core.type')"
			componentName="JRadios"
			:options="ruleTypeOptions"
			v-model="selectedRuleTypeOption"
		/>
		<!-- If Type is ratio -->
		<CFormInput
			v-if="selectedRuleTypeOption === 'ratio'"
			class="mt-4"
			:label="$t('core.percentage')"
			componentName="JInputNumber"
			v-model="form.ratio"
		/>
		<!-- If type is flat -->
		<JFlex class="mt-4" v-if="selectedRuleTypeOption === 'flat'">
			<CFormInput
				class="mr-2"
				:label="$t('core.currencyCode')"
				componentName="JSelect"
				:options="currencyCodes"
				v-model="form.currencyCode"
			/>
			<CFormInput :label="$t('core.amount')" componentName="JInputNumber" v-model="form.amount" />
		</JFlex>
		<!-- If type is ratioBasedOnOtherRule -->
		<JFlex class="mt-4" v-if="selectedRuleTypeOption === 'ratioBasedOnOtherRule'">
			<CFormInput
				class="mr-2"
				:label="$t('core.rule')"
				componentName="JSelect"
				:options="basedOnRuleOptions"
				v-model="form.basedOnRuleId"
			/>
			<CFormInput :label="$t('core.percentage')" componentName="JInputNumber" v-model="form.ratio" />
		</JFlex>
		<JFlex class="mt-6">
			<JButton class="mr-2" @click="handleConfirm()">{{ $t("core.confirm") }}</JButton>
			<JButton variant="tertiary-outline" @click="handleCancel()">{{ $t("core.cancel") }}</JButton>
		</JFlex>
		<!-- Modal to handle enity select  -->
		<JModalSimple zIndex="100" :isVisible="showEntityModal" @overlay-click="toggleEntityPickerModal()">
			<CEntityPicker @selected-entity="handleEntitySelect($event)" :allowedEntityTypes="['organization', 'internal']" />
		</JModalSimple>
	</JBox>
</template>

<script>
import { ref, computed } from "vue"
import { useI18n } from "vue-i18n"

export default {
	props: {
		/** Either "add" or "update" */
		action: {
			type: String,
		},
		rules: {
			type: Array,
			default: () => [],
		},
		/** We use this obj to populate fields if update action is selected */
		updatePayload: {
			type: Object,
			default: () => {},
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()
		const currencyCodes = [
			{
				name: "HKD",
				value: "HKD",
			},
			{
				name: "USD",
				value: "USD",
			},
		]

		const getBaseIdFromJsonLogic = (jsonRule) => {
			let parsed = null
			try {
				parsed = JSON.parse(jsonRule)
			} catch (e) {
				console.error(e)
			}
			return parsed && parsed["*"] && parsed["*"][0] ? parsed["*"][0].var : null
		}
		const getRatioFromJsonLogic = (jsonRule) => {
			let parsed = null
			try {
				parsed = JSON.parse(jsonRule)
			} catch (e) {
				console.error(e)
			}
			return parsed && parsed["*"] && parsed["*"][1] ? parsed["*"][1] : null
		}

		/** Entity Picker */
		const showEntityModal = ref(false)
		const toggleEntityPickerModal = () => {
			showEntityModal.value = !showEntityModal.value
		}
		const selectedEntity = ref({ name: null, id: null })
		const handleEntitySelect = (entity) => {
			selectedEntity.value = entity
			toggleEntityPickerModal()
		}

		/** Form containing user inputs */
		const form = ref({
			ratio: null,
			amount: null,
			currencyCode: null,
			basedOnRuleId: null,
		})

		/** JSON Rule */
		const ruleTypeOptions = [
			{ name: t("core.percentage"), value: "ratio" },
			{ name: t("core.flatFee"), value: "flat" },
			{ name: t("core.percentageOfResultOfRule"), value: "ratioBasedOnOtherRule" },
		]
		const selectedRuleTypeOption = ref("ratio")
		const basedOnRuleOptions = computed(() => {
			return props?.rules?.map((item, index) => ({ name: `${t("core.rule")} ${index + 1}`, value: item.id }))
		})
		/** Create jsonRule based on type for api call */
		const composeJsonRule = () => {
			let jsonRule
			if (selectedRuleTypeOption.value === "ratio")
				jsonRule = `{ "*": [ {"var" : "totalPremium"}, ${parseInt(form?.value?.ratio) / 100} ]}`
			else if (selectedRuleTypeOption.value === "flat")
				jsonRule = `[${form?.value?.amount ? parseFloat(form?.value?.amount) : 0}, "${form?.value?.currencyCode}"]`
			else if (selectedRuleTypeOption.value === "ratioBasedOnOtherRule")
				jsonRule = `{ "*": [ {"var" : "${form?.value?.basedOnRuleId}"}, ${parseInt(form?.value?.ratio) / 100} ]}`
			return jsonRule
		}

		/** Clear all inputs in form */
		const clearForm = () => {
			selectedEntity.value = { name: null, id: null }
			form.value = {
				ratio: null,
				amount: null,
				currencyCode: null,
				basedOnRuleId: null,
			}
			selectedRuleTypeOption.value = "ratio"
		}
		/** Populates form using props.updatePayload */
		const populateForm = () => {
			const { amount, currencyCode, ratio, entity, jsonRule, type } = props?.updatePayload
			selectedEntity.value = entity
			selectedRuleTypeOption.value = type
			if (type === "ratio") {
				form.value = {
					ratio: ratio * 100,
					amount: null,
					currencyCode: null,
					basedOnRuleId: null,
				}
			} else if (type === "flat") {
				form.value = {
					ratio: null,
					amount,
					currencyCode,
					basedOnRuleId: null,
				}
			} else if (type === "ratioBasedOnOtherRule") {
				form.value = {
					ratio: getRatioFromJsonLogic(jsonRule) * 100,
					amount: null,
					currencyCode: null,
					basedOnRuleId: getBaseIdFromJsonLogic(jsonRule),
				}
			}
		}

		/** Action handlers */
		const handleCancel = () => {
			if (props.action === "add") return emit("add:cancel")
			else return emit("update:cancel")
		}
		const handleConfirm = () => {
			if (props?.action === "add") addRule()
			else updateRule()
		}
		const addRule = () => {
			const entityId = selectedEntity?.value?.id
			const jsonRule = composeJsonRule()
			emit("add", { input: { entityId, jsonRule } })
			clearForm()
		}
		const updateRule = () => {
			const id = props?.updatePayload?.id
			const entityId = selectedEntity?.value?.id
			const jsonRule = composeJsonRule()
			emit("update", { id, input: { entityId, jsonRule } })
			clearForm()
		}

		return {
			currencyCodes,
			getBaseIdFromJsonLogic,
			getRatioFromJsonLogic,
			showEntityModal,
			toggleEntityPickerModal,
			selectedEntity,
			handleEntitySelect,
			ruleTypeOptions,
			selectedRuleTypeOption,
			form,
			basedOnRuleOptions,
			clearForm,
			handleCancel,
			handleConfirm,
			populateForm,
		}
	},
	watch: {
		updatePayload: {
			handler() {
				if (this.action !== "update") return
				this.populateForm()
			},
			immediate: true,
			deep: true,
		},
	},
}
</script>
