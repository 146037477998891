<template>
	<div :class="variantCls?.root" @click="toggleCheckbox">
		<!-- Hidden input -->
		<input
			:id="id"
			v-model="checked"
			tabindex="-1"
			:class="variantCls?.hiddenInput"
			type="checkbox"
			:disabled="disabled"
			@focus="onFocus"
		/>

		<!-- Checkbox -->
		<div ref="div" :tabindex="disabled ? '-1' : '0'" :class="checkboxCls" @keydown.space="onSpacebarPress">
			<slot v-if="checked" :class="assets.slot">
				<CIcon icon="Checked" />
			</slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		variant: {
			type: String,
			default: "primary",
		},
		id: {
			type: String,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		indeterminate: {
			type: Boolean,
			default: false,
		},
		modelValue: {
			type: [Boolean, String, Array],
		},
		customValue: {
			type: [Boolean, String, Array],
		},
	},
	emits: ["update:modelValue", "keydown"],

	data() {
		return {
			checked: false,
		}
	},

	computed: {
		checkboxCls() {
			if (this.checked) return this.variantCls?.checkboxChecked
			else return this.variantCls?.checkbox
		},
		variantCls() {
			return this.getComponentVariants()?.el
		},
		assets() {
			return this.getComponentVariants()?.assets || {}
		},
		modelType() {
			if (typeof this.modelValue === "boolean") return "boolean"
			else if (Array.isArray(this.modelValue)) return "array"
			else return "stringOrNull"
		},
		variants() {
			return {
				primary: {
					el: {
						root: "inline-block cursor-pointer border-transparent text-c1-400",
						hiddenInput: "w-5 h-5 opacity-0 absolute",
						checkbox: "flex items-center w-5 h-5 rounded-sm border border-c0-800 cursor-pointer focus:outline-none",
						checkboxChecked:
							"flex items-center w-5 h-5  bg-c3-100 rounded-sm border-transparent text-c1-400 border border-c0-800 cursor-pointer focus:outline-none pl-1",
					},
					assets: {
						iconChecked: "&#x2713;",
						iconIndeterminate: "&#8259;",
					},
				},
				disabled: {
					el: {
						root: "inline-block cursor-pointer",
						hiddenInput: "w-0 h-0 opacity-0 absolute",
						checkbox:
							"s-pointer-events-none s-cursor-not-allowed pointer-events-none  flex items-center w-6 h-6 rounded border bg-c2-500 border-c2-500 cursor-pointer focus:outline-none focus:border-c2-400",
						checkboxChecked:
							"s-pointer-events-none s-cursor-not-allowed pointer-events-none  text-c2-500 flex items-center w-6 h-6 rounded bg-c2-500 border border-c2-500 cursor-pointer focus:outline-none focus:border-c2-400",
					},
					assets: {
						iconChecked: "&#x2713;",
						iconIndeterminate: "&#8259; ",
					},
				},
				"primary-custom-checkbox": {
					el: {
						root: "inline-block cursor-pointer border-transparent text-c1-400",
						hiddenInput: "w-6 h-6 opacity-0 absolute cursor-pointer",
						checkbox: "flex items-center w-6 h-6 rounded-sm border border-c0-800 cursor-pointer focus:outline-none",
						checkboxChecked:
							"flex items-center w-6 h-6 bg-c3-100 rounded-sm border-transparent text-c1-400 border border-c0-800 cursor-pointer focus:outline-none pl-1",
					},
					assets: {
						iconChecked: "&#x2713;",
						iconIndeterminate: "&#8259;",
					},
				},
			}
		},
	},

	watch: {
		checked: {
			handler() {
				let emittedValue = null
				switch (this.modelType) {
					case "boolean":
						emittedValue = this.checked
						break
					case "array":
						if (this.checked && !this.modelValue.includes(this.customValue)) {
							emittedValue = [...this.modelValue, this.customValue]
						} else {
							emittedValue = this.modelValue.filter((i) => i !== this.customValue)
						}
						break
					case "stringOrNull":
						emittedValue = this.checked ? this.customValue : null
						break
				}
				this.$emit("update:modelValue", emittedValue)
			},
			deep: true,
		},
		modelValue: {
			handler() {
				switch (this.modelType) {
					case "boolean":
						this.checked = this.modelValue
						break
					case "array":
						this.checked = this.modelValue.includes(this.customValue)
						break
					case "stringOrNull":
						this.checked = this.modelValue === this.customValue
						break
				}
			},
			immediate: true,
			deep: true,
		},
	},

	methods: {
		toggleCheckbox() {
			if (this.disabled) return
			this.checked = !this.checked
		},
		onSpacebarPress(e) {
			this.toggleCheckbox()
			this.$emit("keydown", e)
			/** Space on keydown will trigger scroll. We don't want that behavior */

			e.preventDefault()
		},
		onFocus() {
			this.$refs.div.focus()
		},
		getComponentVariants() {
			return this.variants[this.variant]
		},
	},
}
</script>
