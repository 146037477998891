import { isUndefined } from "lodash"

export class Selection {
	input = null

	constructor(input) {
		this.input = input
	}

	get start() {
		return this.input.selectionStart
	}

	get end() {
		return this.input.selectionEnd
	}

	get isBoundSelection() {
		return this.start === 0 || this.start === this.input.value.length
	}

	select(start, end) {
		this.input.setSelectionRange(start, !isUndefined(end) ? end : start)
	}

	selectStart() {
		this.select(0)
	}

	selectEnd() {
		this.select(this.input.value.length)
	}

	selectAll() {
		this.select(0, this.input.value.length)
	}
}

export default function useSelection() {
	function getSelection(inputElem) {
		return new Selection(inputElem)
	}

	return {
		getSelection,
	}
}
