import { BrowserUtils } from "@azure/msal-browser"

const ViewLogin = () => import(/* webpackChunkName: "group-auth" */ "../views/ViewLogin.vue")
const ViewForgotPassword = () => import(/* webpackChunkName: "group-auth" */ "../views/ViewForgotPassword.vue")
const ViewNewPassword = () => import(/* webpackChunkName: "group-auth" */ "../views/ViewNewPassword.vue")
const ViewSetPassword = () => import(/* webpackChunkName: "group-auth" */ "../views/ViewSetPassword.vue")

function checkLoginNavigation(to, from, next) {
	// don't perform navigation in iframes or popups
	if (!BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()) {
		next()
	}
}

export default [
	{
		path: "/login",
		component: ViewLogin,
		name: "ViewLogin",
		props: true,
		meta: {
			isPublic: true,
		},
		beforeEnter: checkLoginNavigation,
	},
	{
		path: "/forgot-password",
		component: ViewForgotPassword,
		name: "ViewForgotPassword",
		props: true,
		meta: {
			isPublic: true,
		},
	},
	{
		path: "/new-password",
		component: ViewNewPassword,
		name: "ViewNewPassword",
		props: true,
		meta: {
			isPublic: true,
		},
	},
	{
		path: "/reset-password",
		component: ViewNewPassword,
		name: "ViewNewPassword",
		props: true,
		meta: {
			isPublic: true,
		},
	},
	{
		path: "/verify",
		component: ViewSetPassword,
		name: "ViewSetPassword",
		props: true,
		meta: {
			isPublic: true,
		},
	},
]
