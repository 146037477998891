import { computed, getCurrentInstance, ref, watch, toRaw } from "vue"
import { get, has } from "lodash"

export function useProxiedModel(props, prop, { transformIn = (v) => v, transformOut = (v) => v } = {}) {
	const vm = getCurrentInstance()

	const isPropDefined = computed(() => {
		return has(vm?.vnode?.props, prop)
	})

	const internal = ref(get(props, prop))
	const model = computed({
		get() {
			return transformIn(toRaw(isPropDefined.value ? get(props, prop) : internal.value))
		},
		set(internalValue) {
			const newValue = transformOut(internalValue)
			const value = toRaw(isPropDefined.value ? get(props, prop) : internal.value)

			if (value !== newValue || transformIn(value) !== internalValue) {
				internal.value = newValue
				// eslint-disable-next-line no-unused-expressions
				vm?.emit(`update:${prop}`, newValue)
			}
		},
	})

	if (isPropDefined.value) {
		watch(
			() => get(props, prop),
			(value) => {
				internal.value = value
			}
		)
	}

	Object.defineProperty(model, "externalValue", {
		get: () => (isPropDefined.value ? get(props, prop) : internal.value),
	})

	return model
}
