<template>
	<FormInput
		class="c-number"
		v-bind="{
			...computedProps,
			...eventHandlers,
		}"
		type="number"
		v-model.number="model"
		ref="inputRef"
	>
		<template v-slot:prefix="{ classes }">
			<button type="button" :class="classes.prefix" @click="() => onClick(-computedProps.step)">
				<CIcon icon="Minus" width="16" height="16" />
			</button>
		</template>
		<template v-slot:suffix="{ classes }">
			<button type="button" :class="classes.suffix" @click="() => onClick(computedProps.step)">
				<CIcon icon="Plus" width="16" height="16" />
			</button>
		</template>
	</FormInput>
</template>

<script setup>
import { useAttrs } from "vue"

import useFormKitInput, { EMITS } from "@/composables/formKitInput.js"
import { propsFactory } from "@/helpers"
import { genVariantProps } from "@/composables/variant.js"
import { useProxiedModel } from "@/composables/proxiedModel.js"

import CIcon from "@/modules/core/components/CIcon"

const props = defineProps({
	context: Object,
})
const emit = defineEmits([...EMITS, "update:context._value"])
const attrs = useAttrs()

const { computedProps, inputRef, eventHandlers, setNodeValue } = useFormKitInput(
	props.context,
	{ attrs, emit },
	{
		componentName: "CNumber",
	}
)
const model = useProxiedModel(props, "context._value", {
	transformIn(value) {
		return value !== null ? String(value) : ""
	},
	transformOut(internalValue) {
		return setValue(internalValue)
	},
})

function setValue(value) {
	const { min, max } = computedProps.value
	let newValue = value !== "" ? +value : NaN
	const isNumber = !isNaN(newValue)

	if (isNumber) {
		if (newValue < min) {
			newValue = min
		} else if (newValue > max) {
			newValue = max
		}

		setNodeValue(newValue)
	} else {
		setNodeValue(null)
	}

	return model.value
}

function onClick(increment) {
	const value = +model.value

	setValue(!isNaN(value) ? value + increment : increment)
}
</script>

<script>
export default {
	genProps: propsFactory({
		...genVariantProps(),
		min: {
			type: Number,
			default: 0,
		},
		max: {
			type: Number,
			default: 99,
		},
		step: {
			type: Number,
			default: 1,
		},
	}),
}
</script>
