export default function getEnv(name) {
	/**
	 * From Jan 2023 we changed the environment of bucket to upload static files
	 * VUE_APP_SYSTEM_ENDPOINT: using for the old bucket for downloading the existing static files
	 * VUE_APP_SYSTEM_DEV_ENDPOINT: using for the new bucket for uploading and downloading static files for new features
	 */
	if (name === "VUE_APP_SYSTEM_DEV_ENDPOINT") {
		let systemEndpoint = name
		if (process.env.VUE_APP_DEV !== "cvg_dev") {
			systemEndpoint = "VUE_APP_SYSTEM_ENDPOINT"
		}
		return window?.configs?.[systemEndpoint] || process.env[systemEndpoint]
	}

	return window?.configs?.[name] || process.env[name]
}
