<template>
	<nav>
		<JFlex class="relative bg-c1-600 h-12 xl:h-16 justify-between items-center pr-6">
			<!-- Hamburger Button -->
			<JFlex
				class="w-16 h-16 ml-2 rounded-full text-cWhite flex-1 justify-start items-center select-none cursor-pointer"
				@click="toggleMenu"
			>
				<template v-if="!showMenu">
					<JIcon class="hidden sm:block" width="34" height="34" icon="Menu" />
					<JIcon class="block sm:hidden" width="25" height="25" icon="Menu" />
				</template>
				<template v-else>
					<JIcon class="hidden sm:block" v-scroll-lock="true" width="34" height="34" icon="X" />
					<JIcon class="block sm:hidden" v-scroll-lock="true" width="25" height="25" icon="X" />
				</template>
			</JFlex>
			<!-- Logo -->
			<JFlex class="justify-center my-4 cursor-pointer" @click="goHome">
				<img :src="computedLogo" router alt="Logo" :class="logoClass" :style="logoStyle" />
			</JFlex>

			<!-- Right section -->
			<JFlex class="items-center flex-1 justify-end space-x-4">
				<!-- Locale section -->
				<JFlex class="hidden sm:flex">
					<JText
						class="cursor-pointer text-c1-100"
						:class="{
							'text-cWhite': locale === 'en-US',
						}"
						@click="switchLocale('en-US')"
						>EN</JText
					>
					<JText
						class="cursor-pointer ml-4 text-c1-100"
						:class="{
							'text-cWhite': locale === 'vn',
						}"
						@click="switchLocale('vn')"
						>VN</JText
					>
					<JText class="text-cWhite ml-5 hidden lg:block">{{ username }}</JText>
				</JFlex>
				<!-- Notification -->
				<JBox v-if="!isManagerPosition" @click="isOpenModalNotification = !isOpenModalNotification" class="relative mr-4">
					<JFlex class="w-10 h-10 items-center justify-center rounded-full bg-cWhite cursor-pointer">
						<NotificationBell />
					</JFlex>
					<!-- Modal notification -->
					<JBox
						class="notification-box absolute w-auto max-w-sm top-0 bg-cWhite border-c0-100 border border-c5 shadow-2 pt-2 text-center z-50"
						:style="[statusStyle[notificationModalStyle], 'right: -77px']"
					>
						<NotificationListBox v-if="isOpenModalNotification" @viewAllNotification="viewAllNotification" />
					</JBox>
				</JBox>
				<!-- Log out section -->
				<JFlex
					class="relative w-8 h-8 sm:w-10 sm:h-10 bg-c1-800 rounded-full text-cWhite justify-center items-center select-none ml-4 cursor-pointer"
				>
					<JBox @click="openModalUserSetting">
						<JIcon class="hidden sm:block" width="24" height="24" icon="User" />
						<JIcon class="block sm:hidden" width="18" height="18" icon="User" />
					</JBox>
					<div v-if="isShowLogOut" class="overlay fixed inset-0" @click="openModalUserSetting"></div>

					<!-- Modal User setting -->
					<JBox
						v-if="isShowLogOut"
						class="absolute w-auto max-w-sm right-0 top-0 rounded-lg bg-cWhite border-c0-100 border border-c5 shadow-2 px-4 py-2 text-center z-50"
						style="min-width: 200px; margin-top: 50px"
						:style="statusStyle[logOutStyle]"
					>
						<!-- user name field -->
						<JBox class="sm:hidden relative w-auto p-2 align-center whitespace-no-wrap" @click="onHandleSetting">
							<JText class="text-c0-600">{{ username }}</JText>
							<span class="md:hidden absolute bg-c0-100" style="width: 100%; height: 1px; top: 40px; right: 5px"></span>
						</JBox>
						<!-- Setting -->
						<JBox class="relative w-auto p-2 align-center whitespace-no-wrap" @click="onHandleSetting">
							<JText class="text-c0-600 hover:text-c1-500">{{ $t("fna.userSetting") }}</JText>
							<span class="md:hidden absolute bg-c0-100" style="width: 100%; height: 1px; top: 40px; right: 5px"></span>
						</JBox>
						<!-- modal -->
						<JBox class="w-auto py-2 align-center whitespace-no-wrap" @click="onOpenLogoutModal">
							<JText class="text-c0-600 hover:text-c1-500">{{ $t("fna.logoutText") }}</JText>
							<teleport to="#layer1">
								<CLogout v-if="openModalLogout" @cancel="onOpenLogoutModal" />
							</teleport>
						</JBox>
						<span
							class="hidden md:inline-block absolute bg-c0-100"
							style="width: 90%; height: 1px; top: 50%; transform: translate(-50%, -50%)"
						></span>
					</JBox>
				</JFlex>
			</JFlex>
		</JFlex>
		<JFlex>
			<!-- Menu Wrapper -->
			<div v-if="showMenu" class="overlay fixed inset-0" @click="hideMenu"></div>
			<JBox
				class="fixed left-0 w-full sm:w-9/12 max-w-full sm:max-w-sm h-screen z-20 top-3 xl:top-4"
				:class="showMenu ? '' : 'pointer-events-none'"
			>
				<!-- Menu -->
				<JFlex
					class="absolute h-screen left-0 mr-8 flex-col bg-cWhite items-center transition-all duration-200"
					style="max-width: 500px; transform-origin: top left; box-shadow: 0 8px 16px -2px rgba(0, 0, 0, 0.1)"
					:style="menuStyle"
				>
					<JBox class="overflow-auto px-8 w-full h-full">
						<CSidebarMenu menuDirection="ltr" @click-menu-item="hideMenu">
							<JText
								:class="{
									'text-c1-600 opacity-100': locale === 'en-US',
									'text-cBlack opacity-25': locale !== 'en-US',
								}"
								class="cursor-pointer font-bold mb-4"
								@click="switchLocale('en-US')"
								>{{ $t("core.eng") }}</JText
							>
							<JText
								class="cursor-pointer font-bold"
								:class="{
									'text-c1-600 opacity-100': locale === 'vn',
									'text-cBlack opacity-25': locale !== 'vn',
								}"
								@click="switchLocale('vn')"
								>{{ $t("core.vietnamese") }}</JText
							>
						</CSidebarMenu>
					</JBox>
				</JFlex>
			</JBox>
		</JFlex>
	</nav>
</template>

<script>
import { onBeforeMount, shallowRef, computed, ref } from "vue"
import { useStore } from "vuex"
import CSidebarMenu from "./CSidebarMenu"
import CLogout from "./CLogout"
import { useFNA } from "../../fna/use/useFNA"
import { useI18n } from "vue-i18n"
import { useRoute, useRouter } from "vue-router"
import NotificationListBox from "@/modules/notification/components/NotificationListBox"
import { useContractInfoDetail } from "../../dashboard/use/useContractInfoDetail"
import NotificationBell from "@/modules/notification/components/NotificationBell"
export default {
	name: "CTopNav",
	components: { CSidebarMenu, CLogout, NotificationListBox, NotificationBell },
	emits: ["setLocale"],
	setup(props) {
		const { t } = useI18n()
		const { state: FNAState, send: FNASend } = useFNA("running")
		const store = useStore()
		const tenantId = store?.state?.tenantId
		const clientId = store?.state?.clientId
		const username = store?.state?.me.username
		const logoImg = shallowRef(null)
		const logoClass = ref(null)
		const logoStyle = ref(null)
		const logoLink = ref(null)
		const { locale } = useI18n()
		const route = useRoute()
		const router = useRouter()
		const caseId = computed(() => route.params.caseId)
		const { isManagerPosition } = useContractInfoDetail()
		const isOpenModalNotification = ref(false)
		const mainView = computed(() => router.currentRoute.value.name === "ViewFNA" || router.currentRoute.value.name === "ViewRBO")

		const decideImageToRender = async () => {
			let tenantConfig
			try {
				tenantConfig = await import(`@/tenants/${tenantId}/configs/${clientId}.js`)
				const logoConfig = tenantConfig?.default?.logo
				const fileName = logoConfig?.filename
				const image = await import(`@/tenants/${tenantId}/assets/${fileName}`)
				logoImg.value = image?.default
				logoClass.value = logoConfig?.class
				logoStyle.value = logoConfig?.style
				logoLink.value = logoConfig?.route
				// eslint-disable-next-line no-empty
			} catch (error) {
				console.groupCollapsed(
					"%c Tenant config file does not exist",
					"padding: 1px 6px 1px 0px; background: yellow; color: black"
				)
				console.log(`tenantId: ${tenantId}`)
				console.log(`clientId: ${clientId}`)
				console.log(error)
				console.groupEnd()
			}
		}
		onBeforeMount(async () => {
			await decideImageToRender()
		})
		const goHome = () => {
			if (mainView.value) {
				alert(t("core.warningMessageOfNavigation"))
			} else {
				router.push({ name: "ViewHome" })
			}
		}
		const notificationModalStyle = computed(() => {
			if (isOpenModalNotification.value) return "show"
			return "hidden"
		})
		const viewAllNotification = () => {
			if (mainView.value) {
				alert(t("core.warningMessageOfNavigation"))
			} else {
				router.push({
					name: "ViewNotificationItemList",
				})
			}
		}
		const computedLogo = computed(() => logoImg.value || "https://via.placeholder.com/64x78")
		return {
			logoImg,
			logoClass,
			username,
			logoLink,
			logoStyle,
			computedLogo,
			FNAState,
			FNASend,
			locale,
			router,
			caseId,
			goHome,
			t,
			viewAllNotification,
			notificationModalStyle,
			isOpenModalNotification,
			isManagerPosition,
			mainView,
		}
	},
	data() {
		return {
			menuHeight: 0,
			showMenu: false,
			isShowLogOut: false,
			openModalLogout: false,
			isActived: false,
		}
	},
	computed: {
		logoUrl() {
			return "https://via.placeholder.com/64x78"
		},
		menuBackgroundStyle() {
			const size = this.showMenu ? "60vh" : "0"
			return {
				width: size,
				height: "100%",
			}
		},
		menuStyle() {
			return {
				transform: `scale(${this.showMenu ? 1 : 0})`,
				opacity: this.showMenu ? "1" : "0",
				marginBottom: this.iOS ? "12rem" : "6rem",
				height: "calc(100vh)",
			}
		},
		iOS() {
			return (
				["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(navigator.platform) ||
				// iPad on iOS 13 detection
				navigator.userAgent.includes("Mac")
			)
		},
		statusStyle() {
			return {
				hidden: {
					transform: "translateY(-5%)",
					opacity: 0,
					transition: "all 0.4s",
					visibility: "hidden",
				},
				show: {
					transform: "translateY(0%)",
					opacity: 1,
					transition: "all 0.4s",
					visibility: "visible",
				},
			}
		},
		logOutStyle() {
			if (this.isShowLogOut) return "show"
			return "hidden"
		},
	},
	watch: {
		isShowLogOut() {
			this.isOpenModalNotification = false
		},
		isOpenModalNotification() {
			this.isShowLogOut = false
		},
	},
	methods: {
		toggleMenu() {
			this.showMenu = !this.showMenu
		},
		hideMenu() {
			this.showMenu = false
		},
		onOpenLogoutModal() {
			if (this.mainView) {
				alert(this.t("core.warningMessageOfNavigation"))
			} else {
				this.openModalLogout = !this.openModalLogout
				this.FNASend("CANCEL")
			}
		},
		switchLocale(locale) {
			this.locale = locale
			this.$emit("setLocale", locale)
		},
		onHandleSetting() {
			if (this.mainView) {
				alert(this.t("core.warningMessageOfNavigation"))
			} else {
				this.router.push({
					name: "UserSetting",
				})
			}
		},
		openModalUserSetting() {
			this.isShowLogOut = !this.isShowLogOut
		},
	},
}
</script>
<style>
.tcb-logo {
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0);
}
.notification-box {
	min-width: 200px;
	margin-top: 52px;
	right: -24px;
}
@media screen and (max-width: 1279px) {
	.notification-box {
		margin-top: 44px;
	}
}
</style>
