import { createRouter, createWebHistory } from "vue-router"
import { checkAuthGuard } from "./guards.js"
import auth from "@/modules/auth/router/index.js"
import dashboard from "@/modules/dashboard/router/index.js"
import user from "@/modules/user/router/index.js"
import caseManagement from "@/modules/case/router/index.js"
import fna from "@/modules/fna/router/index.js"
import pages from "@/modules/pages/router/index.js"
import { trackRouter } from "vue-gtag-next"
import account from "@/modules/account/router/index.js"
import setting from "@/modules/setting/router/index.js"
import cms from "@/modules/cms/router/index.js"
import notification from "@/modules/notification/router/index.js"
import rbo from "@/modules/rbo/router/index.js"
const routes = [
	{
		path: "/",
		redirect: "/login",
	},
	...auth,
	...dashboard,
	...user,
	...caseManagement,
	...fna,
	...rbo,
	...pages,
	...account,
	...setting,
	...cms,
	...notification,
]

const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior() {
		return {
			top: 0,
			left: 0,
		}
	},
})

checkAuthGuard(router)
trackRouter(router)

export default router
