<template>
	<JBox class="mb-8">
		<JFlex class="justify-between items-center flex-wrap mb-3">
			<JHeadline as="h2" variant="h2" class="text-c1-800 pr-4">
				{{ $t("policy.holder") }}
			</JHeadline>
			<JButton v-if="isEditable" variant="primary" iconPrefix="plus-circle" @click="handleClickAddHolder()">
				{{ $t("policy.addHolder") }}
			</JButton>
		</JFlex>

		<!-- Main Holder -->
		<JBox class="mb-8 text-c1-800">
			<CEntityCard
				:entity="mainHolder"
				:typename="mainHolder.__typename || ''"
				:title="$t('policy.mainHolder')"
				:isEditable="isEditable"
				@update:credentials="$emit('update')"
			>
				<template #footer>
					<JButton v-if="isEditable" class="mt-8 mx-4" variant="primary" iconPrefix="User" @click="handleClickChangeHolder()">
						{{ $t("policy.changeHolder") }}
					</JButton>
				</template>
			</CEntityCard>
		</JBox>

		<!-- Other Holders -->
		<JBox v-for="(holder, index) in otherHolderList" :key="holder.id" class="mb-8 text-c1-800">
			<CEntityCard
				:entity="holder"
				:typename="holder.__typename"
				:title="`${$t('policy.otherHolder')} ${index + 2}`"
				@update:credentials="$emit('update')"
			>
				<template #footer>
					<JButton class="mt-8 mx-4" variant="warning-sm" iconPrefix="trash" @click="removeHolder(holder)">
						{{ $t("policy.removeHolder") }}
					</JButton>
				</template>
			</CEntityCard>
		</JBox>

		<!-- No Holder Msg -->
		<JBox v-if="!mainHolder && holderList.length === 0" variant="card-1" class="mt-4 text-c0-500">
			<JText class="pl-5">{{ $t("policy.noDataHere") }}</JText>
		</JBox>
	</JBox>

	<!-- Modal Add Entity -->
	<teleport to="#layer2">
		<JModalSimple :isVisible="isModalOpen" @overlay-click="handleModalCancel">
			<!-- Modal Header -->
			<JBox>
				<JText class="text-c0-300 font-medium">
					<span v-if="modalMode === 'add'"> {{ $t("policy.addNew") }} </span>
					<span v-if="modalMode === 'change'"> {{ $t("policy.editFor") }} </span>
				</JText>
				<JHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
					{{ $t("policy.holder") }}
				</JHeadline>
			</JBox>

			<!-- Picker -->
			<CEntityPicker :allowedEntityTypes="['individual', 'company', 'internal']" @selected-entity="cacheEntity" />

			<!-- Modal actions -->
			<JFlex>
				<JButton class="mr-2 flex items-center" variant="primary" @click="handleModalConfirm">
					{{ $t("policy.confirm") }}
				</JButton>
				<JButton class="ml-2" variant="tertiary-outline" @click="handleModalCancel">
					{{ $t("policy.cancel") }}
				</JButton>
			</JFlex>
		</JModalSimple>
	</teleport>
</template>
<script>
import {
	apiUpdateCase,
	apiUpdatePolicy,
	apiAddOtherContractHolder,
	apiRemoveOtherContractHolder,
} from "@covergo/cover-composables"
import { fetcher } from "../api/fetcher"
import { handleErrorForUser } from "../api/handleErrorForUser"
export default {
	name: "CHolderCard",

	props: {
		holderOf: {
			type: String,
			required: true,
			validation(val) {
				return ["policy", "case"].includes(val)
			},
		},

		parentId: {
			type: String,
			required: true,
		},

		mainHolder: {
			type: Object,
			default: () => {},
		},

		otherHolderList: {
			type: Array,
			default: () => [],
		},

		isEditable: {
			type: Boolean,
			default: false,
		},
	},

	emits: ["update"],

	data() {
		return {
			modalMode: "",
			isModalOpen: false,
			entityCache: null,
			holderToRemove: {},
		}
	},

	computed: {
		otherHolderIds() {
			return this.otherHolderList.map((holder) => holder?.id || null)
		},

		apiKeychain() {
			return {
				policy: {
					create: {
						api: apiAddOtherContractHolder,
						variables: {
							policyId: this.parentId,
							entityId: this.entityCache?.id || null,
						},
					},
					update: {
						api: apiUpdatePolicy,
						variables: {
							policyId: this.parentId,
							input: {
								holderId: this.entityCache?.id || null,
							},
						},
					},
					delete: {
						api: apiRemoveOtherContractHolder,
						variables: {
							policyId: this.parentId,
							entityId: this.holderToRemove?.id || "",
						},
					},
				},

				case: {
					create: {
						api: apiUpdateCase,
						variables: {
							caseId: this.parentId,
							input: {
								otherHolderIds: [...this.otherHolderIds, this.entityCache?.id || null],
							},
						},
					},
					update: {
						api: apiUpdateCase,
						variables: {
							caseId: this.parentId,
							input: {
								holderId: this.entityCache?.id || null,
							},
						},
					},
					delete: {
						api: apiUpdateCase,
						variables: {
							caseId: this.parentId,
							input: {
								otherHolderIds: this.otherHolderIds.filter((id) => id !== this.holderToRemove?.id),
							},
						},
					},
				},
			}
		},
	},

	methods: {
		handleClickAddHolder() {
			this.modalMode = "add"
			this.isModalOpen = true
		},

		handleClickChangeHolder() {
			this.modalMode = "change"
			this.isModalOpen = true
		},

		/** Entity Picker Actions */
		cacheEntity(event = null) {
			this.entityCache = event
		},

		async addHolder() {
			const { api, variables } = this.apiKeychain[this.holderOf].create
			const { error } = await api({ variables, fetcher })

			if (error) {
				handleErrorForUser({ error, $t: this.$t })
				return
			}
			this.$emit("update")
			this.addToast("success", this.$t("policy.addHolderSuccess"))
		},

		async removeHolder(holder) {
			this.holderToRemove = holder
			const { api, variables } = this.apiKeychain[this.holderOf].delete
			const { error } = await api({ variables, fetcher })
			this.holderToRemove = {}
			if (error) {
				handleErrorForUser({ error, $t: this.$t })
				return
			}
			this.$emit("update")
			this.addToast("success", this.$t("policy.removeHolderSuccess"))
		},

		async updateHolder() {
			const { api, variables } = this.apiKeychain[this.holderOf].update
			const { error } = await api({ variables, fetcher })

			if (error) {
				handleErrorForUser({ error, $t: this.$t })
				return
			}
			this.$emit("update")
			this.addToast("success", this.$t("policy.updateHolderSuccess"))
		},

		/** Modal Actions */
		async handleModalConfirm() {
			if (this.modalMode === "add") await this.addHolder()
			if (this.modalMode === "change") await this.updateHolder()
			this.handleModalCancel()
		},

		handleModalCancel() {
			const vm = this
			this.isModalOpen = false
			this.$nextTick(() => {
				vm.modalMode = ""
				vm.entityCache = null
			})
		},

		/** Toast Msg */
		addToast(type, message) {
			this.$store.dispatch("addToastMessage", {
				type,
				content: {
					type: "message",
					text: message,
				},
			})
		},
	},
}
</script>
