<template>
	<JBox class="mb-1">
		<JHeadline variant="h4" as="h4" class="text-cSuccess-400 font-bold">{{ $t(`fna.${benefit}`) }}</JHeadline>
		<ul style="color: #003956" class="flex flex-col text-xs list-disc pl-5">
			<li
				v-for="stage in listStages"
				:key="stage"
				class="cursor-pointer select-none relative font-normal"
				style="min-width: 5rem"
			>
				<div>{{ $t(stage) }}</div>
			</li>
		</ul>
	</JBox>
	<JBox v-if="benefit === 'criticalIllnessPlus'">
		<JBox v-for="(item, index) in benefitTooltip" :key="index" class="mb-1">
			<JHeadline variant="h4" as="h4" class="text-cSuccess-400 font-bold">{{ $t(item.headline) }}</JHeadline>
			<JText style="font-size: 0.85rem">{{ $t(item.content) }}</JText>
			<ul v-if="item.options" style="color: #003956" class="flex flex-col text-xs list-disc pl-5 mb-1">
				<li
					v-for="option in item.options"
					:key="option"
					class="cursor-pointer select-none relative font-normal"
					style="min-width: 5rem"
				>
					<div>{{ $t(option) }}</div>
				</li>
			</ul>
		</JBox>
	</JBox>
</template>
<script>
import { ref } from "vue"
export default {
	name: "CIPlusSupportToolTip",
	props: ["benefit"],

	setup() {
		const listStages = ref([
			"fna.criticalIllnessEarlyStage",
			"fna.criticalIllnessMiddleStage",
			"fna.criticalIllnessLateStage",
			"fna.criticalIllnessByGender",
		])
		const benefitTooltip = ref([
			{
				headline: "fna.specialMedicalBenefit",
				content: "fna.specialMedicalBenefitPayCIPlus",
				options: ["fna.specialMedicalBenefitPay1", "fna.specialMedicalBenefitPay2"],
			},
			{
				headline: "fna.deathBenefit",
				content: "fna.deathBenefitPayCIPlus",
			},
		])
		return {
			listStages,
			benefitTooltip,
		}
	},
}
</script>
