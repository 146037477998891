<template>
	<JFlex class="flex-col justify-between" style="width: 340px">
		<!-- items -->
		<JBox v-for="(item, idx) in notificationList" :key="idx">
			<JFlex
				:class="[
					'flex-row w-full justify-start border-b-2 border-c0-100 pt-2 pb-4 px-4 cursor-pointer',
					{ 'font-bold': !item?.isChecked },
				]"
				@click="handleViewMore(item)"
			>
				<!-- Icon warning -->
				<JBox :class="['w-auto', item?.type === 'IncomingBirthday' ? 'text-c1-400' : 'text-c3-100']"
					><CIcon icon="WarningCircledOutline"
				/></JBox>
				<!-- Content -->
				<JFlex class="flex-col max-w-fit items-start text-left">
					<JFlex class="flex-row items-center">
						<!-- Date -->
						<JBox class="w-auto"><CIcon icon="Dot" /></JBox>
						<JText class="text-xs">{{ formatData(item?.createdAt, dayMonthYearFormat) }}</JText>
					</JFlex>
					<!-- Message -->
					<JText class="whitespace-normal text-sm">{{
						`${mapValueNoti(item)} ${formatData(item?.from, dayMonthYearFormat)} - ${formatDateAndSubtractDate(
							item?.to,
							dayMonthYearFormat,
							1
						)}`
					}}</JText>
				</JFlex>
			</JFlex>
		</JBox>
		<!-- Expand to show noti list -->
		<JFlex
			v-if="notificationList?.length > 0"
			class="items-center justify-center py-4 uppercase text-xs text-c0-500 font-bold cursor-pointer"
		>
			<JText @click="onExpandAllItems(item)">{{ $t("notification.clickToExpand") }}</JText></JFlex
		>
	</JFlex>
</template>
<script>
import { ref, onBeforeMount } from "vue"
import CIcon from "@/modules/core/components/CIcon"
import { useI18n } from "vue-i18n"
import { useContractInfoDetail } from "@/modules/dashboard/use/useContractInfoDetail"
import useApis from "@/modules/dashboard/use/useApis"
import { formatData } from "@/modules/core/composables"
import { formatDateAndSubtractDate } from "../helpers"
import { useRouter } from "vue-router"
import { useStore } from "vuex"
export default {
	name: "NotificationListBox",
	components: { CIcon },
	props: {},
	emits: ["viewAllNotification"],
	setup(props, { emit }) {
		const { t } = useI18n()
		const store = useStore()
		const { agentCodeOfUserLogin, dayMonthYearFormat, userBranch } = useContractInfoDetail()
		const { fetchAgentRemindersQuery, updateNotiHasRead } = useApis({})

		const limit = ref(5) // 5 newest items
		const currentPage = ref(1)
		const router = useRouter()
		const countUnreadNoti = ref(0)

		const notificationList = ref([])
		const fetchNotificationList = async () => {
			try {
				currentPage.value = 1
				const variables = {
					where: {
						skip: (currentPage.value - 1) * limit.value,
						first: limit.value,
						orderBy: {
							fieldName: "from",
							type: "DSC",
						},
						where: {
							and: [
								{
									where: {
										agentCode: agentCodeOfUserLogin.value,
									},
								},
								{
									where: {
										branchCode: userBranch || null,
									},
								},
							],
						},
					},
				}
				const dataResponse = await fetchAgentRemindersQuery(variables)
				notificationList.value = dataResponse?.list
				countUnreadNoti.value = dataResponse?.list?.filter((i) => !i?.isChecked)?.length
			} catch (e) {
				console.error(e)
			}
		}
		onBeforeMount(async () => {
			await fetchNotificationList()
		})
		const mapValueNoti = (item) => {
			if (!item) return "-"
			if (item?.type === "IncomingBirthday") return t("notification.dobReminderWeekly")
			else if (item?.type === "PolicyDueDate") return t("notification.PaymentDueReminderWeekly")
		}

		const onExpandAllItems = () => {
			emit("viewAllNotification")
		}

		const handleViewMore = async (item) => {
			await onUpdateReadItem(item)
			const query = {
				agentCode: item?.agentCode,
				type: item?.type,
				from: item?.from,
				to: item?.to,
			}
			if (item?.type === "IncomingBirthday") {
				if (router?.currentRoute?.value?.name === "ViewReminderUserDOBList") {
					router.replace({
						query,
					})
				} else {
					router.push({
						name: "ViewReminderUserDOBList",
						query,
					})
				}
			} else if (item?.type === "PolicyDueDate") {
				if (router?.currentRoute?.value?.name === "ViewPolicyPaymentDueDateList") {
					router.replace({
						query,
					})
				} else {
					router.push({
						name: "ViewPolicyPaymentDueDateList",
						query,
					})
				}
			}
		}
		const onUpdateReadItem = async (item) => {
			try {
				if (!item?.isChecked) {
					const variables = {
						update: {
							id: item?.id,
							isChecked: true,
						},
					}
					await updateNotiHasRead(variables)
					await store.dispatch("notification/setCountNotification", countUnreadNoti.value)
				}
			} catch (e) {
				console.error(e)
			}
		}
		return {
			t,
			notificationList,
			onExpandAllItems,
			formatData,
			dayMonthYearFormat,
			mapValueNoti,
			onUpdateReadItem,
			formatDateAndSubtractDate,
			handleViewMore,
		}
	},
}
</script>
