<template>
	<JBox>
		<CAccordion :title="$t('core.paymentInfo')">
			<JBox class="px-5">
				<PaymentInfo
					v-for="(item, idx) in paymentInfos"
					:key="idx"
					:paymentInfo="item"
					:parentId="parentId"
					:paymentFor="paymentFor"
					@payment:changed="emitPaymentChanged"
				/>
				<JBox v-if="!paymentInfos.length" class="text-c0-500">
					{{ $t("core.noDataHere") }}
				</JBox>
				<JBox class="mt-8">
					<JButton variant="primary-sm" iconPrefix="PlusCircle" @click="handleClickAddPayment">
						{{ $t("core.addPayment") }}
					</JButton>
				</JBox>
			</JBox>
		</CAccordion>
		<!-- Modals -->
		<teleport to="#layer2">
			<!-- Modal Add Payment -->
			<JModalSimple :isVisible="isAddModalOpen" variant="center-2xl" @overlay-click="handleCancelAddPayment">
				<!-- Modal Header -->
				<JBox class="mb-6">
					<JText class="text-c0-300 font-medium">
						{{ $t("core.addNew") }}
					</JText>
					<JHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
						{{ $t("core.payment") }}
					</JHeadline>
				</JBox>
				<!-- Modal Form -->
				<JBox class="mt-5">
					<CFormInput
						v-model="name"
						componentName="JInputText"
						:label="$t('core.name')"
						:validator="v"
						field="name"
						@input="v.name.$touch()"
					/>
					<CFormInput v-model="comment" class="mt-4" componentName="JInputText" :label="$t('core.comment')" />
					<CFormInput
						v-model="amount"
						class="mt-4"
						componentName="JInputNumber"
						type="float"
						:label="$t('core.amount')"
						:validator="v"
						field="amount"
						@input="v.amount.$touch()"
					/>
					<CFormInput
						v-model="currencyCode"
						class="mt-4"
						componentName="JSelect"
						:placeholder="$t('core.pleaseSelect')"
						:options="currencyCodeOptions"
						:label="$t('core.currencyCode')"
						:validator="v"
						field="currencyCode"
						@input="v.currencyCode.$touch()"
					/>
					<CFormInput
						v-model="method"
						class="mt-4"
						componentName="JSelect"
						:placeholder="$t('core.pleaseSelect')"
						:options="methodOptions"
						:label="$t('core.paymentMethod')"
						:validator="v"
						field="method"
						@input="v.method.$touch()"
					/>
					<CFormInput
						v-model="frequency"
						class="mt-4"
						componentName="JSelect"
						:placeholder="$t('core.pleaseSelect')"
						:options="frequencyOptions"
						:label="$t('core.paymentFrequency')"
						:validator="v"
						field="frequency"
						@input="v.frequency.$touch()"
					/>
				</JBox>
				<!-- Modal Actions -->
				<JFlex v-if="!isAddLoading" class="mt-10">
					<JButton class="mr-2 flex items-center" variant="primary" @click="handleConfirmAddPayment">
						{{ $t("core.confirm") }}
					</JButton>
					<JButton class="ml-2" variant="tertiary-outline" @click="handleCancelAddPayment">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
				<JFlex v-else class="items-center mt-6 h-10">
					<JSpinner variant="secondary-lg" type="dots" />
				</JFlex>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import { computed, reactive, toRef, toRefs } from "vue"
import { Machine } from "xstate"
import {
	apiPromise,
	apiAddPaymentInfo as addPaymentToPolicy,
	apiAddPaymentInfoToCase as addPaymentToCase,
} from "@covergo/cover-composables"
import { capitalize } from "@/modules/core/composables"
import { useMachine } from "@/modules/core/composables/useMachine"
import { useI18n } from "vue-i18n"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { fetcher } from "./../../api/fetcher"
import { handleErrorForUser } from "./../../api/handleErrorForUser"
import PaymentInfo from "./PaymentInfo"

const paymentCardMachine = {
	id: "paymentCard",
	initial: "dataListing",
	states: {
		dataListing: {
			on: {
				CREATE: "creating",
			},
		},
		creating: {
			id: "create",
			initial: "formFilling",
			states: {
				formFilling: {
					on: {
						CONFIRM_CREATE: { target: "fetching", cond: { type: "createGuard" } },
						CANCEL_CREATE: "#paymentCard.dataListing",
					},
				},
				fetching: {
					invoke: {
						id: "addPaymentService",
						src: "addPayment",
						onDone: { target: "#paymentCard.dataListing", actions: ["clearForm", "emitChanged"] },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { RETRY: "fetching" },
				},
			},
		},
	},
}

export default {
	/**
	 * Reusable component that used by policy and case module.
	 */
	name: "CPaymentCard",
	emits: ["payment:changed"],
	components: {
		PaymentInfo,
	},
	props: {
		paymentFor: {
			type: String,
			required: true,
			validation(val) {
				return ["policy", "case"].includes(val)
			},
		},
		parentId: {
			type: String,
			required: true,
		},
		paymentInfos: {
			type: Array,
			required: true,
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()

		// Initial data
		const form = reactive({
			name: "",
			comment: "",
			amount: null,
			currencyCode: "",
			method: "",
			frequency: "",
		})

		const currencyCodeOptions = [
			{ name: t("core.HKD"), value: "HKD" },
			{ name: t("core.USD"), value: "USD" },
		]

		const methodOptions = [
			{ name: t("core.CASH"), value: "CASH" },
			{ name: t("core.CREDIT_CARD"), value: "CREDIT_CARD" },
			{ name: t("core.GIRO"), value: "GIRO" },
			{ name: t("core.ATM"), value: "ATM" },
			{ name: t("core.SELF_SERVICE_PAYMENT_MACHINE"), value: "SELF_SERVICE_PAYMENT_MACHINE" },
			{ name: t("core.BANK_TRANSFER"), value: "BANK_TRANSFER" },
			{ name: t("core.CHEQUE"), value: "CHEQUE" },
		]

		const frequencyOptions = [
			{ name: t("core.ONE_TIME"), value: "ONE_TIME" },
			{ name: t("core.MONTHLY"), value: "MONTHLY" },
			{ name: t("core.QUARTERLY"), value: "QUARTERLY" },
			{ name: t("core.SEMI_ANNUALLY"), value: "SEMI_ANNUALLY" },
			{ name: t("core.ANNUALLY"), value: "ANNUALLY" },
		]

		const fn = {
			addPaymentToPolicy,
			addPaymentToCase,
		}

		const addPaymentFunction = fn[`addPaymentTo${capitalize(props.paymentFor)}`]

		// Validation
		const rules = {
			name: { required },
			amount: { required },
			currencyCode: { required },
			method: { required },
			frequency: { required },
		}

		const v = useVuelidate(rules, {
			name: toRef(form, "name"),
			amount: toRef(form, "amount"),
			currencyCode: toRef(form, "currencyCode"),
			method: toRef(form, "method"),
			frequency: toRef(form, "frequency"),
		})

		// Computed data
		const isAddModalOpen = computed(() => state.value.matches("creating"))
		const isAddLoading = computed(() => state.value.matches("creating.fetching"))

		// Methods
		function handleClickAddPayment() {
			send("CREATE")
		}

		function handleCancelAddPayment() {
			resetForm()
			send("CANCEL_CREATE")
		}

		function resetForm() {
			form.name = ""
			form.comment = ""
			form.amount = null
			form.currencyCode = ""
			form.method = ""
			form.frequency = ""
			// Reset validation
			v.value.$reset()
		}

		function handleConfirmAddPayment() {
			send("CONFIRM_CREATE")
		}

		function emitPaymentChanged() {
			emit("payment:changed")
		}

		const options = {
			services: {
				async addPayment() {
					const variables = {
						[`${props.paymentFor}Id`]: props.parentId,
						input: { ...form },
					}
					return await apiPromise(addPaymentFunction, {
						variables,
						fetcher,
					})
				},
			},
			actions: {
				clearForm() {
					resetForm()
				},
				emitChanged() {
					emitPaymentChanged()
				},
				setErrorMessage(ctx, event) {
					handleErrorForUser({ error: event?.data, $t: t })
				},
			},
			guards: {
				createGuard() {
					v.value.$touch()
					v.value.amount.$touch()
					return !v.value.$invalid
				},
			},
		}

		const { state, send } = useMachine(Machine(paymentCardMachine, options), { devTools: process.env.NODE_ENV === "development" })

		return {
			state,
			send,
			v,
			currencyCodeOptions,
			methodOptions,
			frequencyOptions,
			// Computed
			isAddModalOpen,
			isAddLoading,
			// Methods
			handleClickAddPayment,
			handleCancelAddPayment,
			handleConfirmAddPayment,
			emitPaymentChanged,
			...toRefs(form),
		}
	},
}
</script>
