<template>
	<JFlex class="flex-col lg:flex-row mt-4 lg:mt-8">
		<!-- Left Column -->
		<JBox class="lg:w-7/12 lg:pr-4 mb-8">
			<slot name="left" />
		</JBox>
		<!-- Right Column -->
		<JBox class="lg:w-5/12 lg:pl-4">
			<slot name="right" />
		</JBox>
		<JBox v-if="isLoading" v-scroll-lock="true" class="fixed z-50 top-0 left-0 w-full h-full bg-c0-900 bg-opacity-25">
			<JFlex class="w-full h-full justify-center items-center">
				<JSpinner variant="primary-lg" />
			</JFlex>
		</JBox>
	</JFlex>
</template>

<script>
export default {
	name: "CLayoutTwoCol",
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
