<template>
	<CLayout>
		<JFlex class="flex-col px-4">
			<!-- Header -->
			<CTabs
				:isVisibleMenuBar="false"
				class="mt-4 md:mt-8 text-sm"
				customClassTabItems="uppercase font-bold"
				customColorActiveTab="text-c0-800"
				:options="tabOptions"
				:activeTab="activeTab"
				@change="handleTabChange($event)"
			/>
			<!-- Components -->
			<component :is="componentToDisplay" />
		</JFlex>
	</CLayout>
</template>
<script>
import { ref, computed, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import UpdateAgentService from "../../components/contracts/UpdateAgentService"
import AgentServiceHistoryUpdated from "../../components/contracts/AgentServiceHistoryUpdated.vue"
import { useContractInfoDetail } from "@/modules/dashboard/use/useContractInfoDetail"
import { useRouter } from "vue-router"
import validateToAccessPage from "@/modules/pages/use/usePermissionToAccessPage"

export default {
	name: "ViewManageAgentCode",
	components: {
		UpdateAgentService,
		AgentServiceHistoryUpdated,
	},
	setup() {
		const { t } = useI18n()
		const router = useRouter()
		const { goToHomePageIfUserDoesNotHavePermission } = validateToAccessPage()

		const { isBranchManager } = useContractInfoDetail()
		// redirect to ViewHome if user is invalid positions
		if (!isBranchManager.value) {
			router.push({ name: "ViewHome" })
		}
		const componentToDisplay = ref("UpdateAgentService")
		const activeTab = ref("UpdateAgentService")
		const tabOptions = computed(() => [
			{
				name: "UpdateAgentService",
				label: t("dashboard.requestToChangeInfoTab"),
				component: "UpdateAgentService",
			},
			{
				name: "AgentServiceHistoryUpdated",
				label: t("dashboard.requestToChangeHistoryTab"),
				component: "AgentServiceHistoryUpdated",
			},
		])
		function handleTabChange(val) {
			componentToDisplay.value = val?.component
			activeTab.value = val?.name
		}
		onMounted(() => {
			goToHomePageIfUserDoesNotHavePermission()
		})
		return {
			activeTab,
			tabOptions,
			handleTabChange,
			componentToDisplay,
		}
	},
}
</script>
