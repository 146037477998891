<template>
	<CLayout v-loading="loaders.pageLoader">
		<JFlex class="flex-col md:flex-row w-auto h-auto pb-2 items-start px-10 pt-4">
			<JBox class="align-right">
				<JButton variant="primary-outline" iconPrefix="ChevronLeft" @click="$router.go(-1)">{{ $t("fna.back") }}</JButton>
			</JBox>
			<!-- Main -->
			<JBox
				class="w-full max-w-2xl m-auto lg:ml-40 mt-6 pt-5 pb-1 rounded-lg px-10"
				style="background: rgb(250, 252, 252); box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px"
			>
				<JFlex
					v-for="info in userInfo"
					:key="info"
					class="flex-col flex-start w-full mb-3 pb-2"
					style="border-bottom: 1px solid #e9e9e9"
				>
					<JFlex class="flex-col">
						<JFlex class="flex-row">
							<JIcon class="text-c1-400 mr-2" width="24" height="24" icon="UserGroup" />
							<JHeadline class="pointer-events-none break-words" variant="headline">{{ info.label }}</JHeadline>
						</JFlex>
						<JText class="text-md text-c0-500 mt-3 pointer-events-none break-words">{{ info.value }}</JText>
					</JFlex>
				</JFlex>
			</JBox>
		</JFlex>
	</CLayout>
</template>

<script setup>
import { apiPromise, apiMe, gql } from "@covergo/cover-composables"
import { computed, onMounted, reactive, ref } from "vue"
import { fetcher } from "@/modules/user/api/fetcher"
import { capitalize } from "@/modules/core/composables"
import { useI18n } from "vue-i18n"
import position from "@/mock/position"
import useSourceOfLoginUser from "@/modules/core/use/useUserSource.js"
import { INSURANCE_EXPERT_USER } from "@/modules/dashboard/utils/constant"
import { fetchOriginalBranches } from "@/modules/setting/use/appConfigDynamicTemplates"
import { handleErrorForUser } from "@/modules/setting/api/handleErrorForUser.js"
import useLoading from "@/composables/loading"

const { loaders, addLoader } = useLoading()
addLoader("pageLoader")

const { t, locale } = useI18n()
const form = reactive({
	me: {},
})
const originalLocationList = ref([])

const { userBranch, userPosition } = useSourceOfLoginUser()

const fragment = gql`
	fragment result on login {
		username
		email
		associatedUser {
			name
			internalCode
			source
			fields
		}
	}
`
async function getBranchList() {
	try {
		loaders.pageLoader.start()
		originalLocationList.value = await fetchOriginalBranches()
	} catch (error) {
		handleErrorForUser({ error, $t: t })
	} finally {
		loaders.pageLoader.end()
	}
}
const getMe = async () => {
	const res = await apiPromise(apiMe, { fetcher, fragment })
	form.me = JSON.parse(JSON.stringify(res))
}
onMounted(async () => {
	await getBranchList()
	await getMe()
})
const associatedUser = computed(() => form.me?.associatedUser || {})
const roleName = computed(() => {
	let result
	if (userPosition) {
		for (const pos of position) {
			const foundedPosition = pos.positions.find((item) => item.value === userPosition)
			if (foundedPosition) {
				result = foundedPosition.name
				break
			}
		}
	}

	return result ? capitalize(result) : "N/A"
})
const userInfo = computed(() => {
	const mUserBranch = originalLocationList.value?.find((item) => item.value === userBranch)
	const userFullName = associatedUser.value.name === " " ? associatedUser.value.name.trim(" ") : associatedUser.value.name
	const stringifiedAgentCode = associatedUser.value?.fields
	return [
		{ label: `${t("fna.staffId")}`, value: JSON.parse(stringifiedAgentCode || "{}")?.staffId || "N/A" },
		{ label: `${t("fna.username")}`, value: form.me.username || "N/A" },
		{ label: `${t("fna.tcbsEmailAddress")}`, value: form.me.email },
		{
			label: `${t("fna.englishFullName")}`,
			value: userFullName || "N/A",
		},
		{
			label: `${t("fna.branch")}`,
			value: mUserBranch ? (locale.value === "en-US" ? mUserBranch?.branchNameEn : mUserBranch?.branchNameVn) : "N/A",
		},
		{ label: `${t("fna.branchCode")}`, value: userBranch && userPosition !== INSURANCE_EXPERT_USER.NewTIS ? userBranch : "N/A" },
		{ label: `${t("fna.agentCode")}`, value: JSON.parse(stringifiedAgentCode || "{}")?.agentCode || "N/A" },
		{ label: `${t("fna.role")}`, value: roleName.value },
	]
})
</script>
