export const factCardMachine = {
	id: "factCard",
	initial: "dataListing",
	states: {
		dataListing: {
			id: "dataListing",
			on: {
				CREATE: "creating",
				UPDATE: "updating",
				DELETE: "deleting",
			},
		},
		creating: {
			id: "create",
			initial: "formFilling",
			states: {
				formFilling: {
					on: {
						CONFIRM_CREATE: { target: "fetching", cond: { type: "createGuard" } },
						CANCEL_ADD: "#factCard.dataListing",
					},
				},
				fetching: {
					invoke: {
						id: "addFactService",
						src: "addFact",
						onDone: { target: "#factCard.dataListing", actions: ["clearForm", "emitChanged"] },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { CONFIRM_CREATE: "fetching" },
				},
			},
		},
		updating: {
			id: "update",
			initial: "formFilling",
			states: {
				formFilling: {
					on: {
						CONFIRM_UPDATE: { target: "fetching", cond: { type: "updateGuard" } },
						CANCEL_UPDATE: "#factCard.dataListing",
					},
				},
				fetching: {
					invoke: {
						id: "updateFactService",
						src: "updateFact",
						onDone: { target: "#factCard.dataListing", actions: ["clearForm", "emitChanged"] },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { CONFIRM_UPDATE: "fetching" },
				},
			},
		},
		deleting: {
			id: "delete",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "deleteFactService",
						src: "deleteFact",
						onDone: { target: "#dataListing", actions: ["setDeleteSuccessMessage", "emitChanged"] },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { DELETE: "fetching" },
				},
			},
		},
	},
}
