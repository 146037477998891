<template>
	<FormInput
		class="c-amount"
		v-bind="{
			...computedProps,
		}"
	>
		<template v-slot:input="{ classes, inputAttrs }">
			<input
				:class="classes.input"
				:type="inputAttrs.inputType || 'text'"
				:value="model"
				@input="onInput"
				v-bind="{
					...eventHandlers,
					...inputAttrs,
				}"
				ref="inputRef"
			/>
		</template>
		<template v-slot:suffixIcon="{ classes }">
			<span :class="classes.suffixIcon">{{ $t("fna.currency") }}</span>
		</template>
		<template v-slot:suffix="{ classes }">
			<div v-if="isSuggestionsActive" class="c-amount__suggestion" :class="[classes.suffix, classes.suggestion]">
				<button
					v-for="(item, i) in suggestions"
					:key="i"
					:class="[classes.suggestionItem]"
					@mousedown="onSelectSuggestion(item)"
					class="c-amount__suggestion-item"
				>
					{{ item }}
				</button>
			</div>
		</template>
	</FormInput>
</template>

<script setup>
import { ref, computed, nextTick, useAttrs } from "vue"
import { padEnd } from "lodash"

import { toCurrencyString, parseNumberFromCurrencyString, propsFactory } from "@/helpers"

import { useProxiedModel } from "@/composables/proxiedModel.js"
import useFormKitInput, { EMITS } from "@/composables/formKitInput.js"
import { genVariantProps } from "@/composables/variant.js"
import useSelection from "@/composables/selection.js"

const props = defineProps({
	context: Object,
})
const emit = defineEmits([...EMITS, "update:context._value"])
const attrs = useAttrs()
const { computedProps, inputRef, eventHandlers, isFocused, setNodeValue } = useFormKitInput(
	props.context,
	{ attrs, emit },
	{
		componentName: "CAmount",
	}
)
const { getSelection } = useSelection()
const model = useProxiedModel(props, "context._value", {
	transformIn(value) {
		return toCurrencyString(value)
	},
	transformOut(internalValue) {
		let newValue = parseNumberFromCurrencyString(internalValue)

		if (isNaN(newValue)) {
			newValue = null
		}

		setSuggestions(newValue)
		setNodeValue(newValue)

		return newValue
	},
})
const suggestions = ref([])
const isSuggestionsActive = computed(() => isFocused.value && suggestions.value.length)

function genSugesstions(value) {
	const stringValue = String(value)
	const length = stringValue.length
	const PAD_LENGTHS = [3, 6, 9]
	const MAX_PAD = 12

	return PAD_LENGTHS.map((padLength, index) => {
		const nextPad = PAD_LENGTHS[index + 1] || MAX_PAD
		const maxPad = nextPad
		const pad = length ? length + Math.min(padLength, maxPad - length) : 0
		const val = pad > length ? padEnd(stringValue, pad, "0") : ""

		return toCurrencyString(val)
	}).filter((value) => value.length !== 0)
}

function setSuggestions(value) {
	suggestions.value = value ? genSugesstions(value) : []
}

function setInputSelection(...range) {
	const selection = getSelection(inputRef.value)

	nextTick(() => {
		selection.select(...range)
	})
}

function onInput() {
	const value = inputRef.value.value
	const currentLength = value.length
	const selection = getSelection(inputRef.value)
	const oldStart = selection.start

	model.value = inputRef.value.value

	const newLength = model.value.length
	const diffLength = newLength - currentLength
	const newSelectionStart = oldStart + diffLength

	setInputSelection(newSelectionStart)
}

function onSelectSuggestion(item) {
	model.value = item

	setInputSelection(model.value.length)
}
</script>

<script>
export default {
	genProps: propsFactory({
		...genVariantProps(),
	}),
}
</script>
