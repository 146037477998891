<template>
	<transition name="fade">
		<div v-show="isActive" class="c-loading" :class="[overlay && 'absolute flex items-center justify-center']">
			<COverlay v-if="overlay" :variant="overlay" />

			<JSpinner variant="secondary" type="circle" :delay="0" />
		</div>
	</transition>
</template>

<script>
import { defineComponent } from "vue"

import COverlay from "@/modules/core/components/COverlay.vue"
import { useProxiedModel } from "@/composables/proxiedModel"

export default defineComponent({
	name: "Loading",
	components: {
		COverlay,
	},
	props: {
		overlay: String,
		active: Boolean,
	},
	setup(props) {
		const isActive = useProxiedModel(props, "active")

		return {
			isActive,
		}
	},
})
</script>

<style>
.is-loading {
	position: relative;
}
</style>
