<template>
	<JBox v-click-outside="hideShowMore" class="relative ml-auto mr-0">
		<JBox class="cursor-pointer" @click="showMore()">
			<JIcon class="text-c0-300" icon="DotsHorizontal" />
		</JBox>
		<JBox v-if="isShowMore" class="absolute z-10 right-0 py-2 bg-cWhite rounded-lg shadow" style="top: 2rem">
			<JFlex class="items-center cursor-pointer select-none text-c0-500 px-4 py-2 hover:bg-c0-100" @click="clickEdit()">
				<JIcon class="mr-3" icon="Pencil" />
				<JText class="font-medium">{{ $t("core.edit") }}</JText>
			</JFlex>
			<JFlex class="items-center cursor-pointer select-none text-cError-500 px-4 py-2 hover:bg-c0-100" @click="clickDelete(item)">
				<JIcon class="mr-2" width="24" height="24" icon="Trash" />
				<JText class="font-medium">{{ $t("core.delete") }}</JText>
			</JFlex>
		</JBox>
	</JBox>
</template>
<script>
import { ref } from "vue"
export default {
	emits: ["edit", "delete"],

	setup(_, { emit }) {
		const isShowMore = ref(false)

		function showMore() {
			isShowMore.value = true
		}

		function hideShowMore() {
			isShowMore.value = false
		}

		function clickEdit() {
			emit("edit")
			hideShowMore()
		}

		function clickDelete() {
			emit("delete")
			hideShowMore()
		}

		return { isShowMore, showMore, hideShowMore, clickEdit, clickDelete }
	},
}
</script>
