<template>
	<JBox variant="card-1">
		<JFlex class="items-center justify-between mb-8 px-5">
			<JHeadline as="h2" variant="h2" class="flex text-c1-800">
				<JText class="text-c1-800"> {{ $t("core.documents") }} </JText>
			</JHeadline>
			<!-- Add Button -->
			<JBox>
				<JButton variant="primary-sm" iconPrefix="PlusCircle" @click="toggleAddModal(true)">
					{{ $t("core.add") }}
				</JButton>
			</JBox>
		</JFlex>

		<JBox v-if="files && files.length > 0">
			<FileItem v-for="(file, i) in files" :key="`file-${i}`" :file="file" :bucketName="bucketName" @update="getFileListing()" />
		</JBox>

		<!-- Empty Notes -->
		<JBox v-else class="px-5">
			<JText class="text-xl text-c0-300"> {{ $t("core.filesNotFound") }} </JText>
		</JBox>
	</JBox>

	<teleport to="#layer2">
		<JModalSimple variant="center-3xl" :isVisible="showAddModal" @overlay-click="toggleAddModal(false)">
			<CUploadFiles :filesOf="filesOf" :entityId="entityId" :customPath="customPath" @uploaded="getFileListing()" />
		</JModalSimple>
	</teleport>
</template>
<script>
import { ref, onMounted, watch } from "vue"
import { useI18n } from "vue-i18n"
import { fetcher } from "../../api/fetcher"
import { handleErrorForUser } from "../../api/handleErrorForUser"
import { apiFileListing } from "@covergo/cover-composables"

import FileItem from "./FileItem"
export default {
	components: { FileItem },
	props: {
		bucketName: {
			type: String,
			default: null,
		},

		prefix: {
			type: String,
			default: null,
		},

		filesOf: {
			validator(val) {
				return (
					val === "entity" ||
					val === "policy" ||
					val === "claim" ||
					val === "template" ||
					val === "templateAssets" ||
					val === "case" ||
					val === "proposals" ||
					val === "custom"
				)
			},
			required: true,
		},

		entityId: {
			type: String,
			required: true,
		},

		customPath: {
			type: String,
			default: "",
		},
	},

	setup(props) {
		const { t } = useI18n()
		const files = ref([])

		async function getFileListing() {
			const variables = {
				bucketName: props.bucketName,
				prefix: props.prefix,
			}
			const { data, error } = await apiFileListing({ variables, fetcher })
			if (error) handleErrorForUser({ error, $t: t })
			files.value = data?.objectSummaries
		}

		// Modal
		const showAddModal = ref(false)

		function toggleAddModal(option) {
			if (option) showAddModal.value = option
			else showAddModal.value = !showAddModal.value
		}

		onMounted(() => {
			getFileListing()
		})

		watch(showAddModal, (val, oldVal) => {
			if (val === false && oldVal === true) getFileListing() // Update list whenever modal's closed
		})

		return { files, getFileListing, showAddModal, toggleAddModal }
	},
}
</script>
