import provinces from "@/mock/address.json"

export default function useAddresses() {
	function normalizeProvinces(items = []) {
		return items.map((item) => ({ name: item.PROVINCENAME, value: item.PROCODET24, item }))
	}

	function normalizeDistricts(items = []) {
		return items.map((item) => ({ name: item.CITYNAME, value: item.CITYCODE, item }))
	}

	function normalizeWards(items = []) {
		return items.map((item) => ({ name: item.WARD_NAME, value: item.WARD_CODE }))
	}

	return {
		provinces,
		normalizeDistricts,
		normalizeWards,
		normalizeProvinces,
	}
}
