import { useStore } from "vuex"
import { roundFaceAmount, formatNumber, countTargetAmount } from "@/modules/fna/helpers"
import { useHTMLtoPDF } from "./useHTMLToPDF"
import { computed } from "vue"
import { useI18n } from "vue-i18n"
import getEnv from "@/utils/getEnv"

const usePdfTemplate = () => {
	const store = useStore()
	const { t } = useI18n()

	const { fetchPdfTemplate, fetchExcelTemplateData, fetchHTMLTemplate } = useHTMLtoPDF()

	const millionUnit = 1000000

	const dataType = {
		load: 0,
		tb: 1,
		h_coi: 2,
		h_lb: 3,
		h_av: 4,
		h_sv: 5,
		m_coi: 6,
		m_lb: 7,
		m_av: 8,
		m_sv: 9,
		g_coi: 10,
		g_lb: 11,
		g_av: 12,
		g_sv: 13,
	}

	const planContentVietnameseMapping = () => {
		return {
			childrenEducation: "Mục tiêu học tập cho con",
			growWealthAndAssets: "Tích lũy/đầu tư và gia tăng tài sản",
			financeUncertainty: "Bảo đảm tài chính cho gia đình",
			healthAndMedical: "Chi phí y tế",
			retirement: "Hưu trí an nhàn",
		}
	}
	function saveFactsToCase({
		goal,
		sumGoals,
		yourAccumulatedSaving,
		accumulatedSavingPercent,
		currentProtectionCoverage,
		insurancePercent,
		targetDuration,
	}) {
		const goalTarget = {
			targetAmount: countTargetAmount({
				sumGoals,
				yourAccumulatedSaving,
				accumulatedSavingPercent,
				currentProtectionCoverage,
				insurancePercent,
			}),
			targetDuration,
		}
		const answers = store.state.fna.answers

		if (goal === "childrenEducation") {
			answers.targetEducation = {
				...goalTarget,
			}
		}
		if (goal === "growWealthAndAssets") {
			answers.targetGrowWealthAndAssets = {
				...goalTarget,
			}
		}

		if (goal === "retirement") {
			answers.targetRetirement = {
				...goalTarget,
			}
		}
		if (goal === "financeUncertainty") {
			answers.targetFinance = {
				...goalTarget,
			}
		}
		if (goal === "healthAndMedical") {
			answers.targetHealthAndMedical = {
				targetAmount: goalTarget?.targetAmount,
				targetDuration: 0, // default is 0 (MVP3-Release 12 > TEC-1834)
			}
		}
		store.dispatch("fna/setAnswers", answers)
	}

	const buildNeedsAnalyticsTemplateVariables = () => {
		const selectedGoals = store.getters["fna/selectedGoals"]
		const answers = store.getters["fna/answers"]
		const sumGoals = store.getters["fna/sumGoalsWithNoCoefficient"]
		const timePerGoals = store.getters["fna/timePerGoals"]
		const customerDetails = store.getters["fna/customerDetails"]
		const dependents = selectedGoals.includes("childrenEducation") ? answers?.childrenEducation : []
		const collectCustomerInfosData = computed(() => JSON.parse(JSON.stringify(store.state.fna.customerInfo) || "{}"))
		const havingStored = computed(() => answers?.having)
		const allocationCalculatedAmount = computed(() => {
			// allocationCalculatedAmount in case facts is a JSON string
			if (typeof havingStored.value?.allocationCalculatedAmount === "string") {
				return JSON.parse(havingStored.value?.allocationCalculatedAmount || null)
			}
			return havingStored.value?.allocationCalculatedAmount
		})
		const allocationCalculatedPercent = computed(() => {
			// allocationCalculatedPercent in case facts is a JSON string
			if (typeof havingStored.value?.allocationPercentage === "string") {
				return JSON.parse(havingStored.value?.allocationPercentage || null)
			}
			return havingStored.value?.allocationPercentage
		})
		const collectedHouseholdIncomeDetails = computed(() => answers?.having?.householdIncomeDetails)
		const collectedHouseholdExpenseDetails = computed(() => answers?.having?.householdExpenseDetails)

		const householdIncomeDetails = formatHouseholdDetails(collectedHouseholdIncomeDetails.value)
		const householdExpenseDetails = formatHouseholdDetails(collectedHouseholdExpenseDetails.value)

		const inflationRate = store.state.fna.rates.inflation ?? 5 // Muc lam phat du kien

		const summaryGoals = selectedGoals?.map((goal) => {
			const benefitAmount = Math.round(sumGoals[goal] / millionUnit) || 0 // 9
			const currentHaving =
				(allocationCalculatedAmount.value?.accumulatedSaving?.[goal] +
					allocationCalculatedAmount.value?.insuranceCoverage?.[goal]) /
					millionUnit || 0 // 10
			const currentNeeds = benefitAmount - currentHaving // 11 = 9 - 10
			const futureCoefficient = ((1 + inflationRate / 100) ** timePerGoals[goal])?.toFixed(4) // 13
			const futureAmountDemand = Math.round(currentNeeds * futureCoefficient) // 14 = 11 * 13

			const { yourAccumulatedSaving, currentProtectionCoverage } = havingStored.value
			const accumulatedSavingPercent = allocationCalculatedPercent.value?.accumulatedSaving
			const insurancePercent = allocationCalculatedPercent.value?.insuranceCoverage

			saveFactsToCase({
				goal,
				sumGoals: sumGoals[goal],
				yourAccumulatedSaving,
				accumulatedSavingPercent: !accumulatedSavingPercent ? 0 : accumulatedSavingPercent[goal],
				currentProtectionCoverage,
				insurancePercent: !insurancePercent ? 0 : insurancePercent[goal],
				targetDuration: timePerGoals[goal],
			})

			return {
				name: planContentVietnameseMapping()[goal], // 8
				benefitAmount,
				benefitAmountFormatted: benefitAmount?.toLocaleString(),
				currentHaving,
				currentHavingFormatted: currentHaving?.toLocaleString(),
				currentNeeds,
				currentNeedsFormatted: currentNeeds?.toLocaleString(),
				accumulationTime: timePerGoals[goal], // 12
				futureCoefficient,
				futureAmountDemand,
				futureAmountDemandFormatted: futureAmountDemand?.toLocaleString(),
			}
		})

		const summaryGoalsTotal = summaryGoals?.reduce(
			(acc, cur) => {
				acc.benefitAmount += cur.benefitAmount
				acc.benefitAmountFormatted = acc.benefitAmount?.toLocaleString()
				acc.currentHaving += cur.currentHaving
				acc.currentHavingFormatted = acc.currentHaving?.toLocaleString()
				acc.currentNeeds += cur.currentNeeds
				acc.currentNeedsFormatted = acc.currentNeeds?.toLocaleString()
				acc.futureAmountDemand += cur.futureAmountDemand
				acc.futureAmountDemandFormatted = acc.futureAmountDemand?.toLocaleString()
				return acc
			},
			{
				benefitAmount: 0,
				currentHaving: 0,
				currentNeeds: 0,
				futureAmountDemand: 0,
			}
		)

		const income = answers?.having?.householdIncome ? answers?.having?.householdIncome * 12 : 0
		const expense = answers?.having?.householdExpense ? answers?.having?.householdExpense * 12 : 0
		const summary = income && expense ? income - expense : 0

		const financialCapability = {
			income: income ? Math.round(income / millionUnit).toLocaleString() : "0",
			expense: expense ? Math.round(expense / millionUnit).toLocaleString() : "0",
			summary: summary ? Math.round(summary / millionUnit).toLocaleString() : "0",
		}

		const collectCustomerInfos = collectCustomerInfosData.value
		collectCustomerInfos.spouseName =
			collectCustomerInfos.maritalStatus === "2" && collectCustomerInfos.spouseName
				? collectCustomerInfos.spouseName
				: t("fna.noInformation")
		collectCustomerInfos.spouseAge = collectCustomerInfos.spouseAge ?? t("fna.noInformation")

		const dependentList = collectCustomerInfos.dependentList || []
		const emptyDependents = Array.from({ length: 3 - dependentList.length }, () => ({
			name: t("fna.noInformation"),
			age: t("fna.noInformation"),
		}))

		dependentList.splice(dependentList.length, 0, ...emptyDependents)
		dependentList.forEach((i = {}) => {
			i.name = i.name ? i.name : t("fna.noInformation")
			i.age = i.age !== null ? i.age : t("fna.noInformation")
		})
		collectCustomerInfos.dependentList = dependentList

		console.log("Template data FNA", {
			customerDetails,
			collectCustomerInfos,
			householdIncomeDetails,
			householdExpenseDetails,
			dependents,
			summaryGoals,
			summaryGoalsTotal,
			financialCapability,
			inflationRate,
		})

		return {
			customerDetails,
			collectCustomerInfos,
			householdIncomeDetails,
			householdExpenseDetails,
			dependents,
			summaryGoals, // data.summaryGoals (from number 8 to 14)
			summaryGoalsTotal, // data.summaryGoalsTotal (for number 15 and 20)
			financialCapability,
			inflationRate,
		}
	}

	const formatHouseholdDetails = (obj) =>
		Object.keys(obj)?.reduce((acc, key) => {
			acc[key] = formatNumber({ number: obj[key], monthCount: 12 })
			return acc
		}, {})

	const buildProductDetailPdfVariables = (excelRes) => {
		const customerDetails = store.getters["fna/customerDetails"]
		const selectedProductDetail = store.getters["fna/selectedProductDetail"]
		const premTerm = selectedProductDetail?.paymentTerm
		const sa = store.getters["fna/faceAmount"] / 1000

		// Excel data
		excelRes.splice(0, 1)
		const premBase = +excelRes[0].valueJsonString
		const topup = +excelRes[1].valueJsonString

		excelRes.splice(0, 2)
		// excel Data after convert: [[...], [...], [...]]
		const convertedExcelData = convertExcelData(excelRes)

		// delete "tb" field to display
		const displayExcelData = [...convertedExcelData]
		displayExcelData.splice(1, 1)

		const sumOfPremBaseAndTopup = premBase + topup

		// contract value summary

		const constractsValueSummary = {
			tenth: {
				totalPaid: Math.round((sumOfPremBaseAndTopup * Math.min(10, premTerm)) / 1000).toLocaleString(),
				totalRefund: Math.round(excelRes[dataType.h_sv * 15 + 9].valueJsonString / 1000).toLocaleString(),
				totalBenefit: Math.round(excelRes[dataType.tb * 15 + 9].valueJsonString / 1000).toLocaleString(),
			},
			fifteen: {
				totalPaid: Math.round((sumOfPremBaseAndTopup * Math.min(10, premTerm)) / 1000).toLocaleString(),
				totalRefund: Math.round(excelRes[dataType.h_sv * 15 + 14].valueJsonString / 1000).toLocaleString(),
				totalBenefit: Math.round(excelRes[dataType.tb * 15 + 14].valueJsonString / 1000).toLocaleString(),
			},
		}

		// benefit summary
		const getMedicalSupport = (fa) => {
			return fa * 2
		}

		const benefitSummary = {
			insuranceFee: (sumOfPremBaseAndTopup / 1000).toFixed(1),
			contractTerm: 15,
			premTerm,
			paymentPeriod: "Năm",
			hospitalizationAllowance: [
				Math.min(getMedicalSupport(sa) / millionUnit, 3),
				Math.min((2 * getMedicalSupport(sa)) / millionUnit, 6),
			],
			healthCare: Math.min(getMedicalSupport(sa) / millionUnit, 3),
			accidentFatal: sa < millionUnit ? sa / 1000 + " triệu đồng" : sa / millionUnit + " tỷ đồng",
			deadEnsurance: sa < millionUnit ? sa / 1000 + " triệu đồng" : sa / millionUnit + " tỷ đồng",
			funeralObservance: Math.min(0.1 * sa, 30000) / 1000,
		}

		// account values
		const accountValues = {
			age: [...new Array(15)].map((_, index) => `${index + 1}/${+customerDetails.age + index + 1}`),
			insuranceFee: [...new Array(15)].map((_, index) => {
				return index < premTerm ? ((premBase * (index + 1)) / 1000).toFixed(2) : ""
			}),
			data: displayExcelData,
		}

		return {
			customerDetails: { ...customerDetails, gender: customerDetails.gender === "male" ? "Nam" : "Nữ" },
			premTerm,
			sa: selectedProductDetail?.sa?.toLocaleString(),
			insuranceFee: (sumOfPremBaseAndTopup * 1000).toLocaleString(),
			constractsValueSummary,
			benefitSummary,
			accountValues,
		}
	}

	const buildExcelVariables = () => {
		const customerDetails = store.getters["fna/customerDetails"]
		const selectedProductDetail = store.getters["fna/selectedProductDetail"]
		const sa = (Math.round(store.getters["fna/faceAmount"] / 100000000) * 100000000) / 1000

		return [
			customerDetails?.age,
			customerDetails?.gender === "male" ? "M" : "F",
			roundFaceAmount(sa),
			selectedProductDetail?.paymentTerm,
		].join("")
	}
	const convertExcelData = (data) => {
		const itemCount = 15
		const convertDataLength = data.length / itemCount
		const convertData = [...new Array(convertDataLength)].map((_, index) => {
			const childArr = []
			const startAt = itemCount * index
			for (let i = startAt; i < startAt + 15; i++) {
				childArr.push(data[i]?.valueJsonString !== "0" ? (data[i].valueJsonString / 1000).toFixed(2) : "")
			}
			return childArr
		})
		return convertData
	}

	const generateProductDetailPdf = async () => {
		const excelVariables = buildExcelVariables()

		const res = await fetchExcelTemplateData(excelVariables)

		// hardcode excel response for testing

		const variables = buildProductDetailPdfVariables(res)

		const data = await fetchPdfTemplate({
			templateId: getEnv("VUE_APP_PRODUCT_DETAIL_TEMPLATEID"),
			input: {
				name: "data",
				contentJsonString: JSON.stringify(variables),
			},
		})

		return data
	}

	const generateProductDetailHTML = async () => {
		const excelVariables = buildExcelVariables()

		const res = await fetchExcelTemplateData(excelVariables)

		// hardcode excel response for testing

		const variables = buildProductDetailPdfVariables(res)

		const data = await fetchHTMLTemplate({
			templateId: getEnv("VUE_APP_PRODUCT_DETAIL_TEMPLATEID"),
			input: {
				name: "data",
				contentJsonString: JSON.stringify(variables),
			},
		})

		return data
	}

	const generateNeedsAnalytics = async () => {
		const variables = JSON.stringify(buildNeedsAnalyticsTemplateVariables())
		const templateId = getEnv("VUE_APP_NEEDS_ANALYSTIC_TEMPLATEID")
		const data = await fetchPdfTemplate({
			templateId,
			input: {
				name: "data",
				contentJsonString: variables,
			},
		})

		return data
	}

	const generateNeedsAnalyticsHTML = async () => {
		const variables = buildNeedsAnalyticsTemplateVariables()

		const data = await fetchHTMLTemplate({
			templateId: getEnv("VUE_APP_NEEDS_ANALYSTIC_TEMPLATEID"),
			input: {
				name: "data",
				contentJsonString: JSON.stringify(variables),
			},
		})

		return data
	}

	return {
		generateNeedsAnalytics,
		generateNeedsAnalyticsHTML,
		generateProductDetailPdf,
		generateProductDetailHTML,
		buildNeedsAnalyticsTemplateVariables,
	}
}
export default usePdfTemplate
