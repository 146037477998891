import { Machine } from "xstate"
import { useMachine } from "@/modules/core/composables/useMachine"
import { fnaMachine } from "./../machine/fnaMachine"
import { computed } from "vue"
import {
	apiPromise,
	apiCreateIndividual,
	apiCreateCase,
	apiCases,
	apiCaseFactBatch,
	// apiUpdateCase,
	apiDeleteCase,
	apiUpdateFactOfCase,
	apiSendNotification,
	apiUpdateCase,
} from "@covergo/cover-composables"
import getEnv from "@/utils/getEnv"
import { fetcher } from "../api/fetcher"
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"
import { handleErrorForUser } from "../api/handleErrorForUser"
import { useStore } from "vuex"
import { formatMoney } from "../helpers"
import { addAdditionalCaseFacts, addIndividualFacts } from "@/modules/fna/use/useSteps"
import customerFacts from "@/modules/customerSupport/use/useFacts"
import { casesFragmentOnLoadAFlow } from "@/modules/customerSupport/use/queriesAndFragment"
export function useFNA(initial, stage) {
	const router = useRouter()

	const store = useStore()
	const { t } = useI18n()
	const userSource = computed(() => store.getters.me?.associatedUser?.source)
	const conversationStatus = computed(() => store.getters["fna/caseStatus"])
	const leadGCM = computed(() => store.getters["fna/leadGCM"])

	const { initIndividualFacts, initCaseFacts } = customerFacts()

	const saveCaseFact = async (factType) => {
		const factId = store.getters[`fna/${factType}FactId`]
		const updatedFact = store.getters[`fna/${factType}Fact`]
		const caseId = store.getters["fna/caseId"]
		const variables = {
			caseId,
			factId,
			input: updatedFact,
		}
		return await apiPromise(apiUpdateFactOfCase, { variables, fetcher })
	}
	const saveAnswers = async () => await saveCaseFact("answers")
	const saveProductCode = async () => await saveCaseFact("productCode")
	const updateCaseStatus = async (ctx) => {
		// needs to check with tcb:
		// what if conversation status is COMPLETED (users finish step 7)
		// and users go to drop off modal and select option with "canceled" status?
		const caseId = store?.getters?.["fna/caseId"]
		const variables = {
			caseId,
			input: {
				status: ctx.dropOffStatus,
			},
		}
		return await apiPromise(apiUpdateCase, { variables, fetcher })
	}
	// eslint-disable-next-line no-unused-vars
	const sendEmail = async () => {
		const me = store.getters.me
		const userId = me.associatedUser.id
		const caseData = store.getters["fna/caseData"]
		const selectedGoals = store.getters["fna/selectedGoals"]
		const selectedGoalsData = selectedGoals.map((g) => t(`fna.${g}Goal`))
		const selectedProductDetail = store.getters["fna/selectedProductDetail"]
		const conversationResult = store.getters["fna/conversationResult"]
		const conversationId = store.getters["fna/conversationId"]
		const dropOffReason = store.getters["fna/dropOffReason"]
		const otherDropOffReason = store.getters["fna/otherDropOffReason"]
		const leadGCM = store.getters["fna/leadGCM"]
		const subjectConversation = computed(() => {
			return t !== "en-US"
				? `[iTCBLife] ${conversationId} - Kết quả phân tích nhu cầu khách hàng`
				: `[iTCBLife] ${conversationId} - analyzing customer demands`
		})
		const variables = {
			sendNotificationInput: {
				toEntityId: userId,
				emailMessage: {
					from: getEnv("VUE_APP_EMAIL_SENDER"),
					fromName: "iTCBLife",
					to: me?.email,
					subject: subjectConversation.value,
					templateRendering: {
						templateId: getEnv("VUE_APP_RESULT_EMAIL_TEMPLATEID"),
						input: {
							name: "data",
							contentJsonString: JSON.stringify({
								conversationId,
								createdAt: caseData.createdAt,
								status: t(`fna.${caseData.status}`),
								goals: selectedGoalsData.length > 1 ? selectedGoalsData.join(" , ") : selectedGoalsData,
								productName: selectedProductDetail?.name ? t(`fna.${selectedProductDetail.name}ProductName`) : "",
								premium: selectedProductDetail?.premium ? formatMoney(selectedProductDetail.premium) : "",
								conversationResult: conversationResult ? t(`fna.${conversationResult}`) : "",
								dropOffReason: dropOffReason !== "otherDropOffReason" ? t(`fna.${dropOffReason}`) : otherDropOffReason,
								leadGCM,
							}),
						},
					},
				},
			},
		}
		await apiPromise(apiSendNotification, { variables, fetcher })
	}

	const saveDropOffReason = async () => await saveCaseFact("dropOffReason")
	const saveOtherDropOffReason = async () => await saveCaseFact("otherDropOffReason")
	// const loadMe = async () => {
	// 	const me = await apiPromise(apiMe, { variables: {}, fetcher })
	// 	store.dispatch("setMe", me)
	// 	return me
	// }
	const options = {
		services: {
			async loadFNAData() {
				const { mvpVersion } = store?.state?.fna?.caseData
				// if we access case of mvp 1.5 from mvp 2.0 for the first time
				// mpvVersion will be null => need to add more data to sync with 2.0
				if (mvpVersion === null) {
					await addAdditionalCaseFacts(store)
					await addIndividualFacts(store)
				}

				const { id } = store.state.fna.caseData
				const variables = { where: { id } }
				const fragment = casesFragmentOnLoadAFlow

				return await apiPromise(apiCases, {
					variables,
					fetcher,
					fragment,
				})
			},
			async createNewIndividual() {
				const variables = {
					input: {
						facts: initIndividualFacts,
					},
				}
				return await apiPromise(apiCreateIndividual, {
					variables,
					fetcher,
				})
			},
			async createNewCase(ctx) {
				if (!userSource.value) {
					console.error("This user doesn't have source")
					return Promise.reject(new Error("This user doesn't have source"))
				}
				const variables = {
					input: {
						holderId: ctx.holderId,
						status: "IN PROGRESS",
						source: userSource.value,
					},
				}
				return await apiPromise(apiCreateCase, {
					variables,
					fetcher,
				})
			},
			async initCaseFacts(ctx) {
				const answerFactVariables = store.getters["fna/answersFact"]
				const me = store.getters.me
				const fieldsOfMe = JSON.parse(me?.associatedUser?.fields)
				const goalsFactVariables = {
					type: "goals",
					value: {
						arrayValue: null,
					},
				}
				const defaultFacts = initCaseFacts({ me: fieldsOfMe })

				const variables = {
					caseId: ctx.caseId,
					input: {
						addFactInputs: [answerFactVariables, goalsFactVariables, ...defaultFacts],
					},
				}
				return apiPromise(apiCaseFactBatch, { variables, fetcher })
			},
			async deletingConversation(ctx) {
				if (leadGCM.value || conversationStatus.value === "COMPLETED") return

				// we need to reset it because the value will be cached to the new case
				store.dispatch("fna/setAnnualPremium", null)

				const caseId = store?.getters?.["fna/caseId"]
				const variables = {
					id: caseId,
				}
				return await apiPromise(apiDeleteCase, { variables, fetcher })
			},
			async dropOffConversation(ctx) {
				await Promise.all([
					saveProductCode(),
					saveDropOffReason(),
					saveOtherDropOffReason(),
					saveAnswers(),
					updateCaseStatus(ctx),
				])
				sendEmail()
				// we need to reset it because the value will be cached to the new case
				store.dispatch("fna/setAnnualPremium", null)
			},
		},
		actions: {
			setHolderId(ctx, event) {
				ctx.holderId = event?.data?.createdStatus?.id
			},
			setCaseId(ctx, event) {
				ctx.caseId = event?.data?.createdStatus?.id
				store.dispatch("fna/setCaseData", { id: ctx.caseId })
			},
			async onLoadFNADataSuccess(_, event) {
				const caseData = event?.data?.list?.[0] || {}
				store.dispatch("fna/setCaseData", caseData)

				const answers = store.getters["fna/answersFromFact"]
				const answersFactId = store.getters["fna/answersFactId"]
				// const rates = store.getters["fna/ratesFromFact"]
				const goals = store.getters["fna/goalsFromFact"]
				const goalsFactId = store.getters["fna/goalsFactId"]
				const customerDetails = store.getters["fna/customerDetails"]
				const customerInfo = store.getters["fna/customerInfo"]
				const selectedProductFromFact = store.getters["fna/selectedProductFromFact"]
				const conversationResultFromFact = store.getters["fna/conversationResultFromFact"]
				const tisAgentCodeFromFact = store.getters["fna/tisAgentCodeFromFact"]
				const leadGCMFromFact = store.getters["fna/leadGCMFromFact"]
				const referLeadFnaFromFact = store.getters["fna/referLeadFnaFromFact"]
				const recentLeadApiStatus = store.getters["fna/recentLeadApiStatus"]
				const planCodeFromFact = store.getters["fna/planCodeFromFact"]
				const mvpVersionFromFact = store.getters["fna/mvpVersionFromFact"]
				const completedDateFactId = store.getters["fna/completedDateFactId"]
				const dropOffReasonFromFact = store.getters["fna/dropOffReasonFromFact"]
				const otherDropOffReasonFromFact = store.getters["fna/otherDropOffReasonFromFact"]
				// store.dispatch("fna/setRates", rates)
				store.dispatch("fna/setGoals", goals)
				store.dispatch("fna/setGoalsFactId", goalsFactId)
				store.dispatch("fna/setAnswers", answers)
				store.dispatch("fna/setAnswersFactId", answersFactId)
				store.dispatch("fna/setCustomerDetails", customerDetails)
				store.dispatch("fna/setCustomerInfo", customerInfo)
				store.dispatch("fna/setLeadGCM", leadGCMFromFact)
				store.dispatch("fna/setReferLeadFna", referLeadFnaFromFact)
				store.dispatch("fna/setRecentLeadApiStatus", recentLeadApiStatus)
				store.dispatch("fna/setPlanCode", planCodeFromFact)
				store.dispatch("fna/setMVPVersion", mvpVersionFromFact)
				store.dispatch("fna/setCompletedDate", completedDateFactId)
				store.dispatch("fna/setDropOffReason", dropOffReasonFromFact)
				store.dispatch("fna/setOtherDropOffReason", otherDropOffReasonFromFact)
				store.dispatch("fna/setConversationResult", conversationResultFromFact)
				store.dispatch("fna/setTisAgentCode", tisAgentCodeFromFact)
				store.dispatch("fna/setSelectedProduct", selectedProductFromFact.name)
				store.dispatch("fna/setOverwriteProductOptions", {
					[selectedProductFromFact.name]: { ...selectedProductFromFact },
				})

				const initialCompletedSteps = store.getters["fna/initialCompletedSteps"]
				const initialStep = stage && initialCompletedSteps.includes(stage) ? stage : store.getters["fna/initialStep"]

				store.dispatch("fna/setInitialActiveStep", initialStep)
				store.dispatch("fna/setInitialCompletedSteps", initialCompletedSteps)

				const query = {
					stage: initialStep,
				}

				// already dispatched lead gcm when clicking Start FNA from tab 3
				const leadId = computed(() => customerInfo.leadId)

				// so need to check lead gcm from store
				if (leadId.value) {
					query.gcmLeadId = leadId.value
					// set lead gcm to empty, in case users go back to Start FNA from default tab 1
					await store.dispatch("fna/setGCMLeadIdFromRoute", leadId.value)
					await store.dispatch("setLeadGCM", "")
				}

				router.push({
					name: "ViewFNA",
					params: {
						caseId: caseData.id,
					},
					query,
				})
			},
			onFNAStop() {
				store.dispatch("fna/stopFNA")
				router.push({ name: "ViewHome" })
			},
			onApiFailed(ctx, event) {
				handleErrorForUser({ error: event?.data, $t: t })
			},
		},
	}
	fnaMachine.initial = initial || "idle"
	const { state, send } = useMachine(Machine(fnaMachine, options), { devTools: process.env.NODE_ENV === "development" })

	return {
		state,
		send,
	}
}
