<template>
	<JButton @click="onHandleCreateUsers">Create users</JButton>
</template>

<script>
import {
	apiAddToPermissionGroup,
	apiCreateIndividual,
	apiInviteEntityToLogin,
	apiPermissionGroups,
} from "@covergo/cover-composables"
import { fetcher } from "@/modules/user/api/fetcher.js"
import { handleErrorForUser } from "@/modules/user/api/handleErrorForUser.js"
import { onMounted, ref } from "vue"
import { useStore } from "vuex"
import { useI18n } from "vue-i18n"
import users from "../../mock/users"
import getEnv from "@/utils/getEnv"
export default {
	name: "CreateNewUsers",
	setup() {
		const { t } = useI18n()
		const store = useStore()
		const individualId = ref(null)
		const dataUsers = ref(users)
		const usersFailed = ref([])
		const roleOptions = ref([])

		// permission
		const getPermissionGroup = async () => {
			const { data, error } = await apiPermissionGroups({ fetcher })
			if (error) {
				handleErrorForUser({ error, $t: t })
			} else {
				roleOptions.value = data.map((x) => ({
					name: x.name === "default" ? "Sale" : x.name,
					role: x.name,
					value: x.id,
				}))
			}
		}
		onMounted(async () => {
			await getPermissionGroup()
		})
		const assignRole = async (role, loginId) => {
			const variables = {
				loginId,
				permissionGroupId: role.value,
			}
			const { error } = await apiAddToPermissionGroup({ variables, fetcher })
			if (error) {
				throw error
			}
		}
		const createIndividual = async (user) => {
			const { firstName, lastName, area, region, branch, position, fields } = user
			let source = []
			if (position === "hoManager" || position === "executive" || position === "security") {
				user.area = null
				user.region = null
				user.branch = null

				source = position
			} else if (position === "areaManager") {
				user.area = t(`user.area.${area}`)
				user.region = null
				user.branch = null

				source = [area, position].filter((item) => !!item).join("-")
			} else if (position === "regionalManager") {
				user.area = t(`user.area.${area}`)
				user.region = t(`user.region.${region}`)
				user.branch = null

				source = [area, region, position].filter((item) => !!item).join("-")
			} else {
				user.area = t(`user.area.${area}`)
				user.region = t(`user.region.${region}`)
				user.branch = t(`user.branch.${branch}`)
				source = [area, region, branch, position].filter((item) => !!item).join("-")
			}

			const variables = {
				input: {
					englishFirstName: firstName,
					englishLastName: lastName,
					source,
					fields,
				},
			}
			const { data, error } = await apiCreateIndividual({ variables, fetcher })
			if (error) throw error
			individualId.value = data?.createdStatus?.id
		}
		const inviteEntityToLogin = async (user) => {
			const variables = {
				clientId: "covergo_crm",
				input: {
					appIdsToBeGrantedAccessTo: ["covergo_crm"],
					entityId: individualId.value,
					email: user.email,
					username: user.userName,
					sendNotification: {
						emailMessage: {
							from: getEnv("VUE_APP_EMAIL_SENDER"),
							fromName: "iTCBLife",
							to: user.email,
							subject: "[iTCBLife] Thông tin đăng nhập iTCBLife",
							templateRendering: {
								templateId: getEnv("VUE_APP_REGISTRATION_EMAIL_TEMPLATEID"),
								input: {
									name: "data",
									contentJsonString: JSON.stringify({
										userName: user.userName,
										userMail: user.email,
									}),
								},
							},
						},
					},
				},
			}
			const { data, error } = await apiInviteEntityToLogin({
				variables,
				fetcher,
			})
			if (error) throw error
			return data?.createdStatus?.id
		}

		const addPermissionManager = async (user, loginId) => {
			const { area, region, branch, position } = user
			const baseSource = [area, region, branch].filter((item) => !!item).join("-")

			let source = [baseSource]
			if (["RBO.SRBO"].includes(position)) {
				source = [`${baseSource}-RBO`, `${baseSource}-SRBO`]
			}
			if (["TIS.PRM"].includes(position)) {
				source = [`${baseSource}-TIS`, `${baseSource}-PRM`]
			}
			const variables = {
				loginId,
				inputs: [
					{
						type: "readCases",
						value: `{caseIdIfSourceContains=${source.join(",")}}`,
					},
				],
			}
			for (const item of source) {
				variables.inputs.push({
					type: "readIndividuals",
					value: `individualIdIfSourceContains:${item}`,
				})
			}
			const query = `
            mutation addTargettedPermissions(
                $loginId: String!
                $inputs: [addTargettedPermissionInput]!
            ) {
                addTargettedPermissions(loginId: $loginId, inputs: $inputs) {
                    status
                    errors
                    errors_2{
                        code
                        message
                    }
                }
            }
    `
			const { errors } = await fetcher({
				query,
				variables,
			})

			if (errors) throw errors
		}
		const onHandleCreateUsers = async () => {
			while (dataUsers.value.length) {
				// allow to create 20 users at once
				const length = dataUsers.value.length > 20 ? 20 : dataUsers.value.length
				const users = dataUsers.value.splice(0, length)
				const queue = users.map(async (user) => {
					if (user.email) {
						const userRole = roleOptions.value.find((item) => {
							return item.role === user.value
						})
						try {
							await createIndividual(user)
							const loginId = await inviteEntityToLogin(user)
							await assignRole(userRole, loginId)
							if (user.value === "manager") await addPermissionManager(user, loginId)
							store.dispatch("addToastMessage", {
								type: "success",
								content: {
									type: "message",
									text: t("user.message.createSuccess"),
								},
							})
						} catch (err) {
							handleErrorForUser({ error: err, $t: t })
							usersFailed.value.push(user)
						}
					}
				})
				await Promise.all(queue)
			}
		}
		return { onHandleCreateUsers }
	},
}
</script>
