<template>
	<button class="relative" v-click-outside="hide" @focus="onHandleFocus">
		<JBox
			class="relative w-full text-c0-500 bg-cWhite rounded-md leading-tight border border-c0-400 pl-5 pr-4 cursor-default h-10"
			@click="show"
		>
			<JFlex v-if="hasRemoveItem" class="flex-wrap w-full">
				<JFlex v-if="!selected" class="flex-row justify-between w-full py-1 m-1">
					<JText class="truncate">{{ placeholder }}</JText>
					<JIcon class="text-cBlack" icon="ChevronDown" />
				</JFlex>
				<JFlex v-else class="flex-row justify-between w-full py-1 m-1">
					<span>{{ selectedLabel }}</span>
					<span class="cursor-pointer text-c0-300" @click.stop="removeTag">&times;</span>
				</JFlex>
			</JFlex>
			<JFlex v-else class="h-full items-center">
				<JText :class="['flex flex-start flex-1 truncate', selectedLabel ? 'text-c0-600' : 'text-c0-500']">{{
					selected ? selectedLabel : placeholder
				}}</JText>
				<JIcon class="text-cBlack" icon="ChevronDown" />
			</JFlex>
		</JBox>
		<!-- Search Select Dropdown !-->
		<JFlex
			v-show="isShowDropdown"
			class="mt-1 w-full absolute z-10 shadow-5 rounded-md flex-col overflow-hidden bg-cWhite"
			:style="dropdownStyles"
		>
			<JBox class="px-4 py-2 bg-c0-200">
				<JInputText
					ref="inputSearch"
					v-model="inputSearchVal"
					spellcheck="false"
					@input="onInputSearch"
					:placeholder="searchPlaceholder"
				/>
			</JBox>
			<JBox class="overflow-y-auto">
				<JBox class="text-center mt-2" v-show="isSearchLoading">
					<JFlex class="py-2 px-4 inline-flex items-center">
						<JSpinner variant="secondary" type="dots" />
					</JFlex>
				</JBox>
				<ul class="pb-2 pt-2 text-base overflow-y-auto overflow-x-hidden" v-if="optionList.length">
					<template v-for="(option, optionIdx) in optionList" :key="optionIdx">
						<li
							class="text-gray-900 cursor-pointer relative px-4 py-2"
							:class="selected?.[valueKey] === option[valueKey] ? 'bg-c0-400 text-cWhite' : 'hover:bg-c0-200'"
							@click.prevent="select(option)"
						>
							<div class="flex items-center">
								<span class="block truncate">{{ option[nameKey] }}</span>
							</div>
						</li>
					</template>
				</ul>
				<template v-if="!optionList.length && inputSearchVal.length">
					<JBox class="text-center py-2">
						<JText class="text-c0-500">{{ searchResultNothingText }}</JText>
					</JBox>
				</template>
			</JBox>
		</JFlex>
	</button>
</template>
<script>
import debounce from "lodash/debounce"
import { ref, computed, nextTick, watch } from "vue"
import { useI18n } from "vue-i18n"
export default {
	name: "CInputSearchSelect",
	props: {
		modelValue: {
			type: String,
		},
		placeholder: {
			type: String,
			default: "Please select",
		},
		options: {
			type: Array,
			default: () => [],
		},
		defaultOptions: {
			/* Options show when the input search has blank value */
			type: Array,
			default: () => [],
		},
		useDefaultOptions: {
			type: Boolean,
			default: false,
		},
		isSearchLoading: {
			type: Boolean,
			default: false,
		},
		valueKey: {
			type: String,
			default: "key",
		},
		nameKey: {
			type: String,
			default: "name",
		},
		selectedNameKey: {
			type: String,
			default: "name",
		},
		searchPlaceholder: String,
		lookupFields: {
			type: Array,
			default: () => [],
		},
		maxHeightDropdown: {
			type: String,
			default: "300px",
		},
		searchFunction: {
			type: Function,
		},
		searchTimeout: {
			type: Number,
			default: 1000,
		},
		isDisabledSearch: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		hasRemoveItem: {
			type: Boolean,
			default: false,
		},
	},
	emits: ["update:modelValue"],
	setup(props, { emit }) {
		const { t } = useI18n()
		const inputSearch = ref(null)
		const isShowDropdown = ref(false)
		const inputSearchVal = ref("")
		const optionList = ref([])
		function initialize() {
			const options = Array.isArray(props.defaultOptions) && props.useDefaultOptions ? props.defaultOptions : props.options
			optionList.value = options?.filter((o) => o.value)
		}
		initialize()
		watch(
			() => props.options,
			(newVal) => {
				initialize()
			}
		)

		const displayOptions = computed(() => {
			if (!inputSearchVal.value.trim().length) {
				return Array.isArray(props.defaultOptions) && props.useDefaultOptions ? props.defaultOptions : props.options
			} else {
				const searchFields = props.lookupFields.length ? props.lookupFields : [props.valueKey]
				return props.options.filter((x) =>
					searchFields.some(
						(field) => typeof x[field] === "string" && x[field].toLowerCase().includes(inputSearchVal.value.toLowerCase())
					)
				)
			}
		})
		const selected = computed({
			get: () => props.options.find((x) => x[props.valueKey] === props.modelValue),
			set: (val) => {
				emit("update:modelValue", val?.[props.valueKey])
			},
		})
		const selectedLabel = computed(() => {
			if (!selected.value) return ""
			return selected.value[props.selectedNameKey ? props.selectedNameKey : props.nameKey]
		})
		const dropdownStyles = computed(() => {
			return {
				maxHeight: props.maxHeightDropdown,
			}
		})
		const searchResultNothingText = computed(() => t("core.noCodeFound"))
		function show() {
			if (props.isDisabledSearch) return
			isShowDropdown.value = true
			nextTick(() => {
				if (inputSearch?.value?.$el?.tagName === "INPUT") {
					inputSearch.value.$el.focus()
				}
			})
		}
		function hide() {
			isShowDropdown.value = false
			if (!props.options?.length) {
				inputSearchVal.value = ""
				props.searchFunction(inputSearchVal.value)
			}
		}
		function select(val) {
			selected.value = val
			hide()
		}
		const onInputSearch = debounce((evt) => {
			if (typeof props.searchFunction === "function") {
				props.searchFunction(evt.target.value)
			}
		}, props.searchTimeout)

		const removeTag = () => {
			if (props.disabled) return
			selected.value = ""
			emit("update:modelValue", "")
		}

		function onHandleFocus() {
			show()
		}
		return {
			inputSearch,
			selected,
			optionList,
			displayOptions,
			inputSearchVal,
			isShowDropdown,
			dropdownStyles,
			selectedLabel,
			searchResultNothingText,
			show,
			hide,
			select,
			onInputSearch,
			removeTag,
			onHandleFocus,
		}
	},
}
</script>
