import { isString } from "lodash"
import { createVueApp } from "@/helpers/createVueApp"

import Loading from "@/modules/core/components/Loading.vue"
import { isPlainObject } from "@/utils"

const _cache = new WeakMap()

function createLoader(element, binding, { overlay } = {}) {
	const isStatic = window.getComputedStyle(element).position === "static"
	const backupPosition = element.style.position
	const cached = _cache.get(element)

	if (!cached) {
		if (isStatic) {
			element.style.position = "relative"
		}

		const props = Object.assign(
			{},
			overlay && {
				style: {
					width: "100%",
					height: "100%",
					top: 0,
					left: 0,
				},
			}
		)

		const app = createVueApp(
			Loading,
			{
				type: "dots",
				...props,
				overlay,
				active: true,
			},
			binding.instance._.appContext
		)

		const root = document.createElement("div")

		const instance = app.mount(root)

		element.appendChild(instance.$el)

		_cache.set(element, {
			app,
			backupPosition,
		})
	}
}

function removeLoader(element, binding) {
	const cached = _cache.get(element)

	if (cached) {
		cached.app.unmount()
		element.style.position = cached.backupPosition
		_cache.delete(element)
	}
}

function handler(element, binding) {
	const { value } = binding
	const isObject = isPlainObject(value)
	const loading = isObject ? value.loading : !!value
	const overlay = isObject && isString(value.overlay) ? value.overlay : "white-absolute"

	if (loading) {
		createLoader(element, binding, { overlay })
	} else {
		removeLoader(element, binding)
	}
}

function initLoader(element, binding) {
	const { value } = binding
	const isObject = isPlainObject(value)

	handler(element, binding)

	if (isObject) {
		binding.instance.$watch(
			() => value.loading,
			() => {
				handler(element, binding)
			}
		)
	}
}

export default {
	mounted(element, binding) {
		initLoader(element, binding)
	},
	beforeUpdate(element, binding) {
		handler(element, binding)
	},
	beforeUnmount(element, binding) {
		removeLoader(element, binding)
	},
}
