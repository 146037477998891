<template>
	<JBox>
		<!-- Select -->
		<JFlex class="w-full py-3">
			<JSelect class="w-full" :options="tabOptions" v-model="selectedType" :placeholder="$t('core.selectEntityType')" />
		</JFlex>
		<!-- Search -->
		<JFlex class="flex-grow w-full mt-2">
			<CSearchBox
				v-if="selectedType"
				v-model="searchQuery"
				:isSearched="searchQuery !== ''"
				:placeholder="$t('client.search')"
				variant="sm"
				class="w-full"
				@clear-search="handleClearSearch()"
			/>
		</JFlex>
		<!-- List -->
		<JBox v-if="selectedType" class="pt-3 pb-5">
			<JBox v-if="computedList.length === 0">
				<JText class="text-sm">
					<span> {{ $t("core.noEntityFound") }} </span>
				</JText>
			</JBox>
			<JBox v-else>
				<JText class="text-c0-500 font-bold text-sm ml-1 mb-2">{{ $t("core.selectEntity") }}</JText>
				<JFlex
					class="cursor-pointer px-6 py-3 rounded-lg mb-4 items-center"
					:class="selectedEntity === item.id ? 'bg-c1-800' : 'bg-c0-100 bg-opacity-75'"
					@click="handleSelectEntity(item)"
					v-for="(item, index) in computedList"
					:key="index"
				>
					<JFlex
						class="items-center justify-center w-5 h-5 rounded-full border-2 mr-6 flex-shrink-0 text-c1-800"
						:class="selectedEntity === item.id ? 'border-cWhite bg-cWhite' : 'border-c0-500'"
					>
						<JIcon v-if="selectedEntity === item.id" icon="Check" />
					</JFlex>
					<JText class="text-sm" :class="selectedEntity === item.id ? 'text-cWhite font-medium' : 'text-c0-500'">
						{{ item.name }}
					</JText>
				</JFlex>
				<JFlex class="w-full justify-end mt-6">
					<CPagination
						:modelValue="currentPage"
						:totalItems="totalCount"
						:itemPerPage="limit"
						@update:modelValue="setPage($event)"
					/>
				</JFlex>
			</JBox>
		</JBox>
	</JBox>
</template>

<script>
import debounce from "lodash/debounce"
import { gql, apiIndividuals, apiCompanies, apiObjects, apiInternals, apiOrganizations } from "@covergo/cover-composables"
import { fetcher } from "../../api/fetcher"
import { handleErrorForUser } from "../../api/handleErrorForUser"

export default {
	props: {
		/** @desc Which entities list to display
		 *  @param Array [individual, company, object, internal, organization]
		 */
		allowedEntityTypes: {
			type: Array,
			default: () => ["individual"],
		},
		/** @desc number on entities to display at a time */
		limit: {
			type: Number,
			default: 5,
		},
	},
	data() {
		return {
			currentPage: 1,
			totalCount: 0,
			list: [],
			selectedType: null,
			selectedEntity: null,
			searchQuery: null,
		}
	},
	methods: {
		async fetchList() {
			const apiKey =
				this.selectedType === "individuals"
					? apiIndividuals
					: this.selectedType === "companies"
					? apiCompanies
					: this.selectedType === "objects"
					? apiObjects
					: this.selectedType === "internals"
					? apiInternals
					: this.selectedType === "organizations"
					? apiOrganizations
					: null
			if (!apiKey) return
			// Show loading indicator
			this.isLoading = true
			const fragment = gql`
				fragment result on ${this.selectedType} {
					list {
						id
						name
						createdAt
					}
					totalCount
				}`
			const variables = {
				limit: this.limit,
				skip: (this.currentPage - 1) * this.limit,
				sort: {
					fieldName: "createdAt",
					type: "desc",
				},
				where: {
					and: [
						{
							name_contains: this.searchQuery,
						},
					],
				},
			}
			const { error, data } = await apiKey({
				variables,
				fetcher,
				fragment,
			})
			if (error) {
				handleErrorForUser({ error, $t: this.$t })
				this.isLoading = false
				return
			}
			this.totalCount = data.totalCount || 0
			this.list = data.list
			this.isLoading = false
		},
		setPage(value) {
			this.currentPage = value
			this.fetchList()
		},
		handleClearSearch() {
			this.searchQuery = ""
		},
		handleSelectEntity(entityObj) {
			this.selectedEntity = entityObj?.id
			this.$emit("selected-entity", entityObj)
		},
		debounceSearch: debounce(function () {
			this.currentPage = 1
			this.fetchList()
		}, 400),
	},
	computed: {
		computedList() {
			return this?.list || []
		},
		tabOptions() {
			const options = []
			if (this?.allowedEntityTypes?.includes("individual")) {
				options.push({ value: "individuals", name: "individual" })
			}
			if (this?.allowedEntityTypes?.includes("company")) {
				options.push({ value: "companies", name: "company" })
			}
			if (this?.allowedEntityTypes?.includes("object")) {
				options.push({ value: "objects", name: "object" })
			}
			if (this?.allowedEntityTypes?.includes("internal")) {
				options.push({ value: "internals", name: "participant" })
			}
			if (this?.allowedEntityTypes?.includes("organization")) {
				options.push({ value: "organizations", name: "organization" })
			}
			return options
		},
	},
	watch: {
		selectedType: {
			handler() {
				this.fetchList()
			},
			immediate: true,
			deep: true,
		},
		searchQuery: {
			handler(value) {
				// No debounce when "X" in search bar clicked
				if (value === "") {
					this.currentPage = 1
					this.fetchList()
				} else this.debounceSearch()
			},
		},
	},
}
</script>
