<template>
	<CLayout :title="$t('setting.setting')">
		<JBox class="p-10" style="background: rgb(250, 252, 252); box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 4px">
			<JFlex v-if="!isLoading" class="flex-col">
				<JBox class="w-full xl:w-1/3 mb-6 xl:pr-4">
					<CFormInput
						componentName="JInputNumber"
						field="accessTokenLifetime"
						:label="$t('setting.accessTokenLifetime')"
						v-model="form.accessTokenLifetime"
						@input="v.accessTokenLifetime.$touch()"
						thousandSeparator=""
						:validator="v"
					/>
				</JBox>
				<JBox class="w-full xl:w-1/3 mb-6 xl:pr-4">
					<CFormInput
						componentName="JInputNumber"
						field="slidingRefreshTokenLifetime"
						:label="$t('setting.slidingRefreshTokenLifetime')"
						v-model="form.slidingRefreshTokenLifetime"
						@input="v.slidingRefreshTokenLifetime.$touch()"
						thousandSeparator=""
						:validator="v"
					/>
				</JBox>
				<JBox class="w-full xl:w-1/3 mb-6 xl:pr-4">
					<CFormInput
						componentName="JInputNumber"
						field="absoluteRefreshTokenLifetime"
						:label="$t('setting.absoluteRefreshTokenLifetime')"
						v-model="form.absoluteRefreshTokenLifetime"
						@input="v.absoluteRefreshTokenLifetime.$touch()"
						thousandSeparator=""
						:validator="v"
					/>
				</JBox>
			</JFlex>
			<JFlex v-else>
				<JSpinner type="dots" />
			</JFlex>
			<JFlex v-if="!isUpdating" class="mt-6" :class="disableButton && 'cursor-not-allowed'">
				<JButton variant="primary" @click="handleClickSave" :class="disableButton && 'opacity-50 pointer-events-none'">
					{{ $t("button.update") }}
				</JButton>
				<JButton class="ml-4 hidden" variant="tertiary-outline" @click="handleClickCancel">
					{{ $t("button.cancel") }}
				</JButton>
			</JFlex>
			<JFlex v-else class="items-center mt-6">
				<JSpinner variant="secondary-lg" type="dots" />
			</JFlex>
		</JBox>
		<ViewConfigRates class="mt-6" :allowToReadSystemConfig="validPermission" />
	</CLayout>
</template>

<script>
import { useStore } from "vuex"
import { computed, onMounted, reactive, toRef, ref } from "vue"
import { Machine } from "xstate"
import { useI18n } from "vue-i18n"
import { gql, apiPromise, apiApps, apiUpdateApp } from "@covergo/cover-composables"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { handleErrorForUser } from "@/modules/setting/api/handleErrorForUser"
import { useMachine } from "@/modules/core/composables/useMachine"
import { fetcher } from "@/modules/setting/api/fetcher"
import { appEditMachine } from "@/modules/setting/machine/appEditMachine"
import ViewConfigRates from "../components/ViewConfigRates"
import validateToAccessPage from "@/modules/pages/use/usePermissionToAccessPage"

export default {
	name: "ViewTokenSetting",
	components: { ViewConfigRates },
	setup() {
		const { t } = useI18n()
		const store = useStore()
		const { goToHomePageIfUserDoesNotHavePermission, isExecutive, isSecurity, isAdmin } = validateToAccessPage()

		const validPermission = ref(isSecurity.value || isExecutive.value || isAdmin.value)
		const form = reactive({
			accessTokenLifetime: null,
			slidingRefreshTokenLifetime: null,
			absoluteRefreshTokenLifetime: null,
		})

		const tokenOffset = 299
		const minSec = 300

		const isLoading = computed(() => state.value.matches("readingApp"))
		const isUpdating = computed(() => state.value.matches("updatingApp"))

		const disableButton = computed(
			() =>
				v.value?.accessTokenLifetime.$invalid ||
				v.value?.slidingRefreshTokenLifetime.$invalid ||
				v.value?.absoluteRefreshTokenLifetime.$invalid
		)

		// Validation
		const rules = {
			accessTokenLifetime: {
				required,
				validNumber: (value) => {
					return value >= minSec
				},
			},
			slidingRefreshTokenLifetime: {
				required,
				validNumber: (value) => {
					return value >= minSec
				},
			},
			absoluteRefreshTokenLifetime: {
				required,
				validNumber: (value) => {
					return value >= minSec
				},
			},
		}
		const v = useVuelidate(rules, {
			accessTokenLifetime: toRef(form, "accessTokenLifetime"),
			slidingRefreshTokenLifetime: toRef(form, "slidingRefreshTokenLifetime"),
			absoluteRefreshTokenLifetime: toRef(form, "absoluteRefreshTokenLifetime"),
		})

		// Form Actions
		function handleClickSave() {
			send("SAVE")
		}
		function handleClickCancel() {}

		const options = {
			services: {
				async readApp() {
					const variables = {}

					const fragment = gql`
						fragment result on apps {
							totalCount
							list {
								...app
							}
						}
						fragment app on app {
							absoluteRefreshTokenLifetime
							accessTokenLifetime
							slidingRefreshTokenLifetime
						}
					`

					return await apiPromise(apiApps, {
						variables,
						fetcher,
						fragment,
					})
				},
				async updateApp() {
					const variables = {
						appId: "covergo_crm",
						input: {
							accessTokenLifetime: form.accessTokenLifetime - tokenOffset,
							slidingRefreshTokenLifetime: form.slidingRefreshTokenLifetime - tokenOffset,
							absoluteRefreshTokenLifetime: form.absoluteRefreshTokenLifetime - tokenOffset,
						},
					}

					return apiPromise(apiUpdateApp, {
						variables,
						fetcher,
					})
				},
			},
			actions: {
				setAppInfo(_, event) {
					const fields = event?.data?.list?.[0]
					form.accessTokenLifetime = fields?.accessTokenLifetime + tokenOffset
					form.slidingRefreshTokenLifetime = fields?.slidingRefreshTokenLifetime + tokenOffset
					form.absoluteRefreshTokenLifetime = fields?.absoluteRefreshTokenLifetime + tokenOffset
				},
				setUpdateAppSuccess() {
					store.dispatch("addToastMessage", {
						type: "success",
						content: {
							type: "message",
							text: t("setting.updateSettingSuccess"),
						},
					})
				},
				setErrorMessage(_, event) {
					handleErrorForUser({ error: event?.data, $t: t })
				},
			},
			guards: {
				updateGuard() {
					v.value.$touch()
					return !v.value.$invalid
				},
			},
		}

		onMounted(() => {
			goToHomePageIfUserDoesNotHavePermission()
			if (validPermission.value) {
				send("FETCH")
			}
		})

		const { state, send } = useMachine(Machine(appEditMachine, options), { devTools: process.env.NODE_ENV === "development" })

		return {
			state,
			send,
			v,
			form,
			// Computed
			isLoading,
			isUpdating,
			disableButton,
			validPermission,
			// Methods
			handleClickSave,
			handleClickCancel,
		}
	},
}
</script>
