export default {
	crm: {
		warningToSelectAnCrm: "Please choose the existing Opp/Lead or choose to proceed with new lead",
		dataNotFound:
			"Customer information is not available in Techcombank systems. Please search again or enter a new customer information.",
		account: "Account Name",
		poName: "Name",
		citizenId: "Citizen ID/ Passport",
		phoneNumber: "Mobile number",
		CRM_ID: "CRM ID",
		type: "Type",
		status: " Status/Stage",
		owner: "Lead/Opp Owner",
		createdDate: "Created Date",
		createNewLead: "Proceed with New lead",
		createNewCustomer: "Create new customer",

		search: "Search",
		searchCustomer: "Please search your customer",
		selectType: "Select type",
		searchByCitizenId: "Citizen ID",
		searchByPhoneNumber: "Phone number",
		searchByCustomerId: "Customer ID",
		searchByPassportId: "Passport",

		// Lead status
		assigned: "Assigned",
		working: "Working",
		following: "Following",
		closed: "Closed",
		converted: "Converted",

		// Opp status
		targetedOpportunity: "Targeted Opportunity",
		needsAnalysis: "Needs Analysis",
		solutionDesigned: "Solution designed",
		solutionPresenting: "Solution presenting",
		submitApplicationDossier: "Submit application dossier",
		underwriteApplication: "Underwrite application",
		issueContract: "Issue Contract",
		freeLookPeriod: "Free look period",
		closedWon: "Closed Won",
		closedLost: "Closed Lost",

		warningMessageForNewCustomer:
			"Customer information is not available in Techcombank systems. Please enter a new customer information below.",
		warningMessageCustomerDoesNotHaveLeadOrOpp:
			"No lead/opp is selected yet. Please create new lead before completing this conversation.",
	},
	cs: {
		stopSearchingConfirmation: "Do you want to stop searching?",
		editBtn: "Edit",
	},
	product: {
		investmentPreferenceLabel: "Investment preference",
		pleaseSelect: "Please select",
		investmentPreferenceWarningMessage: "Please select before consulting the product",
		highInvestmentPreference: "Non-guaranteed returns Insurance product",
		lowInvestmentPreference: "Guaranteed returns Insurance product (varies by policy year)",
		goalTargetAmountMaxValueWarning: "Target amount should be greater than 0 and less than 100 bil VND",
	},
	otp: {
		otpPopupOptionsMessage:
			"For further processing, we'll need the customer's consent to share their personal information and insurance needs with our life insurance partner, Manulife Vietnam, using an OTP code. How should we send this OTP code?",
		otpPopupOptionsMessageSub1: "How should we send this OTP code?",
		refuseToTransfer: "Deny",
		sendViaMail: "Via Email",
		sendSMS: "Through SMS",
		confirm: "Verify",
		retry: "Resend OTP code",

		// eslint-disable-next-line quotes
		missingEmailFNAFlow: 'Email is not provided at "Customer Details" step',
		// eslint-disable-next-line quotes
		missingEmailRBOFlow: 'Email is not provided at "Verification" step',
		// eslint-disable-next-line quotes
		missingPhoneFNAFlow: 'Phone number is not provided at "Customer Details" step',
		// eslint-disable-next-line quotes
		missingPhoneRBOFlow: 'Phone number is not provided at "Verification" step',
		otpConfirmationMessage: "Please enter the OTP code sent to this customer.",
		otpConfirmationMessageSub1: "The OTP code will expire after 5 minutes upon request.",
		otpInputPlaceholder: "Enter OTP code",
		errorOTPaPIs: "There is an error during processing. Error code: {errorCode}. {errorMessageByLocale}",
		errorLimitOTPRequestSMS: "The request to send OTP through SMS exceed {limit} times, please try again tomorrow!",
		otpIsExpired: "OTP code is invalid or expired!",
		errorLimitOfVerifyOTP:
			"Verify OTP unsuccessfully due to input incorrectly OTP code exceed { limit } times, please try another transaction!",
		errorDroppedConsent: "Techcombank has not yet recorded customer consent to share personal information with Manulife.",
		otpIsInvalid: "OTP code is invalid or expired!",
		errorLimitOTPRequestEmail: "The request to send OTP via Email exceed {limit} times, please try again tomorrow!",
	},
}
