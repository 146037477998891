<template>
	<JBox>
		<JFlex variant="basic">
			<!-- file title and status -->
			<JFlex class="flex-col flex-grow text-sm mr-10">
				<JText class="font-semibold text-cBlack">{{ file.name }}</JText>
				<JBox v-if="file.status === FILE_STATUS.IN_PROGRESS" class="mt-4">
					<CProgressBar :percentage="file.percentage" />
				</JBox>
				<JText v-else class="capitalize mt-2" :class="statusCls">
					{{ file.status }}
					{{ file.error ? ` - ${file.error}` : "" }}
				</JText>
			</JFlex>
			<JBox>
				<!-- Actions -->
				<JButton
					v-if="file.status === FILE_STATUS.FAILED"
					variant="tertiary-rounded-inv"
					class="bg-c0-300 p-1"
					@click="handleReupload()"
				>
					<JIcon class="text-cWhite" width="12px" height="12px" icon="Refresh" />
				</JButton>
				<JButton
					v-else-if="file.status !== FILE_STATUS.UPLOADED"
					variant="tertiary-rounded-inv"
					class="bg-c0-300 p-1"
					@click="handleRemove()"
				>
					<JIcon class="text-cWhite" width="12px" height="12px" icon="X" />
				</JButton>
			</JBox>
		</JFlex>
	</JBox>
</template>

<script>
export default {
	name: "CFileRow",
	props: {
		file: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			FILE_STATUS: {
				FAILED: this.$t("core.failed"),
				IN_PROGRESS: this.$t("core.inProgress"),
				READY: this.$t("core.ready"),
				UPLOADED: this.$t("core.uploaded"),
			},
		}
	},
	computed: {
		statusCls() {
			return {
				"text-cSuccess-500": this.file.status === this.FILE_STATUS.UPLOADED,
				"text-cWarning-500": this.file.status === this.FILE_STATUS.READY,
				"text-cError-500": this.file.status === this.FILE_STATUS.FAILED,
			}
		},
	},
	methods: {
		handleReupload() {
			this.$emit("re-upload", this.file)
		},
		handleRemove() {
			this.$emit("remove", this.file)
		},
	},
}
</script>
