<template>
	<CAccordion :title="$t('core.notes')">
		<JBox class="px-5">
			<JBox v-if="notes && notes.length > 0">
				<JBox v-for="(note, i) in notes" :key="`note-${i}`">
					<NoteItem :entityId="entityId" :typename="typename" :note="note" @update="getNotes" />
				</JBox>
			</JBox>

			<!-- Empty Notes -->
			<JBox v-else>
				<JText class="text-xl text-c0-300"> {{ $t("core.notesNotFound") }} </JText>
			</JBox>

			<!-- Add Notes Button -->
			<JBox class="mt-6">
				<JButton variant="primary-sm" iconPrefix="PlusCircle" @click="toggleAddModal(true)">
					{{ $t("core.addNotes") }}
				</JButton>
			</JBox>
		</JBox>
	</CAccordion>

	<NoteModal
		:isVisible="showAddModal"
		:label="$t('core.addNew')"
		:submitBtn="$t('core.add')"
		@submit="onSubmit"
		@cancel="onCancel"
	/>
</template>
<script>
import { ref, onMounted } from "vue"
import { useI18n } from "vue-i18n"
import { handleErrorForUser } from "../../api/handleErrorForUser"
import { apiReadNote, apiCreateNote } from "./NoteComposables"

import NoteItem from "./NoteItem"
import NoteModal from "./NoteModal"
export default {
	components: { NoteItem, NoteModal },
	props: {
		entityId: {
			type: String,
			required: true,
		},

		typename: {
			type: String,
			required: true,
			validation(val) {
				return [
					"individuals",
					"companies",
					"internals",
					"organizations",
					"objects",
					"policies",
					"cases",
					"transactions",
				].includes(val)
			},
		},
	},

	setup(props) {
		const { t } = useI18n()
		// Notes
		const notes = ref([])
		const isLoading = ref(false)

		async function getNotes() {
			isLoading.value = true
			const { error, data } = await apiReadNote(props.entityId, props.typename)
			if (error) handleErrorForUser({ error, $t: t })
			else notes.value = data?.list[0]?.notes ?? []
			isLoading.value = false
		}

		async function addNote(title, content) {
			const { error } = await apiCreateNote(props.entityId, props.typename, title, content)
			if (error) handleErrorForUser({ error, $t: t })
			else {
				getNotes()
				onCancel()
			}
		}

		// Modal
		const showAddModal = ref(false)

		function toggleAddModal(option) {
			if (option) showAddModal.value = option
			else showAddModal.value = !showAddModal.value
		}

		// Button Actions
		function onCancel() {
			toggleAddModal(false)
		}

		function onSubmit(event) {
			const { title, content } = event
			addNote(title, content)
		}

		// Hooks
		onMounted(() => {
			getNotes()
		})

		return { notes, getNotes, addNote, showAddModal, toggleAddModal, onSubmit, onCancel }
	},
}
</script>
