const ViewCMSListing = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewCMSListing.vue")
const ViewCMSNew = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewCMSNew.vue")
const ViewCMSEdit = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewCMSEdit.vue")
const ViewCMSDetail = () => import(/* webpackChunkName: "group-pages" */ "../views/ViewCMSDetail.vue")

export default [
	{
		path: "/cms",
		component: ViewCMSListing,
		name: "ViewCMSListing",
		props: true,
		meta: { module: "pages", component: "ViewCMSListing", isPublic: true },
	},
	{
		path: "/cms/new",
		component: ViewCMSNew,
		name: "ViewCMSNew",
		props: true,
		meta: { module: "pages", component: "ViewCMSNew", isPublic: true },
	},
	{
		path: "/cms/detail/:id",
		component: ViewCMSDetail,
		name: "ViewCMSDetail",
		props: true,
		meta: { module: "pages", component: "ViewCMSDetail", isPublic: true },
	},
	{
		path: "/cms/edit/:id",
		component: ViewCMSEdit,
		name: "ViewCMSEdit",
		props: true,
		meta: { module: "pages", component: "ViewCMSEdit", isPublic: true },
	},
]
