// Factory function returning fetcher suitable for CoverGo GraphQL APIs
import axios from "axios"
import getEnv from "@/utils/getEnv"
import { makeAxiosGraphQLFetcher, makeAxiosRestFetcher } from "@covergo/cover-composables"

const baseURL = getEnv("VUE_APP_FILE_SYSTEM_ENDPOINT")

export const fetcher = makeAxiosGraphQLFetcher({
	url: getEnv("VUE_APP_COVERGO_GRAPHQL"),
	axios,

	getToken() {
		return localStorage[getEnv("VUE_APP_TOKEN_KEY")]
	},

	getLocale() {
		return "en-US"
	},

	debug: true,
})

export const restFetcher = makeAxiosRestFetcher({
	axios,
	baseURL,

	getToken() {
		return localStorage[getEnv("VUE_APP_TOKEN_KEY")]
	},

	getLocale() {
		return "en-US"
	},

	debug: true,
})
