import axios from "axios"
import { makeAxiosGraphQLFetcher } from "../../../fetcher"
import getEnv from "@/utils/getEnv"

export const fetcher = makeAxiosGraphQLFetcher({
	url: getEnv("VUE_APP_COVERGO_GRAPHQL"),
	axios,

	// Arm to: hacking for testing, normally we can set where to get token e.g. localStorage
	getToken() {
		return ""
	},

	getLocale() {
		return "en-US"
	},

	debug: true,
})

export const fetcherWithToken = makeAxiosGraphQLFetcher({
	url: getEnv("VUE_APP_COVERGO_GRAPHQL"),
	axios,
	timeout: 90000,

	getToken() {
		return localStorage[getEnv("VUE_APP_TOKEN_KEY")]
	},

	getLocale() {
		return "en-US"
	},

	debug: true,
})
