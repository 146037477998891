function defaultConditional() {
	return true
}

function checkEvent(e, el, binding) {
	// The include element callbacks below can be expensive
	// so we should avoid calling them when we're not active.
	// Explicitly check for false to allow fallback compatibility
	// with non-toggleable components
	if (!e || checkIsActive(e, binding) === false) return false

	// Check if additional elements were passed to be included in check
	// (click must be outside all included elements, if any)
	const elements = ((typeof binding.value === "object" && binding.value.include) || (() => []))()
	// Add the root element for the component this directive was defined on
	elements.push(el)

	// Check if it's a click outside our elements, and then if our callback returns true.
	// Non-toggleable components should take action in their callback and return falsy.
	// Toggleable can return true if it wants to deactivate.
	// Note that, because we're in the capture phase, this callback will occur before
	// the bubbling click event on any outside elements.
	return !elements.some((el) => el?.contains(e.target))
}

function checkIsActive(e, binding) {
	const isActive = (typeof binding.value === "object" && binding.value.closeConditional) || defaultConditional

	return isActive(e)
}

function directive(e, el, binding) {
	const handler = typeof binding.value === "function" ? binding.value : binding.value.handler

	el._clickOutside.lastMousedownWasOutside &&
		checkEvent(e, el, binding) &&
		setTimeout(() => {
			checkIsActive(e, binding) && handler && handler(e)
		}, 0)
}

export const ClickOutside = {
	mounted(el, binding) {
		const onClick = (e) => directive(e, el, binding)
		const onMousedown = (e) => {
			el._clickOutside.lastMousedownWasOutside = checkEvent(e, el, binding)
		}

		document.addEventListener("click", onClick, true)
		document.addEventListener("mousedown", onMousedown, true)

		if (!el._clickOutside) {
			el._clickOutside = {
				lastMousedownWasOutside: true,
			}
		}

		el._clickOutside[binding.instance.$.uid] = {
			onClick,
			onMousedown,
		}
	},

	unmounted(el, binding) {
		if (!el._clickOutside) return

		if (!document || !el._clickOutside?.[binding.instance.$.uid]) return

		const { onClick, onMousedown } = el._clickOutside[binding.instance.$.uid]

		document.removeEventListener("click", onClick, true)
		document.removeEventListener("mousedown", onMousedown, true)

		delete el._clickOutside[binding.instance.$.uid]
	},
}

export default ClickOutside
