export default {
	cms: {
		contentConfiguration: "Content configuration",
		createNew: "Create new",
		submit: "Submit",
		cancel: "Cancel",
		tenant: "Tenant",
		id: "ID",
		contentType: "Content Type",
		language: "Language",
		status: "Status",
		title: "Title",
		contentTitle: "Content title",
		subTitile: "Subtitle",
		contentSubtitle: "Content subtitle",
		thumbnail: "Thumbnail",
		thumbnailRec1: "Recommended resolution is 512x512 with file size less than 5MB;",
		thumbnailRec2: "Keep visual elements centered;",
		thumbnailRec3: "Accept file format: PNG, JPG",
		description: "Description",
		wordCount: "Word count",
		uploadFiles: "UPLOAD FILE",
		newContent: "New content",
		home: "Home page",
		product: "Product page",
		campaign: "Campaign page",
		english: "English",
		vn: "Vietnamese",
		active: "Active",
		inactive: "Inactive",
		upload: "Upload",
		url: "URL",
		delete: "Delete",
		replace: "Replace",
		schemaManagement: "Content Management",
		lastUpdated: "Last Updated",
		updateSuccess: "Update Successfully!",
		action: "Actions",
		download: "Download",
		actionText: "Action text",
		edit: "Edit",
		detailContent: "Detail Content",
		editContent: "Edit Content",
		confirmToDeleteCMS: "Are you sure you want to delete?",
		yesBtn: "Yes",
		noBtn: "No",
		"en-US": "English",
		save: "Save",
		schemaType: "Content type",
		warningMessageToFillForm: "Please finish the form",
		editItemSuccess: "Content has been edited",
		deleteSuccess: "Content has been deleted",
		createSuccess: "Content has been created",
		notAllowToDeleteItem: "Can't delete the active content!",
		errorMessageUploadThumbnailFile: "Only support for JPG, JPEG, PNG file",
		errorMessageUploadActionFile: "Only support for pdf, docs, excel, csv file",
		noContentAvailableMessage: "No content available. Please contact your administrator.",
		guidance: "Guidance",
		aboutUs: "About us",
		redirectLink: "Redirect to page",
		addUrl: "Add link",
		copySuccess: "Duplicated this content successfully",
		typeToSearch: "Type to search",
		selectType: "Select Type",
		search: "Search",
		clear: "Clear",
		createdAt: "Created At",
		all: "All",
	},
}
