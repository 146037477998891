import { merge } from "lodash"

import { translate } from "@/i18n.js"
import { normalizeParams } from "@/forms/helpers/params.js"

function i18nPlugin(node) {
	node.context = merge(node.context, {
		t(key, data) {
			return translate(key, ...normalizeParams(data))
		},
	})
}

export default i18nPlugin
