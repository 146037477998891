/* eslint-disable quotes */
export default {
	notification: {
		clickToExpand: "Xem tất cả thông báo",
		noti: "Thông báo",
		policyNo: "Số hợp đồng",
		contractor: "Chủ hợp đồng",
		paidToDate: "Ngày đến hạn thanh toán",
		paidAmount: "Số tiền cần thanh toán",
		policyPaymentDueDate: "Hợp đồng đến hạn đóng phí tuần",
		userDOBByWeek: "Khách hàng sinh nhật tuần",
		userFullName: "Họ tên khách hàng",
		userIdentify: "Mã số cá nhân",
		userDOB: "Ngày sinh nhật",
		phoneNo: "Số điện thoại",
		address: "Địa chỉ",

		// reminder list
		PaymentDueReminderWeekly: "Danh sách hợp đồng đến hạn trả phí Tuần",
		dobReminderWeekly: "Danh sách Khách hàng sinh nhật Tuần",
	},
}
