import { USER_SOURCE_TO_VIEW_POLICY_MANAGEMENT, USER_ROLES } from "@/modules/dashboard/utils/constant"

export const DEFAULT_ROLES_PERMISSION = ["default", "manager", "security", "executive", "admin"]
export const CONVERSATION_INQUIRY_ROLES_PERMISSION = ["default", "executive"]
export const POLICY_INQUIRY_ROLES_PERMISSION = ["default", "manager"]
export const DASH_BOARD_ROLES_PERMISSION = ["default", "manager", "executive"]
export const USER_LIST_ROLES = [USER_ROLES.EXECUTIVE, USER_ROLES.SECURITY, USER_ROLES.OPERATION]
export const CONFIG_SYSTEM_ROLES_PERMISSION = [USER_ROLES.ADMIN, USER_ROLES.EXECUTIVE, USER_ROLES.SECURITY]
export const ROLES_NOT_ALLOW_TO_GET_EPOS = ["executive", "manager"]
export const UPDATE_STAFF_PERMISSIONS = ["admin", USER_ROLES.OPERATION]
export const CREATE_STAFF_PERMISSIONS = ["admin", USER_ROLES.OPERATION]
export const DEFAULT_MENU = [
	{
		module: "fna",
		icon: "ClipboardList",
		text: "pages.financialNeedsAnalysis",
		href: "ViewHome",
		roles: null,
	},
	{
		module: "pages",
		icon: "ClipboardList",
		text: "pages.aboutUs",
		href: "ViewAbout",
		roles: null,
	},
	{
		module: "pages",
		icon: "ClipboardList",
		text: "pages.library",
		roles: null,
		subs: [
			{
				module: "pages",
				icon: "ClipboardList",
				text: "pages.product",
				href: "ViewProduct",
				roles: null,
			},
			{
				module: "pages",
				icon: "ClipboardList",
				text: "pages.promotion",
				href: "ViewPromotion",
				roles: null,
			},
			{
				module: "pages",
				icon: "ClipboardList",
				text: "pages.guidance",
				href: "ViewGuidance",
				roles: null,
			},
			{
				module: "pages",
				icon: "ClipboardList",
				text: "pages.referenceMaterial",
				href: "ViewForms",
				roles: null,
			},
			{
				module: "pages",
				icon: "ClipboardList",
				text: "pages.faq",
				href: "ViewFAQ",
				roles: null,
			},
		],
	},
	{
		module: "user",
		icon: "Users",
		text: "core.users",
		href: "ViewUserList",
		roles: USER_LIST_ROLES,
	},
	{
		module: "dashboard",
		icon: "ClipboardList",
		text: "dashboard.policyManagement",
		href: "ViewContracts",
		roles: POLICY_INQUIRY_ROLES_PERMISSION,
		customViewByUserSource: [...USER_SOURCE_TO_VIEW_POLICY_MANAGEMENT],
	},
	{
		module: "dashboard",
		icon: "ClipboardList",
		text: "dashboard.updateServicingAgent",
		href: "ViewManageAgentCode",
		roles: [USER_ROLES.MANAGER],
		customViewByUserSource: ["branchManager"],
	},
	{
		module: "case",
		icon: "ClipboardList",
		text: "pages.inquiry",
		href: "ViewCaseList",
		roles: CONVERSATION_INQUIRY_ROLES_PERMISSION,
	},
	{
		module: "dashboard",
		icon: "ClipboardList",
		text: "dashboard.dashboard",
		href: "ViewDashboard",
		roles: DASH_BOARD_ROLES_PERMISSION,
	},
	{
		module: "setting",
		icon: "ClipboardList",
		text: "setting.setting",
		href: "ViewTokenSetting",
		roles: CONFIG_SYSTEM_ROLES_PERMISSION,
	},
	{
		module: "pages",
		icon: "ClipboardList",
		text: "pages.contact",
		href: "ViewContact",
		roles: null,
	},
]
