/* eslint-disable no-tabs */
/* eslint-disable camelcase */
import { formatMoney } from "@/modules/fna/helpers"
import { useStore } from "vuex"
import { getSumHealthcare } from "@/modules/fna/calculation"
import { PRODUCT_NAME } from "@/modules/customerSupport/constants"

// validation
// ADD
// from 100.000.000 to 10.000.000.000
// MC
// from 200.000 to 3.000.000
// CI
// from 100.000.000 to 8.000.000.000

export const min_sa_add = 100000000 // not to be confused, we applying min_sa_add and max_sa_add for ADD-plus rider
export const max_sa_add = 10000000000 // not to be confused, we applying min_sa_add and max_sa_add for ADD-plus rider
export const default_sa_add = 125000000 // not to be confused, we applying min_sa_add and max_sa_add for ADD-plus rider
export const min_sa_mc_per_day = 200000
export const max_sa_mc_per_day = 1500000
export const max_sa_mc_for_titan_per_day = 3000000
export const min_sa_ci = 100000000
export const max_sa_ci = 8000000000
export const MIN_CAN06_SA = 100000000
export const MAX_CAN06_SA = 8000000000
export const MIN_CAN06_SA_UL040 = 100000000
export const MAX_CAN06_SA_UL040 = 8000000000
export const MAX_CAN06_SA_UL048 = 500000000

const PRODUCT_UL048 = PRODUCT_NAME.ul048
const PRODUCT_UL038 = PRODUCT_NAME.ul038
const PRODUCT_UL040 = PRODUCT_NAME.ul040
const PRODUCT_TITAN_2 = PRODUCT_NAME.titanium_2
const PRODUCT_TITAN_6_YEARS = PRODUCT_NAME.titan_6years
const PRODUCT_TITAN_10_YEARS = PRODUCT_NAME.titan_10years

const generateAccidentalDeathCoverageTooltip = ({ sa_add, t, sa_add_for_ul038, sa_add_for_titanium_2 }) => {
	const generalAccidentalDeathCoverage = sa_add_for_ul038 ? sa_add : Math.min(sa_add, max_sa_add)
	const publicDeathCoverage = sa_add_for_ul038 ? 2 * sa_add : Math.min(2 * sa_add, max_sa_add)
	const planeCrashCoverage = sa_add_for_ul038 ? 3 * sa_add : Math.min(3 * sa_add, max_sa_add)

	let toolTips = [
		`${t("fna.generalAccidentalDeathCoverage", { amount: formatMoney(generalAccidentalDeathCoverage) })}`,
		`${t("fna.publicDeathCoverage", { amount: formatMoney(publicDeathCoverage) })}`,
		`${t("fna.planeCrashCoverage", { amount: formatMoney(planeCrashCoverage) })}`,
	]

	if (sa_add_for_titanium_2) {
		toolTips = [
			`${t("fna.generalAddDeathTooltipOfLegacy", { amount: formatMoney(generalAccidentalDeathCoverage) })}`,
			`${t("fna.publicAddDeathTooltipOfLegacy")}`,
		]
	}
	return toolTips
}
const generateAccidentalDismembermentCoverageTooltip = ({ sa_add_plus, sa, t }) => {
	const generalAccidentalDeathCoverage = sa_add_plus
	const publicDeathCoverage = 2 * sa_add_plus
	const planeCrashCoverage = 3 * sa_add_plus

	const toolTips = [
		`${t("fna.generalAccidentalDeathCoverage", { amount: formatMoney(generalAccidentalDeathCoverage) })}`,
		`${t("fna.publicDeathCoverage", { amount: formatMoney(publicDeathCoverage) })}`,
		`${t("fna.planeCrashCoverage", { amount: formatMoney(planeCrashCoverage) })}`,
		`${t("fna.payDueToAccident", { amount: formatMoney(sa) })}`,
	]
	return toolTips
}
const generateMedicalSupportCoverageTooltip = ({ sa_mc, t, sa_mc_for_titan, sa_mc_for_titanium_2 }) => {
	const result = [`${t("fna.benefitMaximumPay", { amount: formatMoney(sa_mc) })}`, `${t("fna.benefitDoublePayment")}`]

	if (sa_mc_for_titanium_2) {
		result.push(`${t("fna.maxPaidDay")}`)
		result.push(`${t("fna.doublePayment")}`)
	}

	return result
}
const generateMedicalSupportCoverageTooltipForTitan = ({ sa, t }) => {
	return [`${t("fna.medicalCashTooltipTitan", { amount: formatMoney(sa) })}`]
}
const generateCriticalIllnessCoverageTooltip = ({ sa_ci, t, sa_ci_for_edu }) => {
	const updated_sa_ci = sa_ci_for_edu ? 1.5 * sa_ci : 1.25 * sa_ci
	const capped_sa_ci = updated_sa_ci >= max_sa_ci ? max_sa_ci : updated_sa_ci
	const updated_fna = sa_ci_for_edu ? "fna.criticalIllnessProtectionForEdu" : "fna.criticalIllnessProtection"

	return [`${t(updated_fna, { amount: formatMoney(capped_sa_ci) })}`]
}
const generatePrenatalTooltip = ({ t, sa_prenatal }) => {
	const prenatalMaxAmount = Number(sa_prenatal) * 30
	const prenatalDeathAmount = Number(sa_prenatal) * 25
	const prenatalDisorderAmount = Number(sa_prenatal) * 25
	return [
		`${t("fna.prenatalTotalBenefits", { prenatalAmount: formatMoney(sa_prenatal) })} ${t("fna.prenatalMaxAmount", {
			prenatalMaxAmount: formatMoney(prenatalMaxAmount),
		})}`,
		`${t("fna.prenatalDeathCoverage", { prenatalDeathAmount: formatMoney(prenatalDeathAmount) })}`,
		`${t("fna.prenatalCongenitalDisorder", { prenatalDisorderAmount: formatMoney(prenatalDisorderAmount) })}`,
	]
}
const generateLateStageCancerTooltip = ({ saCAN06, t }) => {
	if (originalProductName === PRODUCT_UL040) return [`${t("fna.lateStageCancerOfUL040MaxSATT")}`]
	if (originalProductName === PRODUCT_UL048) return [`${t("fna.lateStageCancerOfUL048MaxSATT")}`]

	return [`${t("fna.lateStageCancerMaxSATT")}`]
}

const healthcareTooltip = ({ t, productName, params }) => {
	return [
		`${t("fna.totalBenefitHealthcareTT")}`,
		`${t("fna.inpatientMaxAmountTT", { amount: formatMoney(params?.sa_hc_inpatient) })}`,
		`${t("fna.outpatientMaxAmountTT", { amount: formatMoney(params?.sa_hc_outpatient) })}`,
		`${t("fna.dentalMaxAmountTT", { amount: formatMoney(params?.sa_hc_dental) })}`,
	]
}
const totalBenefitToolTipWithHealthcarePackage = ({ t, productName, productNoteByRider = "", params }) => {
	return [
		`${t(productNoteByRider, {
			outpatient: params?.sa_hc_outpatient ? `${t("fna.amountOutpatient")}` : "",
			dental: params?.sa_hc_dental ? `${t("fna.amountDental")}` : "",
		})}`,
	]
}

let originalProductName = ""

export const benefitsCalculate = ({
	productName,
	riders = [],
	sa,
	sa_add,
	sa_mc,
	sa_ci,
	sa_hc,
	sa_prenatal,
	sa_add_plus,
	t,
	...params
}) => {
	riders = riders.filter((i) => i !== "DeathCoverage" && i !== "ADD")

	// overwrite product name, we treats UL040 as UL038 here to calculate the benefits
	originalProductName = productName
	if (productName === PRODUCT_UL040) {
		productName = PRODUCT_UL038
	}

	if (productName === PRODUCT_UL038) {
		if (sa < 100000000) sa = 100000000
	}

	// overwrite product options from user input
	if (productName === PRODUCT_TITAN_6_YEARS || productName === PRODUCT_TITAN_10_YEARS) {
		if (sa > 5000000000) sa = 5000000000
		if (sa < 100000000) sa = 100000000
		sa_add = getADD(sa)
		sa_mc = getMedicalSupportForTitan(sa)
		sa_ci = getCI(sa)
		sa_hc = getHC()
	} else if ([PRODUCT_UL038, PRODUCT_TITAN_2].includes(productName)) {
		sa_add = sa_add ?? getADD(sa)
		sa_mc = sa_mc ?? getMedicalSupport(sa)
		sa_ci = sa_ci ?? getCI(sa)
		sa_hc = sa_hc ?? getHC()
		sa_add_plus = sa_add_plus ?? getADDPlus()
	} else {
		sa_add = sa_add ?? getADD(sa)
		sa_mc = sa_mc ?? getMedicalSupport(sa)
		sa_ci = sa_ci ?? getCI(sa)
		sa_hc = sa_hc ?? getHC()
	}

	const defaultBenefits = {
		totalBenefit: null,
		deathCoverage: null,
		accidentalDeath: null,
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: null,
	}

	if (!productName || !riders || !sa) return defaultBenefits

	// map by rider keys
	const calculationHandlerMapping = [
		/** ul048 */
		{
			match: ({ name, riders }) => name === PRODUCT_UL048,
			calculate: ul048,
		},

		/** Both Titan 6 and 10 years use one calculate func */
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_6_YEARS && riders.includes("MC") && riders.length === 1,
			calculate: titan,
		},
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_10_YEARS && riders.includes("MC") && riders.length === 1,
			calculate: titan,
		},

		// 2019 Universal Life (UL038)
		/** ul038 */
		{
			match: ({ name, riders }) => name === PRODUCT_UL038 && riders.length === 0,
			calculate: ul038,
		},
		/** ul038 - MC */
		{
			match: ({ name, riders }) => name === PRODUCT_UL038 && riders.includes("MC") && riders.length === 1,
			calculate: ul038_MC,
		},
		/** ul038 - CI */
		{
			match: ({ name, riders }) => name === PRODUCT_UL038 && riders.includes("CI") && riders.length === 1,
			calculate: ul038_CI,
		},
		/** ul038 - CI-plus */
		{
			match: ({ name, riders }) => name === PRODUCT_UL038 && riders.includes("CI-plus") && riders.length === 1,
			calculate: ul038_CI,
		},
		/** ul038 - HC */
		{
			match: ({ name, riders }) => name === PRODUCT_UL038 && riders.includes("HC") && riders.length === 1,
			calculate: ul038_HC,
		},
		/** ul038 - PRENATAL */
		{
			match: ({ name, riders }) => name === PRODUCT_UL038 && riders.includes("PRENATAL") && riders.length === 1,
			calculate: ul038_PRENATAL,
		},
		/** ul038 - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) => name === PRODUCT_UL038 && riders.includes("ADD-plus") && riders.length === 1,
			calculate: ul038_ACDDISMEMBERMENT,
		},
		/** ul038 - MC - CI */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("MC") && riders.includes("CI") && riders.length === 2,
			calculate: ul038_MC_CI,
		},
		/** ul038 - MC - CI-plus */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("MC") && riders.includes("CI-plus") && riders.length === 2,
			calculate: ul038_MC_CI,
		},
		/** ul038 - CI - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("CI") && riders.includes("HC") && riders.length === 2,
			calculate: ul038_CI_HC,
		},
		/** ul038 - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("CI-plus") && riders.includes("HC") && riders.length === 2,
			calculate: ul038_CI_HC,
		},
		/** ul038 - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("MC") && riders.includes("HC") && riders.length === 2,
			calculate: ul038_MC_HC,
		},
		/** ul038 - MC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("MC") && riders.includes("PRENATAL") && riders.length === 2,
			calculate: ul038_MC_PRENATAL,
		},
		/** ul038 - HC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("HC") && riders.includes("PRENATAL") && riders.length === 2,
			calculate: ul038_HC_PRENATAL,
		},
		/** ul038 - CI - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("CI") && riders.includes("PRENATAL") && riders.length === 2,
			calculate: ul038_CI_PRENATAL,
		},
		/** ul038 - CI-plus - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("CI-plus") && riders.includes("PRENATAL") && riders.length === 2,
			calculate: ul038_CI_PRENATAL,
		},
		/** ul038 - CI-plus - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("CI-plus") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: ul038_CI_ACDDISMEMBERMENT,
		},
		/** ul038 - MC - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("MC") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: ul038_MC_ACDDISMEMBERMENT,
		},
		/** ul038 - HC - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("HC") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: ul038_HC_ACDDISMEMBERMENT,
		},
		/** ul038 - CI - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("CI") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: ul038_CI_ACDDISMEMBERMENT,
		},
		/** ul038 - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("PRENATAL") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: ul038_PRENATAL_ACDDISMEMBERMENT,
		},
		/** ul038 - MC - CI - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("MC") && riders.includes("CI") && riders.includes("HC") && riders.length === 3,
			calculate: ul038_MC_CI_HC,
		},
		/** ul038 - MC - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.length === 3,
			calculate: ul038_MC_CI_HC,
		},
		/** ul038 - MC_CI-plus_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: ul038_MC_CI_PRENATAL,
		},
		/** ul038 - MC_CI_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: ul038_MC_CI_PRENATAL,
		},
		/** ul038 - MC_HC_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: ul038_MC_HC_PRENATAL,
		},
		/** ul038 - HC_CI_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: ul038_HC_CI_PRENATAL,
		},
		/** ul038 - HC_CI-plus_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: ul038_HC_CI_PRENATAL,
		},

		/** ul038 - MC_CI-plus_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_MC_CI_ACDDISMEMBERMENT,
		},
		/** ul038 - MC_CI_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_MC_CI_ACDDISMEMBERMENT,
		},
		/** ul038 - MC_HC_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_MC_HC_ACDDISMEMBERMENT,
		},
		/** ul038 - HC_CI_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_HC_CI_ACDDISMEMBERMENT,
		},
		/** ul038 - HC_CI-plus_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_HC_CI_ACDDISMEMBERMENT,
		},

		/** ul038 - CI_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_CI_PRENATAL_ACDDISMEMBERMENT,
		},
		/** ul038 - CI-plus_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_CI_PRENATAL_ACDDISMEMBERMENT,
		},

		/** ul038 - MC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_MC_PRENATAL_ACDDISMEMBERMENT,
		},
		/** ul038 - HC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: ul038_HC_PRENATAL_ACDDISMEMBERMENT,
		},

		/** ul038 - MC - HC- CI - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.length === 4,
			calculate: ul038_MC_HC_CI_PRENATAL,
		},
		/** ul038 - MC -  HC - CI-plus -PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.length === 4,
			calculate: ul038_MC_HC_CI_PRENATAL,
		},

		/** ul038 - MC - HC- CI - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: ul038_MC_HC_CI_ACDDISMEMBERMENT,
		},
		/** ul038 - MC - HC- PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: ul038_MC_HC_PRENATAL_ACDDISMEMBERMENT,
		},
		/** ul038 - MC -  HC - CI-plus -ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: ul038_MC_HC_CI_ACDDISMEMBERMENT,
		},

		/** ul038 - HC- CI - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: ul038_HC_CI_PRENATAL_ACDDISMEMBERMENT,
		},
		/** ul038 - HC - CI-plus - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: ul038_HC_CI_PRENATAL_ACDDISMEMBERMENT,
		},

		/** ul038 - MC- CI - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: ul038_MC_CI_PRENATAL_ACDDISMEMBERMENT,
		},
		/** ul038 - MC - CI-plus - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: ul038_MC_CI_PRENATAL_ACDDISMEMBERMENT,
		},

		/** ul038 - MC_HC_CI_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.length === 5,
			calculate: ul038_MC_HC_CI_PRENATAL_ACDDISMEMBERMENT,
		},
		/** ul038 - MC_HC_CI-plus_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.length === 5,
			calculate: ul038_MC_HC_CI_PRENATAL_ACDDISMEMBERMENT,
		},

		/** ul038 with CAN06 are below */
		{
			match: ({ name, riders }) => name === PRODUCT_UL038 && riders.includes("CAN06") && riders.length === 1,
			calculate: ul038_CAN06,
		},
		/** ul038 - MC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("MC") && riders.includes("CAN06") && riders.length === 2,
			calculate: ul038_MC_CAN06,
		},
		/** ul038 - CI */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("CI") && riders.includes("CAN06") && riders.length === 2,
			calculate: ul038_CI_CAN06,
		},
		/** ul038 - CI-plus */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("CI-plus") && riders.includes("CAN06") && riders.length === 2,
			calculate: ul038_CI_CAN06,
		},
		/** ul038 - CI-plus */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("HC") && riders.includes("CAN06") && riders.length === 2,
			calculate: ul038_HC_CAN06,
		},
		/** ul038 - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("PRENATAL") && riders.includes("CAN06") && riders.length === 2,
			calculate: ul038_PRENATAL_CAN06,
		},
		/** ul038 - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 && riders.includes("ADD-plus") && riders.includes("CAN06") && riders.length === 2,
			calculate: ul038_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC - CI */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_MC_CI_CAN06,
		},
		/** ul038 - MC - CI-plus */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_MC_CI_CAN06,
		},
		/** ul038 - CI - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("CI") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_CI_HC_CAN06,
		},
		/** ul038 - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_CI_HC_CAN06,
		},
		/** ul038 - MC - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_MC_HC_CAN06,
		},
		/** ul038 - MC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_MC_PRENATAL_CAN06,
		},
		/** ul038 - HC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_HC_PRENATAL_CAN06,
		},
		/** ul038 - CI - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_CI_PRENATAL_CAN06,
		},
		/** ul038 - CI-plus - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_CI_PRENATAL_CAN06,
		},
		/** ul038 - CI-plus - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_MC_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - HC - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_HC_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - CI - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: ul038_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC - CI - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_CI_HC_CAN06,
		},
		/** ul038 - MC - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_CI_HC_CAN06,
		},
		/** ul038 - MC_CI-plus_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_CI_PRENATAL_CAN06,
		},
		/** ul038 - MC_CI_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_CI_PRENATAL_CAN06,
		},
		/** ul038 - MC_HC_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_HC_PRENATAL_CAN06,
		},
		/** ul038 - HC_CI_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_HC_CI_PRENATAL_CAN06,
		},
		/** ul038 - HC_CI-plus_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_HC_CI_PRENATAL_CAN06,
		},

		/** ul038 - MC_CI-plus_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC_CI_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC_HC_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_HC_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - HC_CI_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_HC_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - HC_CI-plus_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_HC_CI_ACDDISMEMBERMENT_CAN06,
		},

		/** ul038 - CI_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - CI-plus_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** ul038 - MC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_MC_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - HC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: ul038_HC_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** ul038 - MC - HC- CI - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_MC_HC_CI_PRENATAL_CAN06,
		},
		/** ul038 - MC -  HC - CI-plus -PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_MC_HC_CI_PRENATAL_CAN06,
		},

		/** ul038 - MC - HC- CI - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_MC_HC_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC - HC- PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_MC_HC_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC -  HC - CI-plus -ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_MC_HC_CI_ACDDISMEMBERMENT_CAN06,
		},

		/** ul038 - HC- CI - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - HC - CI-plus - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** ul038 - MC- CI - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_MC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC - CI-plus - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: ul038_MC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** ul038 - MC_HC_CI_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 6,
			calculate: ul038_MC_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** ul038 - MC_HC_CI-plus_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_UL038 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 6,
			calculate: ul038_MC_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		// Titan 2.0
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_2 && riders.length === 0,
			calculate: titanium_2,
		},
		/** titanium_2 - MC */
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_2 && riders.includes("MC") && riders.length === 1,
			calculate: titanium_2_MC,
		},
		/** titanium_2 - CI */
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_2 && riders.includes("CI") && riders.length === 1,
			calculate: titanium_2_CI,
		},
		/** titanium_2 - CI-plus */
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_2 && riders.includes("CI-plus") && riders.length === 1,
			calculate: titanium_2_CI,
		},
		/** titanium_2 - HC */
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_2 && riders.includes("HC") && riders.length === 1,
			calculate: titanium_2_HC,
		},
		/** titanium_2 - PRENATAL */
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_2 && riders.includes("PRENATAL") && riders.length === 1,
			calculate: titanium_2_PRENATAL,
		},
		/** titanium_2 - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_2 && riders.includes("ADD-plus") && riders.length === 1,
			calculate: titanium_2_ACDDISMEMBERMENT,
		},
		/** titanium_2 - MC - CI */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("MC") && riders.includes("CI") && riders.length === 2,
			calculate: titanium_2_MC_CI,
		},
		/** titanium_2 - MC - CI-plus */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("MC") && riders.includes("CI-plus") && riders.length === 2,
			calculate: titanium_2_MC_CI,
		},
		/** titanium_2 - CI - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("CI") && riders.includes("HC") && riders.length === 2,
			calculate: titanium_2_CI_HC,
		},
		/** titanium_2 - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("CI-plus") && riders.includes("HC") && riders.length === 2,
			calculate: titanium_2_CI_HC,
		},
		/** titanium_2 - MC - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("MC") && riders.includes("HC") && riders.length === 2,
			calculate: titanium_2_MC_HC,
		},
		/** titanium_2 - MC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("MC") && riders.includes("PRENATAL") && riders.length === 2,
			calculate: titanium_2_MC_PRENATAL,
		},
		/** titanium_2 - CI - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("CI") && riders.includes("PRENATAL") && riders.length === 2,
			calculate: titanium_2_CI_PRENATAL,
		},
		/** titanium_2 - CI-plus - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("CI-plus") && riders.includes("PRENATAL") && riders.length === 2,
			calculate: titanium_2_CI_PRENATAL,
		},
		/** titanium_2 - HC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("HC") && riders.includes("PRENATAL") && riders.length === 2,
			calculate: titanium_2_HC_PRENATAL,
		},

		/** titanium_2 - CI-plus - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("CI-plus") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: titanium_2_CI_ACDDISMEMBERMENT,
		},
		/** titanium_2 - MC - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("MC") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: titanium_2_MC_ACDDISMEMBERMENT,
		},
		/** titanium_2 - HC - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("HC") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: titanium_2_HC_ACDDISMEMBERMENT,
		},
		/** titanium_2 - CI - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("CI") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: titanium_2_CI_ACDDISMEMBERMENT,
		},
		/** titanium_2 - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("PRENATAL") && riders.includes("ADD-plus") && riders.length === 2,
			calculate: titanium_2_PRENATAL_ACDDISMEMBERMENT,
		},

		/** titanium_2 - MC - CI - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("HC") &&
				riders.length === 3,
			calculate: titanium_2_MC_CI_HC,
		},
		/** titanium_2 - MC - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.length === 3,
			calculate: titanium_2_MC_CI_HC,
		},
		/** titanium_2 - MC_CI-plus_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: titanium_2_MC_CI_PRENATAL,
		},
		/** titanium_2 - MC_CI_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: titanium_2_MC_CI_PRENATAL,
		},
		/** titanium_2 - MC_HC_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: titanium_2_MC_HC_PRENATAL,
		},
		/** titanium_2 - HC_CI_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: titanium_2_HC_CI_PRENATAL,
		},
		/** titanium_2 - HC_CI-plus_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.length === 3,
			calculate: titanium_2_HC_CI_PRENATAL,
		},

		/** titanium_2 - MC_CI-plus_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_MC_CI_ACDDISMEMBERMENT,
		},
		/** titanium_2 - MC_CI_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_MC_CI_ACDDISMEMBERMENT,
		},
		/** titanium_2 - MC_HC_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_MC_HC_ACDDISMEMBERMENT,
		},
		/** titanium_2 - HC_CI_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_HC_CI_ACDDISMEMBERMENT,
		},
		/** titanium_2 - HC_CI-plus_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_HC_CI_ACDDISMEMBERMENT,
		},

		/** titanium_2 - CI_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_CI_PRENATAL_ACDDISMEMBERMENT,
		},
		/** titanium_2 - CI-plus_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_CI_PRENATAL_ACDDISMEMBERMENT,
		},

		/** titanium_2 - MC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_MC_PRENATAL_ACDDISMEMBERMENT,
		},
		/** titanium_2 - HC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.length === 3,
			calculate: titanium_2_HC_PRENATAL_ACDDISMEMBERMENT,
		},

		/** titanium_2 - MC - CI-plus - HC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_HC_PRENATAL,
		},
		/** titanium_2 - MC - CI - HC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_HC_PRENATAL,
		},

		/** titanium_2 - MC - HC- CI - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_HC_ACDDISMEMBERMENT,
		},
		/** titanium_2 - MC - HC- PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: titanium_2_MC_HC_PRENATAL_ACDDISMEMBERMENT,
		},
		/** titanium_2 - MC -  HC - CI-plus -ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_HC_ACDDISMEMBERMENT,
		},

		/** titanium_2 - HC- CI - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: titanium_2_HC_CI_PRENATAL_ACDDISMEMBERMENT,
		},
		/** titanium_2 - HC - CI-plus - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: titanium_2_HC_CI_PRENATAL_ACDDISMEMBERMENT,
		},

		/** titanium_2 - MC- CI - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_PRENATAL_ACDDISMEMBERMENT,
		},
		/** titanium_2 - MC - CI-plus - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_PRENATAL_ACDDISMEMBERMENT,
		},

		/** titanium_2  - MC_CI_HC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.length === 5,
			calculate: titanium_2_MC_CI_HC_PRENATAL_ACDDISMEMBERMENT,
		},
		/** titanium_2  - MC_CI-plus_HC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.length === 5,
			calculate: titanium_2_MC_CI_HC_PRENATAL_ACDDISMEMBERMENT,
		},

		// Titan 2.0 with CAN06 are below
		{
			match: ({ name, riders }) => name === PRODUCT_TITAN_2 && riders.includes("CAN06") && riders.length === 1,
			calculate: titanium_2_CAN06,
		},
		/** titanium_2 - MC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("MC") && riders.includes("CAN06") && riders.length === 2,
			calculate: titanium_2_MC_CAN06,
		},
		/** titanium_2 - CI */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("CI") && riders.includes("CAN06") && riders.length === 2,
			calculate: titanium_2_CI_CAN06,
		},
		/** titanium_2 - CI-plus */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("CI-plus") && riders.includes("CAN06") && riders.length === 2,
			calculate: titanium_2_CI_CAN06,
		},
		/** titanium_2 - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("HC") && riders.includes("CAN06") && riders.length === 2,
			calculate: titanium_2_HC_CAN06,
		},
		/** titanium_2 - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("PRENATAL") && riders.includes("CAN06") && riders.length === 2,
			calculate: titanium_2_PRENATAL_CAN06,
		},
		/** titanium_2 - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 && riders.includes("ADD-plus") && riders.includes("CAN06") && riders.length === 2,
			calculate: titanium_2_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - MC - CI */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_MC_CI_CAN06,
		},
		/** titanium_2 - MC - CI-plus */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_MC_CI_CAN06,
		},
		/** titanium_2 - CI - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("CI") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_CI_HC_CAN06,
		},
		/** titanium_2 - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_CI_HC_CAN06,
		},
		/** titanium_2 - MC - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_MC_HC_CAN06,
		},
		/** titanium_2 - MC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_MC_PRENATAL_CAN06,
		},
		/** titanium_2 - CI - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_CI_PRENATAL_CAN06,
		},
		/** titanium_2 - CI-plus - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_CI_PRENATAL_CAN06,
		},
		/** titanium_2 - HC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_HC_PRENATAL_CAN06,
		},

		/** titanium_2 - CI-plus - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - MC - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_MC_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - HC - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_HC_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - CI - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 3,
			calculate: titanium_2_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** titanium_2 - MC - CI - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_HC_CAN06,
		},
		/** titanium_2 - MC - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_HC_CAN06,
		},
		/** titanium_2 - MC_CI-plus_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_PRENATAL_CAN06,
		},
		/** titanium_2 - MC_CI_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_PRENATAL_CAN06,
		},
		/** titanium_2 - MC_HC_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_HC_PRENATAL_CAN06,
		},
		/** titanium_2 - HC_CI_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_HC_CI_PRENATAL_CAN06,
		},
		/** titanium_2 - HC_CI-plus_PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_HC_CI_PRENATAL_CAN06,
		},

		/** titanium_2 - MC_CI-plus_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - MC_CI_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - MC_HC_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_HC_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - HC_CI_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_HC_CI_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - HC_CI-plus_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_HC_CI_ACDDISMEMBERMENT_CAN06,
		},

		/** titanium_2 - CI_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - CI-plus_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** titanium_2 - MC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_MC_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - HC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 4,
			calculate: titanium_2_HC_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** titanium_2 - MC - CI-plus - HC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_MC_CI_HC_PRENATAL_CAN06,
		},
		/** titanium_2 - MC - CI - HC - PRENATAL */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_MC_CI_HC_PRENATAL_CAN06,
		},

		/** titanium_2 - MC - HC- CI - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_MC_CI_HC_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - MC - HC- PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_MC_HC_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - MC -  HC - CI-plus -ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_MC_CI_HC_ACDDISMEMBERMENT_CAN06,
		},

		/** titanium_2 - HC- CI - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - HC - CI-plus - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** titanium_2 - MC- CI - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_MC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2 - MC - CI-plus - PRENATAL - ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("PRENATAL") &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 5,
			calculate: titanium_2_MC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		/** titanium_2  - MC_CI_HC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 6,
			calculate: titanium_2_MC_CI_HC_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},
		/** titanium_2  - MC_CI-plus_HC_PRENATAL_ACDDISMEMBERMENT */
		{
			match: ({ name, riders }) =>
				name === PRODUCT_TITAN_2 &&
				riders.includes("MC") &&
				riders.includes("HC") &&
				riders.includes("CI-plus") &&
				riders.includes("PRENATAL") &&
				riders.includes("ADD-plus") &&
				riders.includes("CAN06") &&
				riders.length === 6,
			calculate: titanium_2_MC_CI_HC_PRENATAL_ACDDISMEMBERMENT_CAN06,
		},

		// 2017 Unit Linked (ILP)
		/** unitLinked */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.length === 0,
			calculate: unitLinked,
		},
		/** unitLinked - ADD */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.includes("ADD") && riders.length === 1,
			calculate: unitLinked_ADD,
		},
		/** unitLinked - MC */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.includes("MC") && riders.length === 1,
			calculate: unitLinked_MC,
		},
		/** unitLinked - HC */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.includes("HC") && riders.length === 1,
			calculate: unitLinked_HC,
		},
		/** unitLinked - CI */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.includes("CI") && riders.length === 1,
			calculate: unitLinked_CI,
		},
		/** unitLinked - CI-plus */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.includes("CI-plus") && riders.length === 1,
			calculate: unitLinked_CI,
		},
		/** unitLinked - ADD - MC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("ADD") && riders.includes("MC") && riders.length === 2,
			calculate: unitLinked_ADD_MC,
		},
		/** unitLinked - ADD - CI */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("ADD") && riders.includes("CI") && riders.length === 2,
			calculate: unitLinked_ADD_CI,
		},
		/** unitLinked - ADD - CI-plus */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("ADD") && riders.includes("CI-plus") && riders.length === 2,
			calculate: unitLinked_ADD_CI,
		},
		/** unitLinked - ADD - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("ADD") && riders.includes("HC") && riders.length === 2,
			calculate: unitLinked_ADD_HC,
		},
		/** unitLinked - ADD - MC - CI */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("ADD") && riders.includes("MC") && riders.includes("CI") && riders.length === 3,
			calculate: unitLinked_ADD_MC_CI,
		},
		/** unitLinked - ADD - MC - CI-plus */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" &&
				riders.includes("ADD") &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.length === 3,
			calculate: unitLinked_ADD_MC_CI,
		},
		/** unitLinked - ADD - CI - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("ADD") && riders.includes("CI") && riders.includes("HC") && riders.length === 3,
			calculate: unitLinked_ADD_CI_HC,
		},
		/** unitLinked - ADD - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" &&
				riders.includes("ADD") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.length === 3,
			calculate: unitLinked_ADD_CI_HC,
		},
		/** unitLinked - ADD - MC - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("ADD") && riders.includes("MC") && riders.includes("HC") && riders.length === 3,
			calculate: unitLinked_ADD_MC_HC,
		},
		/** unitLinked - MC - CI */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.includes("MC") && riders.includes("CI") && riders.length === 2,
			calculate: unitLinked_MC_CI,
		},
		/** unitLinked - MC - CI-plus */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("MC") && riders.includes("CI-plus") && riders.length === 2,
			calculate: unitLinked_MC_CI,
		},
		/** unitLinked - MC - HC */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.includes("MC") && riders.includes("HC") && riders.length === 2,
			calculate: unitLinked_MC_HC,
		},
		/** unitLinked - CI - HC */
		{
			match: ({ name, riders }) => name === "unitLinked" && riders.includes("HC") && riders.includes("CI") && riders.length === 2,
			calculate: unitLinked_CI_HC,
		},
		/** unitLinked - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("HC") && riders.includes("CI-plus") && riders.length === 2,
			calculate: unitLinked_CI_HC,
		},
		/** unitLinked - MC - CI - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" && riders.includes("MC") && riders.includes("CI") && riders.includes("HC") && riders.length === 3,
			calculate: unitLinked_MC_CI_HC,
		},
		/** unitLinked - MC - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.length === 3,
			calculate: unitLinked_MC_CI_HC,
		},
		/** unitLinked - ADD - MC - CI - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" &&
				riders.includes("ADD") &&
				riders.includes("MC") &&
				riders.includes("CI") &&
				riders.includes("HC") &&
				riders.length === 4,
			calculate: unitLinked_ADD_MC_CI_HC,
		},
		/** unitLinked - ADD - MC - CI-plus - HC */
		{
			match: ({ name, riders }) =>
				name === "unitLinked" &&
				riders.includes("ADD") &&
				riders.includes("MC") &&
				riders.includes("CI-plus") &&
				riders.includes("HC") &&
				riders.length === 4,
			calculate: unitLinked_ADD_MC_CI_HC,
		},

		/** fallback */
		{
			match: ({ name, riders = [] }) => {
				console.log(`No benefits calculation available for product ${name} with riders "${riders}"`)
				return true
			},
			calculate: () => defaultBenefits,
		},
	]

	return calculationHandlerMapping
		.find((handler) => handler.match({ name: productName, riders }))
		.calculate({
			sa,
			sa_add,
			sa_mc,
			sa_ci,
			sa_hc,
			sa_prenatal,
			sa_add_plus,
			t,
			riders,
			...params,
		})
}

// UL048
function ul048({ sa, sa_add, saCAN06 = MAX_CAN06_SA_UL048, t }) {
	console.log(PRODUCT_NAME.ul048)
	const totalBenefit = sa + saCAN06 + sa_add

	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul048")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}

// Titan
function titan({ sa, sa_add, sa_mc, t }) {
	console.log("titan_MC") // Titan calculates sa_mc fron sa value
	const totalBenefit = sa + sa_add + 1000 * Math.min(0.002 * sa, 3000000)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titan")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t }),
		},
		medicalSupport: {
			value: Math.min(0.002 * sa, 3000000),
			notes: generateMedicalSupportCoverageTooltipForTitan({ sa, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
	}
}

// 2019 Universal Life (UL38)
function ul038({ sa, sa_add, sa_mc, sa_add_plus, t }) {
	console.log(PRODUCT_NAME.ul038)
	const totalBenefit = 2 * sa
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_ul038")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: null,
	}
}
function ul038_MC({ sa, sa_add, sa_mc, t }) {
	console.log("ul038_MC")
	const totalBenefit = 2 * sa + 1000 * sa_mc
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function ul038_CI({ sa, sa_add, sa_ci, t, riders }) {
	console.log("ul038_CI")
	const totalBenefit = 2 * sa + 1.25 * sa_ci
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CI")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}

	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_HC({ sa, sa_add, sa_hc, t, ...params }) {
	console.log("ul038_HC")
	const totalBenefit = 2 * sa + (sa_hc || 0)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function ul038_MC_CI({ sa, sa_add, sa_mc, sa_ci, t, riders }) {
	console.log("ul038_MC_CI")
	const totalBenefit = 2 * sa + 1000 * sa_mc + 1.25 * sa_ci
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CI")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_CI_HC({ sa, sa_add, sa_ci, sa_hc, t, riders, ...params }) {
	console.log("ul038_CI_HC")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + sa_hc
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_CI",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_HC({ sa, sa_add, sa_mc, sa_hc, t, ...params }) {
	console.log("ul038_MC_HC")
	const totalBenefit = 2 * sa + 1000 * sa_mc + sa_hc
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function ul038_MC_CI_HC({ sa, sa_add, sa_mc, sa_ci, sa_hc, t, riders, ...params }) {
	console.log("ul038_MC_CI_HC")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * sa_mc + sa_hc
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_CI_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}

function ul038_PRENATAL({ sa, sa_add, sa_prenatal, t }) {
	console.log("ul038_PRENATAL")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		accidentDismemberment: null,
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_MC_PRENATAL({ sa, sa_add, sa_mc, sa_prenatal, t }) {
	console.log("ul038_MC_PRENATAL")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		accidentDismemberment: null,
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_HC_PRENATAL({ sa, sa_add, sa_hc, sa_prenatal, t, ...params }) {
	console.log("ul038_HC_PRENATAL")
	const totalBenefit = 2 * sa + (Number(sa_hc) || 0) + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		accidentDismemberment: null,
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_CI_PRENATAL({ sa, sa_add, sa_ci, sa_prenatal, t, riders }) {
	console.log("ul038_CI_PRENATAL")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal)
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CI_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_CI_PRENATAL({ sa, sa_add, sa_mc, sa_ci, sa_prenatal, t, riders }) {
	console.log("ul038_MC_CI_PRENATAL")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 1.25 * sa_ci + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CI_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_MC_HC_PRENATAL({ sa, sa_add, sa_mc, sa_hc, sa_prenatal, t, ...params }) {
	console.log("ul038_MC_HC_PRENATAL")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_HC_CI_PRENATAL({ sa, sa_add, sa_hc, sa_ci, sa_prenatal, t, riders, ...params }) {
	console.log("ul038_HC_CI_PRENATAL")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + Number(sa_hc) + 30 * Number(sa_prenatal)
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_CI_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_HC_CI_PRENATAL({ sa, sa_add, sa_mc, sa_hc, sa_ci, sa_prenatal, t, riders, ...params }) {
	console.log("ul038_MC_HC_CI_PRENATAL")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal)
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_CI_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// ADD-plus (sa_add_plus): is the portion of ADD (accidental Death) -> if user selects the option of accident and disability at step 3 will show up the data of ADD-plus
// wording benefits are the same as ADD (accidental death)
function ul038_ACDDISMEMBERMENT({ sa, sa_add, sa_add_plus, t }) {
	console.log("ul038_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
// 2
function ul038_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_prenatal, sa_add_plus, t }) {
	console.log("ul038_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_MC_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_add_plus, t }) {
	console.log("ul038_MC_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function ul038_HC_ACDDISMEMBERMENT({ sa, sa_add, sa_hc, sa_add_plus, t, ...params }) {
	console.log("ul038_HC_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + (Number(sa_hc) || 0) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function ul038_CI_ACDDISMEMBERMENT({ sa, sa_add, sa_ci, sa_add_plus, t, riders }) {
	console.log("ul038_CI_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CI")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 3
function ul038_MC_CI_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_ci, sa_add_plus, t, riders }) {
	console.log("ul038_MC_CI_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 1.25 * sa_ci + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CI")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_HC_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_hc, sa_add_plus, t, ...params }) {
	console.log("ul038_MC_HC_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function ul038_HC_CI_ACDDISMEMBERMENT({ sa, sa_add, sa_hc, sa_ci, sa_add_plus, t, riders, ...params }) {
	console.log("ul038_HC_CI_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + Number(sa_hc) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_CI",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_prenatal, sa_add_plus, t }) {
	console.log("ul038_MC_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_HC_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_hc, sa_prenatal, sa_add_plus, t, ...params }) {
	console.log("ul038_HC_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + (Number(sa_hc) || 0) + 30 * Number(sa_prenatal) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_CI_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_ci, sa_prenatal, sa_add_plus, t, riders }) {
	console.log("ul038_CI_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CI_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 4
function ul038_MC_HC_CI_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_hc, sa_ci, sa_add_plus, t, riders, ...params }) {
	console.log("ul038_MC_HC_CI_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_CI_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_HC_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_hc, sa_prenatal, sa_add_plus, t, ...params }) {
	console.log("ul038_MC_HC_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function ul038_MC_CI_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_prenatal, sa_ci, sa_add_plus, t, riders }) {
	console.log("ul038_MC_CI_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CI_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_HC_CI_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_hc, sa_ci, sa_prenatal, sa_add_plus, t, riders, ...params }) {
	console.log("ul038_HC_CI_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal) + Number(sa_hc) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_CI_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 5
function ul038_MC_HC_CI_PRENATAL_ACDDISMEMBERMENT({
	sa,
	sa_add,
	sa_mc,
	sa_hc,
	sa_ci,
	sa_prenatal,
	sa_add_plus,
	t,
	riders,
	...params
}) {
	console.log("ul038_MC_HC_CI_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_CI_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// UL038 with CAN06
function ul038_CAN06({ sa, sa_add, sa_mc, sa_add_plus, saCAN06, t }) {
	console.log("ul038_CAN06")
	const totalBenefit = 2 * sa + saCAN06
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_ul038_CAN06")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
// 2
function ul038_MC_CAN06({ sa, sa_add, sa_mc, t, saCAN06 }) {
	console.log("ul038_MC_CAN06")
	const totalBenefit = 2 * sa + 1000 * sa_mc + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_CI_CAN06({ sa, sa_add, sa_ci, t, riders, saCAN06 }) {
	console.log("ul038_CI_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CI_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}

	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_HC_CAN06({ sa, sa_add, sa_hc, t, saCAN06, ...params }) {
	console.log("ul038_HC_CAN06")
	const totalBenefit = 2 * sa + (sa_hc || 0) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_PRENATAL_CAN06({ sa, sa_add, sa_prenatal, t, saCAN06 }) {
	console.log("ul038_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		accidentDismemberment: null,
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
// 3
function ul038_MC_CI_CAN06({ sa, sa_add, sa_mc, sa_ci, t, riders, saCAN06 }) {
	console.log("ul038_MC_CI_CAN06")
	const totalBenefit = 2 * sa + 1000 * sa_mc + 1.25 * sa_ci + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CI_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_CI_HC_CAN06({ sa, sa_add, sa_ci, sa_hc, t, riders, saCAN06, ...params }) {
	console.log("ul038_CI_HC_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + sa_hc + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_CI_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_HC_CAN06({ sa, sa_add, sa_mc, sa_hc, t, saCAN06, ...params }) {
	console.log("ul038_MC_HC_CAN06")
	const totalBenefit = 2 * sa + 1000 * sa_mc + sa_hc + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_MC_CI_HC_CAN06({ sa, sa_add, sa_mc, sa_ci, sa_hc, t, riders, saCAN06, ...params }) {
	console.log("ul038_MC_CI_HC_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * sa_mc + sa_hc + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_CI_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_PRENATAL_CAN06({ sa, sa_add, sa_mc, sa_prenatal, t, saCAN06 }) {
	console.log("ul038_MC_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		accidentDismemberment: null,
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_HC_PRENATAL_CAN06({ sa, sa_add, sa_hc, sa_prenatal, t, saCAN06, ...params }) {
	console.log("ul038_HC_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + (Number(sa_hc) || 0) + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		accidentDismemberment: null,
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_CI_PRENATAL_CAN06({ sa, sa_add, sa_ci, sa_prenatal, t, riders, saCAN06 }) {
	console.log("ul038_CI_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal) + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CI_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_CI_PRENATAL_CAN06({ sa, sa_add, sa_mc, sa_ci, sa_prenatal, t, riders, saCAN06 }) {
	console.log("ul038_MC_CI_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 1.25 * sa_ci + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CI_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_MC_HC_PRENATAL_CAN06({ sa, sa_add, sa_mc, sa_hc, sa_prenatal, t, saCAN06, ...params }) {
	console.log("ul038_MC_HC_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_HC_CI_PRENATAL_CAN06({ sa, sa_add, sa_hc, sa_ci, sa_prenatal, t, riders, saCAN06, ...params }) {
	console.log("ul038_HC_CI_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + Number(sa_hc) + 30 * Number(sa_prenatal) + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_CI_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_HC_CI_PRENATAL_CAN06({ sa, sa_add, sa_mc, sa_hc, sa_ci, sa_prenatal, t, riders, saCAN06, ...params }) {
	console.log("ul038_MC_HC_CI_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal) + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_CI_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// ADD-plus (sa_add_plus): is the portion of ADD (accidental Death) -> if user selects the option of accident and disability at step 3 will show up the data of ADD-plus
// wording benefits are the same as ADD (accidental death)
function ul038_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_add_plus, t, saCAN06 }) {
	console.log("ul038_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_prenatal, sa_add_plus, t, saCAN06 }) {
	console.log("ul038_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_MC_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_add_plus, t, saCAN06 }) {
	console.log("ul038_MC_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_HC_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_hc, sa_add_plus, t, saCAN06, ...params }) {
	console.log("ul038_HC_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + (Number(sa_hc) || 0) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_CI_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_ci, sa_add_plus, t, riders, saCAN06 }) {
	console.log("ul038_CI_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CI_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 4
function ul038_MC_CI_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_ci, sa_add_plus, t, riders, saCAN06 }) {
	console.log("ul038_MC_CI_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 1.25 * sa_ci + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CI_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_HC_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_hc, sa_add_plus, t, saCAN06, ...params }) {
	console.log("ul038_MC_HC_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_HC_CI_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_hc, sa_ci, sa_add_plus, t, riders, saCAN06, ...params }) {
	console.log("ul038_HC_CI_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + Number(sa_hc) + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_CI_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_prenatal, sa_add_plus, t, saCAN06 }) {
	console.log("ul038_MC_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_HC_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_hc, sa_prenatal, sa_add_plus, t, saCAN06, ...params }) {
	console.log("ul038_HC_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + (Number(sa_hc) || 0) + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_CI_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_ci, sa_prenatal, sa_add_plus, t, riders, saCAN06 }) {
	console.log("ul038_CI_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_CI_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 5
function ul038_MC_HC_CI_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_hc, sa_ci, sa_add_plus, t, riders, saCAN06, ...params }) {
	console.log("ul038_MC_HC_CI_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_CI_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_MC_HC_PRENATAL_ACDDISMEMBERMENT_CAN06({
	sa,
	sa_add,
	sa_mc,
	sa_hc,
	sa_prenatal,
	sa_add_plus,
	t,
	saCAN06,
	...params
}) {
	console.log("ul038_MC_HC_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function ul038_MC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_prenatal, sa_ci, sa_add_plus, t, riders, saCAN06 }) {
	console.log("ul038_MC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_ul038_MC_CI_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function ul038_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06({
	sa,
	sa_add,
	sa_hc,
	sa_ci,
	sa_prenatal,
	sa_add_plus,
	t,
	riders,
	saCAN06,
	...params
}) {
	console.log("ul038_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal) + Number(sa_hc) + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_HC_CI_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 6
function ul038_MC_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06({
	sa,
	sa_add,
	sa_mc,
	sa_hc,
	sa_ci,
	sa_prenatal,
	sa_add_plus,
	t,
	riders,
	saCAN06,
	...params
}) {
	console.log("ul038_MC_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit =
		2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.ul038,
				productNoteByRider: "fna.totalBenefit_ul038_MC_HC_CI_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_ul038: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.ul038, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}

// Titan 2.0
function titanium_2({ sa, t, sa_add, sa_add_plus }) {
	console.log(PRODUCT_NAME.titanium_2)
	const totalBenefit = 2 * sa
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_titanium_2")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function titanium_2_MC({ sa, sa_mc, t, sa_add }) {
	console.log("titanium_2_MC")
	const totalBenefit = 2 * sa + 1000 * sa_mc
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function titanium_2_CI({ sa, sa_ci, t, sa_add, riders }) {
	console.log("titanium_2_CI")
	const totalBenefit = 2 * sa + 1.25 * sa_ci
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CI")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_HC({ sa, sa_add, sa_hc, t, ...params }) {
	console.log("titanium_2_HC")
	const totalBenefit = 2 * sa + (sa_hc || 0)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function titanium_2_MC_CI({ sa, sa_add, sa_mc, sa_ci, t, riders }) {
	console.log("titanium_2_MC_CI")
	const totalBenefit = 2 * sa + 1000 * sa_mc + 1.25 * sa_ci
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CI")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_CI_HC({ sa, sa_add, sa_ci, sa_hc, t, riders, ...params }) {
	console.log("titanium_2_CI_HC")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + sa_hc
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_CI_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_HC({ sa, sa_add, sa_mc, sa_hc, t, ...params }) {
	console.log("titanium_2_MC_HC")
	const totalBenefit = 2 * sa + 1000 * sa_mc + sa_hc
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function titanium_2_MC_CI_HC({ sa, sa_add, sa_mc, sa_ci, sa_hc, t, riders, ...params }) {
	console.log("titanium_2_MC_CI_HC")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * sa_mc + sa_hc
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_CI_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_PRENATAL({ sa, sa_add, sa_prenatal, t }) {
	console.log("titanium_2_PRENATAL")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function titanium_2_MC_PRENATAL({ sa, sa_add, sa_mc, sa_prenatal, t }) {
	console.log("titanium_2_MC_PRENATAL")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function titanium_2_CI_PRENATAL({ sa, sa_add, sa_ci, sa_prenatal, t, riders }) {
	console.log("titanium_2_CI_PRENATAL")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal)
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CI_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_HC_PRENATAL({ sa, sa_add, sa_hc, sa_prenatal, t, riders, ...params }) {
	console.log("titanium_2_HC_PRENATAL")
	const totalBenefit = 2 * sa + (sa_hc || 0) + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function titanium_2_MC_CI_PRENATAL({ sa, sa_add, sa_mc, sa_ci, sa_prenatal, t, riders }) {
	console.log("titanium_2_MC_CI_PRENATAL")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 1.25 * sa_ci + 30 * Number(sa_prenatal)

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CI_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_HC_PRENATAL({ sa, sa_add, sa_mc, sa_hc, sa_prenatal, t, riders, ...params }) {
	console.log("titanium_2_MC_HC_PRENATAL")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal)
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function titanium_2_HC_CI_PRENATAL({ sa, sa_add, sa_hc, sa_ci, sa_prenatal, t, riders, ...params }) {
	console.log("titanium_2_HC_CI_PRENATAL")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + Number(sa_hc) + 30 * Number(sa_prenatal)
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_CI_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_CI_HC_PRENATAL({ sa, sa_add, sa_mc, sa_ci, sa_hc, sa_prenatal, t, riders, ...params }) {
	console.log("titanium_2_MC_CI_HC_PRENATAL")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal)
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_CI_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}

function titanium_2_ACDDISMEMBERMENT({ sa, sa_add, sa_add_plus, t }) {
	console.log("titanium_2_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
// 2
function titanium_2_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_prenatal, sa_add_plus, t }) {
	console.log("titanium_2_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function titanium_2_MC_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_add_plus, t }) {
	console.log("titanium_2_MC_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function titanium_2_HC_ACDDISMEMBERMENT({ sa, sa_add, sa_hc, sa_add_plus, t, ...params }) {
	console.log("titanium_2_HC_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + (sa_hc || 0) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function titanium_2_CI_ACDDISMEMBERMENT({ sa, sa_add, sa_ci, sa_add_plus, t, riders }) {
	console.log("titanium_2_CI_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CI")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 3
function titanium_2_MC_CI_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_ci, sa_add_plus, t, riders }) {
	console.log("titanium_2_MC_CI_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 1.25 * sa_ci + sa_add_plus

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CI")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_HC_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_hc, sa_add_plus, t, ...params }) {
	console.log("titanium_2_MC_HC_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
}
function titanium_2_HC_CI_ACDDISMEMBERMENT({ sa, sa_add, sa_hc, sa_ci, sa_add_plus, t, riders, ...params }) {
	console.log("titanium_2_HC_CI_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + Number(sa_hc) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_CI_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_prenatal, sa_add_plus, t }) {
	console.log("titanium_2_MC_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function titanium_2_HC_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_prenatal, sa_hc, sa_add_plus, t, ...params }) {
	console.log("titanium_2_HC_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + Number(sa_hc) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function titanium_2_CI_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_prenatal, sa_ci, sa_add_plus, t, riders }) {
	console.log("titanium_2_CI_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CI_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 4
function titanium_2_MC_CI_HC_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_ci, sa_hc, sa_add_plus, t, riders, ...params }) {
	console.log("titanium_2_MC_CI_HC_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_CI_HC",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_HC_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_hc, sa_prenatal, sa_add_plus, t, ...params }) {
	console.log("titanium_2_MC_HC_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
}
function titanium_2_MC_CI_PRENATAL_ACDDISMEMBERMENT({ sa, sa_add, sa_mc, sa_ci, sa_prenatal, sa_add_plus, t, riders }) {
	console.log("titanium_2_MC_CI_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + 1000 * Number(sa_mc) + 1.25 * sa_ci + sa_add_plus

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CI_PRENATAL")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_HC_CI_PRENATAL_ACDDISMEMBERMENT({
	sa,
	sa_add,
	sa_hc,
	sa_ci,
	sa_prenatal,
	sa_add_plus,
	t,
	riders,
	...params
}) {
	console.log("titanium_2_HC_CI_PRENATAL_ACDDISMEMBERMENT || titanium_2_HC_CI-plus_PRENATAL_ACDDISMEMBERMENT")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + Number(sa_hc) + 1.25 * sa_ci + sa_add_plus
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_CI_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
//  5
function titanium_2_MC_CI_HC_PRENATAL_ACDDISMEMBERMENT({
	sa,
	sa_add,
	sa_mc,
	sa_ci,
	sa_hc,
	sa_prenatal,
	sa_add_plus,
	t,
	riders,
	...params
}) {
	console.log("titanium_2_MC_CI_HC_PRENATAL_ACDDISMEMBERMENT", params)
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal) + sa_add_plus

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_CI_HC_PRENATAL",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: {
			value: sa_hc,
			notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }),
		},
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}

// Titan 2.0 with CAN06 are below
function titanium_2_CAN06({ sa, t, sa_add, sa_add_plus, saCAN06 }) {
	console.log("titanium_2_CAN06")
	const totalBenefit = 2 * sa + saCAN06
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_titanium_2_CAN06")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
// 2
function titanium_2_MC_CAN06({ sa, sa_mc, t, sa_add, saCAN06 }) {
	console.log("titanium_2_MC_CAN06")
	const totalBenefit = 2 * sa + 1000 * sa_mc + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_CI_CAN06({ sa, sa_ci, t, sa_add, saCAN06, riders }) {
	console.log("titanium_2_CI_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CI_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_HC_CAN06({ sa, sa_add, sa_hc, t, saCAN06, ...params }) {
	console.log("titanium_2_HC_CAN06")
	const totalBenefit = 2 * sa + (sa_hc || 0) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_MC_CI_CAN06({ sa, sa_add, sa_mc, sa_ci, saCAN06, t, riders }) {
	console.log("titanium_2_MC_CI_CAN06")
	const totalBenefit = 2 * sa + 1000 * sa_mc + 1.25 * sa_ci + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CI_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_CI_HC_CAN06({ sa, sa_add, sa_ci, sa_hc, saCAN06, t, riders, ...params }) {
	console.log("titanium_2_CI_HC_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + sa_hc + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_CI_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_HC_CAN06({ sa, sa_add, sa_mc, sa_hc, saCAN06, t, ...params }) {
	console.log("titanium_2_MC_HC_CAN06")
	const totalBenefit = 2 * sa + 1000 * sa_mc + sa_hc + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_MC_CI_HC_CAN06({ sa, sa_add, sa_mc, sa_ci, sa_hc, t, saCAN06, riders, ...params }) {
	console.log("titanium_2_MC_CI_HC_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * sa_mc + sa_hc + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_CI_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_PRENATAL_CAN06({ sa, sa_add, sa_prenatal, saCAN06, t }) {
	console.log("titanium_2_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_MC_PRENATAL_CAN06({ sa, sa_add, sa_mc, sa_prenatal, saCAN06, t }) {
	console.log("titanium_2_MC_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_CI_PRENATAL_CAN06({ sa, sa_add, sa_ci, sa_prenatal, saCAN06, t, riders }) {
	console.log("titanium_2_CI_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal) + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CI_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_HC_PRENATAL_CAN06({ sa, sa_add, sa_hc, sa_prenatal, saCAN06, t, riders, ...params }) {
	console.log("titanium_2_HC_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + (sa_hc || 0) + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_MC_CI_PRENATAL_CAN06({ sa, sa_add, sa_mc, sa_ci, sa_prenatal, saCAN06, t, riders }) {
	console.log("titanium_2_MC_CI_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 1.25 * sa_ci + 30 * Number(sa_prenatal) + saCAN06

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CI_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_HC_PRENATAL_CAN06({ sa, sa_add, sa_mc, sa_hc, sa_prenatal, saCAN06, t, riders, ...params }) {
	console.log("titanium_2_MC_HC_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal) + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_HC_CI_PRENATAL_CAN06({ sa, sa_add, sa_hc, sa_ci, sa_prenatal, saCAN06, t, riders, ...params }) {
	console.log("titanium_2_HC_CI_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + Number(sa_hc) + 30 * Number(sa_prenatal) + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_CI_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_CI_HC_PRENATAL_CAN06({ sa, sa_add, sa_mc, sa_ci, sa_hc, sa_prenatal, saCAN06, t, riders, ...params }) {
	console.log("titanium_2_MC_CI_HC_PRENATAL_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal) + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_CI_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_add_plus, saCAN06, t }) {
	console.log("titanium_2_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
// 3
function titanium_2_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_prenatal, sa_add_plus, saCAN06, t }) {
	console.log("titanium_2_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_MC_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_add_plus, saCAN06, t }) {
	console.log("titanium_2_MC_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_HC_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_hc, sa_add_plus, saCAN06, t, ...params }) {
	console.log("titanium_2_HC_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + (sa_hc || 0) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_NAME.titanium_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_NAME.titanium_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_CI_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_ci, sa_add_plus, saCAN06, t, riders }) {
	console.log("titanium_2_CI_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CI_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 4
function titanium_2_MC_CI_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_ci, sa_add_plus, saCAN06, t, riders }) {
	console.log("titanium_2_MC_CI_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 1.25 * sa_ci + sa_add_plus + saCAN06

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CI_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_HC_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_hc, sa_add_plus, saCAN06, t, ...params }) {
	console.log("titanium_2_MC_HC_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_HC_CI_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_hc, sa_ci, sa_add_plus, saCAN06, t, riders, ...params }) {
	console.log("titanium_2_HC_CI_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + Number(sa_hc) + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_CI_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_mc, sa_prenatal, sa_add_plus, saCAN06, t }) {
	console.log("titanium_2_MC_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1000 * Number(sa_mc) + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_HC_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_prenatal, sa_hc, sa_add_plus, saCAN06, t, ...params }) {
	console.log("titanium_2_HC_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + Number(sa_hc) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_CI_PRENATAL_ACDDISMEMBERMENT_CAN06({ sa, sa_add, sa_prenatal, sa_ci, sa_add_plus, saCAN06, t, riders }) {
	console.log("titanium_2_CI_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_CI_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
// 5
function titanium_2_MC_CI_HC_ACDDISMEMBERMENT_CAN06({
	sa,
	sa_add,
	sa_mc,
	sa_ci,
	sa_hc,
	sa_add_plus,
	saCAN06,
	t,
	riders,
	...params
}) {
	console.log("titanium_2_MC_CI_HC_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_CI_HC_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: null,
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_MC_HC_PRENATAL_ACDDISMEMBERMENT_CAN06({
	sa,
	sa_add,
	sa_mc,
	sa_hc,
	sa_prenatal,
	sa_add_plus,
	saCAN06,
	t,
	...params
}) {
	console.log("titanium_2_MC_HC_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + 1000 * Number(sa_mc) + Number(sa_hc) + sa_add_plus + saCAN06
	return {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
}
function titanium_2_MC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06({
	sa,
	sa_add,
	sa_mc,
	sa_ci,
	sa_prenatal,
	sa_add_plus,
	saCAN06,
	t,
	riders,
}) {
	console.log("titanium_2_MC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + 1000 * Number(sa_mc) + 1.25 * sa_ci + sa_add_plus + saCAN06

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: [t("fna.totalBenefit_titanium_2_MC_CI_PRENATAL_CAN06")],
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: null,
		criticalIllness: null,
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function titanium_2_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06({
	sa,
	sa_add,
	sa_hc,
	sa_ci,
	sa_prenatal,
	sa_add_plus,
	saCAN06,
	t,
	riders,
	...params
}) {
	console.log("titanium_2_HC_CI_PRENATAL_ACDDISMEMBERMENT_CAN06 || titanium_2_HC_CI-plus_PRENATAL_ACDDISMEMBERMENT_CAN06")
	const totalBenefit = 2 * sa + 30 * Number(sa_prenatal) + Number(sa_hc) + 1.25 * sa_ci + sa_add_plus + saCAN06
	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_HC_CI_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
//  6
function titanium_2_MC_CI_HC_PRENATAL_ACDDISMEMBERMENT_CAN06({
	sa,
	sa_add,
	sa_mc,
	sa_ci,
	sa_hc,
	sa_prenatal,
	sa_add_plus,
	saCAN06,
	t,
	riders,
	...params
}) {
	console.log("titanium_2_MC_CI_HC_PRENATAL_ACDDISMEMBERMENT_CAN06", params)
	const totalBenefit =
		2 * sa + 1.25 * sa_ci + 1000 * Number(sa_mc) + Number(sa_hc) + 30 * Number(sa_prenatal) + sa_add_plus + saCAN06

	const data = {
		totalBenefit: {
			value: totalBenefit,
			notes: totalBenefitToolTipWithHealthcarePackage({
				t,
				productName: PRODUCT_TITAN_2,
				productNoteByRider: "fna.totalBenefit_titanium_2_MC_CI_HC_PRENATAL_CAN06",
				params,
			}),
		},
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add: sa, t, sa_add_for_titanium_2: true }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t, sa_mc_for_titanium_2: true }),
		},
		healthcare: {
			value: sa_hc,
			notes: healthcareTooltip({ t, productName: PRODUCT_TITAN_2, params }),
		},
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		// ADD-plus: at step 3, if user input the amount Accident & Disability
		accidentDismemberment: {
			value: sa_add_plus,
			notes: generateAccidentalDismembermentCoverageTooltip({ sa_add_plus, sa, t }),
		},
		permanentDisability: null,
		financeSupport: null,
		prenatal: {
			value: sa_prenatal,
			notes: generatePrenatalTooltip({ t, sa_prenatal }),
		},
		lateStageCancer: {
			value: saCAN06,
			notes: generateLateStageCancerTooltip({ saCAN06, t }),
		},
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}

// 2017 Unit Linked (ILP)
// this plan is not available
function unitLinked({ sa, sa_mc, t }) {
	const totalBenefit = 2 * sa
	return {
		totalBenefit: { value: totalBenefit, notes: [`${t("fna.totalBenefitValue", { amount: formatMoney(totalBenefit) })}`] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: null,
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
	}
}
function unitLinked_ADD({ sa, sa_add, t }) {
	console.log("unitLinked_ADD")
	const totalBenefit = sa + sa_add
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa_add,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add, t }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
}
// this plan is not available
function unitLinked_MC({ sa, sa_mc, t }) {
	console.log("unitLinked_MC")
	const totalBenefit = sa + sa_mc * 1000
	return {
		totalBenefit: { value: totalBenefit, notes: [`${t("fna.totalBenefitValue", { amount: formatMoney(totalBenefit) })}`] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: null,
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
	}
}
// this plan is not available
function unitLinked_CI({ sa, sa_ci, t, riders }) {
	console.log("unitLinked_CI")
	const totalBenefit = sa + 1.25 * sa_ci
	const data = {
		totalBenefit: { value: totalBenefit, notes: [`${t("fna.totalBenefitValue", { amount: formatMoney(totalBenefit) })}`] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: null,
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: null,
		permanentDisability: null,
		financeSupport: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}

function unitLinked_HC({ sa, sa_add, sa_hc, t, ...params }) {
	console.log("unitLinked_HC")
	const totalBenefit = sa + sa_hc
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_HC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: null,
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
}

function unitLinked_ADD_MC({ sa, sa_add, sa_mc, t }) {
	console.log("unitLinked_ADD_MC")
	const totalBenefit = sa + sa_add + sa_mc * 1000
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_MC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa_add,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add, t }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
}
function unitLinked_ADD_CI({ sa, sa_add, sa_ci, t, riders }) {
	console.log("unitLinked_ADD_CI")
	const totalBenefit = sa + 1.25 * sa_ci + sa_add
	const data = {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_CI")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa_add,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add, t }),
		},
		medicalSupport: null,
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function unitLinked_ADD_HC({ sa, sa_add, sa_hc, t, ...params }) {
	console.log("unitLinked_ADD_HC")

	const totalBenefit = sa + sa_add + sa_hc
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_HC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa_add,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add, t }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
}
function unitLinked_ADD_MC_CI({ sa, sa_add, sa_mc, sa_ci, t, riders }) {
	console.log("unitLinked_ADD_MC_CI")
	const totalBenefit = sa + sa_add + 1000 * sa_mc + 1.25 * sa_ci
	const data = {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_MC_CI")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa_add,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add, t }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function unitLinked_ADD_CI_HC({ sa, sa_add, sa_ci, sa_hc, t, riders, ...params }) {
	console.log("unitLinked_ADD_CI_HC")
	const totalBenefit = sa + sa_add + 1.25 * sa_ci + sa_hc
	const data = {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_CI_HC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa_add,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add, t }),
		},
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function unitLinked_ADD_MC_HC({ sa, sa_add, sa_mc, sa_hc, t, ...params }) {
	console.log("unitLinked_ADD_MC_HC")
	const totalBenefit = sa + sa_add + 1000 * sa_mc + sa_hc
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_MC_HC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa_add,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add, t }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
}

function unitLinked_MC_CI({ sa, sa_add, sa_mc, sa_ci, t, riders }) {
	console.log("unitLinked_ADD_MC_CI")
	const totalBenefit = sa + 1000 * sa_mc + 1.25 * sa_ci
	const data = {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_MC_CI")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: null,
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: null,
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function unitLinked_CI_HC({ sa, sa_add, sa_ci, sa_hc, t, riders, ...params }) {
	console.log("unitLinked_CI_HC")
	const totalBenefit = sa + 1.25 * sa_ci + sa_hc
	const data = {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_CI_HC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: null,
		medicalSupport: null,
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}
function unitLinked_MC_HC({ sa, sa_add, sa_mc, sa_hc, t, ...params }) {
	console.log("unitLinked_MC_HC")
	const totalBenefit = sa + 1000 * sa_mc + sa_hc
	return {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_MC_HC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: null,
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, params }) },
		criticalIllness: null,
		criticalIllnessPlus: null,
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
}

function unitLinked_MC_CI_HC({ sa, sa_add, sa_mc, sa_ci, sa_hc, t, riders, ...params }) {
	console.log("unitLinked_MC_CI_HC")
	const totalBenefit = sa + 1000 * sa_mc + 1.25 * sa_ci + sa_hc
	const data = {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_MC_CI_HC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: null,
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: {
			value: sa_add,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}

function unitLinked_ADD_MC_CI_HC({ sa, sa_add, sa_mc, sa_ci, sa_hc, t, riders, ...params }) {
	console.log("unitLinked_ADD_MC_CI_HC")
	const store = useStore()
	const answers_add = store?.state?.fna?.answers?.healthAndMedical?.accidentAndDisabilityCoverAmount
	const sa_add_value = answers_add > 0 ? answers_add : sa_add
	const totalBenefit = sa + sa_add_value + 1000 * sa_mc + 1.25 * sa_ci + sa_hc
	const data = {
		totalBenefit: { value: totalBenefit, notes: [t("fna.totalBenefit_unitLinked_ADD_MC_CI_HC")] },
		deathCoverage: { value: sa, notes: [] },
		accidentalDeath: {
			value: sa_add_value,
			notes: generateAccidentalDeathCoverageTooltip({ sa_add_value, t }),
		},
		medicalSupport: {
			value: sa_mc,
			notes: generateMedicalSupportCoverageTooltip({ sa_mc, t }),
		},
		healthcare: { value: sa_hc, notes: healthcareTooltip({ t, params }) },
		criticalIllness: {
			value: Math.min(1.25 * sa_ci, max_sa_ci),
			notes: generateCriticalIllnessCoverageTooltip({ sa_ci, t }),
			originalValue: sa_ci,
		},
		accidentDismemberment: {
			value: sa_add_value,
			notes: [`${t("fna.accidentalDismembermentCoverage", { amount: formatMoney(sa_add_value) })}`],
		},
		permanentDisability: null,
		financeSupport: null,
	}
	if (riders?.includes("CI-plus")) {
		data.criticalIllnessPlus = data.criticalIllness
		delete data.criticalIllness
	}
	return data
}

export const getADD = (fa) => {
	const max_sa_add_plus = Math.min(5 * fa, 10000000000)
	if (fa > max_sa_add_plus) return max_sa_add_plus
	else if (fa < min_sa_add) return min_sa_add
	return fa
}

export const getMedicalSupport = (fa) => {
	const store = useStore()
	const answers_mc = store?.state?.fna?.answers?.healthAndMedical?.hospitalisedRoomAndBoard
	return answers_mc > 0 ? answers_mc : Math.min(0.002 * fa, max_sa_mc_per_day)
}

export const getMedicalSupportForTitan = (fa) => {
	return Math.min(0.002 * fa, max_sa_mc_for_titan_per_day)
}

export const getCI = (fa) => {
	const store = useStore()
	const answers_ci = store?.state?.fna?.answers?.healthAndMedical?.critticalIllnessCoverAmount

	if (answers_ci && answers_ci > 0) return answers_ci

	if (fa > max_sa_ci) return max_sa_ci
	else if (fa < min_sa_ci) return min_sa_ci
	return fa
}

export const getHC = () => {
	const store = useStore()
	const { healthAndMedical } = store?.state?.fna?.answers || {}
	const { roomAndBoardExpense } = healthAndMedical || {}
	const inpatient = roomAndBoardExpense?.inpatient
	const outpatient = roomAndBoardExpense?.outpatient
	const dental = roomAndBoardExpense?.dental
	return getSumHealthcare({ roomAndBoardExpense: inpatient, healthCareOutpatientBenefit: outpatient, dentalBenefit: dental })
}

export const getADDPlus = () => {
	const store = useStore()
	const { healthAndMedical } = store?.state?.fna?.answers || {}
	return healthAndMedical?.accidentAndDisabilityCoverAmount || default_sa_add
}
