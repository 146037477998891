export const PERIODIC_PAYMENT_TERM = 20
export const APE_PERCENTAGE_ROUND_FORMATTED = 0

export const GENDERS = {
	M: "male",
	F: "female",
}
/** NOTE : some case will see the options
 * option_0 to option_2
 * DEFAULT option will be: option_0 (which rider is not selected) -> flag to BE
 * Option is applying to all riders, even the rider only has 1 option like medicalCare, default will be option_0, after users pick the medicalCare rider -> option will be 1 (option_1)
 */

/**
 * HEALTH CARE options : inPatient & outPatient rider
 * using OPTION_1 -> OPTION_5 in case of rider has a few options to select one of them which is showing option
 * default will be option_0 like the others rider
 */
export const HEALTH_CARE_RIDER_OPTIONS = [
	{ benefitPerDay: 750000, saInpatient: 125000000, saOutpatient: 0, level: "silver", option: "option_1" },
	{ benefitPerDay: 1500000, saInpatient: 250000000, saOutpatient: 12000000, level: "titan", option: "option_2" },
	{ benefitPerDay: 2500000, saInpatient: 500000000, saOutpatient: 24000000, level: "gold", option: "option_3" },
	{ benefitPerDay: 5000000, saInpatient: 1000000000, saOutpatient: 48000000, level: "platinum", option: "option_4" },
	{ benefitPerDay: 10000000, saInpatient: 2000000000, saOutpatient: 100000000, level: "diamond", option: "option_5" },
]
export const BENEFIT_TYPES = [
	"deathCoverage",
	"accidentalDeath",
	"accidentDismemberment",
	"medicalSupport",
	"healthcare",
	"criticalIllness",
	"criticalIllnessPlus",
	"permanentDisability",
	"financeSupport",
	"prenatal",
] // dont change the order of the benefits

export const BENEFIT_KEYS_TYPES = {
	ADD: "accidentalDeath",
	MC: "medicalSupport",
	CI: "criticalIllness",
	"CI-plus": "criticalIllnessPlus",
	HC: "healthcare",
	PRENATAL: "prenatal",
	"ADD-plus": "accidentDismemberment",
}
export const RIDER_KEY_TYPES = {
	ADD: "accidentalDeathRider",
	MC: "medicalRider",
	CI: "criticalIllnessRider",
	"CI-plus": "criticalIllnessRider",
	HC: "healthcareRider",
	PRENATAL: "prenatalRider",
	"ADD-plus": "accidentDismemberment",
	INPATIENT: "impatientRider", // NOT: do not change the key, incorrect key on the query logic
	OUTPATIENT: "outpatientRider",
	DENTAL: "dentalRider",
	CAN06: "lateStageCancerRider",
}
// Sum assured of riders
export const SA_KEYS = {
	ADD: "sa_add",
	MC: "sa_mc",
	CI: "sa_ci",
	"CI-plus": "sa_ci",
	HC: "sa_hc",
	HC_outpatient: "sa_hc_outpatient",
	PRENATAL: "sa_prenatal",
	"ADD-plus": "sa_add_plus",
}
/** Using for
 * Case: Options in question and answer of health care goal
 */
export const HEALTH_CARE_BENEFIT_OPTIONS = {
	medicalCare: "hospitalisedRoomAndBoard",
	healthCare: "roomAndBoardExpense",
	accidentAndDisability: "accidentAndDisabilityCoverAmount",
	CI: "critticalIllnessCoverAmount",
	"CI-plus": "critticalIllnessCoverAmount",
}

export const GOALS_ORDER = {
	childrenEducation: 1,
	retirement: 2,
	growWealthAndAssets: 3,
	financeUncertainty: 4,
	healthAndMedical: 5,
}

// PRENATAL options
export const PRENATAL_VALUE_OPTIONS = [
	{
		value: 200000,
		code: 200,
		limit: 0,
	},
	{
		value: 300000,
		code: 300,
		limit: 10000000,
	},
	{
		value: 500000,
		code: 500,
		limit: 10000000,
	},
	{
		value: 1000000,
		code: 1000,
		limit: 20000000,
	},
	{
		value: 2000000,
		code: 2000,
		limit: 40000000,
	},
	{
		value: 3000000,
		code: 3000,
		limit: 80000000,
	},
]

// MEDICAL CASH options
export const MEDICAL_CASH_VALUE_OPTIONS = [
	{
		value: 200000,
		code: 200,
	},
	{
		value: 300000,
		code: 300,
	},
	{
		value: 500000,
		code: 500,
	},
	{
		value: 1000000,
		code: 1000,
	},
	{
		value: 1500000,
		code: 1500,
	},
]

// STATUS EPOS
export const EPOS_STATUSES = ["completedFNA", "applicationInprogress", "lateSubmission", "applicationSubmitted"]

export const TITAN_PAYMENT_TERM = {
	titan_6years: 6, // titan_1
	titan_10years: 10, // titan_1
}

export const SCHOOL_TYPES = {
	publicSchool: "1",
	privateSchool: "2",
	internationalSchool: "3",
}
