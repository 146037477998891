export default {
	user: {
		users: "Users",
		newUser: "New User",
		permissionGroup: "Permission Group",
		associatedEntity: "Associated Entity",
		deleteUser: "Delete User",
		deleteFullNote:
			"You can permanently delete the user from database. This action cannot be reverted and you cannot restore the record anymore.",
		confirmToDelete: "Confirm to Delete",
		cfDeleteNote: "You are going to delete ",
		cfDeleteNote2: "This action cannot be reverted. The record will be deleted permanently.",
		userLockoutStatus: "User Status",
		userLockStatusNote: "You can deactivate user. User cannot login after the current status is locked.",
		userUnlockStatusNote: "You can activate user. User can login again after the current status is unlocked.",
		confirmToActivate: "Confirm to Activate User",
		confirmToActivateNote1: "You are going to activate",
		confirmToActivateNote2: "This user can login after you activate it.",
		confirmToDeactivate: "Confirm to Deactivate User",
		confirmToDeactivateNote1: "You are going to deactivate",
		confirmToDeactivateNote2: "This user cannot login after you deactivate it.",
		optional: "Optional",
		deactivateSuccessfully: "Deactivate successfully",
		reactivateSuccessfully: "Reactivate successfully",
		updatedSuccessfully: "Update successfully",
		pleaseInputAgentCode: "Input agent code",
		agentCodeNoteTip: "Agent code is required for iTCBLife role = DRBO/RBO/SRBO/PRM/TIS/TIS1",
		infoUnchangedWarning: "User account information is same as current, please recheck!",
		createAccountSuccessfully: "Create new account successfully",
		form: {
			updateStaffInfo: "Update account information",
			staffId: "Staff ID",
			username: "Username",
			email: "Email",
			fullName: "Last name, First name",
			iTcbLifeRole: "iTCBLife role",
			area: "Area",
			region: "Region",
			branch: "Branch",
			agentCode: "Agent code",
			noteForPurpose: "Notes/Purposes",
			placeHolderNotes: "Input reasons, remarks/notes...",
			placeHolderStaffId: "Input Staff ID",
			placeHolderUserName: "Input Username",
			placeHolderMail: "Input Techcombank email",
			placeHolderFullName: "Input Full Name",
			createNewAccount: "New User Account Information",
			englishFirstName: "First Name",
			englishLastName: "Last Name",
			placeHolderFirstName: "Input First Name",
			placeHolderLastName: "Input Last Name",
		},
		label: {
			emailOrUsername: "Email or Username",
			entityName: "Entity Name",
			code: "Code",
			emailActivationStatus: "Email Activation",
			lockoutStatus: "Lockout Status",
			active: "Active",
			inactive: "Inactive",
			done: "Done",
			pending: "Pending",
			createdAt: "Created At",
			area: "Area",
			region: "Region",
			branch: "Branch",
			username: "Username",
			name: "Name",
			englishFirstName: "English First Name",
			englishLastName: "English Last Name",
			chineseFirstName: "Chinese First Name",
			chineseLastName: "Chinese Last Name",
			email: "Email",
			role: "Role",
			type: "Type",
			position: "Position",
			pleaseSelect: "Please select",
			all: "All",
		},

		button: {
			view: "View",
			reInvite: "Re-invite",
			add: "Add",
			create: "Create",
			confirm: "Confirm",
			cancel: "Cancel",
			delete: "Delete",
			deleteUser: "Delete User",
			activateUser: "Reactivate",
			deactivateUser: "Deactivate",
			search: "Search",
			clear: "Clear",
			createNewUser: "Create new user account",
			updateRoleInfo: "Update role",
			ok: "OK",
			save: "Save",
		},

		message: {
			reinviteSuccess: "Invitation email has been sent to user!",
			createSuccess: "Create user successfully!",
			deleteSuccess: "User has been deleted successfully!",
			activateSuccess: "User has been activated successfully!",
			deactivateSuccess: "User has been deactivated successfully!",
			updateSuccess: "User has been updated successfully",
		},

		area: {
			all: "All",
			north: "North",
			south: "South",
			mb: "North",
			mn: "South",
			Hub_Mortgage: "Hub_Mortgage",
			Hub_Private: "Hub_Private",
			Hub_Insurance: "Hub_Insurance",
		},

		region: {
			all: "All",
			region_1: "Region 01",
			region_2: "Region 02",
			region_3: "Region 03",
			region_4: "Region 04",
			region_5a: "Region 5A",
			region_5: "Region 05",
			region_6: "Region 06",
			region_7: "Region 07",
			region_8: "Region 08",
			region_9: "Region 09",
			region_10: "Region 10",
			region_11: "Region 11",
			region_12: "Region 12",
			region_12a: "Region 12A",
			region_13: "Region 13",
			region_14: "Region 14",
			region_15: "Region 15",
			region_16: "Region 16",
			Hub_Insurance: "Region Insurance",
			Hub_Mortgage: "Region Mortgage",
			Hub_Private: "Region Private",
		},

		branch: {
			branchManager: "Branch Manager",
			regionalManager: "Regional Manager",
			areaManager: "Area Manager",
			all: "All",
			HDG: "Hai Duong",
			TNT: "Thong Nhat",
			HPG: "Hai Phong",
			KAN: "Kien An",
			LHP: "Le Hong Phong",
			NQN: "Ngo Quyen",
			TNN: "Thuy Nguyen",
			TOH: "To Hieu",
			VCO: "Van Cao",
			CDN: "Cho Don",
			NSU: "Le Thanh Tong ",
			HBG: "Hong Bang",
			BTY: "Ecopark",
			HYN: "Hung Yen",
			PHN: "Pho Hien",
			KDT: "Van Lam",
			YMY: "Yen My",
			MCI: "Mong Cai",
			QNH: "Quang Ninh",
			CPA: "Cam Pha",
			UBI: "Uong Bi",
			BDA: "Ha Long ",
			BGN: "Bac Giang",
			BAN: "Bac Ninh",
			DBG: "Tu Son",
			TDU: "Tien Du",
			TNS: "Que Vo",
			NCO: "Yen Phong",
			LSN: "Lang Son",
			DDG: "Dong Dang",
			LCI: "Lao Cai",
			THN: "Thai Nguyen",
			MBH: "Pho Yen",
			PTO: "Viet Tri",
			VPC: "Vinh Phuc",
			PYN: "Phuc Yen",
			YBI: "Yen Bai",
			BLD: "Gia Lam",
			TRP: "Ruby",
			CHD: "Chuong Duong",
			NTU: "Ngo Gia Tu",
			YVN: "Ninh Hiep ",
			NLM: "Ngoc Lam",
			SDG: "Sai Dong",
			VHG: "Viet Hung",
			PSM: "Long Bien ",
			BIZ: "Ha Noi",
			LTH: "Van Phu",
			BTD: "Bat Dan",
			HBT: "Xuan Phuong",
			CBC: "Cua Bac",
			HDU: "Hang Dau",
			LND: "Co Linh",
			LTT: "Smart City",
			XDU: "Xuan Dieu",
			NBI: "Noi Bai",
			DAH: "Dong Anh",
			MLH: "Me Linh",
			PLO: "Phu Lo",
			SSN: "Soc Son",
			CLH: "Cat Linh",
			KTN: "Kham Thien",
			TLG: "Thang Long",
			DCG: "Dinh Cong",
			DOA: "Dong Da",
			HCU: "Hoang Cau",
			KMI: "Khuong Mai",
			KLN: "Kim Lien",
			LHA: "Lang Ha",
			NHN: "Hapulico",
			NSO: "Nga Tu So",
			PMI: "Tan Xuan",
			TTH: "Thai Thinh",
			DVN: "Gamuda",
			HGA: "Hoang Gia",
			TXN: "Thanh Xuan",
			SGO: "Ha Thanh",
			HMI: "Hoang Mai",
			LDM: "Linh Dam",
			LDC: "Lo Duc",
			LTK: "Kim Giang",
			TGA: "Trang An",
			TRN: "Tran Dien",
			TKC: "Hai Ba Trung",
			BTR: "Ba Trieu",
			BKA: "Bach Khoa",
			CMO: "Kien Hung",
			LTN: "Lac Trung",
			LNM: "Linh Nam",
			NAH: "Nam Thang Long",
			TRD: "Truong Dinh",
			NHT: "Thuong Tin",
			HKM: "Nguyen Tuan",
			CNM: "Park City",
			THO: "Tay Mo",
			HSO: "Trung tam giao dich Hoi So",
			BDN: "Ba Dinh",
			CGY: "Cau Giay",
			CLG: "Chua Lang",
			DTN: "Dao Tan",
			DCN: "Doi Can",
			HTK: "An Hung",
			KMA: "Ho Tung Mau",
			NKH: "Ngoc Khanh",
			KTG: "Duong Noi",
			HQV: "Hoang Quoc Viet",
			HHT: "Tay Ho Tay",
			LLQ: "Lac Long Quan",
			NKT: "Nguyen Khanh Toan",
			CBI: "Tay Ho",
			TKE: "Thuy Khue",
			TNI: "Tran Dang Ninh",
			NPS: "Tran Thai Tong",
			XLA: "Pham Van Dong",
			CPT: "Ciputra",
			TAH: "Tran Binh",
			DDA: "Dong Do",
			BGC: "Big C",
			KNM: "Keangnam",
			MDH: "Ha Dong",
			CTH: "Nguyen Co Thach",
			PXA: "An Khanh",
			TDH: "Tran Duy Hung",
			QNM: "Tu Liem",
			NTD: "Hoang Dao Thuy",
			MNR: "The Manor",
			TYN: "Trung Yen",
			DXN: "Green Bay",
			HTY: "Ha Tay",
			DPG: "Dan Phuong",
			NGG: "Nhue Giang",
			STY: "Son Tay",
			VQN: "Van Quan",
			XAL: "Xa La",
			XMI: "Xuan Mai",
			HNM: "Ha Nam",
			HTH: "Ha Tinh",
			BHI: "Ky Anh",
			NDH: "Nam Dinh",
			NAN: "Nghe An",
			NSS: "Nguyen Sy Sach",
			NBH: "Ninh Binh",
			TBH: "Thai Binh",
			THA: "Thanh Hoa",
			TRU: "Cho Tay Thanh",
			DTU: "Quang Binh",
			DNG: "Da Nang",
			CHN: "Son Tra",
			HCH: "Ngu Hanh Son",
			HKH: "Hoa Khanh",
			NHE: "Nguyen Huu Tho",
			PCT: "Phan Chu Trinh",
			DLK: "Dak Lak",
			HUE: "Hue",
			LDG: "Lam Dong",
			NTG: "Nha Trang",
			MVG: "Ma Vong",
			VHI: "Starcity",
			PLU: "Pleiku",
			TAK: "Quang Nam",
			HON: "Hoi An",
			QNI: "Quang Ngai",
			QNN: "Quy Nhon",
			THK: "Da Thanh",
			HCB: "29-3",
			CMI: "Cho Moi",
			TBI: "SunCity",
			BHD: "Binh Duong",
			STN: "Di An",
			MPC: "My Phuoc",
			LTU: "Lai Thieu",
			BPC: "Binh Phuoc",
			BTU: "Binh Thuan",
			DAI: "Dong Nai",
			BHA: "Bien Hoa",
			NAI: "Ho Nai",
			LBN: "Long Khanh",
			LOT: "Long Thanh",
			THP: "Tam Hiep",
			TNH: "Tay Ninh",
			VTU: "Vung Tau",
			BMT: "30/4",
			BRI: "Ba Ria",
			HDV: "Chi Linh",
			SMI: "Sao Mai",
			SGN: "Phu Nhuan",
			HCM: "Sai Gon",
			KDG: "Ky Dong",
			LVS: "Le Van Sy",
			NSG: "Nam Sai Gon",
			NTT: "Nguyen Tat Thanh",
			NVT: "Nguyen Van Troi",
			PTR: "Pasteur",
			HVG: "Central Park ",
			SGM: "Sala",
			TQD: "Lumiere",
			BTH: "An Phu",
			ADV: "CityLand",
			TDN: "Thao Dien",
			LVB: "Tan Cang",
			PMH: "Phu My Hung",
			ANG: "Binh Dang",
			HTP: "Huynh Tan Phat",
			PPU: "Phong Phu",
			PXN: "Phu Xuan",
			NTP: "Tan Qui",
			TTN: "Tan Thuan",
			PTG: "Sunrise City",
			BCH: "Phuoc Kien.",
			CMT: "Ha Do",
			TAL: "Thang Loi",
			BAH: "Bac Hai",
			BTX: "Bui Thi Xuan",
			DSG: "Diamond Island",
			D32: "Duong 3 thang 2",
			HHU: "Hoa Hung",
			KHA: "Ky Hoa",
			THI: "Hong Lac ",
			LTG: "Cao Thang",
			Q10: "Lacasa",
			CLN: "Cho Lon",
			ADG: "An Dong",
			ANC: "An Lac",
			BPU: "Binh Phu",
			BTI: "Binh Thoi",
			BTN: "Binh Tien",
			LBT: "Lanh Binh Thang",
			NSN: "Nguyen Son",
			PCD: "Manhattan",
			PHO: "Phu Tho",
			KDV: "Quan 6",
			TPU: "Tan Phu",
			TKU: "Thuan Kieu",
			BTG: "Binh Tri Dong",
			TTO: "Global City",
			PLM: "The Rainbow",
			LTR: "Binh Tan",
			HHG: "Hung Dao",
			QU5: "Quan 5",
			VAH: "Van Thanh",
			PDL: "Gia Dinh",
			BDG: "Cantavil An Phu",
			BIH: "Binh Hoa",
			BTA: "Binh Thanh",
			DBL: "Dinh Bo Linh",
			DXH: "Do Xuan Hop",
			HPU: "Hiep Phu",
			LQD: "Le Quang Dinh",
			GDN: "Minh Khai ",
			PXL: "Phan Xich Long",
			TDI: "Tan Dinh",
			TDA: "Thanh Da",
			TNO: "Tran Nao",
			VVN: "Vo Van Ngan",
			BCU: "Ba Chieu",
			CLI: "Nguyen Duy Trinh ",
			LXN: "Hiep Binh Phuoc ",
			MTN: "Van Phuc City",
			THD: "Thu Duc",
			QTN: "Quang Trung",
			CCI: "Cu Chi",
			HMN: "Hoc mon",
			LDT: "Le Duc Tho",
			NGT: "Nguyen Anh Thu",
			HVH: "Nguyen Kiem",
			NOH: "Nguyen Oanh",
			NTS: "Nguyen Thai Son",
			THT: "Tan Hung Thuan",
			TKY: "To Ky",
			TBN: "Tan Binh",
			APB: "Ap Bac",
			AUC: "Au Co",
			BCT: "Bau Cat",
			BHN: "Phan Van Hon",
			TSN: "Tan Son Nhat",
			TSG: "Tay Sai Gon",
			TCH: "Truong Chinh",
			TVK: "Truong Vinh Ky",
			THG: "Tan Huong",
			TSO: "Trung Son",
			PMY: "Le Van Tho",
			AGG: "An Giang",
			CMU: "Ca Mau",
			CTO: "Can Tho",
			AHA: "An Hoa",
			TAN: "Ninh Kieu",
			DTP: "Dong Thap",
			KGG: "Kien Giang",
			LAN: "Long An",
			CRH: "Phu Quoc ",
			STG: "Soc Trang",
			TGG: "Tien Giang",
			VLG: "Vinh Long",
			NTL: "Pham Thai Buong",
			TTC: "Hub Mortgage North",
			TCU: "Hub Mortgage South",
			VIN: "Hub Private North",
			PDP: "Hub Private South",
			IMB: "Hub Insurance North",
			IMN: "Hub Insurance South",
			BLN: "Hub Telesale South",
			TTT: "Hub Telesale North",
		},

		staffType: {
			SRBO: "SRBO",
			RBO: "RBO",
			TIS: "TIS",
			PRM: "PRM",
		},
		ho: {
			HO: "Executive",
		},
	},
}
