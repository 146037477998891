export default {
	setInitialActiveStep({ commit }, payload) {
		commit("setInitialActiveStep", payload)
	},
	setInitialCompletedSteps({ commit }, payload) {
		commit("setInitialCompletedSteps", payload)
	},
	setGoals({ commit }, payload) {
		commit("setGoals", payload)
	},
	setCustomerDetails({ commit }, payload) {
		commit("setCustomerDetails", payload)
	},
	setCustomerInfo({ commit }, payload) {
		commit("setCustomerInfo", payload)
	},
	setCaseData({ commit }, payload) {
		commit("setCaseData", payload)
	},
	setRates({ commit }, payload) {
		commit("setRates", payload)
	},
	setAnswers({ commit }, payload) {
		commit("setAnswers", payload)
	},
	setAnswersFactId({ commit }, payload) {
		commit("setAnswersFactId", payload)
	},
	setGoalsFactId({ commit }, payload) {
		commit("setGoalsFactId", payload)
	},
	stopFNA({ commit }) {
		commit("stopFNA")
	},
	setDropOffReason({ commit }, payload) {
		commit("setDropOffReason", payload)
	},
	setOtherDropOffReason({ commit }, payload) {
		commit("setOtherDropOffReason", payload)
	},
	setInterestRate({ commit }, payload) {
		commit("setInterestRate", payload)
	},
	setInflationRate({ commit }, payload) {
		commit("setInflationRate", payload)
	},
	setEducationInflationRate({ commit }, payload) {
		commit("setEducationInflationRate", payload)
	},
	setSelectedProduct({ commit }, payload) {
		commit("setSelectedProduct", payload)
	},
	setPrices({ commit }, payload) {
		commit("setPrices", payload)
	},
	setPromotions({ commit }, payload) {
		commit("setPromotions", payload)
	},
	setOverwriteProductOptions({ commit }, payload) {
		commit("setOverwriteProductOptions", payload)
	},
	setCustomizingProduct({ commit }, payload) {
		commit("setCustomizingProduct", payload)
	},
	setConversationResult({ commit }, payload) {
		commit("setConversationResult", payload)
	},
	setTisAgentCode({ commit }, payload) {
		commit("setTisAgentCode", payload)
	},
	setLeadGCM({ commit }, payload) {
		commit("setLeadGCM", payload)
	},
	setGCMLeadIdFromRoute({ commit }, payload) {
		commit("setGCMLeadIdFromRoute", payload)
	},
	setReferLeadFna({ commit }, payload) {
		commit("setReferLeadFna", payload)
	},
	setRecentLeadApiStatus({ commit }, payload) {
		commit("setRecentLeadApiStatus", payload)
	},
	setPlanCode({ commit }, payload) {
		commit("setPlanCode", payload)
	},
	setMVPVersion({ commit }, payload) {
		commit("setMVPVersion", payload)
	},
	setDefaultSA({ commit }, payload) {
		commit("setDefaultSA", payload)
	},
	setIsResumeFNA({ commit }, payload) {
		commit("setIsResumeFNA", payload)
	},
	setCompletedDate({ commit }, payload) {
		commit("setCompletedDate", payload)
	},
	setResumeFlag({ commit }, payload) {
		commit("setResumeFlag", payload)
	},
	setTitanPdf({ commit }, payload) {
		commit("setTitanPdf", payload)
	},
	setFNAPdf({ commit }, payload) {
		commit("setFNAPdf", payload)
	},
	setIsAnswerDefault({ commit }, payload) {
		commit("setIsAnswerDefault", payload)
	},
	setFnaBackDestination({ commit }, payload) {
		commit("setFnaBackDestination", payload)
	},
	setProductCode({ commit }, payload) {
		commit("setProductCode", payload)
	},
	setEPosLinkCustomerDB({ commit }, payload) {
		commit("setEPosLinkCustomerDB", payload)
	},
	setBaseProductId({ commit }, payload) {
		commit("setBaseProductId", payload)
	},
	setBaseProductList({ commit }, payload) {
		commit("setBaseProductList", payload)
	},
	setExistenceUserInfo({ commit }, payload) {
		commit("setExistenceUserInfo", payload)
	},
	setDirectReferralCode({ commit }, payload) {
		commit("setDirectReferralCode", payload)
	},
	setRidersValid({ commit }, payload) {
		commit("setRidersValid", payload)
	},
	setCustomerAccountsBySearching({ commit }, payload) {
		commit("setCustomerAccountsBySearching", payload)
	},
	setRefererBranchCode({ commit }, payload) {
		commit("setRefererBranchCode", payload)
	},
	setAnnualPremium({ commit }, payload) {
		commit("setAnnualPremium", payload)
	},
}
