export const exclusionCardMachine = {
	id: "exclusionCard",
	initial: "idle",
	states: {
		idle: {
			on: {
				FETCH: "reading",
			},
		},
		reading: {
			id: "read",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "readExclusionService",
						src: "readExclusion",
						onDone: { target: "dataListing", actions: "setResults" },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				dataListing: {
					on: {
						CREATE: "#exclusionCard.creating",
						DELETE: "#exclusionCard.deleting",
					},
				},
				failure: { on: { RETRY: "fetching" } },
			},
		},
		creating: {
			id: "create",
			initial: "formFilling",
			states: {
				formFilling: {
					on: {
						SAVE: "fetching",
						CANCEL: "#read.dataListing",
					},
				},
				fetching: {
					invoke: {
						id: "createExclusionService",
						src: "createExclusion",
						onDone: { target: "#exclusionCard.reading", actions: "clearForm" },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { RETRY: "fetching" },
				},
			},
		},
		deleting: {
			id: "delete",
			initial: "fetching",
			states: {
				fetching: {
					invoke: {
						id: "deleteExclusionService",
						src: "deleteExclusion",
						onDone: { target: "#exclusionCard.reading", actions: "setDeleteSuccessMessage" },
						onError: { target: "failure", actions: "setErrorMessage" },
					},
				},
				failure: {
					on: { RETRY: "fetching" },
				},
			},
		},
	},
}
