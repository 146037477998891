<template>
	<JFlex class="p-4 justify-between rounded-lg hover:bg-c0-100">
		<JBox class="flex-grow-1">
			<JText class="text-c0-500"> {{ file.fileName }} </JText>
		</JBox>
		<JBox class="ml-10">
			<FileOption ref="optionRef" @delete="onDelete()" />
		</JBox>
	</JFlex>
</template>
<script>
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import { fetcher } from "../../api/fetcher"
import { handleErrorForUser } from "../../api/handleErrorForUser"
import { apiDeleteFile } from "@covergo/cover-composables"

import FileOption from "./FileOption"
export default {
	components: { FileOption },

	emits: ["update"],

	props: {
		file: {
			type: Object,
			required: true,
		},

		bucketName: {
			type: String,
			required: true,
		},
	},

	setup(props, { emit }) {
		const { t } = useI18n()
		const optionRef = ref(null)

		async function onDelete() {
			const variables = {
				bucketName: props.bucketName,
				key: props.file?.key,
			}
			const { error } = await apiDeleteFile({ variables, fetcher })
			if (error) handleErrorForUser({ error, $t: t })
			emit("update")
			optionRef.value.hideShowMore()
		}

		return { optionRef, onDelete }
	},
}
</script>
