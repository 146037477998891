import store from "@/store"
import getEnv from "@/utils/getEnv"
export default function getTokenAuth() {
	const tokenExpireTime = localStorage.getItem(getEnv("VUE_APP_TOKEN_EXPIRES"))
	const currentTime = new Date().getTime()
	if (!tokenExpireTime || tokenExpireTime === "null" || tokenExpireTime <= currentTime) {
		localStorage.removeItem(getEnv("VUE_APP_TOKEN_KEY"))
		localStorage.removeItem(getEnv("VUE_APP_TOKEN_EXPIRES"))
		store.dispatch("setAccessToken", null)
		if (tokenExpireTime) store.dispatch("setTokenExpired", true)
		return null
	}
	let token = store.state.accessToken
	if (!token) token = localStorage.getItem(getEnv("VUE_APP_TOKEN_KEY"))
	return token
}
