<template>
	<JBox class="relative">
		<JBox
			:class="[isVisibleMenuBar ? 'bg-c0-300' : 'bg-c0-transparent', 'absolute left-0 w-full']"
			style="bottom: 6px; height: 2px"
		/>
		<JBox class="overflow-y-auto pb-2">
			<ul class="flex text-lg font-medium">
				<li
					v-for="(option, idx) in options"
					:key="idx"
					:class="[customClassTabItems, 'cursor-pointer select-none px-8 py-2 text-center relative flex-shrink-0 mr-6']"
					style="min-width: 5rem"
					@click="onChange(option)"
				>
					<JText class="leading-tight" :class="option.name === activeTab ? customColorActiveTab : 'text-c0-300'">
						{{ option.label }}
					</JText>
					<JBox
						class="absolute h-1 left-0 right-0 rounded"
						style="bottom: -2.5px"
						:class="option.name === activeTab ? 'bg-c1-500' : 'bg-c15'"
					/>
				</li>
				<slot />
			</ul>
		</JBox>
	</JBox>
</template>

<script setup>
const emits = defineEmits(["change"])
const props = defineProps({
	options: {
		type: Array,
		default: () => [],
		required: false,
	},
	activeTab: {
		type: String,
		default: "",
	},
	isVisibleMenuBar: {
		type: Boolean,
		default: true,
	},
	customClassTabItems: {
		type: String,
		default: "",
	},
	customColorActiveTab: {
		type: String,
		default: "text-c1-500",
	},
	disabledTabs: {
		type: Boolean,
		default: false,
	},
})

function onChange(selectedTab) {
	if (props.disabledTabs) return
	emits("change", selectedTab)
}
</script>
