import dayjs from "dayjs"
import router from "@/router"
import store from "@/store"
import { byType } from "@/modules/fna/helpers"

const formatData = (value, dateFormat) => {
	if (!value) return "-"
	// If dayjs || Date object
	if (dateFormat) {
		const dayObj = dayjs(value)
		if (dayObj.$d.toString() !== "Invalid Date") {
			return dayObj.format(dateFormat)
		}
	}
	return value
}

// can't use current formatData util above because of wrong time locale
// change formatData above may break many components
// so this is separate function to solve time locale issue
// will check again
const formatData2 = (value, dateFormat) => {
	if (!value) return "-"
	// If dayjs || Date object
	if (dateFormat) {
		const dayObj = dayjs(value.split("Z")[0]) // // needs to remove Z
		if (dayObj.$d.toString() !== "Invalid Date") {
			return dayObj.format(dateFormat)
		}
	}
	return value
}

const capitalize = (str) => {
	return str
		.split(" ")
		.map((word) => {
			const firstChar = word[0].toUpperCase()
			return `${firstChar}${word.substring(1)}`
		})
		.join(" ")
}

const goto = (routerName, { params, query } = {}) => {
	// Set default language params
	const defaultParams = {
		lang: store.state?.locale || "en",
	}
	router.push({
		name: routerName,
		params: {
			...defaultParams,
			...params,
		},
		query,
	})
}

const chunkDate = ({ fromDate, toDate, chunkValue }) => {
	const result = []
	if (!fromDate || !toDate) return result
	toDate = dayjs(toDate)
	fromDate = dayjs(fromDate)
	chunkValue = chunkValue || 7
	const diffDays = Math.abs(fromDate.diff(toDate, "days"))
	if (diffDays === 0) {
		return [
			{
				and: [
					{
						createdAt_gt: fromDate,
					},
					{
						createdAt_lt: fromDate.add(23, "hour").add(59, "minute").add(59, "second"),
					},
				],
			},
		]
	}
	/**
	 * If diffDays = 30 | chunkValue = 7
	 * daysChunk will be [7,7,7,7,2]
	 */
	const daysChunk = [
		...Array.from(Array(Math.floor(diffDays / chunkValue))).map((i) => chunkValue),
		...(diffDays % chunkValue === 0 ? [] : [diffDays % chunkValue]),
	]
	let tempDate = fromDate
	daysChunk.forEach((day, index) => {
		const chunk = {
			and: [
				{
					createdAt_gt: tempDate,
				},
				{
					createdAt_lt: tempDate
						.add(index === daysChunk.length - 1 ? day : day - 1, "day")
						.add(23, "hour")
						.add(59, "minute")
						.add(59, "second"),
				},
			],
		}
		tempDate = tempDate.add(day, "day")
		result.push(chunk)
	})
	return result
}

const crunchDate = (what, { type, value }) => {
	if (value === null || value.length === 0) {
		return {}
	} else {
		if (type === "between") {
			return {
				and: [
					{
						[`${what}_gt`]: dayjs(value[0]),
					},
					{
						// add 23 hours 59 minutes
						[`${what}_lt`]: dayjs(value[1]).add(1, "day"),
					},
				],
			}
		}
		// Type before
		if (type === "before") {
			return {
				[`${what}_lt`]: dayjs(value),
			}
		}
		// Type after
		if (type === "after") {
			return {
				[`${what}_gt`]: dayjs(value).add(1, "day"),
			}
		}
	}
}

const parseJSON = (stringData = null) => {
	try {
		return JSON.parse(stringData)
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error)
		return null
	}
}

const buildDate = ({ from, to, t, range }) => {
	const dateArr = []

	while (dayjs(to).diff(from, "day") >= 0) {
		const endedAt = from.add(range, "day")
		const isOverEndDate = endedAt.diff(to, "day") > 0
		dateArr.push({
			startedAt: from,
			endedAt: isOverEndDate ? to : endedAt,
			name: `${t("dashboard.week")} ${dateArr.length + 1}`,
		})
		from = from.add(range + 1, "day")
	}
	return dateArr
}

function getHolderData(item) {
	const data = item?.holder?.facts
	const citizenType = data?.find(byType("citizenIdType"))?.value?.stringValue
	const citizenId = data?.find(byType("citizenId"))?.value?.stringValue
	return {
		id: item?.holder.id,
		citizenType,
		citizenId,
	}
}

function formatObjectStringFromApiIntegration(stringWithBackslash) {
	if (!stringWithBackslash) return null
	const removeBackslash = stringWithBackslash?.replace(/\\/g, "")
	return parseJSON(removeBackslash) || {}
}
export {
	formatData,
	formatData2,
	capitalize,
	goto,
	crunchDate,
	chunkDate,
	buildDate,
	parseJSON,
	getHolderData,
	formatObjectStringFromApiIntegration,
}
