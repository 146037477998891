<template>
	<JBox class="relative">
		<JFlex v-if="isLoading" class="justify-center">
			<JSpinner variant="secondary-lg" type="dots" />
		</JFlex>
		<JBox v-else>
			<!-- Table -->
			<JBox v-show="list.length > 0" ref="tableWrapper" class="overflow-x-auto" :class="customClassTableContainer">
				<table ref="table" :class="[hasBorder && 'border', 'border-collapse w-full']">
					<thead>
						<slot name="header">
							<tr>
								<th
									v-for="(item, i) in header"
									:key="i"
									class="text-c1-800 px-6 py-4 text-left whitespace-no-wrap md:font-medium"
								>
									{{ item.label }}
								</th>
							</tr>
						</slot>
					</thead>
					<tbody
						:class="[isApplyingBoxShadow ? 'shadow' : 'shadow-none', 'overflow-hidden relative shadow rounded-lg bg-cWhite']"
					>
						<template v-for="(item, index) in list" :key="item?.id ?? index">
							<slot :item="item" :last="index === list.length - 1" :first="index === 0" :even="index % 2 === 0" />
						</template>
					</tbody>
				</table>
			</JBox>
			<slot name="footer"> </slot>
			<!-- Scrollable Arrow -->
			<JFlex v-if="isScrollable && !isDisabledScrollable" class="absolute top-0 right-0 h-16 text-c1-200 p-4 pr-6 items-center">
				<JIcon icon="ChevronDoubleRight" />
			</JFlex>
			<!-- Loading & Empty Table -->
			<JBox v-show="list?.length === 0" :class="[customClassErrorMessage, 'ml-6']">
				<JText>{{ customErrorMessage }}</JText>
			</JBox>
		</JBox>
	</JBox>
</template>

<script>
import { ref, watch, nextTick } from "vue"

export default {
	name: "CTable",
	props: {
		header: {
			type: Array,
			default: () => [],
		},
		list: {
			type: Array,
			default: () => [],
		},
		isLoading: {
			type: Boolean,
			default: false,
		},
		customErrorMessage: {
			type: String,
			default: "",
		},
		customClassErrorMessage: {
			type: String,
			default: "",
		},
		isApplyingBoxShadow: {
			type: Boolean,
			default: true,
		},
		customClassTableContainer: {
			type: String,
			default: "",
		},
		isDisabledScrollable: {
			type: Boolean,
			default: false,
		},
		hasBorder: {
			type: Boolean,
			default: false,
		},
	},

	setup(props) {
		const tableWrapper = ref(null)
		const table = ref(null)

		const isScrollable = ref(false)

		function checkScrollable() {
			nextTick(() => {
				const tableWrapperWidth = tableWrapper.value.$el.clientWidth
				const tableWidth = table.value.clientWidth
				// Check if table has horizontal scroll
				isScrollable.value = tableWidth > tableWrapperWidth
			})
		}

		watch(
			() => props.list,
			(value) => {
				if (value.length > 0) checkScrollable()
			}
		)

		watch(
			() => props.isLoading,
			(newVal, oldVal) => {
				if (oldVal === true && newVal === false) checkScrollable()
			}
		)

		return { tableWrapper, table, isScrollable, checkScrollable }
	},
}
</script>
