<template>
	<JFlex v-if="isReadingData" class="justify-center pt-10">
		<JSpinner variant="secondary-lg" type="dots" />
	</JFlex>
	<JFlex v-else class="w-full flex-col justify-center space-y-4">
		<JFlex class="flex-row w-full space-x-4 justify-between text-sm">
			<!-- Row 1 -->
			<!-- Content 1 -->
			<JFlex class="w-1/3 flex-col space-y-4 pointer-events-none">
				<JText v-for="(info, idx) in mapDataContractGeneralInfoCol1" :key="idx"
					><span class="font-bold">{{ info.label }}: </span>{{ formatData(info.value) }}</JText
				>
			</JFlex>
			<!-- Content 2 -->
			<JFlex class="w-1/3 flex-col space-y-4 pointer-events-none">
				<JText v-for="(info2, idx) in mapDataContractGeneralInfoCol2" :key="idx"
					><span class="font-bold">{{ info2.label }}: </span>{{ formatData(info2.value) }}</JText
				>
			</JFlex>
			<!-- Content 3 -->
			<JFlex class="w-1/3 flex-col space-y-4 pointer-events-none">
				<JText v-for="(info3, idx) in mapDataContractGeneralInfoCol3" :key="idx"
					><span class="font-bold">{{ info3.label }}: </span>{{ formatData(info3.value) }}</JText
				>
			</JFlex>
		</JFlex>

		<!-- Row 2 -->
		<JFlex class="w-full flex-col pointer-events-none space-y-4">
			<JText class="font-bold mt-4 text-xl uppercase">{{ $t("dashboard.mainProduct") }}</JText>
			<JFlex class="grid grid-cols-3 gap-4 text-sm">
				<JText v-for="(item, idx) in mapDataContractMainProduct" :key="idx">
					<span class="font-bold">{{ item.label }}: </span>{{ formatData(item.value) }}
				</JText>
			</JFlex>
		</JFlex>

		<!-- Row 3 -->
		<JFlex class="flex-col space-y-4">
			<JText class="font-bold mt-4 text-xl uppercase">{{ $t("dashboard.riderProducts") }}</JText>
			<GroupCollapseContractInfo
				v-for="(item, index) in mapDataContractRiders"
				:key="index"
				:title="item.title"
				:options="item.options"
				:tick="item.ticked"
				@update:tick="item.ticked = $event"
				:isTranslateTitle="false"
				customClassOptions="grid grid-cols-3"
			/>
		</JFlex>
	</JFlex>
</template>

<script>
import GroupCollapseContractInfo from "./GroupCollapseContractInfo"
import { useI18n } from "vue-i18n"
import { computed, ref } from "vue"
import { formatData } from "@/modules/core/composables"
import { useContractInfoDetail } from "../../use/useContractInfoDetail"
import { formatMoney } from "../../../fna/helpers"

export default {
	name: "ContractDetail",
	emits: ["cancel"],
	components: { GroupCollapseContractInfo },
	props: {
		contract: {
			type: Object,
			default: () => ({}),
		},
		isReadingData: {
			type: Boolean,
			default: false,
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()
		const {
			dayMonthYearFormat,
			getValPolicyStatusByCodeMVL,
			mapPaymentModes,
			freeLookIndicatorOptions,
			getValPolicyStatusByCodeTCB,
			mapNbPendingReason,
		} = useContractInfoDetail()

		const mapDataContractGeneralInfoCol1 = computed(() => {
			const {
				coverages,
				policyNo,
				apeModalPremium,
				paymentMode,
				modalPremium,
				topupPremium,
				policyStatus,
				policyStatusTCB,
				nbPendingReason,
				freeLookIndicator,
			} = props?.contract || {}

			const mProductName = coverages?.find((i) => i?.coverageType === "B")?.productName
			const mPaymentMode = mapPaymentModes.value?.find((i) => i.value === paymentMode)
			const mFreeLookIndicator = freeLookIndicatorOptions.value?.find((i) => i.value === freeLookIndicator)
			const mNBPendingReason = mapNbPendingReason.value?.find((i) => i.value === nbPendingReason)?.name

			return [
				{ value: policyNo, label: t("dashboard.policyNo") },
				{ value: mProductName, label: t("dashboard.productName") },
				{ value: apeModalPremium ? formatMoney(apeModalPremium) : apeModalPremium, label: t("dashboard.apeModalPremium") },
				{
					value: mPaymentMode?.name,
					label: t("dashboard.paymentMode"),
				},
				{ value: modalPremium ? formatMoney(modalPremium) : modalPremium, label: t("dashboard.modalPremium") },
				{ value: topupPremium ? formatMoney(topupPremium) : topupPremium, label: t("dashboard.topUpFee") },
				{
					value: `${getValPolicyStatusByCodeTCB({ status: policyStatusTCB })} (${getValPolicyStatusByCodeMVL({
						status: policyStatus,
					})})`,
					label: t("dashboard.contractStatus"),
				},
				{
					value: nbPendingReason && mNBPendingReason ? mNBPendingReason : nbPendingReason && t("dashboard.otherReason"),
					label: t("dashboard.pendingReason"),
				},
				{ value: mFreeLookIndicator?.name, label: t("dashboard.overDateOfConsideration") },
			]
		})

		const mapDataContractGeneralInfoCol2 = computed(() => {
			const { submissionDate, policyEffectiveDate, paidToDate, passFreeLookDate, issuanceDate, confirmationDate, polTrmnDt } =
				props?.contract || {}
			const mSubmissionDate = formatData(submissionDate, dayMonthYearFormat.value)
			const mPolicyEffectiveDate = formatData(policyEffectiveDate, dayMonthYearFormat.value)
			const mPaidToDate = formatData(paidToDate, dayMonthYearFormat.value)
			const mPassFreeLookDate = formatData(passFreeLookDate, dayMonthYearFormat.value)
			const mPolicyIssuanceDate = formatData(issuanceDate, dayMonthYearFormat.value)
			const mConfirmationDate = formatData(confirmationDate, dayMonthYearFormat.value)
			const mPolicyTerminatedDate = formatData(polTrmnDt, dayMonthYearFormat.value)
			return [
				{ value: mSubmissionDate, label: t("dashboard.submissionDate") },
				{ value: mPolicyEffectiveDate, label: t("dashboard.policyEffectiveDate") },
				{ value: mPassFreeLookDate, label: t("dashboard.passFreeLookDate") },
				{ value: mPolicyIssuanceDate, label: t("dashboard.policyIssuanceDate") },
				{ value: mConfirmationDate, label: t("dashboard.confirmationDate") },
				{ value: mPaidToDate, label: t("dashboard.paymentDueDate") },
				{ value: mPolicyTerminatedDate, label: t("dashboard.policyTerminatedDate") },
			]
		})

		const mapDataContractGeneralInfoCol3 = computed(() => {
			const { servicingAgentName, servicingAgentCode, tiotisSellingName, tiotisSellingCode, channel, branchCode } =
				props?.contract || {}
			return [
				{ value: channel, label: t("dashboard.policySubmitChannel") },
				{ value: `${tiotisSellingName} (${tiotisSellingCode})`, label: t("dashboard.contractConsultant") },
				{ value: branchCode, label: t("dashboard.branchCodeOfSalesAgent") },
				{ value: `${servicingAgentName} (${servicingAgentCode})`, label: t("dashboard.customerCare") },
			]
		})

		const mapDataContractMainProduct = computed(() => {
			const { coverages } = props?.contract || {}
			const mMainProduct = coverages?.find((i) => i?.coverageType === "B")
			return [
				{ value: mMainProduct?.insuredName, label: t("dashboard.insuredPerson") },
				{
					value: mMainProduct?.coverageSumAssured
						? formatMoney(mMainProduct?.coverageSumAssured)
						: mMainProduct?.coverageSumAssured,
					label: t("dashboard.insuranceFee"),
				},
				// {
				// 	value: mMainProduct?.coverageApe ? formatMoney(mMainProduct?.coverageApe) : mMainProduct?.coverageApe,
				// 	label: t("dashboard.mainProductAPE"),
				// },
				{
					value: mMainProduct?.coveragePremium ? formatMoney(mMainProduct?.coveragePremium) : mMainProduct?.coveragePremium,
					label: t("dashboard.coveragePremium"),
				},
				{
					value: `${mMainProduct?.coveragePremiumTerm || "--"} ${t("dashboard.years")}`,
					label: t("dashboard.premiumTerm"),
				},
			]
		})

		const mapDataContractRidersComputed = computed(() => {
			const { coverages } = props?.contract || {}
			const mRiders = coverages?.filter((i) => i?.coverageType !== "B")

			const contractDetail = mRiders?.map((_r) => {
				const mCoverageEffectiveDate = formatData(_r?.coverageEffectiveDate, dayMonthYearFormat.value)
				return {
					title: _r?.productName,
					ticked: false,
					options: [
						{ value: _r?.insuredName, customOrderList: t("dashboard.insuredPerson") },
						{ value: mCoverageEffectiveDate, customOrderList: t("dashboard.riderEffectiveDate") },
						{
							value: getValPolicyStatusByCodeMVL({ status: _r?.coverageStatus }),
							customOrderList: t("dashboard.status"),
						},
						{
							value: _r?.coverageSumAssured ? formatMoney(_r?.coverageSumAssured) : _r?.coverageSumAssured,
							customOrderList: t("dashboard.insuranceFee"),
						},
						// {
						// 	value: _r?.coverageApe ? formatMoney(_r?.coverageApe) : _r?.coverageApe,
						// 	customOrderList: t("dashboard.annualPremium"),
						// },
						{
							value: _r?.coveragePremium ? formatMoney(_r?.coveragePremium) : _r?.coveragePremium,
							customOrderList: t("dashboard.coveragePremium"),
						},
						{
							value: `${_r?.coveragePremiumTerm || "--"} ${t("dashboard.years")}`,
							customOrderList: t("dashboard.premiumTerm"),
						},
					],
				}
			})

			return contractDetail
		})

		const mapDataContractRiders = ref(mapDataContractRidersComputed.value)

		return {
			t,
			formatData,
			dayMonthYearFormat,
			mapDataContractGeneralInfoCol1,
			mapDataContractGeneralInfoCol2,
			mapDataContractGeneralInfoCol3,
			mapDataContractMainProduct,
			mapDataContractRiders,
		}
	},
}
</script>
