import { computed, ref, nextTick } from "vue"
import { Machine } from "xstate"
import { useMachine } from "@/modules/core/composables/useMachine"
import { offerPremiumMachine } from "./../machine/offerPremiumMachine"
import {
	gql,
	apiPromise,
	apiCases,
	apiUpdateOfferOfProposal,
	apiAddDiscountToProposalOffer,
	apiRemoveDiscountFromProposalOffer,
} from "@covergo/cover-composables"
import { fetcher } from "./../api/fetcher"
import { useRoute } from "vue-router"
import { useI18n } from "vue-i18n"
import { useStore } from "vuex"
import { handleErrorForUser } from "../api/handleErrorForUser"

export function useCaseOfferPremium() {
	const route = useRoute()
	const { t } = useI18n()
	const store = useStore()

	// store fetched data
	const premiumInfo = ref([])
	const discounts = ref([])
	const discountCodes = ref([])
	const currencyCode = ref("")
	const premiumBreakdown = ref([])

	// Form data
	const inputForm = ref({})
	const name = ref(null)
	const order = ref(null)
	const mode = ref("percentage")
	const flat = ref(null)
	const percentage = ref(null)
	const code = ref(null)
	// Form Helpers
	function resetFields() {
		name.value = null
		order.value = null
		mode.value = "percentage"
		flat.value = null
		percentage.value = null
		code.value = null
	}

	// Modal status
	const isAddModalOpen = ref(false)

	// Computed data
	const caseId = computed(() => route.params.caseId)
	const proposalId = computed(() => route.params.proposalId)
	const offerId = computed(() => route.params.offerId)
	const selectedDiscountId = ref(null)

	// Tabs
	const activeTab = ref("manual")
	const discountInputOption = ref([
		{
			label: t("core.manual"),
			name: "manual",
		},
		{
			label: t("core.discountCode"),
			name: "code",
		},
	])

	const buildPremiumInfo = (currentOffer) => {
		return [
			{
				label: t("core.paymentFrequency"),
				value: currentOffer?.premium?.paymentFrequency,
			},
			{
				label: t("core.grossPremium"),
				value: currentOffer?.premium?.formattedOriginalPrice,
			},
		]
	}

	const buildPremiumBreakdown = (currentOffer) => {
		const appliedTaxes = currentOffer?.premium?.appliedTaxes || []
		const loadings =
			currentOffer?.premium?.loadings?.map((x) => ({
				...x,
				name: x?.codeName?.name,
				code: x?.codeName?.code,
				formattedFlat: `${x?.currencyCode}${x?.flat}`,
			})) || []
		return [
			...appliedTaxes,
			...loadings,
			{
				name: t("core.netPremium"),
				formattedFlat: currentOffer?.premium?.formattedPrice,
			},
		]
	}

	function addToast({ type = "success", messageText = "" }) {
		store.dispatch("addToastMessage", {
			type,
			content: {
				type: "message",
				text: messageText,
			},
		})
	}

	const options = {
		services: {
			setManualInput() {
				activeTab.value = "manual"
			},

			setCodeInput() {
				activeTab.value = "code"
			},

			openAddDiscountModal() {
				isAddModalOpen.value = true
			},

			async readOfferPremium() {
				const variables = {
					where: {
						id: caseId.value,
					},
					proposalWhere: {
						id: proposalId.value,
					},
					offerWhere: {
						id: offerId.value,
					},
				}
				const fragment = gql`
					fragment result on cases {
						list {
							proposals(where: $proposalWhere) {
								name
								basket(where: $offerWhere) {
									id
									createdAt
									premium {
										currencyCode
										paymentFrequency
										formattedPrice
										formattedOriginalPrice
										discountCodes
										appliedTaxes {
											name
											code
											formattedFlat
											formattedOriginalPrice
											ratio
										}
										discounts {
											name
											code
											formattedFlat
											formattedOriginalPrice
											ratio
										}
										appliedDiscounts {
											id
											name
											code
											ratio
											flat
											formattedFlat
											order
											formattedOriginalPrice
											originalPrice
										}
										loadings {
											codeName {
												name
												code
											}
											flat
											currencyCode
											formattedOriginalPrice
											ratio
										}
									}
								}
							}
						}
					}
				`
				return await apiPromise(apiCases, {
					variables,
					fetcher,
					fragment,
				})
			},

			async addManualDiscount() {
				const variables = {
					caseId: caseId.value,
					proposalId: proposalId.value,
					offerId: offerId.value,
					input: { ...inputForm.value },
				}
				return await apiAddDiscountToProposalOffer({ variables, fetcher })
			},

			async addCodeDiscount() {
				const variables = {
					caseId: caseId.value,
					proposalId: proposalId.value,
					offerId: offerId.value,
					input: {
						premium: {
							discountCodes: [...discountCodes.value, ...inputForm.value.code],
						},
					},
				}
				return await apiUpdateOfferOfProposal({ variables, fetcher })
			},

			async removeDiscount() {
				const variables = {
					caseId: caseId.value,
					proposalId: proposalId.value,
					offerId: offerId.value,
					discountId: selectedDiscountId.value,
				}
				return await apiRemoveDiscountFromProposalOffer({ variables, fetcher })
			},

			async resetPremium() {
				const variables = {
					caseId: caseId.value,
					proposalId: proposalId.value,
					offerId: offerId.value,
					input: {
						premium: {
							amount: null,
							currencyCode: currencyCode?.value ?? null,
						},
					},
				}
				return await apiUpdateOfferOfProposal({ variables, fetcher })
			},
		},
		actions: {
			setResults(ctx, event) {
				const currentCase = event?.data?.list?.[0]
				const currentProposal = currentCase?.proposals?.[0]
				const currentOffer = currentProposal?.basket?.[0]
				premiumInfo.value = buildPremiumInfo(currentOffer)
				currencyCode.value = currentOffer?.premium?.currencyCode ?? ""
				discounts.value = currentOffer?.premium?.appliedDiscounts ?? []
				discountCodes.value = currentOffer?.premium?.discountCodes ?? []
				premiumBreakdown.value = buildPremiumBreakdown(currentOffer)
			},

			buildInputForm() {
				if (activeTab.value === "code") inputForm.value = { code: code.value }
				if (activeTab.value === "manual") {
					const buildCalcLogic = () => {
						if (mode.value === "flat") return `{ '-': [ {'var' : 'premium'}, ${flat.value} ]}`
						if (mode.value === "percentage") return `{ '*': [ {'var' : 'premium'}, ${1 - percentage.value / 100} ]}`
					}
					const calculationJsonLogic = buildCalcLogic()
					inputForm.value = { name: name.value, order: order.value, calculationJsonLogic }
				}
			},

			setAddDiscountSuccessMessage() {
				addToast({ messageText: t("core.addDiscountSuccess") })
			},

			setRemoveDiscountSuccessMessage() {
				addToast({ messageText: t("core.removeDiscountSuccess") })
			},

			setResetPremiumSuccessMessage() {
				addToast({ messageText: t("core.resetPremiumSuccess") })
			},

			setMessage(ctx, event) {
				handleErrorForUser({ error: event?.data, $t: t })
			},

			closeAddDiscountModal() {
				isAddModalOpen.value = false
				nextTick(() => resetFields())
			},
		},
		guards: {
			isManualDiscount: () => activeTab.value === "manual",
			isCodeDiscount: () => activeTab.value === "code",
		},
	}

	const { state, send } = useMachine(Machine(offerPremiumMachine, options), {
		devTools: process.env.NODE_ENV === "development",
	})

	return {
		state,
		send,
		name,
		order,
		mode,
		flat,
		percentage,
		code,
		premiumInfo,
		discounts,
		currencyCode,
		premiumBreakdown,
		isAddModalOpen,
		discountInputOption,
		activeTab,
		inputForm,
		selectedDiscountId,
	}
}
