export default {
	resumeFlag: null,
	initialActiveStep: "customerDetails",
	initialCompletedSteps: [],
	goalsFactId: null,
	selectedGoals: ["childrenEducation"],
	caseData: null,
	prices: {},
	promotions: null,
	defaultSA: null,
	overwriteProductOptions: null,
	selectedProduct: null,
	customizingProduct: null,
	customerDetails: {
		gender: null,
		age: null,
		numberOfDependents: 0,
	},
	customerInfo: {
		uid: "",
		relatedAccountId: "",
		leadId: "",
		branchCode: "",
		fullName: "",
		citizenIdType: null, // 1: citizen id || 4: passport
		citizenId: "",
		phoneNumber: "",
		email: "",
		gender: "",
		dateOfBirth: "",
		maritalStatus: "",
		spouseName: "",
		spouseAge: null,
		customerId: "",
		dependentList: [],
		meetingMethod: "",
		staffId: "",
		bankStaffName: "",
		idIssuedDate: null,
		idIssuedPlace: "",
		occupation: "",
		address: {
			street: "",
			district: "",
			ward: "",
			city: "",
			normalizedAddress: "",
		},
		smokingHabit: "",
		isAgentPO: false,
	},
	ratesFactId: null,
	answersFactId: null,
	dropOffReason: null,
	otherDropOffReason: null,
	pauseReason: null,
	conversationResult: null,
	tisAgentCode: null,
	leadGCM: null,
	gcmLeadIdFromRoute: null,
	referLeadFna: null,
	recentLeadApiStatus: null,
	planCode: null,
	mvpVersion: null,
	completedDate: null,
	rates: {
		interest: 5,
		inflation: 5,
		educationInflation: 4,
	},
	productCode: null,
	answers: {},
	answersSchema: {
		// Example for answers data
		childrenEducation: [
			{
				age: 12,
				ageToUseFund: 18,
				studyTime: 5,
				expectedEducationFee: 5000000,
			},
		],
		growWealthAndAssets: {
			goal: "bigPurchases",
			bigPurchases: [
				{
					asset: "house",
					amount: 1000000,
					yearsPlannedToBuy: 10,
				},
			],
			growWealth: {
				targetSaving: 50000,
				expectedTimeToReachTargetSaving: 10,
			},
		},
		financeUncertainty: {
			monthlyIncomeNeeded: 4000,
			currentLoan: 0,
			protectionTime: 20,
		},
		healthAndMedical: {
			interestedBenefits: ["medicalCare", "healthCare", "accidentAndDisability", "CI"],
			hospitalisedRoomAndBoard: 200,
			roomAndBoardExpense: {
				inpatient: 125000000,
				outpatient: 0,
				dental: 0,
			},
			accidentAndDisabilityCoverAmount: 50000,
			critticalIllnessCoverAmount: 50000,
		},
		retirement: {
			retirementAge: 65,
			expectedMonthlyExpenseAfterRetirement: 2000,
			howLongRetirement: 10,
		},
		having: {
			householdIncome: 0,
			householdIncomeDetails: {
				investmentBusinessIncome: 0,
				salaryBonusIncome: 0,
				rentalIncome: 0,
				bankInterestIncome: 0,
				dividendIncome: 0,
				otherIncome: 0,
			},
			householdExpense: 0,
			householdExpenseDetails: {
				livingExpense: 0,
				educationExpense: 0,
				contingencyBudget: 0,
				entertainmentExpense: 0,
				insuranceExpense: 0,
				liabilityExpense: 0,
				otherExpense: 0,
			},
			yourAccumulatedSaving: 5000,
			currentProtectionCoverage: 0,
			totalNetSaving: 0, // = householdIncome - householdExpense
		},
	},
	isResumeFNA: false,
	titanPdf: null,
	fnaPdf: null,
	isAnswerDefault: false,
	ridersValid: true,
	fnaBackDestination: null,
	ePosLinkCustomerDB: "",
	baseProductId: null, // logic at June 2022 when fetching the baseProduct for a specific product
	baseProductList: [],
	existenceUserInfo: {
		citizenId: false, // only need to check idNumber (if user selects idType -> also require to fill idNumber)
		phoneNumber: false,
	},
	directReferralCode: "",
	refererBranchCode: "",
	customerAccountsBySearching: null,
	annualPremium: null,
}
