import { DEFAULT_ROLES_PERMISSION } from "@/tenants/use/pagesAccessingByRolesAndPermissions.js"

const ViewHome = () => import(/* webpackChunkName: "group-fna" */ "../views/ViewHome")
const ViewFNA = () => import(/* webpackChunkName: "group-fna" */ "../views/ViewFNA")
const ViewResumeFNA = () => import(/* webpackChunkName: "group-fna" */ "../views/ViewResumeFNA")

export default [
	{
		path: "/home",
		component: ViewHome,
		name: "ViewHome",
		props: true,
		meta: { module: "fna", component: "ViewHome", roles: DEFAULT_ROLES_PERMISSION },
	},
	{
		path: "/fna/:caseId",
		component: ViewFNA,
		name: "ViewFNA",
		props: true,
		meta: { module: "fna", component: "ViewFNA", roles: ["default"] },
	},
	{
		path: "/fna/resume/:caseId",
		component: ViewResumeFNA,
		name: "ViewResumeFNA",
		props: true,
		meta: { module: "case", component: "ViewResumeFNA", roles: ["default"] },
	},
]
