import { PRODUCT_NAME, PRODUCT_PLANS, PRODUCT_TYPES, VERSION } from "@/modules/customerSupport/constants"

// Premium of fetching a pricing (base + rider premium)
export const PRODUCT_MIN_PREMIUM = {
	[PRODUCT_NAME.ul048]: 7000000,
	[PRODUCT_NAME.titan_6years]: 7000000,
	[PRODUCT_NAME.titan_10years]: 7000000,
	[PRODUCT_NAME.titanium_2]: 10000000,
	[PRODUCT_NAME.ul038]: 7000000,
	[PRODUCT_NAME.ul040]: 7000000,
}

export const PRODUCT_MIN_SA = {
	[PRODUCT_NAME.ul048]: 100000000,
	[PRODUCT_NAME.titan_6years]: 100000000,
	[PRODUCT_NAME.titan_10years]: 100000000,
	[PRODUCT_NAME.titanium_2]: 300000000,
	[PRODUCT_NAME.ul038]: 100000000,
	[PRODUCT_NAME.ul040]: 100000000,
	[PRODUCT_NAME.edu]: 100000000,
}

export const PRODUCT_MAX_SA = {
	[PRODUCT_NAME.ul048]: 1000000000,
	[PRODUCT_NAME.titan_6years]: 5000000000,
	[PRODUCT_NAME.titan_10years]: 5000000000,
	[PRODUCT_NAME.titanium_2]: 0,
	[PRODUCT_NAME.ul038]: 0,
	[PRODUCT_NAME.ul040]: 0,
}

export function productsPlanMapping() {
	return {
		titan_6years: {
			type: PRODUCT_TYPES.titan_6years,
			plan: PRODUCT_PLANS.titan_6years,
			getFaceAmount: (sa) => {
				if (sa < PRODUCT_MIN_SA.titan_6years) return PRODUCT_MIN_SA.titan_6years
				if (sa > PRODUCT_MAX_SA.titan_6years) return PRODUCT_MAX_SA.titan_6years

				return sa
			},
		},
		titan_10years: {
			type: PRODUCT_TYPES.titan_10years,
			plan: PRODUCT_PLANS.titan_10years,
			getFaceAmount: (sa) => {
				if (sa < PRODUCT_MIN_SA.titan_10years) return PRODUCT_MIN_SA.titan_10years
				if (sa > PRODUCT_MAX_SA.titan_10years) return PRODUCT_MAX_SA.titan_10years

				return sa
			},
		},
		titanium_2: {
			type: PRODUCT_TYPES.titanium_2,
			plan: PRODUCT_PLANS.titanium_2, // superLink
			getFaceAmount: (sa) => {
				if (sa < PRODUCT_MIN_SA.titanium_2) return PRODUCT_MIN_SA.titanium_2

				return sa
			},
		},
		ul038: {
			type: PRODUCT_TYPES.ul038,
			plan: PRODUCT_PLANS.ul038,
			getFaceAmount: (sa) => {
				if (sa < PRODUCT_MIN_SA.ul038) return PRODUCT_MIN_SA.ul038

				return sa
			},
		},
		ul040: {
			type: PRODUCT_TYPES.ul040,
			plan: PRODUCT_PLANS.ul040,
			getFaceAmount: (sa) => {
				if (sa < PRODUCT_MIN_SA.ul040) return PRODUCT_MIN_SA.ul040

				return sa
			},
		},
		ul048: {
			type: PRODUCT_TYPES.ul048,
			plan: PRODUCT_PLANS.ul048,
			getFaceAmount: (sa) => {
				if (sa < PRODUCT_MIN_SA.ul048) return PRODUCT_MIN_SA.ul048
				if (sa > PRODUCT_MAX_SA.ul048) return PRODUCT_MAX_SA.ul048
				return sa
			},
		},
	}
}

export function productVersionMapping({ productName }) {
	let productVersion = VERSION.version_1
	switch (productName) {
		case PRODUCT_NAME.titan_6years:
		case PRODUCT_NAME.titan_10years:
		case PRODUCT_NAME.ul048:
			productVersion = VERSION.version_1
			break
		case PRODUCT_NAME.titanium_2:
			productVersion = VERSION.version_6
			break
		case PRODUCT_NAME.ul040:
			productVersion = VERSION.version_2
			break
		case PRODUCT_NAME.ul038:
			productVersion = VERSION.version_8
			break
	}
	return productVersion
}
