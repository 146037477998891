import { computed } from "vue"
import { merge } from "lodash"

import { useVariant } from "@/composables/variant"

export default function useFormKit(props, ctx, { componentName } = {}) {
	const variants = useVariant(componentName, props)
	const computedProps = computed(() => {
		const attrs = ctx.attrs
		const inputProps = {
			select: {
				selectIcon: "CaretDown",
			},
		}

		return merge(
			{
				variants,
			},
			attrs,
			inputProps[attrs.type]
		)
	})

	return {
		computedProps,
	}
}
