export default {
	auth: {
		loginTitle: "Đăng nhập vào iTCBLife",
		loginTo: "Đăng nhập vào iTCBLife",
		loginSubtitle: "Giải pháp quản trị chính sách doanh nghiệp",
		email: "Địa chỉ email",
		toolName: "",
		password: "Mật khẩu",
		login: "Đăng nhập",
		forgotPassword: "QUÊN MẬT KHẨU?",
		loginError: "Đăng nhập lỗi",
		send: "Gửi",
		backToLogin: "Quay lại Đăng Nhập",
		success: "Thành công!",
		fail: "Thất bại!",
		sendMailSuccessNote:
			"Chúng tôi đã gửi thông tin tới hộp thư của quý khách. Hãy làm theo hướng dẫn để khôi phục lại mật khẩu.",
		sendMailFailNote: "Không gửi được. Vui lòng thử lại sau.",
		forgotPasswordNote: "Đừng lo! Quý khách vui lòng nhập tên đăng nhập, chúng tôi sẽ gửi hướng dẫn phục hồi lại mật khẩu.",
		newPassword: "Mật khẩu mới",
		updatePasswordSuccessNote: "Mật khẩu đã được cập nhật thành công.",
		updatePasswordFailNote: "Có lỗi khi cập nhật mật khẩu. Hãy thử lại!",
		updatePasswordNote: "Tạo mật khẩu mới cho tài khoản của quý khách.",
		confirm: "Xác nhận",
		goToLogin: "Đi tới Đăng Nhập",
		confirmPassword: "Xác nhận mật khẩu",
		invalid_username_or_password: "Tên người dùng hoặc mật khẩu không đúng",
		userNameToLogin: "Tên đăng nhập",
		invalid_current_password: "Thông tin mật khẩu hiện tại chưa đúng",
		userLockoutMessage: "Tài khoản của Anh/Chị đã bị khoá. Vui lòng liên hệ với quản trị để được trợ giúp.",
		invalid_username: "Thông tin người dùng không tồn tại",
		invalidAccount:
			"<div>\n" +
			"    <div>Tài khoản iTCBLife được tự động đồng bộ với tài khoản Microsoft account được đăng nhập trên thiết bị.</div>\n" +
			"    <div>Tài khoản {userId} bạn đang đăng nhập hiện chưa được cấp quyền truy cập trên iTCBLife.</div>\n" +
			"    <div style='margin-left: 20px'>- Nếu đây ĐÚNG là tài khoản của bạn thì vui lòng email tới hòm thư <a style='text-decoration: underline;' href={mailTo}>{supportEmail}</a> để được hỗ trợ.</div>\n" +
			"    <div style='margin-left: 20px'>\n" +
			"        - Nếu đây KHÔNG phải tài khoản của bạn, vui lòng đăng xuất các tài khoản Microsoft trên thiết bị bằng đường dẫn sau <a style=\"text-decoration: underline;\" href=\"https://www.office.com\">https://www.office.com</a> và đăng nhập lại.\n" +
			"    </div>\n" +
			"</div>",
		systemErrorLogin:
			"Đăng nhập không thành công do vấn đề về mạng, vui lòng kiểm tra/kết nối với mạng khác, hoặc đổi thiết bị truy cập",
	},
}
