/* eslint-disable no-tabs */
import { ref, reactive, toRefs } from "vue"
import { useVuelidate } from "@vuelidate/core"
import { useSearchAgentCode } from "./useSearchAndFetchAgentCode"
import dayjs from "dayjs"
import { useI18n } from "vue-i18n"
import useApis from "./useApis"
import { splitAgentCodeValue } from "@/helpers/splitAgentCodeValue.js"
import { required, maxLength } from "@vuelidate/validators"
import { useStore } from "vuex"
export function useToUpdateAgentInfo() {
	const { t } = useI18n()
	const store = useStore()
	// initial data
	const { agentCodeOptions, isLoadingSearchAgentCode, onHandleSearchAgentCode } = useSearchAgentCode()
	const { fetchDataPolicyByServicingAgent, sendRequestToUpdateAgentCode } = useApis({})
	const isLoadingFetchAllCases = ref(false)
	const contractListAreEmpty = ref(null)

	const contractList = ref([])
	const filterData = reactive({
		servicingAgentCode: "",
		contractNo: "",
		newServicingAgentCode: "",
	})
	// validation
	const filterDataFormToRefs = toRefs(filterData)
	const rules = {
		servicingAgentCode: { requiredToInputAgentCode: required },
		contractNo: {
			maxLength: maxLength(10),
		},
	}
	const v = useVuelidate(rules, filterDataFormToRefs)

	// pagination
	const totalItems = ref(0)
	const skip = ref(0)
	const limit = ref(20) // 20 records a page: dont change the rule
	const currentPage = ref(1)
	const renderSystemError = async (err = "", errorMessage = "") => {
		await store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: t(`dashboard.${errorMessage}`) + " " + err,
			},
		})
	}
	const renderWarningToSelectPolicy = () => {
		store.dispatch("addToastMessage", {
			type: "error",
			content: {
				type: "message",
				text: t("dashboard.warningSelectItem"),
			},
		})
	}
	const readAllCases = async () => {
		/**
		 * expected params: {
			"headerRequest": {
				"createDate": "2022-07-08T21:00:00",
				"fromNm": "iTCBLife",
				"toNm": "MVL",
				"serviceType": "GetServicingAgent"
			},
			"document": {
				"agentCode": "HK930", // the agent code
				"policyNumber": "",
				"pageIndex": 1,
				"pageSize": 20
			}
		}
		 */

		const params = {
			headerRequest: {
				createDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
				fromNm: "iTCBLife",
				serviceType: "GetServicingAgent",
				toNm: "MVL",
			},
			document: {
				agentCode: (filterData?.servicingAgentCode && splitAgentCodeValue(filterData?.servicingAgentCode)?.code) || "B2082",
				policyNumber: filterData?.contractNo ? filterData?.contractNo?.trim() : "",
				pageSize: limit.value,
				pageIndex: currentPage.value,
			},
		}
		return await fetchDataPolicyByServicingAgent({ params })
	}
	const readAllContractData = async () => {
		try {
			isLoadingFetchAllCases.value = true
			const data = await readAllCases()
			// data list return []
			if (data?.status === "failure") {
				store.dispatch("addToastMessage", {
					type: "error",
					content: {
						type: "message",
						text: t("dashboard.systemError"),
					},
				})
				return
			}
			if (!data?.value?.data?.length > 0) contractListAreEmpty.value = t("dashboard.resultNotMatch")

			totalItems.value = data?.value?.paging?.totalElements
			contractList.value = data?.value?.data || []
		} catch (e) {
			console.error(e)
		} finally {
			isLoadingFetchAllCases.value = false
		}
	}

	const refetchTotalItems = async () => {
		try {
			const data = await readAllCases()
			if (data?.status === "failure") return
			totalItems.value = data?.value?.paging?.totalElements
		} catch (e) {
			console.error(e)
		}
	}

	// Request to MVL
	const sendRequestToMVLToUpdateAgentCode = async ({ data = [] }) => {
		/**
		 * expected params = {
		 * {
				"headerRequest": {
					"createDate": "2022-07-08T21:00:00",
					"fromNm": "iTCBLife",
					"toNm": "MVL",
					"serviceType": "updateServicingAgent"
				},
				"document": [
					{
						"policyNumber": "2900180224",
						"newAgentCode": "HK930"
						// add more field to BE to record the case
						poName: "",
						currentAgentCode: "",
						currentAgentName: "",
						newAgentName: "",
					}
				]
			}}
		 */
		const params = {
			headerRequest: {
				createDate: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
				fromNm: "iTCBLife",
				serviceType: "updateServicingAgent",
				toNm: "MVL",
			},
			document: data,
		}
		return await sendRequestToUpdateAgentCode({ params })
	}

	return {
		v,
		isLoadingFetchAllCases,
		contractList,
		contractListAreEmpty,
		agentCodeOptionsOnFilter: agentCodeOptions,
		isLoadingSearchAgentCode,

		// pagination
		totalItems,
		skip,
		limit,
		currentPage,

		// methods
		readAllContractData,
		sendRequestToMVLToUpdateAgentCode,
		onHandleSearchAgentCode,
		renderSystemError,
		renderWarningToSelectPolicy,
		refetchTotalItems,
		//
		...filterDataFormToRefs,
	}
}
