import { computed, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { parseJSON } from "@/modules/core/composables"
import { useContractInfoDetail } from "./useContractInfoDetail"
import { splitAgentCodeValue } from "@/helpers/splitAgentCodeValue.js"
import { uniqBy } from "lodash"
import useSourceOfLoginUser from "@/modules/core/use/useUserSource"
import useAgentCodes from "@/modules/core/use/useAgentCodes"

export function useSearchAgentCode() {
	const store = useStore()
	const agentCodeOptions = ref([])
	const userAgentCodeOptions = ref([])
	const isLoadingSearchAgentCode = ref(false)
	const userAgentCode = computed(() => JSON.parse(store.getters.me?.associatedUser?.fields || "{}")?.agentCode || "")
	const { mValueUserPosition, isBranchManager } = useContractInfoDetail()
	const loadedTisAgentCode = computed(() => store.state.fna.tisAgentCode)
	const agentCode = ref("")
	const { userLocatedByBranchOrArea } = useSourceOfLoginUser()
	const { fetchAgentCode, defaultVariablesOfFetchingAgentCodes } = useAgentCodes()

	const defaultVariablesToFetchAgents = computed(() => {
		const variables = defaultVariablesOfFetchingAgentCodes(userLocatedByBranchOrArea)
		if (mValueUserPosition.value?.length) {
			variables.where.and.push({ or: [...mValueUserPosition.value] })
		}
		return variables
	})
	async function refetchAgentCodes() {
		if (loadedTisAgentCode.value) {
			onHandleSearchAgentCode(loadedTisAgentCode.value)
		} else {
			await readIndividualAgentCode(defaultVariablesToFetchAgents.value)
		}

		userAgentCodeOptions.value = agentCodeOptions.value
	}

	onMounted(async () => {
		await refetchAgentCodes()
	})

	const onUpdateAgentCode = (newVal) => {
		const { code } = splitAgentCodeValue(newVal)
		return code
	}

	async function readIndividualAgentCode(variables = {}) {
		isLoadingSearchAgentCode.value = true
		const { data, error } = await fetchAgentCode(variables)
		if (error) throw error
		else {
			if (Array.isArray(data?.individualAgentCode?.list)) {
				let dataList = data.individualAgentCode.list?.reduce((resultList, currentItem) => {
					const fields = parseJSON(currentItem.fields, "{}")
					if (!fields?.agentCodeName) return resultList
					const transformItem = {
						...currentItem,
						value: fields?.agentCodeName,
						code: fields?.agentCodeName,
						name: fields?.agentCode,
					}
					if (transformItem?.name === userAgentCode.value) return resultList
					if (isBranchManager.value) {
						const userSource = transformItem?.source?.split("-")
						const userPosition = userSource?.[userSource?.length - 1]
						if (!["RBO.SRBO", "TIS.PRM"]?.includes(userPosition)) {
							resultList.push(transformItem)
						}
					} else {
						resultList.push(transformItem)
					}
					return resultList
				}, [])
				dataList = uniqBy(dataList, "code")
				agentCodeOptions.value = dataList
				// in case user has selected the agent code and when searching -> check it
				agentCode.value = agentCodeOptions.value?.find((x) => x?.code.includes(loadedTisAgentCode.value))?.value || ""
			}
		}
		isLoadingSearchAgentCode.value = false
	}

	function onHandleSearchAgentCode(value) {
		if (value.length) {
			const variablesOfInputSearch = defaultVariablesToFetchAgents.value
			variablesOfInputSearch.where.and.push({
				fields: {
					path: "fields.agentCodeName",
					value: { stringValue: value },
					condition: "STRING_CONTAINS",
				},
			})
			readIndividualAgentCode(variablesOfInputSearch)
		} else {
			agentCodeOptions.value = userAgentCodeOptions.value
		}
	}

	return {
		agentCodeOptions,
		isLoadingSearchAgentCode,
		agentCode,
		onUpdateAgentCode,
		onHandleSearchAgentCode,
	}
}
