<template>
	<JBox>
		<CAccordion showCount :numberOfField="facts.length" :title="$t('core.facts')" class="mb-8">
			<CEditableField
				v-for="(f, idx) in formatedFacts"
				:key="idx"
				:type="f.type"
				:label="f.label"
				:value="getValue(f)"
				:itemId="f.id"
				:isList="f.type === 'arrayValue'"
				@edit="handleClickEditFact($event)"
				@delete="handleDeleteFact($event)"
			/>
			<JBox class="px-5">
				<JText v-if="!facts.length" class="text-c0-500">
					{{ $t("core.noDataHere") }}
				</JText>
				<JButton class="mt-5" variant="primary-sm" iconPrefix="PlusCircle" @click="handleClickAddFact">
					{{ $t("core.addFacts") }}
				</JButton>
			</JBox>
		</CAccordion>

		<!-- Modal Add/Edit Fact -->
		<teleport to="#layer2">
			<JModalSimple :isVisible="isModalOpen" variant="center-2xl" @overlay-click="handleCloseModal">
				<!-- Modal Header -->
				<JBox class="mb-6">
					<JText class="text-c0-300 font-medium">
						{{ !isUpdating ? $t("core.addNew") : $t("core.update") }}
					</JText>
					<JHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
						{{ $t("core.fact") }}
					</JHeadline>
				</JBox>

				<!-- Modal form -->
				<JFlex class="w-full mt-5">
					<CFormInput
						v-model="type"
						componentName="JSelect"
						:placeholder="$t('core.pleaseSelect')"
						:options="factTypeOptions"
						:label="$t('core.type')"
						:validator="v"
						field="type"
						@update:modelValue="handleChangeType($event)"
					/>
				</JFlex>
				<JFlex v-if="type" class="w-full mt-5">
					<CFormInput v-model="label" componentName="JInputText" :validator="v" field="label" :label="$t('core.label')" />
				</JFlex>
				<JFlex v-if="type && componentName" class="w-full mt-5">
					<CFormInput
						v-model="value[type]"
						:componentName="componentName"
						:iconSuffix="componentName === 'JDatePicker' ? 'Calendar' : ''"
						:label="$t('core.value')"
					/>
				</JFlex>
				<JBox v-if="type === 'arrayValue'" class="w-full mt-5">
					<JBox class="pointer-events-none text-md font-medium mb-2 text-cBlack">
						{{ $t("core.value") }}
					</JBox>
					<JFlex v-for="(item, index) in value.arrayValue" :key="index" class="mb-2 items-center">
						<CFormInput v-model="item.stringValue" componentName="JInputText" />
						<JButton class="ml-3" variant="warning-sm" @click="handleRemoveArrayValue(index)">
							{{ $t("core.remove") }}
						</JButton>
					</JFlex>
					<JButton variant="primary-sm" iconSubfix="PlusCircle" @click="handleAddArrayValue">
						{{ $t("core.addRow") }}
					</JButton>
				</JBox>

				<!-- Modal actions -->
				<JFlex v-if="!isFactLoading" class="mt-10">
					<JButton class="mr-2 flex items-center" variant="primary" @click="handleClickConfirm">
						{{ $t("core.confirm") }}
					</JButton>
					<JButton class="ml-2" variant="tertiary-outline" @click="handleCloseModal">
						{{ $t("core.cancel") }}
					</JButton>
				</JFlex>
				<JFlex v-else class="items-center mt-6 h-10">
					<JSpinner variant="secondary-lg" type="dots" />
				</JFlex>
			</JModalSimple>
		</teleport>
	</JBox>
</template>

<script>
import { computed, onMounted, reactive, ref, toRef, toRefs } from "vue"
import { Machine } from "xstate"
import dayjs from "dayjs"
import {
	apiPromise,
	apiAddPolicyFact as addFactToPolicy,
	apiRemovePolicyFact as removeFactFromPolicy,
	apiUpdatePolicyFact as updateFactOfPolicy,
	apiAddFactToCase as addFactToCase,
	apiRemoveFactFromCase as removeFactFromCase,
	apiUpdateFactOfCase as updateFactOfCase,
	apiAddFactToTransaction as addFactToTransaction,
	apiUpdateTransactionFact as updateFactOfTransaction,
	apiRemoveFactFromTransaction as removeFactFromTransaction,
	apiAddFactToProposalOffer as addFactToProposalOffer,
	apiUpdateFactOfProposalOffer as updateFactOfProposalOffer,
	apiRemoveFactFromProposalOffer as removeFactFromProposalOffer,
} from "@covergo/cover-composables"
import { formatData, capitalize } from "@/modules/core/composables"
import { fetcher } from "./../../api/fetcher"
import { handleErrorForUser } from "./../../api/handleErrorForUser"
import { useMachine } from "@/modules/core/composables/useMachine"
import { factCardMachine } from "./machine"
import { useI18n } from "vue-i18n"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { useStore } from "vuex"

export default {
	name: "CFactCard",
	emits: ["fact:changed"],
	props: {
		factOf: {
			type: String,
			validator(val) {
				const validFactOf = ["policy", "case", "transaction", "proposalOffer"]
				return validFactOf.includes(val)
			},
			required: true,
		},
		/**
		 *  --- parentId ---
		 *  Base on factOf
		 *  - policy: policyId
		 *  - case: caseId
		 *  - transaction: transactionId
		 *  - proposalOffer: { caseId, proposalId, offerId }
		 */
		parentId: {
			type: [String, Object],
		},

		/**
		 * Fact list
		 */
		facts: {
			type: Array,
			required: true,
		},
	},
	setup(props, { emit }) {
		// Initial data
		const factItem = reactive({
			id: "",
			type: "",
			label: "",
			value: null,
		})
		const factTypeOptions = ref([])
		const deleteFactId = ref("")

		const store = useStore()
		const { t } = useI18n()
		const rules = {
			type: { required },
			label: { required },
		}

		const v = useVuelidate(rules, {
			type: toRef(factItem, "type"),
			label: toRef(factItem, "label"),
		})

		// Computed
		const fn = computed(() => ({
			addFactToPolicy,
			updateFactOfPolicy,
			removeFactFromPolicy,
			addFactToCase,
			updateFactOfCase,
			removeFactFromCase,
			addFactToTransaction,
			updateFactOfTransaction,
			removeFactFromTransaction,
			addFactToProposalOffer,
			updateFactOfProposalOffer,
			removeFactFromProposalOffer,
		}))

		const formatedFacts = computed(() => {
			return props.facts.map((f) => ({
				id: f.id,
				...parseScalarValue(f.value),
				label: f?.type,
			}))
		})

		const componentName = computed(() => {
			const { type } = factItem
			if (!type) return null
			if (type === "stringValue") return "JInputText"
			if (type === "numberValue") return "JInputNumber"
			if (type === "dateValue") return "JDatePicker"
			if (type === "booleanValue") return "JToggle"
			return null
		})

		const addFactFunction = computed(() => fn.value[`addFactTo${capitalize(props.factOf)}`])

		const updateFactFunction = computed(() => fn.value[`updateFactOf${capitalize(props.factOf)}`])

		const deleteFactFunction = computed(() => fn.value[`removeFactFrom${capitalize(props.factOf)}`])

		const idObject = computed(() => {
			switch (props.factOf) {
				case "policy":
					return {
						policyId: props.parentId,
					}
				case "case":
					return {
						caseId: props.parentId,
					}
				case "transaction":
					return {
						transactionId: props.parentId,
					}
				case "proposalOffer":
					return {
						...props.parentId,
					}
			}
			return null
		})

		// Methods
		function handleChangeType(type) {
			const newFactItem = { ...factItem }
			if (type === "stringValue") {
				newFactItem.value = {
					stringValue: "",
				}
			} else if (type === "numberValue") {
				newFactItem.value = {
					numberValue: 0,
				}
			} else if (type === "booleanValue") {
				newFactItem.value = {
					booleanValue: false,
				}
			} else if (type === "dateValue") {
				newFactItem.value = {
					dateValue: dayjs(),
				}
			} else if (type === "arrayValue") {
				newFactItem.value = {
					arrayValue: [
						{
							stringValue: "",
						},
					],
				}
			} else newFactItem.value = {}
			setFactItem(newFactItem)
		}

		function handleClickAddFact() {
			setFactItem({
				id: "",
				type: "",
				label: "",
				value: null,
			})
			send("CREATE")
		}

		function handleClickEditFact(selectedFactItem) {
			const factItemToEdit = props.facts.find((f) => f.id === selectedFactItem.id)
			const parsedValue = parseScalarValue(factItemToEdit.value)
			setFactItem({
				id: factItemToEdit.id,
				label: factItemToEdit.type,
				type: parsedValue.type,
				value: parsedValue.value,
			})
			send("UPDATE")
		}

		function handleDeleteFact(factId) {
			deleteFactId.value = factId
			send("DELETE")
		}

		function handleCloseModal() {
			if (state.value.matches("creating")) {
				send("CANCEL_ADD")
			} else if (state.value.matches("updating")) {
				send("CANCEL_UPDATE")
			}
			// Reset factItem
			setTimeout(() => {
				setFactItem({
					id: "",
					type: "",
					label: "",
					value: null,
				})
			}, 100)
			// Reset validation
			v.value.$reset()
		}

		function handleAddArrayValue() {
			const newFactItem = { ...factItem }
			newFactItem.value.arrayValue.push({
				stringValue: "",
			})
			setFactItem(newFactItem)
		}

		function handleRemoveArrayValue(index) {
			const newFactItem = { ...factItem }
			newFactItem.value.arrayValue.splice(index, 1)
			setFactItem(newFactItem)
		}

		function setFactItem(newVal) {
			factItem.id = newVal.id
			factItem.type = newVal.type
			factItem.label = newVal.label
			factItem.value = newVal.value
		}

		function handleClickConfirm() {
			if (state.value.matches("creating")) {
				send("CONFIRM_CREATE")
			} else if (state.value.matches("updating")) {
				send("CONFIRM_UPDATE")
			}
		}

		function parseScalarValue(valueObj) {
			let type
			let value
			factTypeOptions.value.forEach((fo) => {
				if (valueObj && valueObj[fo.value] !== null && valueObj[fo.value] !== undefined) {
					type = fo.value
					value = {}
					value[type] = valueObj[fo.value]

					if (type === "arrayValue") {
						value[type] = valueObj[fo.value]?.map((vo) => {
							return parseScalarValue(vo).value
						})
					}
				}
			})
			return {
				type,
				value,
			}
		}

		function getValue(fact) {
			if (fact?.type === "arrayValue") {
				fact.isArrayValue = true
				const values = fact?.value?.arrayValue?.map((valueObj) => valueObj?.stringValue || "-") || []
				return values
			}
			return fact?.value?.[fact?.type]
		}

		const options = {
			services: {
				async addFact() {
					const variables = {
						...idObject.value,
						input: {
							type: factItem.label,
							value: factItem.value,
						},
					}
					return await apiPromise(addFactFunction.value, {
						variables,
						fetcher,
					})
				},
				async updateFact() {
					const variables = {
						...idObject.value,
						factId: factItem.id,
						input: {
							id: factItem.id,
							type: factItem.label,
							value: factItem.value,
						},
					}
					return await apiPromise(updateFactFunction.value, {
						variables,
						fetcher,
					})
				},
				async deleteFact() {
					const variables = {
						...idObject.value,
						factId: deleteFactId.value,
						id: deleteFactId.value,
					}
					return await apiPromise(deleteFactFunction.value, {
						variables,
						fetcher,
					})
				},
			},
			actions: {
				clearForm() {
					setFactItem({
						id: "",
						type: "",
						label: "",
						value: null,
					})
				},
				setErrorMessage(ctx, event) {
					handleErrorForUser({ error: event?.data, $t: t })
				},
				emitChanged() {
					emit("fact:changed")
				},
				setDeleteSuccessMessage() {
					store.dispatch("addToastMessage", {
						type: "success",
						content: {
							type: "message",
							text: t("core.deleteFactSuccess"),
						},
					})
				},
			},
			guards: {
				createGuard() {
					v.value.$touch()
					return !v.value.$invalid
				},
				updateGuard() {
					v.value.$touch()
					return !v.value.$invalid
				},
			},
		}

		function initialOptions() {
			factTypeOptions.value = [
				{ name: t("core.text"), value: "stringValue" },
				{ name: t("core.number"), value: "numberValue" },
				{ name: t("core.boolean"), value: "booleanValue" },
				{ name: t("core.date"), value: "dateValue" },
				{ name: t("core.list"), value: "arrayValue" },
			]
		}

		onMounted(() => {
			initialOptions()
		})

		const { state, send } = useMachine(Machine(factCardMachine, options), { devTools: process.env.NODE_ENV === "development" })

		return {
			state,
			send,
			v,
			factItem,
			factTypeOptions,
			// Computed
			fn,
			formatedFacts,
			componentName,
			addFactFunction,
			updateFactFunction,
			deleteFactFunction,
			idObject,
			// Methods
			formatData,
			handleChangeType,
			handleClickAddFact,
			handleClickEditFact,
			handleDeleteFact,
			handleCloseModal,
			handleAddArrayValue,
			handleRemoveArrayValue,
			handleClickConfirm,
			getValue,
			...toRefs(factItem),
		}
	},
	computed: {
		isModalOpen() {
			return this.state.matches("creating") || this.state.matches("updating")
		},
		isFactLoading() {
			return this.state.matches("creating.fetching") || this.state.matches("updating.fetching")
		},
		isUpdating() {
			return this.state.matches("updating")
		},
	},
}
</script>
