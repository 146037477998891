<template>
	<JFlex class="flex-col justify-between h-auto min-h-full pb-8">
		<JFlex class="flex-col space-y-6 pb-10">
			<!-- Header -->
			<JFlex class="relative flex-row">
				<CTabs
					:isVisibleMenuBar="false"
					:disabledTabs="isReadingData"
					class="mt-4 md:mt-8 text-sm"
					customClassTabItems="uppercase font-bold"
					customColorActiveTab="text-c0-800"
					:options="tabOptions"
					:activeTab="activeTab"
					@change="handleTabChange($event)"
				/>
				<JBox class="absolute" style="top: 1rem; right: -5rem">
					<JButton @click="$emit('closeModalContractDetail')" iconPrefix="X" variant="tertiary"></JButton>
				</JBox>
			</JFlex>
			<!-- Components -->
			<component :is="componentToDisplay" :contract="contractData" :isReadingData="isReadingData" />
		</JFlex>
		<!-- Close btn -->
		<JFlex class="justify-center pointer-events">
			<JButton @click="$emit('closeModalContractDetail')" variant="primary" style="padding-left: 10px; padding-right: 10px">{{
				$t("dashboard.closeBtn")
			}}</JButton>
		</JFlex>
	</JFlex>
</template>

<script>
import { ref, onMounted, computed } from "vue"
import { useI18n } from "vue-i18n"
import ContractorDetail from "../../components/contracts/ContractorDetail"
import ContractDetail from "../../components/contracts/ContractDetail"
import ContractClaim from "../../components/contracts/ContractClaim"
import useApis from "../../use/useApis"
import validateToAccessPage from "@/modules/pages/use/usePermissionToAccessPage"

export default {
	name: "ViewManagementPolicyDetail",
	components: {
		ContractorDetail,
		ContractDetail,
		ContractClaim,
	},
	props: {
		policyNo: {
			type: String,
			default: "",
		},
		customActiveTab: {
			type: String,
			default: "ContractorDetail",
		},
	},
	setup(props, { emit }) {
		const { t } = useI18n()
		const { goToHomePageIfUserDoesNotHavePermission, userDoesNotHavePermissionAndRole } = validateToAccessPage()

		const isReadingData = ref(false)
		const contractId = computed(() => props?.policyNo)
		const contractData = ref({})
		const componentToDisplay = ref(props.customActiveTab)
		const activeTab = ref(props.customActiveTab)
		const tabOptions = [
			{
				name: "ContractorDetail",
				label: t("dashboard.personalInfo"),
				component: "ContractorDetail",
			},
			{
				name: "ContractDetail",
				label: t("dashboard.contract"),
				component: "ContractDetail",
			},
			{
				name: "ContractClaim",
				label: t("dashboard.compensate"),
				component: "ContractClaim",
			},
		]

		const { readInfoContract } = useApis()

		function handleTabChange(val) {
			componentToDisplay.value = val?.component
			activeTab.value = val?.name
		}

		async function getContractInfo() {
			try {
				isReadingData.value = true
				const data = await readInfoContract({ policyNo: contractId.value })
				contractData.value = data?.value?.data
			} catch (e) {
				console.error(e)
			} finally {
				isReadingData.value = false
			}
		}

		onMounted(async () => {
			goToHomePageIfUserDoesNotHavePermission()
			if (!userDoesNotHavePermissionAndRole.value) {
				await getContractInfo()
			}
		})

		return {
			tabOptions,
			activeTab,
			componentToDisplay,
			handleTabChange,
			contractData,
			isReadingData,
		}
	},
}
</script>
