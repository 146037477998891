const ViewRBO = () => import(/* webpackChunkName: "group-rbo" */ "../views/ViewRBO")
const ViewResumeRBO = () => import(/* webpackChunkName: "group-rbo" */ "../views/ViewResumeRBO")

export default [
	{
		path: "/rbo/:caseId",
		component: ViewRBO,
		name: "ViewRBO",
		props: true,
		meta: { module: "rbo", component: "ViewRBO", roles: ["default"] },
	},
	{
		path: "/rbo/resume/:caseId",
		component: ViewResumeRBO,
		name: "ViewResumeRBO",
		props: true,
		meta: { module: "case", component: "ViewResumeRBO", roles: ["default"] },
	},
]
