import { Machine } from "xstate"
import { useMachine } from "@/modules/core/composables/useMachine"
import { stepMachine } from "./../machine/stepMachine"
import { useStore } from "vuex"
import { uniq } from "lodash"
import { computed, watch } from "vue"
import getEnv from "@/utils/getEnv"
import {
	apiPromise,
	apiUpdateIndividual,
	apiUpdateFactOfCase,
	apiSendNotification,
	apiUpdateCase,
	apiCaseFactBatch,
} from "@covergo/cover-composables"
import { formatMoney } from "../helpers"
import { handleErrorForUser } from "../api/handleErrorForUser"
import { useI18n } from "vue-i18n"
import { fetcher } from "../api/fetcher"
import { useRoute, useRouter } from "vue-router"
import usePdfTemplate from "../use/usePdf"
import { PRODUCT_NAME } from "@/modules/customerSupport/constants"

export function useSteps() {
	const store = useStore()
	const router = useRouter()
	const route = useRoute()
	const { t } = useI18n()

	const { generateNeedsAnalytics, generateProductDetailPdf } = usePdfTemplate()

	let isUserNavigate = false

	const queryStage = computed(() => route.query.stage)
	const conversationStatus = computed(() => store.getters["fna/caseStatus"])
	const userRole = computed(() => store.getters.user_role)
	const mvpVersionFromFact = computed(() => store.state.fna.mvpVersion)
	const leadGCM = computed(() => store.getters["fna/leadGCM"]?.trim())

	stepMachine.initial = store?.state?.fna?.initialActiveStep || "customerDetails"
	stepMachine.context = {
		completedSteps: store?.state?.fna?.initialCompletedSteps || [],
	}

	/**
	 *
	 * @param {*} factType
	 * Posible values
	 		fna/goalsFactId
			fna/answersFactId
			fna/ratesFactId
			fna/selectedProductFactId
			fna/conversationResultFactId
			fna/leadGCMFactId
	 */
	const saveCaseFact = async (factType) => {
		const factId = store.getters[`fna/${factType}FactId`]
		const updatedFact = store.getters[`fna/${factType}Fact`]
		const caseId = store.getters["fna/caseId"]
		const variables = {
			caseId,
			factId,
			input: updatedFact,
		}
		return await apiPromise(apiUpdateFactOfCase, { variables, fetcher })
	}

	const saveGoals = async () => await saveCaseFact("goals")
	const saveAnswers = async () => await saveCaseFact("answers")
	const saveMeetingMethod = async () => await saveCaseFact("meetingMethod")
	const saveRates = async () => await saveCaseFact("rates")
	const saveProduct = async () => await saveCaseFact("selectedProduct")
	const saveProductCode = async () => await saveCaseFact("productCode")
	const saveConversationResult = async () => await saveCaseFact("conversationResult")
	const saveTisAgentCode = async () => await saveCaseFact("tisAgentCode")
	const saveLeadGCM = async () => await saveCaseFact("leadGCM")
	const saveReferLeadFna = async () => await saveCaseFact("referLeadFna")
	const savePlanCode = async () => await saveCaseFact("planCode")
	const saveOtherDropOffReason = async () => await saveCaseFact("otherDropOffReason")
	const saveMVPVersion = async () => {
		await saveCaseFact("mvpVersion")
	}
	const savePrice = async () => await saveCaseFact("annualPremium")
	const saveCompletedDate = async () => await saveCaseFact("completedDate")
	const completeConversation = async () => {
		const caseId = store.getters["fna/caseId"]
		const variables = {
			caseId,
			input: { status: "COMPLETED" },
		}
		return await apiPromise(apiUpdateCase, { variables, fetcher })
	}
	const saveBaseProductId = async () => await saveCaseFact("baseProductId")
	const saveEPosLinkCustomerDB = async () => await saveCaseFact("ePosLinkCustomerDB")

	// eslint-disable-next-line no-unused-vars
	const sendEmail = async () => {
		const me = store.getters.me
		const userId = me.associatedUser.id
		const caseData = store.getters["fna/caseData"]
		const selectedGoals = store.getters["fna/selectedGoals"]
		const selectedGoalsData = selectedGoals.map((g) => t(`fna.${g}Goal`))
		const selectedProductDetail = store.getters["fna/selectedProductDetail"]
		const selectedProduct = store.getters["fna/selectedProduct"]
		const conversationResult = store.getters["fna/conversationResult"]
		const conversationId = store.getters["fna/conversationId"]
		const dropOffReason = store.getters["fna/dropOffReason"]
		const otherDropOffReason = store.getters["fna/otherDropOffReason"]
		const leadGCM = store.getters["fna/leadGCM"]

		let needsAnalytics = null
		if (store.state.fna.fnaPdf) {
			needsAnalytics = store.state.fna.fnaPdf
		} else {
			needsAnalytics = await generateNeedsAnalytics()
		}

		const pdfAttachments = [{ fileName: `${t("fna.needsAnalytics")}.pdf`, bytes: needsAnalytics }]
		const subjectConversation = computed(() => {
			return t !== "en-US"
				? `[iTCBLife] ${conversationId} - Kết quả phân tích nhu cầu khách hàng`
				: `[iTCBLife] ${conversationId} - analyzing customer demands`
		})

		if (selectedProduct === PRODUCT_NAME.titan_6years || selectedProduct === PRODUCT_NAME.titan_10years) {
			let productDetailPdf = null
			if (store.state.fna.titanPdf) {
				productDetailPdf = store.state.fna.titanPdf
			} else {
				productDetailPdf = await generateProductDetailPdf()
			}
			pdfAttachments.push({ fileName: `${t("fna.titanProductDetail")}.pdf`, bytes: productDetailPdf })
		}
		const variables = {
			sendNotificationInput: {
				toEntityId: userId,
				emailMessage: {
					from: getEnv("VUE_APP_EMAIL_SENDER"),
					fromName: "iTCBLife",
					to: me?.email,
					subject: subjectConversation.value,
					templateRendering: {
						templateId: getEnv("VUE_APP_RESULT_EMAIL_TEMPLATEID"),
						input: {
							name: "data",
							contentJsonString: JSON.stringify({
								conversationId,
								createdAt: caseData.createdAt,
								status: t("fna.completed"),
								goals: selectedGoalsData.length > 1 ? selectedGoalsData.join(" , ") : selectedGoalsData,
								productName: selectedProductDetail?.name ? t(`fna.${selectedProductDetail.name}ProductName`) : "",
								premium: selectedProductDetail?.premium ? formatMoney(selectedProductDetail.premium) : "",
								conversationResult: conversationResult ? t(`fna.${conversationResult}`) : "",
								dropOffReason: dropOffReason
									? dropOffReason !== "otherDropOffReason"
										? t(`fna.${dropOffReason}`)
										: otherDropOffReason
									: "",
								leadGCM,
							}),
						},
					},
					pdfAttachments,
				},
			},
		}
		await apiPromise(apiSendNotification, { variables, fetcher })
	}

	const initSaveCustomerDetails = async (ctx) => {
		if (userRole.value !== "default") return await Promise.resolve()
		const { customerDetails, customerInfo } = store.state.fna

		return await Promise.all([
			updateIndividual(customerDetails, store),
			updateIndividualFacts({ ...customerDetails, ...customerInfo }, store),
		])
	}

	const initUpdateMVPVersion = async () => {
		await store.dispatch("fna/setMVPVersion", "2.0")
		await saveMVPVersion()
	}

	const disableUpdateFNAInfo = computed(() => {
		return (
			(leadGCM.value &&
				mvpVersionFromFact.value !== "2.0" &&
				(conversationStatus.value === "COMPLETED" || conversationStatus.value === "IN PROGRESS")) ||
			conversationStatus.value === "COMPLETED" ||
			userRole.value !== "default"
		)
	})

	function setDefaultValueAnswer() {
		const answers = {
			childrenEducation: [
				{
					age: 1,
					ageToUseFund: 18,
					studyTime: 4,
					expectedEducationFee: 125000000,
				},
			],
			growWealthAndAssets: {
				goal: null,
				bigPurchases: [],
				growWealth: {
					targetSaving: 1000000000,
					expectedTimeToReachTargetSaving: 10,
				},
			},
			financeUncertainty: {
				monthlyIncomeNeeded: 6500000,
				currentLoan: 0,
				protectionTime: 10,
			},
			healthAndMedical: {
				interestedBenefits: ["accidentAndDisability", "medicalCare", "CI", "healthCare"],
				hospitalisedRoomAndBoard: 500000, // follow the data base of Medical cash on file constant
				roomAndBoardExpense: {
					inpatient: 125000000, // follow the data base of Medical cash on file useHealthcareOption
					outpatient: 0,
					dental: 0,
				},
				healthCareOutpatientBenefit: null,
				accidentAndDisabilityCoverAmount: 125000000,
				critticalIllnessCoverAmount: 250000000,
			},
			retirement: {
				retirementAge: 60,
				expectedMonthlyExpenseAfterRetirement: 5000000,
				howLongRetirement: 10,
			},
			having: {
				householdIncome: 0,
				householdIncomeDetails: {
					investmentBusinessIncome: 0,
					salaryBonusIncome: 35000000,
					rentalIncome: 0,
					bankInterestIncome: 0,
					dividendIncome: 0,
					otherIncome: 0,
				},
				householdExpense: 0,
				householdExpenseDetails: {
					livingExpense: 15000000,
					educationExpense: 0,
					contingencyBudget: 0,
					entertainmentExpense: 0,
					insuranceExpense: 0,
					liabilityExpense: 0,
					otherExpense: 0,
				},
				yourAccumulatedSaving: 0,
				currentProtectionCoverage: 0,
				totalNetSaving: 0, // = householdIncome - householdExpense
			},
		}
		const { householdIncomeDetails, householdExpenseDetails } = answers.having
		answers.having.householdIncome = Object.values(householdIncomeDetails || [])?.reduce((x, y) => x + y, 0)
		answers.having.householdExpense = Object.values(householdExpenseDetails || [])?.reduce((x, y) => x + y, 0)
		answers.having.totalNetSaving = answers.having.householdIncome - answers.having.householdExpense
		return answers
	}

	const options = {
		services: {
			async saveCustomerDetails(ctx) {
				await initSaveCustomerDetails(ctx)
				if (mvpVersionFromFact.value !== "2.0") {
					await initUpdateMVPVersion()
				}
			},
			async saveGoals(ctx) {
				if (disableUpdateFNAInfo.value) return await Promise.resolve()
				return await saveGoals()
			},
			async saveSummarizedOptions() {
				if (disableUpdateFNAInfo.value) return await Promise.resolve()
				return Promise.all([saveGoals(), saveAnswers(), saveRates()])
			},
			async saveSelectedProduct() {
				if (disableUpdateFNAInfo.value) return await Promise.resolve()
				return Promise.all([saveProduct(), savePrice()])
			},
			async finishFNA() {
				return await Promise.all([
					saveGoals(),
					saveConversationResult(),
					saveTisAgentCode(),
					saveLeadGCM(),
					saveReferLeadFna(),
					savePlanCode(),
					saveOtherDropOffReason(),
					saveCompletedDate(),
					completeConversation(),
					saveMeetingMethod(),
					sendEmail(),
					saveProductCode(),
					saveBaseProductId(),
				])
			},
			async finishFNAWithRefer() {
				return await Promise.all([
					saveGoals(),
					saveConversationResult(),
					saveTisAgentCode(),
					saveLeadGCM(),
					saveReferLeadFna(),
					savePlanCode(),
					saveOtherDropOffReason(),
					saveCompletedDate(),
					completeConversation(),
					saveMeetingMethod(),
					sendEmail(),
					saveProductCode(),
					saveEPosLinkCustomerDB(),
				])
			},
		},
		actions: {
			updateRoute(context, event, actionMeta) {
				if (!isUserNavigate) return
				const state =
					typeof actionMeta?.state?.value === "string" ? actionMeta?.state?.value : Object.keys(actionMeta?.state?.value)[0]
				const metaData = actionMeta?.state?.meta?.[state]

				router.push({
					path: route.fullPath,
					query: metaData?.query || "",
				})
			},
			onSaveCustomerDetailsCompleted(ctx, event) {
				ctx.completedSteps = uniq([...ctx.completedSteps, "customerDetails"])
			},
			onSaveGoalsCompleted(ctx, event) {
				ctx.completedSsteps = uniq([...ctx.completedSteps, "goals"])
			},
			onSaveSummarizedOptionsCompleted(ctx, event) {
				ctx.completedSteps = uniq([...ctx.completedSteps, "summary"])
			},
			onSaveSelectedProduct(ctx, event) {
				ctx.completedSteps = uniq([...ctx.completedSteps, "selectProduct"])
			},
			onFinishFNA(ctx, event) {
				ctx.completedSteps = uniq([...ctx.completedSteps, "finish"])
			},
			onSaveStepDataFailed(ctx, event) {
				handleErrorForUser({ error: event?.data, $t: t })
			},
			setDefaultAnswers(ctx) {
				store.dispatch("fna/setOverwriteProductOptions", null)
				ctx.completedSteps = ["customerDetails", "goals", "questions", "having", "summary"]
				const selectedGoals = store.getters["fna/selectedGoals"]
				const defaultAnswers = setDefaultValueAnswer()

				if (!selectedGoals.includes("healthAndMedical")) {
					defaultAnswers.healthAndMedical.interestedBenefits = []
				}

				store.dispatch("fna/setAnswers", defaultAnswers)

				saveGoals()
				saveAnswers()
				saveRates()
			},
		},
	}

	const { state, send } = useMachine(Machine(stepMachine, options), { devTools: process.env.NODE_ENV === "development" })

	const goToState = (state) => {
		isUserNavigate = true
		send(state)
	}

	function handleRouteChanged(currentStage) {
		if (route.name !== "ViewFNA") return
		if (isUserNavigate) {
			isUserNavigate = false
			return
		}

		send(`goTo_${currentStage}`)
	}

	watch(queryStage, handleRouteChanged)

	return {
		state,
		send,
		disableUpdateFNAInfo,
		goToState,
		initSaveCustomerDetails,
		saveAnswers,
		completeConversation,
		saveConversationResult,
		saveCompletedDate,
		saveOtherDropOffReason,
		saveCaseFact,
	}
}

export async function updateIndividual({ gender }, store) {
	const variables = {
		id: store.getters["fna/individualId"],
		input: { gender },
	}
	return apiPromise(apiUpdateIndividual, { variables, fetcher })
}

export async function updateIndividualFacts(
	{
		age,
		numberOfDependents,
		fullName,
		citizenIdType,
		citizenId,
		phoneNumber,
		email,
		gender,
		dateOfBirth,
		maritalStatus,
		spouseName,
		spouseAge,
		dependentList,
		staffId,
		branchCode,
		indirectReferrer,
		confirmTimeline,
		bankStaffName,
		idIssuedDate,
		idIssuedPlace,
		occupation,
		address,
		smokingHabit,
		uid,
		relatedAccountId,
		leadId,
		dealCloser,
		directReferralAgentCode,
		isAgentPO,
		customerConsentType,
	},
	store
) {
	const individualFactsByType = store.getters["fna/individualFactsByType"]
	const uidVariables = {
		id: individualFactsByType?.uid?.id,
		type: "uid",
		value: {
			stringValue: uid,
		},
	}

	const ageVariables = {
		id: individualFactsByType?.age?.id,
		type: "age",
		value: {
			numberValue: age,
		},
	}
	const numOfDependentsVariables = {
		id: individualFactsByType?.numberOfDependents?.id,
		type: "numberOfDependents",
		value: {
			numberValue: numberOfDependents,
		},
	}

	// customerInfo
	const fullNameVariables = {
		id: individualFactsByType?.fullName?.id,
		type: "fullName",
		value: {
			stringValue: fullName,
		},
	}
	const citizenIdTypeVariables = {
		id: individualFactsByType?.citizenIdType?.id,
		type: "citizenIdType",
		value: {
			stringValue: citizenIdType,
		},
	}
	const citizenIdVariables = {
		id: individualFactsByType?.citizenId?.id,
		type: "citizenId",
		value: {
			stringValue: citizenId,
		},
	}
	const phoneNumberVariables = {
		id: individualFactsByType?.phoneNumber?.id,
		type: "phoneNumber",
		value: {
			stringValue: phoneNumber,
		},
	}
	const emailVariables = {
		id: individualFactsByType?.email?.id,
		type: "email",
		value: {
			stringValue: email,
		},
	}
	const genderVariables = {
		id: individualFactsByType?.gender?.id,
		type: "gender",
		value: {
			stringValue: gender,
		},
	}
	const dateOfBirthVariables = {
		id: individualFactsByType?.dateOfBirth?.id,
		type: "dateOfBirth",
		value: {
			stringValue: dateOfBirth,
		},
	}
	const maritalStatusVariables = {
		id: individualFactsByType?.maritalStatus?.id,
		type: "maritalStatus",
		value: {
			stringValue: maritalStatus,
		},
	}
	const spouseNameVariables = {
		id: individualFactsByType?.spouseName?.id,
		type: "spouseName",
		value: {
			stringValue: spouseName,
		},
	}
	const spouseAgeVariables = {
		id: individualFactsByType?.spouseAge?.id,
		type: "spouseAge",
		value: {
			numberValue: spouseAge,
		},
	}
	const dependentListValues = JSON.parse(JSON.stringify(dependentList)).map((item) => {
		return {
			objectValue: [
				{
					key: "name",
					value: { stringValue: item.name },
				},
				{
					key: "age",
					value: { numberValue: item.age },
				},
			],
		}
	})
	const dependentListVariables = {
		id: individualFactsByType?.dependentList?.id,
		type: "dependentList",
		value: {
			arrayValue: dependentListValues,
		},
	}
	const staffIdVariables = {
		id: individualFactsByType?.staffId?.id,
		type: "staffId",
		value: {
			stringValue: staffId,
		},
	}
	const branchCodeVariables = {
		id: individualFactsByType?.branchCode?.id,
		type: "branchCode",
		value: {
			stringValue: branchCode,
		},
	}
	const indirectReferrerVariables = {
		id: individualFactsByType?.indirectReferrer?.id,
		type: "indirectReferrer",
		value: {
			stringValue: indirectReferrer,
		},
	}
	const confirmTimelineVariables = {
		id: individualFactsByType?.confirmTimeline?.id,
		type: "confirmTimeline",
		value: {
			stringValue: confirmTimeline,
		},
	}
	const bankStaffNameVariables = {
		id: individualFactsByType?.bankStaffName?.id,
		type: "bankStaffName",
		value: {
			stringValue: bankStaffName,
		},
	}
	const dealCloserVariables = {
		id: individualFactsByType?.dealCloser?.id,
		type: "dealCloser",
		value: {
			stringValue: dealCloser,
		},
	}
	const idIssuedDateVariable = {
		id: individualFactsByType?.idIssuedDate?.id,
		type: "idIssuedDate",
		value: {
			stringValue: idIssuedDate,
		},
	}
	const idIssuedPlaceVariable = {
		id: individualFactsByType?.idIssuedPlace?.id,
		type: "idIssuedPlace",
		value: {
			stringValue: idIssuedPlace,
		},
	}
	const occupationVariable = {
		id: individualFactsByType?.occupation?.id,
		type: "occupation",
		value: {
			stringValue: occupation,
		},
	}

	const addressVariable = {
		id: individualFactsByType?.address?.id,
		type: "address",
		value: {
			objectValue: [
				{ key: "street", value: { stringValue: address?.street || null } },
				{ key: "city", value: { stringValue: address?.city || null } },
				{ key: "district", value: { stringValue: address?.district || null } },
				{ key: "ward", value: { stringValue: address?.ward || null } },
				{ key: "normalizedAddress", value: { stringValue: address?.normalizedAddress || null } },
			],
		},
	}
	const smokingHabitVariable = {
		id: individualFactsByType?.smokingHabit?.id,
		type: "smokingHabit",
		value: {
			stringValue: smokingHabit,
		},
	}
	const relatedAccountIdVariables = {
		id: individualFactsByType?.relatedAccountId?.id,
		type: "relatedAccountId",
		value: {
			stringValue: relatedAccountId,
		},
	}
	const leadIdVariables = {
		id: individualFactsByType?.leadId?.id,
		type: "leadId",
		value: {
			stringValue: leadId,
		},
	}
	const directReferralAgentCodeVariables = {
		id: individualFactsByType?.directReferralAgentCode?.id,
		type: "directReferralAgentCode",
		value: {
			stringValue: directReferralAgentCode,
		},
	}
	const customerConsentTypeVariables = {
		id: individualFactsByType?.customerConsentType?.id,
		type: "customerConsentType",
		value: {
			stringValue: customerConsentType,
		},
	}
	const isAgentPOVariables = {
		id: individualFactsByType?.isAgentPO?.id,
		type: "isAgentPO",
		value: {
			booleanValue: isAgentPO,
		},
	}
	const query = `mutation entityFactBatch($id: String!, $input: factBatchInput!) {
		entityFactBatch(entityId: $id, input:$input){
			status
			errors
		}
	}
	`

	const variables = {
		id: store.getters["fna/individualId"],
		input: {
			updateFactInputs: [
				uidVariables,
				relatedAccountIdVariables,
				ageVariables,
				numOfDependentsVariables,
				fullNameVariables,
				citizenIdTypeVariables,
				citizenIdVariables,
				phoneNumberVariables,
				emailVariables,
				genderVariables,
				dateOfBirthVariables,
				maritalStatusVariables,
				spouseNameVariables,
				spouseAgeVariables,
				dependentListVariables,
				staffIdVariables,
				branchCodeVariables,
				indirectReferrerVariables,
				confirmTimelineVariables,
				bankStaffNameVariables,
				idIssuedDateVariable,
				idIssuedPlaceVariable,
				occupationVariable,
				addressVariable,
				smokingHabitVariable,
				leadIdVariables,
				dealCloserVariables,
				directReferralAgentCodeVariables,
				isAgentPOVariables,
				customerConsentTypeVariables,
			],
		},
	}

	return fetcher({
		query,
		variables,
	})
}

// add additional empty facts to case holder if we access case of mvp 1.5 from mvp 2.0
export async function addIndividualFacts(store) {
	const uidVariables = {
		type: "uid",
		value: {
			stringValue: null,
		},
	}
	const fullNameVariables = {
		type: "fullName",
		value: {
			stringValue: null,
		},
	}
	const citizenIdTypeVariables = {
		type: "citizenIdType",
		value: {
			numberValue: null,
		},
	}
	const citizenIdVariables = {
		type: "citizenId",
		value: {
			stringValue: null,
		},
	}
	const phoneNumberVariables = {
		type: "phoneNumber",
		value: {
			stringValue: null,
		},
	}
	const emailVariables = {
		type: "email",
		value: {
			stringValue: null,
		},
	}
	const genderVariables = {
		type: "gender",
		value: {
			stringValue: null,
		},
	}
	const dateOfBirthVariables = {
		type: "dateOfBirth",
		value: {
			stringValue: null,
		},
	}
	const maritalStatusVariables = {
		type: "maritalStatus",
		value: {
			stringValue: null,
		},
	}
	const spouseNameVariables = {
		type: "spouseName",
		value: {
			stringValue: null,
		},
	}
	const spouseAgeVariables = {
		type: "spouseAge",
		value: {
			numberValue: null,
		},
	}
	const dependentListVariables = {
		type: "dependentList",
		value: {
			arrayValue: null,
		},
	}
	const staffIdVariables = {
		type: "staffId",
		value: {
			stringValue: null,
		},
	}
	const bankStaffNameVariables = {
		type: "bankStaffName",
		value: {
			stringValue: null,
		},
	}
	const idIssuedDateVariable = {
		type: "idIssuedDate",
		value: {
			stringValue: null,
		},
	}
	const idIssuedPlaceVariable = {
		type: "idIssuedPlace",
		value: {
			stringValue: null,
		},
	}
	const occupationVariable = {
		type: "occupation",
		value: {
			stringValue: null,
		},
	}
	const addressVariable = {
		type: "address",
		value: {
			objectValue: null,
		},
	}
	const smokingHabitVariable = {
		type: "smokingHabit",
		value: {
			stringValue: null,
		},
	}
	const relatedAccountIdVariables = {
		type: "relatedAccountId",
		value: {
			stringValue: null,
		},
	}
	const leadIdVariables = {
		type: "leadId",
		value: {
			stringValue: null,
		},
	}
	const isAgentPOVariables = {
		type: "isAgentPO",
		value: {
			booleanValue: null,
		},
	}
	const query = `mutation entityFactBatch($id: String!, $input: factBatchInput!) {
		entityFactBatch(entityId: $id, input:$input){
			status
			errors
		}
	}
	`

	const { holder } = store.state.fna.caseData
	const variables = {
		id: holder.id,
		input: {
			addFactInputs: [
				uidVariables,
				relatedAccountIdVariables,
				fullNameVariables,
				citizenIdTypeVariables,
				citizenIdVariables,
				phoneNumberVariables,
				emailVariables,
				genderVariables,
				dateOfBirthVariables,
				maritalStatusVariables,
				spouseNameVariables,
				spouseAgeVariables,
				dependentListVariables,
				staffIdVariables,
				bankStaffNameVariables,
				idIssuedDateVariable,
				idIssuedPlaceVariable,
				occupationVariable,
				addressVariable,
				smokingHabitVariable,
				leadIdVariables,
				isAgentPOVariables,
			],
		},
	}

	return fetcher({
		query,
		variables,
	})
}

// add missing case facts for case 1.5
// for mvpVersion fact:
// mark default mvp version as 1.5 for case if we access case of mvp 1.5 from mvp 2.0
// later if we finish step 1 and click Next, mvp 1.5 will be converted to mvp 2.0
export async function addAdditionalCaseFacts(store) {
	const { id: caseId } = store?.state?.fna?.caseData

	const mvpVersionVariables = { type: "mvpVersion", value: { stringValue: "1.5" } }
	const tisAgentCodeVariables = { type: "tisAgentCode", value: { stringValue: null } }
	const referLeadFnaVariables = { type: "referLeadFna", value: { stringValue: "" } }
	const planCodeVariables = { type: "planCode", value: { stringValue: null } }
	const otherDropOffReasonFactVariables = {
		type: "otherDropOffReason",
		value: {
			stringValue: null,
		},
	}

	const variables = {
		caseId,
		input: {
			addFactInputs: [
				mvpVersionVariables,
				tisAgentCodeVariables,
				referLeadFnaVariables,
				planCodeVariables,
				otherDropOffReasonFactVariables,
			],
		},
	}

	return apiPromise(apiCaseFactBatch, { variables, fetcher })
}
