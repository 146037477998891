import { computed, onMounted, ref, watch } from "vue"
import { fetcher } from "./../api/fetcher"
import { gql, apiPromise, apiIndividuals, apiCases } from "@covergo/cover-composables"
import { useI18n } from "vue-i18n"

import { useStore } from "vuex"
import staffTypes from "../../../mock/lead.json"
import { crunchDate, buildDate } from "@/modules/core/composables"
import groupBy from "lodash/groupBy"
import chunk from "lodash/chunk"
import concat from "lodash/concat"
import dayjs from "dayjs"
import isBetwwen from "dayjs/plugin/isBetween"
import validateToAccessPage from "@/modules/pages/use/usePermissionToAccessPage"
import { fetchOriginalBranches } from "@/modules/setting/use/appConfigDynamicTemplates"
import getLocationByBranches from "@/modules/core/use/useLocationByCompanyBranches"
import useLoading from "@/composables/loading"

dayjs.extend(isBetwwen)

export function useDashboard() {
	// initialize hook
	const { t, locale } = useI18n()

	const store = useStore()
	const { goToHomePageIfUserDoesNotHavePermission } = validateToAccessPage()
	const { loaders, addLoader } = useLoading()
	addLoader("pageLoader")
	// Initial data

	const indicator = ref("conversation")
	const sumBy = ref("")
	const groupedBy = ref("")
	const isShowChart = ref(false)
	const isLoading = ref(false)
	const originalLocationList = ref([])

	const chartBaseOptions = computed(() => ({
		chart: {
			type: "line",
			stacked: false,
			fontFamily: "Noto Sans, sans-serif",
			toolbar: {
				show: true,
				offsetX: -50,
				offsetY: -10,
				tools: {
					download: locale.value === "en-US",
					selection: true,
					zoom: true,
					zoomin: true,
					zoomout: true,
					pan: true,
					reset: true,
				},
			},
		},

		grid: {
			padding: {
				right: 20,
				left: 20,
			},
		},

		tooltip: {
			y: {
				formatter: function (value) {
					return value?.toLocaleString()
				},
			},
		},

		legend: {
			show: true,
			horizontalAlign: "left",
			offsetX: 30,
		},
	}))

	const chartOptions = ref({})

	const chartSeries = ref([])

	const filters = ref({
		createdAt: {
			type: "between",
			value: [],
			quickOptionValue: "",
		},
	})

	// user info

	const userRole = computed(() => store.getters.user_role)
	const userLogin = computed(() => store.getters.me)
	const userSource = computed(() => store.getters.me?.associatedUser?.source?.split("-"))
	const userPosition = computed(() => userSource.value?.[userSource.value.length - 1])
	const userArea = computed(() => {
		return userPosition.value === "executive" ? null : userSource.value?.[0]
	})
	const userRegion = computed(() => {
		return userPosition.value === "executive" || userPosition.value === "areaManager" ? null : userSource.value?.[1]
	})

	const userBranch = computed(() => {
		return userPosition.value === "executive" || ["regionalManager", "areaManager"].includes(userPosition.value)
			? null
			: userSource.value?.[2]
	})

	const chartType = computed(() => {
		if (
			indicator.value === "conversation" &&
			groupedBy.value === "total" &&
			(userRole.value === "default" ||
				(userPosition.value === "executive" && sumBy.value === "ho") ||
				(userPosition.value === "branchManager" && sumBy.value === "branch") ||
				(userPosition.value === "regionalManager" && sumBy.value === "region") ||
				(userPosition.value === "areaManager" && sumBy.value === "area") ||
				(userPosition.value === "RBO.SRBO" && sumBy.value === "lead") ||
				(userPosition.value === "TIS.PRM" && sumBy.value === "lead"))
		) {
			return "pie"
		}
		return "line"
	})

	const quickOptions = computed(() => [
		{ label: t("core.today"), value: "today" },
		{ label: t("core.lastWeek"), value: "lastWeek" },
		{ label: t("core.lastMonth"), value: "lastMonth" },
		{ label: t("core.lastThreeMonths"), value: "last3Months" },
	])

	const dateTypeOptions = computed(() => [{ name: t("core.between"), value: "between" }])

	const indicatorData = computed(() => {
		const options = [
			{
				name: t("dashboard.conversation"),
				value: "conversation",
			},
			{
				name: t("dashboard.leadGCM"),
				value: "leadGCM",
			},
			{
				name: t("dashboard.APE"),
				value: "APE",
			},
		]

		if (userRole.value !== "default") {
			options.push({
				name: t("dashboard.activeUser"),
				value: "user",
			})
		}
		return options
	})

	const groupByData = computed(() => {
		return [
			{
				name: t("dashboard.total"),
				value: "total",
			},
			{
				name: t("dashboard.month"),
				value: "month",
			},
			{
				name: t("dashboard.week"),
				value: "week",
			},
		]
	})

	const sumByData = computed(() => {
		let sumByOpts = []

		if (userPosition.value === "executive")
			sumByOpts = [
				{
					name: t("dashboard.ho"),
					value: "ho",
				},
				{
					name: t("dashboard.area"),
					value: "area",
				},
				{
					name: t("dashboard.region"),
					value: "region",
				},
				{
					name: t("dashboard.branch"),
					value: "branch",
				},
				{
					name: t("dashboard.staffType"),
					value: "staffType",
				},
				{
					name: t("dashboard.staff"),
					value: "staff",
				},
			]
		switch (userPosition.value) {
			case "branchManager":
				sumByOpts = [
					{
						name: t("dashboard.branch"),
						value: "branch",
					},
					{
						name: t("dashboard.staffType"),
						value: "staffType",
					},
					{
						name: t("dashboard.staff"),
						value: "staff",
					},
				]
				break
			case "regionalManager":
				sumByOpts = [
					{
						name: t("dashboard.region"),
						value: "region",
					},
					{
						name: t("dashboard.branch"),
						value: "branch",
					},
					{
						name: t("dashboard.staffType"),
						value: "staffType",
					},
					{
						name: t("dashboard.staff"),
						value: "staff",
					},
				]
				break
			case "areaManager":
				sumByOpts = [
					{
						name: t("dashboard.area"),
						value: "area",
					},
					{
						name: t("dashboard.region"),
						value: "region",
					},
					{
						name: t("dashboard.branch"),
						value: "branch",
					},
					{
						name: t("dashboard.staffType"),
						value: "staffType",
					},
					{
						name: t("dashboard.staff"),
						value: "staff",
					},
				]
				break
			case "RBO.SRBO":
			case "TIS.PRM":
				sumByOpts = [
					{
						name: t("dashboard.lead"),
						value: "lead",
					},
					{
						name: t("dashboard.staffType"),
						value: "staffType",
					},
					{
						name: t("dashboard.staff"),
						value: "staff",
					},
				]
				break
		}
		if (indicator.value === "user") return sumByOpts.filter((item) => item.value !== "staff")
		return sumByOpts
	})

	const staffTypeData = computed(() => {
		if (userPosition.value === "RBO.SRBO")
			return [
				{
					name: "All",
					value: "",
				},
				{
					name: "SRBO",
					value: "SRBO",
				},
				{
					name: "RBO",
					value: "RBO",
				},
			]

		if (userPosition.value === "TIS.PRM")
			return [
				{
					name: "All",
					value: "",
				},
				{
					name: "TIS",
					value: "TIS",
				},
				{
					name: "PRM",
					value: "PRM",
				},
			]
		else {
			return [
				{
					name: "All",
					value: "",
				},
				...staffTypes,
			]
		}
	})

	const disableBranchSelect = computed(() => enableFilters.value.region && !filters.value?.region && enableFilters.value.branch)

	const areaOptions = computed(() => {
		const defaultOptions = sumBy.value === "staffType" ? [{ name: "All", value: "all" }] : []
		const defaultAreas = originalLocationList.value?.areas || []
		return userArea.value ? defaultAreas?.filter((item) => item.value === userArea.value) : [...defaultOptions, ...defaultAreas]
	})

	const regionOptions = computed(() => {
		const defaultOptions = sumBy.value === "staffType" ? [{ name: "All", value: "all" }] : []
		let filteredRegions
		const defaultRegions = originalLocationList.value?.regions || []

		if (filters.value?.area) {
			if (filters.value?.area === "all") filteredRegions = [...defaultOptions, ...defaultRegions]
			else {
				const regionSelectedByArea = defaultRegions?.length
					? defaultRegions?.filter((item) => item.area === filters.value?.area)
					: []
				filteredRegions = [...defaultOptions, ...regionSelectedByArea]
			}
		} else if (userRegion.value) {
			filteredRegions = defaultRegions?.filter((item) => item.value === userRegion.value)
		} else if (userArea.value) {
			const regionByArea = defaultRegions?.length ? defaultRegions?.filter((item) => item.area === userArea.value) : []
			filteredRegions = [...defaultOptions, ...regionByArea]
		} else {
			filteredRegions = []
		}

		return filteredRegions
	})

	const branchOptions = computed(() => {
		const defaultOptions = sumBy.value === "staffType" ? [{ name: "All", value: "all" }] : []
		let filteredBranches
		const defaultBranches = originalLocationList.value?.branches || []
		if (filters.value?.region) {
			if (filters.value?.region === "all") filteredBranches = [...defaultOptions, ...defaultBranches]
			else {
				const mBranches = defaultBranches?.length ? defaultBranches.filter((item) => item.region === filters.value?.region) : []
				filteredBranches = [...defaultOptions, ...mBranches]
			}
		} else if (userBranch.value) {
			filteredBranches = defaultBranches?.filter((item) => item.value === userBranch.value)
		} else if (userRegion.value) {
			const branchesByUserLocation = defaultBranches?.length
				? defaultBranches.filter((item) => item.region === userRegion.value)
				: []
			filteredBranches = [...defaultOptions, ...branchesByUserLocation]
		} else {
			filteredBranches = []
		}
		return filteredBranches.map((branch) => ({
			...branch,
			name: locale.value === "en-US" ? branch?.branchNameEn : branch.branchNameVn,
		}))
	})

	const enableFilters = computed(() => {
		const defaultOpts = {
			area: false,
			region: false,
			branch: false,
			staffType: false,
		}

		if (userRole.value === "default") {
			return defaultOpts
		}
		if (userPosition.value === "RBO.SRBO" || userPosition.value === "TIS.PRM") {
			if (sumBy.value === "lead" || sumBy.value === "staffType" || sumBy.value === "") {
				return {}
			}
			return { staffType: true }
		}
		switch (sumBy.value) {
			case "staffType":
				if (userPosition.value === "branchManager") return {}
				if (userPosition.value === "regionalManager") return { branch: true }
				if (userPosition.value === "areaManager") return { region: true, branch: true }

				return { area: true, region: true, branch: true }

			case "staff":
				if (userPosition.value === "branchManager") return { staffType: true }
				if (userPosition.value === "regionalManager") return { branch: true, staffType: true }
				if (userPosition.value === "areaManager") return { staffType: true, region: true, branch: true }
				return { staffType: true, region: true, branch: true, area: true }

			case "branch":
				if (userPosition.value === "branchManager") return { staffType: true }
				if (userPosition.value === "regionalManager") return { staffType: true }
				if (userPosition.value === "areaManager") return { region: true, staffType: true }
				return { staffType: true, region: true, area: true }

			case "region":
				if (userPosition.value === "regionalManager") return { staffType: true }
				if (userPosition.value === "areaManager") return { staffType: true }
				return { staffType: true, area: true }

			case "area":
				if (userPosition.value === "executive") return { staffType: true }
				return {}
			case "month":
			case "week":
				if (userPosition.value === "branchManager") return { staffType: true }
				if (userPosition.value === "regionalManager") return { staffType: true, branch: true }

				return { staffType: true, region: true, branch: true }

			default:
				return defaultOpts
		}
	})

	const enableSumBy = computed(() => {
		return userRole.value !== "default"
	})

	const enableGroupBy = computed(() => {
		return (
			(userRole.value === "default" ||
				userPosition.value?.includes(sumBy.value) ||
				(["RBO.SRBO", "TIS.PRM"].includes(userPosition.value) && sumBy.value === "lead") ||
				(userPosition.value === "executive" && sumBy.value === "ho")) &&
			indicator.value !== "user"
		)
	})

	const filterDate = computed(() => {
		let dateArr = []

		if (filters.value.createdAt.type !== "between") return []
		const startedDate = dayjs(filters.value.createdAt.value[0])
		const endedDate = dayjs(filters.value.createdAt.value[1])

		const startedMonth = startedDate.set("date", 1)
		const endedMonth = endedDate.set("date", 1)

		const monthDiff = endedMonth.diff(startedMonth, "month")

		switch (groupedBy.value) {
			case "week":
				dateArr = buildDate({ from: startedDate, to: endedDate, t, range: 6 })
				break
			case "month":
				for (let i = 0; i <= monthDiff; i++) {
					dateArr.push(startedMonth.add(i, "month").format("YYYY-M"))
				}
		}
		return dateArr
	})

	const tooltipData = computed(() => {
		if (groupedBy.value !== "week") return []
		return filterDate.value.map(
			(item, index) =>
				`${t("dashboard.weekTooltipMessage", {
					week: index + 1,
					startDate: dayjs(item.startedAt).format("DD/MM"),
					endDate: dayjs(item.endedAt).format("DD/MM"),
				})}`
		)
	})

	const disableViewChartButton = computed(
		() =>
			!indicator.value ||
			(enableSumBy.value && !sumBy.value) ||
			(enableGroupBy.value && !groupedBy.value) ||
			(enableFilters.value.staffType && filters.value?.staffType === null) ||
			(enableFilters.value.area && !filters.value?.area) ||
			(enableFilters.value.region && !filters.value?.region) ||
			(enableFilters.value.branch &&
				(sumBy.value === "staffType" && !enableFilters.value.region ? filters.value?.branch === null : !filters.value?.branch)) ||
			filters.value.createdAt.value?.length === 0 ||
			filters.value.createdAt.value === null
	)

	watch(
		() => enableGroupBy.value,
		() => {
			groupedBy.value = null
		}
	)

	// if change area , reset region and branch
	watch(
		() => filters.value.area,
		() => {
			if (filters.value.area === "all") {
				filters.value.region = "all"
				filters.value.branch = "all"
			} else {
				filters.value.region = null
				filters.value.branch = null
			}
		}
	)

	// if change region , reset branch
	watch(
		() => filters.value.region,
		() => {
			if (filters.value.region === "all") {
				filters.value.branch = "all"
			} else {
				filters.value.branch = null
			}
		}
	)

	watch(
		() => enableFilters.value,
		(val) => {
			filters.value = { createdAt: { ...filters.value.createdAt } }
			Object.keys(val).forEach((key) => {
				if (val[key]) {
					filters.value[key] = key === "staffType" || key === "branch" ? "" : null
				}
			})
		}
	)

	// for any changes in filter => reset chart
	watch(
		() => ({
			indicator: indicator.value,
			sumBy: sumBy.value,
			staffType: filters.value?.staffType,
			area: filters.value?.area,
			region: filters.value?.region,
			branch: filters.value?.branch,
			createdAt: filters.value.createdAt,
			groupedBy: groupedBy.value,
		}),
		() => {
			isShowChart.value = false
		}
	)

	/** METHOD */

	const fetchCases = async () => {
		const variables = {
			where: {
				and: [],
			},
		}

		if (filters.value.area && filters.value.area !== "all") variables.where.and.push({ source_contains: filters.value.area })
		if (filters.value.region && filters.value.region !== "all")
			variables.where.and.push({ source_contains: filters.value.region })
		if (filters.value.branch && filters.value.branch !== "all")
			variables.where.and.push({ source_contains: filters.value.branch })
		if (filters.value.staffType) variables.where.and.push({ source_contains: "-" + filters.value.staffType })
		if (filters.value.createdAt) variables.where.and.push(crunchDate("createdAt", filters.value.createdAt))

		const fragment = gql`
			fragment result on cases {
				totalCount
				list {
					status
					source
					createdAt
					createdBy {
						username
					}
					facts {
						id
						type
						value {
							numberValue
							stringValue
						}
					}
				}
			}
		`

		const res = await apiPromise(apiCases, {
			variables,
			fetcher,
			fragment,
		})

		return res.list || []
	}

	const fetchCaseReport = async ({ startedAt, endedAt }) => {
		const variables = {
			where: {
				and: [],
			},
		}

		const filterTime = {
			type: "between",
			value: [startedAt, endedAt],
		}

		if (filters.value.area && filters.value.area !== "all") variables.where.and.push({ source_contains: filters.value.area })
		if (filters.value.region && filters.value.region !== "all")
			variables.where.and.push({ source_contains: filters.value.region })
		if (filters.value.branch && filters.value.branch !== "all")
			variables.where.and.push({ source_contains: filters.value.branch })
		if (filters.value.staffType) variables.where.and.push({ source_contains: "-" + filters.value.staffType })
		if (filters.value.createdAt) variables.where.and.push(crunchDate("createdAt", filterTime))

		const query = gql`
			query dailyreport($where: caseWhere) {
				casesReport(where: $where) {
					totalCount
					list {
						reportFields
					}
				}
			}
		`

		const res = await fetcher({
			query,
			variables,
		})

		return JSON.parse(res.data?.casesReport?.list[0]?.reportFields || [])
	}

	const fetchCasesByChunks = async () => {
		const startedDate = dayjs(filters.value.createdAt.value[0])
		const endedDate = dayjs(filters.value.createdAt.value[1])
		let allCases = []

		const dateChunks = chunk(buildDate({ from: startedDate, to: endedDate, t, range: 6 }), 6)
		for (const chunk of dateChunks) {
			const chunkCases = await Promise.all(
				chunk.map((item) => fetchCaseReport({ startedAt: item.startedAt, endedAt: item.endedAt }))
			)
			allCases = [...allCases, ...chunkCases]
		}

		return concat(...allCases)
	}

	const convertChartData = async () => {
		let list
		if (sumBy.value === "staff") {
			list = await fetchCases()

			return list.map((item) => {
				const caseSource = item.source?.split("-") || []
				const createdAtMonth = dayjs(item.createdAt).format("YYYY-M")

				return {
					...item,
					area: caseSource[0],
					region: caseSource[1],
					branch: caseSource[2],
					staffType: caseSource[3],
					lead: userLogin.value.username,
					ho: "HO",
					createdAt: {
						date: dayjs(item.createdAt),
						month: createdAtMonth,
					},
				}
			})
		}
		list = await fetchCasesByChunks()
		return list.map((item) => {
			const caseSource = item.Source?.split("-") || []
			const createdAtMonth = dayjs(item.CreatedDate).format("YYYY-M")

			return {
				...item,
				area: caseSource[0],
				region: caseSource[1],
				branch: caseSource[2],
				staffType: caseSource[3],
				lead: userLogin.value.username,
				ho: "HO",
				createdAt: {
					date: dayjs(item.CreatedDate),
					month: createdAtMonth,
				},
			}
		})
	}

	const groupChartData = async (list, groupedBy) => {
		if (["month", "week"].includes(groupedBy)) {
			const data = {}

			filterDate.value.forEach((key) => {
				data[typeof key === "string" ? key : key.name] = []
			})

			switch (groupedBy) {
				case "month":
					list.forEach((item) => {
						if (!data[item.createdAt.month]) data[item.createdAt.month] = []
						data[item.createdAt.month].push(item)
					})
					break
				case "week":
					list.forEach((item) => {
						filterDate.value.every((date) => {
							if (
								item.createdAt.date.diff(date.startedAt, "day") >= 0 &&
								item.createdAt.date.diff(date.endedAt, "day", true) <= 0
							) {
								data[date.name].push(item)
								return false
							}
							return true
						})
					})
			}
			return data
		}

		return { ...(await groupXaxisData()), ...groupBy(list, groupedBy) }
	}

	const groupXaxisData = async () => {
		const xAxisData = {}

		switch (sumBy.value) {
			case "staff":
				// eslint-disable-next-line no-case-declarations
				const res = await fetchIndividuals({})

				res.list
					.filter(
						(item) =>
							item.associatedLogin &&
							item.source &&
							["RBO", "SRBO", "TIS", "PRM"].includes(item.source?.split("-")[item.source?.split("-").length - 1])
					)
					.forEach((item) => {
						xAxisData[item.associatedLogin.username] = []
					})
				break
			case "staffType":
				staffTypeData.value
					.filter((item) => item.value)
					.forEach((item) => {
						xAxisData[item.value] = []
					})
				break

			case "branch":
				branchOptions.value
					.filter((item) => item.value)
					.forEach((item) => {
						xAxisData[item.value] = []
					})
				break

			case "region":
				regionOptions.value
					.filter((item) => item.value)
					.forEach((item) => {
						xAxisData[item.value] = []
					})
				break

			case "area":
				areaOptions.value
					.filter((item) => item.value)
					.forEach((item) => {
						xAxisData[item.value] = []
					})
				break

			case "ho":
				xAxisData.HO = []
				break
			case "lead":
				xAxisData[userLogin.value.username] = []
				break
		}

		if (xAxisData.all) delete xAxisData.all

		return xAxisData
	}

	const fetchTotalIndividuals = async () => {
		const overwriteQuery = `query events($where: individualWhereInput) {
			individuals(where:$where) {
			  totalCount
			}
		  }`
		return await fetchIndividuals({ overwriteQuery })
	}

	const fetchIndividualsWithLoginEvents = async ({ limit, skip }) => {
		const startedDate = dayjs(filters.value.createdAt.value[0]).format("YYYY-MM-DD")
		const endedDate = dayjs(filters.value.createdAt.value[1]).add(1, "day").format("YYYY-MM-DD")

		const overwriteQuery = `query events($where: individualWhereInput, $limit: Int, $skip: Int) {
			individuals(where:$where, limit: $limit, skip: $skip) {
			totalCount
			list {
				source
				associatedLogin {
				id
				username
				lockoutEndDateUtc
				events(types: ["requestAccessTokenSuccess"], fromDate:"${startedDate}", toDate: "${endedDate}") {
					type
					id
					timestamp
				}
				}
			}
			}
	  }`
		const customVariables = { limit, skip }
		return await fetchIndividuals({ overwriteQuery, customVariables })
	}

	const fetchIndividuals = async ({ overwriteQuery, customVariables = {} }) => {
		const query = `query events($where: individualWhereInput) {
				individuals(where:$where) {
					totalCount
					list {
						source
						associatedLogin {
							id
							username

						}
					}
				}
		  }`

		const variables = {
			...customVariables,
			where: {
				and: [],
			},
		}

		if (filters.value.area && filters.value.area !== "all") variables.where.and.push({ source_contains: filters.value.area })
		if (filters.value.region && filters.value.region !== "all")
			variables.where.and.push({ source_contains: filters.value.region })
		if (filters.value.branch && filters.value.branch !== "all")
			variables.where.and.push({ source_contains: filters.value.branch })
		if (filters.value.staffType) variables.where.and.push({ source_contains: "-" + filters.value.staffType })

		variables.where.and.push({ source_contains: "[a-z]" })

		return await apiPromise(apiIndividuals, {
			fetcher,
			queryOverride: overwriteQuery || query,
			variables,
		})
	}

	const fetchIndividualsByChunks = async () => {
		try {
			const { totalCount } = await fetchTotalIndividuals()
			const queryOpts = []

			let individuals = []

			const totalOpts = Math.ceil(totalCount / 400)

			for (let i = 0; i < totalOpts; i++) {
				queryOpts.push({ limit: 400, skip: i * 400 })
			}

			const queryOptChunks = chunk(queryOpts, 6)
			for (const chunk of queryOptChunks) {
				const chunkIndividuals = await Promise.all(chunk.map((item) => fetchIndividualsWithLoginEvents(item)))

				individuals = [...individuals, ...chunkIndividuals]
			}

			return concat(...individuals.map((item) => item.list))
		} catch (err) {
			//
		}
	}

	const fetchCasesWithLoginIdsByChunks = async (users) => {
		try {
			let cases = []

			const chunks = chunk(chunk(users, 500), 6)

			for (const chunk of chunks) {
				const chunkCases = await Promise.all(
					chunk.map((item) =>
						fetchCasesWithLoginIds(item.filter((item) => item.associatedLogin?.id).map((item) => item.associatedLogin.id))
					)
				)

				cases = [...cases, ...chunkCases]
			}

			return concat(...cases)
		} catch (err) {
			//
		}
	}

	const convertUsersChartData = async () => {
		const users = await fetchIndividualsByChunks()
		const cases = await fetchCasesWithLoginIdsByChunks(users)

		return {
			users: users
				.filter(
					(item) =>
						item.source &&
						["RBO", "SRBO", "TIS", "PRM"].includes(item.source?.split("-")[item.source?.split("-").length - 1]) &&
						checkUserUnlockStatus(item.associatedLogin?.lockoutEndDateUtc)
				)
				.map((item) => {
					const caseSource = item.source?.split("-") || []

					return {
						...item,
						id: item?.associatedLogin?.id,
						lead: userLogin.value.username,
						ho: "HO",
						area: caseSource[0],
						region: caseSource[1],
						branch: caseSource[2],
						staffType: caseSource[3],
					}
				}),
			cases,
		}
	}

	const fetchCasesWithLoginIds = async (loginIds) => {
		const variables = {
			where: {
				and: [{ createdById_in: loginIds }],
			},
		}
		if (filters.value.createdAt) variables.where.and.push(crunchDate("createdAt", filters.value.createdAt))
		const query = `
				query cases($where: caseWhere){
					cases(where:$where){
						totalCount
						list {
							createdBy{
								id
							}
							id

						}
					}
				}`
		const res = await apiPromise(apiCases, { variables, fetcher, queryOverride: query })

		return res.list || []
	}

	const groupFNAStatusChart = (data) => {
		return Object.keys(data).map((key) => {
			let completedConversations = 0

			let completedRate = 0
			let totalCoversations = 0
			data[key].forEach((item) => {
				if (sumBy.value !== "staff") {
					totalCoversations += item.NumberOfCases
					completedConversations += item.NumberOfCompletedCases
				} else {
					if (item.status === "COMPLETED") completedConversations++
					totalCoversations++
				}
			})

			completedRate = Math.round((completedConversations / totalCoversations || 0) * 100)
			return {
				completedConversations,
				completedRate,
				totalCount: totalCoversations,
				mapBy: key,
			}
		})
	}

	const groupActiveUserChart = (list, cases, groupedBy) => {
		const data = groupBy(list, groupedBy)

		// currently remove invalid area in FE side
		const areas = originalLocationList.value?.areas?.length ? originalLocationList.value?.areas : []
		const availableArea = areas?.map((item) => item.value)

		let mappedData = Object.keys(data)
		if (groupedBy === "area") {
			mappedData = mappedData.filter((key) => availableArea.includes(key))
		}

		mappedData = mappedData.map((key) => {
			let totalStaff = 0
			let activeLogin = 0
			let activeLoginRate = 0
			let activeSale = 0
			let activeSaleRate = 0

			data[key].forEach((item) => {
				totalStaff++
				if (item.associatedLogin?.events?.length) activeLogin++
				if (cases.find((c) => c.createdBy.id === item.id)) {
					activeSale++
				}
			})

			activeLoginRate = Math.round((activeLogin / totalStaff) * 100) || 0
			activeSaleRate = Math.round((activeSale / totalStaff) * 100) || 0

			return {
				totalStaff,
				activeLogin,
				activeLoginRate,
				activeSale,
				activeSaleRate,
				mapBy: key,
			}
		})

		return mappedData
	}

	const groupLeadGCMChart = (data) => {
		return Object.keys(data).map((key) => {
			let totalConversationWithGCM = 0

			data[key].forEach((item) => {
				if (sumBy.value !== "staff") {
					totalConversationWithGCM += item.LeadGcm
				} else if (item.facts?.find((fact) => fact.type === "leadGCM")?.value?.stringValue !== "     ") {
					totalConversationWithGCM++
				}
			})

			return {
				totalConversationWithGCM,
				mapBy: key,
			}
		})
	}

	const groupAPEChart = (data) => {
		return Object.keys(data).map((key) => {
			let totalAPE = 0
			let totalConversation = 0

			data[key].forEach((item) => {
				if (sumBy.value !== "staff") {
					totalAPE += item.Premium / 1000
					totalConversation += item.TotalConversationAPE
				} else {
					const annualPremium = item.facts?.find((fact) => fact.type === "annualPremium")?.value?.numberValue
					const coversationResult = item.facts?.find((fact) => fact.type === "conversationResult")?.value?.stringValue

					if (annualPremium && (coversationResult === "referToTIS" || coversationResult === "agreeToApply")) {
						totalAPE += annualPremium / 1000
						totalConversation++
					}
				}
			})

			return {
				APE: totalAPE / totalConversation || 0,
				mapBy: key,
			}
		})
	}

	const composeFNAStatusXAxisLabel = (code) => {
		if (["area", "region", "staffType", "ho"].includes(sumBy.value) && !["month", "week"].includes(groupedBy.value)) {
			return t(`user.${sumBy.value}.${code}`)
		}
		return code
	}

	const buildFNAStatusChartOpts = (data) => {
		if (chartType.value === "line") {
			chartSeries.value = [
				{
					name: t("dashboard.started"),
					type: "column",
					stroke: {
						show: false,
					},
					data: mapData(data.map((item) => item.totalCount)),
				},
				{
					name: t("dashboard.completed"),
					type: "column",
					stroke: {
						show: false,
					},
					data: mapData(data.map((item) => item.completedConversations)),
				},
				{
					name: t("dashboard.completedRate"),
					type: "line",
					data: mapData(data.map((item) => item.completedRate)),
				},
			]
			chartOptions.value = {
				...chartBaseOptions.value,
				plotOptions: {
					bar: {
						columnWidth: data.length === 1 ? "20%" : data.length > 5 ? "80%" : "50%",
					},
				},
				xaxis: {
					categories: mapCategories(data.map((item) => composeFNAStatusXAxisLabel(item.mapBy))),
					max: data.length > 25 ? 25 : undefined,
				},

				stroke: {
					width: [0, 0, 2],
				},
				dataLabels: {
					enabled: true,
					enabledOnSeries: [2],
					offsetY: -5,
					offsetX: 2,
					formatter: (value) => (value ? value.toFixed(0) + "%" : ""),
					style: {
						colors: ["#333"],
					},
					background: {
						enabled: false,
					},
				},
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: "#bfbfbf",
						},
						labels: {
							style: {
								colors: "#bfbfbf",
							},
							formatter: (value) => {
								return value?.toLocaleString()
							},
						},
						title: {
							text: t("dashboard.conversation"),
							style: {
								color: "#bfbfbf",
								fontSize: "15px",
							},
						},
						allowDecimals: false,
					},

					{
						seriesName: t("dashboard.started"),
						show: false,
						allowDecimals: false,
					},

					{
						seriesName: t("dashboard.completedRate"),
						allowDecimals: false,
						opposite: true,
						max: 150,
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: "#a5a5a5",
						},
						labels: {
							style: {
								colors: "#a5a5a5",
							},
						},
						title: {
							text: t("dashboard.rates"),
							style: {
								color: "#a5a5a5",
								fontSize: "15px",
							},
						},
					},
				],
				colors: ["#bfbfbf", "#c10000", "#a5a5a5"],
			}
		} else if (chartType.value === "pie") {
			chartSeries.value = [data[0]?.completedConversations, data[0]?.totalCount - data[0]?.completedConversations]

			chartOptions.value = {
				...chartBaseOptions.value,
				chart: {
					type: "pie",
				},
				labels: [t("dashboard.completed"), t("dashboard.incompleted")],
				colors: ["#c10000", "#bfbfbf"],
				legend: {
					position: "bottom",
				},
			}
		}
	}

	const buildActiveUserChartOpts = (data) => {
		chartSeries.value = [
			{
				name: t("dashboard.sales"),
				type: "column",
				stroke: {
					show: false,
				},
				data: mapData(data.map((item) => item.totalStaff)),
			},
			{
				name: t("dashboard.activeLogin"),
				type: "column",
				data: mapData(data.map((item) => item.activeLogin)),
			},
			{
				name: t("dashboard.activeSale"),
				type: "column",
				data: mapData(data.map((item) => item.activeSale)),
			},
			{
				name: t("dashboard.activeLoginRate"),
				type: "line",
				data: mapData(data.map((item) => item.activeLoginRate)),
			},
			{
				name: t("dashboard.activeSaleRate"),
				type: "line",
				data: mapData(data.map((item) => item.activeSaleRate)),
			},
		]
		chartOptions.value = {
			...chartBaseOptions.value,
			plotOptions: {
				bar: {
					columnWidth: data.length === 1 ? "20%" : data.length > 5 ? "80%" : "50%",
				},
			},
			xaxis: {
				categories: mapCategories(data.map((item) => composeFNAStatusXAxisLabel(item.mapBy))),
				max: data.length > 25 ? 25 : undefined,
			},
			stroke: {
				width: [0, 0, 0, 3, 3],
			},
			dataLabels: {
				enabled: true,
				offsetY: -5,
				enabledOnSeries: [3, 4],
				formatter: (value) => (value ? value.toFixed(0) + "%" : ""),
				style: {
					colors: ["#4d73be", "#a5a5a5"],
				},
				background: {
					enabled: false,
				},
			},
			yaxis: [
				{
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: "#bfbfbf",
					},
					labels: {
						style: {
							colors: "#bfbfbf",
						},
						formatter: (value) => {
							return value?.toLocaleString()
						},
					},
					title: {
						text: "User",
						style: {
							color: "#bfbfbf",
							fontSize: "15px",
						},
					},
					allowDecimals: false,
				},

				{
					seriesName: t("dashboard.sales"),
					show: false,
					allowDecimals: false,
				},
				{
					seriesName: t("dashboard.sales"),
					show: false,
					allowDecimals: false,
				},
				{
					seriesName: t("dashboard.activeLoginRate"),
					allowDecimals: false,
					opposite: true,
					axisTicks: {
						show: true,
					},
					axisBorder: {
						show: true,
						color: "#a5a5a5",
					},
					labels: {
						style: {
							colors: "#a5a5a5ƒ",
						},
					},
					title: {
						text: t("dashboard.rates"),
						style: {
							color: "#a5a5a5",
							fontSize: "15px",
						},
					},
				},
				{
					seriesName: t("dashboard.activeLoginRate"),
					show: false,
					allowDecimals: false,
				},
			],
			colors: ["#bfbfbf", "#c10000", "#2222af", "#a5a5a5", "#4d73be"],
		}
	}

	const buildLeadGCMChartOpts = (data) => {
		chartSeries.value = [
			{
				name: t("dashboard.leadGCM"),
				type: "column",
				stroke: {
					show: false,
				},
				data: mapData(data.map((item) => item.totalConversationWithGCM)),
			},
		]
		chartOptions.value = {
			...chartBaseOptions.value,
			plotOptions: {
				bar: {
					columnWidth: data.length === 1 ? "20%" : data.length > 5 ? "80%" : "50%",
				},
			},
			xaxis: {
				categories: mapCategories(data.map((item) => composeFNAStatusXAxisLabel(item.mapBy))),
				max: data.length > 25 ? 25 : undefined,
			},
			stroke: {
				width: [1, 1, 1, 4],
			},
			yaxis: {
				labels: {
					formatter: (value) => {
						return value?.toLocaleString()
					},
				},
				allowDecimals: false,
				title: {
					text: t("dashboard.conversationWithLeadGCM"),
					style: {
						fontSize: "15px",
					},
				},
			},
			colors: ["#bfbfbf"],
		}
	}

	const buildAPEChartOpts = (data) => {
		chartSeries.value = [
			{
				name: t("dashboard.APE"),
				type: "column",
				stroke: {
					show: false,
				},
				data: mapData(data.map((item) => item.APE)),
			},
		]
		chartOptions.value = {
			...chartBaseOptions.value,
			plotOptions: {
				bar: {
					columnWidth: data.length === 1 ? "20%" : data.length > 5 ? "80%" : "50%",
				},
			},

			xaxis: {
				categories: mapCategories(data.map((item) => composeFNAStatusXAxisLabel(item.mapBy))),
				max: data.length > 25 ? 25 : undefined,
			},
			stroke: {
				width: [1, 1, 1, 4],
			},

			yaxis: {
				allowDecimals: false,
				title: {
					text: `APE (${t("dashboard.millionDong")})`,
					style: {
						fontSize: "15px",
					},
				},
				labels: {
					formatter: (value) => {
						return value?.toLocaleString()
					},
				},
			},
			colors: ["#bfbfbf"],
		}
	}

	const handleSubmit = async () => {
		isLoading.value = true
		let sumField
		if (["month", "week"].includes(groupedBy.value)) {
			sumField = groupedBy.value
		} else if (sumBy.value === "staff") {
			if (indicator.value === "user") sumField = "associatedLogin.username"
			else sumField = "createdBy.username"
		} else sumField = sumBy.value

		try {
			if (indicator.value === "user") {
				const { users, cases } = await convertUsersChartData()

				const userData = groupActiveUserChart(users, cases, sumField)
				buildActiveUserChartOpts(userData)
			} else {
				const data = await convertChartData()

				const groupedData = await groupChartData(data, sumField)

				if (indicator.value === "conversation") {
					const FNAData = groupFNAStatusChart(groupedData)
					buildFNAStatusChartOpts(FNAData)
				} else if (indicator.value === "leadGCM") {
					const leadGCMData = groupLeadGCMChart(groupedData)
					buildLeadGCMChartOpts(leadGCMData)
				} else if (indicator.value === "APE") {
					const apeData = groupAPEChart(groupedData)
					buildAPEChartOpts(apeData)
				}
			}
		} catch (err) {
			if (indicator.value === "user") buildActiveUserChartOpts([])
			else if (indicator.value === "conversation") buildFNAStatusChartOpts([])
			else if (indicator.value === "leadGCM") buildLeadGCMChartOpts([])
			else if (indicator.value === "APE") buildAPEChartOpts([])
		}

		isLoading.value = false
		isShowChart.value = true
	}

	const mapData = (data) => {
		const mappedData = data.map((val) => Math.round(val))
		if (data.length >= 5 || data.length === 1) return mappedData
		for (let i = 0; i < 5 - data.length; i++) {
			mappedData.push(null)
		}
		return mappedData
	}

	const mapCategories = (data) => {
		const mappedData = data
		if (data.length >= 5 || data.length === 1) return mappedData
		for (let i = 0; i < 5 - data.length; i++) {
			mappedData.push("")
		}
		return mappedData
	}

	const setDefaultSumBy = () => {
		if (userPosition.value === "executive") return "ho"
		if (userPosition.value === "branchManager") return "branch"
		if (userPosition.value === "regionalManager") return "region"
		if (userPosition.value === "areaManager") return "area"
		if (userPosition.value === "RBO.SRBO" || userPosition.value === "TIS.PRM") return "lead"
		return "staff"
	}

	const checkUserUnlockStatus = (lockoutEndDateUtc) => {
		if (!lockoutEndDateUtc) {
			return true
		}

		const timeDiff = new Date(lockoutEndDateUtc).getTime() - Date.now()
		return timeDiff < 0
	}
	async function initLocationsData() {
		const branches = await fetchOriginalBranches()
		const getLocation = getLocationByBranches({ originalBranches: branches, locale })
		return {
			branches,
			areas: getLocation?.areas,
			regions: getLocation?.regions,
		}
	}

	onMounted(async () => {
		goToHomePageIfUserDoesNotHavePermission()
		loaders.pageLoader.start()
		originalLocationList.value = await initLocationsData()
		sumBy.value = setDefaultSumBy()
		loaders.pageLoader.end()
	})

	return {
		indicator,
		sumBy,
		chartType,
		areas: areaOptions,
		regions: regionOptions,
		branches: branchOptions,
		disableBranchSelect,
		staffTypes: staffTypeData,
		filters,
		quickOptions,
		dateTypeOptions,
		enableFilters,
		disableViewChartButton,
		indicatorData,
		sumByData,
		groupedBy,
		groupByData,
		chartOptions,
		chartSeries,
		isShowChart,
		isLoading,
		enableSumBy,
		enableGroupBy,
		tooltipData,
		// methods
		handleSubmit,
		loaders,
	}
}
